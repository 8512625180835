import axios from "axios";
import moment from "moment";

import { tokenConfig } from "../authActions";
import fileSaver from "../../utility/fileSaver";
import { convertFileToArrayBuffer, convertBlobToBase64 } from "../../utility";
import { returnErrors } from "../messageActions";
import config from "../../config";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const downloadOctet = (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const START_DOWNLOAD = "file/START_DOWNLOAD";
const OK_DOWNLOAD = "file/OK_DOWNLOAD";
const ERROR_DOWNLOAD = "file/ERROR_DOWNLOAD";
const PROGRESS_UPLOAD = "file/PROGRESS_UPLOAD";
const OK_UPLOAD = "file/OK_UPLOAD";
const SETUP_UPLOAD = "file/SETUP_UPLOAD";
const ERROR_UPLOAD = "file/ERROR_UPLOAD";
const CLEAR_UPLOAD = "file/CLEAR_UPLOAD";
const REMOVE_UPLOAD = "file/REMOVE_UPLOAD";

export const fileActions = {
  START_DOWNLOAD,
  OK_DOWNLOAD,
  ERROR_DOWNLOAD,
  PROGRESS_UPLOAD,
  OK_UPLOAD,
  SETUP_UPLOAD,
  ERROR_UPLOAD,
  CLEAR_UPLOAD,
  REMOVE_UPLOAD,
};

const getFileStart = () => ({
  type: START_DOWNLOAD,
});

const getFileOk = () => ({
  type: OK_DOWNLOAD,
});

export const uploadFileSetup = (files, field = null) => ({
  type: SETUP_UPLOAD,
  files,
  field,
});

export const uploadProgress = (id, progress) => ({
  type: PROGRESS_UPLOAD,
  payload: {
    id,
    progress,
  },
});

export const uploadOk = (id, response) => ({
  type: OK_UPLOAD,
  id,
  response,
});

export const uploadError = (id, response) => ({
  type: ERROR_UPLOAD,
  id,
  response,
});

export const uploadClear = () => ({
  type: CLEAR_UPLOAD,
});

export const uploadRemove = (id) => ({
  type: REMOVE_UPLOAD,
  id,
});

export const downloadFile =
  (path, filename, responseType = "base64", token) =>
  async (dispatch, getState) => {
    try {
      dispatch(getFileStart());

      if (token) {
        const access_token = getState().auth.token;
        downloadOctet(
          `${config.api_url}${path}?token=${token}&oauth_token=${access_token}`,
          filename
        );
      } else if (responseType === "blob") {
        const response = await axios.get(`${path}`, {
          ...tokenConfig(getState),
          responseType: "blob",
          timeout: 30000,
        });

        await fileSaver(response.data, filename);

        dispatch(getFileOk());
      } else {
        const response = await axios.get(`${path}`, {
          ...tokenConfig(getState),
          responseType: "arrayBuffer",
          timeout: 30000,
        });

        const blob = b64toBlob(response.data.data[0].value);

        await fileSaver(blob, filename);

        dispatch(getFileOk());
      }
    } catch (error) {
      dispatch(returnErrors(error));
    }
  };

export const downloadFileNoAuth = (path, filename) => async (dispatch) => {
  try {
    dispatch(getFileStart());

    const response = await axios.get(`${path}`);

    await fileSaver(response.data, filename);

    dispatch(getFileOk());
  } catch (error) {
    dispatch(returnErrors(error));
  }
};

export const uploadFile =
  (files, entity, bundle, field = null, contentType = null) =>
  async (dispatch, getState) => {
    if (files.length) {
      const fileRequests = [];
      files.forEach((file) => {
        fileRequests.push(
          uploadRequest(
            file,
            dispatch,
            getState,
            entity,
            bundle,
            file.field ? file.field : field,
            contentType
          )
        );
      });

      const responseArray = await Promise.all(fileRequests);
      return responseArray;
    }
  };

const uploadRequest = async (
  file,
  dispatch,
  getState,
  entity,
  bundle,
  field,
  contentType
) => {
  const auth = getState().auth.token;
  const csrf = getState().auth.csrf;
  const headers = {
    withCredentials: true,
    headers: {
      "X-CSRF-Token": csrf,
      "Content-Type": "application/octet-stream",
      "Content-Disposition": `file; filename="${file.file.name}"`,
      Authorization: `Bearer ${auth}`,
    },
    params: {
      _format: "hal_json",
    },
    onUploadProgress: (progress) => {
      const { loaded, total } = progress;

      const percentageProgress = Math.floor((loaded / total) * 100);
      dispatch(uploadProgress(file.id, percentageProgress));
    },
  };

  if (!entity && !bundle && !field && contentType) {
    try {
      const fileStream = await convertBlobToBase64(file.file);
      const year = moment().format("YYYY");
      const month = moment().format("MM");

      const response = await axios.post(
        `${config.api_url}/entity/file`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/file/image`,
            },
          },
          uri: [
            {
              value: `private://${contentType}/${year}-${month}/${file.file.name}`,
            },
          ],
          type: [
            {
              target_id: "image",
              uuid: "1",
              status: "1",
            },
          ],
          filename: [
            {
              value: file.file.name,
            },
          ],
          filemime: [
            {
              value: file.file.type,
            },
          ],
          data: [
            {
              value: fileStream.split(",")[1],
            },
          ],
        },
        {
          ...tokenConfig(getState),
        }
      );
      dispatch(uploadOk(file.id, response));
      return response;
    } catch (error) {
      dispatch(uploadError(file.id, error));
      return error;
    }
  } else {
    try {
      const fileStream = await convertFileToArrayBuffer(file.file);

      const response = await axios.post(
        `${config.api_url}/file/upload/${entity}/${bundle}/${field}`,
        fileStream,
        { ...headers }
      );
      dispatch(uploadOk(file.id, response));
      return response;
    } catch (error) {
      dispatch(uploadError(file.id, error));
      return error;
    }
  }
};
