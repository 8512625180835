import styled from 'styled-components'

export const Loader = styled.div`
    display: flex;
    width: 100%;
    height: 80vh;
    align-items: center;
    justify-content: center;
    z-index: 0;
`;
