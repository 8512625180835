import styled from "styled-components";
import constants from "../constants";

export const Page = styled.div`
  flex: 1;
  height: calc((var(--vh, 1vh) * 100) - var(--page-header-height));
  min-height: calc(var(--page-body-min-height) - var(--page-header-height));
  width: 100%;
  overflow: hidden;

  &.has-bottom-navigation{
    min-height: calc(var(--page-body-min-height) - var(--page-header-height) + var(--page-bottom-navigation-height));
  }
  @media (max-height: ${constants.pageBodyMinHeight}) {
    min-height: 0px;
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(
    var(--vh, 1vh) * 100 - ${constants.pageHeaderHeight} -
      ${constants.pageHeaderHeight}
  );
  background: ${constants.colorGrayLighter};
  outline: none !important;

  @media (max-width: ${constants.breakpoints.sm}) {
    height: calc(
      var(--vh, 1vh) * 100 - ${constants.pageHeaderHeight2Line} -
        ${constants.pageHeaderHeight2Line}
    );
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const RigthAside = styled.div`
  width: 0;
  background: var(--color-white);
  overflow: auto;
  box-shadow: -1px 0px 6px 1px rgba(0,0,0,0.15);
  z-index: 8;
  transition: width 0.5s ease-in;
  overflow-x: hidden;

  &.open{
    width: 280px;
  }

  .list{
    width: 280px;
    background-color: var(--color-white);
  }
`;
