import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "config";
import { formatField } from "utility";
import { formatInsuranceFields } from "./utils";
import { setAlert } from "features/Alert/alertSlice";

// const getQueryParams = (params) => {
//   return `?${
//     typeof params === "string"
//       ? params.substring(1)
//       : querystring.stringify(params)
//   }`;
// };

const namespace = "insurance";

//   export const fetchWorkArea = createAsyncThunk(
//     `${namespace}/fetchWorkArea`,
//     async (id, { getState, signal, rejectWithValue }) => {
//       try {
//         const source = axios.CancelToken.source();

//         signal.addEventListener("abort", () => {
//           source.cancel();
//         });

//         const response = await axios.get(`${config.api_url}/node/${id}`, {
//           ...tokenConfig(getState),
//           cancelToken: source.token,
//         });

//         return formatWorkAreaFields(response.data);
//       } catch (err) {
//         let error = err; // cast the error for access
//         if (!error.response) {
//           throw err;
//         }
//         // We got validation errors, let's return those so we can reference in our component and set form errors
//         return rejectWithValue(error.response.data);
//       }
//     }
//   );

export const postInsurance = createAsyncThunk(
  `${namespace}/postInsurance`,
  async ({ id, title, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job_division_insurance`,
            },
          },
          title: [{ value: `Insurance info for ${title}` }],
          field_jdi_job_division: [{ target_id: id }],
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully Added Insurance",
        })
      );

      return formatInsuranceFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding insurance: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInsurance = createAsyncThunk(
  `${namespace}/patchInsurance`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job_division_insurance`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated Insurance",
        })
      );

      return formatInsuranceFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating insurance: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const insuranceSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    data: {},
    resources: {},
  },
  reducers: {
    setInsuranceData: (state, { payload: insurance }) => {
      state.data = insurance;
    },
  },
  extraReducers: {
    [postInsurance.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      state.data = {
        ...state.data,
        ...tempParams,
      };
    },
    [postInsurance.fulfilled](state, { payload: insurance }) {
      state.data = insurance;
    },
    [postInsurance.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchInsurance.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      state.data = {
        ...state.data,
        ...tempParams,
      };
    },
    [patchInsurance.fulfilled](state, { payload: insurance }) {
      state.data = insurance;
    },
    [patchInsurance.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getInsuranceLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.insurance.loading
);

export const getInsuranceDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.insurance.data
);

export const getInsuranceErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.insurance.error
);

export const { setInsuranceData } = insuranceSlice.actions;

export default insuranceSlice.reducer;
