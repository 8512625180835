const GET = "message/GET";
const CLEAR = "message/CLEAR";

export const messageActions = {
  GET,
  CLEAR,
};

export const returnErrors = (error) => {
  if (error.response) {
    if (typeof error.response.data === "string") {
      return {
        type: GET,
        payload: {
          msg: error.response.data,
          status: error.response.status,
          id: "negative",
        },
      };
    } else {
      return {
        type: GET,
        payload: {
          msg: error.response.data.message,
          status: error.response.status,
          id: "negative",
        },
      };
    }
  } else if (error.request) {
    return {
      type: GET,
      payload: { msg: error.request.data, status: 400, id: "negative" },
    };
  } else {
    return {
      type: GET,
      payload: { msg: error.message, status: 400, id: "negative" },
    };
  }
};

export const returnSuccess = (response, msg) => {
  return {
    type: GET,
    payload: {
      msg: msg,
      status: response.status,
      id: "positive",
    },
  };
};

export const clearMessage = () => {
  return {
    type: CLEAR,
  };
};
