import styled from "styled-components";

export const Container = styled.div`
  padding: 0;
`;

export const Wrapper = styled.div`
  width: 300px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 200000;
`;

export const Header = styled.header`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--color-blue-darker);
  letter-spacing: 0.125rem;
  position: relative;
  z-index: 2;
`;

export const ItemContainer = styled.div`
  padding: 1rem;
  position: relative;
`;

export const ItemBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;
