import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Collapse } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import SimpleBar from "simplebar-react";

import { GroupHeader } from "./Styled";

const FormCollapse = ({ title, children, expand, limitHeight }) => {
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    setOpen(expand);
  }, [expand]);

  return (
    <div>
      <GroupHeader onClick={() => setOpen(!open)}>
        <Typography variant="subtitle1" color="textSecondary">
          {title}
        </Typography>
        <ExpandMore
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.25s",
          }}
        />
      </GroupHeader>
      <Collapse in={open}>
        {limitHeight ? (
          <SimpleBar
            forceVisible="y"
            autoHide={false}
            style={{ maxHeight: "300px" }}
          >
            {children}
          </SimpleBar>
        ) : (
          children
        )}
      </Collapse>
    </div>
  );
};

const { bool } = PropTypes;
FormCollapse.propTypes = {
  expand: bool,
  limitHeight: bool,
};

FormCollapse.defaultProps = {
  expand: true,
  limitHeight: false,
};

export default FormCollapse;
