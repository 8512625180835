import React from "react";
import { useSelector } from "react-redux";
import { Divider, Typography } from "@mui/material";
import { FaRegAddressBook } from "react-icons/fa";
import { RiContactsBookLine } from "react-icons/ri";
import IconTextItem from "common/IconTextItem";
import UserItem from "common/UserItem";

import { getMemberDataSelector } from "features/Member/memberSlice";
import { size } from "lodash";

const MemberAssignmentDetail = (props) => {
  const _member = useSelector(getMemberDataSelector);
  const inListing = (props?.member ? true : false);
  const member = (inListing ? props.member : _member);

  if (!member) return null;

  return (
    <div className={props.className}>
      <IconTextItem icon={<FaRegAddressBook />}  className={`${props.className}__item`}>
        <Typography
          variant="subtitle3"
          style={{ textTransform: "uppercase" }}
          color="textSecondary"
        >
          Address
        </Typography>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "1rem" }}>
            <Typography variant="body2" align="right">
              Address:
            </Typography>
            {member?.field_street_address?.address_line2 && (
              <Typography variant="body2" align="right">
                Address 2:
              </Typography>
            )}
            <Typography variant="body2" align="right">
              City:
            </Typography>
            <Typography variant="body2" align="right">
              State:
            </Typography>
            <Typography variant="body2" align="right">
              Zip Code:
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <Typography variant="body2">
              {member?.field_street_address?.address_line1}
            </Typography>
            <Typography variant="body2">
              {member?.field_street_address?.address_line2}
            </Typography>
            <Typography variant="body2">
              {member?.field_street_address?.locality}
            </Typography>
            <Typography variant="body2">
              {member?.field_street_address?.administrative_area}
            </Typography>
            <Typography variant="body2">
              {member?.field_street_address?.postal_code}
            </Typography>
          </div>
        </div>
      </IconTextItem>
      {member?.field_phone && (
        <IconTextItem icon={<FaRegAddressBook />}  className={`${props.className}__item`}>
          <Typography
            variant="subtitle3"
            style={{ textTransform: "uppercase" }}
            color="textSecondary"
          >
            Office Phone
          </Typography>
          <Typography variant="body2">{member?.field_phone}</Typography>
        </IconTextItem>
      )}
      {member?.field_phone_after_hours && (
        <IconTextItem icon={<FaRegAddressBook />}  className={`${props.className}__item`}>
          <Typography
            variant="subtitle3"
            style={{ textTransform: "uppercase" }}
            color="textSecondary"
          >
            After Hours Phone
          </Typography>
          <Typography variant="body2">
            {member?.field_phone_after_hours}
          </Typography>
        </IconTextItem>
      )}
      <Divider />
      <IconTextItem icon={<RiContactsBookLine />}  className={`${props.className}__item`}>
        <Typography
          variant="subtitle3"
          style={{ textTransform: "uppercase" }}
          color="textSecondary"
        >
          Primary Contact
        </Typography>
        {size(member?.field_primary_poc) > 0 ? (
          member?.field_primary_poc.map((user, i) => (
            <UserItem key={`${user.uid}-primary-${i}`} user={user} />
          ))
        ) : (
          <Typography>No contact set</Typography>
        )}
      </IconTextItem>
      <Divider />
      <IconTextItem icon={<RiContactsBookLine />}  className={`${props.className}__item`}>
        <Typography
          variant="subtitle3"
          style={{ textTransform: "uppercase" }}
          color="textSecondary"
        >
          Secondary Contacts
        </Typography>
        {size(member?.field_secondary_pocs) > 0 ? (
          member?.field_secondary_pocs.map((user, i) => (
            <UserItem key={`${user.uid}-secondary-${i}`} user={user} />
          ))
        ) : (
          <Typography>No contact set</Typography>
        )}
      </IconTextItem>
      <Divider />
      <IconTextItem icon={<RiContactsBookLine />}  className={`${props.className}__item`}>
        <Typography
          variant="subtitle3"
          style={{ textTransform: "uppercase" }}
          color="textSecondary"
        >
          After Hours Contacts
        </Typography>
        {size(member?.field_after_hours_pocs) > 0 ? (
          member?.field_after_hours_pocs.map((user, i) => (
            <UserItem key={`${user.uid}-ah-${i}`} user={user} />
          ))
        ) : (
          <Typography>No contact set</Typography>
        )}
      </IconTextItem>
    </div>
  );
};

MemberAssignmentDetail.propTypes = {};

export default MemberAssignmentDetail;
