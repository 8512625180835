import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { toArray } from "lodash";

import { TextFormField } from "common/Fields";
import { RadioFormField } from "common/Fields";
import { AutocompleteFormField } from "common/Fields";

import {
  fetchMembers,
  getMembersLoadingSelector,
  membersSelectors,
} from "features/Members/membersSlice";

const Information = ({ values, isSubmitting, value, handleNext }) => {
  const dispatch = useDispatch();
  const members = useSelector(membersSelectors.selectAll);
  const loadingMembers = useSelector(getMembersLoadingSelector);

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchMembers({
          keywords: query,
          "filter[phx_client_nids]": values.field_phoenix_client,
        })
      );
    },
    [dispatch, values.field_phoenix_client]
  );

  return (
    <div>
      <Typography variant="h6">Information</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Creating a Job Division will also create a Customer, Customer Location,
        and a Job container for this Job Division. If you already have a
        Customer or Customer Location you can create a job for those
        specifically.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xxs={12}>
          <RadioFormField
            name="field_res_com"
            aria="field_res_com-radio"
            label="Type"
            disabled={isSubmitting}
            style={{ flexDirection: "row" }}
            options={[
              { label: "Residential", value: "residential" },
              { label: "Commercial", value: "commercial" },
            ]}
            orientation="horizontal"
          />
        </Grid>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_assigned_member"
            variant="outlined"
            label="Provider"
            size="small"
            disabled={isSubmitting}
            options={toArray(members).map((obj) => ({
              name: obj.member_name,
              nid: obj.member_nid,
            }))}
            getOptionLabel={(option) => (option?.name ? option.name : '')}
            loading={loadingMembers}
            fetchData={getUsers}
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography variant="body1" color="textSecondary">
            Referrer
          </Typography>
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_referred_by_text"
            label="Referred By Employee"
            disabled={isSubmitting}
            labelwidth={70}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_referred_by_phone"
            label="Referred By Phone"
            disabled={isSubmitting}
            labelwidth={70}
            autoComplete="off"
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography variant="body1" color="textSecondary">
            Caller
          </Typography>
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_jd_mf_caller"
            label="Caller"
            disabled={isSubmitting}
            labelwidth={70}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_jd_mf_caller_phone"
            label="Caller Phone"
            disabled={isSubmitting}
            labelwidth={70}
            autoComplete="off"
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Information.propTypes = {};

export default Information;
