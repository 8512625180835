import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "aime";

export const fetchAimeFiles = createAsyncThunk(
  `${namespace}/fetchAimeFiles`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/askaime/files/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { data: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const filesAdapter = createEntityAdapter({
  selectId: (file) => file.pid,
});

const aimeSlice = createSlice({
  name: namespace,
  initialState: {
    files: filesAdapter.getInitialState({
      loading: false,
      error: null,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchAimeFiles.pending](state) {
      state.files.loading = true;
      state.files.error = null;
    },
    [fetchAimeFiles.fulfilled](state, { payload: { data, pagination } }) {
      state.files.loading = false;
      state.files.error = null;
      state.files.pagination = pagination;
      filesAdapter.setAll(state.files, data);
    },
    [fetchAimeFiles.rejected](state, action) {
      state.files.loading = false;
      if (action.payload) {
        state.files.error = action.payload.message;
      } else {
        state.files.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getAimeFilesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.aime.files.loading
);

export const getAimeFilesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.aime.files.pagination
);

export const getAimeFilesErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.aime.files.error
);

export const aimeFilesSelectors = filesAdapter.getSelectors(
  (state) => state.aime.files
);

export default aimeSlice.reducer;
