import { hasPermission } from "../../utility";
import { useSelector } from "react-redux";

const Guard = ({ intendedRoles, children }) => {
  const user = useSelector((state) => state.auth.user.data);
  const roles = user?._processed?.roles ? user._processed.roles : [];
  const hasAccess = hasPermission(roles, intendedRoles);

  if (!hasAccess) return null;

  return children;
};

Guard.propTypes = {};

export default Guard;
