import * as Yup from "yup";
import constants from "components/constants";

export const addMemberGeneral = Yup.object().shape({
  name: Yup.string("Must be a string").required("Required"),
  notes: Yup.string("Must be a string"),
  status: Yup.string("Must be a string").required("Required"),
  region: Yup.string("Must be a string").required("Required"),
  office: Yup.string("Must be a string").required("Required"),
  affiliation: Yup.string("Must be a string").required("Required"),
  number: Yup.string("Must be a string").required("Required"),
  memberCode: Yup.string("Must by a string")
    .max(4, "No longer than 4 characters")
    .required("Required"),
  website: Yup.string()
    .url("Must be a url ex https://www.google.com")
    .nullable(),
  meeting: Yup.string()
    .url("Must be a url ex https://www.google.com")
    .nullable(),
});

export const addMemberAddress = Yup.object().shape({
  "office-street": Yup.string("Must be a string").required("Required"),
  "office-street2": Yup.string("Must be a string"),
  "office-city": Yup.string("Must be a string").required("Required"),
  "office-state": Yup.string("Must be a string").required("Required"),
  "office-zip": Yup.string()
    .matches(/^[0-9]{5}$/, "Must be a valid zipcode")
    .required("Required"),
  "mail-street": Yup.string("Must be a string"),
  "mail-street2": Yup.string("Must be a string"),
  "mail-city": Yup.string("Must be a string"),
  "mail-state": Yup.string("Must be a string"),
  "mail-zip": Yup.string("Must be a string"),
});

export const addMemberCompliance = Yup.object().shape({
  reason: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  method: Yup.string().required("Required"),
});

export const addMemberInsurance = Yup.object().shape({
  title: Yup.string().when("existing", {
    is: (existing) =>
      !existing || (existing && existing.nid && existing.nid === "manual"),
    then: Yup.string().required("Field is required"),
  }),
  email: Yup.string().when("existing", {
    is: (existing) =>
      !existing || (existing && existing.nid && existing.nid === "manual"),
    then: Yup.string().email().required("Required"),
  }),
  phone: Yup.string().when("existing", {
    is: (existing) =>
      !existing || (existing && existing.nid && existing.nid === "manual"),
    then: Yup.string()
      .matches(constants.phoneRegExp, "Phone number is not valid")
      .required(),
  }),
  agency: Yup.string().when("existing", {
    is: (existing) =>
      !existing || (existing && existing.nid && existing.nid === "manual"),
    then: Yup.string().nullable(),
  }),
});

export const addMemberFormSchema = Yup.object().shape({
  title: Yup.string("Must be a string").required("Required"),
  field_phoenix_clients: Yup.array().when('$exist', {
    is: exist => exist,
    then: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string("Must be a string"),
          nid: Yup.string("Must be a string"),
        })
      )
      .required("Must have HLX clients")
  }),
  field_note: Yup.string("Must be a string"),
  field_status: Yup.string("Must be a string").required("Required"),
  field_region: Yup.string("Must be a string").required("Required"),
  field_office_id: Yup.string("Must be a string").required("Required"),
  field_affiliation: Yup.string("Must be a string").required("Required"),
  field_next_job_number: Yup.string("Must be a string").required("Required"),
  field_website: Yup.string()
    .url("Must be a url ex https://www.google.com")
    .nullable(),
  field_video_call_url: Yup.string()
    .url("Must be a url ex https://www.google.com")
    .nullable(),
  field_street_address: Yup.object().shape({
    address_line1: Yup.string("Must be a string").required("Required"),
    address_line2: Yup.string("Must be a string"),
    administrative_area: Yup.string("Must be a string").required("Required"),
    locality: Yup.string("Must be a string").required("Required"),
    postal_code: Yup.string("Must be a string").required("Required"),
  }),
  field_billing_address: Yup.object().shape({
    address_line1: Yup.string("Must be a string").required("Required"),
    address_line2: Yup.string("Must be a string"),
    administrative_area: Yup.string("Must be a string").required("Required"),
    locality: Yup.string("Must be a string").required("Required"),
    postal_code: Yup.string("Must be a string").required("Required"),
  }),
});
