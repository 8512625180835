import { formatField } from "../../utility";

export const formatInsuranceFields = (d) => {
  return {
    nid: formatField(d, "nid"),
    field_jdi_carrier: formatField(d, "field_jdi_carrier"),
    field_jdi_claim_number: formatField(d, "field_jdi_claim_number"),
    field_jdi_policy_number: formatField(d, "field_jdi_policy_number"),
    field_jdi_deductible: formatField(d, "field_jdi_deductible"),
    field_jdi_adjuster_email: formatField(d, "field_jdi_adjuster_email"),
    field_jdi_adjuster_fax: formatField(d, "field_jdi_adjuster_fax"),
    field_jdi_adjuster_name: formatField(d, "field_jdi_adjuster_name"),
    field_jdi_adjuster_phone: formatField(d, "field_jdi_adjuster_phone"),
    field_jdi_agent_email: formatField(d, "field_jdi_agent_email"),
    field_jdi_agent_fax: formatField(d, "field_jdi_agent_fax"),
    field_jdi_agent_name: formatField(d, "field_jdi_agent_name"),
    field_jdi_agent_phone: formatField(d, "field_jdi_agent_phone"),
  };
};
