import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "timeEntries";

export const fetchTimeEntries = createAsyncThunk(
  `${namespace}/fetchTimeEntries`,
  async (params, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/time-entries${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return {
        results: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const timeEntriesAdapter = createEntityAdapter({
  selectId: (result) => result.time_entry_nid,
});

const timeEntriesSlice = createSlice({
  name: namespace,
  initialState: timeEntriesAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
  }),
  reducers: {},
  extraReducers: {
    [fetchTimeEntries.pending](state) {
      state.loading = true;
    },
    [fetchTimeEntries.fulfilled](state, { payload: { results, pagination } }) {
      timeEntriesAdapter.setAll(state, results);
      state.pagination = pagination;
      state.loading = false;
    },
    [fetchTimeEntries.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getTimeEntriesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.timeEntries.loading
);

export const getTimeEntriesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.timeEntries.pagination
);

export const timeEntriesSelectors = timeEntriesAdapter.getSelectors(
  (state) => state.timeEntries
);

export default timeEntriesSlice.reducer;
