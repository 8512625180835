import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

// const getQueryParams = (params) => {
//   return `?${
//     typeof params === "string"
//       ? params.substring(1)
//       : querystring.stringify(params)
//   }`;
// };

const namespace = "activity";

export const fetchRecentAcitivty = createAsyncThunk(
  `${namespace}/fetchRecentActivity`,
  async (_, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/user/recent-activity`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return response.data.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const recentActivityAdapter = createEntityAdapter({
  selectId: (result) => result.pid,
});

const recentActivitySlice = createSlice({
  name: namespace,
  initialState: recentActivityAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
  }),
  reducers: {
    clearResults: (state) => recentActivityAdapter.removeAll(state),
  },
  extraReducers: {
    [fetchRecentAcitivty.pending](state, action) {
      state.loading = true;
      recentActivityAdapter.removeAll(state);
    },
    [fetchRecentAcitivty.fulfilled](state, { payload: results }) {
      recentActivityAdapter.setAll(state, results);
      state.loading = false;
    },
    [fetchRecentAcitivty.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getRecentActivityLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.activity.loading
);

export const recentActivitySelectors = recentActivityAdapter.getSelectors(
  (state) => state.activity
);

export const { clearResults } = recentActivitySlice.actions;

export default recentActivitySlice.reducer;
