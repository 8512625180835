import store from "store";
import { fetchCurrentUser } from 'features/Auth/authSlice';
import { updateHelixAPICurrent } from 'features/User/userSlice';
import { fetchConversations, updateConversation } from 'features/Conversations/conversationsSlice';
import { fetchUpcomingEvents, updateEvent } from 'features/Events/eventsSlice';
import { fetchGroups, updateGroup } from 'features/Groups/groupsSlice';

export const updateFromWebSocket = (e) => {
  switch(e.type){
    case 'user.current.refresh':
      store.dispatch(fetchCurrentUser());
    break;

    case 'hub.user.update':
      store.dispatch(updateHelixAPICurrent(e.data));
    break;

    case 'hub.conversation.list':
      store.dispatch(fetchConversations());
    break;

    case 'hub.conversation.update':
      store.dispatch(updateConversation(e.data));
    break;

    case 'hub.event.list':
      store.dispatch(fetchUpcomingEvents());
    break;

    case 'hub.event.unread':
    case 'hub.event.status':
      store.dispatch(updateEvent(e.data));
    break;

    case 'hub.group.list':
      store.dispatch(fetchGroups());
    break;

    case 'hub.group.unread':
      store.dispatch(updateGroup(e.data));
    break;

    default:
    break;
  }
};
