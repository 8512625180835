import React from "react";
import ContentLoader from "react-content-loader";
import constants from "../constants";

const TaskSkeleton = () => (
  <ContentLoader
    speed={2}
    width={328}
    height={228}
    viewBox="0 0 328 228"
    foregroundColor={constants.colorGray}
    backgroundColor={constants.colorGrayLight}
  >
    <rect x="0" y="40" rx="3" ry="3" width="200" height="30" />
    <rect x="0" y="80" rx="3" ry="3" width="100" height="15" />
    <rect x="0" y="120" rx="3" ry="3" width="300" height="10" />
    <rect x="0" y="140" rx="3" ry="3" width="230" height="10" />
  </ContentLoader>
);

export default TaskSkeleton;
