import React, { useState } from "react";
import { first } from "lodash";
import { Grid, MenuItem, Typography } from "@mui/material";
import { SelectFormField } from "common/Fields";

import TMTermsFormComponent from "./TMTermsFormComponent";


const MemoTMTermsFormComponent = React.memo(TMTermsFormComponent, (prevProps, nextProps) => {
  const isSame = prevProps.isSubmitting === nextProps.isSubmitting
                 && JSON.stringify(prevProps.existingTerms) === JSON.stringify(nextProps.existingTerms)
                 && JSON.stringify(prevProps.resourceCategories) === JSON.stringify(nextProps.resourceCategories)
                 && JSON.stringify(prevProps.laborTypes) === JSON.stringify(nextProps.laborTypes);
  return isSame;
});

const TMTermsDivisionFormComponent = ({
  tmTerms,
  resourceCategories,
  laborTypes,
  validateField,
  isSubmitting,
  setFieldValue,
  divisionTerms,
  inDialog,
  isDivisionEdit,
}) => {
  const firstTerms = first(tmTerms);
  const defaultOwnerID = (!divisionTerms ? firstTerms.owner_nid : divisionTerms.owner_nid);
  const [selectedTMTerms, setSelectedTMTerms] = useState((!divisionTerms ? firstTerms : divisionTerms));
  const allTerms = tmTerms.concat((divisionTerms ? [{...divisionTerms}] : []));

  let options = tmTerms.map((obj) => ({
    label: obj.owner_title,
    value: obj.owner_nid,
  }));
  if(divisionTerms){
    options = [{
      label: divisionTerms.owner_title + ' (Current)',
      value: divisionTerms.owner_nid,
    }].concat(options);
  }

  return (
    <>
      <Grid item xxs={12}>
        <Typography variant="subtitle1">T&M Terms</Typography>
      </Grid>
      <Grid item xxs={12}>
        <SelectFormField
          fullWidth
          name="tm_terms_owner"
          label={(!divisionTerms ? "Select Terms" : "Select Other Terms")}
          // required
          size="small"
          variant="outlined"
          options={options}
          value={(selectedTMTerms?.owner_nid !== undefined ? selectedTMTerms?.owner_nid : defaultOwnerID)}
          defaultValue={defaultOwnerID}
          onChange={(e) => {
            const selectedTerms = first(allTerms.filter(
              (terms) => terms.owner_nid === e.target.value
            ));
            setSelectedTMTerms(selectedTerms);
          }}
        >
          {options.map((obj) => (
            <MenuItem key={obj.value} value={obj.value}>
              {obj.label}
            </MenuItem>
          ))}
        </SelectFormField>
      </Grid>
      <MemoTMTermsFormComponent
        validateField={validateField}
        setFieldValue={setFieldValue}
        isSubmitting={isSubmitting}
        existingTerms={selectedTMTerms?.terms}
        resourceCategories={resourceCategories}
        laborTypes={laborTypes}
        inDialog={inDialog}
        isDivisionEdit={isDivisionEdit}
      />
    </>
  );
};

TMTermsDivisionFormComponent.propTypes = {};

export default TMTermsDivisionFormComponent;
