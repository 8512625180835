import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "./authActions";
import { returnErrors } from "./messageActions";
import { cancelRequest } from "../utility";

export default function action(type, host, path, params = {}, method, respond) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return (dispatch, getState) => {
    const cancelSource =
      getState().app[type] && getState().app[type].cancel
        ? getState().app[type].cancel
        : null;

    const url = `${host}/${path}?${
      typeof params === "string"
        ? params.substring(1)
        : querystring.stringify(params)
    }`;
    const getDataStart = (payload) => ({
      type: `REQUEST/${type}`,
      payload,
      cancel: source,
    });
    const getDataOk = (payload) => ({
      type: `OK/${type}`,
      payload,
    });
    const getError = (error) => ({
      type: `ERROR/${type}`,
      error,
    });

    const getData = async () => {
      try {
        cancelRequest(cancelSource);

        const response = await axios.get(url, {
          ...tokenConfig(getState),
          cancelToken: source.token,
        });

        dispatch(getDataOk(response.data));
        return response;
      } catch (err) {
        if (err.message !== "canceled axios request") {
          dispatch(getError(err));
          dispatch(returnErrors(err));
        }

        return err;
      }
    };
    const clearData = () => {
      dispatch({
        type: `CLEAR/${type}`,
      });
    };

    const patchData = async () => {
      try {
        const response = await axios.patch(
          `${host}/${path}`,
          params,
          tokenConfig(getState)
        );

        dispatch(getDataOk(response.data));
        return response;
      } catch (err) {
        return err;
      }
    };

    const postData = async () => {
      try {
        const response = await axios.post(`${host}/${path}`, params, {
          ...tokenConfig(getState),
        });

        if (respond) {
          dispatch(getDataOk(response.data));
        }
        return response;
      } catch (err) {
        return err;
      }
    };

    const deleteData = async () => {
      try {
        const response = await axios.delete(
          `${host}/${path}`,
          tokenConfig(getState)
        );

        return response;
      } catch (err) {
        return err;
      }
    };

    if (method === "patch") return patchData();
    if (method === "post") return postData();
    if (method === "delete") return deleteData();
    if (method === "clear") return clearData();

    // dispatch(getDataStart(source));
    dispatch(getDataStart());
    return getData();
  };
}
