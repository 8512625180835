import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

// const getQueryParams = (params) => {
//   return `?${
//     typeof params === "string"
//       ? params.substring(1)
//       : querystring.stringify(params)
//   }`;
// };

const namespace = "tmTerms";

export const fetchTMTerms = createAsyncThunk(
  `${namespace}/fetchTMTerms`,
  async ({ member, customer }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/tm-terms/available/${customer}/${member}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      //return formatPriceListsByOwnerType(response.data);
      // const lists = [];
      // response.data.forEach((list) => {
      //   lists.push({
      //     ...list,
      //     owner_type: list.owner_type ? list.owner_type : "global",
      //   });
      // });
      // return lists;

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const tmTermsAdapter = createEntityAdapter({
  selectId: (terms) => (terms.owner_nid ? terms.owner_nid : 0),
});

// Custom selectors
const selectSelf = (state) => state;
export const getTMTermsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tmTerms.loading
);

export const getTMTermsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tmTerms.error
);

export const tmTermsSelectors = tmTermsAdapter.getSelectors(
  (state) => state.tmTerms
);



export const defaltTMTermsAdapter = createEntityAdapter({
  selectId: () => 0
});
export const setDefaultTMTermsSelectors = tmTermsAdapter.setAll;

export const defaultTMTermsSelectors = defaltTMTermsAdapter.getSelectors(
  (state) => state.taxonomies.defaultTMTerms
);

const tmTermsSlice = createSlice({
  name: namespace,
  initialState: tmTermsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
    removeAllTerms: tmTermsAdapter.removeAll,
  },
  extraReducers: {
    [fetchTMTerms.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchTMTerms.fulfilled](state, { payload: lists }) {
      state.loading = false;
      tmTermsAdapter.setAll(state, lists);
    },
    [fetchTMTerms.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const { removeAllTerms } = tmTermsSlice.actions;

export default tmTermsSlice.reducer;
