import React from "react";
import { Field } from "formik";
import moment from "moment";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DateFormField = ({ name, datePicker, ...props }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const handleDateChange = (value) => {
          if (!value) {
            form.setFieldValue(name, null);
          } else {
            if(field.value && typeof field.value === "object") {
              value = moment(value);
            }
            form.setFieldValue(name, value);
          }
        };
        const handleBlur = () => form.setFieldTouched(name, true);
        const errorText = meta.error && meta.touched ? meta.error : "";
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={field.value}
              onChange={handleDateChange}
              onBlur={handleBlur}
              error={!!errorText}
              helperText={errorText}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth={props?.fullWidth}
                  variant={props?.inputVariant}
                  margin={props?.margin}
                />
              )}
              {...props}
            />
          </LocalizationProvider>
        );
      }}
    </Field>
  );
};

DateFormField.propTypes = {};

export default DateFormField;
