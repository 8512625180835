import React from "react";
import PropTypes from "prop-types";
import { Fade } from "@mui/material";

const Loader = ({ loading, children }) => {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? "400ms" : "0ms"
      }}
      unmountOnExit
    >
      {children}
    </Fade>
  );
};

const { bool } = PropTypes;
Loader.propTypes = {
  loading: bool
};

export default Loader;
