import moment from "moment";

export const eventIsCurrent = (event) => {
  return moment().isBetween(event.start, event.end);
};

export const formatTimeRange = (event, dateFormat) => {
  if(!event || !event?.start){
    return '';
  }

  let startDateFormat = "l";
  let endDateFormat = "l";
  if(dateFormat){
    startDateFormat = dateFormat;
    endDateFormat = dateFormat;
  }
  else{
    let startYear = moment(event.start).format(" YYYY");
    let endYear = moment(event.end).format(" YYYY");
    if(startYear !== endYear){
      startYear = ' YYYY';
      endYear = ' YYYY';
    }
    else{
      startYear = '';
      endYear = '';
    }
    startDateFormat = `MMMM Do${startYear}`;
    endDateFormat = `MMMM Do${endYear}`;
  }

  const startDate = moment(event.start).format(startDateFormat);
  const endDate = moment(event.end).format(endDateFormat);
  let startTime = moment(event.start).format(" h:mm a");
  let endTime = moment(event.end).format(" h:mm a");
  if(startTime === " 12:00 am" && endTime === " 12:00 am"){
    startTime = "";
    endTime = "";
  }

  if (event.start === event.end) {
    return `${startDate}${startTime}`;
  } else if (startDate === endDate && startTime !== endTime) {
    return `${startDate}${startTime} - ${endTime}`;
  } else if (startDate !== endDate && startTime === endTime) {
    return `${startDate} - ${endDate}${startTime}`;
  } else {
    return `${startDate}${startTime} - ${endDate}${endTime}`;
  }
};
