import { size, first, toArray, has, isArray } from "lodash";
import {
  formatField,
  getTaxonomyFields,
  getValuesFromFieldArray,
  getFileFields,
} from "../../utility";

export const parseFeetInches = (feetAndInches, stringOut = false) => {
  if (stringOut) {
    const feet = Math.floor(feetAndInches);
    const inches = Math.round((feetAndInches - feet) * 12);

    return `${feet}' ${inches}"`;
  } else {
    try {
      let fAndI = feetAndInches.split("'");
      let feet = Number(fAndI[0]) * 12;
      let inches = fAndI[1] || "0";
      inches = inches.replace('"', "");
      inches = Number(inches);

      let total = feet + inches;

      return total / 12;
    } catch (e) {
      return null;
    }
  }
};

export const formatScopesArray = (object, field) => {
  if (has(object, field)) {
    if (isArray(object[field])) {
      return object[field].map((obj) => ({
        id: formatField(obj, "id"),
        revision_id: formatField(obj, "revision_id"),
        field_mf_room_cc_scopes: obj.field_mf_room_cc_scopes
          ? obj.field_mf_room_cc_scopes
          : [],
        field_mf_room_pm_scopes: obj.field_mf_room_pm_scopes
          ? obj.field_mf_room_pm_scopes
          : [],
      }));
    } else {
      return {
        id: null,
        revision_id: null,
        field_mf_room_cc_scopes: [],
        field_mf_room_pm_scopes: [],
      };
    }
  } else {
    return {
      id: null,
      revision_id: null,
      field_mf_room_cc_scopes: [],
      field_mf_room_pm_scopes: [],
    };
  }
};

const getInspectionReportData = (d) => {
  const field = formatField(d, "field_mf_room_insp_rpt_data");
  const data = {
    ceiling_air_mover_rec: 0,
    class: 0,
    class_rec: 0,
    floor_air_mover_rec: 0,
    offset_qty: 0,
    total_air_mover_qty: 0,
    total_air_mover_rec: 0,
    total_sqft: 0,
    total_wall_height: 0,
    total_wet_sqft: 0,
    wall_offset_air_mover_rec: 0,
    wet_ceiling_sqft: 0,
    wet_floor_sqft: 0,
    wet_wall_lft: 0,
  };

  if (field) {
    return field;
  } else {
    return data;
  }
};

export const formatRoomSurface = (obj) => {
  return {
    links: obj && obj._links ? obj._links : null,
    id: formatField(obj, "id"),
    uuid: formatField(obj, "uuid"),
    revisionId: formatField(obj, "revision_id"),
    field_mfrs_total_height_depth: formatField(
      obj,
      "field_mfrs_total_height_depth"
    ),
    field_mfrs_total_width: formatField(obj, "field_mfrs_total_width"),
    field_mfrs_wet_height_depth: formatField(
      obj,
      "field_mfrs_wet_height_depth"
    ),
    field_mfrs_wet_width: formatField(obj, "field_mfrs_wet_width"),
    field_mfrs_location: formatField(obj, "field_mfrs_location"),
  };
};

export const formatRoomFields = (r) => {
  return {
    nid: formatField(r, "nid"),
    title: formatField(r, "title"),
    field_mf_chamber_level: getTaxonomyFields(r, "field_mf_chamber_level"),
    field_mf_room_scopes: formatScopesArray(r, "field_mf_room_scopes"),
    field_mf_room_flooring_options: getValuesFromFieldArray(
      r,
      "field_mf_room_flooring_options"
    ),
    field_mm_image: first(getFileFields(r, "field_mm_image")),
    field_mm_final_image: first(getFileFields(r, "field_mm_final_image")),
    field_mm_data: formatField(r, "field_mm_data"),
    field_mf_room_insp_rpt_data: getInspectionReportData(r),
    //   field_ii_assignments: formatInventoryItemsArray(r, "field_ii_assignments"),
    //   field_mf_room_surfaces: formatRoomWallArray(r, "field_mf_room_surfaces"),
  };
};

export const getColumnSizes = (total) => {
  const col1 = Math.round(total / 3);
  const col2 = Math.round((total - col1) / 2);
  const col3 = Math.round(total - col1 - col2);

  return [col1, col2, col3];
};

export const formatScopeCategoriesIntoColumnArray = (categories) => {
  const data = [];

  categories.forEach((category) => {
    const subCategories = toArray(category.children);
    subCategories.forEach((sub) => {
      data.push({
        name: sub.name,
        tid: sub.tid,
        parentTid: category.tid,
        parentName: category.name,
        code: sub.code,
        value: "",
      });
    });
  });

  return data;
};

export const formatColumns = (categories) => {
  const columnArray = formatScopeCategoriesIntoColumnArray(categories);
  const columns = getColumnSizes(size(columnArray));

  return [
    [...columnArray].splice(0, columns[0]),
    [...columnArray].splice(columns[0], columns[1]),
    [...columnArray].splice(columns[0] + columns[1], columns[2]),
  ];
};

export const formatColumnSubGroups = (column) => {
  const data = {};
  column.forEach((row) => {
    if (data[row.parentName] && data[row.parentName].data) {
      data[row.parentName].data.push(row);
    } else {
      data[row.parentName] = { name: row.parentName, data: [row] };
    }
  });

  return toArray(data);
};

export const formatExistingScopes = (ccs, pms) => {
  const data = {};

  ccs.forEach((cc) => {
    data[`cc-${cc.scope_tid}`] = cc.value;
  });

  pms.forEach((pm) => {
    data[`pm-${pm.scope_tid}`] = pm.value;
  });

  return data;
};

export const getCategoryScope = (scopes) => {
  const data = {
    field_mf_room_cc_scopes: [],
    field_mf_room_pm_scopes: [],
    id: null,
    revision_id: null,
  };
  return first(scopes) ? first(scopes) : data;
};

export const formatScopes = (data) => {
  const params = {
    field_mf_room_pm_scopes: [],
    field_mf_room_cc_scopes: [],
  };

  for (let key in data) {
    const scope = data[key];
    if (Number(scope) !== 0) {
      const keyCode = key.split("-");
      if (keyCode[0] === "pm") {
        params.field_mf_room_pm_scopes.push({
          scope_tid: Number(keyCode[1]),
          value: scope,
        });
      } else if (keyCode[0] === "cc") {
        params.field_mf_room_cc_scopes.push({
          scope_tid: Number(keyCode[1]),
          value: scope,
        });
      }
    }
  }

  return params;
};
