import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { formatQuoteSheet } from "./utils";

const namespace = "quotesheets";

export const fetchQuoteSheets = createAsyncThunk(
  `${namespace}/fetchQuoteSheets`,
  async (ids, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const sheets = ids.map((id, i) => ({
        nid: id,
        name: `Quote Sheet (${id})`,
      }));

      return sheets;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuoteSheet = createAsyncThunk(
  `${namespace}/fetchQuoteSheet`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatQuoteSheet(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuoteSheet = createAsyncThunk(
  `${namespace}/postQuoteSheet`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/quote_sheet`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      const sheet = formatQuoteSheet(response.data);

      return { nid: sheet.nid, name: "Quote Sheet" };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchQuoteSheet = createAsyncThunk(
  `${namespace}/patchQuoteSheet`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/quote_sheet`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      const sheet = formatQuoteSheet(response.data);

      return { nid: sheet.nid, name: "Quote Sheet" };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuoteSheetLineItem = createAsyncThunk(
  `${namespace}/postQuoteSheetLineItem`,
  async (
    { id, params, parentField },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/line_items`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: parentField,
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchQuoteSheetLineItem = createAsyncThunk(
  `${namespace}/patchQuoteSheetLineItem`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/line_items`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteQuoteSheet = createAsyncThunk(
  `${namespace}/deleteQuoteSheet`,
  async ({ id }, { getState, rejectWithValue, dispatch }) => {
    try {
      await axios.delete(`${config.api_url}/node/${id}`, tokenConfig(getState));

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing quote sheet: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const quoteSheetsAdapter = createEntityAdapter({
  selectId: (result) => result.nid,
});

const quoteSheetsSlice = createSlice({
  name: namespace,
  initialState: quoteSheetsAdapter.getInitialState({
    loading: false,
    sheet: {
      loading: false,
      data: {},
    },
  }),
  reducers: {},
  extraReducers: {
    [fetchQuoteSheet.pending](state, action) {
      state.sheet.loading = true;
      state.sheet.error = null;
      state.sheet.data = {};
    },
    [fetchQuoteSheet.fulfilled](state, { payload: sheet }) {
      state.sheet.loading = false;
      state.sheet.data = sheet;
    },
    [fetchQuoteSheet.rejected](state, action) {
      if (!action.meta.aborted) {
        state.sheet.loading = false;
        state.sheet.data = {};
        if (action.payload) {
          state.sheet.error = action.payload.message;
        } else {
          state.sheet.error = action.error.message;
        }
      }
    },
    [fetchQuoteSheets.pending](state, action) {
      state.loading = true;
      state.error = null;
    },
    [fetchQuoteSheets.fulfilled](state, { payload: sheets }) {
      state.loading = false;
      quoteSheetsAdapter.setAll(state, sheets);
    },
    [fetchQuoteSheets.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postQuoteSheet.fulfilled](state, { payload: sheet }) {
      quoteSheetsAdapter.addOne(state, sheet);
    },
    [deleteQuoteSheet.pending](state, action) {
      const { id } = action.meta.arg;
      quoteSheetsAdapter.removeOne(state, id);
    },
    [deleteQuoteSheet.fulfilled](state, { payload: id }) {},
    [deleteQuoteSheet.rejected](state, action) {
      const { sheet } = action.meta.arg;
      quoteSheetsAdapter.addOne(state, sheet);

      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getQuoteSheetsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quotesheets.loading
);

export const getQuoteSheetDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quotesheets.sheet.data
);

export const getQuoteSheetloadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quotesheets.sheet.loading
);

export const quoteSheetsSelectors = quoteSheetsAdapter.getSelectors(
  (state) => state.quotesheets
);

export default quoteSheetsSlice.reducer;
