import React from "react";
// import styled from "styled-components";

// const Container = styled.div`
//   background: white;
//   // border: 1px solid rgb(236, 239, 241);
//   border: 1px solid rgba(34, 27, 35, 0.1);
//   box-shadow: rgb(17 51 83 / 4%) 0px 4px 12px 0px;
//   border-radius: 3px;
//   margin-bottom: ${(props) => (props.disableGutterBottom ? 0 : "1.25rem")};
//   position: relative;
// `;


const Card = ({ children, ...rest }) => {
  let classNames = `bg-white border border-solid border-[rgba(34,27,35,0.1)] shadow-[1px_2px_4px_0px_rgb(17,51,83,0.1)] rounded-md relative`;

  let marginBottom = 'mb-5';
  let addlClasses = "";
  if(rest?.className){
    addlClasses = rest.className;
    delete rest.className;
    addlClasses.split(" ").forEach((className) => {
      if(className.startsWith("mb-") || className.startsWith("my-")){
        marginBottom = '';
      }
    });
  }
  classNames = `${classNames} ${marginBottom} ${addlClasses}`
  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
};

Card.propTypes = {};

export default Card;
