import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";

import { statesSelectors } from "features/Taxonomies/taxonomiesSlice";

const Resident = ({ values, isSubmitting, value, handleNext, handlePrev }) => {
  const states = useSelector(statesSelectors.selectAll);

  return (
    <div>
      <Typography style={{ marginTop: "2rem" }} variant="h6">
        {values.field_jd_res_com === "residential" ? "Resident" : "Location"}
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        This information is specific to the Customer Location or the Resident's
        Location.
      </Typography>
      <Grid container spacing={2}>
        <>
          <Grid item xxs={12} md={6}>
            <TextFormField
              fullWidth
              name="customer_location_title"
              label="Name"
              disabled={isSubmitting}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xxs={12} md={6}>
            <TextFormField
              fullWidth
              name="field_cl_year_built"
              label="Year home built"
              disabled={isSubmitting}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xxs={12}>
            <Typography variant="body1" color="textSecondary">
              Address
            </Typography>
          </Grid>
          <Grid item xxs={12}>
            <Grid container spacing={3}>
              <Grid item xxs={12}>
                <TextFormField
                  fullWidth
                  name="field_street_address.address_line1"
                  label="Address"
                  required
                  disabled={isSubmitting}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xxs={12}>
                <TextFormField
                  fullWidth
                  name="field_street_address.address_line2"
                  label="Address 2"
                  disabled={isSubmitting}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xxs={4}>
                <TextFormField
                  fullWidth
                  name="field_street_address.locality"
                  label="City"
                  required
                  disabled={isSubmitting}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xxs={4}>
                <SelectFormField
                  fullWidth
                  name="field_street_address.administrative_area"
                  label="State"
                  required
                  disabled={isSubmitting}
                  options={states}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xxs={4}>
                <TextFormField
                  fullWidth
                  name="field_street_address.postal_code"
                  label="Zip Code"
                  required
                  disabled={isSubmitting}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xxs={12}>
            <Typography variant="body1" color="textSecondary">
              Contact
            </Typography>
          </Grid>
          <Grid item xxs={12} md={6}>
            <TextFormField
              fullWidth
              name="customer_location_field_phone"
              label={
                values.field_jd_res_com === "residential"
                  ? "Home Phone"
                  : "Phone"
              }
              disabled={isSubmitting}
              autoComplete="off"
              variant="outlined"
              size="small"
              isPhone
            />
          </Grid>
          <Grid item xxs={12} md={6}>
            <TextFormField
              fullWidth
              name="customer_location_field_phone_after_hours"
              label={
                values.field_jd_res_com === "residential"
                  ? "Cell Phone"
                  : "After hours phone"
              }
              disabled={isSubmitting}
              autoComplete="off"
              variant="outlined"
              size="small"
              isPhone
            />
          </Grid>
          <Grid item xxs={12} md={6}>
            <TextFormField
              fullWidth
              name="field_cl_email"
              label="Email"
              disabled={isSubmitting}
              variant="outlined"
              size="small"
              autoComplete="off"
              type="email"
            />
          </Grid>
          <Grid item xxs={12} md={6}>
            <SelectFormField
              fullWidth
              name="field_pref_contact_method"
              label="Preferred Contact Method"
              size="small"
              variant="outlined"
              required
              disabled={isSubmitting}
              options={[
                { value: "phone_call", label: "Phone Call" },
                { value: "text", label: "Text" },
                { value: "email", label: "Email" },
              ]}
            />
          </Grid>
        </>
      </Grid>
    </div>
  );
};

Resident.propTypes = {};

export default Resident;
