import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
//   import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatChamberFields } from "./utils";

//   const getQueryParams = (params) => {
//     return `?${
//       typeof params === "string"
//         ? params.substring(1)
//         : querystring.stringify(params)
//     }`;
//   };

const namespace = "chamber";

export const fetchChamber = createAsyncThunk(
  `${namespace}/fetchChamber`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatChamberFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChamberRefresh = createAsyncThunk(
  `${namespace}/fetchChamberRefresh`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatChamberFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const chamberSlice = createSlice({
  name: namespace,
  initialState: {
    error: null,
    loading: false,
    data: {},
  },
  reducers: {},
  extraReducers: {
    [fetchChamber.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [fetchChamber.fulfilled](state, { payload: chamber }) {
      state.loading = false;
      state.data = chamber;
    },
    [fetchChamber.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchChamberRefresh.pending](state, action) {
      state.error = null;
    },
    [fetchChamberRefresh.fulfilled](state, { payload: chamber }) {
      state.data = chamber;
    },
    [fetchChamberRefresh.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getChamberDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.chamber.data
);

export const getChamberLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.chamber.loading
);

export const getChamberErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.chamber.error
);

export default chamberSlice.reducer;
