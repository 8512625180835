import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import notificationAPI from "./notificationAPI";
import { size } from "lodash";

const namespace = "notification";

export const fetchNotifications = createAsyncThunk(
  "notification/fetchNotifications",
  async (config, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await notificationAPI.getNotifications({
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data.map((n) => {
        return {
          ...n,
          last_notifications : n.last_notifications.map((ln) => {
            return {
              ...ln,
              grouping_id: n.grouping_id,
              parent_id: n.parent_id,
              parent_type: n.parent_type,
              data: JSON.parse(ln.data),
            };
          }),
        };
      });
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUnreadNotifications = createAsyncThunk(
  "notification/fetchUnreadNotifications",
  async (config, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await notificationAPI.getUnreadNotifications({
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data.map((n) => {
        return {
          ...n,
          last_notifications : n.last_notifications.map((ln) => {
            return {
              ...ln,
              grouping_id: n.grouping_id,
              parent_id: n.parent_id,
              parent_type: n.parent_type,
              data: JSON.parse(ln.data),
            };
          }),
        };
      });
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


export const patchReadNotifications = createAsyncThunk(
  "groups/patchReadNotifications",
  async ({ uuid, parent_id, parent_type, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await notificationAPI.setRead({
        params: {
          ...params,
          uuid: uuid ? uuid : null,
          parent_id: parent_id ? parent_id : null,
          parent_type: parent_type ? parent_type : null,
        },
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


export const deleteNotifications = createAsyncThunk(
  "groups/deleteNotifications",
  async ({ uuid, parent_id, parent_type, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const _config = config
                      ? config
                      : {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        };

      let response = null;
      if(uuid){
        response = await notificationAPI.deleteNotification({
          uuid,
          config: _config
        });
      }
      else{
        response = await notificationAPI.deleteNotifications({
          parent_id: parent_id,
          parent_type: encodeURIComponent(parent_type),
          config: _config
        });
      }

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


const notificationAdapter = createEntityAdapter({
  // selectId: (notification) => notification.uid,
});

const notificationSlice = createSlice({
  name: namespace,
  initialState: {
    data: [],
    unread: [],
    loading: true,
    deleting: true,
    error: null,
  },
  reducers: {
    // updateNotification: (state, action) => {
    //   state.data = { ...state.data, ...action.payload };
    // },
    clearUnreadNotifications: (state, action) => {
      state.unread = [];
    },
  },
  extraReducers: {
    [fetchNotifications.pending](state, action) {
      state.loading = true;
      state.error = null;
      state.data = [];
    },
    [fetchNotifications.fulfilled](state, { payload }) {
      state.loading = false;
      state.error = null;
      state.data = payload;
    },
    [fetchNotifications.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchUnreadNotifications.pending](state, action) {
      state.loading = true;
      state.error = null;
      state.unread = [];
    },
    [fetchUnreadNotifications.fulfilled](state, { payload }) {
      state.loading = false;
      state.error = null;
      state.unread = payload;
    },
    [fetchUnreadNotifications.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchReadNotifications.pending](state, action) {
      state.loading = true;
      state.error = null;
    },
    [patchReadNotifications.fulfilled](state, { payload, meta }) {
      state.unread = updateStateData(meta.arg, state.unread);
      state.loading = false;
      state.error = null;
    },
    [patchReadNotifications.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteNotifications.pending](state, action) {
      state.loading = true;
      state.deleting = true;
      state.error = null;
    },
    [deleteNotifications.fulfilled](state, { payload, meta }) {
      state.data = updateStateData(meta.arg, state.data);
      state.unread = updateStateData(meta.arg, state.unread);
      state.loading = false;
      state.deleting = false;
      state.error = null;
    },
    [deleteNotifications.rejected](state, action) {
      state.loading = false;
      state.deleting = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

const updateStateData = (args, original) => {
  let newArray = [];
  if(args.parent_id){
    newArray = original.filter((n) => {
      if(args.parent_id !== n.parent_id || args.parent_type !== n.parent_type){
        return n;
      }
      return null;
    });
  }
  else{
    original.forEach((n) => {
      let filtered = n.last_notifications.filter((ln) => ln.uuid !== args.uuid);
      if(size(filtered) > 0){
        newArray.push({
          ...n,
          last_notifications: filtered,
        });
      }
    });
  }
  return newArray;
}

export const notificationSelector = notificationAdapter.getSelectors((state) => state.notification);

// Custom selectors
const selectSelf = (state) => state;
export const getNotificationDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.notification.data
);

export const getUnreadNotificationDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.notification.unread
);

export const getNotificationLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.notification.loading
);

export const getNotificationDeletingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.notification.deleting
);

export const getNotificationErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.notification.error
);

export const { clearUnreadNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
