import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Popper,
  Grid,
  Typography,
  ListSubheader,
  MenuItem,
} from "@mui/material";

import Button from "common/Button";
import { TextFormField } from "common/Fields";
import AutocompleteFormField from "components/AutocompleteFormField";
import { DateFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { times } from "utility";

import {
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import { size } from "lodash";
import {
  fetchPriceListsSeperate,
  priceListsExpenseSelectors,
  priceListsLaborActivitiesSelectors,
  priceListsLaborTypesSelectors,
  priceListsResourcesSelectors,
} from "features/PriceLists/priceListsSlice";
import {
  fetchTMTerms,
  tmTermsSelectors,
} from "components/TMTerms/TMTermsSlice";
import { fetchPriceList, categoriesSelectors, laborTypesSelectors } from "features/PriceList/priceListSlice";
import TMTermsDivisionFormComponent from "components/TMTerms/forms/components/TMTermsDivisionFormComponent";

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 150 }} placement="bottom-start" />;
};

const PriceListField = ({ isSubmitting, lists, name, label }) => {
  const defaultLists = lists.filter((list) => list.owner_type === "global");
  const memberLists = lists.filter((list) => list.owner_type === "member");
  const customerLists = lists.filter((list) => list.owner_type === "customer");

  return (
    <Grid item xxs={12} md={3}>
      <SelectFormField
        fullWidth
        name={name}
        label={label}
        required
        disabled={isSubmitting}
        size="small"
        variant="outlined"
        options={lists.map((obj) => ({
          label: obj.title,
          value: obj.pid,
        }))}
      >
        {size(defaultLists) > 0 && (
          <ListSubheader>System Default</ListSubheader>
        )}
        {defaultLists.map((option) => (
          <MenuItem key={option.pid} value={option.pid}>
            {option.title}
          </MenuItem>
        ))}
        {size(memberLists) > 0 && <ListSubheader>Member</ListSubheader>}
        {memberLists.map((option) => (
          <MenuItem key={option.pid} value={option.pid}>
            {option.title}
          </MenuItem>
        ))}
        {size(customerLists) > 0 && <ListSubheader>Customer</ListSubheader>}
        {customerLists.map((option) => (
          <MenuItem key={option.pid} value={option.pid}>
            {option.title}
          </MenuItem>
        ))}
      </SelectFormField>
    </Grid>
  );
};

const Details = ({ value, handlePrev, isSubmitting, values, setFieldValue, jobSourceOptions, }) => {
  const dispatch = useDispatch();
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const expensePrices = useSelector(priceListsExpenseSelectors.selectAll);
  const resourcePrices = useSelector(priceListsResourcesSelectors.selectAll);
  const laborTypePrices = useSelector(priceListsLaborTypesSelectors.selectAll);
  const laborActivityPrices = useSelector(
    priceListsLaborActivitiesSelectors.selectAll
  );
  const priceListResources = useSelector(categoriesSelectors.selectAll);
  const laborTypes = useSelector(laborTypesSelectors.selectAll);
  const tmTerms = useSelector(tmTermsSelectors.selectAll);
  const [tmTermsResourceCategories, setTMTermsResourceCategories] = useState([]);
  const [tmTermsLaborTypes, setTMTermsLaborTypes] = useState([]);

  useEffect(() => {
    let promise, promise2;
    if (values.field_assigned_member?.nid) {
      promise = dispatch(
        fetchPriceListsSeperate({
          member: values.field_assigned_member?.nid,
          customer: 0,
        })
      );
      promise2 = dispatch(
        fetchTMTerms({
          member: values.field_assigned_member?.nid,
          customer: 0,
        })
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
      if (promise2) {
        promise2.abort();
      }
    };
  }, [dispatch, values.field_assigned_member]);

  useEffect(() => {
    let promise;
    if (values.resources_price_list_pid) {
      promise = dispatch(
        fetchPriceList({
          id: values.resources_price_list_pid,
          type: 'resources',
        })
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, values.resources_price_list_pid]);

  useEffect(() => {
    let promise;
    if (values.service_types_price_list_pid) {
      promise = dispatch(
        fetchPriceList({
          id: values.service_types_price_list_pid,
          type: 'service_types',
        })
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, values.service_types_price_list_pid]);

  useEffect(() => {
    if (size(priceListResources) > 0) {
      let categories = [];
      priceListResources.forEach((cat) => {
        if(cat.name !== "Consumables & Materials"){
          categories.push({
            value: Number(cat.tid),
            label: cat.name,
          });
        }
      });
      setTMTermsResourceCategories(categories);
    }
    else{
      setTMTermsResourceCategories([]);
    }
    if (size(laborTypes) > 0) {
      setTMTermsLaborTypes(laborTypes);
    }
    else{
      setTMTermsLaborTypes([]);
    }
  }, [
    priceListResources,
    setTMTermsResourceCategories,
    laborTypes,
    setTMTermsLaborTypes,
  ]);

  const lossWidth = (size(jobSourceOptions) > 1 ? 6 : 12);

  return (
    <div>
      <Typography style={{ marginTop: "2rem" }} variant="h6">
        Details
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        This information pertains to the Job Division. Information here is
        important to tracking and performing work for the job.
      </Typography>
      <Grid container spacing={2}>
        {size(jobSourceOptions) > 1 && (
          <Grid item xxs={12} md={6}>
            <SelectFormField
              fullWidth
              name="field_job_source"
              label="Job Source"
              size="small"
              variant="outlined"
              disabled={isSubmitting}
              required
              options={jobSourceOptions}
            />
          </Grid>
        )}
        <Grid item xxs={12} md={lossWidth}>
          <DateFormField
            fullWidth
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            id="field_loss_date"
            label="Loss Date"
            name="field_loss_date"
            inputVariant="outlined"
            size="small"
            KeyboardButtonProps={{
              "aria-label": "change loss date",
            }}
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography variant="body1" color="textSecondary">
            ETA
          </Typography>
        </Grid>
        <Grid item xxs={12}>
          <Grid container spacing={3}>
            <Grid item xxs={4}>
              <DateFormField
                fullWidth
                disableToolbar
                variant="inline"
                format="MM/DD/YYYY"
                id="field_jd_eta_range.date"
                label="Scheduled Date"
                name="field_jd_eta_range.date"
                inputVariant="outlined"
                size="small"
                KeyboardButtonProps={{
                  "aria-label": "change loss date",
                }}
              />
            </Grid>
            <Grid item xxs={4}>
              <AutocompleteFormField
                name="field_jd_eta_range.value"
                label="From"
                disabled={isSubmitting}
                disableClearable
                options={times}
                variant="outlined"
                size="small"
                PopperComponent={CustomPopper}
              />
            </Grid>
            <Grid item xxs={4}>
              <AutocompleteFormField
                name="field_jd_eta_range.end_value"
                variant="outlined"
                size="small"
                label="To"
                disabled={isSubmitting}
                disableClearable
                PopperComponent={CustomPopper}
                options={times}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xxs={12} md={4}>
          <SelectFormField
            fullWidth
            name="field_job_division_type"
            label="Type"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            required
            options={types.map((type) => ({
              label: type.name,
              value: type.tid,
            }))}
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <SelectFormField
            fullWidth
            name="field_jd_status"
            label="Status"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            required
            options={statuses.map((status) => ({
              label: status.name,
              value: status.tid,
            }))}
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_reference_num"
            label="Reference Number"
            disabled={isSubmitting}
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid item xxs={12}>
          <SelectFormField
            fullWidth
            name="field_cause_select"
            label="Cause"
            size="small"
            variant="outlined"
            required
            disabled={isSubmitting}
            options={[
              { value: "freeze", label: "Freeze" },
              { value: "sewer_backup", label: "Sewer Backup" },
              { value: "plumbing", label: "Plumbing" },
              { value: "mold", label: "Mold" },
              { value: "storm", label: "Storm" },
              { value: "at_fault", label: "At Fault" },
              { value: "fire", label: "Fire" },
              { value: "pre_existing", label: "Pre-existing" },
              { value: "other", label: "Other" },
            ]}
          />
        </Grid>
        {values.field_cause_select === "other" && (
          <Grid item xxs={12}>
            <TextFormField
              fullWidth
              name="field_cause"
              label="Cause (Other)"
              variant="outlined"
              size="small"
              required
              disabled={isSubmitting}
              labelwidth={70}
            />
          </Grid>
        )}
        <Grid item xxs={6} md={3}>
          <TextFormField
            fullWidth
            name="field_run_time_text"
            label="Run Time"
            disabled={isSubmitting}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={6} md={3}>
          <SelectFormField
            fullWidth
            name="field_run_time_select"
            label="Duration"
            size="small"
            variant="outlined"
            disabled={isSubmitting}
            options={[
              { value: "Minutes", label: "Minutes" },
              { value: "Hours", label: "Hours" },
              { value: "Days", label: "Days" },
              { value: "Weeks", label: "Weeks" },
            ]}
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_mf_effected_level_qty"
            label="Effected Levels"
            disabled={isSubmitting}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12}>
          <Grid container spacing={3}>
            <Grid item xxs={values.field_mf_hw_wet ? 6 : 12}>
              <CheckboxFormField name="field_mf_hw_wet" label="Hardwood Wet" />
            </Grid>
            {values.field_mf_hw_wet && (
              <Grid item xxs={6}>
                <TextFormField
                  fullWidth
                  name="field_mf_hw_wet_sqft"
                  label="Hardwood Wet Sqft"
                  disabled={isSubmitting}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_details"
            label="Details"
            disabled={isSubmitting}
            autoComplete="off"
            variant="outlined"
            size="small"
            multiline
          />
        </Grid>
        {size(expensePrices) > 0 &&
          size(resourcePrices) > 0 &&
          size(laborTypePrices) > 0 &&
          size(laborActivityPrices) > 0 && (
            <>
              <Grid item xxs={12}>
                <Typography variant="subtitle1">Price Lists</Typography>
              </Grid>
              <PriceListField
                name="expense_types_price_list_pid"
                label="Expense Types"
                isSubmitting={isSubmitting}
                lists={expensePrices}
              />
              <PriceListField
                name="resources_price_list_pid"
                label="Resources"
                isSubmitting={isSubmitting}
                lists={resourcePrices}
              />
              <PriceListField
                name="service_types_price_list_pid"
                label="Labor Types"
                isSubmitting={isSubmitting}
                lists={laborTypePrices}
              />
              <PriceListField
                name="service_activities_price_list_pid"
                label="Labor Activities"
                isSubmitting={isSubmitting}
                lists={laborActivityPrices}
              />
            </>
          )}
        {size(tmTerms) > 0 && (
          <TMTermsDivisionFormComponent
            tmTerms={tmTerms}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            resourceCategories={tmTermsResourceCategories}
            laborTypes={tmTermsLaborTypes}
          />
        )}
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Details.propTypes = {};

export default Details;
