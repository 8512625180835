import styled, { keyframes } from "styled-components";

const subNotificationIncs = [2,3,4,5]; // Start at 2 since this is meant to select subsequent child items

const loop = subNotificationIncs.map((i) => (
  `& .notification:nth-child(${i}) {
      z-index: ${10 - i};
      margin-left: ${2 + (2 * (i - 1))}px;
  }`
)).join('');

export const StyledNotificationGroup = styled.div`
  position: relative;
  margin-bottom: 1rem;
  z-index: ${(props) => 100 - props.index}};

  &.expanded{
    z-index: 100;
  }

  .notification{
    position: relative;
    z-index: 10;
    margin-bottom: 0.25rem;
    box-shadow: var(--drop-shadow-sharp);
    transition: margin-top 0.25s var(--timing-quartic),
                margin-left 0.25s var(--timing-quartic),
                width 0.25s var(--timing-quartic),
                background 0.25s var(--timing-quartic);

    &:last-child {
      box-shadow: var(--drop-shadow);
    }
  }

  .notification + .notification{
    margin-top: calc(0px - 92px + 5px - 0.25rem);
    margin-left: 2px;
    border-radius: 0.25rem;
    background: var(--color-gray-darker);
  }

  ${loop}

  &.expanded .notification + .notification{
    margin-top: 0;
    margin-left: 0.75em;
    width: calc(100% - 0.75em);
    background: var(--color-gray-darkest);
  }

  &:not(.expanded) .notification.added ~ .notification,
  .notification:first-child{
    transition: none;
  }

/* Helps animate second notification into the top spot when dissmissed */
  // &.expanded .notification{
  //   top: 0px;
  //   left: 0px;
  //   width: 100%;
  // }

  .clear{
    position: absolute;
    top: -0.25rem;
    left: -0.25rem;
    z-index: 11;
    font-size: 0.8rem;
    border-radius: 0.75rem;
    background: var(--color-gray-light);
    z-index: -5;
    opacity: 0;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: opacity 0.25s;
  }

  &:hover .clear,
  &.expanded .clear{
    z-index: 11;
    opacity: 0.9;
  }


  & > .background{
    z-index: 0;
    background: var(--color-gray-lighter);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s var(--timing-quartic);
  }

  &.expanded > .background{
    opacity: 0.45;
    pointer-events: auto;
  }
`;



const slideInAnimation = keyframes`
  0% { transform: translateX(125%); }
  100% { transform: none; }
`;

const slideOutAnimation = keyframes`
  0% { transform: none; }
  100% { transform: translateX(125%); }
`;

export const StyledNotification = styled.div`
  width: 100%;
  height: 92px;
  display: flex;
  justify-content: left;
  background: var(--color-gray-darkest);
  padding: 0.75rem 0.75rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: var(--drop-shadow);

  &.added{
    animation-name: ${slideInAnimation};
    animation-duration: 500ms;
    animation-timing-function: var(--timing-quartic);
  }

  &.dismissed{
    animation-name: ${slideOutAnimation};
    animation-duration: 500ms;
    animation-timing-function: var(--timing-quartic);
    animation-fill-mode: forwards;
  }

  &,
  a,
  .MuiTypography-root{
    color: var(--color-primary-inverse);
  }

  .icon{
    padding-right: 0.25rem;
    max-width: 48px;

    > *{
      max-width: 100%;
    }
  }
  .body{
    min-width: 0; // Prevents item expanding out of the flexbox
    flex: 0 1 75%;
  }

  .preview-links{
    flex: 0 1 auto;
    padding-left: 0.5rem;
    height: 2.5rem;

    .preview-wrapper{ padding: 0; }

    .giphy-gif{
      max-width: 50px;
      height: auto !important;

      > div{
        max-width: 50px;
        height: auto !important;
      }
    }
    .giphy-caption {
      .giphy-title,
      .giphy-logo{ display: none; }
    }
  }

  .aside{
    padding-left: 1rem;
    flex: 1 0 auto;
    text-align: right;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-end;

    svg{ cursor: pointer; }

    .close{
      flex: 1 0 auto;
    }

    .proceed{
    }
  }
`;
