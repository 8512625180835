import { Badge, Tooltip } from "@mui/material";

const EventTitle = ({
  event,
}) => {

  let titleAttr = '';
  let title = event?.name ? (<>{event.name}</>) : '';
  if(event?.unread_posts_count > 0 || event?.is_new_event > 0){
    title = <Badge
      color="tertiary"
      variant="dot"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        [`&`]: {
          paddingLeft: "0.5rem",
        }
      }}
    >
      {title}
    </Badge>;
    if(event.is_current > 0){
      titleAttr = " and has unread posts";
    }
    else{
      titleAttr = "Event has unread posts";
    }
  }
  if(event?.is_current > 0){
    title = <Badge
      variant="dot"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        [`&`]: {
          paddingLeft: "0.5rem",
        },
        [`& > .MuiBadge-badge`]: {
          backgroundColor: "var(--color-green)",
        }
      }}
    >
      {title}
    </Badge>;
    titleAttr = "Event is happening now" + titleAttr;
  }

  if(title === ""){
    return;
  }
  return (
    <Tooltip title={titleAttr} placement="top">{title}</Tooltip>
  );
};

EventTitle.propTypes = {};

export default EventTitle;
