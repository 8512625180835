import React from "react";
import styled from "styled-components";
import constants from "../constants";

const imageIcon = `${process.env.PUBLIC_URL}/images/icons/icon-image--large.svg`;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${constants.colorGrayLight};
`;

const ImageLoader = (props) => {
  return (
    <Container>
      <img src={imageIcon} alt="Loading" />
    </Container>
  );
};

ImageLoader.propTypes = {};

export default ImageLoader;
