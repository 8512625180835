import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Popover,
  List,
  ListItem,
  ListSubheader,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { VscAccount } from "react-icons/vsc";
import { RiLogoutCircleLine } from "react-icons/ri";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { size } from "lodash";

import MenuItemLoader from "components/MenuItemLoader";
import Avatar from "common/Avatar";

import { postLogout, setClient } from "features/Auth/authSlice";

const User = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user.data);
  const currentClient = useSelector((state) => state.auth.current_client);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggingOut, setLoggingOut] = useState(false);

  const handleLogout = async () => {
    setLoggingOut(true);
    await dispatch(postLogout({}));
  };

  const handleChangeClient = (nid) => (e) => {
    dispatch(setClient(nid));
  };

  return (
    <>
      <div
        style={{
          marginLeft: "1rem",
          cursor: "pointer",
          height: 40,
        }}
      >
        <Avatar
          onClick={(e) => setAnchorEl(e.currentTarget)}
          title={`${user.first} ${user.last}`}
          link={user.pic}
        />
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: 260,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List disablePadding>
          <ListItem
            alignItems="flex-start"
            style={{
              background: "var(--color-gray-background)",
              borderBottom: "1px solid var(--color-gray-dark-alpha)",
            }}
          >
            <ListItemAvatar>
              <Avatar title={`${user.first} ${user.last}`} link={user.pic} />
            </ListItemAvatar>
            <ListItemText
              primary={`${user.first} ${user.last}`}
              secondary={user.title ? user.title : "No Title Set"}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/user");
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <VscAccount />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <Divider />
          {size(user.field_clients) > 1 && (
            <>
              <ListSubheader>Switch Organizations</ListSubheader>
              <ListItem button onClick={handleChangeClient(null)}>
                <ListItemIcon>
                  {!Boolean(currentClient) && (
                    <AiOutlineCheckCircle
                      style={{ color: "var(--color-green)" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="All Organizations" />
              </ListItem>
              {user.field_clients.map((client) => (
                <ListItem
                  button
                  key={client.nid}
                  onClick={handleChangeClient(client.nid)}
                >
                  <ListItemIcon>
                    {currentClient === client.nid && (
                      <AiOutlineCheckCircle
                        style={{ color: "var(--color-green)" }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={client.title} />
                </ListItem>
              ))}
              <Divider />
            </>
          )}
          <MenuItemLoader
            text="Logout"
            loading={loggingOut}
            onClick={handleLogout}
            large
          >
            <RiLogoutCircleLine />
          </MenuItemLoader>
        </List>
      </Popover>
    </>
  );
};

User.propTypes = {};

export default User;
