import {
  createSlice,
  createAsyncThunk,
  //   createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
//   import { formatWorkAreaFields } from "./utils";

// const getQueryParams = (params) => {
//   return `?${
//     typeof params === "string"
//       ? params.substring(1)
//       : querystring.stringify(params)
//   }`;
// };

const namespace = "equipment";

export const fetchEquipmentResources = createAsyncThunk(
  `${namespace}/fetchEquipmentResources`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/resources/daily_sheet/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const equipmentResourcesAdapter = createEntityAdapter({
  selectId: (resource) => resource.tid,
});

const equipmentSlice = createSlice({
  name: namespace,
  initialState: {
    resources: equipmentResourcesAdapter.getInitialState({
      loading: false,
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchEquipmentResources.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchEquipmentResources.fulfilled](state, { payload: resources }) {
      state.loading = false;
      state.error = null;
      equipmentResourcesAdapter.setAll(state.resources, resources);
    },
    [fetchEquipmentResources.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
//   const selectSelf = (state) => state;
//   export const getDailysheetsLoadingSelector = createDraftSafeSelector(
//     selectSelf,
//     (state) => state.dailysheets.loading
//   );

export const equipmentResourcesSelectors = equipmentResourcesAdapter.getSelectors(
  (state) => state.equipment.resources
);

export default equipmentSlice.reducer;
