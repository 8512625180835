import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { formatEstimate, formatEstimateLineItem } from "./utils";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "estimates";

export const fetchEstimates = createAsyncThunk(
  `${namespace}/fetchEstimates`,
  async ({ id, params }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/accounting/estimates/${id}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return {
        results: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEstimate = createAsyncThunk(
  `${namespace}/postEstimate`,
  async ({ params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/estimate`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully added Estimate`,
        })
      );

      return formatEstimate(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Estimate: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchEstimate = createAsyncThunk(
  `${namespace}/patchEstimate`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/estimate`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated Estimate`,
        })
      );

      return formatEstimate(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating Estimate: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteEstimate = createAsyncThunk(
  `${namespace}/deleteEstimate`,
  async ({ id }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/estimate`,
            },
          },
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully removed Estimate`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing Estimate: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEstimateLineItem = createAsyncThunk(
  `${namespace}/postEstimateLineItem`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/line_items`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_est_line_items",
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatEstimateLineItem(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchEstimateLineItem = createAsyncThunk(
  `${namespace}/patchEstimateLineItem`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/line_items`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatEstimateLineItem(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const estimatesAdapter = createEntityAdapter({
  selectId: (result) => result.nid,
});

const estimatesSlice = createSlice({
  name: namespace,
  initialState: estimatesAdapter.getInitialState({
    loading: false,
  }),
  reducers: {},
  extraReducers: {
    [fetchEstimates.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchEstimates.fulfilled](state, { payload: { results, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      estimatesAdapter.setAll(state, results);
    },
    [fetchEstimates.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postEstimate.pending](state, action) {},
    [postEstimate.fulfilled](state, { meta, payload: estimate }) {
      const { customer } = meta.arg;
      const params = {
        nid: estimate.nid,
        date: estimate.field_est_date,
        discount: estimate.field_est_discount,
        discount_percent: estimate.field_est_discount_percent,
        sub_total: estimate.field_est_subtotal,
        tax: estimate.field_est_tax,
        tax_rate: estimate.field_est_tax_rate,
        total: estimate.field_est_total,
        customer_title: customer,
      };

      estimatesAdapter.addOne(state, params);
    },
    [postEstimate.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchEstimate.pending](state, action) {},
    [patchEstimate.fulfilled](state, { meta, payload: estimate }) {
      const { id } = meta.arg;

      const changes = {
        date: estimate.field_est_date,
        discount: estimate.field_est_discount,
        discount_percent: estimate.field_est_discount_percent,
        sub_total: estimate.field_est_subtotal,
        tax: estimate.field_est_tax,
        tax_rate: estimate.field_est_tax_rate,
        total: estimate.field_est_total,
      };

      estimatesAdapter.updateOne(state, {
        id,
        changes: changes,
      });
    },
    [patchEstimate.rejected](state, action) {
      const { id, estimate } = action.meta.arg;

      estimatesAdapter.updateOne(state, { id, estimate });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [deleteEstimate.pending](state, action) {
      const { id } = action.meta.arg;
      estimatesAdapter.removeOne(state, id);
    },
    [deleteEstimate.fulfilled](state, action) {},
    [deleteEstimate.rejected](state, action) {
      const { estimate } = action.meta.arg;

      estimatesAdapter.addOne(state, estimate);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getEstimatesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.estimates.loading
);

export const getEstimatesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.estimates.pagination
);

export const estimatesSelectors = estimatesAdapter.getSelectors(
  (state) => state.estimates
);

export default estimatesSlice.reducer;
