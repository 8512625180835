import React, { useState, useEffect } from "react";
import { CalendarIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUpcomingEvents,
  upcomingPrivateEventsSelectors,
  upcomingPublicEventsSelectors,
} from "features/Events/eventsSlice";
import { Skeleton/*, Badge*/ } from "@mui/material";
import { Link } from "react-router-dom";
import Card from "common/Card";
import styled from "styled-components";
import constants from "components/constants";
import { formatTimeRange } from 'features/Events/utils';
import EventTitle from 'features/Events/components/EventTitle';

const defaultCover = `${process.env.PUBLIC_URL}/images/default-event.png`;

const Tabs = styled.div`
  display: flex;
  border-radius: 0.25em 0.25em 0 0;
  padding: 0.25rem 0.5rem 0;
  margin-bottom: 0.5rem;
  background: ${constants.colorGrayLight};
`;

const Tab = styled.div`
  padding: 0.5rem;
  background: ${(props) => (props.active ? "white" : "rgba(255, 255, 255, 0)")};
  cursor: pointer;
  // color: ${constants.colorGrayDark};
  border-radius: 0.25em 0.25em 0 0;
  justify-content: flex-end;
`;

const EventWidget = (props) => {
  const dispatch = useDispatch();
  const privateEvents = useSelector(upcomingPrivateEventsSelectors.selectAll);
  const publicEvents = useSelector(upcomingPublicEventsSelectors.selectAll);
  const loading = useSelector((state) => state.events.upcoming.loading);
  // const selectedClient = useSelector((state) => state.app.selectedClient.client);
  const currentEnvironment = useSelector((state) => state.hub.currentEnvironment);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if(currentEnvironment.nid/*selectedClient?.nid*/) {
      const promise = dispatch(fetchUpcomingEvents());

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, currentEnvironment/*selectedClient*/]);

  return (
    <Card className="p-4 my-2 grid-lg:my-4">
    {/* <div className="p-4 border-b border-gray-middle rounded-b-lg"> */}
      <main>
        {loading ? (
          <>
          <header className=" flex items-center">
            <div className="rounded-full h-6 w-6 mr-2">
              <CalendarIcon className="h-6 w-6 text-gray-dark" />
            </div>
            <h6 className="text-lg font-semibold text-gray-darkest">Events</h6>
          </header>
          <div className="mb-2 flex items-center">
            <div className="flex-1 mr-2">
              <Skeleton height={24} width={100} variant="text" />
              <Skeleton height={18} width={100} variant="text" />
              <Skeleton height={14} width={75} variant="text" />
            </div>
            <Skeleton
              height={70}
              width={70}
              variant="rectangle"
              className="rounded-lg"
            />
          </div>
          </>
        ) : (
          <>
          <Tabs>
              <header className=" flex items-center flex-[1_0_auto]">
                <div className="rounded-full h-6 w-6 mr-2">
                  <CalendarIcon className="h-6 w-6 text-gray-dark" />
                </div>
                <h6 className="text-lg font-semibold text-gray-darkest">Events</h6>
              </header>
              <Tab active={tabIndex === 0} onClick={(e) => setTabIndex(0)}>My Events</Tab>
              <Tab active={tabIndex === 1} onClick={(e) => setTabIndex(1)}>New Events</Tab>
            </Tabs>
            <EventList events={tabIndex === 0 ? privateEvents : publicEvents} />
          </>
        )}
      </main>
    {/* </div> */}
    </Card>
  );
};

const EventList = ({events}) => {
  return (
    <ul>
      {events.length > 0 ? (
        <>
        {events.slice(0, 3).map((event, i) => (
          <li className="mb-2" key={event.id}>
            <Link
              to={`/hub/events/${event.id}`}
              className="flex items-center text-black"
            >
              <div className="flex-1">
                <h6 className="mb-2 text-large font-semibold">
                  <EventTitle event={event} />
                </h6>
                <p>{formatTimeRange(event)}</p>
              </div>
              <div className="bg-gray-light h-[70px] w-[70px] ml-4 rounded-lg">
                <img
                  className={`w-full h-full rounded-lg object-cover object-center`}
                  src={event.cover_url ? event.cover_url : defaultCover}
                  alt="cover"
                />
              </div>
            </Link>
          </li>
        ))}
        {events.length > 3 && (
          <li className="mt-3 px-2 text-right">
            <Link
              to={`/hub/events`}
              className="italic text-blue-dark"
            >
              More Events...
            </Link>
          </li>
        )}
        </>
        ) : (
        <li className="mb-2">
          <div className="flex items-center text-gray-dark">
            <div className="flex-1">
              <h6 className="mt-4 px-2 text-large font-semibold">
                Nothing scheduled
              </h6>
            </div>
          </div>
        </li>
      )}
    </ul>
  );
};

EventWidget.propTypes = {};

export default EventWidget;
