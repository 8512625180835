import action from "../action";
import config from "../../config";

export const getChambers = (entity_id, params) =>
  action(
    "chambers",
    config.api_url,
    `entity/node/field_mf_chambers/${entity_id}`,
    params
  );

export const getChamber = (nid, params) =>
  action("chamber", config.api_url, `node/${nid}`, params);

export const postChamber = (params) =>
  action("chamber", config.api_url, `node`, params, "post");

export const patchChamber = (nid, params) =>
  action("chamber", config.api_url, `node/${nid}`, params, "patch");

export const getRooms = (entity_id, params) =>
  action(
    "rooms",
    config.api_url,
    `entity/node/field_mf_chamber_rooms/${entity_id}`,
    params
  );

export const getRoom = (nid, params) =>
  action("room", config.api_url, `node/${nid}`, params);

export const postRoom = (params) =>
  action("room", config.api_url, `node`, params, "post");

export const patchRoom = (nid, params) =>
  action("room", config.api_url, `node/${nid}`, params, "patch");

export const postStockToRoom = (params) =>
  action("roomStock", config.api_url, `entity/paragraph`, params, "post");

export const patchStockToRoom = (rid, params) =>
  action(
    "roomStock",
    config.api_url,
    `entity/paragraph/${rid}`,
    params,
    "patch"
  );
