import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "members";

export const fetchMembers = createAsyncThunk(
  `${namespace}/fetchMembers`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/members${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        members: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChildMembers = createAsyncThunk(
  `${namespace}/fetchChildMembers`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/child-members/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        members: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const membersAdapter = createEntityAdapter({
  selectId: (member) => member.member_nid,
});

const membersSlice = createSlice({
  name: namespace,
  initialState: membersAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    error: null,
  }),
  reducers: {},
  extraReducers: {
    [fetchMembers.pending](state) {
      state.loading = true;
    },
    [fetchMembers.fulfilled](state, { payload: { members, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      membersAdapter.setAll(state, members);
    },
    [fetchMembers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchChildMembers.pending](state) {
      state.loading = true;
    },
    [fetchChildMembers.fulfilled](state, { payload: { members, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      membersAdapter.setAll(state, members);
    },
    [fetchChildMembers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const membersSelectors = membersAdapter.getSelectors(
  (state) => state.members
);

// Custom selectors
const selectSelf = (state) => state;
export const getMembersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.members.loading
);

export const getMembersErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.members.error
);

export const getMembersPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.members.pagination
);

//   export const {
//     setAllDivisions,
//     setManyDivisions,
//     setOneDivision,
//     addOneDivision,
//     updateOneDivision,
//   } = membersSlice.actions;

export default membersSlice.reducer;
