import { toArray, times } from "lodash";
import constants from "../../components/constants";

export const formatLaborTypeRow = (data) => {
  const laborTypes = splitArrayIntoChunks(data, 4);
  const rows = [
    [
      {
        text: "Labor Classes (This may not be all labor classes)",
        margin: [10, 2, 10, 2],
        alignment: "center",
        style: "tableHeader",
        colSpan: 4,
      },
      {
        text: "",
        margin: [10, 2, 10, 2],
        alignment: "center",
        style: "tableHeader",
      },
      {
        text: "",
        margin: [10, 2, 10, 2],
        alignment: "center",
        style: "tableHeader",
      },
      {
        text: "",
        margin: [10, 2, 10, 2],
        alignment: "center",
        style: "tableHeader",
      },
    ],
  ];
  laborTypes.forEach((type) => {
    if (type.length === 4) {
      rows.push(
        type.map((item) => ({
          text: item.name,
          margin: [3, 3, 3, 3],
        }))
      );
    }
  });

  return rows;
};

export const splitArrayIntoChunks = (array, size) => {
  return Array(Math.ceil(array.length / size))
    .fill()
    .map((_, index) => index * size)
    .map((begin) => array.slice(begin, begin + size));
};

export const formatResourcesForPDF = (resources) => {
  const data = {};
  const categories = toArray(resources);
  categories.forEach((category) => {
    data[category.name] = [];
    const subCategories = toArray(category.sub_categories);
    subCategories.forEach((sub) => {
      const items = toArray(sub.items);
      items.forEach((item) => {
        data[category.name].push({
          sub: sub.name,
          name: item.name,
          increment: item.increment,
        });
      });
    });
  });

  return data;
};

const formatIncrement = (row) => {
  const value = increments[row.increment]
    ? increments[row.increment]
    : row.increment
    ? row.increment
    : "";

  if (
    value.toLowerCase() === "days" ||
    value.toLowerCase() === "day(s)" ||
    value.toLowerCase() === "day"
  ) {
    return "";
  }

  return value;
};

export const generateEquipmentDocumentDefinition = (
  data,
  jobName,
  locationName
) => {
  const content = [
    {
      columns: [
        // {
        //   image: dsLogo,
        //   width: 300,
        // },
        { text: "", width: "*" },
        {
          style: "table",
          margin: [0, 0, 0, 0],
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: `Project Number:   ${jobName ? jobName : ''}`,
                  // text: `Project Number:`,
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: `Project Location:   ${locationName ? locationName : ''}`,
                  // text: `Project Location:`,
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: "Completed By:",
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: "Date:",
                  margin: [5, 2, 10, 2],
                },
              ],
            ],
          },
        },
      ],
    },
  ];

  const resources = formatResourcesForPDF(data);
  for (let cat in resources) {
    const half = Math.ceil(resources[cat].length / 2);
    const firstHalf = resources[cat].splice(0, half);
    const secondHalf = resources[cat].splice(-half);
    content.push({ text: cat, style: "header" });
    content.push({
      columns: [
        {
          style: "table",
          table: {
            widths: ["*", 60],
            headerRows: 1,
            body: [
              [
                {
                  text: "ITEM DESCRIPTION",
                  margin: [5, 3, 10, 3],
                  alignment: "center",
                  style: "tableHeader",
                },
                {
                  text: "QTY",
                  margin: [5, 3, 10, 5],
                  alignment: "center",
                  style: "tableHeader",
                },
              ],
              ...firstHalf.map((row) => [
                {
                  text: row.name,
                  margin: [5, 3, 10, 5],
                },
                {
                  text: formatIncrement(row),
                  alignment: "right",
                  color: constants.colorGrayMedium,
                  margin: [5, 3, 3, 5],
                },
              ]),
            ],
          },
        },
        {
          style: "table",
          margin: [-1, 0, 0, 0],
          table: {
            widths: ["*", 60],
            headerRows: 1,
            body: [
              [
                {
                  text: "ITEM DESCRIPTION",
                  margin: [5, 3, 10, 3],
                  alignment: "center",
                  style: "tableHeader",
                },
                {
                  text: "QTY",
                  margin: [5, 3, 10, 5],
                  alignment: "center",
                  style: "tableHeader",
                },
              ],
              ...secondHalf.map((row) => [
                {
                  text: row.name,
                  margin: [10, 3, 10, 5],
                },
                {
                  text: formatIncrement(row),
                  alignment: "right",
                  color: constants.colorGrayMedium,
                  margin: [5, 3, 3, 5],
                },
              ]),
            ],
          },
        },
      ],
    });
  }

  return content;
};

export const generateServiceDocumentDefinition = (
  data,
  jobName,
  locationName
) => {
  const bodyData = [...formatLaborTypeRow(data)];
  const content = [
    {
      columns: [
        {
          style: "table",
          margin: [0, 0, 0, 0],
          width: "*",
          table: {
            widths: ["*", "*", "*", "*"],
            body: bodyData,
          },
        },
        {
          width: 250,
          style: "table",
          margin: [-1, 0, 0, 0],
          table: {
            widths: ["*"],
            body: [
              // [
              //   {
              //     image: dsLogo,
              //     width: 150,
              //     alignment: "center",
              //     margin: [0, 2, 0, 10],
              //     border: [false, false, false, false],
              //   },
              // ],
              [
                {
                  text: `Project Number:   ${jobName ? jobName : ''}`,
                  // text: `Project Number:`,
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: `Project Location:   ${locationName ? locationName : ''}`,
                  // text: `Project Location:`,
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: "Completed By:",
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: "Date:",
                  margin: [5, 2, 10, 2],
                },
              ],
            ],
          },
        },
      ],
    },
    {
      style: "table",
      margin: [0, 20, 0, 0],
      table: {
        widths: [
          "*",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          150,
        ],
        headerRows: 1,
        body: [
          [
            {
              text: "MANAGEMENT | SUPERVISION",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "LABOR TYPE",
              margin: [10, 3, 10, 3],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "START TIME",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "LUNCH START",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "LUNCH END",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "END TIME",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "TRAVEL",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "TOTAL",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "OT",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "SIGNATURE",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
          ],
          ...times(16, null).map((row) => [
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
          ]),
        ],
      },
    },
  ];

  return content;
};

export const generateDocumentDefinition = (
  jobName,
  locationName
) => {

  const content = [
    {
      columns: [
        // {
        //   image: dsLogo,
        //   width: 300,
        // },
        { text: "", width: "*" },
        {
          style: "table",
          margin: [0, 0, 0, 0],
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: `Project Number:   ${jobName ? jobName : ''}`,
                  // text: `Project Number:`,
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: `Project Location:   ${locationName ? locationName : ''}`,
                  // text: `Project Location:`,
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: "Completed By:",
                  margin: [5, 2, 10, 2],
                },
              ],
              [
                {
                  text: "Date:",
                  margin: [5, 2, 10, 2],
                },
              ],
            ],
          },
        },
      ],
    },
    {
      style: "table",
      margin: [0, 20, 0, 0],
      table: {
        widths: ["*", 150, "*", 150],
        headerRows: 1,
        body: [
          [
            {
              text: "EXPENSE",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "PURCHASED BY",
              margin: [10, 3, 10, 3],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "NOTES",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
            {
              text: "TOTAL",
              margin: [10, 3, 10, 5],
              alignment: "center",
              style: "tableHeader",
            },
          ],
          ...times(19, null).map((row) => [
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
            {
              text: "",
              margin: [10, 3, 10, 15],
            },
          ]),
        ],
      },
    },
  ];

  return content;
};

export const increments = {
  h: "Hour(s)",
  d: "Day(s)",
  e: "Each",
  df: "Day(s) + Fuel",
  mi: "MI(s)",
  p: "Pair(s)",
  b: "Box(es)",
  c: "Can(s)",
  g: "Gallon(s)",
  r: "Roll(s)",
  cs: "CS",
  s: "Set(s)",
  pk: "Pack(s)",
  bg: "Bag(s)",
  pc: "Per Cubic Ft.",
  dz: "Dozen",
  pd: "Pad(s)",
  sf: "Sqft.",
  pnd: "Pound(s)",
  cas: "Case(s)",
  lf: "Linear Ft.",
  wk: "Week(s)",
  mnth: "Month(s)",
  ft: "Per ft Per Day",
  mrkt: "Market Price",
};

export const formatPriceListsByOwnerType = (data) => {
  const lists = [];
  data.forEach((item) => {
    item.price_lists.forEach((list) => {
      lists.push({
        ...list,
        owner_type: item.owner_type ? item.owner_type : "global",
      });
    });
  });

  return lists;
};
