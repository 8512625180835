import action from "../action";
import config from "../../config";

export const postJobComment = (params, method) =>
  action("jobNotes", config.api_url, `comment`, params, method);

export const postWorkareaComment = (params) =>
  action("workareaNotes", config.api_url, `comment`, params, "post");

export const postMemberComment = (params) =>
  action("memberNotes", config.api_url, `comment`, params, "post");

export const patchMemberComment = (cid, params) =>
  action("memberNotes", config.api_url, `comment/${cid}`, params, "patch");

export const postCustomerComment = (params) =>
  action("customerNotes", config.api_url, `comment`, params, "post");

export const postComment = (params) =>
  action("comment", config.api_url, `comment`, params, "post");

// export const patchComment = (cid, params, method) =>
//   action("jobNotes", config.api_url, `comment/${cid}`, params, method);

export const patchComment = (cid, params) =>
  action("comment", config.api_url, `comment/${cid}`, params, "patch");

export const getComment = (cid, params) =>
  action("comment", config.api_url, `comment/${cid}`, params);
