import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
//   import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatField } from "../../utility";
import { fetchChamberRefresh } from "../Chamber/chamberSlice";
// import { addToast } from "../Toast/toastSlice";
import { formatRoomFields, formatRoomSurface } from "./utils";

//   const getQueryParams = (params) => {
//     return `?${
//       typeof params === "string"
//         ? params.substring(1)
//         : querystring.stringify(params)
//     }`;
//   };

const namespace = "room";

export const fetchRoom = createAsyncThunk(
  `${namespace}/fetchRoom`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      const room = formatRoomFields(response.data);
      const surfaces = response.data.field_mf_room_surfaces
        ? response.data.field_mf_room_surfaces?.map((surface) =>
            formatRoomSurface(surface)
          )
        : [];

      return { room, surfaces };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRoom = createAsyncThunk(
  `${namespace}/patchRoom`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/mf_room`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "positive",
      //     message: "Successfully updated room",
      //   })
      // );
      return formatRoomFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error updating room: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoomRefresh = createAsyncThunk(
  `${namespace}/fetchRoomRefresh`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      const room = formatRoomFields(response.data);
      const surfaces = response.data.field_mf_room_surfaces
        ? response.data.field_mf_room_surfaces?.map((surface) =>
            formatRoomSurface(surface)
          )
        : [];

      return { room, surfaces };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postRoomSurface = createAsyncThunk(
  `${namespace}/postRoomSurface`,
  async ({ id, params, chamber }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/mf_room_surfaces`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_mf_room_surfaces",
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchRoomRefresh(id));
      dispatch(fetchChamberRefresh(chamber.nid));

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "positive",
      //     message: "Successfully added room surface",
      //   })
      // );
      return formatRoomSurface(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error creating room surface: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRoomSurface = createAsyncThunk(
  `${namespace}/patchRoomSurface`,
  async (
    { id, params, room, chamber },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/mf_room_surfaces`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchRoomRefresh(room.nid));
      dispatch(fetchChamberRefresh(chamber.nid));

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "positive",
      //     message: "Successfully updated room surface",
      //   })
      // );
      return formatRoomSurface(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error updating room surface: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoomSurface = createAsyncThunk(
  `${namespace}/deleteRoomSurface`,
  async ({ id, room, chamber }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `${config.api_url}/entity/paragraph/${id}`,
        tokenConfig(getState)
      );

      dispatch(fetchRoomRefresh(room.nid));
      dispatch(fetchChamberRefresh(chamber.nid));

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "positive",
      //     message: "Successfully removed room surface",
      //   })
      // );
      return formatRoomSurface(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error removing room surface: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const surfacesAdapter = createEntityAdapter({
  selectId: (surface) => surface.id,
});

const roomSlice = createSlice({
  name: namespace,
  initialState: {
    error: null,
    loading: false,
    data: {},
    surfaces: surfacesAdapter.getInitialState({
      error: null,
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchRoom.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [fetchRoom.fulfilled](state, { payload: { room, surfaces } }) {
      state.loading = false;
      state.data = room;
      surfacesAdapter.setAll(state.surfaces, surfaces);
    },
    [fetchRoom.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchRoomRefresh.pending](state, action) {
      state.error = null;
    },
    [fetchRoomRefresh.fulfilled](state, { payload: { room, surfaces } }) {
      state.data = room;
      surfacesAdapter.setAll(state.surfaces, surfaces);
    },
    [fetchRoomRefresh.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postRoomSurface.fulfilled](state, { payload: surface }) {
      surfacesAdapter.addOne(state.surfaces, surface);
    },
    [postRoomSurface.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.surfaces.error = action.payload.message;
        } else {
          state.surfaces.error = action.error.message;
        }
      }
    },
    [deleteRoomSurface.pending](state, action) {
      const { id } = action.meta.arg;
      surfacesAdapter.removeOne(state.surfaces, id);
    },
    [deleteRoomSurface.rejected](state, action) {
      if (!action.meta.aborted) {
        const { surface } = action.meta.arg;
        surfacesAdapter.addOne(state.surfaces, surface);
        if (action.payload) {
          state.surfaces.error = action.payload.message;
        } else {
          state.surfaces.error = action.error.message;
        }
      }
    },
    [patchRoomSurface.pending](state, action) {
      const { id, params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      surfacesAdapter.updateOne(state.surfaces, {
        id,
        changes: { ...tempParams, is_saving: true },
      });
    },
    [patchRoomSurface.fulfilled](state, { meta, payload: surface }) {
      const { id } = meta.arg;
      surfacesAdapter.updateOne(state.surfaces, {
        id,
        changes: { is_saving: false },
      });
    },
    [patchRoomSurface.rejected](state, action) {
      const { id, surface } = action.meta.arg;

      surfacesAdapter.updateOne(state.surfaces, {
        id,
        changes: surface,
      });
      if (!action.meta.aborted) {
        if (action.payload) {
          state.surfaces.error = action.payload.message;
        } else {
          state.surfaces.error = action.error.message;
        }
      }
    },
    [patchRoom.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      state.data = {
        ...state.data,
        ...tempParams,
      };
    },
    [patchRoom.fulfilled](state, { payload: room }) {
      state.data = room;
    },
    [patchRoom.rejected](state, action) {
      const { original } = action.meta.arg;

      state.data = original;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.surfaces.error = action.payload.message;
        } else {
          state.surfaces.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getRoomDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.room.data
);

export const getRoomLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.room.loading
);

export const getRoomErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.room.error
);

export const surfacesSelectors = surfacesAdapter.getSelectors(
  (state) => state.room.surfaces
);

export default roomSlice.reducer;
