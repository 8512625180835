import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 1.25rem;
`;

const CardContent = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

CardContent.propTypes = {};

export default CardContent;
