import React, { useState } from "react";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
import { Drawer } from "@mui/material";

import { Container } from "./Styled";
import { Page, RigthAside } from "../../components/Styled";
import PageHeader from "./components/PageHeader";
import Alert from "../../features/Alert";
import {
  getRightAsideOpenSelector,
  getRightAsideDrawerOpenSelector,
} from "common/Layout/layoutSlice";

const Layout = ({
  title,
  name,
  icon,
  children,
  meta,
  subHeaderComponent,
  bottomNavigation,
  titleMenu,
  actions,
  loading,
  rightAside,
  leftAside,
  disablePageHeader,
  overflowYScroll,
  disableScroll,
  pageHeaderStyles,
  pageHeaderProps,
}) => {
  const browser = useSelector((state) => state.browser);
  const [layoutScrollTo, setLayoutScrollTo] = useState(null);
  const rightAsideOpen = useSelector(getRightAsideOpenSelector);
  const rightAsideDrawerOpen = useSelector(getRightAsideDrawerOpenSelector);

  const updateScroll = (!disableScroll ? (scrollTo) => {
    setLayoutScrollTo(scrollTo);
  }
  : null);

  const container =  <Container
    disablePageHeader={disablePageHeader}
    overflowYScroll={overflowYScroll}
    disableScroll={disableScroll}
    className={`layout-container ${bottomNavigation ? "has-bottom-navigation" : ""}`}
    scrollTo={layoutScrollTo}
    setLayoutScrollTo={setLayoutScrollTo}
  >
    {subHeaderComponent}
    <Alert margin="none" disableElevation disableRoundedCorners />
    {children?.type?.name && children.type.name !== 'DefaultLoader' ? (
      React.cloneElement(children, {
        updateScroll: updateScroll,
      })
    ) : (
      children
    )}
    {(bottomNavigation && browser.lessThan.medium) && bottomNavigation}
  </Container>;

  return (
    <>
      <Page
        style={{ background: "var(--color-gray-background)" }}
        className={bottomNavigation ? "has-bottom-navigation" : ""}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={meta} />
        </Helmet>
        {!disablePageHeader && (
          <PageHeader
            title={name}
            icon={icon}
            hasTitleMenu={Boolean(titleMenu)}
            titleMenu={titleMenu}
            loading={loading}
            pageHeaderStyles={pageHeaderStyles}
            pageHeaderProps={pageHeaderProps}
          >
            {actions}
          </PageHeader>
        )}
        {leftAside && browser.greaterThan.large ? (
          <div className="flex">
            {leftAside &&
              <div
                style={{
                  width: 310,
                  background: "white",
                  overflow: "auto",
                  zIndex: 7,
                  borderRight: "1px solid var(--color-gray-medium)",
                }}
              >
                {leftAside}
              </div>
            }
            {container}
          </div>
        ) : (
          container
        )}

      </Page>
      {rightAside &&
        (browser.greaterThan.large ? (
          <RigthAside className={rightAsideOpen || browser.greaterThan.extraLarge2 ? 'open' : ''}>
            {/* <BsArrowBarLeft className="toggle" /> */}
            {rightAside}
          </RigthAside>
        ) : (
          <Drawer
            open={rightAsideDrawerOpen}
            anchor="right"
            PaperProps={{
              sx: { width: "280px", maxWidth: "80%" },
            }}
          >
            {rightAside}
          </Drawer>
        )
      )}
    </>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {
  disablePageHeader: false,
  overflowYScroll: false,
};

export default Layout;
