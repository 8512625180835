import axios from "axios";
import conf from "config";
import getQueryParams from "utility/getQueryParams";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const conversationsAPI = {
  addConversation: async ({ params, config }) => {
    return instance.post(`/threads`, params, config);
  },
  findConversation: async ({ params, config }) => {
    return instance.post(`/threads/find`, params, config);
  },
  addMessage: async ({ params, config }) => {
    return instance.post(`/messages`, params, config);
  },
  addReaction: async ({ id, params, config }) => {
    return instance.patch(`/messages/${id}/add-reaction`, params, config);
  },
  removeReaction: async ({ id, config }) => {
    return instance.patch(`/messages/${id}/remove-reaction`, {}, config);
  },
  getConversation: async ({ id, config }) => {
    return instance.get(`/threads/${id}`, config);
  },
  getMessages: async ({ id, params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/threads/${id}/messages${queryParams}`, config);
  },
  getConversations: async ({ params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/threads${queryParams}`, config);
  },
  deleteConversation: async ({ id, config }) => {
    return instance.delete(`/threads/${id}`, config);
  },
  addPreview: async ({ params, config }) => {
    return instance.post(`/scrape`, params, config);
  },
  deleteMessage: async ({ id, config }) => {
    return instance.delete(`/messages/${id}`, config);
  },
};

export default conversationsAPI;
