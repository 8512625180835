import axios from "axios";
import conf from "config";
import getQueryParams from "utility/getQueryParams";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  addGroup: async ({ params, config }) => {
    return instance.post(`/groups`, params, config);
  },
  getGroups: async ({ params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/groups${queryParams}`, config);
  },
  getGroup: async ({ id, config }) => {
    return instance.get(`/groups/${id}`, config);
  },
  getPosts: async ({ id, params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/groups/${id}/posts${queryParams}`, config);
  },
  inviteUser: async ({ id, params, config }) => {
    return instance.patch(`/groups/${id}/add-users`, params, config);
  },
  removeUser: async ({ id, params, config }) => {
    return instance.patch(`/groups/${id}/remove-users`, params, config);
  },
  addAdmin: async ({ id, params, config }) => {
    return instance.patch(`/groups/${id}/add-admin`, params, config);
  },
  removeAdmin: async ({ id, params, config }) => {
    return instance.patch(`/groups/${id}/remove-admin`, params, config);
  },
  updateGroup: async ({ id, params, config }) => {
    return instance.patch(`/groups/${id}`, params, config);
  },
  uploadAttachment: async ({ id, params, config }) => {
    return instance.patch(`/groups/${id}/upload-attachment`, params, config);
  },
  downloadAttachment: async ({ id, config }) => {
    return instance.get(`/attachments/${id}`, config);
  },
  deleteGroup: async ({ id, config }) => {
    return instance.delete(`/groups/${id}`, config);
  },
  deleteAttachment: async ({ id, config }) => {
    return instance.delete(`/attachments/${id}`, config);
  },
};

export default API;
