import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "customers";

export const fetchCustomers = createAsyncThunk(
  `${namespace}/fetchCustomers`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customers${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        customers: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChildCustomers = createAsyncThunk(
  `${namespace}/fetchChildCustomers`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/child-customers/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        customers: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const customersAdapter = createEntityAdapter({
  selectId: (customer) => customer.customer_nid,
});

const customersSlice = createSlice({
  name: namespace,
  initialState: customersAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    error: null,
  }),
  reducers: {},
  extraReducers: {
    [fetchCustomers.pending](state) {
      state.loading = true;
    },
    [fetchCustomers.fulfilled](state, { payload: { customers, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      customersAdapter.setAll(state, customers);
    },
    [fetchCustomers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchChildCustomers.pending](state) {
      state.loading = true;
    },
    [fetchChildCustomers.fulfilled](
      state,
      { payload: { customers, pagination } }
    ) {
      state.loading = false;
      state.pagination = pagination;
      customersAdapter.setAll(state, customers);
    },
    [fetchChildCustomers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const customersSelectors = customersAdapter.getSelectors(
  (state) => state.customers
);

// Custom selectors
const selectSelf = (state) => state;
export const getCustomersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.customers.loading
);

export const getCustomersErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.customers.error
);

export const getCustomersPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.customers.pagination
);

//   export const {
//     setAllDivisions,
//     setManyDivisions,
//     setOneDivision,
//     addOneDivision,
//     updateOneDivision,
//   } = membersSlice.actions;

export default customersSlice.reducer;
