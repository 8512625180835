import { getTaxonomyFields, formatField } from "../../utility";

export const formatInventoryItem = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_ii_id: formatField(d, "field_ii_id"),
    field_ii_type: getTaxonomyFields(d, "field_ii_type"),
    field_ii_type_text: formatField(d, "field_ii_type_text"),
    field_ii_type_category: formatField(d, "field_ii_type_category"),
    field_ii_type_category_text: formatField(d, "field_ii_type_category_text"),
    field_ii_type_subcategory: formatField(d, "field_ii_type_subcategory"),
    field_ii_type_subcategory_text: formatField(
      d,
      "field_ii_type_subcategory_text"
    ),
    field_ii_actual_cost: formatField(d, "field_ii_actual_cost"),
    field_ii_estimate_cost: formatField(d, "field_ii_estimate_cost"),
    field_ii_is_rental: formatField(d, "field_ii_is_rental"),
    field_ii_rental_price_per_day: formatField(
      d,
      "field_ii_rental_price_per_day"
    ),
    field_ii_make: formatField(d, "field_ii_make"),
    field_ii_model: formatField(d, "field_ii_model"),
    field_ii_serial_number: formatField(d, "field_ii_serial_number"),
    field_ii_warehouse_location: formatField(d, "field_ii_warehouse_location"),
    field_ii_notes: formatField(d, "field_ii_notes"),
    field_ii_barcode_image: formatField(d, "field_ii_barcode_image"),
    field_ii_in_use: formatField(d, "field_ii_in_use"),
    field_ii_mntnce_schdls: d?.field_ii_mntnce_schdls
      ? d?.field_ii_mntnce_schdls.map((item) => formatMaintenanceSchedule(item))
      : [],
  };
};

export const formatMaintenanceRecord = (d) => {
  return {
    id: formatField(d, "id"),
    revision_id: formatField(d, "revision_id"),
    field_ii_mr_date: formatField(d, "field_ii_mr_date"),
    field_ii_mr_notes: formatField(d, "field_ii_mr_notes"),
    field_ii_mr_title: formatField(d, "field_ii_mr_title"),
    field_ii_mr_user: formatField(d, "field_ii_mr_user"),
  };
};

export const formatMaintenanceSchedule = (d) => {
  return {
    id: formatField(d, "id"),
    revision_id: formatField(d, "revision_id"),
    field_ii_ms_interval: formatField(d, "field_ii_ms_interval"),
    field_ii_ms_next_mntnce_date: formatField(
      d,
      "field_ii_ms_next_mntnce_date"
    ),
    field_ii_ms_title: formatField(d, "field_ii_ms_title"),
  };
};
