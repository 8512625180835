import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import { parseHubEnvParams } from "features/Hub/hubSlice.js";

import resourcesAPI from "./resourcesAPI";

const namespace = "resources";

export const fetchDirectories = createAsyncThunk(
  "resources/fetchDirectories",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      // const clientNid = getState().app?.selectedClient?.client?.nid;
      const params = {};
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.getDirectories({
        params: params/*{
          client_nid: clientNid,
        }*/,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDirectory = createAsyncThunk(
  "resources/fetchDirectory",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.getDirectory({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postDirectory = createAsyncThunk(
  "resources/postDirectory",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.addDirectory({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddAdmin = createAsyncThunk(
  "resources/patchAddAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.addAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveAdmin = createAsyncThunk(
  "resources/patchRemoveAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.removeAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchUploadAttachment = createAsyncThunk(
  "resources/patchUploadAttachment",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.uploadAttachment({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchDirectory = createAsyncThunk(
  "resources/patchDirectory",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.updateDirectory({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInviteUser = createAsyncThunk(
  "resources/patchInviteUser",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.inviteUser({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDirectory = createAsyncThunk(
  "resources/deleteDirectory",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await resourcesAPI.deleteDirectory({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  "resources/deleteAttachment",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      await resourcesAPI.deleteAttachment({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return id;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const resourcesAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const resourcesSlice = createSlice({
  name: namespace,
  initialState: resourcesAdapter.getInitialState({
    loading: false,
    error: null,
    can_create: false,
    directories: resourcesAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    attachments: resourcesAdapter.getInitialState({}),
    directory: {
      data: {},
      loading: false,
      error: null,
    },
  }),
  reducers: {
    resetResources: (state, action) => {
      state.directories.loading = true;
      state.directories.error = null;
      state.can_create = false;
      resourcesAdapter.removeAll(state.directories);
      state.pagination = {
        last_page: 1,
        current_page: 1,
        total: 1,
      };
    },
    resetResource: (state, action) => {
      state.directory.loading = true;
      state.directory.error = null;
      state.directory.data = {};
    },
  },
  extraReducers: {
    [fetchDirectories.pending](state, action) {
      state.directories.loading = true;
      state.directories.error = null;
      state.can_create = false;
      resourcesAdapter.removeAll(state.directories);
    },
    [fetchDirectories.fulfilled](state, { payload: directories }) {
      state.directories.loading = false;
      state.directories.error = null;
      state.can_create = directories.can_create;
      resourcesAdapter.setAll(state.directories, directories.directories);
    },
    [fetchDirectories.rejected](state, action) {
      state.directories.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directories.error = action.payload.message;
        } else {
          state.directories.error = action.error.message;
        }
      }
    },
    [postDirectory.fulfilled](state, { payload: directory }) {
      resourcesAdapter.addOne(state.directories, directory);
    },
    [deleteAttachment.fulfilled](state, { payload: id }) {
      resourcesAdapter.removeOne(state.attachments, id);
    },
    [fetchDirectory.pending](state, action) {
      state.directory.loading = true;
      state.directory.error = null;
      state.directory.data = {};
    },
    [fetchDirectory.fulfilled](state, { payload: directory }) {
      state.directory.loading = false;
      state.directory.error = null;
      state.directory.data = directory;
      resourcesAdapter.setAll(state.attachments, directory.attachments);
    },
    [fetchDirectory.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchAddAdmin.fulfilled](state, { payload: directory }) {
      resourcesAdapter.updateOne(state, {
        id: directory.id,
        changes: directory,
      });
      state.directory.data = directory;
      state.directory.error = null;
    },
    [patchAddAdmin.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchRemoveAdmin.fulfilled](state, { payload: directory }) {
      resourcesAdapter.updateOne(state, {
        id: directory.id,
        changes: directory,
      });
      state.directory.data = directory;
      state.directory.error = null;
    },
    [patchRemoveAdmin.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchUploadAttachment.fulfilled](state, { payload: directory }) {
      resourcesAdapter.setAll(state.attachments, directory.attachments);
      state.directory.error = null;
    },
    [patchUploadAttachment.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchDirectory.fulfilled](state, { payload: directory }) {
      state.directory.data = directory;
      state.directory.error = null;
    },
    [patchDirectory.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchInviteUser.fulfilled](state, { payload: directory }) {
      state.directory.data = directory;
      state.directory.error = null;
    },
    [patchInviteUser.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getResourcesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.resources.loading
);

export const getResourcesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.resources.pagination
);

export const resourcesSelectors = resourcesAdapter.getSelectors(
  (state) => state.resources
);

export const directoriesSelectors = resourcesAdapter.getSelectors(
  (state) => state.resources.directories
);

export const attachmentsSelectors = resourcesAdapter.getSelectors(
  (state) => state.resources.attachments
);

export const { resetResources, resetResource } = resourcesSlice.actions;

export default resourcesSlice.reducer;
