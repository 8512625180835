import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import Button from "common/Button";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";

import {
  customerStatusSelectors,
  provincesSelectors,
  statesSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import { fetchPhxClientUsers } from "features/Users/usersSlice";

const Information = ({
  values,
  isSubmitting,
  value,
  handleNext,
  setFieldValue,
}) => {
  const dispatch = useDispatch();
  const statuses = useSelector(customerStatusSelectors.selectAll);
  const states = useSelector(statesSelectors.selectAll);
  const provinces = useSelector(provincesSelectors.selectAll);
  const clientNid = values.field_phoenix_client;

  useEffect(() => {
    if(clientNid){
      const promise = dispatch(
        fetchPhxClientUsers({ "filter[phx_client_nids]": clientNid })
      );

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, clientNid]);

  return (
    <div hidden={value !== 0}>
      <Typography variant="h6">Information</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Create a new Customer Location to grant access to job assignment,
        compliance tracking, and other system based benefits.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="title"
            label="Name"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <SelectFormField
            fullWidth
            name="field_cl_status"
            label="Status"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            options={statuses.map((obj) => ({
              value: obj.machine_name,
              label: obj.label,
            }))}
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <TextFormField
            fullWidth
            name="field_square_feet"
            label="Location Size"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_street_address.address_line1"
            label="Address"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            onChange={(e) => {
              setFieldValue(
                "field_street_address.address_line1",
                e.target.value
              );
              if (values.billing_same_as_street) {
                setFieldValue(
                  "field_billing_address.address_line1",
                  e.target.value
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_street_address.address_line2"
            label="Address 2"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            onChange={(e) => {
              setFieldValue(
                "field_street_address.address_line2",
                e.target.value
              );
              if (values.billing_same_as_street) {
                setFieldValue(
                  "field_billing_address.address_line2",
                  e.target.value
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={4}>
          <TextFormField
            fullWidth
            name="field_street_address.locality"
            label="City"
            required
            disabled={isSubmitting}
            variant="outlined"
            size="small"
            onChange={(e) => {
              setFieldValue("field_street_address.locality", e.target.value);
              if (values.billing_same_as_street) {
                setFieldValue("field_billing_address.locality", e.target.value);
              }
            }}
          />
        </Grid>
        <Grid item xxs={4}>
          <SelectFormField
            fullWidth
            name="field_street_address.administrative_area"
            label="State"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            options={
              values.field_street_address.country_code === "CA"
                ? provinces
                : states
            }
            onChange={(e) => {
              setFieldValue(
                "field_street_address.administrative_area",
                e.target.value
              );
              if (values.billing_same_as_street) {
                setFieldValue(
                  "field_billing_address.administrative_area",
                  e.target.value
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={4}>
          <TextFormField
            fullWidth
            name="field_street_address.postal_code"
            label="Zip Code"
            required
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            onChange={(e) => {
              setFieldValue("field_street_address.postal_code", e.target.value);
              if (values.billing_same_as_street) {
                setFieldValue(
                  "field_billing_address.postal_code",
                  e.target.value
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={4}>
          <SelectFormField
            fullWidth
            name="field_street_address.country_code"
            label="Country"
            margin="normal"
            required
            variant="outlined"
            disabled={isSubmitting}
            options={[
              {
                label: "USA",
                value: "US",
              },
              {
                label: "Canada",
                value: "CA",
              },
            ]}
            onChange={(e) => {
              setFieldValue("field_street_address.administrative_area", "");
              setFieldValue(
                "field_street_address.country_code",
                e.target.value
              );
            }}
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_note"
            label="Notes"
            variant="outlined"
            size="small"
            multiline
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Information.propTypes = {};

export default Information;
