import React, { useEffect } from "react";
import { ViewGridIcon, ViewGridAddIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGroups,
  groupsSelectors,
} from "features/Groups/groupsSlice";
import { Skeleton, Badge } from "@mui/material";
import Card from "common/Card";
import { Link } from "react-router-dom";
import TruncateReactMarkdown from 'common/TruncateReactMarkdown';
import { HubIconButton } from "features/Hub/Styled";
import Create from "features/Groups/forms/Create";

const defaultCover = `${process.env.PUBLIC_URL}/images/default-group.png`;

const GroupsWidget = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.groups.loading);
  const groups = useSelector(groupsSelectors.selectAll);
  // const selectedClient = useSelector((state) => state.app.selectedClient.client);
  const currentEnvironment = useSelector((state) => state.hub.currentEnvironment);
  const canCreate = useSelector((state) => state.groups.can_create);

  useEffect(() => {
    if(currentEnvironment?.nid/*selectedClient?.nid*/) {
      const promise = dispatch(fetchGroups());

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, currentEnvironment/*selectedClient*/]);

  return (
    <Card className="p-4 my-2 grid-lg:my-4">
      <header className="flex items-center">
        <div className="rounded-full h-6 w-6 mr-2">
          <ViewGridIcon className="h-6 w-6 text-gray-dark" />
        </div>
        <h6 className="text-lg font-semibold text-gray-darkest">Groups</h6>
      {canCreate &&
        <div className="flex-1 text-right" >
          <HubIconButton>
            <Create>
              <ViewGridAddIcon />
            </Create>
          </HubIconButton>
        </div>
      }
      </header>
      <main>
        {loading ? (
          <div className="mb-2 flex items-center">
            <div className="flex-1 mr-2">
              <Skeleton height={24} width={100} variant="text" />
              <Skeleton height={18} width={100} variant="text" />
              <Skeleton height={14} width={75} variant="text" />
            </div>
            <Skeleton
              height={70}
              width={70}
              variant="rectangle"
              className="rounded-lg"
            />
          </div>
        ) : (
          <ul>
            {groups.slice(0, 3).map((group) => (
              <li className="mb-2" key={group.id}>
                <Link
                  to={`/hub/groups/${group.id}`}
                  className="flex items-center text-black"
                >
                  <div className="flex-1">
                    <h6 className="mb-2 text-large font-semibold">
                      {group.unread_posts_count > 0 || group.is_new_group > 0 ? (
                        <Badge
                          color="tertiary"
                          variant="dot"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <div className="inline-block pl-2">{group.name}</div>
                        </Badge>
                      ) : (
                        group.name
                      )}
                    </h6>
                    <TruncateReactMarkdown lines={2} noMarkdown >
                      <p className="text-md whitespace-pre-wrap">{group.description}</p>
                    </TruncateReactMarkdown>
                  </div>
                  <div className="bg-gray-light h-[70px] w-[70px] ml-4 rounded-lg">
                    <img
                      className="w-full h-full rounded-lg object-cover object-center border-solid border border-gray-dark"
                      src={group.banner_url ? group.banner_url : defaultCover}
                      alt="cover"
                    />
                  </div>
                </Link>
              </li>
            ))}
            {groups.length === 0 && (
              <li className="mt-3 px-2">
                No groups yet
                {/* , <Link
                  to={`/hub/groups/create`}
                  className="italic font-bold text-blue-dark"
                >
                  find some folks to share with.
                </Link> */}
              </li>
            )}
            {groups.length > 3 && (
              <li className="mt-3 px-2 text-right">
                <Link
                  to={`/hub/groups`}
                  className="italic text-blue-dark"
                >
                  More Groups...
                </Link>
              </li>
            )}
          </ul>
        )}
      </main>
    </Card>
  );
};

GroupsWidget.propTypes = {};

export default GroupsWidget;
