import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import postsAPI from "./postsAPI";
import { parseHubEnvParams } from "features/Hub/hubSlice.js";

const namespace = "posts";

export const fetchCompanyPosts = createAsyncThunk(
  "posts/fetchCompanyPosts",
  async ({ params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      // params.client_nid = getState().app?.selectedClient?.client?.nid;
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.getCompanyPosts({
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserPosts = createAsyncThunk(
  "posts/fetchUserPosts",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      // const clientNid = getState().app?.selectedClient?.client?.nid;
      const params = {};
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.getUserPosts({
        id,
        params: params/*{
          client_nid: clientNid,
        }*/,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPinnedPosts = createAsyncThunk(
  "posts/fetchPinnedPosts",
  async ({ config }, { rejectWithValue, getState, dispatch }) => {
    try {
      // const clientNid = getState().app?.selectedClient?.client?.nid;
      const params = {};
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.getPinnedPosts({
        params: params/*{
          client_nid: clientNid,
        }*/,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPost = createAsyncThunk(
  "posts/fetchPost",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.getPost({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddReaction = createAsyncThunk(
  "posts/patchAddReaction",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.addReaction({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveReaction = createAsyncThunk(
  "posts/patchRemoveReaction",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.removeReaction({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddPinned = createAsyncThunk(
  "posts/patchAddPinned",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.addPinned({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemovePinned = createAsyncThunk(
  "posts/patchRemovePinned",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.removePinned({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postPost = createAsyncThunk(
  "posts/postPost",
  async (params, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await postsAPI.addPost({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Socket-ID": params?.socketId,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePost = createAsyncThunk(
  "posts/deletePost",
  async (params, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      await postsAPI.deletePost({
        id: params.id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return params;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const postsAdapter = createEntityAdapter({
  selectId: (result) => result.id,
  sortComparer: (a, b) => b.id - a.id,
});

const postsSlice = createSlice({
  name: namespace,
  initialState: postsAdapter.getInitialState({
    loading: true,
    error: null,
    post: {
      data: {},
      loading: true,
      error: null,
    },
    pinned: postsAdapter.getInitialState({
      loading: true,
      error: null,
    }),
    pagination: {
      last_page: 1,
      current_page: 1,
      total: 1,
    },
  }),
  reducers: {
    setAllPosts: (state, { payload: posts }) => {
      state.loading = false;
      state.error = null;

      postsAdapter.setAll(state, posts.data);
      state.pagination = {
        last_page: posts.last_page,
        current_page: posts.current_page,
        total: posts.total,
      };
    },
    resetPosts: (state, action) => {
      state.loading = true;
      state.error = null;
      postsAdapter.removeAll(state);
    },
    resetFeaturedPosts: (state, action) => {
      state.pinned.loading = true;
      state.pinned.error = null;
      postsAdapter.removeAll(state.pinned);
    },
    resetPost: (state, action) => {
      state.post.loading = true;
      state.post.error = null;
      state.post.data = {};
    },
  },
  extraReducers: {
    [fetchCompanyPosts.pending](state, action) {
      state.loading = true;
      state.error = null;
      postsAdapter.removeAll(state);
    },
    [fetchCompanyPosts.fulfilled](state, { payload: posts }) {
      state.loading = false;
      state.error = null;
      state.pagination = {
        last_page: posts.last_page,
        current_page: posts.current_page,
        total: posts.total,
      };
      postsAdapter.setAll(state, posts.data);
    },
    [fetchCompanyPosts.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.errors;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchUserPosts.pending](state, action) {
      state.loading = true;
      state.error = null;
      postsAdapter.removeAll(state);
    },
    [fetchUserPosts.fulfilled](state, { payload: posts }) {
      state.loading = false;
      state.error = null;
      state.pagination = {
        last_page: posts.last_page,
        current_page: posts.current_page,
        total: posts.total,
      };
      postsAdapter.setAll(state, posts.data);
    },
    [fetchUserPosts.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchPinnedPosts.pending](state, action) {
      state.pinned.loading = true;
      state.pinned.error = null;
      postsAdapter.removeAll(state.pinned);
    },
    [fetchPinnedPosts.fulfilled](state, { payload: posts }) {
      state.pinned.loading = false;
      state.pinned.error = null;
      postsAdapter.setAll(state.pinned, posts);
    },
    [fetchPinnedPosts.rejected](state, action) {
      state.pinned.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.pinned.error = action.payload.message;
        } else {
          state.pinned.error = action.error.message;
        }
      }
    },
    [patchAddReaction.fulfilled](state, { payload: post }) {
      state.post.data.reactions = post.reactions;
      state.post.data.reactions_summary = post.reactions_summary;
      state.post.data.my_reaction = post.my_reaction;
      postsAdapter.updateOne(state, { id: post.id, changes: post });
    },
    [patchRemoveReaction.fulfilled](state, { payload: post }) {
      state.post.data.reactions = post.reactions;
      state.post.data.reactions_summary = post.reactions_summary;
      state.post.data.my_reaction = post.my_reaction;
      postsAdapter.updateOne(state, { id: post.id, changes: post });
    },
    [patchAddPinned.fulfilled](state, { payload: post }) {
      postsAdapter.addOne(state.pinned, post);
      postsAdapter.updateOne(state, { id: post.id, changes: post });
    },
    [patchRemovePinned.fulfilled](state, { payload: post }) {
      postsAdapter.removeOne(state.pinned, post.id);
      postsAdapter.updateOne(state, { id: post.id, changes: post });
    },
    [fetchPost.pending](state, { payload: post }) {
      state.post.loading = true;
      state.post.error = null;
      state.post.data = {};
      postsAdapter.removeAll(state);
    },
    [fetchPost.fulfilled](state, { payload: post }) {
      state.post.loading = false;
      state.post.error = null;
      state.post.data = post;
      postsAdapter.setAll(state, post.posts);
    },
    [fetchPost.rejected](state, action) {
      state.post.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.post.error = action.payload.message;
        } else {
          state.post.error = action.error.message;
        }
      }
    },
    [postPost.fulfilled](state, { payload: post }) {
      if (post.postable_type.includes("Post") && state.ids.includes(post.postable.id)) {
        // state.post.data = {
        //   ...state.post.data,
        //   post_count: state.post.data.post_count + 1,
        // };
        postsAdapter.updateOne(state, { id: post.postable.id, changes: post.postable });
      }
      else{
        postsAdapter.addOne(state, post);
      }
    },
    [deletePost.fulfilled](state, { payload: {id, postable} }) {
      if (postable && !postable.postable_type.includes("Post")) {
        const _postable = {
          ...postable,
          post_count: postable.post_count - 1,
          posts: postable.posts.filter((p) => p.id !== id),
        };
        postsAdapter.updateOne(state, { id: _postable.id, changes: _postable });
      }
      postsAdapter.removeOne(state, id);
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getPostsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.posts.loading
);

export const getPostsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.posts.pagination
);

export const postsSelectors = postsAdapter.getSelectors((state) => state.posts);
export const pinnedPostsSelectors = postsAdapter.getSelectors(
  (state) => state.posts.pinned
);

export const { resetPost, resetPosts, setAllPosts, resetFeaturedPosts, updatePost } =
  postsSlice.actions;

export default postsSlice.reducer;
