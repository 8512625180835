import action from "../action";
import config from "../../config";

export const getAimeCarriers = (refresh = 0, params) =>
  action(
    "aimeCarriers",
    config.api_url,
    `rest/askaime/get-carriers/${refresh}`,
    params
  );

export const postAimeProcessFile = (params) =>
  action(
    "aimeProcessFile",
    config.api_url,
    `rest/askaime/process-document`,
    params,
    "post"
  );

export const postAimeAnalyzeFile = (params) =>
  action(
    "aimeAnalyzeFile",
    config.api_url,
    `rest/askaime/run-doc-analysis`,
    params,
    "post"
  );

export const postAimeFinalizeReport = (params) =>
  action(
    "aimeFinalizeReport",
    config.api_url,
    `rest/askaime/finalize-report`,
    params,
    "post"
  );
