import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, LinearProgress, IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  batchImportSelectors,
  postBatchImportProcess,
  removeOneImportBatch,
} from "./batchSlice";
import { RiUploadCloud2Line } from "react-icons/ri";

import { ItemContainer, ItemBarContainer } from "./Styled";

const BatchImportItem = ({ id }) => {
  const dispatch = useDispatch();
  const batch = useSelector((state) =>
    batchImportSelectors.selectById(state, id)
  );

  useEffect(() => {
    if (batch.batch_url && !batch.error) {
      dispatch(
        postBatchImportProcess({
          url: batch.batch_url,
          params: batch.persisted,
          persisted: batch.persisted,
        })
      );
    }

    if (!batch.batch_url) {
      dispatch(removeOneImportBatch(id));
    }
  }, [batch, dispatch, id]);

  return (
    <ItemContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {batch.error ? (
            <ErrorOutlineIcon color="primary" style={{ marginRight: "1rem" }} />
          ) : (
            <RiUploadCloud2Line
              style={{ marginRight: "1rem", color: "var(--color-secondary)" }}
            />
          )}
          <div>
            <Typography variant="body1">{batch.title}</Typography>
            <Typography variant="body1">
              {batch.error ? `Error: ${batch.error}` : batch.message}
            </Typography>
          </div>
        </div>
        {batch.error && (
          <IconButton
            onClick={() => dispatch(removeOneImportBatch(id))}
            size="small"
          >
            <HighlightOffIcon size="small" />
          </IconButton>
        )}
      </div>
      {!batch.error && (
        <ItemBarContainer>
          <LinearProgress color="secondary" variant="determinate" value={batch.percentage} />
        </ItemBarContainer>
      )}
    </ItemContainer>
  );
};

BatchImportItem.propTypes = {};

export default BatchImportItem;
