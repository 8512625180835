import React, { useState, useEffect, useCallback, useMemo } from "react";
import { DialogForm } from "common/Dialog";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Typography,
  List,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import styled from "styled-components";
import constants from "components/constants";
import { RiUserLocationFill } from "react-icons/ri";

import Avatar from "common/Avatar";
import {
  availableMembersSelectors,
  fetchAvailableJobMembers,
  fetchMoreAvailableJobMembers,
  getAvailableMembersLoadingSelector,
  getAvailableMembersPaginationSelector,
} from "features/Job/jobSlice";
import { memberStatusSelectors } from "features/Taxonomies/taxonomiesSlice";
import Button from "common/Button";
import ButtonLoader from "common/ButtonLoader";
import MemberDecline from "../forms/MemberDecline";
import { DefaultLoader } from "common/Loader";
import { Info } from "@mui/icons-material";
import { formatMemberforDetail, formatMemberNameForProximity } from "../../utils";
import MemberAssignmentDetail from "components/Member/MemberAssignmentDetail";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { mediaDown } from "components/mixins";

export const Styles = styled.div`
  .list__item {
    padding: 0.5rem;
    display: flex;
    align-items: flex-start;

    ${mediaDown.sm`
      display: block;
    `}

    &-actions {
      display: flex;
      align-items: center;
      margin-top: 1.25rem;

      ${mediaDown.sm`
        justify-content: right;
        margin-top: 0;
        margin-bottom: 0.5em;
      `}

      .MuiButton-root {
        margin-right: 1rem;
      }
    }

    &:nth-child(odd){
      background-color: ${constants.colorGrayLighter};
    }
  }

  .list__primary {
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;

    &-text{
      flex: 0 1 76%;
    }

    &-expand{
      margin: 0 0.5rem;
      cursor: pointer;
    }

    &-detail{
      flex: 0 1 100%;
      font-size: 0.85em;
      padding: 0.5rem 1em 0;
      margin-left: 5rem;

      &__item{
        margin-bottom: 0.5rem;
      }

      hr{ margin: 0 0 0.5rem; }
    }
  }
`;

export const Legend = styled.div`
  padding: 1.25rem;
`;

const getLabel = (haystack, needle) => {
  if (!haystack || !needle) return null;
  const object = haystack.find((obj) => obj.machine_name === needle);
  return object.label;
};

const AssignMember = ({ cid, tid, clientId, setDeclinedMembers, onAccept }) => {
  const dispatch = useDispatch();
  const members = useSelector(availableMembersSelectors.selectAll);
  const loading = useSelector(getAvailableMembersLoadingSelector);
  const pagination = useSelector(getAvailableMembersPaginationSelector);
  const [trackingID, setTrackingID] = useState(null);
  const [page, setPage] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    setPage(0);
    let promise = null;
    if (open) {
      promise = dispatch(
        fetchAvailableJobMembers({
          type: tid,
          location: cid,
          closest: true,
          track: true,
          params: {"filter[phx_client_nids]": clientId,},
        })
      );
      promise.then((response) => {
        const payload = unwrapResult(response);
        setTrackingID(payload.meta.tracking_id);
      });
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, cid, tid, clientId/*, showAll*/, open]);

  const handleLoadMore = useCallback(
    async (showAll, page, pagination) => {
      if (pagination?.total_pages > 1 && pagination?.total_pages > page + 1) {
        setPage(page + 1);
        setLoadingMore(true);
        await dispatch(
          fetchMoreAvailableJobMembers({
            type: tid,
            location: cid,
            closest: (!showAll ? 'true' : 'false'),
            track: trackingID,
            params: {
              "filter[phx_client_nids]": clientId,
              page: page + 1,
            },
          })
        );

        setLoadingMore(false);
      }
    },
    [dispatch, tid, cid, trackingID, clientId]
  );

  const hasMoreEntires = useMemo(
    () => pagination?.total_pages > 1 && pagination?.total_pages > page + 1,
    [pagination, page]
  );

  const handleCheck = () => {
    setShowAll(!showAll);
    setPage(0);
    let promise = null;
    if (open) {
      promise = dispatch(
        fetchAvailableJobMembers({
          type: showAll ? 0 : tid,
          location: cid,
          closest: true,
          track: (trackingID ? trackingID : true),
        })
      );
      promise.then((response) => {
        const payload = unwrapResult(response);
        setTrackingID(payload.meta.tracking_id);
      });
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  };

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="tertiary"
        size="small"
        variant="contained"
        disableElevation
        onClick={handleOpen}
        style={{ marginBottom: "2rem" }}
        startIcon={<RiUserLocationFill />}
        disabled={!tid || !cid}
      >
        Assign Member
      </Button>
      <DialogForm
        open={open}
        onClose={handleClose}
        title="Assign Member"
        maxWidth="sm"
        // fillScreen
        initialValues={{}}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div>
            <div style={{ padding: "1.25rem" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAll}
                    color="secondary"
                    onClick={handleCheck}
                  />
                }
                label="Show All Members In Range"
              />
            </div>
            {loading ? (
              <DefaultLoader />
            ) : (
              <Styles>
                <List>
                  {members.map((member) => (
                    <MemberItem
                      member={member}
                      key={member.member_nid}
                      onAccept={onAccept}
                      setDeclinedMembers={setDeclinedMembers}
                      trackingID={trackingID}
                      handleClose={handleClose}
                    />
                  ))}
                </List>
                {hasMoreEntires && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem 0",
                    }}
                  >
                    <ButtonLoader
                      color="default"
                      variant="contained"
                      size="small"
                      disableElevation
                      onClick={() => handleLoadMore(showAll, page, pagination)}
                      isSubmitting={loadingMore}
                    >
                      Load More
                    </ButtonLoader>
                  </div>
                )}
              </Styles>
            )}
            {/* <Legend>
              <Typography variant="body1" style={{ marginTop: "1.25rem" }}>
                <strong>Served County</strong>
              </Typography>
              <Typography variant="body1">* Owned County</Typography>
              <Typography variant="body1">
                <em>Affiliate Member</em>
              </Typography>
            </Legend> */}
          </div>
        )}
      </DialogForm>
    </>
  );
};

function MemberItem(props) {
  const {member, key, onAccept, setDeclinedMembers, trackingID, handleClose} = props;
  const statuses = useSelector(memberStatusSelectors.selectAll);
  const [open, setOpen] = React.useState(false);
  const openToggle = (() => setOpen(!open));

  return (
    <div key={key} className="list__item">
      <div className="list__primary">
        <Avatar
          title={member.member_name}
          link={member._files.logo}
          outline={member.daily_sheets_member === 1}
          onClick={openToggle}
          className="cursor-pointer"
          type="org"
        />
        {open
          ? <ExpandLess onClick={openToggle} className="list__primary-expand" />
          : <ExpandMore onClick={openToggle} className="list__primary-expand" />
        }
        <div className="list__primary-text cursor-pointer" onClick={openToggle}>
          {/* <Member
            member={formatMemberforDetail(member)}
          /> */}
          <Typography
            variant="subtitle"
            style={{ textTransform: "uppercase", cursor: "pointer" }}
            color="secondary"
          >
            {formatMemberNameForProximity(member)}
          </Typography>
          <Typography>
            <>
            <span className="block">
              {getLabel(statuses, member.status)}
              {(
                Number(member.qualified_for_customer) === 1 ? " | Qualified" :
                  (member.qualified_for_customer === null ? "" : <span> | <b>Not</b> Qualified</span>)
              )}
              {member?.response_time && (
                <span className="text-sm">{` - Response Time: ${member.response_time}`}</span>
              )}
            </span>
            <span>Recent Jobs (last 60 days) - {member.recent_job_qty} job{Number(member.recent_job_qty) === 1 ? '' : 's'}{` | `}
              {member.recent_job_declination_qty} declined</span>
            </>
          </Typography>
        </div>
        {member.status !== "in" && member.compliance_change_reasons && (
          <Tooltip
            title={
              member.compliance_change_reasons
                    .split(",")
                    .map((reason, i) => (
                      <div key={i}>
                        {reason}
                        <br />
                      </div>
                    ))
            }
          >
            <IconButton edge="end">
              <Info />
            </IconButton>
          </Tooltip>
        )}
        {open && <MemberAssignmentDetail member={formatMemberforDetail(member)} className="list__primary-detail" />}
      </div>
      <div className="list__item-actions">
        <Button
          color="tertiary"
          variant="contained"
          disableElevation
          size="small"
          onClick={() => {
            onAccept({
              nid: member.member_nid,
              name: member.member_name,
              status: member.status,
            }, trackingID);
            handleClose();
          }}
        >
          Assign
        </Button>
        <MemberDecline
          member={member}
          onDecline={setDeclinedMembers}
        />
      </div>
    </div>
  );
}

AssignMember.propTypes = {};

export default AssignMember;
