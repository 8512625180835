import {
  getUserFields,
  formatTimeRangeField,
  formatField,
} from "../../utility";

export const formatCrew = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_crew_arrival_time_range: formatTimeRangeField(
      d,
      "field_crew_arrival_time_range"
    ),
    field_crew_confirm_time: formatField(d, "field_crew_confirm_time"),
    field_crew_confirm_user: getUserFields(d, "field_crew_confirm_user"),
    field_crew_chief_user: getUserFields(d, "field_crew_chief_user")
      ? getUserFields(d, "field_crew_chief_user")[0]
      : null,
    field_crew_member_users: getUserFields(d, "field_crew_member_users")
      ? getUserFields(d, "field_crew_member_users")
      : [],
    field_crew_users_temporary: getUserFields(d, "field_crew_users_temporary")
      ? getUserFields(d, "field_crew_users_temporary")
      : [],
  };
};
