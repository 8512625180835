import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import config from "config";
import querystring from "querystring";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const instance = axios.create({
  baseURL: `${config.helix_api_url}/api/`,
});

const namespace = "floorplanner";

export const fetchMeters = createAsyncThunk(
  `${namespace}/fetchMeters`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`meters${queryparams}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChambers = createAsyncThunk(
  `${namespace}/fetchChambers`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`divisions/${id}/chambers`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchEquipment = createAsyncThunk(
  `${namespace}/fetchEquipment`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`divisions/${id}/equipment`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchEquipmentTypes = createAsyncThunk(
  `${namespace}/fetchEquipmentTypes`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`equipment-types`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchEquipmentSubTypes = createAsyncThunk(
  `${namespace}/fetchEquipmentSubTypes`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`equipment-sub-types`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoom = createAsyncThunk(
  `${namespace}/fetchRoom`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`rooms/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSurfaces = createAsyncThunk(
  `${namespace}/fetchSurfaces`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`surfaces${queryparams}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSubstrates = createAsyncThunk(
  `${namespace}/fetchSubstrates`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`substrates${queryparams}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLevelTypes = createAsyncThunk(
  `${namespace}/fetchLevelTypes`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`levels${queryparams}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDamageTypes = createAsyncThunk(
  `${namespace}/fetchDamageTypes`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`damage-types${queryparams}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSourceOfLosses = createAsyncThunk(
  `${namespace}/fetchSourceOfLosses`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`sources${queryparams}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchClassTypes = createAsyncThunk(
  `${namespace}/fetchClassTypes`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`class-types${queryparams}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCategories = createAsyncThunk(
  `${namespace}/fetchCategories`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`categories${queryparams}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRooms = createAsyncThunk(
  `${namespace}/fetchRooms`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`divisions/${id}/rooms`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoistureReadings = createAsyncThunk(
  `${namespace}/fetchMoistureReadings`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`rooms/${id}/moisture-readings`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoistureReadingsByDivision = createAsyncThunk(
  `${namespace}/fetchMoistureReadingsByDivision`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`divisions/${id}/moisture-readings`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPsychometrics = createAsyncThunk(
  `${namespace}/fetchPsychometrics`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`divisions/${id}/psychometrics`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPsychometric = createAsyncThunk(
  `${namespace}/fetchPsychometric`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`psychometrics/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoistureReading = createAsyncThunk(
  `${namespace}/fetchMoistureReading`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await instance.get(`moisture-readings/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postSketchUpload = createAsyncThunk(
  `${namespace}/postSketchUpload`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.post(`s3/multipart`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postMoistureReading = createAsyncThunk(
  `${namespace}/postMoistureReading`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.post(`moisture-readings`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEquipment = createAsyncThunk(
  `${namespace}/postEquipment`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.post(`equipment`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postMoistureMeasurement = createAsyncThunk(
  `${namespace}/postMoistureMeasurement`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.post(`moisture-measurements`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchChamber = createAsyncThunk(
  `${namespace}/patchChamber`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.patch(`chambers/${id}`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchMoistureMeasurement = createAsyncThunk(
  `${namespace}/patchMoistureMeasurement`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.patch(
        `moisture-measurements/${id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${
              getState().auth.user.data._processed.phx_offsite_api_token
            }`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchMoistureReading = createAsyncThunk(
  `${namespace}/patchMoistureReading`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.patch(`moisture-readings/${id}`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchEquipment = createAsyncThunk(
  `${namespace}/patchEquipment`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.patch(`equipment/${id}`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchPsychometric = createAsyncThunk(
  `${namespace}/patchPsychometric`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.patch(`psychometrics/${id}`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postPsychometric = createAsyncThunk(
  `${namespace}/postPsychometric`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.post(`psychometrics`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postPsychometricMeasurement = createAsyncThunk(
  `${namespace}/postPsychometricMeasurement`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.post(
        `psychometric-measurements`,
        params,
        {
          headers: {
            Authorization: `Bearer ${
              getState().auth.user.data._processed.phx_offsite_api_token
            }`,
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postChamber = createAsyncThunk(
  `${namespace}/postChamber`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.post(`chambers`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postRoom = createAsyncThunk(
  `${namespace}/postRoom`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.post(`rooms`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRoom = createAsyncThunk(
  `${namespace}/patchRoom`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await instance.patch(`rooms/${id}`, params, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMoistureMeasurement = createAsyncThunk(
  `${namespace}/deleteMoistureMeasurement`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await instance.delete(`moisture-measurements/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteEquipment = createAsyncThunk(
  `${namespace}/deleteEquipment`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await instance.delete(`equipment/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoom = createAsyncThunk(
  `${namespace}/deleteRoom`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await instance.delete(`rooms/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteChamber = createAsyncThunk(
  `${namespace}/deleteChamber`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await instance.delete(`chambers/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMoistureReading = createAsyncThunk(
  `${namespace}/deleteMoistureReading`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await instance.delete(`moisture-readings/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePsychometricReading = createAsyncThunk(
  `${namespace}/deletePsychometricReading`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await instance.delete(`psychometrics/${id}`, {
        headers: {
          Authorization: `Bearer ${
            getState().auth.user.data._processed.phx_offsite_api_token
          }`,
        },
      });

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const floorplannerAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const floorplannerSlice = createSlice({
  name: namespace,
  initialState: {
    rooms: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    chambers: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    readings: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    psychometrics: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    reading: {
      data: {},
      loading: false,
      error: null,
      measurements: floorplannerAdapter.getInitialState({}),
    },
    room: {
      data: {},
      loading: false,
      error: null,
    },
    psychometric: {
      data: {},
      loading: false,
      error: null,
      measurements: floorplannerAdapter.getInitialState({}),
    },
    meters: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    surfaces: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    substrates: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    levels: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    damageTypes: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    sources: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    classTypes: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    categories: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    equipment: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    equipmentTypes: floorplannerAdapter.getInitialState({
      loading: false,
    }),
    equipmentSubTypes: floorplannerAdapter.getInitialState({
      loading: false,
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchChambers.pending](state) {
      state.chambers.loading = true;
      floorplannerAdapter.removeAll(state.chambers);
    },
    [fetchChambers.fulfilled](state, { payload: chambers }) {
      state.chambers.loading = false;
      floorplannerAdapter.setAll(state.chambers, chambers);
    },
    [fetchChambers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.chambers.loading = false;
        if (action.payload) {
          state.chambers.error = action.payload.message;
        } else {
          state.chambers.error = action.error.message;
        }
      }
    },
    [fetchRooms.pending](state) {
      floorplannerAdapter.removeAll(state.rooms);
      state.rooms.loading = true;
    },
    [fetchRooms.fulfilled](state, { payload: rooms }) {
      state.rooms.loading = false;
      floorplannerAdapter.setAll(state.rooms, rooms);
    },
    [fetchRooms.rejected](state, action) {
      if (!action.meta.aborted) {
        state.rooms.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchEquipment.pending](state) {
      state.equipment.loading = true;
      floorplannerAdapter.removeAll(state.equipment);
    },
    [fetchEquipment.fulfilled](state, { payload: equipment }) {
      state.equipment.loading = false;
      floorplannerAdapter.setAll(state.equipment, equipment);
    },
    [fetchEquipment.rejected](state, action) {
      if (!action.meta.aborted) {
        state.equipment.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchEquipmentTypes.pending](state) {
      state.equipmentTypes.loading = true;
    },
    [fetchEquipmentTypes.fulfilled](state, { payload: equipment }) {
      state.equipmentTypes.loading = false;
      floorplannerAdapter.setAll(state.equipmentTypes, equipment);
    },
    [fetchEquipmentTypes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.equipmentTypes.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchRoom.pending](state) {
      state.room.loading = true;
    },
    [fetchRoom.fulfilled](state, { payload: room }) {
      state.room.loading = false;
      state.room.data = room;
    },
    [fetchRoom.rejected](state, action) {
      if (!action.meta.aborted) {
        state.room.loading = false;
        if (action.payload) {
          state.room.error = action.payload.message;
        } else {
          state.room.error = action.error.message;
        }
      }
    },
    [fetchEquipmentSubTypes.pending](state) {
      state.equipmentSubTypes.loading = true;
    },
    [fetchEquipmentSubTypes.fulfilled](state, { payload: equipment }) {
      state.equipmentSubTypes.loading = false;
      floorplannerAdapter.setAll(state.equipmentSubTypes, equipment);
    },
    [fetchEquipmentSubTypes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.equipmentSubTypes.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchSurfaces.pending](state) {
      state.surfaces.loading = true;
    },
    [fetchSurfaces.fulfilled](state, { payload: surfaces }) {
      state.surfaces.loading = false;
      floorplannerAdapter.setAll(state.surfaces, surfaces);
    },
    [fetchSurfaces.rejected](state, action) {
      if (!action.meta.aborted) {
        state.surfaces.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchSubstrates.pending](state) {
      state.substrates.loading = true;
    },
    [fetchSubstrates.fulfilled](state, { payload: substrates }) {
      state.substrates.loading = false;
      floorplannerAdapter.setAll(state.substrates, substrates);
    },
    [fetchSubstrates.rejected](state, action) {
      if (!action.meta.aborted) {
        state.substrates.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchLevelTypes.pending](state) {
      state.levels.loading = true;
    },
    [fetchLevelTypes.fulfilled](state, { payload: levels }) {
      state.levels.loading = false;
      floorplannerAdapter.setAll(state.levels, levels);
    },
    [fetchLevelTypes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.levels.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchSourceOfLosses.pending](state) {
      state.sources.loading = true;
    },
    [fetchSourceOfLosses.fulfilled](state, { payload: types }) {
      state.sources.loading = false;
      floorplannerAdapter.setAll(state.sources, types);
    },
    [fetchSourceOfLosses.rejected](state, action) {
      if (!action.meta.aborted) {
        state.sources.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchDamageTypes.pending](state) {
      state.damageTypes.loading = true;
    },
    [fetchDamageTypes.fulfilled](state, { payload: types }) {
      state.damageTypes.loading = false;
      floorplannerAdapter.setAll(state.damageTypes, types);
    },
    [fetchDamageTypes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.damageTypes.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchClassTypes.pending](state) {
      state.classTypes.loading = true;
    },
    [fetchClassTypes.fulfilled](state, { payload: types }) {
      state.classTypes.loading = false;
      floorplannerAdapter.setAll(state.classTypes, types);
    },
    [fetchClassTypes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.classTypes.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchCategories.pending](state) {
      state.categories.loading = true;
    },
    [fetchCategories.fulfilled](state, { payload: categories }) {
      state.categories.loading = false;
      floorplannerAdapter.setAll(state.categories, categories);
    },
    [fetchCategories.rejected](state, action) {
      if (!action.meta.aborted) {
        state.categories.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMeters.pending](state) {
      state.meters.loading = true;
    },
    [fetchMeters.fulfilled](state, { payload: meters }) {
      state.meters.loading = false;
      floorplannerAdapter.setAll(state.meters, meters);
    },
    [fetchMeters.rejected](state, action) {
      if (!action.meta.aborted) {
        state.meters.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoistureReadings.pending](state) {
      state.readings.loading = true;
      floorplannerAdapter.removeAll(state.readings);
    },
    [fetchMoistureReadings.fulfilled](state, { payload: readings }) {
      state.readings.loading = false;
      floorplannerAdapter.setAll(state.readings, readings);
    },
    [fetchMoistureReadings.rejected](state, action) {
      if (!action.meta.aborted) {
        state.readings.loading = false;
        floorplannerAdapter.removeAll(state.readings);
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoistureReadingsByDivision.pending](state) {
      state.readings.loading = true;
    },
    [fetchMoistureReadingsByDivision.fulfilled](state, { payload: readings }) {
      state.readings.loading = false;
      floorplannerAdapter.setAll(state.readings, readings);
    },
    [fetchMoistureReadingsByDivision.rejected](state, action) {
      if (!action.meta.aborted) {
        state.readings.loading = false;
        floorplannerAdapter.removeAll(state.readings);
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchPsychometrics.pending](state) {
      state.psychometrics.loading = true;
      floorplannerAdapter.removeAll(state.psychometrics);
    },
    [fetchPsychometrics.fulfilled](state, { payload: readings }) {
      state.psychometrics.loading = false;
      floorplannerAdapter.setAll(state.psychometrics, readings);
    },
    [fetchPsychometrics.rejected](state, action) {
      if (!action.meta.aborted) {
        state.psychometrics.loading = false;
        floorplannerAdapter.removeAll(state.psychometrics);
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoistureReading.pending](state) {
      state.reading.loading = true;
      floorplannerAdapter.removeAll(state.reading.measurements);
    },
    [fetchMoistureReading.fulfilled](state, { payload: reading }) {
      state.reading.loading = false;
      state.reading.data = reading;
      floorplannerAdapter.setAll(
        state.reading.measurements,
        reading.measurements
      );
    },
    [fetchMoistureReading.rejected](state, action) {
      if (!action.meta.aborted) {
        state.reading.loading = false;
        if (action.payload) {
          state.reading.error = action.payload.message;
        } else {
          state.reading.error = action.error.message;
        }
      }
    },
    [fetchPsychometric.pending](state) {
      state.psychometric.loading = true;
      floorplannerAdapter.removeAll(state.psychometric.measurements);
    },
    [fetchPsychometric.fulfilled](state, { payload: reading }) {
      state.psychometric.loading = false;
      state.psychometric.data = reading;
      floorplannerAdapter.setAll(
        state.psychometric.measurements,
        reading.measurements
      );
    },
    [fetchPsychometric.rejected](state, action) {
      if (!action.meta.aborted) {
        state.psychometric.loading = false;
        if (action.payload) {
          state.psychometric.error = action.payload.message;
        } else {
          state.psychometric.error = action.error.message;
        }
      }
    },
    [postMoistureReading.fulfilled](state, { payload: reading }) {
      floorplannerAdapter.addOne(state.readings, reading);
    },
    [postMoistureReading.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postEquipment.fulfilled](state, { payload: reading }) {
      floorplannerAdapter.addOne(state.equipment, reading);
    },
    [postEquipment.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postChamber.fulfilled](state, { payload: chamber }) {
      floorplannerAdapter.addOne(state.chambers, chamber);
    },
    [postRoom.fulfilled](state, { payload: room }) {
      floorplannerAdapter.addOne(state.rooms, room);
    },
    [patchRoom.fulfilled](state, { payload: room }) {
      floorplannerAdapter.updateOne(state.rooms, {
        id: room.id,
        changes: room,
      });
    },
    [postMoistureMeasurement.fulfilled](state, { payload: measurement }) {
      floorplannerAdapter.updateOne(state.readings, {
        id: measurement.reading.id,
        changes: measurement.reading,
      });
      floorplannerAdapter.updateOne(state.rooms, {
        id: measurement.reading.room.id,
        changes: {
          readings: measurement.reading.room.readings,
          latest_reading: measurement.reading.room.latest_reading,
        },
      });
      floorplannerAdapter.addOne(state.reading.measurements, measurement);
    },
    [postMoistureMeasurement.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchMoistureMeasurement.fulfilled](state, { payload: measurement }) {
      floorplannerAdapter.updateOne(state.readings, {
        id: measurement.reading.id,
        changes: measurement.reading,
      });
    },
    [patchMoistureMeasurement.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchMoistureReading.pending](state) {
      state.readings.loading = true;
    },
    [patchMoistureReading.fulfilled](state, { payload: reading }) {
      state.readings.loading = false;
      floorplannerAdapter.updateOne(state.readings, {
        id: reading.id,
        changes: reading,
      });
    },
    [patchMoistureReading.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchEquipment.fulfilled](state, { payload: reading }) {
      floorplannerAdapter.updateOne(state.equipment, {
        id: reading.id,
        changes: reading,
      });
    },
    [patchEquipment.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteEquipment.fulfilled](state, { payload: id }) {
      floorplannerAdapter.removeOne(state.equipment, id);
    },
    [deleteEquipment.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteRoom.fulfilled](state, { payload: id }) {
      floorplannerAdapter.removeOne(state.rooms, id);
    },
    [deleteRoom.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteChamber.fulfilled](state, { payload: id }) {
      floorplannerAdapter.removeOne(state.chambers, id);
    },
    [deleteChamber.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchChamber.fulfilled](state, { payload: chamber }) {
      floorplannerAdapter.updateOne(state.chambers, {
        id: chamber.id,
        changes: chamber,
      });
    },
    [patchChamber.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteMoistureReading.fulfilled](state, { payload: id }) {
      floorplannerAdapter.removeOne(state.readings, id);
    },
    [deleteMoistureReading.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deletePsychometricReading.fulfilled](state, { payload: id }) {
      floorplannerAdapter.removeOne(state.psychometrics, id);
    },
    [deletePsychometricReading.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postPsychometric.fulfilled](state, { payload: reading }) {
      floorplannerAdapter.addOne(state.psychometrics, reading);
    },
    [postPsychometric.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchPsychometric.fulfilled](state, { payload: reading }) {
      floorplannerAdapter.updateOne(state.psychometrics, {
        id: reading.id,
        changes: reading,
      });
    },
    [patchPsychometric.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postPsychometricMeasurement.fulfilled](state, { payload: measurement }) {
      floorplannerAdapter.updateOne(state.psychometrics, {
        id: measurement.reading.id,
        changes: measurement.reading,
      });
      floorplannerAdapter.addOne(state.psychometric.measurements, measurement);
    },
    [postPsychometricMeasurement.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getFloorplannerLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.loading
);

export const getFloorplannerPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.pagination
);

export const getFloorplannerReadingDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.reading.data
);

export const getFloorplannerReadingLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.reading.loading
);

export const getFloorplannerPsychometricDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.psychometric.data
);

export const getFloorplannerPsychometricLoadingSelector =
  createDraftSafeSelector(
    selectSelf,
    (state) => state.floorplanner.psychometric.loading
  );

export const getFloorplannerPsychometricsLoadingSelector =
  createDraftSafeSelector(
    selectSelf,
    (state) => state.floorplanner.psychometrics.loading
  );

export const getFloorplannerReadingsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.readings.loading
);

export const getFloorplannerEquipmentLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.equipment.loading
);

export const getFloorplannerRoomLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.room.loading
);

export const getFloorplannerRoomDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.room.data
);

export const getFloorplannerRoomsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.rooms.loading
);

export const getFloorplannerChambersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.floorplanner.chambers.loading
);

export const floorplannerReadingsSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.readings
);

export const floorplannerPsychometricsSelectors =
  floorplannerAdapter.getSelectors((state) => state.floorplanner.psychometrics);

export const floorplannerPsychometricMeasurementsSelectors =
  floorplannerAdapter.getSelectors(
    (state) => state.floorplanner.psychometric.measurements
  );

export const floorplannerMeasurementsSelectors =
  floorplannerAdapter.getSelectors(
    (state) => state.floorplanner.reading.measurements
  );

export const floorplannerMetersSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.meters
);

export const floorplannerSurfacesSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.surfaces
);

export const floorplannerSubstratesSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.substrates
);

export const floorplannerRoomsSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.rooms
);

export const floorplannerChambersSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.chambers
);

export const floorplannerLevelsSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.levels
);

export const floorplannerDamageTypesSelectors =
  floorplannerAdapter.getSelectors((state) => state.floorplanner.damageTypes);

export const floorplannerSourcesSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.sources
);

export const floorplannerClassTypesSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.classTypes
);

export const floorplannerCategoriesSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.categories
);

export const floorplannerEquipmentSelectors = floorplannerAdapter.getSelectors(
  (state) => state.floorplanner.equipment
);

export const floorplannerEquipmentTypesSelectors =
  floorplannerAdapter.getSelectors(
    (state) => state.floorplanner.equipmentTypes
  );

export const floorplannerEquipmentSubTypesSelectors =
  floorplannerAdapter.getSelectors(
    (state) => state.floorplanner.equipmentSubTypes
  );

export default floorplannerSlice.reducer;
