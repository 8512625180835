import React, {useState} from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

// import QuickAdd from "../QuickAdd";
// import { JobsFormContainer } from "../Jobs";
// import { MembersAdd } from "../Members";
// import { CustomersAdd } from "../Customers";
// import Header from "../Header";
import Toast from "features/Toast";
import BrowserNotifications from "components/BrowserNotifications";
import Sidebar from "../Sidebar";
// import { TaskDrawer } from "../Drawers";
import { Wrapper } from "./Styled";
import { withPermissions } from "../../providers";
import Header from "../../features/Header";
import Batch from "../../features/Batch";
// import { UnreadComments } from "../../features/Comments";

import {
  getIsAuthenticatedSelector,
  // getAccessTokenSelector,
} from "../../features/Auth/authSlice";
// import PatchNotes from "../../features/PatchNotes";
import BatchImport from "../../features/Batch/BatchImport";

const UserRoute = ({ children, roles, ...rest }) => {
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  // const token = useSelector(getAccessTokenSelector);
  const [openMainDrawer, setOpenMainDrawer] = useState(false);

  const toggleMainDrawer = (toggle) => {
    setOpenMainDrawer(toggle);
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <>
          <Toast continueAction={toggleMainDrawer}/>
          <BrowserNotifications />
          <div className="app">
            {/* <Header roles={roles} /> */}
            <Header
              isAuthenticated={isAuthenticated}
              openMainDrawer={openMainDrawer}
              onCloseMainDrawer={() => toggleMainDrawer(false)}
            />
            <Wrapper>
              <Sidebar />
              {/* <TaskDrawer /> */}
              {/* <QuickAdd roles={roles} /> */}
              {children}
              {/* <JobsFormContainer roles={roles} />
              <MembersAdd roles={roles} />
              <CustomersAdd roles={roles} /> */}
            </Wrapper>
            <Batch />
            <BatchImport />
            {/* <UnreadComments /> */}
            {/* <PatchNotes /> */}
          </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default withPermissions(UserRoute);
