import action from "../action";
import config from "../../config";

export const getMemberStatus = () =>
  action(
    "memberStatus",
    config.api_url,
    `rest/field/list-text-options/node/field_status`
  );

export const getLossCategories = () =>
  action(
    "lossCategories",
    config.api_url,
    `rest/field/list-text-options/node/field_loss_category`
  );

export const getJobSources = () =>
  action(
    "jobSources",
    config.api_url,
    `rest/field/list-text-options/node/field_job_source`
  );

export const getCustomerStatus = () =>
  action(
    "customerStatus",
    config.api_url,
    `rest/field/list-text-options/node/field_customer_status`
  );

export const getDaDemo = () =>
  action(
    "daDemo",
    config.api_url,
    `rest/field/list-text-options/node/field_da_demo`
  );

export const getDaWaterPresent = () =>
  action(
    "daWaterPresent",
    config.api_url,
    `rest/field/list-text-options/node/field_da_water_present`
  );

export const getDaSafety = () =>
  action(
    "daSafety",
    config.api_url,
    `rest/field/list-text-options/node/field_da_life_safety`
  );

export const getDaPower = () =>
  action(
    "daPower",
    config.api_url,
    `rest/field/list-text-options/node/field_da_power`
  );

export const getDsEntryTypes = () =>
  action(
    "dsEntryTypes",
    config.api_url,
    `rest/field/list-text-options/node/field_ds_entry_type`
  );

export const getCustInvoiceTerms = () =>
  action(
    "custInvoiceTerms",
    config.api_url,
    `rest/field/list-text-options/node/field_customer_invoice_terms`
  );

export const getCustInvoiceDiscount = () =>
  action(
    "custInvoiceDiscount",
    config.api_url,
    `rest/field/list-text-options/node/field_customer_discount`
  );

export const getLiftMethods = () =>
  action(
    "liftMethods",
    config.api_url,
    `rest/field/list-text-options/node/field_compliance_lift_method`
  );

// Location
export const getStates = (country) =>
  action("states", config.api_url, `rest/options/states/${country}`);

export const getCounties = () =>
  action("counties", config.api_url, `rest/options/counties`);

// AutoCompletes
export const getAvailableUsers = (nid, assignedOnly = 0, params) =>
  action(
    "availableUsers",
    config.api_url,
    `rest/users/${nid}${assignedOnly ? `/${assignedOnly}` : ""}`,
    params
  );

export const getAvailableMemberUsers = (nid, params) =>
  action(
    "availableUsers",
    config.api_url,
    `rest/member/users/${nid}/true`,
    params
  );

export const getAvailableDailySheetsUsers = (nid, params) =>
  action(
    "availableDSUsers",
    config.api_url,
    `rest/member/users-w-temp/${nid}`,
    params
  );

export const getAvailableJobMembers = (cid, did, closest, params) =>
  action(
    "availableMembers",
    config.api_url,
    `rest/members/for-job/${cid}/${did}${closest ? `/${closest}` : ""}`,
    params
  );

export const getAvailableJobCustomers = (params) =>
  action("availableCustomers", config.api_url, `rest/customers`, params);

export const getAvailableJobLocations = (nid, params) =>
  action(
    "availableLocations",
    config.api_url,
    `rest/customer/locations/${nid}`,
    params
  );

export const getAvailableMembersByCoords = (
  customer_nid,
  coords,
  county_tid,
  params
) =>
  action(
    "availableMembers",
    config.api_url,
    `rest/members/for-customer-location/${customer_nid}/${coords}/${county_tid}`,
    params
  );

export const getJobAccountingTemplateOptions = (params) =>
  action(
    "accountingTemplateOptions",
    config.api_url,
    `rest/job-accounting-invoice/template/options`,
    params
  );

export const getMemberInsuranceAgentsByMember = (nid, params) =>
  action(
    "availableAgents",
    config.api_url,
    `rest/member/insurance-agents/${nid}`,
    params
  );

export const getCurrentJobDivisionStatus = (entity_id, params) =>
  action(
    "jobDivisionStatus",
    config.api_url,
    `entity/node/field_jd_status/${entity_id}`,
    params
  );
