import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Button, Popover } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "var(--color-blue)",
    paddingRight: "10px",
    color: "white",
    "&:hover": {
      backgroundColor: "var(--color-blue)",
      color: "white",
    },
  },
}));

const ButtonDropdown = forwardRef(
  (
    {
      id,
      label,
      children,
      anchorOrigin,
      color,
      transformOrigin,
      disabled,
      icon: Icon,
      style,
      onClose,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      if (onClose) {
        onClose();
      }
    };

    useImperativeHandle(ref, () => ({
      close() {
        setAnchorEl(null);
        if (onClose) {
          onClose();
        }
      },
    }));

    return (
      <React.Fragment>
        <Button
          {...props}
          style={style}
          onClick={handleClick}
          className={`${props?.className} ${color === "tertiary" ? classes.button : ""}`}
          size="small"
          startIcon={Icon ? <Icon /> : null}
          color={color === "tertiary" ? "default" : color}
          disabled={disabled}
        >
          {label}
        </Button>
        <Popover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {children}
        </Popover>
      </React.Fragment>
    );
  }
);

const { string, element, oneOfType, array } = PropTypes;
ButtonDropdown.propTypes = {
  label: string,
  children: oneOfType([array, element]),
};

export default ButtonDropdown;
