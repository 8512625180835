import React, { useState } from "react";
import { connect } from "react-redux";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";
import * as Yup from "yup";
import config from "config";
import { postNode } from "actions/paragraphs";

const typeOptions = [
  {
    value: "account",
    label: "Account Issues",
  },
  {
    value: "general",
    label: "General Inquiry",
  },
  {
    value: "error",
    label: "Error/Bug",
  },
];

const importanceOptions = [
  {
    value: "low",
    label: "Low (Can be handled by End-of-Day)",
  },
  {
    value: "high",
    label: "High (Service is disrupted or you cannot proceed with work)",
  },
];

const Support = ({addParagraph, children}) => {
  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    const recipients = [config.support_email];
    const typeOption = typeOptions.find((type) => type.value === data.type).label;
    const importanceOption = importanceOptions.find((importance) => importance.value === data.importance).label;

    let message = `
    <b>Type:</b> ${typeOption}<br />
    <b>Importance:</b> ${importanceOption}<br />&nbsp;<br />
    ${data.message.replace(/\n/g, "<br />")}`;

    const params = {
      _links: {
        type: {
          href: `${config.api_url}/rest/type/node/email_message`,
        },
      },
      _meta: {
        send: 1,
      },
      title: [{ value: data.subject }],
      field_em_message: [{ value: message }],
      field_em_recipients: recipients.map((r) => ({ value: r.trim() })),
    };

    const response = await addParagraph(params);
    if (response.status === 201) {
      successCallback("Support was contacted. We will respond to you soon.");
    } else {
      errorCallback(setMessage, response, []);
    }
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children && (
        React.cloneElement(children, {
          onClick: () => setOpen(true),
        })
      )}
      <DialogForm
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        title="Support"
        initialValues={{
          type: "",
          importance: "low",
          subject: "",
          message: "",
        }}
        validationSchema={Yup.object({
          type: Yup.string().required("Required"),
          importance: Yup.string().required("Required"),
          subject: Yup.string().required("Required"),
          message: Yup.string().required("Required"),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <>
            <SelectFormField
              fullWidth
              name="type"
              label="Type"
              margin="normal"
              disabled={isSubmitting}
              required
              options={typeOptions}
              variant="filled"
              size="small"
            />
            <SelectFormField
              fullWidth
              name="importance"
              label="Importance"
              margin="normal"
              disabled={isSubmitting}
              required
              options={importanceOptions}
              variant="filled"
              size="small"
            />
            <TextFormField
              fullWidth
              htmlFor="subject"
              name="subject"
              label="Subject"
              margin="normal"
              required
              disabled={isSubmitting}
              type="text"
              variant="filled"
              size="small"
            />
            <TextFormField
              fullWidth
              htmlFor="message"
              name="message"
              label="Message"
              margin="normal"
              required
              disabled={isSubmitting}
              type="text"
              variant="filled"
              size="small"
              multiline
              className="min-h-24"
            />
          </>
        )}
      </DialogForm>
  </>
  );
};

Support.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  addParagraph: (params) => dispatch(postNode("node", params)),
});

export default connect(null, mapDispatchToProps)(Support);
