import React, { useEffect, useState } from "react";
import {
  useFlexLayout,
  useResizeColumns,
  useTable,
  useSortBy,
  useFilters,
} from "react-table";
import styled from "styled-components";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { size } from "lodash";

import FilterMenu from "./components/FilterMenu";
import { KeywordFilter } from "./components/Filters";
import Pagination from "./components/Pagination";
import { useDebounce } from "../../hooks";
import { TableSkeleton } from "../../components/Skeletons";

const Container = styled.div`
  display: block;
  overflow: auto;
  height: 100%;
  .table {
    display: flex;
    flex-direction: column;
    border-spacing: 0;
    .thead {
      overflow-y: auto;
      overflow-x: hidden;
    }
    .tbody {
      overflow-y: scroll;
      overflow-x: hidden;
      flex: 1;
    }
    .tr {
      position: relative;
      :nth-child(odd) {
        background: white;
      }
      :nth-child(even) {
        background: var(--color-gray-light);
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid var(--color-gray-medium);
    }
    .th,
    .td {
      margin: 0;
      padding: 0.5rem 1rem;
      position: relative;
      :last-child {
        border-right: 0;
      }
      .resizer {
        right: 0;
        background: var(--color-gray-medium);
        width: 2px;
        border-radius: 1px;
        height: 80%;
        position: absolute;
        top: 10%;
        bottom: 10%;
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;
        &.isResizing {
          background: var(--color-blue);
        }
      }
    }
  }

  .table-head {
    border-top: 1px solid var(--color-gray-medium);
    background: white;
  }
`;

// const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

const getNumFilters = (query, filters) => {
  const temp = [...filters].filter((filter) => filter.value !== "_none");

  let num = size(temp);
  if (query) {
    num += 1;
  }

  return num;
};

const Table = ({
  data,
  columns,
  defaultColumn,
  loading,
  manualSortBy,
  disableMultiSort,
  manualFilters,
  manualGlobalFilter,
  fetchData,
  pagination,
  actions,
}) => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState("50");
  const searchQuery = useDebounce(query, 250);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualSortBy,
      disableMultiSort,
      manualFilters,
      manualGlobalFilter,
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy
  );

  useEffect(() => {
    const promise = fetchData({
      sortBy,
      keywords: searchQuery,
      page,
      rows_per_page: rowsPerPage,
      filters,
    });

    return () => {
      promise.abort();
    };
  }, [sortBy, fetchData, searchQuery, page, rowsPerPage, filters]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;

    setRowsPerPage(newRowsPerPage);
  };

  return (
    <>
      <div style={{ background: "white", paddingTop: "1.25rem" }}>
        <FilterMenu
          numFilters={getNumFilters(searchQuery, filters)}
          actions={actions}
        >
          <KeywordFilter query={query} setQuery={setQuery} />
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
              column.canFilter ? column.render("Filter") : null
            )
          )}
        </FilterMenu>
      </div>
      <Container>
        <div {...getTableProps()} className="table">
          <div className="table-head">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps({})} className="tr">
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps()} className="th">
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span style={{ padding: "0 1rem" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TiArrowSortedDown />
                          ) : (
                            <TiArrowSortedUp />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody">
            {loading ? (
              <TableSkeleton />
            ) : (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div {...cell.getCellProps(cellProps)} className="td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Container>
      <Pagination
        pagination={pagination}
        onPageChange={(handleChangePage ? handleChangePage : {})}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

Table.propTypes = {};

export default Table;
