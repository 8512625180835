import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  padding: 0.5rem 1.25rem;
  border-top: 1px solid var(--color-gray-lighter);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CardActions = ({ children }) => {
  return <Styles>{children}</Styles>;
};

CardActions.propTypes = {};

export default CardActions;
