import querystring from "querystring";
import {size} from "lodash";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

export const getQueryParamsNested = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : Object.entries(params)
          .filter((entries) => size(entries[1]))
          .map((entries) => {
            const [key, entry] = entries;
            if(typeof entry === "string"){
              return `${key}=${entry}`;
            }
            else{
              return entry.map((value) => `${key}[]=${value}`).join("&");
            }
          })
          .join("&")
  }`;
};

export default getQueryParams;
