import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
//   import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { formatChamberFields } from "./utils";
import { formatField } from "../../utility";

//   const getQueryParams = (params) => {
//     return `?${
//       typeof params === "string"
//         ? params.substring(1)
//         : querystring.stringify(params)
//     }`;
//   };

const namespace = "chambers";

export const fetchChambers = createAsyncThunk(
  `${namespace}/fetchChambers`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/entity/node/field_mf_chambers/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const chambers = response.data.field_mf_chambers.map((c) =>
        formatChamberFields(c)
      );

      return chambers;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteChamber = createAsyncThunk(
  `${namespace}/deleteChamber`,
  async ({ id }, { getState, rejectWithValue, dispatch }) => {
    try {
      await axios.delete(`${config.api_url}/node/${id}`, tokenConfig(getState));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully removed chamber`,
        })
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing chamber: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postChamber = createAsyncThunk(
  `${namespace}/postChamber`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/mf_chamber`,
            },
          },
          _meta: {
            job_division_nid: id,
          },
          title: [{ value: params.name }],
          field_mf_chamber_level: [{ target_id: params.level }],
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully created chamber",
        })
      );
      return formatChamberFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating chamber: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchChamber = createAsyncThunk(
  `${namespace}/patchChamber`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/mf_chamber`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated chamber",
        })
      );

      return formatChamberFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating chamber: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const chambersAdapter = createEntityAdapter({
  selectId: (chamber) => chamber.nid,
});

const chambersSlice = createSlice({
  name: namespace,
  initialState: chambersAdapter.getInitialState({
    error: null,
    loading: false,
  }),
  reducers: {
    updateOneChamber: chambersAdapter.updateOne,
    incrementChamberRooms(state, { payload: id }) {
      const currentRooms = state.entities[id].field_mf_chamber_rooms;
      if (currentRooms) {
        chambersAdapter.updateOne(state, {
          id,
          changes: {
            field_mf_chamber_rooms: currentRooms + 1,
          },
        });
      }
    },
    decrementChamberRooms(state, { payload: id }) {
      const currentRooms = state.entities[id].field_mf_chamber_rooms;
      if (currentRooms && currentRooms > 0) {
        chambersAdapter.updateOne(state, {
          id,
          changes: {
            field_mf_chamber_rooms: currentRooms - 1,
          },
        });
      }
    },
  },
  extraReducers: {
    [fetchChambers.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [fetchChambers.fulfilled](state, { payload: chambers }) {
      state.loading = false;
      chambersAdapter.setAll(state, chambers);
    },
    [fetchChambers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteChamber.pending](state, action) {
      const { id } = action.meta.arg;
      chambersAdapter.removeOne(state, id);
    },
    [deleteChamber.rejected](state, action) {
      const { chamber } = action.meta.arg;
      chambersAdapter.addOne(state, chamber);

      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postChamber.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;
      const chamber = {
        nid: params.id,
        title: params.name,
        field_mf_chamber_rooms: 0,
        is_saving: true,
      };

      chambersAdapter.addOne(state, chamber);
    },
    [postChamber.fulfilled](state, { meta, payload: chamber }) {
      const { params } = meta.arg;

      chambersAdapter.updateOne(state, {
        id: params.id,
        changes: { ...chamber, is_saving: false },
      });
    },
    [postChamber.rejected](state, action) {
      const { params } = action.meta.arg;
      chambersAdapter.removeOne(state, params.id);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchChamber.pending](state, action) {
      state.error = null;
      const { id, params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      const chamber = {
        nid: id,
        title: tempParams.title,
        is_saving: true,
      };

      chambersAdapter.updateOne(state, {
        id,
        changes: chamber,
      });
    },
    [patchChamber.fulfilled](state, { meta, payload: chamber }) {
      const { id } = meta.arg;

      chambersAdapter.updateOne(state, {
        id,
        changes: { ...chamber, is_saving: false },
      });
    },
    [patchChamber.rejected](state, action) {
      const { params, chamber } = action.meta.arg;
      chambersAdapter.updateOne(state, { id: params.id, changes: chamber });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getChambersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.chambers.loading
);

export const getChambersErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.chambers.error
);

export const chambersSelectors = chambersAdapter.getSelectors(
  (state) => state.chambers
);

export const {
  updateOneChamber,
  incrementChamberRooms,
  decrementChamberRooms,
} = chambersSlice.actions;

export default chambersSlice.reducer;
