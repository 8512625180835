import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

const Container = styled.header`
  padding: 0.625rem 1.25rem;
  border-bottom: 1px solid var(--color-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    flex: 1;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    & > div {
      flex: 1;
    }
  }
`;

const CardHeader = ({
  title,
  leftActions,
  rightActions,
  children,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Typography variant="h6">{title}</Typography>
      <div className="left">{leftActions}</div>
      {children}
      <div className="right">{rightActions}</div>
    </Container>
  );
};

CardHeader.propTypes = {};

export default CardHeader;
