import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { formatTaskForListing } from "./utils";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "tasks";

export const fetchTasks = createAsyncThunk(
  `${namespace}/fetchTasks`,
  async (
    { view, start, end, hideComplete, userParentNid, params },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/tasks/${view}/${start}/${end}/${
          hideComplete ? 1 : 0
        }${userParentNid ? `/${userParentNid}` : ""}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data.data.map((task) => ({
        ...task,
        id: `${task.entity_id}-${task.recur_delta}`,
      }));
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTasksList = createAsyncThunk(
  `${namespace}/fetchTasksList`,
  async (
    { start, end, hideComplete, userParentNid, params },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/tasks/list${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        tasks: response.data.data.map((task) => ({
          ...task,
          id: `${task.entity_id}-${task.recur_delta}`,
        })),
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchJobTasks = createAsyncThunk(
  `${namespace}/fetchJobTasks`,
  async (
    { id, params, includeComplete },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/tasks/${id}${
          includeComplete ? "/1" : ""
        }${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data.data.map((task) => ({
        ...task,
        id: `${task.entity_id}-${task.recur_delta}`,
      }));
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTodaysJobTasks = createAsyncThunk(
  `${namespace}/fetchTodaysJobTasks`,
  async (
    { id, params, includeComplete },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const start = moment().startOf("day").format();
      const end = moment().endOf("day").format();
      const queryParams = getQueryParams({
        ...params,
        "filter[start_time][0][>=]": start,
        "filter[end_time][0][<=]": end,
      });

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/tasks/${id}${
          includeComplete ? "/1" : ""
        }${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data.data.map((task) => ({
        ...task,
        id: `${task.entity_id}-${task.recur_delta}`,
      }));
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchTaskComplete = createAsyncThunk(
  `${namespace}/patchTaskComplete`,
  async ({ id, delta }, { getState, signal, dispatch, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.patch(
        `${config.api_url}/rest/tasks/completion-toggle`,
        {
          task_nid: id,
          completed: 1,
          recur_delta: delta,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed task",
        })
      );

      return {
        id,
        changes: { completed_date: moment().format() },
        task: response.data,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing task: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postTask = createAsyncThunk(
  `${namespace}/postTask`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/task`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatTaskForListing(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating task: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const tasksAdapter = createEntityAdapter({
  selectId: (task) => task.id,
});

const tasksSlice = createSlice({
  name: namespace,
  initialState: tasksAdapter.getInitialState({
    loading: false,
    error: null,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
  }),
  reducers: {
    updateOneTask: (state, { payload }) => {
      tasksAdapter.updateOne(state, payload);
    },
  },
  extraReducers: {
    [fetchTasks.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchTasks.fulfilled](state, { payload: tasks }) {
      state.loading = false;
      state.error = null;
      tasksAdapter.setAll(state, tasks);
    },
    [fetchTasks.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchTasksList.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchTasksList.fulfilled](state, { payload: { tasks, pagination } }) {
      state.loading = false;
      state.error = null;
      state.pagination = pagination;
      tasksAdapter.setAll(state, tasks);
    },
    [fetchTasksList.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchJobTasks.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchJobTasks.fulfilled](state, { payload: tasks }) {
      state.loading = false;
      state.error = null;
      tasksAdapter.setAll(state, tasks);
    },
    [fetchJobTasks.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postTask.pending](state) {},
    [postTask.fulfilled](state, { payload: task }) {
      state.loading = false;
      state.error = null;
      tasksAdapter.addOne(state, task);
    },
    [postTask.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchTaskComplete.pending](state) {
      state.error = null;
    },
    [patchTaskComplete.fulfilled](state, { payload: { id, changes, task } }) {
      state.error = null;
      tasksAdapter.updateOne(state, {
        id,
        changes,
      });
    },
    [patchTaskComplete.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getTasksLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tasks.loading
);

export const getTasksPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tasks.pagination
);

export const getTasksErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tasks.error
);

export const tasksSelectors = tasksAdapter.getSelectors((state) => state.tasks);

export const { updateOneTask } = tasksSlice.actions;

export default tasksSlice.reducer;
