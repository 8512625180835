import React from "react";
import { Typography } from "@mui/material";
// import TruncateReactMarkdown from 'common/TruncateReactMarkdown';
import moment from "moment";
// import ReactDomServer from 'react-dom/server';
import { linkStrategy } from "features/Hub/components/editor/plugins/Linkify";
import Previews from "features/Hub/components/editor/Previews";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { SparklesIcon } from "@heroicons/react/outline";

const defaultGroupCover = `${process.env.PUBLIC_URL}/images/default-group.png`;
const defaultEventCover = `${process.env.PUBLIC_URL}/images/default-event.png`;

export const notification = ( {data, isSubNotication, currentUser} ) => {

  let path = data.link;
  if(data.link.includes('://')){
    path = path.slice(path.indexOf('://') + 3);
    path = path.slice(path.indexOf('/'));
  }

  let defaultIcon = false;
  let defaultCover = null;
  switch(data.parent_type){
    case 'App\\Models\\Group':
      defaultCover = defaultGroupCover;
    break;

    case 'App\\Models\\Event':
      defaultCover = defaultEventCover;
    break;

    default:
      defaultIcon = true;
    break;
  }

  const { text, links } = linkStrategy(data.body);

  return {
    'path': path,
    'icon': (
      <div className="bg-gray-light h-[48px] w-[48px] rounded-lg">
        {(!data?.icon && defaultIcon) ? (
          <SparklesIcon className="text-gray-dark p-2 max-w-full" />
        ) : (
          <img
            className="w-full h-full rounded-lg object-cover object-center border-solid border border-gray-dark"
            src={data?.icon ? data.icon : defaultCover}
            alt="cover"
          />
        )}
      </div>
    ),
    'body': (
      <div className="flex flex-col justify-between h-full">
        <div className="flex justify-end">
          <div className="h-4 flex-1 min-w-0 overflow-hidden text-ellipsis whitespace-nowrap">{data.title}</div>
          <div className="whitespace-nowrap text-sm">{moment(data.created_at).format('M/D/YY h:mma')}</div>
        </div>
        <div className="flex-[1_0_auto] flex px-1 italic">
          <div className="multiline-ellipsis-2 flex-1">
          {/* <TruncateReactMarkdown
            lines={(others.length <= 1 ? 3 : 2)}
          > */}
            <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]} >{text}</ReactMarkdown>
          {/* </TruncateReactMarkdown> */}
          </div>
          {links.length > 0 && (
            <div className="preview-links">
              <Previews links={links} />
            </div>
          )}
        </div>
        <Typography variant="body2" className="whitespace-nowrap overflow-hidden text-ellipsis">from {data.user.first_name} {data.user.last_name}</Typography>
      </div>
    )
  };
};

