/**
 * Get list of all thread participants
 *
 * @param {*} conversation
 * @param {*} user
 * @returns string
 */
export const getParticpants = (conversation, user) => {
  return  conversation.participants
              .filter((p) => p.user_id !== user.id)
              .map((p) => p.user?.profile?.first_name + ' ' + p.user?.profile?.last_name)
              .join(", ");
}

/**
 * Get a string representation of a user
 *
 * @param {*} name
 * @param {*} num
 * @returns
 */
export function stringAvatar(name, num) {
  return {
    sx: {
      width: num > 2 ? "50%" : "100%",
      height: num > 2 ? "50%" : "100%",
      bgcolor: stringToColor(name),
    },
    children: `${name[0]}`,
  };
}


export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
