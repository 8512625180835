import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
//   import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { fetchJobProgress } from "../JobProgress/jobProgressSlice";
import { formatCrew } from "./utils";

//   const getQueryParams = (params) => {
//     return `?${
//       typeof params === "string"
//         ? params.substring(1)
//         : querystring.stringify(params)
//     }`;
//   };

const namespace = "crew";

export const fetchCrews = createAsyncThunk(
  `${namespace}/fetchCrews`,
  async (nid, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/crews/${nid}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const crews = response.data.map((c) => formatCrew(c));
      return crews;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCrew = createAsyncThunk(
  `${namespace}/postCrew`,
  async (
    { id, params, start, end },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/job_crew`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_jd_crews",
            parent_id: id,
            assign_crew: {
              job_division_nid: id,
              arrival_time: start,
              arrival_end_time: end,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(id));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully assigned Crew`,
        })
      );

      return formatCrew(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error assigning Crew: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchCrew = createAsyncThunk(
  `${namespace}/patchCrew`,
  async (
    { id, params, start, end, division },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/job_crew`,
            },
          },
          _meta: {
            assign_crew: {
              job_division_nid: division,
              arrival_time: start,
              arrival_end_time: end,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated Crew`,
        })
      );

      return formatCrew(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updated Crew: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCrew = createAsyncThunk(
  `${namespace}/deleteCrew`,
  async ({ id }, { getState, rejectWithValue, dispatch }) => {
    try {
      await axios.delete(
        `${config.api_url}/entity/paragraph/${id}`,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully removed Crew`,
        })
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing Crew: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const crewsAdapter = createEntityAdapter({
  selectId: (crew) => crew.id,
});

const crewSlice = createSlice({
  name: namespace,
  initialState: crewsAdapter.getInitialState({
    error: null,
    loading: false,
  }),
  reducers: {},
  extraReducers: {
    [fetchCrews.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [fetchCrews.fulfilled](state, { payload: crews }) {
      state.loading = false;
      crewsAdapter.setAll(state, crews);
    },
    [fetchCrews.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postCrew.pending](state, action) {},
    [postCrew.fulfilled](state, { payload: crew }) {
      crewsAdapter.addOne(state, crew);
    },
    [postCrew.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchCrew.pending](state, action) {},
    [patchCrew.fulfilled](state, { payload: crew }) {
      crewsAdapter.updateOne(state, { id: crew.id, changes: crew });
    },
    [patchCrew.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [deleteCrew.pending](state, action) {
      const { id } = action.meta.arg;
      crewsAdapter.removeOne(state, id);
    },
    [deleteCrew.fulfilled](state, { payload: id }) {},
    [deleteCrew.rejected](state, action) {
      const { crew } = action.meta.arg;
      crewsAdapter.addOne(state, crew);

      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getCrewDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.crew.data
);

export const getCrewsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.crew.loading
);

export const crewsSelectors = crewsAdapter.getSelectors((state) => state.crew);

export default crewSlice.reducer;
