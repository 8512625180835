import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DialogForm } from "common/Dialog";
import {
  DocumentTextIcon,
  PhotographIcon,
  // PlusIcon,
} from "@heroicons/react/solid";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { TextFormField } from "common/Fields";
import { postGroup } from "../groupsSlice";
import { handleUploadToS3 } from "features/Files/utils";
import ButtonLoader from "common/ButtonLoader";
import FileInput from "common/FileInput";
import { Grid } from "@mui/material";

const Create = ({children}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [cover, setCover] = useState(null);
  const [processing, setProcessing] = useState(false);
  // const selectedClient = useSelector((state) => state.app.selectedClient.client);
  const currentEnvironment = useSelector((state) => state.hub.currentEnvironment);

  const token = useSelector(
    (state) => state.auth.user?.data?._processed?.phx_offsite_api_token
  );

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    setFieldValue,
    setFieldError
  ) => {
    setSubmitting(true);
    try {
      const params = {
        ...data,
      };
      // params.client_nid = selectedClient?.nid;
      params.groupable_id = currentEnvironment?.nid;
      params.groupable_type = currentEnvironment.className;

      if (cover) {
        params.banner = {
          mimetypes: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
          destination: "group_covers/",
          thumb: true,
          thumbResize: [300, 300],
          uuid: cover.response.uuid,
          key: cover.response.key,
          bucket: cover.response.bucket,
          name: cover.file.name,
          content_type: cover.file.type,
        };
      }
      await dispatch(postGroup(params)).unwrap();

      handleClose();
    } catch (err) {
      if (err.errors && typeof err.errors === "object") {
        Object.keys(err.errors).forEach((key) => {
          setFieldError(key, err.errors[key]);
        });
      } else {
        setMessage({ id: "negative", msg: err?.message });
      }
    }
    setSubmitting(false);
  };

  const handleUpload = async (file) => {
    setProcessing(true);
    await handleUploadToS3({
      file,
      token,
      setProcessing: null,
      setProgress: null,
      onUploadAction: async (response) => {
        setCover({
          file,
          thumbnail: URL.createObjectURL(file),
          response,
        });
        setProcessing(false);
      },
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <IconButton
          color="secondary"
          size="medium"
          className="bg-white shadow-lg"
          onClick={handleOpen}
        >
          <Add />
        </IconButton>
      )}
      <DialogForm
        open={open}
        onClose={handleClose}
        title="Add Group"
        maxWidth="xs"
        initialValues={{
          name: "",
          description: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <>
            <div className="flex items-center">
              <div className="pr-4">
                <PhotographIcon className="text-gray-medium h-6 w-6" />
              </div>
              <div className="flex-1">
                <Grid container spacing={3}>
                  <Grid item xxs={12}>
                    {cover ? (
                      <FileInput
                        onClick={(file) => handleUpload(file)}
                        accept="image/png, image/jpg, image/gif, image/jpeg"
                      >
                        <div className="h-[150px] w-full rounded-lg">
                          <img
                            src={cover.thumbnail}
                            alt="thumbnail"
                            className="w-full h-full rounded-lg object-cover"
                          />
                        </div>
                      </FileInput>
                    ) : (
                      <FileInput
                        onClick={(file) => handleUpload(file)}
                        accept="image/png, image/jpg, image/gif, image/jpeg"
                      >
                        <ButtonLoader
                          color="secondary"
                          disableElevation
                          isSubmitting={processing}
                        >
                          Add Cover
                        </ButtonLoader>
                      </FileInput>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="flex mt-6">
              <div className="pr-4">
                <DocumentTextIcon className="text-gray-medium h-6 w-6" />
              </div>
              <div className="flex-1">
                <Grid container spacing={3}>
                  <Grid item xxs={12}>
                    <TextFormField
                      name="name"
                      label="Name"
                      fullWidth
                      variant="filled"
                      size="small"
                      required
                      color="secondary"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xxs={12}>
                    <TextFormField
                      name="description"
                      label="Description"
                      multiline
                      fullWidth
                      variant="filled"
                      size="small"
                      color="secondary"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}
      </DialogForm>
    </>
  );
};

Create.propTypes = {};

export default Create;
