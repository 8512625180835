import chroma from "chroma-js";
import { formatField, stringToColor, getUserFields } from "../../utility";
import { first } from "lodash";
import moment from "moment";
import constants from "components/constants";

export const formatUserForTask = (users) => {
  const data = [];
  if (!Array.isArray(users)) return data;
  for (var i = 0; i < users.length; i += 1) {
    const userName =
      users[i].first && users[i].last
        ? `${users[i].first} ${users[i].last}`
        : users[i].name
        ? users[i].name
        : "NA";

    data.push({ name: userName, uid: users[i].uid });
  }

  return data;
};

export const getReminderValues = (reminder) => {
  if (reminder) {
    const values = reminder.split(" ");

    return {
      time: values[0],
      type: values[1],
    };
  } else {
    return {
      time: "1",
      type: "hours",
    };
  }
};

export const reminderHours = () => {
  let hours = [];

  for (let i = 0; i < 10; i += 1) {
    hours = [
      ...hours,
      { value: `${i}.25`, label: `${i}.25` },
      { value: `${i}.5`, label: `${i}.50` },
      { value: `${i}.75`, label: `${i}.75` },
      { value: `${i + 1}`, label: `${i + 1}.00` },
    ];
  }

  return hours;
};

export const repeats = ["YEARLY", "MONTHLY", "WEEKLY", "DAILY"];

export const times = [
  { value: "00:00", name: "12:00 AM" },
  { value: "00:15", name: "12:15 AM" },
  { value: "00:30", name: "12:30 AM" },
  { value: "00:45", name: "12:45 AM" },
  { value: "01:00", name: "1:00 AM" },
  { value: "01:15", name: "1:15 AM" },
  { value: "01:30", name: "1:30 AM" },
  { value: "01:45", name: "1:45 AM" },
  { value: "02:00", name: "2:00 AM" },
  { value: "02:15", name: "2:15 AM" },
  { value: "02:30", name: "2:30 AM" },
  { value: "02:45", name: "2:45 AM" },
  { value: "03:00", name: "3:00 AM" },
  { value: "03:15", name: "3:15 AM" },
  { value: "03:30", name: "3:30 AM" },
  { value: "03:45", name: "3:45 AM" },
  { value: "04:00", name: "4:00 AM" },
  { value: "04:15", name: "4:15 AM" },
  { value: "04:30", name: "4:30 AM" },
  { value: "04:45", name: "4:45 AM" },
  { value: "05:00", name: "5:00 AM" },
  { value: "05:15", name: "5:15 AM" },
  { value: "05:30", name: "5:30 AM" },
  { value: "05:45", name: "5:45 AM" },
  { value: "06:00", name: "6:00 AM" },
  { value: "06:15", name: "6:15 AM" },
  { value: "06:30", name: "6:30 AM" },
  { value: "06:45", name: "6:45 AM" },
  { value: "07:00", name: "7:00 AM" },
  { value: "07:15", name: "7:15 AM" },
  { value: "07:30", name: "7:30 AM" },
  { value: "07:45", name: "7:45 AM" },
  { value: "08:00", name: "8:00 AM" },
  { value: "08:15", name: "8:15 AM" },
  { value: "08:30", name: "8:30 AM" },
  { value: "08:45", name: "8:45 AM" },
  { value: "09:00", name: "9:00 AM" },
  { value: "09:15", name: "9:15 AM" },
  { value: "09:30", name: "9:30 AM" },
  { value: "09:45", name: "9:45 AM" },
  { value: "10:00", name: "10:00 AM" },
  { value: "10:15", name: "10:15 AM" },
  { value: "10:30", name: "10:30 AM" },
  { value: "10:45", name: "10:45 AM" },
  { value: "11:00", name: "11:00 AM" },
  { value: "11:15", name: "11:15 AM" },
  { value: "11:30", name: "11:30 AM" },
  { value: "11:45", name: "11:45 AM" },
  { value: "12:00", name: "12:00 PM" },
  { value: "12:15", name: "12:15 PM" },
  { value: "12:30", name: "12:30 PM" },
  { value: "12:45", name: "12:45 PM" },
  { value: "13:00", name: "1:00 PM" },
  { value: "13:15", name: "1:15 PM" },
  { value: "13:30", name: "1:30 PM" },
  { value: "13:45", name: "1:45 PM" },
  { value: "14:00", name: "2:00 PM" },
  { value: "14:15", name: "2:15 PM" },
  { value: "14:30", name: "2:30 PM" },
  { value: "14:45", name: "2:45 PM" },
  { value: "15:00", name: "3:00 PM" },
  { value: "15:15", name: "3:15 PM" },
  { value: "15:30", name: "3:30 PM" },
  { value: "15:45", name: "3:45 PM" },
  { value: "16:00", name: "4:00 PM" },
  { value: "16:15", name: "4:15 PM" },
  { value: "16:30", name: "4:30 PM" },
  { value: "16:45", name: "4:45 PM" },
  { value: "17:00", name: "5:00 PM" },
  { value: "17:15", name: "5:15 PM" },
  { value: "17:30", name: "5:30 PM" },
  { value: "17:45", name: "5:45 PM" },
  { value: "18:00", name: "6:00 PM" },
  { value: "18:15", name: "6:15 PM" },
  { value: "18:30", name: "6:30 PM" },
  { value: "18:45", name: "6:45 PM" },
  { value: "19:00", name: "7:00 PM" },
  { value: "19:15", name: "7:15 PM" },
  { value: "19:30", name: "7:30 PM" },
  { value: "19:45", name: "7:45 PM" },
  { value: "20:00", name: "8:00 PM" },
  { value: "20:15", name: "8:15 PM" },
  { value: "20:30", name: "8:30 PM" },
  { value: "20:45", name: "8:45 PM" },
  { value: "21:00", name: "9:00 PM" },
  { value: "21:15", name: "9:15 PM" },
  { value: "21:30", name: "9:30 PM" },
  { value: "21:45", name: "9:45 PM" },
  { value: "22:00", name: "10:00 PM" },
  { value: "22:15", name: "10:15 PM" },
  { value: "22:30", name: "10:30 PM" },
  { value: "22:45", name: "10:45 PM" },
  { value: "23:00", name: "11:00 PM" },
  { value: "23:15", name: "11:15 PM" },
  { value: "23:30", name: "11:30 PM" },
  { value: "23:45", name: "11:45 PM" },
];

export const formatTaskForListing = (task) => {
  const end =
    task &&
    task.field_task_date &&
    task.field_task_date[0] &&
    task.field_task_date[0].end_value
      ? task.field_task_date[0].end_value
      : null;
  const start = formatField(task, "field_task_date");

  const user = first(getUserFields(task, "field_task_users"));

  return {
    id: `${formatField(task, "nid")}-${0}`,
    title: formatField(task, "title"),
    entity_id: formatField(task, "nid"),
    recur_delta: "0",
    _start_time: moment(start).format("X"),
    _end_time: moment(end).format("X"),
    status: formatField(task, "field_task_completed"),
    all_day: formatField(task, "field_task_all_day") ? "1" : "0",
    start_time: moment(start).format("X"),
    end_time: moment(end).format("X"),
    uid: user?.uid,
    user_first_name: user?.first,
    user_last_name: user?.last,
    color_code: formatField(task, "field_task_color_code"),
  };
};

export const getStringColor = (s) => {
  const bkg = stringToColor(s);
  const bkgIsDark = chroma(bkg).get("lab.l") < 80 ? true : false;

  return bkgIsDark ? bkg : chroma(bkg).darken(5);
};

export const getFillColor = (event, color) => {
  const complete = event.completed_uid ? true : false;
  const priority = event.priority === "High";

  if (complete) {
    return constants.colorGreen;
  } else if (event.color_code) {
    return event.color_code;
  } else if (event.job_title) {
    return getStringColor(event.job_title);
  } else if (color) {
    return color;
  } else if (complete) {
    return constants.colorGreen;
  } else if (priority) {
    return "#F4511E";
  } else {
    return constants.colorBlueAlt;
  }
};

export const getTextColor = (event, color) => {
  return "white";
};
