import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Menu,
  ListItemIcon,
  Checkbox,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  InputBase,
  DialogContent,
  DialogActions,
  DialogTitle as MuiDialogTitle,
  TablePagination,
  MenuItem,
} from "@mui/material";
import { BiSearchAlt } from "react-icons/bi";

import { useDebounce } from "hooks";
import LinkHeader from "common/LinkHeader";
import Dialog from "common/Dialog";
import { MuiTable } from "common/Table";
import { bundles } from "./utils";

import {
  clearResults,
  fetchSearchResults,
  getSearchLoadingSelector,
  getSearchPaginationSelector,
  searchSelectors,
} from "./searchSlice";

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    background: "var(--gradient-black)",
    color: "var(--color-gray-lightest)",
    // borderBottom: "1px solid var(--color-gray-medium)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "var(--color-gray-lightest)",
    border: "1px solid var(--color-gray-dark)",
    padding: "0 0.5rem",
  },
  iconButton: {
    padding: 10,
    color: "var(--color-gray-lightest)",
  },
  divider: {
    height: 28,
    margin: 4,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    children,
    classes,
    onClose,
    id,
    progress,
    fullScreen,
    value,
    setValue,
    filters,
    setFilters,
    ...other
  } = props;
  const acctDetailAccess = useSelector(
    (state) => state.auth.user?.data?._processed?.accounting_detail_access
  );

  const handleToggle = (value) => (e) => {
    const index = filters.indexOf(value);
    const tempfilters = [...filters];
    if (index >= 0) {
      tempfilters.splice(index, 1);
    } else {
      tempfilters.push(value);
    }

    setFilters([...tempfilters]);
  };

  const isChecked = (value) => {
    return filters.indexOf(value) >= 0 ? true : false;
  };

  return (
    <MuiDialogTitle className={classes.root} id={id} {...other}>
      <IconButton
        className={classes.iconButton}
        aria-label="menu"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <SearchIcon />
      <Menu
        id="Search Filters"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem value="job_division" onClick={handleToggle("job_division")}>
          <ListItemIcon>
            <Checkbox checked={isChecked("job_division")} size="small" />
          </ListItemIcon>
          <Typography variant="inherit">Job Division</Typography>
        </MenuItem>
        <MenuItem onClick={handleToggle("member")}>
          <ListItemIcon>
            <Checkbox checked={isChecked("member")} size="small" />
          </ListItemIcon>
          <Typography variant="inherit">Provider</Typography>
        </MenuItem>
        <MenuItem onClick={handleToggle("customer")}>
          <ListItemIcon>
            <Checkbox checked={isChecked("customer")} size="small" />
          </ListItemIcon>
          <Typography variant="inherit">Customer</Typography>
        </MenuItem>
        <MenuItem onClick={handleToggle("customer_location")}>
          <ListItemIcon>
            <Checkbox checked={isChecked("customer_location")} size="small" />
          </ListItemIcon>
          <Typography variant="inherit">Customer Location</Typography>
        </MenuItem>
        {acctDetailAccess &&
          <MenuItem onClick={handleToggle("job_division_accounting")}>
            <ListItemIcon>
              <Checkbox checked={isChecked("job_division_accounting")} size="small" />
            </ListItemIcon>
            <Typography variant="inherit">Accounting</Typography>
          </MenuItem>
        }
      </Menu>
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {/* <IconButton
        color="primary"
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        disabled
        size="large"
      >
        <SearchIcon />
      </IconButton> */}
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const formatFilterArray = (arr, field) => {
  const filters = {};
  arr.forEach((filter, i) => {
    filters[`filter[${field}][${i}]`] = filter;
  });

  return filters;
};

const Search = (props) => {
  const dispatch = useDispatch();
  const results = useSelector(searchSelectors.selectAll);
  const pagination = useSelector(getSearchPaginationSelector);
  const loading = useSelector(getSearchLoadingSelector);

  const [showSearch, setShowSearch] = useState(false);
  const [checkShowSearch, setCheckShowSearch] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);
  const [value, setValue] = useState("");
  const debouncedQuery = useDebounce(value, 250);

  useEffect(() => {
    let promise;
    if (debouncedQuery) {
      promise = dispatch(
        fetchSearchResults({
          query: debouncedQuery,
          params: {
            page,
            ...formatFilterArray(filters, "node_bundle"),
          },
        })
      );
    } else {
      dispatch(clearResults());
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [debouncedQuery, dispatch, page, filters]);

//  Putting another state toggle in a useEffect prevents the dialog from staying open after navigating to a new page
  useEffect(() => {
    setCheckShowSearch(showSearch);
  }, [showSearch, setCheckShowSearch]);

  const columns = useMemo(
    () => [
      {
        id: "name",
        label: "Results",
        minWidth: 300,
        notSortable: true,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      results.map((result) => ({
        name: (
          <div>
            <LinkHeader
              to={result?._paths?.node}
              onClick={() => setShowSearch(false)}
              size="medium"
              color="secondary"
            >
              {result.title}
            </LinkHeader>
            <Typography color="textSecondary">
              {bundles[result.node_bundle]}
            </Typography>
          </div>
        ),
      })),
    [results]
  );

  return (
    <>
      <Tooltip title="Search">
        <IconButton
          onClick={() => setShowSearch(true)}
          style={{ color: "white" }}
          size="large"
        >
          <BiSearchAlt />
        </IconButton>
      </Tooltip>
      <Dialog
        open={checkShowSearch}
        maxWidth="xs"
        onClose={() => setShowSearch(false)}
      >
        <DialogTitle
          value={value}
          setValue={setValue}
          filters={filters}
          setFilters={setFilters}
          onClose={() => {setShowSearch(false);}}
        />
        <DialogContent style={{ padding: 0 }}>
          <MuiTable
            rows={data}
            columns={columns}
            loading={loading}
            size="medium"
            handleChangePage={(e, newPage) => setPage(newPage)}
            disableHeader
          />
        </DialogContent>
        <DialogActions
          style={{
            background: "var(--color-gray-lightest)",
            borderTop: "1px solid var(--color-gray-medium)",
            padding: 0,
          }}
        >
          <TablePagination
            rowsPerPageOptions={[]}
            labelRowsPerPage="Rows:"
            component="div"
            count={Number(pagination.count)}
            rowsPerPage={
              pagination.per_page_total ? Number(pagination.per_page_total) : 25
            }
            page={pagination.current_page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

Search.propTypes = {};

export default Search;
