import useMediaQuery from "@mui/material/useMediaQuery";

const TableColumnHider = ({ column, children }) => {
//  The <Hidden> component is deprecated in Material UI v5.0.0-alpha.37; so, use this method instead.
  const hidden = useMediaQuery(theme => {
    if(column?.hiddenProps?.only){
      return theme.breakpoints.only(column.hiddenProps.only[0])
    }
    else if(column?.hiddenProps?.up){
      return theme.breakpoints.up(column.hiddenProps.up[0])
    }
    else if(column?.hiddenProps?.down){
      return theme.breakpoints.down(column.hiddenProps.down[0])
    }
    else if(column?.hiddenProps?.not){
      return theme.breakpoints.not(column.hiddenProps.not[0])
    }
    else if(column?.hiddenProps?.between){
      return theme.breakpoints.between(column.hiddenProps.between[0], column.hiddenProps.between[1])
    }
    return '@media (min-width:999999px)';
  });
  if(hidden) return null;

  return children;
};

TableColumnHider.propTypes = {};

export default TableColumnHider;
