import React, { useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toArray } from "lodash";
import {
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { DialogForm } from "common/Dialog";
import { AutocompleteFormField } from "common/Fields";
import { TextFormField } from "common/Fields";

import { postDuplicateJobOverride } from "../../jobSlice";
import {
  fetchUsers,
  getUsersLoadingSelector,
  usersAllSelector,
} from "features/Users/usersSlice";
import {
  complianceSelectors,
  fetchMemberCompliance,
  getComplianceLoadingSelector,
} from "features/Compliance/complianceSlice";

const ComplianceOverride = ({
  open,
  onClose,
  setFieldValue,
  locationName,
  id,
}) => {
  const dispatch = useDispatch();
  const users = useSelector(usersAllSelector.selectAll);
  const loadingUsers = useSelector(getUsersLoadingSelector);
  const compliance = useSelector(complianceSelectors.selectAll);
  const loadingMember = useSelector(getComplianceLoadingSelector);

  const getComplianceReasons = useCallback((data) => {
    const reasons = [];
    if (!data) return reasons;
    data.forEach((reason) => {
      if (!reason.lift_date) {
        reasons.push(reason.compliance_change_reason);
      }
    });
    return reasons;
  }, []);

  useEffect(() => {
    const promise = dispatch(fetchMemberCompliance({ id }));

    return () => {
      promise.abort();
    };
  }, [id, dispatch]);

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchUsers({
          params: {
            keywords: query,
          },
        })
      );
    },
    [dispatch]
  );

  const handleClose = () => {
    setFieldValue("field_assigned_member", null);
    onClose();
  };

  const changeReasons = useMemo(
    () => getComplianceReasons(compliance),
    [compliance, getComplianceReasons]
  );

  return (
    <DialogForm
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      title="Compliance Override"
      disablePadding
      loading={loadingMember}
      initialValues={{
        field_override_reason: "",
        field_approved_by: null,
      }}
      onSubmit={async (
        data,
        setSubmitting,
        setMessage,
        successCallback,
        errorCallback
      ) => {
        setMessage(null);

        const params = {
          field_approved_by: [{ target_id: data.field_approved_by.uid }],
          field_override_reason: [{ value: data.field_override_reason }],
        };

        try {
          const resultAction = await dispatch(postDuplicateJobOverride(params));
          unwrapResult(resultAction);
          setFieldValue("field_override_log_entry", resultAction.payload.id);
          onClose();
        } catch (err) {
          setFieldValue("field_assigned_member", null);
          console.log(err);
        }
      }}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <DialogContent>
          <Typography>
            The Member you are trying to assign is out of compliance for the
            following reasons.
          </Typography>
          <List>
            {changeReasons.map((reason, i) => (
              <ListItem key={i}>
                <ListItemText>{reason}</ListItemText>
              </ListItem>
            ))}
          </List>
          <TextFormField
            name="field_override_reason"
            label="Override Reason"
            labelwidth={105}
            multiline
            variant="filled"
            rows={4}
            fullWidth
            margin="normal"
            required
          />
          <AutocompleteFormField
            name="field_approved_by"
            label="Approved By"
            disabled={isSubmitting}
            options={toArray(users).map((user) => ({
              name: user.name,
              uid: user.uid,
            }))}
            loading={loadingUsers}
            fetchData={getUsers}
            variant="filled"
            size="small"
          />
        </DialogContent>
      )}
    </DialogForm>
  );
};

ComplianceOverride.propTypes = {};

export default ComplianceOverride;
