import * as Yup from "yup";
import constants from "components/constants";

export const addDKIJobSchema = Yup.object().shape({
  customer: Yup.object().typeError("Required").required("Required"),
  location: Yup.object().typeError("Required").required("Required"),
});

export const addDKIJobDivisionSchema = Yup.object().shape({
  type: Yup.string().required("Required"),
  field_work_area_tax: Yup.number().typeError("Must be a number"),
});

export const addResidentialJobSchema = Yup.object().shape({
  field_assigned_member: Yup.object()
    .typeError("Required")
    .required("Required"),
  field_referred_by_text: Yup.string("Must be a string"),
  field_referred_by_phone: Yup.string().matches(
    constants.phoneRegExp,
    "Phone number is not valid"
  ),
  field_jd_mf_caller: Yup.string("Must be a string"),
  field_jd_mf_caller_phone: Yup.string().matches(
    constants.phoneRegExp,
    "Phone number is not valid"
  ),
  field_street_address: Yup.object().shape({
    address_line1: Yup.string("Must be a string").required("Required"),
    address_line2: Yup.string("Must be a string"),
    administrative_area: Yup.string("Must be a string").required("Required"),
    locality: Yup.string("Must be a string").required("Required"),
    postal_code: Yup.string("Must be a string").required("Required"),
  }),
  customer_location_title: Yup.string("Must be a string").required("Required"),
  field_cl_year_built: Yup.string("Must be a string"),
  customer_location_field_phone: Yup.string().matches(
    constants.phoneRegExp,
    "Phone number is not valid"
  ),
  customer_location_field_phone_after_hours: Yup.string().matches(
    constants.phoneRegExp,
    "Phone number is not valid"
  ),
  field_cl_email: Yup.string().email(),
  field_pref_contact_method: Yup.string("Must be a string"),
  field_loss_date: Yup.string("Must be a string"),
  field_jd_eta_range: Yup.object().shape({
    date: Yup.string("Must be a string"),
    value: Yup.object().shape({
      name: Yup.string("Must be a string"),
      value: Yup.string("Must be a string"),
    }),
    end_value: Yup.object().shape({
      name: Yup.string("Must be a string"),
      value: Yup.string("Must be a string"),
    }),
  }),
  field_cause: Yup.string("Must be a string"),
  field_cause_select: Yup.string("Must be a string"),
  field_run_time_text: Yup.string("Must be a string"),
  field_run_time_select: Yup.string("Must be a string"),
  field_mf_effected_level_qty: Yup.string("Must be a string"),
  field_mf_hw_wet_sqft: Yup.string("Must be a string"),
  field_details: Yup.string("Must be a string"),
});

export const addExistingResidentialJobSchema = Yup.object().shape({
  field_customer: Yup.object().typeError("Required").required("Required"),
  field_customer_location: Yup.object()
    .typeError("Required")
    .required("Required"),
  field_assigned_member: Yup.object()
    .typeError("Required")
    .required("Required"),
  field_jd_mf_caller: Yup.string("Must be a string"),
  field_jd_mf_caller_phone: Yup.string().matches(
    constants.phoneRegExp,
    "Phone number is not valid"
  ),
  field_loss_date: Yup.string("Must be a string"),
  field_jd_eta_range: Yup.object().shape({
    date: Yup.string("Must be a string"),
    value: Yup.object().shape({
      name: Yup.string("Must be a string"),
      value: Yup.string("Must be a string"),
    }),
    end_value: Yup.object().shape({
      name: Yup.string("Must be a string"),
      value: Yup.string("Must be a string"),
    }),
  }),
  field_cause: Yup.string("Must be a string"),
  field_cause_select: Yup.string("Must be a string"),
  field_run_time_text: Yup.string("Must be a string"),
  field_run_time_select: Yup.string("Must be a string"),
  field_mf_effected_level_qty: Yup.string("Must be a string"),
  field_mf_hw_wet_sqft: Yup.string("Must be a string"),
  field_details: Yup.string("Must be a string"),
});

export const addDKICompleteJobSchema = Yup.object().shape({
  field_customer: Yup.object().typeError("Required").required("Required"),
  field_customer_location: Yup.object()
    .typeError("Required")
    .required("Required"),
  field_phx_client_rep: Yup.string("Must be a string"),
  field_phoenix_client: Yup.string("Must be a string"),
  field_loss_category: Yup.string("Must be a string"),
  field_date: Yup.string("Must be a string").required("Required"),
  field_reported_by: Yup.string("Must be a string"),
  field_note: Yup.string("Must be a string"),
  field_assigned_member: Yup.object()
    .typeError("Required")
    .required("Required"),
  field_job_division_type: Yup.string("Must be a string").required("Required"),
  field_reference_num: Yup.string("Must be a string"),
  field_jd_status: Yup.string("Must be a string").required("Required"),
  field_nte: Yup.string("Must be a string"),
  field_work_area_tax: Yup.string("Must be a string"),
  field_source_loss: Yup.string("Must be a string"),
  field_jd_mf_caller: Yup.string("Must be a string"),
  field_jd_mf_caller_phone: Yup.string().matches(
    constants.phoneRegExp,
    "Phone number is not valid"
  ),
});
