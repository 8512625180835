import {
  formatField,
  formatFieldByValue,
  getPathFromLinksSelf,
  // getUserFields,
} from "../../utility";



export const formatInvoice = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/job_division_accounting_invoice",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_jadi_job_number: formatField(d, "field_jadi_job_number"),
    field_jdai_amount: formatField(d, "field_jdai_amount"),
    field_jdai_customer_name_address: formatField(
      d,
      "field_jdai_customer_name_address"
    ),
    field_jdai_customer_notes: formatField(d, "field_jdai_customer_notes"),
    field_jdai_file_fee: formatField(d, "field_jdai_file_fee"),
    field_jdai_g2_markup: formatField(d, "field_jdai_g2_markup"),
    field_jdai_job_name: formatField(d, "field_jdai_job_name"),
    field_jdai_job_notes: formatField(d, "field_jdai_job_notes"),
    field_jdai_loss_type: formatField(d, "field_jdai_loss_type"),
    field_jdai_tax_id: formatField(d, "field_jdai_tax_id"),
    field_jdai_work_order: formatField(d, "field_jdai_work_order"),
  };
};
