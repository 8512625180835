import React, { useState } from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { ClickAwayListener, Typography, Popper, Grid } from "@mui/material";
import { size } from "lodash";
import { GoSettings } from "react-icons/go";

import Button from "common/Button";
import { KeywordFilter } from "./Filters";

const useStyles = makeStyles((theme) => ({
  popper: {
    border: "1px solid var(--color-gray-dark-alpha)",
    boxShadow: "0 3px 12px var(--color-gray-darker-alpha)",
    borderRadius: 3,
    width: 250,
    zIndex: 1000,
    fontSize: 13,
    color: "var(--color-gray-dark)",
    backgroundColor: "var(--color-gray-light)",
  },
  header: {
    padding: "8px 10px",
    fontWeight: 600,
  },
}));

const FilterMenu = ({ filters, search, fillBackground, embedded }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const lessThan = useSelector((state) => state.browser.lessThan);

  const offset = lessThan.large || embedded ? 0 : size(filters) <= 4 ? 4 : 3;
  const hasFilters = size(filters) > 0;
  return (
    <div
      style={{
        padding: "0.625rem 1.25rem 0.625rem 1.25rem",
        background: fillBackground ? "var(--color-gray-background)" : "unset",
        borderBottom: fillBackground
          ? "1px solid var(--color-gray-medium)"
          : "none",
      }}
    >
      <Grid container spacing={2}>
        {search && (
          <Grid item xxs={hasFilters ? 8 : 12} md={hasFilters ? (embedded ? 6 : 4) : 12}>
            <KeywordFilter query={search.query} setQuery={search.setQuery} />
          </Grid>
        )}
        <>
          {filters.slice(0, offset).map((filter, i) => (
            <Grid key={i} item xxs={12} md={2}>
              {React.cloneElement(filter.component, { ...filter.props })}
            </Grid>
          ))}
          {hasFilters && offset < 4 && (
            <>
              <Grid item xxs={4} md={embedded ? 6 : 2}>
                <Typography
                  variant="caption"
                  color="primary"
                  style={{
                    textDecoration: "underline",
                    marginBottom: "0.25rem",
                    display: "inline-block",
                    opacity: 0,
                    pointerEvents: "none",
                    cursor: "pointer",
                  }}
                >
                  Clear
                </Typography>
                <Button
                  fullWidth
                  color="default"
                  variant="outlined"
                  startIcon={<GoSettings />}
                  style={{ background: "white", height: 38.81 }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  Filters
                </Button>
                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  placement="bottom-end"
                  className={classes.popper}
                >
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <div>
                      <div className={classes.header}>More Filters</div>
                      <div
                        style={{
                          padding: "1rem",
                          width: "248px",
                          borderTop: "1px solid var(--color-gray-dark-alpha)",
                          background: "white",
                        }}
                      >
                        <Grid container spacing={0}>
                          {filters
                            .slice(offset, size(filters))
                            .map((filter, i) => (
                              <Grid key={i} item xxs={12}>
                                {React.cloneElement(filter.component, {
                                  ...filter.props,
                                })}
                              </Grid>
                            ))}
                        </Grid>
                      </div>
                    </div>
                  </ClickAwayListener>
                </Popper>
              </Grid>
            </>
          )}
        </>
      </Grid>
    </div>
  );
};

FilterMenu.propTypes = {};

export default FilterMenu;
