import React from "react";
import Layout from "@phx/common/Layout";
import styled from "styled-components";
import { Typography } from "@mui/material";

const icon = `${process.env.PUBLIC_URL}/images/helix_icon.svg`;

const Styles = styled.div`
  padding: 1.25rem;
  max-width: 700px;
`;

const Privacy = (props) => {
  return (
    <div
      style={{
        marginTop: "-62px",
        minHeight: "calc(var(--vh, 1vh) * 100)",
        background: "white",
      }}
    >
      <Layout
        name="Privacy Policy"
        title="Privacy Policy"
        meta="Privacy Policy."
        icon={{ src: icon, alt: "HLX" }}
        loading={false}
      >
        <Styles>
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            Helix Co, LLC ("Company", "We", or “Us”) respect your privacy and
            are committed to protecting it through our compliance with this
            policy. This policy describes:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                The types of information we may collect or that you may provide
                when you access or use the Helix Platform (the ″SaaS″
                Platform″).
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Our practices for collecting, using, maintaining, protecting,
                and disclosing that information.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            This policy applies only to information we collect in the SaaS
            Platform.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            This policy DOES NOT apply to information that:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                We collect offline or on any other Company apps or websites,
                including websites you may access through this SaaS Platform.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You provide to or is collected by any third party (see
                Third-Party Information Collection).
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            Our websites and apps, and these other third parties may have their
            own privacy policies, which we encourage you to read before
            providing information on or through them. Please read this policy
            carefully to understand our policies and practices regarding your
            information and how we will treat it. If you do not agree with our
            policies and practices, do not use the SaaS Platform. By using the
            SaaS Platform, you agree to this privacy policy. This policy may
            change from time to time (see Changes to Our Privacy Policy). Your
            continued use of the SaaS Platform after we revise this policy means
            you accept those changes, so please check the policy periodically
            for updates.
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Children Under the Age of 16
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            The SaaS Platform is not intended for children under 16 years of
            age, and we do not knowingly collect personal information from
            children under 16. If we learn we have collected or received
            personal information from a child under 16 without verification of
            parental consent, we will delete that information. If you believe we
            might have any information from or about a child under 16, please
            contact us at privacy@helixco.io.
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Information We Collect and How We Collect It
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            We collect information from and about users of the SaaS Platform:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Directly from you when you provide it to us.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Automatically when you use the SaaS Platform.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Information You Provide to Us
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            When you use the SaaS Platform, we may ask you provide information:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                By which you may be personally identified, such as name, work
                address, email address, telephone number, or ANY OTHER
                INFORMATION THE SAAS PLATFORM COLLECTS THAT IS DEFINED AS
                PERSONAL OR PERSONALLY IDENTIFIABLE INFORMATION UNDER AN
                APPLICABLE LAW ("personal information").
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            This information includes:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Information that you provide by filling in forms in the SaaS
                Platform. This includes information provided at the time of
                registering to use the SaaS Platform, posting material, and
                requesting additional services. We may also ask you for
                information when you report a problem with the SaaS Platform.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Records and copies of your correspondence (including email
                addresses and phone numbers), if you contact us.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Your search queries on the SaaS Platform.
              </Typography>
            </li>
          </ul>

          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            Information transmitted to, stored within and/or used by the SaaS
            Platform (collectively, "User Contributions"), is intended to be
            shared with other end-users, including end-users who are not
            employed by your employer. Your User Contributions are Posted and
            transmitted to others at your own risk and without any legitimate
            expectation of privacy. Additionally, we cannot control the actions
            of third parties with whom you may choose to share your User
            Contributions. Therefore, we cannot and do not guarantee that your
            User Contributions will not be viewed by unauthorized persons.
          </Typography>

          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Automatic Information Collection
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            When use the SaaS Platform, it may use technology to automatically
            collect:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Usage Details. When you access and use the SaaS Platform, we may
                automatically collect certain details of your access to and use
                of the SaaS Platform, including traffic data, location data,
                logs, and other communication data and the resources that you
                access and use on or through the SaaS Platform.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Device Information. We may collect information about your device
                and internet connection, including the device's unique device
                identifier, IP address, operating system, browser type, mobile
                network information, and, for devices with a telephone number,
                that device's telephone number.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Location Information. The SaaS Platform collects geo location
                information of your device upon login, including latitude,
                longitude and address.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Information Collection Technologies
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            The technologies we use for automatic information collection may
            include:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Cookies (or mobile cookies). A cookie is a small file placed on
                your computer or smartphone. It may be possible to refuse to
                accept cookies by activating the appropriate setting on your
                device. However, if you select this setting you may be unable to
                access certain parts of the SaaS Platform.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Web Beacons. Pages of the SaaS Platform may contain small
                electronic files known as web beacons (also referred to as clear
                gifs, pixel tags, and single-pixel gifs) that permit the
                Company, for example, to count users who have visited those
                pages and for other related statistics (for example, recording
                the popularity of certain content and verifying system and
                server integrity).
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Third-Party Information Collection
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            Because you access the SaaS Platform through a web browser, when you
            use the SaaS Platform, certain third parties may use automatic
            information collection technologies to collect information about you
            or your device. These third parties may include:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">Analytics companies.</Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Your Internet Service Provider
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Your mobile device manufacturer.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Your mobile service provider.
              </Typography>
            </li>
          </ul>

          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            How We Use Your Information
          </Typography>

          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Third-Party Information Collection
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            We use information that we collect about you or that you provide to
            us, including any personal information, to:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Provide you with the SaaS Platform and its contents, and any
                other information, products or services that you request from
                us.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Fulfill any other purpose for which you provide it.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Give you notices about your account.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Notify you when SaaS Platform updates are available, and of
                changes to the SaaS Platform.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            The usage information we collect helps us to improve the SaaS
            Platform and to deliver a better and more personalized experience by
            enabling us to:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Estimate our audience size and usage patterns.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Store information about your preferences, allowing us to
                customize the SaaS Platform according to your individual
                interests.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">Speed up your searches.</Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Recognize you when you use the SaaS Platform.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                We use location information we collect to perform geo fencing
                functions, to provide location-driven advertisements, and to
                assist in dispatch and routing.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Disclosure of Your Information
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            We may disclose aggregated information about our users, and
            information that does not identify any individual or device, without
            restriction. In addition, we may disclose personal information that
            we collect or you provide:
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                To our subsidiaries and affiliates.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                To contractors, service providers, and other third parties we
                use to support our business.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Company's assets,
                whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which personal
                information held by Company about the SaaS Platform users is
                among the assets transferred.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                To fulfill the purpose for which you provide it.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                For any other purpose disclosed by us when you provide the
                information.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">With your consent.</Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                To enforce our rights arising from any contracts entered into
                between you and us, including the SaaS Platform EULA, and for
                billing and collection.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of Company, our customers or
                others.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Your Choices About Our Collection, Use, and Disclosure of Your
            Information
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            We strive to provide you with choices regarding the personal
            information you provide to us. This section describes mechanisms we
            provide for you to control certain uses and disclosures of over your
            information.
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Tracking Technologies. You can set your browser to refuse all or
                some browser cookies, or to alert you when cookies are being
                sent. If you disable or refuse cookies or block the use of other
                tracking technologies, some parts of the SaaS Platform may then
                be inaccessible or not function properly.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Location Information. You can choose whether or not to allow the
                SaaS Platform to collect and use information about your device's
                location. If you block the use of location information, some
                parts of the SaaS Platform may become inaccessible or not
                function properly.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Data Security
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            We have implemented measures designed to secure your personal
            information from accidental loss and from unauthorized access, use,
            alteration, and disclosure.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            The safety and security of your information also depends on you.
            Where we have given you (or where you have chosen) a password for
            access to the SaaS Platform, you are responsible for keeping this
            password confidential. We ask you not to share your password with
            anyone. We urge you to be careful about giving out information in
            SaaS Platform. The information you share may be viewed by any user
            of the SaaS Platform.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            Unfortunately, the transmission of information via the internet and
            mobile platforms is not completely secure. Although we do our best
            to protect your personal information, we cannot guarantee the
            security of your personal information transmitted through the SaaS
            Platform. Any transmission of personal information is at your own
            risk. We are not responsible for circumvention of any privacy
            settings or security measures we provide.
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Changes to Our Privacy Policy
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            We may update our privacy policy from time to time. If we make
            material changes to how we treat our users' personal information, we
            will post the new privacy policy on this page. The date the privacy
            policy was last revised is identified at the top of the page. You
            are for periodically visiting this privacy policy to check for any
            changes.
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Contact Information
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            To ask questions or comment about this privacy policy and our
            privacy practices, contact us at: privacy@helixco.io
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            CL2:527111_v1
          </Typography>
        </Styles>
      </Layout>
    </div>
  );
};

Privacy.propTypes = {};

export default Privacy;
