import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: ${(props) => (props.fullWidth ? "block" : "inline-block")};
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = styled.div``;
