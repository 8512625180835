import action from "../action";
import config from "../../config";

export const getReportAccounting = (params) =>
  action("report", config.api_url, `rest/report/job-accountings`, params);

export const getReportAccountingDKI = (params) =>
  action("report", config.api_url, `rest/report/job-accountings/1`, params);

export const getReportWeeklyAccountingDKI = (params) =>
  action(
    "report",
    config.api_url,
    `rest/report/job-accountings/weekly/1`,
    params
  );

export const getReportMembers = (params) =>
  action("report", config.api_url, `rest/report/members`, params);

export const getReportCustomers = (params) =>
  action("report", config.api_url, `rest/report/customers`, params);

export const getReportCustomerLocations = (params) =>
  action("report", config.api_url, `rest/report/customer_locations`, params);

export const getReportCustomerNotes = (params) =>
  action("report", config.api_url, `rest/report/customer_notes`, params);

export const getReportJobsAging = (params) =>
  action("report", config.api_url, `rest/report/jobs/aging`, params);

export const getReportJobsAgingSpan = (params) =>
  action("report", config.api_url, `rest/report/jobs/aging/1`, params);

export const getReportComplianceDocs = (params) =>
  action(
    "report",
    config.api_url,
    `rest/report/members/compliance-documents`,
    params
  );

export const getReportJobDivisions = (params) =>
  action("report", config.api_url, `rest/report/job-divisions`, params);

export const getReportMemberNotes = (params) =>
  action("report", config.api_url, `rest/report/member_notes`, params);

export const getReportCustomerUsers = (params) =>
  action("report", config.api_url, `rest/report/customer/users`, params);

export const getReportMemberUsers = (params) =>
  action("report", config.api_url, `rest/report/member/users`, params);

export const getReportMembersOverride = (params) =>
  action("report", config.api_url, `rest/report/override/members`, params);

export const getReportJobsOverride = (params) =>
  action(
    "report",
    config.api_url,
    `rest/report/override/duplicate-jobs`,
    params
  );

export const getReportWipDetail = (params) =>
  action("report", config.api_url, `rest/report/job/wip-detail`, params);

export const getReportWipDetailParent = (params) =>
  action(
    "report",
    config.api_url,
    `rest/report/job/wip-detail/parent-customer`,
    params
  );

export const getReportWipOverview = (params) =>
  action("report", config.api_url, `rest/report/job/wip-overview`, params);

export const getReportHpnSelect = (params) =>
  action("report", config.api_url, `rest/report/jobs/hpn-select`, params);

export const getReportTimeEntries = (params) =>
  action("report", config.api_url, `rest/report/time-entries`, params);

export const getReportTerritories = (params) =>
  action("report", config.api_url, `rest/report/member/territories`, params);

export const getReportAgreements = (params) =>
  action("report", config.api_url, `rest/report/member/agreements`, params);

export const getReportTerminations = (params) =>
  action("report", config.api_url, `rest/report/member/terminations`, params);

export const getReportOwners = (params) =>
  action("report", config.api_url, `rest/report/member/owners`, params);

export const getReportEmployees = (params) =>
  action("report", config.api_url, `rest/report/member/employees`, params);

export const getReportAddresses = (params) =>
  action("report", config.api_url, `rest/report/member/addresses`, params);

export const getReportMemberFullListing = (params) =>
  action("report", config.api_url, `rest/report/member/full_listing`, params);

export const getReportEntityName = (params) =>
  action("report", config.api_url, `rest/report/member/entity_details`, params);

export const getReportXactimate = (params) =>
  action(
    "report",
    config.api_url,
    `rest/report/member/financials/xactimate`,
    params
  );

export const getReportMemberCoverage = (params) =>
  action("report", config.api_url, `rest/report/member/coverage`, params);

export const getReportMemberCoverageGaps = (params) =>
  action("report", config.api_url, `rest/report/member/coverage/gaps`, params);

export const getReportMemberProximity = (params) =>
  action("report", config.api_url, `rest/report/job/member-proximity`, params);

export const getReportMemberAssignmentAppearance = (params) =>
  action("report", config.api_url, `rest/report/members/assignment-appearances`, params);

export const getReportMemberDeclined = (params) =>
  action(
    "report",
    config.api_url,
    `rest/report/assignment-answers/member/declined`,
    params
  );
