import React from "react";
import { Button } from "@mui/material";

const icon = `${process.env.PUBLIC_URL}/android-chrome-512x512.png`;

export const notification = ( {data, isSubNotication, currentUser, onDismiss} ) => {
  const handleAnswer = (e, answer) => {
    data.onAnswer(answer)
    onDismiss(e);
  }

  return {
    'icon': (
      <img src={icon} alt="No Data to display" />
    ),
    'body': (
      <div className="flex flex-col justify-between h-full">
        <div>Allow Browser Notifications?</div>
        <div className="flex-[1_0_auto] px-1 italic text-[0.875rem]">
          (If allowed, your browser will ask you again)
        </div>
        <div className="flex justify-between">
          <Button
            onClick={(e) => handleAnswer(e, false)}
            className="text-gray-medium"
          >
            Dismiss
          </Button>
          <Button
            onClick={(e) => handleAnswer(e, true)}
            color="secondaryInverse"
          >
            Allow
          </Button>
        </div>
      </div>
    )
  };
};

