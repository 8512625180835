import action from "../action";
import config from "../../config";

export const clearMapData = (type) => ({
  type: `CLEAR/${type}`,
});

export const getMapJobs = (start, end, archived, params) => {
  const urlParams = [];
  if (start && end) urlParams.push(start, end);
  if (archived) urlParams.push(archived);

  return action(
    "mapJobs",
    config.api_url,
    `rest/map/jobs/${urlParams.join("/")}`,
    params
  );
};

export const getMapMembers = (affiliates = 0, archived = 0, params) =>
  action(
    "mapMembers",
    config.api_url,
    `rest/map/members/${affiliates}/${archived}`,
    params
  );

export const getMapCustomers = (archived = 0, params = {}) =>
  action(
    "mapCustomers",
    config.api_url,
    `rest/map/customers/${archived}`,
    params
  );

export const getMapCustomerLocations = (archived = 0, params = {}) =>
  action(
    "mapCustomerLocations",
    config.api_url,
    `rest/map/customer/locations/${archived}`,
    params
  );

export const getMapSearch = (query, archived = 0) =>
  action("mapSearch", config.api_url, `rest/map/search/${query}/${archived}`);

export const getMapZones = (params) =>
  action("zones", config.api_url, `rest/map/zones`, params);

export const postZone = (params) =>
  action("zones", config.api_url, `node`, params, "post");

export const patchZone = (nid, params) =>
  action("zones", config.api_url, `node/${nid}`, params, "patch");

export const postMapLocation = (params) =>
  action("mapLocations", config.api_url, `node`, params, "post");

export const postMapGroup = (params) =>
  action("mapGroups", config.api_url, `node`, params, "post");

export const getMapGroups = (params) =>
  action("mapGroups", config.api_url, `rest/map/groups`, params);

export const getMapLocations = (params) =>
  action("mapLocations", config.api_url, `rest/map/locations`, params);

export const patchMapLocation = (nid, params) =>
  action("mapLocations", config.api_url, `node/${nid}`, params, "patch");

export const deleteZone = (nid, params) =>
  action("zones", config.api_url, `node/${nid}`, params, "delete");
