import { useEffect, useState } from "react";
import axios from "axios";

import config from "../config";
import { convertBlobToBase64, getFileDimensions } from "../utility";

let _is_mounted = false;

const useImageLoad = (src, token, includesBaseUrl) => {
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    _is_mounted = true;

    return () => {
      _is_mounted = false;
    };
  }, []);

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    if (src) {
      setLoading(true);
      const url = `${includesBaseUrl ? "" : config.api_url}${src}`;
      axios
        .get(url, {
          withCredentials: true,
          cancelToken: axiosCancelSource.token,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .then(convertBlobToBase64)
        .then((res) => {
          setBase64(res);
          // setLoading(false);
          getFileDimensions(res)
            .then((res) => {
              setWidth(res.naturalWidth);
              setHeight(res.naturalHeight);
              setLoading(false);
            })
            .catch((err) => {
              if (_is_mounted) {
                setLoading(false);
              }
              console.log(err);
            });
        })
        .catch((err) => {
          if (_is_mounted) {
            setLoading(false);
          }
          console.log(err);
        });
    }

    return () => {
      axiosCancelSource.cancel("Request canceled.");
    };
  }, [src, token, includesBaseUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return [loading, base64, width, height];
};

export default useImageLoad;
