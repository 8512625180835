import action from "../action";
import config from "../../config";

export const postJobDivisionFile = (params) =>
  action("jobDivisionFile", config.api_url, "entity/paragraph", params, "post");

export const postMemberBackCharge = (params) =>
  action(
    "memberChargeBack",
    config.api_url,
    "entity/paragraph",
    params,
    "post"
  );

export const patchMemberBackCharge = (pid, params) =>
  action(
    "memberChargeBack",
    config.api_url,
    `entity/paragraph/${pid}`,
    params,
    "patch"
  );

export const postThirdPartyExpense = (params) =>
  action(
    "thirdPartyExpense",
    config.api_url,
    "entity/paragraph",
    params,
    "post"
  );

export const patchThirdPartyExpense = (pid, params) =>
  action(
    "thirdPartyExpense",
    config.api_url,
    `entity/paragraph/${pid}`,
    params,
    "patch"
  );

export const patchJobDivisionFile = (rid, params) =>
  action(
    "jobDivisionFile",
    config.api_url,
    `entity/paragraph/${rid}`,
    params,
    "patch"
  );

export const patchMultipleJobDivisionFiles = (rid, params) =>
  action(
    "MultipleJobDivisionFile",
    config.api_url,
    `rest/job/files/update`,
    params,
    "patch"
  );

export const postMemberFile = (params) =>
  action("memberFile", config.api_url, "entity/paragraph", params, "post");

export const patchMemberFile = (rid, params) =>
  action(
    "memberFile",
    config.api_url,
    `entity/paragraph/${rid}`,
    params,
    "patch"
  );

export const postServiceBreakTimes = (params) =>
  action(
    "serviceBreakTimes",
    config.api_url,
    "entity/paragraph",
    params,
    "post"
  );

export const patchAimeFile = (rid, params) =>
  action(
    "askAimeFile",
    config.api_url,
    `entity/paragraph/${rid}`,
    params,
    "patch"
  );

export const deleteParagraph = (pid, params) =>
  action(
    "paragraph",
    config.api_url,
    `entity/paragraph/${pid}`,
    params,
    "delete"
  );

export const getParagraph = (pid, params) =>
  action("paragraph", config.api_url, `entity/paragraph/${pid}`, params);

export const getSubParagraph = (pid, params) =>
  action("subParagraph", config.api_url, `entity/paragraph/${pid}`, params);

export const postParagraph = (reducer, params) =>
  action(
    reducer ? reducer : "paragraph",
    config.api_url,
    "entity/paragraph",
    params,
    "post"
  );

export const patchParagraph = (reducer, pid, params) =>
  action(
    reducer ? reducer : "parapraph",
    config.api_url,
    `entity/paragraph/${pid}`,
    params,
    "patch"
  );

export const postNode = (reducer, params) =>
  action(reducer ? reducer : "node", config.api_url, "node", params, "post");

export const patchNode = (reducer, pid, params) =>
  action(
    reducer ? reducer : "node",
    config.api_url,
    `node/${pid}`,
    params,
    "patch"
  );
