import React from "react";
import Layout from "@phx/common/Layout";
import styled from "styled-components";
import { Typography } from "@mui/material";

const icon = `${process.env.PUBLIC_URL}/images/helix_icon.svg`;

const Styles = styled.div`
  padding: 1.25rem;
  max-width: 700px;
`;

const Terms = (props) => {
  return (
    <div
      style={{
        marginTop: "-62px",
        minHeight: "calc(var(--vh, 1vh) * 100)",
        background: "white",
      }}
    >
      <Layout
        name="Terms"
        title="Terms"
        meta="Terms of Service."
        icon={{ src: icon, alt: "Helixco Icon" }}
        loading={false}
      >
        <Styles>
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            This END USER AGREEMENT (“EUA”) governs Your access to and use
            certain software-as-a-services commonly known as the PHXRestore™
            Platform (the “SaaS Platform”) which is made available to You by
            Phoenix Enterprise Solutions, LLC (“Company”, “We,” or “Us”).
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            This EUA is a legally binding agreement between You and Us. Please
            read it carefully. By clicking “I agree”, creating an account on the
            SaaS Platform and/or otherwise accessing and/or using the SaaS
            Platform, You agree to be bound by all of the terms and conditions
            of this EUA.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>
            The current version of this EUA is posted at
            https://app.helixco.io/login and/or may be accessed by from within
            the SaaS Platform. This EUA may be revised or amended by Company at
            any time without notice to You and all such revisions and amendments
            shall be immediately binding on You. You agree that it is your
            obligation and responsibility to periodically review the EUA to see
            if it has been updated, revised or amended. For convenience, each
            party hereto may be referred to within as a “Party” and collectively
            as the “Parties.”
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Your Ability to Access and Use the SaaS Platform
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                For so long as it determines to do so, Company permits You, on a
                non-exclusive, personal, non-transferable, non-sublicensable,
                revocable and limited basis, to remotely access and use certain
                limited functionality available within the SaaS Platform and to
                use the Documentation, strictly to facilitate commercial
                transactions between your employer and third parties who have
                also been given the right to access and use the SaaS Platform
                and for no other purpose (the “Permitted Use”).
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                “Documentation” means all written materials and documentation
                provided by Company relating to the performance, operation
                and/or use of the SaaS Platform, including without limitation
                any specifications and user manuals.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You agree and acknowledge that You have been identified as a
                “end user” by your employer who desires that you have access to
                the SaaS Platform. If You leave your employment with Your
                current employer or your employer revokes its designation of you
                as someone whom it desires to have access to the SaaS Platform,
                you are no longer permitted to access and use the SaaS Platform.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You agree and acknowledge that Company shall have no liability
                to You of any kind for any failure of or deficiency in the SaaS
                Platform or any unavailability thereof.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You agree and acknowledge that You or your employer will be
                solely responsible to obtain and maintain those personal
                computers, tablets, scanners and other web enabled devices,
                internet connectivity, including sufficient upload/download
                capacity as may be specified by Company, and current versions of
                internet browsers necessary to connect to, access and use the
                SaaS Platform as hosted on servers located at the host site.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You agree that Company shall have no responsibility or liability
                for the deletion, alteration, interception, destruction or loss
                of information being transmitted to or from the data center or
                other host site of the SaaS Platform or that is stored on any of
                Partner’s hardware.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You agree and acknowledge that Company will own the individual
                user ID and passcode that may be used by You to access and use
                the SaaS Platform regardless of how they are issued or comprised
                and in no case will they be considered to contain or set forth
                Your personal information.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Company may suspend or terminate Your ability to access and use
                the SaaS Platform without liability to You, without notice to
                You, and for any reason, including without implicitly limiting
                the foregoing, Your breach of this EUA or any misuse by You of
                the SaaS Platform.
              </Typography>
            </li>
          </ul>

          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Your Obligations with Respect to the SaaS Platform
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You agree that You will use the SaaS Platform in compliance with
                this EUA Agreement, Company’s standard policies then in effect
                as may be published at [ ], and all applicable laws and
                regulations.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                In accessing and/or using SaaS Platform, You agree that You will
                not:
              </Typography>
              <ul style={{ padding: "20px" }}>
                <li style={{ marginBottom: "1rem" }}>
                  Sell, license, sub-license, distribute, rent, lease, transfer,
                  or assign the ability to access and use the SaaS Platform;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Reverse engineer, decompile, disassemble the SaaS Platform, or
                  otherwise attempt to obtain any of the source code, object
                  code, executable code, or underlying structure, ideas,
                  know-how, or algorithms inherent or related to the SaaS
                  Platform;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Remove or obscure any product identification, proprietary,
                  copyright, or other notices contained in or on the SaaS
                  Platform (including any reports or data which may be accessed,
                  downloaded or printed form the SaaS Platform);
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Modify, translate, or create derivative works based upon the
                  SaaS Platform;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Use the SaaS Platform to provide a service to a third-party
                  other than Your employer;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Incorporate the SaaS Platform into any other product or
                  service or create Internet “links” to or from the SaaS
                  Platform or “frame” or “mirror” any content forming part of
                  the SaaS Platform;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Use the SaaS Platform to send spam or other duplicative or
                  unsolicited messages in violation of applicable laws;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Use the SaaS Platform to send or store infringing, obscene,
                  threatening, libelous, or otherwise unlawful or tortious
                  material, including material harmful to children or violative
                  of third-party privacy rights;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Use the SaaS Platform to transmit, send, or store material
                  containing software viruses, worms, Trojan horses, or other
                  harmful computer code, files, scripts, agents or programs;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Interfere with or disrupt the integrity or performance of the
                  SaaS Platform or the data contained therein or any equipment
                  used to connect to the SaaS Platform;
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Attempt to gain unauthorized access to the SaaS Platform or
                  its related systems or networks; or,
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  Access, or allow access to, the SaaS Platform in order to (i)
                  build, or assist a third-party in building, a competitive
                  product or service; (ii) build, or assist a third-party in
                  building, a product or service using similar ideas, features,
                  functions, or graphics of the SaaS Platform; or (iii) copy, or
                  assist a third-party in copying, any ideas, features,
                  functions, or graphics of the SaaS Platform.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Confidentiality. Any and all information relating to the SaaS
                Platform shall be considered confidential information of Company
                for all purposes hereunder (“Confidential Information”). You
                agree that you will not use Confidential Information other than
                in connection with your use of the SaaS Platform and you will
                not disclose Confidential Information to any third party. You
                agree that if you were to disclose or use Confidential
                Information in a manner not authorized by this EUA, it would
                likely cause significant irreparable harm to Company.
              </Typography>
            </li>
          </ul>

          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            INFORMATION AND PRIVACY
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Information. You and your employer accept and have sole
                responsibility for the accuracy, quality, integrity, legality,
                reliability, and appropriateness of all data and information You
                enter into the SaaS Platform (“Information”). You represent to
                Us that all Information You submit is (i) accurate and complete,
                (ii) owned by You or Your employer, and (iii) is submitted by
                You without violation of any third-party’s rights in or to the
                Information. You will not transmit to or store within the SaaS
                Platform (xi) credit card or other financial information, (xii)
                a social security number, driver’s license number, dates of
                birth, home addresses, or any other information capable of being
                used to identifying a person or that is otherwise protected by
                or under the privacy laws of any State or Country, or (xiii) any
                protected heath information, as that term is defined in
                HIPAA/HITECH regulations or state laws of similar import.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Privacy. You agree and acknowledge that all information
                transmitted to, stored within and/or used by the SaaS Platform
                is intended to be shared with other end-users, including
                end-users who are not employed by your employer. As such, you
                agree and acknowledge that any such information is information
                in which you have no legitimate expectation of privacy.
                Notwithstanding the foregoing, you understand that Company may
                have from time to time an established Privacy Policy which, when
                in existence, will be posted and available at [https:// [ ]]. To
                the extent that any information you transmit to or store withing
                the SaaS Platform that is determined to comprise any of your
                personal information, you agree that such information shall be
                subject to our Privacy Policy.
              </Typography>
            </li>
          </ul>

          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            PROPRIETARY RIGHTS
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                This EUA is not an agreement for sale or the licensing of any
                underlying software. You are not being granted any right, title,
                or interest in the SaaS Platform or a right to possess an
                executable version of the software which comprises the SaaS
                Platform. You acknowledge and agree that Company owes You no
                duty, contractual or otherwise, to provide You with the right to
                access and/or to use the SaaS Platform.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You acknowledge and agree that Company shall have and retain
                exclusive and full ownership of all right, title, and interest
                in and to the SaaS Platform, any modifications, improvements,
                enhancements, and derivative works thereto, the Documentation,
                and any and all intellectual property rights therein, including,
                without limitation, any and all copyrights, moral rights, rights
                in inventions, rights in mask works, rights in and to software
                code of any kind (including all software routines, machine
                readable or executable code, source code, database structure,
                database content, and all Company APIs), rights in proprietary
                algorithms, processes and workflows executed by or otherwise
                related to the SaaS Platform, and any other form of an
                intellectual property interest recognized anywhere in the world.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                You agree that Company may use Your feedback, suggestions, or
                ideas relative to the SaaS Platform in any way, including in
                future modifications of the SaaS Platform, other products or
                services, advertising, or marketing materials. You grant Company
                a perpetual, worldwide, fully transferable, sub-licensable,
                non-revocable, fully paid-up, royalty free, and unlimited
                license to use all such feedback that provide or disclose to
                Company.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Any and all information relating to the SaaS Platform shall be
                considered confidential information of Company for all purposes
                hereunder (“Confidential Information”). You agree that you will
                not use Confidential Information other than in connection with
                your use of the SaaS Platform and you will not disclose
                Confidential Information to any third party. You agree that if
                you were to disclose or use Confidential Information in a manner
                not authorized by this EUA, it would likely cause significant
                irreparable harm to Company.
              </Typography>
            </li>
          </ul>

          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Warranty
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            TO THE MAXIMUM EXTENT ALLOWED BY LAW, THE SaaS PLATFORM IS PROVIDED
            “AS IS.” YOU ACKNOWLEDGE THAT COMPANY HAS NOT MADE ANY
            REPRESENTATIONS OR WARRANTIES TO YOU REGARDING THE SaaS PLATFORM.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            COMPANY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
            IMPLIED,
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            STATUTORY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
            WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE
            AND ANY OTHER WARRANTIES WITH RESPECT TO THE SaaS PLATFORM.
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            Indemnification
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            YOU AGREE TO INDEMNIFY, DEFEND AND HOLD COMPANY AND ITS AFFILIATES
            HARMLESS FROM ANY AND ALL CLAIMS, LIABILITIES, AND EXPENSES,
            INCLUDING REASONABLE ATTORNEYS’ FEES AND COSTS, ARISING OUT OF YOUR
            IMPROPER OR UNAUTHORIZED USE OF THE SaaS PLATFORM OR ANY BREACH OF
            THIS EUA BY YOU.
          </Typography>

          <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
            General
          </Typography>
          <ul style={{ padding: "20px" }}>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Governing Law and Enforcement of Agreement. This EUA shall be
                governed in accordance with the laws of the State of Ohio
                without reference to conflict of laws principles.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Entire Agreement. This EUA constitutes the entire, final,
                complete, and exclusive agreement between You and Us and
                supersedes all previous agreements, intentions, or
                representations, oral or written, relating to this Agreement.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                No Third-Party Beneficiaries. This EUA does not confer any
                rights on any person or party other than You and Us, including
                without limitation, Your employer.
              </Typography>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                Miscellaneous. In the event that any provision of this EUA
                conflicts with governing law or if any provision is held to be
                null, void, or otherwise ineffective or invalid by a court of
                competent jurisdiction:
              </Typography>
              <ul style={{ padding: "20px" }}>
                <li style={{ marginBottom: "1rem" }}>
                  Such provision shall be deemed to be restated to reflect as
                  nearly as possible the original intentions of the Parties in
                  accordance with applicable law; and
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  The remaining terms, provisions, covenants, and restrictions
                  of this EUA shall remain in full force and effect.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <Typography variant="body1">
                No Waiver. A failure by Us to enforce at any time any of the
                provisions of this EUA shall not be deemed to be a waiver of Our
                right thereafter to enforce any such provisions.
              </Typography>
            </li>
          </ul>
        </Styles>
      </Layout>
    </div>
  );
};

Terms.propTypes = {};

export default Terms;
