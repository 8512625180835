import {
  formatFieldByValue,
  formatField,
  getPathFromLinksSelf,
  getTaxonomyFields,
} from "../../utility";

export const formatJobComment = (d) => {
  return {
    changed: formatField(d, "changed"),
    cid: formatField(d, "cid"),
    created: formatField(d, "created"),
    entity_id: formatField(d, "entity_id"),
    entity_type: formatField(d, "entity_type"),
    field_comment_long: formatField(d, "field_comment_long"),
    field_comment_type: formatField(d, "field_comment_type"),
    field_comment_visible_to: formatField(d, "field_comment_visible_to"),
    field_name: formatField(d, "field_name"),
    field_original_comment: formatField(d, "field_original_comment"),
    field_sent_to_fm_pilot: formatField(d, "field_sent_to_fm_pilot"),
    status: formatField(d, "status"),
    uid: formatField(d, "uid"),
    uuid: formatField(d, "uuid"),
    self: getPathFromLinksSelf(d),
    user: formatUserFields(d.uid?.[0]),
    _processed: d._processed,
  };
};

export const formatWorkAreaComment = (d) => {
  return {
    changed: formatField(d, "changed"),
    cid: formatField(d, "cid"),
    created: formatField(d, "created"),
    entity_id: formatField(d, "entity_id"),
    entity_type: formatField(d, "entity_type"),
    field_da_comment: formatField(d, "field_da_comment"),
    field_da_comment_visible_to: formatField(d, "field_da_comment_visible_to"),
    field_name: formatField(d, "field_name"),
    field_original_comment: formatField(d, "field_original_comment"),
    status: formatField(d, "status"),
    uid: formatField(d, "uid"),
    uuid: formatField(d, "uuid"),
    self: getPathFromLinksSelf(d),
    user: formatUserFields(d.uid?.[0]),
    _processed: d._processed,
  };
};

export const formatGeneralFileComment = (d) => {
  return {
    changed: formatField(d, "changed"),
    cid: formatField(d, "cid"),
    created: formatField(d, "created"),
    entity_id: formatField(d, "entity_id"),
    entity_type: formatField(d, "entity_type"),
    field_comment_long: formatField(d, "field_comment_long"),
    field_name: formatField(d, "field_name"),
    field_original_comment: formatField(d, "field_original_comment"),
    field_commenter_name: formatField(d, "field_commenter_name"),
    status: formatField(d, "status"),
    uid: formatField(d, "uid"),
    uuid: formatField(d, "uuid"),
    self: getPathFromLinksSelf(d),
    user: formatUserFields(d.uid?.[0]),
    _processed: d._processed,
  };
};

export const formatCustomerComment = (d) => {
  return {
    changed: formatField(d, "changed"),
    cid: formatField(d, "cid"),
    created: formatField(d, "created"),
    entity_id: formatField(d, "entity_id"),
    entity_type: formatField(d, "entity_type"),
    field_customer_note_comment: formatField(d, "field_customer_note_comment"),
    subject: formatField(d, "subject"),
    field_customer_note_type: getTaxonomyFields(d, "field_customer_note_type"),
    field_customer_note_status: getTaxonomyFields(
      d,
      "field_customer_note_status"
    ),
    field_name: formatField(d, "field_name"),
    field_original_comment: formatField(d, "field_original_comment"),
    status: formatField(d, "status"),
    uid: formatField(d, "uid"),
    uuid: formatField(d, "uuid"),
    self: getPathFromLinksSelf(d),
    user: formatUserFields(d.uid?.[0]),
    _processed: d._processed,
  };
};

export const formatUserFields = (d) => {
  if(!d?.uid?.[0]){
    return {};
  }
  return {
    self: getPathFromLinksSelf(d),
    type: "user/user",
    uid: formatField(d, "uid"),
    path: formatFieldByValue(d, "path", "alias"),
    field_first_name: formatField(d, "field_first_name"),
    field_last_name: formatField(d, "field_last_name"),
    field_title_position: formatField(d, "field_title_position"),
    name: formatField(d, "name"),
    profile_pic: d._files?.profile_pic,
  };
};
