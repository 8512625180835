import React from "react";
import { useSelector } from "react-redux";
import BatchImportItem from "./BatchImportItem";
import { batchImportSelectors } from "./batchSlice";
import { Wrapper, Container } from "./Styled";
import SimpleBar from "simplebar-react";
import { Portal, Slide } from "@mui/material";
import { size } from "lodash";
import Card from "common/Card";

const BatchImport = (props) => {
  const batches = useSelector(batchImportSelectors.selectIds);

  return (
    <div>
      <Portal container={document.body}>
        <Slide direction="up" in={size(batches) > 0} mountOnEnter unmountOnExit>
          <Wrapper>
            <Card>
              <SimpleBar style={{ maxHeight: "70vh" }}>
                <Container>
                  {batches.map((id) => (
                    <BatchImportItem id={id} key={id} />
                  ))}
                </Container>
              </SimpleBar>
            </Card>
          </Wrapper>
        </Slide>
      </Portal>
    </div>
  );
};

BatchImport.propTypes = {};

export default BatchImport;
