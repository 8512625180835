import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import { Typography, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { first, size } from "lodash";

import Job from "./Job";
import Division from "./Division";
import Alert from "features/Alert";
import * as Yup from "yup";
import { addDKICompleteJobSchema } from "schemas/forms/job";
import { tmTermsSchema } from "components/TMTerms/forms/schema/TMTerms";
import { parseJobSource } from "../../utils";

import { patchMemberDecline, postJob, postJobDivision } from "../../jobSlice";
import {
  getJobDivisionStatusLoadingSelector,
  jobDivisionsStatusSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import { DefaultLoader } from "common/Loader";

const AddJob = ({ client, updateScroll, setClientDisabled }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(getJobDivisionStatusLoadingSelector);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const [declinedMembers, setDeclinedMembers] = useState([]);
  const _jobSourceOptions =  useSelector(
    (state) => state.auth.user.data._processed.job_source_options
  );

  const jobSourceOptions = parseJobSource(_jobSourceOptions, client);
  const jobSourceDefault = size(jobSourceOptions) === 1 ? first(jobSourceOptions).value : "";

  const acceptedStatus = useMemo(
    () => statuses.find((status) => status.name === "Created"),
    [statuses]
  );

  const [progress, setProgress] = useState({ show: false, message: "" });

  const handleCreateJob = async (data) => {
    try {
      setProgress({ show: true, message: "Creating Job" });
      const params = {
        field_phoenix_client: [{ target_id: data.field_phoenix_client }],
        field_customer: [{ target_id: data.field_customer.nid }],
        field_customer_name: [{ value: data.field_customer.name }],
        field_loss_category: [{ value: data.field_loss_category }],
        field_job_source: [{ value: data.field_job_source }],
        field_reported_by: [{ value: data.field_reported_by }],
        field_customer_location: [
          { target_id: data.field_customer_location.nid },
        ],
        field_date: [{ value: moment(data.field_date).format("YYYY-MM-DD") }],
        field_note: [{ value: data.field_note }],
      };

      if (data.field_phx_client_rep !== "_none") {
        params.field_phx_client_rep = [
          { target_id: data.field_phx_client_rep },
        ];
      }

      if (data.field_override_log_entry) {
        params.field_override_log_entry = [
          { target_id: data.field_override_log_entry },
        ];
      }
      const resultAction = await dispatch(postJob(params));

      unwrapResult(resultAction);
      return resultAction.payload.nid;
    } catch (err) {
      setProgress({ show: false, message: "" });
      setClientDisabled(false);
      return false;
    }
  };

  const handleCreateJobDivision = async (job, data) => {
    try {
      setProgress({ show: true, message: "Creating Job Division" });

      const params = {
        field_job_division_type: [{ target_id: data.field_job_division_type }],
        field_jd_status: [{ target_id: data.field_jd_status }],
        field_assigned_member: [
          {
            target_id: data.field_assigned_member.nid,
          },
        ],
        field_reference_num: [{ value: data.field_reference_num }],
        field_nte: [{ value: data.field_nte }],
        field_cause_select: [{ value: data.field_cause_select }],
        field_cause: [{ value: data.field_cause }],
        field_jd_mf_caller: [{ value: data.field_jd_mf_caller }],
        field_jd_mf_caller_phone: [{ value: data.field_jd_mf_caller_phone }],
        // field_audit_file_categories: [{ value: "" }],
      };

      if (data.field_work_area_tax) {
        params.field_work_area_tax = [{ value: data.field_work_area_tax }];
      }
      if (data.field_source_loss) {
        params.field_source_loss = [{ target_id: data.field_source_loss }];
      }
      if (data.field_assigned_member && data.field_assigned_member.nid) {
        params.field_assigned_member = [
          { target_id: data.field_assigned_member.nid },
        ];
      }

      if (data.isPilot) {
        params.field_fm_pilot_work_order_number = [
          { value: data.field_fm_pilot_work_order_number },
        ];
      }

      const meta = {};
      if (data.resources_price_list_pid) {
        meta.resources_price_list_pid = data.resources_price_list_pid;
      }
      if (data.service_activities_price_list_pid) {
        meta.service_activities_price_list_pid =
          data.service_activities_price_list_pid;
      }
      if (data.service_types_price_list_pid) {
        meta.service_types_price_list_pid = data.service_types_price_list_pid;
      }
      if (data.expense_types_price_list_pid) {
        meta.expense_types_price_list_pid = data.expense_types_price_list_pid;
      }

      if (data?.field_tm_terms) {
        params.field_tm_terms = [{value: JSON.stringify(data.field_tm_terms)}];
      }

      if (data?.member_assignment_appearance_tracking_id) {
        meta.member_assignment_appearance_tracking_id = data.member_assignment_appearance_tracking_id;
      }

      const resultAction = await dispatch(
        postJobDivision({
          id: job,
          params,
          meta,
        })
      );

      const payload = unwrapResult(resultAction);

      for await (const [i, mem] of declinedMembers.entries()) { // eslint-disable-line
        await dispatch(
          patchMemberDecline({ ...mem, job_division_nid: payload.nid })
        );
      }

      return payload.self;
    } catch (err) {
      setProgress({ show: false, message: "" });
      setClientDisabled(false);
      return false;
    }
  };

  const handleSubmit = async (data) => {
    updateScroll(0);
    setClientDisabled(true);

    const job = await handleCreateJob(data);
    if (!job) return;
    const division = await handleCreateJobDivision(job, data);
    if (!division) return;

    history.push(division);
  };

  return loading ? (
    <DefaultLoader />
  ) : (
    <Formik
      initialValues={{
        field_customer: null,
        field_customer_location: null,
        field_phx_client_rep: "_none",
        field_phoenix_client: client.nid,
        field_job_source: jobSourceDefault,
        field_loss_category: "cs",
        field_date: moment(),
        field_reported_by: "",
        field_note: "",
        field_assigned_member: null,
        field_job_division_type: "",
        field_reference_num: "",
        field_jd_status: acceptedStatus?.tid,
        field_nte: "",
        field_work_area_tax: "",
        field_source_loss: "",
        field_jd_mf_caller: "",
        field_jd_mf_caller_phone: "",
        price_list_pid: "",
        resources_price_list_pid: "",
        service_activities_price_list_pid: "",
        service_types_price_list_pid: "",
        expense_types_price_list_pid: "",
        field_tm_terms: null,
        isPilot: false,
        member_assignment_appearance_tracking_id: null,
      }}
      validationSchema={Yup.object().shape({
        ...addDKICompleteJobSchema.fields,
        ...tmTermsSchema.fields,
      })}
      onSubmit={async (data, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        await handleSubmit(data);
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        validateForm,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form>
          <div
            style={{
              position: "relative",
            }}
          >
            {progress.show && (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  background: "rgba(245, 242, 247, 0.5)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  zIndex: 2,
                }}
              >
                <CircularProgress />
                <Typography variant="h6" style={{ marginTop: "2rem" }}>
                  {progress.message}
                </Typography>
              </div>
            )}
            <div style={{ padding: "2rem 0" }}>
              <Alert margin="none" disableElevation disableRoundedCorners />
            </div>
            <Job
              setFieldValue={setFieldValue}
              clientId={client.nid}
              isSubmitting={isSubmitting}
              values={values}
              jobSourceOptions={jobSourceOptions}
            />
            <Division
              setFieldValue={setFieldValue}
              clientId={client.nid}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              values={values}
              setDeclinedMembers={setDeclinedMembers}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddJob.propTypes = {};

export default AddJob;
