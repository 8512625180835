let hostname = window && window.location && window.location.hostname;
let rawHostname = 'https://' + (window && window.location && window.location.hostname);
let _url = "";
let qb_url = "";
let helix_api_url = "";
let helix_s3_bucket = "";
let devTunnel = hostname.includes("ngrok") || hostname.includes("-ldev");
let pusherKey = '';
let pusherBeamKey = '';
let vaporAddrs = {
  local: {
    // url: "http://localhost:86",
    url: process.env?.REACT_APP_LOCAL_LARAVEL_ADDR ? process.env?.REACT_APP_LOCAL_LARAVEL_ADDR : "https://helix_api.test:4343",
    bucket: process.env?.REACT_APP_LOCAL_LARAVEL_BUCKET ? process.env?.REACT_APP_LOCAL_LARAVEL_BUCKET : "helix-api-staging-bucket",
  },
  dev: {
    url: "https://clean-zurich-pzaxghzoddgp.vapor-farm-e1.com",
    bucket: "helix-api-dev-bucket",
  },
  sandbox: {
    url: "https://gentle-cloud-rjxi8cpnhhzv.vapor-farm-g1.com",
    bucket: "helix-api-sandbox-bucket",
  },
  sandbox2: {
    url: "https://colorful-thunder-oprpyzcypvfa.vapor-farm-e1.com",
    bucket: "helix-api-sandbox2-bucket",
  },
  sandbox3: {
    url: "https://exquisite-winter-rxp0wb1yet3r.vapor-farm-g1.com",
    bucket: "helix-api-sandbox3-bucket",
  },
  sandbox4: {
    url: "https://lively-dream-uvayw3nitxsk.vapor-farm-b1.com",
    bucket: "helix-api-sandbox4-bucket",
  },
  test: {
    url: "https://unwavering-night-z5q59pbdtsun.vapor-farm-e1.com",
    bucket: "helix-api-staging-bucket",
  },
  production: {
    url: "https://damp-snowflake-fecela8xbpxx.vapor-farm-c1.com",
    bucket: "helix-api-production-bucket",
  },
};

if (hostname === "localhost") {
  hostname = "helixco.lndo.site";
  _url = `https://service-${hostname}`;
  rawHostname = `http://localhost:3000`;
}
else if (devTunnel) {
  _url = process.env?.REACT_APP_BACKEND_ADDR;
}
else if (hostname.split(".").length - 1 === 1) {
  _url = `https://service.${hostname}`;
  /* eslint-disable */
} else if (hostname.slice(0, 4) == "app." || hostname.slice(0, 4) == "app-") {
  /* eslint-enable */
  _url = `https://service${hostname.replace("app", "")}`;
} else if (
  hostname === "www.in2dki-phx.com" ||
  hostname === "www.phxrestore.io"
) {
  _url = `https://service${hostname.replace("www", "")}`;
} else {
  _url = `https://service-${hostname.replace("www.", "")}`;
}

let _mbyUrl = `https://dev.mybackyard.io`;
const mbyPrefixes = ["dev", "test", "app"];
if (
  hostname === "localhost" ||
  hostname.includes("lndo") ||
  devTunnel){
  _mbyUrl = `https://my-backyard.lndo.site`;
}
else{
  let _prefix = hostname.slice(0, hostname.indexOf("."));
  if(mbyPrefixes.includes(_prefix)){
    _mbyUrl = `https://${_prefix}.mybackyard.io`;
  }
}

if (
  hostname === "localhost" ||
  hostname.includes("lndo") ||
  devTunnel ||
  !hostname.includes("app.")
) {
  helix_api_url = vaporAddrs.test.url;
  helix_s3_bucket = vaporAddrs.test.bucket;
  if(hostname === "localhost" || hostname.includes("lndo") || devTunnel){
    helix_api_url = vaporAddrs.local.url;
    helix_s3_bucket = vaporAddrs.local.bucket;
  }
  else{
    const _prefix = hostname.slice(0, hostname.indexOf("."));
    if(vaporAddrs[_prefix]){
      helix_api_url = vaporAddrs[_prefix].url;
      helix_s3_bucket = vaporAddrs[_prefix]?.bucket ? vaporAddrs[_prefix]?.bucket : helix_s3_bucket;
    }
  }
  qb_url = "https://sandbox.api.intuit.com";
  pusherKey = (hostname === "localhost" || hostname.includes("lndo") || devTunnel) && process.env?.REACT_APP_DEVELOPMENT_PUSHER_KEY
    ? process.env.REACT_APP_DEVELOPMENT_PUSHER_KEY
    : process.env?.REACT_APP_STAGING_PUSHER_KEY;
  pusherBeamKey = (hostname === "localhost" || hostname.includes("lndo") || devTunnel) && process.env?.REACT_APP_DEVELOPMENT_PUSHER_BEAM_KEY
    ? process.env.REACT_APP_DEVELOPMENT_PUSHER_BEAM_KEY
    : process.env?.REACT_APP_STAGING_PUSHER_BEAM_KEY;
} else {
  helix_api_url = vaporAddrs.production.url;
  helix_s3_bucket = vaporAddrs.production.bucket;
  qb_url = "https://api.intuit.com";
  pusherKey = process.env?.REACT_APP_PRODUCTION_PUSHER_KEY;
  pusherBeamKey = process.env?.REACT_APP_PRODUCTION_PUSHER_BEAM_KEY;
}
const config = {
  api_url: _url,
  hostname: hostname,
  rawHostname: rawHostname,
  support_email: "support@helixo.io",
  qb_url,
  //  jsonapi_base_path: 'https://phoenix-solutions.lndo.site/hidden/da6b490f4981baae/jsonapi',
  client_id: "a825cc42-31eb-46dd-b195-c62614c6b926",
  client_secret: "ccdc65c1e55ad96dbaedbcaeeb827331",
  grant_type: "password",
  scope:
    "accounting admin phx_sub_admin compliance_admin customer phx_client_admin intranet_admin member_admin_agent member_estimator member_field_agent operations view_only mby_agent",
  aeris_client_id: "1pyejgpmI5sWSBWyXdhTr",
  aeris_client_secret: "Du7ph2ripTt8Mmcyw1whyogLxWzoHoTKV6UWvZXs",
  recaptcha_site_key: "6LczabMoAAAAABu5hYVsSpW5gpCJ50uxHMPjZ6Hf",
  recaptcha_invisible_site_key: "6LdBc7MoAAAAAGP4dU6Zkqe4sRuSfUAW6P7oHTgR",
  helix_api_url,
  helix_s3_bucket,
  mby_url: _mbyUrl,
  pusher_key: pusherKey,
  pusher_beam_key: pusherBeamKey,
};

export default config;
