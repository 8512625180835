import { first, toArray } from "lodash";
import {
  formatField,
  formatFieldByValue,
  getPathFromLinksSelf,
  getTodaysDay,
  getHours,
  getUserFields,
  getFileFields,
  getValuesFromFieldArray,
} from "../../utility";

export const formatCustomerFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/customer",
    nid: formatFieldByValue(d, "nid", "value"),
    title: formatFieldByValue(d, "title", "value"),
    created: formatFieldByValue(d, "created", "value"),
    changed: formatFieldByValue(d, "changed", "value"),
    path: formatFieldByValue(d, "path", "alias"),
    field_logo: first(getFileFields(d, "field_logo")),
    // field_audit_file_categories: null,
    field_national_contracts: getValuesFromFieldArray(d, "field_national_contracts"),
    field_street_address: formatField(d, "field_street_address"),
    field_billing_address: formatField(d, "field_billing_address"),
    field_referred_by_text: formatField(d, "field_referred_by_text"),
    field_referred_by_phone: formatField(d, "field_referred_by_phone"),
    field_billing_address_geo: d?.field_billing_address_geo?.[0],
    field_customer_invoice_terms: formatFieldByValue(
      d,
      "field_customer_invoice_terms",
      "value"
    ),
    field_customer_jd_active: formatFieldByValue(
      d,
      "field_customer_jd_active",
      "value"
    ),
    field_customer_locations_active: formatFieldByValue(
      d,
      "field_customer_locations_active",
      "value"
    ),
    field_customer_notes: null,
    field_customer_status: formatFieldByValue(
      d,
      "field_customer_status",
      "value"
    ),
    field_cust_files: null,
    field_daily_sheets_customer: formatFieldByValue(
      d,
      "field_daily_sheets_customer",
      "value"
    ),
    field_ds_cust_validation_toggle: formatFieldByValue(
      d,
      "field_ds_cust_validation_toggle",
      "value"
    ),
    field_emergency_service_fee: formatFieldByValue(
      d,
      "field_emergency_service_fee",
      "value"
    ),
    field_employees: null,
    field_file_fees: d?.field_file_fees,
    field_hours: formatField(d, "field_hours"),
    field_is_fm_pilot_client: formatFieldByValue(
      d,
      "field_is_fm_pilot_client",
      "value"
    ),
    field_is_headquarters: formatFieldByValue(
      d,
      "field_is_headquarters",
      "value"
    ),
    field_is_parent_customer: formatFieldByValue(
      d,
      "field_is_parent_customer",
      "value"
    ),
    field_last_updated: formatFieldByValue(d, "field_last_updated", "value"),
    field_location_name: formatFieldByValue(d, "field_location_name", "value"),
    field_note: formatFieldByValue(d, "field_note", "value"),
    field_phone: formatFieldByValue(d, "field_phone", "value"),
    field_phone_after_hours: formatFieldByValue(
      d,
      "field_phone_after_hours",
      "value"
    ),
    field_primary_member_ids_text: [],
    field_recon_service_fee: formatFieldByValue(
      d,
      "field_recon_service_fee",
      "value"
    ),
    field_resources: null,
    field_resources_custom: null,
    field_service_activities: null,
    field_service_labor_types: null,
    field_street_address_geo: d?.field_street_address_geo?.[0],
    field_jda_invoice_template: formatField(d, "field_jda_invoice_template"),
    field_phx_client_rep: getUserFields(d, "field_phx_client_rep"),
    field_mby_agents: getUserFields(d, "field_mby_agents"),
    field_phoenix_client: first(
      toArray(d?.field_phoenix_client).map((client) => ({
        nid: formatField(client, "nid"),
        title: formatField(client, "title"),
        field_pc_proper_name: formatField(client, "field_pc_proper_name"),
        _processed: client?._processed,
      }))
    ),
    field_child_customers:
      toArray(d?.field_child_customers).map((child) => ({
        nid: formatField(child, "nid"),
        title: formatField(child, "title"),
      })),
    field_res_com: formatField(d, "field_res_com"),
    field_mem_job_status_override: formatFieldByValue(d, "field_mem_job_status_override", "target_id", true),
    field_tm_terms: JSON.parse(formatFieldByValue(
      d,
      "field_tm_terms",
      "value"
    )),
    _processed: d?._processed,
    _meta: d?._meta,
  };
};

// export const formatCustomerFields = (customer) => {
//     const logo = formatField(customer, "field_logo");
//     const logoUrl = logo ? logo._links.self.href : null;
//     const path =
//       customer && customer.path && customer.path[0] && customer.path[0].alias
//         ? customer.path[0].alias
//         : null;
//     const hasNotesAccess =
//       customer && customer._meta && customer._meta.notes_access_permitted
//         ? customer._meta.notes_access_permitted
//         : false;
//     return {
//       links: customer && customer._links ? customer._links : null,
//       client: formatField(customer, "field_phoenix_client"),
//       isFMPilotClient: formatField(customer, "field_is_fm_pilot_client"),
//       self: getPathFromLinksSelf(customer),
//       path,
//       nid: formatField(customer, "nid"),
//       name: formatField(customer, "title"),
//       isParent: formatField(customer, "field_is_parent_customer"),
//       isHeadquarters: formatField(customer, "field_is_headquarters"),
//       hasNotesAccess,
//       hasDailySheets: formatField(customer, "field_daily_sheets_customer"),
//       contacts: getContacts(customer),
//       phone: formatField(customer, "field_phone"),
//       afterHoursPhone: formatField(customer, "field_phone_after_hours"),
//       fax: formatField(customer, "field_fax"),
//       officeAddress: formatField(customer, "field_street_address"),
//       billingAddress: formatField(customer, "field_billing_address"),
//       status: formatField(customer, "field_customer_status"),
//       logo: logoUrl,
//       activeJobs: formatField(customer, "field_customer_jd_active"),
//       activeLocations: formatField(customer, "field_customer_locations_active"),
//       specialInstructions: formatField(customer, "field_note"),
//       hours: formatField(customer, "field_hours"),
//       term: formatField(customer, "field_customer_invoice_terms"),
//       discount: formatField(customer, "field_customer_discount"),
//       discountAmount: formatField(customer, "field_customer_discount_amount"),
//       discountPercent: formatField(customer, "field_customer_discount_percent"),
//       referralRecon: formatField(customer, "field_referral_recon"),
//       referralMitigation: formatField(customer, "field_referral_mitigation"),
//       reconServiceFee: formatField(customer, "field_recon_service_fee"),
//       reconServiceFeeWork: formatField(customer, "field_recon_service_fee_work"),
//       emergencyServiceFee: formatField(customer, "field_emergency_service_fee"),
//       invoiceTemplate: formatField(customer, "field_jda_invoice_template"),
//       emergencyServiceFeeWork: formatField(
//         customer,
//         "field_emergency_service_fee_work"
//       ),
//       referredMember: formatMemberFields(
//         customer &&
//           customer.field_referred_member &&
//           customer.field_referred_member[0]
//           ? customer.field_referred_member[0]
//           : null
//       ),
//       fileFees:
//         customer && customer.field_file_fees ? customer.field_file_fees : [],
//       geo:
//         customer &&
//         customer.field_street_address_geo &&
//         customer.field_street_address_geo[0]
//           ? {
//               lat: customer.field_street_address_geo[0].lat,
//               lng: customer.field_street_address_geo[0].lon,
//             }
//           : {
//               lat: null,
//               lng: null,
//             },
//       documents:
//         customer && customer.field_cust_files ? customer.field_cust_files : [],
//       website:
//         customer && customer.field_website && customer.field_website[0]
//           ? customer.field_website[0].uri
//           : null,
//       zoom:
//         customer &&
//         customer.field_video_call_url &&
//         customer.field_video_call_url[0]
//           ? customer.field_video_call_url[0].uri
//           : null,
//     };
//   };

export const getTodaysHours = (rawHours, day) => {
  let today = getTodaysDay();
  if (day) today = day;
  let hours = [];
  if (rawHours) hours = getHours(rawHours);
  let now = "Not Set";
  hours.forEach((hour) => {
    if (hour.day === today) now = hour.hours;
  });

  return { today, time: now };
};
