import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  InputAdornment,
  Grid,
  Typography,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import { first, toArray, size } from "lodash";

import { AutocompleteFormField } from "common/Fields";
import Button from "common/Button";
import Role from "components/Role";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";
import ComplianceOverride from "./ComplianceOverride";

import { fetchPhxClientUsers } from "features/Users/usersSlice";
import {
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
  sourceOfLossSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import {
  fetchMembers,
  getMembersLoadingSelector,
  membersSelectors,
} from "features/Members/membersSlice";
import { getLocationDataSelector } from "features/Location/locationSlice";
import {
  fetchPriceListsSeperate,
  priceListsExpenseSelectors,
  priceListsLaborActivitiesSelectors,
  priceListsLaborTypesSelectors,
  priceListsResourcesSelectors,
} from "features/PriceLists/priceListsSlice";
import {
  fetchTMTerms,
  tmTermsSelectors,
} from "components/TMTerms/TMTermsSlice";
import Guard from "common/Guard";
import AssignMember from "../components/AssignMember";
import { fetchPriceList, categoriesSelectors, laborTypesSelectors } from "features/PriceList/priceListSlice";
import TMTermsDivisionFormComponent from "components/TMTerms/forms/components/TMTermsDivisionFormComponent";

const PriceListField = ({ isSubmitting, lists, name, label }) => {
  const defaultLists = lists.filter((list) => list.owner_type === "global");
  const memberLists = lists.filter((list) => list.owner_type === "member");
  const customerLists = lists.filter((list) => list.owner_type === "customer");

  return (
    <Grid item xxs={12} md={3}>
      <SelectFormField
        fullWidth
        name={name}
        label={label}
        required
        disabled={isSubmitting}
        size="small"
        variant="outlined"
        options={lists.map((obj) => ({
          label: obj.title,
          value: obj.pid,
        }))}
      >
        {size(defaultLists) > 0 && (
          <ListSubheader>System Default</ListSubheader>
        )}
        {defaultLists.map((option) => (
          <MenuItem key={option.pid} value={option.pid}>
            {option.title}
          </MenuItem>
        ))}
        {size(memberLists) > 0 && <ListSubheader>Member</ListSubheader>}
        {memberLists.map((option) => (
          <MenuItem key={option.pid} value={option.pid}>
            {option.title}
          </MenuItem>
        ))}
        {size(customerLists) > 0 && <ListSubheader>Customer</ListSubheader>}
        {customerLists.map((option) => (
          <MenuItem key={option.pid} value={option.pid}>
            {option.title}
          </MenuItem>
        ))}
      </SelectFormField>
    </Grid>
  );
};

const Job = ({
  clientId,
  values,
  isSubmitting,
  value,
  handlePrev,
  handleSubmit,
  setFieldValue,
  setDeclinedMembers,
}) => {
  const dispatch = useDispatch();
  const members = useSelector(membersSelectors.selectAll);
  const loadingMembers = useSelector(getMembersLoadingSelector);
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const sources = useSelector(sourceOfLossSelectors.selectAll);
  const user = useSelector((state) => state.auth.user.data);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const location = useSelector(getLocationDataSelector);
  const expensePrices = useSelector(priceListsExpenseSelectors.selectAll);
  const resourcePrices = useSelector(priceListsResourcesSelectors.selectAll);
  const laborTypePrices = useSelector(priceListsLaborTypesSelectors.selectAll);
  const laborActivityPrices = useSelector(
    priceListsLaborActivitiesSelectors.selectAll
  );
  const priceListResources = useSelector(categoriesSelectors.selectAll);
  const laborTypes = useSelector(laborTypesSelectors.selectAll);
  const tmTerms = useSelector(tmTermsSelectors.selectAll);
  const [tmTermsResourceCategories, setTMTermsResourceCategories] = useState([]);
  const [tmTermsLaborTypes, setTMTermsLaborTypes] = useState([]);

  const [openOverride, setOpenOverride] = useState(false);

  useEffect(() => {
    let promise, promise2;
    if (values.field_assigned_member?.nid && values.field_customer?.nid) {
      promise = dispatch(
        fetchPriceListsSeperate({
          member: values.field_assigned_member?.nid,
          customer: values.field_customer?.nid,
        })
      );
      promise2 = dispatch(
        fetchTMTerms({
          member: values.field_assigned_member?.nid,
          customer: values.field_customer?.nid,
        })
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
      if (promise2) {
        promise2.abort();
      }
    };
  }, [dispatch, values.field_assigned_member, values.field_customer]);

  useEffect(() => {
    if (size(resourcePrices) > 0) {
      setFieldValue("resources_price_list_pid", first(resourcePrices).pid);
    }
    if (size(laborActivityPrices) > 0) {
      setFieldValue(
        "service_activities_price_list_pid",
        first(laborActivityPrices).pid
      );
    }
    if (size(laborTypePrices) > 0) {
      setFieldValue("service_types_price_list_pid", first(laborTypePrices).pid);
    }
    if (size(expensePrices) > 0) {
      setFieldValue("expense_types_price_list_pid", first(expensePrices).pid);
    }
  }, [
    resourcePrices,
    expensePrices,
    laborTypePrices,
    laborActivityPrices,
    setFieldValue,
  ]);

  useEffect(() => {
    let promise;
    if (values.resources_price_list_pid) {
      promise = dispatch(
        fetchPriceList({
          id: values.resources_price_list_pid,
          type: 'resources',
        })
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, values.resources_price_list_pid]);

  useEffect(() => {
    let promise;
    if (values.service_types_price_list_pid) {
      promise = dispatch(
        fetchPriceList({
          id: values.service_types_price_list_pid,
          type: 'service_types',
        })
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, values.service_types_price_list_pid]);

  useEffect(() => {
    if (size(priceListResources) > 0) {
      let categories = [];
      priceListResources.forEach((cat) => {
        if(cat.name !== "Consumables & Materials"){
          categories.push({
            value: Number(cat.tid),
            label: cat.name,
          });
        }
      });
      setTMTermsResourceCategories(categories);
    }
    else{
      setTMTermsResourceCategories([]);
    }
    if (size(laborTypes) > 0) {
      setTMTermsLaborTypes(laborTypes);
    }
    else{
      setTMTermsLaborTypes([]);
    }
  }, [
    priceListResources,
    setTMTermsResourceCategories,
    laborTypes,
    setTMTermsLaborTypes,
  ]);

  useEffect(() => {
    const promise = dispatch(
      fetchPhxClientUsers({ "filter[phx_client_nids]": clientId })
    );

    return () => {
      promise.abort();
    };
  }, [dispatch, clientId]);

  const getMembers = useCallback(
    (query) => {
      return dispatch(
        fetchMembers({
          keywords: query,
          "filter[phx_client_nids][0]": clientId,
        })
      );
    },
    [dispatch, clientId]
  );

  const checkIfDivisionServiced = useCallback((divisions, tid) => {
    if (!divisions) return false;
    for (let index in divisions) {
      const division = divisions[index];
      const thisTid = division.target_id;
      if (thisTid.toString() === tid && division.phx_client_nid === clientId) {
        return true;
      }
    }

    return false;
  }, [clientId]);

  const checkMemberInCompliance = useCallback(
    (data, type) => {
      const member = data;
      const status = member?.field_status;
      if (status) {
        const servicesDivision = checkIfDivisionServiced(
          member ? member.field_jd_types : null,
          type
        );
        if (status === "in" && servicesDivision) {
          return {
            nid: member.nid,
            name: member.title,
          };
        } else {
          return null;
        }
      } else return null;
    },
    [checkIfDivisionServiced]
  );

  const getPreferredMember = useCallback(
    (location, type) => {
      const primary = checkMemberInCompliance(
        location.field_preferred_member,
        type
      );
      const second = checkMemberInCompliance(
        location.field_preferred_member_2,
        type
      );
      const third = checkMemberInCompliance(
        location.field_preferred_member_3,
        type
      );
      const fourth = checkMemberInCompliance(
        location.field_preferred_member_4,
        type
      );

      if (primary) {
        return primary;
      } else if (second) {
        return second;
      } else if (third) {
        return third;
      } else if (fourth) {
        return fourth;
      } else {
        return null;
      }
    },
    [checkMemberInCompliance]
  );

  return (
    <div>
      <Typography style={{ marginTop: "2rem" }} variant="h6">
        Division
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Creating a Job Division will also create a Job container for this Job
        Division.
      </Typography>
      <AssignMember
        tid={values.field_job_division_type}
        clientId={clientId}
        cid={values.field_customer_location?.nid}
        setDeclinedMembers={setDeclinedMembers}
        onAccept={(value, trackingID) => {
          setFieldValue("field_assigned_member", value);
          setFieldValue("member_assignment_appearance_tracking_id", trackingID);
        }}
      />
      <Grid container spacing={2}>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_jd_mf_caller"
            label="Caller"
            disabled={isSubmitting}
            labelwidth={70}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_jd_mf_caller_phone"
            label="Caller Phone"
            disabled={isSubmitting}
            labelwidth={70}
            autoComplete="off"
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <SelectFormField
            fullWidth
            name="field_job_division_type"
            label="Division Type"
            required
            size="small"
            variant="outlined"
            disabled={isSubmitting}
            options={types.map((type) => ({
              label: type.name,
              value: type.tid,
            }))}
            onChange={(e) => {
              setFieldValue("field_job_division_type", e.target.value);
              const preferred = getPreferredMember(location, e.target.value);
              setFieldValue("field_assigned_member", preferred);
            }}
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <SelectFormField
            fullWidth
            name="field_source_loss"
            label="Source"
            size="small"
            variant="outlined"
            disabled={isSubmitting}
            options={sources.map((source) => ({
              label: source.name,
              value: source.tid,
            }))}
          />
        </Grid>
        {values.source === "3393" && (
          <Grid item xxs={12}>
            <TextFormField
              fullWidth
              name="source-other"
              label="Source Other"
              disabled={isSubmitting}
              type="text"
              multiline
              size="small"
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item xxs={12} md={6}>
          <AutocompleteFormField
            name="field_assigned_member"
            label="Assigned Member"
            disabled={isSubmitting}
            options={toArray(members).map((d) => ({
              name: d.member_name,
              nid: d.member_nid,
              status: d.status,
            }))}
            loading={loadingMembers}
            fetchData={getMembers}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              setFieldValue("field_assigned_member", value);
              if (
                value &&
                (value.status === "out" || value.status === "suspended")
              ) {
                setOpenOverride(true);
              }
            }}
          />
        </Grid>
        <Guard
          intendedRoles={[
            "admin",
            "phx_sub_admin",
            "phx_client_admin",
            "operations",
            "accounting",
            "compliance_admin",
          ]}
        >
          <Grid item xxs={12} md={6}>
            <SelectFormField
              fullWidth
              name="field_jd_status"
              label="Status"
              required
              disabled={isSubmitting}
              size="small"
              variant="outlined"
              options={statuses.map((status) => ({
                label: status.name,
                value: status.tid,
              }))}
            />
            {values.isPilot && (
              <CheckboxFormField
                name="synchWithPilot"
                label="Synch with FM Pilot"
              />
            )}
          </Grid>
        </Guard>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_reference_num"
            label="Reference Number"
            disabled={isSubmitting}
            size="small"
            variant="outlined"
          />
        </Grid>
        {values.isPilot && (
          <Grid item xxs={12} md={6}>
            <Role
              userRoles={user?._processed?.roles}
              intendedRoles={[
                "admin",
                "phx_sub_admin",
                "phx_client_admin",
                "operations",
                "accounting",
                "compliance_admin",
              ]}
            >
              <TextFormField
                fullWidth
                name="field_fm_pilot_work_order_number"
                label="FM Pilot Work Order Number"
                disabled={isSubmitting}
                size="small"
                variant="outlined"
              />
            </Role>
          </Grid>
        )}
        <Grid item xxs={12} md={6}>
          <Role
            userRoles={user?._processed?.roles}
            intendedRoles={[
              "admin",
              "phx_sub_admin",
              "phx_client_admin",
              "operations",
              "accounting",
              "compliance_admin",
            ]}
          >
            <TextFormField
              fullWidth
              name="field_nte"
              label="NTP"
              disabled={isSubmitting}
              type="number"
              step="any"
              size="small"
              variant="outlined"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </Role>
        </Grid>
        <Grid item xxs={12} md={6}>
          {user?._processed?.editable_work_area_tax_rate && (
            <TextFormField
              fullWidth
              name="field_work_area_tax"
              label="Work Area Tax"
              disabled={isSubmitting}
              size="small"
              variant="outlined"
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              helperText="Leave empty for default: 10%"
            />
          )}
        </Grid>
        {size(expensePrices) > 0 &&
          size(resourcePrices) > 0 &&
          size(laborTypePrices) > 0 &&
          size(laborActivityPrices) > 0 && (
            <>
              <Grid item xxs={12}>
                <Typography variant="subtitle1">Price Lists</Typography>
              </Grid>
              <PriceListField
                name="expense_types_price_list_pid"
                label="Expense Types"
                isSubmitting={isSubmitting}
                lists={expensePrices}
              />
              <PriceListField
                name="resources_price_list_pid"
                label="Resources"
                isSubmitting={isSubmitting}
                lists={resourcePrices}
              />
              <PriceListField
                name="service_types_price_list_pid"
                label="Labor Types"
                isSubmitting={isSubmitting}
                lists={laborTypePrices}
              />
              <PriceListField
                name="service_activities_price_list_pid"
                label="Labor Activities"
                isSubmitting={isSubmitting}
                lists={laborActivityPrices}
              />
            </>
          )}
        {size(tmTerms) > 0 && (
          <TMTermsDivisionFormComponent
            tmTerms={tmTerms}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            resourceCategories={tmTermsResourceCategories}
            laborTypes={tmTermsLaborTypes}
          />
        )}
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              issubmitting={isSubmitting ? isSubmitting : null}
              disableElevation
              size="small"
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
      <ComplianceOverride
        open={openOverride}
        onClose={() => setOpenOverride(false)}
        setFieldValue={setFieldValue}
        id={values.field_assigned_member?.nid}
      />
    </div>
  );
};

Job.propTypes = {};

export default Job;
