import React, { useEffect } from "react";
import styled from "styled-components";
import ButtonTab from "./ButtonTab";

const Styles = styled.div`
  padding: 0.5rem 0;
  margin-bottom: 1.25rem;

  .MuiButtonBase-root {
    margin-right: 1rem;
  }
`;

const getCurrentPath = (baseUrl, pathname, routes) => {
  const arr = pathname.split("/");
  arr.shift();
  let hasRun = false;
  routes.forEach((route) => {
    const index = arr.indexOf(route.path);
    if (index >= 0) {
      arr.splice(index + 1, arr.length);
      hasRun = true;
    }
  });

  if (hasRun) {
    return ["", ...arr].join("/");
  } else {
    return baseUrl;
  }
};

const filterOutRoutesByPermissons = (routes, permissions) => {
  if (permissions) {
    const data = [];
    for (let index in routes) {
      const path = routes[index].path;
      if (permissions[path] !== false) {
        data.push(routes[index]);
      }
    }

    return data;
  } else {
    return routes;
  }
};

const ButtonTabs = ({
  url,
  pathname,
  routes,
  ariaLabel,
  nested,
  permissions,
}) => {
  const [value, setValue] = React.useState(
    getCurrentPath(url, pathname, routes)
  );

  useEffect(() => {
    setValue(getCurrentPath(url, pathname, routes));
  }, [url, pathname, routes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const authRoutes = filterOutRoutesByPermissons(routes, permissions);

  return (
    <Styles>
      {authRoutes.map((route, index) => (
        <ButtonTab
          key={route.identifier}
          active={`${url}${route.path ? `/${route.path}` : ""}` === value}
          value={`${url}${route.path ? `/${route.path}` : ""}`}
          label={route.label}
          to={`${url}${route.path ? `/${route.path}` : ""}`}
          // style={route.style}
          startIcon={route.startIcon}
          onClick={(e) =>
            handleChange(e, `${url}${route.path ? `/${route.path}` : ""}`)
          }
        />
      ))}
    </Styles>
  );
};

ButtonTabs.propTypes = {};

export default ButtonTabs;
