import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { size } from 'lodash';
// import ReactHowler from 'react-howler';
// import notificationSound from 'assets/sounds/notification.wav';
import {
  notification as conversationMessageNotification,
} from "features/Hub/components/notifications/ConversationMessage";
import {
  notification as postMessageNotification,
} from "features/Hub/components/notifications/PostMessage";
import {
  groupInviteNotification,
} from "features/Hub/components/notifications/GroupInvite";
import {
  eventNotification,
} from "features/Hub/components/notifications/EventNotification";
import {
  notification as browserNotificationsPrompt,
} from "components/BrowserNotifications/notifications/BrowserNotificationsPrompt";
import { StyledNotification, StyledNotificationGroup } from "./NotificationStyled";
import { clearAdded, getToastAddedSelector } from "features/Toast/toastSlice";
import {
  patchReadNotifications,
} from "components/Notification/notificationSlice";


const NotificationGroup = ({ messages, onClose, onDelete, isLastNotificationGroup, ...props }) => {
  const [expanded, setExpanded] = useState(null);
  // const [hidden, setHidden] = useState(null);
  const [groupDismissedEvent, setGroupDismissedEvent] = useState(null);

  if(!size(messages)){
    return null;
  }

  const handleClose = (e, params) => {
  //  contract if only 1 notification will remain
    if(messages.length === 2){
      setExpanded(null);
    }
  // Clear all if only 1 notification will remain, or this was submitted without params
    else if(messages.length === 1 || !params){
      handleClearAll(e);
    }

    if(onClose){
      onClose(params);
    }
  }

  const handleClearAll = (e) => {
    e.stopPropagation();
    setGroupDismissedEvent(e);
  }

  let messageInc = 0;
  return (
    <StyledNotificationGroup
      onClick={() => messages.length > 1 ? setExpanded(!expanded) : null}
      className={`${expanded !== null ? (expanded ? 'expanded' : 'contracted') : ''}`}
      index={props.index}
    >
      {messages.map((message) => {
        messageInc++;
        return (
          <Notification
            key={message.uuid ? message.uuid : messageInc}
            message={message}
            isSubNotication={messageInc > 1}
            onClose={handleClose}
            groupDismissedEvent={groupDismissedEvent}
            onDelete={onDelete}
            // setHidden={isLastNotificationGroup ? setHidden : null}
            {...props} />
        );
      })}
      {!groupDismissedEvent && (
        <div className="clear" onClick={handleClearAll}>Clear All</div>
      )}
      {/* <div className="background" /> */}
    </StyledNotificationGroup>
  );
};

const Notification = ({
  message,
  onClose,
  onNavigate,
  isSubNotication,
  groupDismissedEvent,
  onDelete,
  setHidden,
  ...props
}) => {
  const dispatch = useDispatch();
// NOTE:  Tabling Audio support here
//  Sounds will only play if the user has clicked on the site and currently has the site open
  // const audioContext = new AudioContext();
  // const [playing, setPlaying] = useState(audioContext.state === 'suspended' ? message.uuid : '');
  const [dismissed, setDismissed] = useState(false);
  const added = useSelector(getToastAddedSelector);
  const currentUser = useSelector((state) => state.user.helixAPICurrent.data);

  useEffect(() => {
    if(added === message.grouping_id && !isSubNotication){
      setTimeout(() => {
        dispatch(clearAdded(false));
        clearInterval();
      }, 500);
    }
  }, [added, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(groupDismissedEvent){
      handleClose(groupDismissedEvent, message.uuid);
    }
  }, [groupDismissedEvent, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = (e, uuid, noDelay) => {
    e.stopPropagation();
    if(!noDelay){
      setDismissed(true);
    }

    if(onClose){
      const setGroupUnread = (groupDismissedEvent && !isSubNotication);
      const closeAction = (e2) => {
        // if(setHidden && setGroupUnread){
        //   setHidden(true);
        // }
        if(!onDelete){
          if(!groupDismissedEvent){
            dispatch(patchReadNotifications({uuid: uuid}));
          }
          else if(setGroupUnread){
            dispatch(patchReadNotifications({
              parent_id: message.parent_id,
              parent_type: message.parent_type
            }));
          }
        }
        else{
          if(!groupDismissedEvent){
            onDelete({uuid: uuid});
          }
          else if(setGroupUnread){
            onDelete({
              parent_id: message.parent_id,
              parent_type: message.parent_type
            });
          }
        }
        onClose(e2, {uuid: uuid});
        clearInterval();
      };

      if(!noDelay){
        setTimeout(() => closeAction(e), 500);
      }
      else{
        closeAction(e);
      }
    }
  }

  const handleNavigate = (e, uuid) => {
    e.stopPropagation();

  //  Clear the grouping if this is a sub-notification
    if(isSubNotication){
      onClose(e);
    }
    else{
      handleClose(e, uuid, (onNavigate ? true : false));
    }

    if(onNavigate){
      // setTimeout(() => {
        onNavigate();
      //   clearInterval();
      // }, 500);
    }
  }

  let notification = {};
  const messageSettings = {
    data: {
      ...message,
      ...message?.data,
      data: null,
    },
    isSubNotication: isSubNotication,
    currentUser: currentUser,
    onNavigate: (e) => handleNavigate(e, message.uuid),
    onDismiss: (e) => handleClose(e, message.uuid),
  };
  switch (message?.type) {
    case "conversation.message":
    default:
      notification = conversationMessageNotification(messageSettings);
    break;

    case "post.message":
      notification = postMessageNotification(messageSettings);
    break;

    case "event.invite":
    case "event.starting":
      notification = eventNotification(messageSettings);
    break;

    case "group.invite":
      notification = groupInviteNotification(messageSettings);
    break;

    case "browser_notifications_prompt":
      notification = browserNotificationsPrompt(messageSettings);
    break;
  }

  let addClassNames = '';
  if(dismissed){
    addClassNames += ' dismissed';
  }
  if(added === message.grouping_id && !isSubNotication){
    addClassNames += ' added';
  }
  if(props?.className){
    addClassNames += ' ' + props.className;
  }

  return (
    <StyledNotification
      {...props}
      className={`notification ${addClassNames}`}
    >
      {/* <ReactHowler
        src={notificationSound}
        preload
        // The alert can only sound if the user is currently active and having clicked in the site
        playing={playing === message.uuid}
        onEnd={() => setPlaying('')}
      /> */}
      {notification?.icon && (
        <div className="icon" >{notification.icon}</div>
      )}
      <div className="body" >{notification.body}</div>
      <div className="aside" >
        {notification?.path && (
          <Link className="proceed" to={notification.path} onClick={(e) => handleNavigate(e, message.uuid)} ><ArrowForwardIosIcon  fontSize="small" /></Link>
        )}
        {onClose &&
          <div className="close" ><CloseIcon fontSize="small" onClick={(e) => handleClose(e, message.uuid)} /></div>
        }
      </div>
    </StyledNotification>
  );
};

export default NotificationGroup;
