import {
  createSlice,
  // createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatField } from "../../utility";
import { formatCustomerFields } from "./utils";

import { setAlert } from "features/Alert/alertSlice";

// const getQueryParams = (params) => {
//   return `?${
//     typeof params === "string"
//       ? params.substring(1)
//       : querystring.stringify(params)
//   }`;
// };

const namespace = "customer";

export const patchCustomer = createAsyncThunk(
  `${namespace}/patchCustomer`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/customer`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated Customer",
        })
      );
      return formatCustomerFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating Customer: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const archiveCustomer = createAsyncThunk(
  `${namespace}/archiveCustomer`,
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/customer`,
            },
          },
          field_customer_status: [{ value: "archived" }],
        },
        tokenConfig(getState)
      );

      return formatCustomerFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const unarchiveCustomer = createAsyncThunk(
  `${namespace}/unarchiveCustomer`,
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/customer`,
            },
          },
          field_customer_status: [{ value: "active" }],
        },
        tokenConfig(getState)
      );

      return formatCustomerFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCustomer = createAsyncThunk(
  `${namespace}/postCustomer`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/customer`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatCustomerFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding Customer: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomer = createAsyncThunk(
  `${namespace}/fetchCustomer`,
  async (name, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/customer/${name}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatCustomerFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerByNid = createAsyncThunk(
  `${namespace}/fetchCustomerByNid`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatCustomerFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const customerSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  reducers: {
    setCustomerData: (state, { payload: customer }) => {
      state.loading = false;
      state.data = customer;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchCustomer.pending](state) {
      state.loading = true;
    },
    [fetchCustomer.fulfilled](state, { payload: customer }) {
      state.loading = false;
      state.data = customer;
    },
    [fetchCustomer.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerByNid.pending](state) {
      state.loading = true;
    },
    [fetchCustomerByNid.fulfilled](state, { payload: customer }) {
      state.loading = false;
      state.data = customer;
    },
    [fetchCustomerByNid.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postCustomer.fulfilled](state, { payload: customer }) {
      state.loading = false;
      state.data = customer;
    },
    [postCustomer.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchCustomer.pending](state, action) {
      const { params, processed } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        if(key !== 'field_child_customers'
          && key !== 'field_national_contracts'){
          tempParams[key] = formatField(tempParams, key);
        }
      });

      if (processed?.reps) {
        tempParams.field_phx_client_rep = processed.reps;
      }

      state.data = {
        ...state.data,
        ...tempParams,
      };
    },
    [patchCustomer.fulfilled](state, { payload: customer }) {
      state.data = customer;
    },
    [patchCustomer.rejected](state, action) {
      const { customer } = action.meta.arg;

      state.data = customer;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [archiveCustomer.fulfilled](state, { payload: customer }) {
      state.data = customer;
    },
    [archiveCustomer.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [unarchiveCustomer.fulfilled](state, { payload: customer }) {
      state.data = customer;
    },
    [unarchiveCustomer.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getCustomerDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.customer.data
);

export const getCustomerLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.customer.loading
);

export const getCustomerErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.customer.error
);

export const {
  setAllCustomers,
  setManyCustomers,
  setOneCustomers,
  addOneCustomers,
  updateOneCustomers,
  setCustomerData,
} = customerSlice.actions;

export default customerSlice.reducer;
