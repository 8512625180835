import {
  getTodaysDay,
  getHours,
  getPathFromLinksSelf,
  formatField,
} from "../../utility";
import { formatMemberFields } from "../Member/utils";

export const formatLocationFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/customer_location",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_phone: formatField(d, "field_phone"),
    field_phone_after_hours: formatField(d, "field_phone_after_hours"),
    field_cl_email: formatField(d, "field_cl_email"),
    field_pref_contact_method: formatField(d, "field_pref_contact_method"),
    field_cl_year_built: formatField(d, "field_cl_year_built"),
    field_street_address: formatField(d, "field_street_address"),
    field_street_address_geo: d?.field_street_address_geo?.[0],
    field_cl_status: formatField(d, "field_cl_status"),
    field_county: formatField(d, "field_county"),
    field_hours: formatField(d, "field_hours"),
    field_note: formatField(d, "field_note"),
    field_square_feet: formatField(d, "field_square_feet"),
    field_preferred_member: formatMemberFields(
      d?.field_preferred_member ? d.field_preferred_member[0] : null
    ),
    field_preferred_member_2: formatMemberFields(
      d?.field_preferred_member_2 ? d.field_preferred_member_2[0] : null
    ),
    field_preferred_member_3: formatMemberFields(
      d?.field_preferred_member_3 ? d.field_preferred_member_3[0] : null
    ),
    field_preferred_member_4: formatMemberFields(
      d?.field_preferred_member_4 ? d.field_preferred_member_4[0] : null
    ),
    _processed: d._processed,
  };
};

export const getTodaysHours = (rawHours, day) => {
  let today = getTodaysDay();
  if (day) today = day;
  let hours = [];
  if (rawHours) hours = getHours(rawHours);
  let now = "Not Set";
  hours.forEach((hour) => {
    if (hour.day === today) now = hour.hours;
  });

  return { today, time: now };
};
