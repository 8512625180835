export const getInitialTMTermsValues = (terms) => {
  return {
    field_tm_terms: {
      equipment: {
        weekly: {
          toggle: (terms?.equipment?.weekly ? true : false),
          threshold: (terms?.equipment?.weekly?.threshold ? terms.equipment.weekly.threshold : 7),
          start: (terms?.equipment?.weekly?.start ? terms.equipment.weekly.start : 5),
          percent: (terms?.equipment?.weekly?.percent ? terms.equipment.weekly.percent : 100),
        },
        monthly: {
          toggle: (terms?.equipment?.monthly ? true : false),
          threshold: (terms?.equipment?.monthly?.threshold ? terms.equipment.monthly.threshold : 30),
          start: (terms?.equipment?.monthly?.start ? terms.equipment.monthly.start : 21),
          percent: (terms?.equipment?.monthly?.percent ? terms.equipment.monthly.percent : 100),
        }
      },
      labor: {
        after_hours: {
          toggle: (terms?.labor?.after_hours ? true : false),
          multiplier: (terms?.labor?.after_hours?.multiplier ? terms.labor.after_hours.multiplier : 1.3),
        },
        overtime: {
          toggle: (terms?.labor?.overtime ? true : false),
          multiplier: (terms?.labor?.overtime?.multiplier ? terms.labor.overtime.multiplier : 1.5),
        },
        small_tools_charge: {
          toggle: (terms?.labor?.small_tools_charge ? true : false),
          percent: (terms?.labor?.small_tools_charge?.percent ? terms.labor.small_tools_charge.percent : 0.03),
        },
      },
    },
    // update_existing_ds_items: false,
  };
}

export const formatEquipmentDiscountLine = (line, full) => {
  let output = '';
  if(line.percent < 100){
    if(line.threshold - line.start > 1){
      output = `Days ${(Number(line.start) + 1)}-${line.threshold} - ${line.percent}% off`;
    }
    else{
      output = `Day ${line.threshold} - ${line.percent}% off`;
    }
  }
  else{
    if(full){
      let startDays = (line.start > 1 ? 'days' : 'day');
      output = `${line.threshold} days for the price of ${line.start} ${startDays}`;
    }
    else{
      output = `${line.threshold} for ${line.start}`;
    }
  }
  return output;
};

/**
 *  Check if a T&M Term is applicable to a Equipment Item
 *
 * @param Object termItem
 * @param String equipment
 * @return boolean
 */
export const checkTMTermEquipmentItem = (termItem, equipment) => {
  if(termItem){
    if(!termItem?.resource_categories || termItem.resource_categories.indexOf(Number(equipment.category_chase)) > -1){
      return true;
    }
  }
  return false;
}

/**
 *  Get the rate option for an Equipment Item in a Estimate/Invoice Line Item
 *
 * @param String termType
 * @param Object tmTerms
 * @param Number dailyRate
 * @returns Object
 */
export const getTMTermEquipmentLineItemRate = (termType, tmTerms, dailyRate) => {
  let info = {rate: null, name: null, description: null};
  if(tmTerms?.[termType]){
    info.rate = (dailyRate * tmTerms[termType].threshold) - (dailyRate * ((tmTerms[termType].threshold - tmTerms[termType].start) * (tmTerms[termType].percent / 100)));
    info.name = termType.slice(0, 1).toUpperCase() + termType.slice(1);
    info.description = '(' + formatEquipmentDiscountLine(tmTerms[termType], true) + ')';
  }
  return info;
}

/**
 *  Check if a T&M Term is applicable to a Labor Type
 *
 * @param Object termItem
 * @param String laborTypeIndex
 * @return boolean
 */
export const checkTMTermLaborItem = (termItem, laborTypeIndex) => {
  if(termItem){
    if(!termItem?.labor_type_excludes || (Number(laborTypeIndex) !== -1 && termItem.labor_type_excludes.indexOf(Number(laborTypeIndex)) === -1)){
      return true;
    }
  }
  return false;
}

/**
 *  Get the rate for a Labor Type Item based on a T&M Term
 *
 * @param String termType
 * @param Object tmTerms
 * @param Object laborTypeItem
 * @param Number laborTypeIndex
 * @returns Number
 */
export const getTMTermLaborTypeItemRate = (termType, tmTerms, laborTypeItem, laborTypeIndex) => {
  if(tmTerms && tmTerms?.[termType] && checkTMTermLaborItem(tmTerms[termType], laborTypeIndex)){
    return laborTypeItem.s * tmTerms?.[termType].multiplier;
  }
  switch(termType){
    case 'after_hours':
      return laborTypeItem.e;

    case 'overtime':
      return laborTypeItem.o;

    case 'travel':
      return laborTypeItem.o;

    default:
      return laborTypeItem.s;
  }
}

export const calcTermDiscount = (groups, terms) => {
  const info = getTermDiscountInfo(groups, terms);
  return info.discount;
};

export const calcTermDiscountDetails = (groups, terms) => {
  const info = getTermDiscountInfo(groups, terms);
  return info;
};

export const getTermDiscountInfo = (groups, termsParam) => {
  let totals = {
    discount: 0,
    details: [],
  };

  if(!termsParam || !termsParam?.equipment){
    return totals;
  }
  const equipmentTerms = (termsParam?.equipment ? termsParam.equipment : {});
//  Filter-out Resource Categories and Reverse sort the terms
  const terms = Object.fromEntries(
    Object.entries(equipmentTerms)
      .filter(([key, value]) => key !== 'resource_categories')
      .sort((x, y) => y[1].threshold - x[1].threshold)
  );
  const resourceCategories = Object.fromEntries(Object.entries(equipmentTerms).filter(([key, value]) => key === 'resource_categories'))['resource_categories'];

  groups.forEach((group) => {
    if(group?.field_li_product?.name !== "Equipment"){
      return;
    }
  //  Combine qtys for the same days
    let grouped = {};
    group.dsItems.forEach((item) => {
      var index = item.date + '-' + item.chase;
      if(!grouped[index]){
        grouped[index] = item;
      }
      else{
        grouped[index].qty += item.qty;
      }
    });

  //  Determine how many pieces of equipment were used, and on how many days they were used.
    let tallies = {};
    let items = {};
    Object.entries(grouped).forEach(([index, item]) => {
      if(resourceCategories.length > 0 && !resourceCategories.includes(Number(item.category_tid))){
        return;
      }
      if(!tallies[item.chase]){
        tallies[item.chase] = [];
      }
      [...Array(Math.ceil(item.qty))].forEach((_, i) => {
        if(!tallies[item.chase][i]){
          tallies[item.chase][i] = 0;
        }
        tallies[item.chase][i]++;
      });
      if(!items[item.chase]){
        items[item.chase] = {...item};
      }
    });

    let totalDiscount = 0;
    let details = [];
    Object.entries(items).forEach(([index, info]) => {
      let detail = getTMTermsEquipmentDetail(info, tallies[index], terms);
      if(!detail){
        return;
      }
      // let detail = {
      //   chase: info.chase,
      //   type: info.type,
      //   category: (info?.real_category ? info.real_category : info.category),
      //   sub_category: info.sub_category,
      //   name: info.name,
      //   price: info.price,
      //   billed: null,
      //   remainder: 0
      // };
      // let found = false;
      // tallies[index].forEach((days) => {
      //   let remainder = days;
      //   Object.entries(terms).forEach(([termType, term]) => {
      //     if(!detail[termType]){
      //       detail[termType] = 0;
      //       if(term.percent === 100){
      //         detail[termType + '_rate'] = Math.round((info.price * term.start) * 100) / 100;
      //       }
      //       else{
      //         detail[termType + '_rate'] = Math.round((
      //           (info.price * term.start)
      //           + ((1 - (term.percent/ 100)) * (info.price * (term.threshold - term.start)))
      //         ) * 100) / 100;
      //       }
      //     }
      //     let termQty = Math.floor(remainder / term.threshold);
      //     if(termQty > 0){
      //       detail[termType] += termQty;
      //       found = true;
      //     }
      //     remainder -= (termQty * term.threshold);
      //   });
      //   detail.remainder += remainder;
      // });
      // if(!found){
      //   return;
      // }

      // detail.remainder_total = Math.round((detail.remainder * detail.price) * 100) / 100;

      // let totalBase = detail.remainder_total;
      // let total = totalBase;
      // let totalDiff = 0;
      // Object.entries(terms).forEach(([termType, term]) => {
      //   if(!detail[termType] || detail[termType] === 0){
      //     delete detail[termType];
      //     delete detail[termType + '_rate'];
      //     return;
      //   }
      //   let base = Math.round(((detail[termType] * term.threshold) * detail.price) * 100) / 100;
      //   detail[termType + '_total_base'] = base;
      //   totalBase += base;
      //   let termTotal = Math.round((detail[termType] * detail[termType + '_rate']) * 100) / 100;
      //   detail[termType + '_total'] = termTotal;
      //   total += termTotal;
      //   let diff = detail[termType + '_total_base'] - detail[termType + '_total'];
      //   detail[termType + '_total_diff'] = diff;
      //   totalDiff += diff;
      // });
      // detail.total_base = totalBase;
      // detail.total = total;
      // detail.total_diff = totalDiff;
      // totalDiscount += totalDiff;

      details.push(detail);
    });

    if(Object.keys(details).length === 0){
      return;
    }

    totals.discount = totalDiscount;
    totals.details = details;
  })
  return totals;
}


export const getTMTermsEquipmentDetail = (info, tallyItems, equipmentTerms) => {
//  Filter-out Resource Categories and Reverse sort the terms
  const terms = Object.fromEntries(
    Object.entries(equipmentTerms)
      .filter(([key, value]) => key !== 'resource_categories')
      .sort((x, y) => y[1].threshold - x[1].threshold)
  );
  let detail = {
    chase: info.chase,
    type: info.type,
    category: (info?.real_category ? info.real_category : info.category),
    sub_category: info.sub_category,
    name: info.name,
    price: info.price,
    billed: null,
    remainder: 0
  };
  let found = false;
  tallyItems.forEach((days) => {
    let remainder = days;
    Object.entries(terms).forEach(([termType, term]) => {
      if(!detail[termType]){
        detail[termType] = 0;
        if(term.percent === 100){
          detail[termType + '_rate'] = Math.round((info.price * term.start) * 100) / 100;
        }
        else{
          detail[termType + '_rate'] = Math.round((
            (info.price * term.start)
            + ((1 - (term.percent/ 100)) * (info.price * (term.threshold - term.start)))
          ) * 100) / 100;
        }
      }
      let termQty = Math.floor(remainder / term.threshold);
      if(termQty > 0){
        detail[termType] += termQty;
        found = true;
      }
      remainder -= (termQty * term.threshold);
    });
    detail.remainder += remainder;
  });
  if(!found){
    return;
  }

  detail.remainder_total = Math.round((detail.remainder * detail.price) * 100) / 100;
  detail.remainder_total = Math.round((detail.remainder * detail.price) * 100) / 100;

  let totalBase = detail.remainder_total;
  let total = totalBase;
  let totalDiff = 0;
  Object.entries(terms).forEach(([termType, term]) => {
    if(!detail[termType] || detail[termType] === 0){
      delete detail[termType];
      delete detail[termType + '_rate'];
      return;
    }
    let base = Math.round(((detail[termType] * term.threshold) * detail.price) * 100) / 100;
    detail[termType + '_total_base'] = base;
    totalBase += base;
    let termTotal = Math.round((detail[termType] * detail[termType + '_rate']) * 100) / 100;
    detail[termType + '_total'] = termTotal;
    total += termTotal;
    let diff = detail[termType + '_total_base'] - detail[termType + '_total'];
    detail[termType + '_total_diff'] = diff;
    totalDiff += diff;
  });
  detail.total_base = totalBase;
  detail.total = total;
  detail.total_diff = totalDiff;

  return detail;
};
