import styled from "styled-components";
import constants from "components/constants";

export const Container = styled.div`
  // width: 420px;
  display: flex;
  flex-direction: column;
  height: 100%;

  & .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
  }

  // @media (max-width: ${constants.breakpoints.md}) {
  //   width: 90%;
  // }
`;

export const Main = styled.div`
  flex: 1;
  overflow: auto;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 1.25rem;
  background: var(--gradient-black);
  color: var(--color-white);
`;

export const Icon = styled.div`
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--color-white);

  & > svg {
    fill: var(--color-white);
    stroke: var(--color-white);

    & > path {
      stroke: var(--color-white);
    }
  }
`;
