import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatWorkAreaFields, formatWorkAreaGroupFields } from "./utils";
import { formatField } from "../../utility";
import { setAlert } from "features/Alert/alertSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "workAreas";

export const fetchWorkAreas = createAsyncThunk(
  `${namespace}/fetchWorkAreas`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams({
        ...params,
        "filter[status][0][!=]": "archived",
      });
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/damage_areas/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        areas: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkAreaGroups = createAsyncThunk(
  `${namespace}/fetchWorkAreaGroups`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/damage_area_groups/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const groups = response.data.data.filter((group) => group.nid);

      return {
        groups,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkAreasOverview = createAsyncThunk(
  `${namespace}/fetchWorkAreasOverview`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/damage_areas/overview/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postWorkArea = createAsyncThunk(
  `${namespace}/postWorkArea`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/damage_area`,
            },
          },
          _meta: {
            job_division_nid: id,
          },
          type: [{ target_id: "damage_area" }],
          title: [{ value: params.name }],
          field_da_is_general: [{ value: params.general === "true" ? 1 : 0 }],
          field_da_group: params.group ? [{ target_id: params.group }] : [],
          field_da_type: [{ target_id: params.type }],
          field_da_location: [{ value: params.location }],
          field_da_power: [{ value: params.power }],
          field_da_demo: [{ value: params.demo }],
          field_da_life_safety: [{ value: params.safety }],
          field_da_life_safety_descript: [
            { value: params.safety === "y" ? params["safety-issue"] : "" },
          ],
          field_da_notes: [{ value: params.scope }],
          field_da_area_size: [{ value: params.sqft }],
          field_da_linear_affected: [{ value: params.lf }],
          field_da_water_present: [{ value: params.water }],
          field_da_height_water: [
            { value: params.water === "y" ? params["water-height"] : 0 },
          ],
          field_consumables_tax:
            params.consumables >= 0 ? [{ value: params.consumables }] : [],
          field_da_equipment_resources: [],
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully created Work Area",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating work area: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchWorkArea = createAsyncThunk(
  `${namespace}/patchWorkArea`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/damage_area`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated Work Area",
        })
      );

      const fields = formatWorkAreaFields(response.data);
      const area = {
        nid: `${fields.nid}`,
        url: fields.path,
        title: fields.title,
        location: fields.field_da_location,
        type: fields.field_da_type.name,
        size: fields.field_da_area_size
          ? `${fields.field_da_area_size} sqft`
          : fields.field_da_linear_affected
          ? `${fields.field_da_linear_affected} LF`
          : "NA",
        status: fields.field_da_status,
        status_text: fields.field_da_status,
        is_general: fields.field_da_is_general,
        cust_approval_needed: fields.field_cust_approval_needed,
      };

      return area;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating work area: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postWorkAreaGroup = createAsyncThunk(
  `${namespace}/postWorkAreaGroup`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/damage_area_group`,
            },
          },
          title: [{ value: params.name }],
          field_dag_job_division: [{ target_id: id }],
        },
        tokenConfig(getState)
      );

      return formatWorkAreaGroupFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteWorkArea = createAsyncThunk(
  `${namespace}/deleteWorkArea`,
  async ({ id, area }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/damage-area/archive`,
        {
          damage_area_nid: id,
          archive: true,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully removed ${area.title}`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing item: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const reviewWorkArea = createAsyncThunk(
  `${namespace}/reviewWorkArea`,
  async (
    { id, type, notes, area },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/damage_area/review`,
        {
          damage_area_nid: id,
          status: type,
          notes: notes,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully reviewed ${area.title}`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error reviewing item: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


const stateWorkAreaAddUpdateSubAction = async ({ nid }, { getState, signal, rejectWithValue }) => {
  try {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await axios.get(
      `${config.api_url}/rest/job/damage_areas/${getState().workareas.params.id}?filter[nid][0]=${nid}`,
      {
        ...tokenConfig(getState),
        cancelToken: source.token,
      }
    );

    return {
      areas: response.data.data,
      pagination: response.data.pagination,
    };
  } catch (err) {
    let error = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data);
  }
}

export const addStateWorkArea = createAsyncThunk(
  `${namespace}/addStateWorkArea`,
  stateWorkAreaAddUpdateSubAction
);

export const updateStateWorkArea = createAsyncThunk(
  `${namespace}/updateStateWorkArea`,
  stateWorkAreaAddUpdateSubAction
);

const workAreasAdapter = createEntityAdapter({
  selectId: (area) => area.nid,
});

const workAreaGroupsAdapter = createEntityAdapter({
  selectId: (group) => group.nid,
});

const workAreasSlice = createSlice({
  name: namespace,
  initialState: workAreasAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    params: {},
    groups: workAreaGroupsAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    overview: {
      loading: false,
      total: null,
      totals: {},
      fee: null,
      requirements: {
        before: 0,
        during: 0,
        after: 0,
      },
    },
  }),
  reducers: {
    updateOneWorkArea: (state, action) => {
      workAreasAdapter.updateOne(state, action);
    },
  },
  extraReducers: {
    [fetchWorkAreas.pending](state) {
      state.loading = true;
    },
    [fetchWorkAreas.fulfilled](state, { payload: { areas, pagination }, meta }) {
      state.loading = false;
      state.pagination = pagination;
      state.params = meta.arg;
      workAreasAdapter.setAll(state, areas);
    },
    [fetchWorkAreas.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchWorkAreaGroups.pending](state) {
      state.groups.loading = true;
    },
    [fetchWorkAreaGroups.fulfilled](
      state,
      { payload: { groups, pagination } }
    ) {
      state.groups.loading = false;
      state.groups.pagination = pagination;
      workAreaGroupsAdapter.setAll(state.groups, groups);
    },
    [fetchWorkAreasOverview.pending](state) {
      state.overview.loading = true;
    },
    [fetchWorkAreasOverview.fulfilled](state, { payload: overview }) {
      state.overview.loading = false;
      state.overview.requirements = overview.photos_required_counts;
      state.overview.total = overview.damage_areas_total;
      state.overview.totals = overview.totals;
      state.overview.fee = overview.division_file_fee;
    },
    [fetchWorkAreasOverview.rejected](state, action) {
      if (!action.meta.aborted) {
        state.overview.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postWorkArea.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;
      const area = {
        nid: params.id,
        url: null,
        title: params.name,
        location: params.location,
        type: params.type_text,
        size: params.sqft
          ? `${params.sqft} sqft`
          : params.lf
          ? `${params.lf} LF`
          : "NA",
        status: "open",
        status_text: "Open",
        is_general: params.general,
        cust_approval_needed: 0,
        total: "$0.00",
        before_photo_qty: 0,
        during_photo_qty: 0,
        after_photo_qty: 0,
      };

      workAreasAdapter.addOne(state, area);
    },
    [postWorkArea.fulfilled](state, { meta, payload }) {
      const { params } = meta.arg;
      const fields = formatWorkAreaFields(payload);

      const area = {
        nid: `${fields.nid}`,
        url: fields.path,
        title: fields.title,
        location: fields.field_da_location,
        type: fields.field_da_type.name,
        size: fields.field_da_area_size
          ? `${fields.field_da_area_size} sqft`
          : fields.field_da_linear_affected
          ? `${fields.field_da_linear_affected} LF`
          : "NA",
        status: fields.field_da_status,
        status_text: fields.field_da_status,
        is_general: fields.field_da_is_general,
        cust_approval_needed: fields.field_cust_approval_needed,
        total: "$0.00",
        before_photo_qty: 0,
        during_photo_qty: 0,
        after_photo_qty: 0,
      };

      state.pagination.count++;
      workAreasAdapter.removeOne(state, params.id);
      workAreasAdapter.addOne(state, area);
    },
    [postWorkArea.rejected](state, action) {
      const { params } = action.meta.arg;
      workAreasAdapter.removeOne(state, params.id);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchWorkArea.pending](state, action) {
      const { id, params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      const area = {
        url: tempParams.path,
        title: tempParams.title,
        location: tempParams.field_da_location,
        type: tempParams.field_da_type.name,
        size: tempParams.field_da_area_size
          ? `${tempParams.field_da_area_size} sqft`
          : tempParams.field_da_linear_affected
          ? `${tempParams.field_da_linear_affected} LF`
          : "NA",
        status: tempParams.field_da_status,
        status_text: tempParams.field_da_status,
        is_general: tempParams.field_da_is_general,
        cust_approval_needed: tempParams.field_cust_approval_needed,
        is_saving: true,
      };

      workAreasAdapter.updateOne(state, {
        id,
        changes: area,
      });
    },
    [patchWorkArea.fulfilled](state, { meta, payload: area }) {
      const { id } = meta.arg;

      workAreasAdapter.updateOne(state, {
        id,
        changes: { ...area, is_saving: false },
      });
    },
    [patchWorkArea.rejected](state, action) {
      const { id, area } = action.meta.arg;
      const changes = {
        url: area.path,
        title: area.title,
        location: area.field_da_location,
        type: area.field_da_type.name,
        size: area.field_da_area_size
          ? `${area.field_da_area_size} sqft`
          : area.field_da_linear_affected
          ? `${area.field_da_linear_affected} LF`
          : "NA",
        status: area.field_da_status,
        status_text: area.field_da_status,
        is_general: area.field_da_is_general,
        cust_approval_needed: area.field_cust_approval_needed,
        is_saving: false,
      };

      workAreasAdapter.updateOne(state, { id, changes });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postWorkAreaGroup.fulfilled](state, { payload: group }) {
      workAreaGroupsAdapter.addOne(state.groups, group);
    },
    [postWorkAreaGroup.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [deleteWorkArea.pending](state, action) {
      const { id } = action.meta.arg;
      state.pagination.count--;
      workAreasAdapter.removeOne(state, id);
    },
    [deleteWorkArea.rejected](state, action) {
      const { area } = action.meta.arg;
      workAreasAdapter.addOne(state, area);
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [reviewWorkArea.pending](state, action) {
      const { id, type } = action.meta.arg;
      const statuses = {
        approved: "Approve",
        customer_approva: "Customer Approval",
        adjust: "Adjust",
        denied: "Deny",
        review: "Ready for review",
      };

      workAreasAdapter.updateOne(state, {
        id,
        changes: {
          status: type,
          status_proper: statuses[type],
        },
      });
    },
    [reviewWorkArea.rejected](state, action) {
      const { id, area } = action.meta.arg;
      workAreasAdapter.updateOne(state, { id, changes: { ...area } });
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [addStateWorkArea.pending](state) {
    //  This is to update the work area on the sly; so, don't broadcast the loading
      // state.loading = true;
      state.error = null;
    },
    [addStateWorkArea.fulfilled](state, { payload: { areas, pagination } }) {
      workAreaGroupsAdapter.addOne(state, areas[0]);
      state.error = null;
    },
    [addStateWorkArea.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [updateStateWorkArea.pending](state) {
    //  This is to update the user on the sly; so, don't broadcast the loading
      // state.loading = true;
      state.error = null;
    },
    [updateStateWorkArea.fulfilled](state, { payload: { areas, pagination } }) {
      workAreaGroupsAdapter.updateOne(state, {
        id: String(areas[0].nid),
        changes: areas[0],
      })
      state.error = null;
    },
    [updateStateWorkArea.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getAreasLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workareas.loading
);

export const getAreasPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workareas.pagination
);

export const getAreasParamsSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workareas.params
);

export const getAreasRequirementsSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workareas.overview.requirements
);

export const getAreasOverviewSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workareas.overview
);

export const getAreasFeeSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workareas.overview.fee
);

export const getAreasErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workareas.error
);

export const workAreasSelectors = workAreasAdapter.getSelectors(
  (state) => state.workareas
);

export const workAreaGroupsSelectors = workAreaGroupsAdapter.getSelectors(
  (state) => state.workareas.groups
);

export const {
  updateOneWorkArea,
} = workAreasSlice.actions;

export default workAreasSlice.reducer;
