import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { withPermissions } from "../../providers";

import { getIsAuthenticatedSelector } from "../../features/Auth/authSlice";

const FullScreenUserRoute = ({ children, roles, ...rest }) => {
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <div>{children}</div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default withPermissions(FullScreenUserRoute);
