import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip, IconButton, } from "@mui/material";
import { ReactComponent as IconHub } from "assets/images/icons/icon-hub.svg";

import Drawer from "common/Drawer";
import HubEnvironments from "./HubEnvironments";
import EventWidget from "features/Hub/components/EventWidget";
import GroupsWidget from "features/Hub/components/GroupsWidget";
import ConversationsWidget from "features/Hub/components/ConversationsWidget";
import ArticleWidget from "features/Hub/components/ArticleWidget";
import { hubEnvironmentSelectors } from "features/Hub/hubSlice.js";
import { getHubEnvironmentHeadingStyles, getHubEnvironmentIndicatorInfo } from "../utils.js";
import Indicators from "common/Indicators";

const HubIndicator = (props) => {
  const drawerRef = useRef();
  const helixAPICurrent = useSelector((state) => state.user.helixAPICurrent.data);
  const [indicatorInfo, setIndicatorInfo] = useState({});
  const envs = useSelector(hubEnvironmentSelectors.selectAll);
  const currentEnvironment = useSelector((state) => state.hub.currentEnvironment);

  useEffect(() => {
    if(helixAPICurrent?.hub_updates){
      const indicatorInfo = getHubEnvironmentIndicatorInfo(Object.entries(helixAPICurrent.hub_updates).map(([nid, env]) => env));
      setIndicatorInfo(indicatorInfo);
    }
  }, [helixAPICurrent]);

  let icon = <IconHub className="w-6 h-6" />;

  const closeDrawer = () => {
    drawerRef.current.close();
  }

  return (
    <Drawer
      ref={drawerRef}
      anchor="right"
      primary="Hub"
      icon={icon}
      remoteOpen={props?.open}
      onClose={props?.onClose}
      action={
        <Tooltip title={`Hub${indicatorInfo?.description ? ' | ' + indicatorInfo.description : ''}`}>
          <IconButton style={{ color: "white" }} size="large">
            <Indicators
              {...indicatorInfo}
              marginRightStart={0.125}
            >
              {icon}
            </Indicators>
          </IconButton>
        </Tooltip>
      }
    >
      {envs.length > 1 && (
        <div className="hub-environment-wrapper bg-banner-black" style={getHubEnvironmentHeadingStyles(currentEnvironment)} >
          <HubEnvironments omitHubIcon />
        </div>
      )}
      <div className="p-2" >
        <div onClick={(e) => {setTimeout(closeDrawer, 500);}}>
          {/* <Feed compact={true} /> */}
          <EventWidget />
          <ConversationsWidget />
          <GroupsWidget />
          <ArticleWidget />
        </div>
      </div>
    </Drawer>
  );
};

HubIndicator.propTypes = {};

export default HubIndicator;
