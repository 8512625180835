import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  savePhoto: async ({ params, config }) => {
    return instance.post("/photos", params, config);
  },
};

export default API;
