import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { formatField } from "../../utility";
import {
  removeAllCategories,
  removeAllExpenseTypes,
  removeAllLaborActivities,
  removeAllLaborTypes,
  removeAllSubCategories,
} from "../PriceList/priceListSlice";
import { formatPriceListsByOwnerType } from "./utils";
import {getQueryParamsNested} from "utility/getQueryParams";

const namespace = "pricelists";

export const fetchPriceLists = createAsyncThunk(
  `${namespace}/fetchPriceLists`,
  async ({ member, customer }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/price-list/job/available/${customer}/${member}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return formatPriceListsByOwnerType(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPriceListsSeperate = createAsyncThunk(
  `${namespace}/fetchPriceListsSeperate`,
  async ({ member, customer }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/price-list/job/available/${customer}/${member}/1`,
        // `${config.api_url}/rest/price-list/job/available/${customer}/0/1`, // Testing purposes to override member price lists
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        expenses: formatPriceListsByOwnerType(
          response.data.expense_types_price_lists
        ),
        resources: formatPriceListsByOwnerType(
          response.data.resources_price_lists
        ),
        laborActivities: formatPriceListsByOwnerType(
          response.data.service_activities_price_lists
        ),
        laborTypes: formatPriceListsByOwnerType(
          response.data.service_types_price_lists
        ),
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchPriceList = createAsyncThunk(
  `${namespace}/patchPriceList`,
  async ({ params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/price-list/add-edit`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated price list",
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating price list: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postPriceList = createAsyncThunk(
  `${namespace}/postPriceList`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/price-list/add-edit`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully added price list",
        })
      );

      return {
        pid: formatField(response.data, "id"),
        title: formatField(response.data, "field_pl_title"),
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding price list: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePriceList = createAsyncThunk(
  `${namespace}/deletePriceList`,
  async ({ id }, { getState, rejectWithValue, dispatch }) => {
    try {
      await axios.delete(
        `${config.api_url}/rest/price-list/delete/${id}`,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully removed price list",
        })
      );

      dispatch(removeAllCategories());
      dispatch(removeAllSubCategories());
      dispatch(removeAllExpenseTypes());
      dispatch(removeAllLaborTypes());
      dispatch(removeAllLaborActivities());

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing price list: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const importPriceList = createAsyncThunk(
  `${namespace}/importPriceList`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/price-list/import`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully imported price list",
        })
      );

      return {
        pid: formatField(response.data, "id"),
        title: formatField(response.data, "field_pl_title"),
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error importing price list: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const removePriceListsFromChildren = createAsyncThunk(
  `${namespace}/removePriceListsFromChildren`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/price-list/remove-from-children`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully removed Price Lists from Children",
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing price lists from children: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPriceListsToChildren = createAsyncThunk(
  `${namespace}/addPriceListsToChildren`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/price-list/add-to-children`,
        params,
        tokenConfig(getState)
      );

      // dispatch(
      //   setAlert({
      //     show: true,
      //     kind: "positive",
      //     msg: "Successfully removed Price Lists from Children",
      //   })
      // );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding price lists to children: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPriceListTypeOptions = createAsyncThunk(
  `${namespace}/fetchPriceListTypeOptions`,
  async ({ params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const queryString = getQueryParamsNested(params, {arrayFormat: 'index'});
      const response = await axios.get(
        `${config.api_url}/rest/price-list/type-options${queryString}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const priceListsAdapter = createEntityAdapter({
  selectId: (resource) => resource.pid,
});

const priceListsSlice = createSlice({
  name: namespace,
  initialState: priceListsAdapter.getInitialState({
    loading: false,
    error: null,
    expenses: priceListsAdapter.getInitialState({}),
    resources: priceListsAdapter.getInitialState({}),
    laborActivities: priceListsAdapter.getInitialState({}),
    laborTypes: priceListsAdapter.getInitialState({}),
    optionType: '',
    options: [],
  }),
  reducers: {
    removeAllLists: priceListsAdapter.removeAll,
  },
  extraReducers: {
    [fetchPriceLists.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchPriceLists.fulfilled](state, { payload: lists }) {
      state.loading = false;
      priceListsAdapter.setAll(state, lists);
    },
    [fetchPriceLists.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchPriceListsSeperate.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchPriceListsSeperate.fulfilled](
      state,
      { payload: { expenses, resources, laborTypes, laborActivities } }
    ) {
      state.loading = false;
      priceListsAdapter.setAll(state.expenses, expenses);
      priceListsAdapter.setAll(state.resources, resources);
      priceListsAdapter.setAll(state.laborTypes, laborTypes);
      priceListsAdapter.setAll(state.laborActivities, laborActivities);
    },
    [fetchPriceListsSeperate.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchPriceList.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;

      priceListsAdapter.updateOne(state, {
        id: params.pid,
        changes: { title: params.name },
      });
    },
    [patchPriceList.rejected](state, action) {
      const { params, list } = action.meta.arg;
      priceListsAdapter.updateOne(state, {
        id: params.pid,
        changes: list,
      });
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postPriceList.fulfilled](state, { payload: list }) {
      priceListsAdapter.addOne(state, list);
    },
    [deletePriceList.fulfilled](state, { payload: list }) {
      priceListsAdapter.removeOne(state, list);
    },
    [importPriceList.fulfilled](state, { payload: list }) {
      priceListsAdapter.addOne(state, list);
    },
    [fetchPriceListTypeOptions.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchPriceListTypeOptions.fulfilled](state, { meta, payload: options }) {
      state.loading = false;
      const { type } = meta.arg;
      state.optionType = type;
      state.options = options;
    },
    [fetchPriceListTypeOptions.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getPriceListsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.pricelists.loading
);

export const getPriceListsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.pricelists.error
);

export const priceListsSelectors = priceListsAdapter.getSelectors(
  (state) => state.pricelists
);

export const priceListsExpenseSelectors = priceListsAdapter.getSelectors(
  (state) => state.pricelists.expenses
);

export const priceListsResourcesSelectors = priceListsAdapter.getSelectors(
  (state) => state.pricelists.resources
);

export const priceListsLaborActivitiesSelectors =
  priceListsAdapter.getSelectors((state) => state.pricelists.laborActivities);

export const priceListsLaborTypesSelectors = priceListsAdapter.getSelectors(
  (state) => state.pricelists.laborTypes
);

export const priceListsTypeOptionsSelectors = priceListsAdapter.getSelectors(
  (state) => state.pricelists.options
);

export const { removeAllLists } = priceListsSlice.actions;

export default priceListsSlice.reducer;
