import { isArray } from "lodash";
import { formatField, getTaxonomyFields } from "../../utility";

export const formatRoomFields = (r) => {
  return {
    nid: formatField(r, "nid"),
    title: formatField(r, "title"),
    field_mf_chamber_level: getTaxonomyFields(r, "field_mf_chamber_level"),
    //   field_mf_room_scopes: formatScopesArray(r, "field_mf_room_scopes"),
    //   field_ii_assignments: formatInventoryItemsArray(r, "field_ii_assignments"),
    //   field_mf_room_flooring_options: getValuesFromFieldArray(
    //     r,
    //     "field_mf_room_flooring_options"
    //   ),
    //   field_mf_room_surfaces: formatRoomWallArray(r, "field_mf_room_surfaces"),
    //   inspectionReportData: getInspectionReportData(r),
  };
};

export const formatInitialValues = (data, existingData) => {
  const params = {};
  if (existingData) {
    if (isArray(data)) {
      data.forEach((field) => {
        params[field.tid] =
          existingData.findIndex((d) => d.tid === Number(field.tid)) >= 0
            ? true
            : false;
      });
    }
  } else {
    if (isArray(data)) {
      data.forEach((field) => {
        params[field.tid] = false;
      });
    }
  }
  return params;
};

export const formatFlooringOptionsForSubmit = (obj) => {
  const data = [];
  for (let key in obj) {
    if (key !== "title" && key !== "mf_chamber_nid" && key !== "id") {
      if (obj[key]) {
        data.push({ target_id: key });
      }
    }
  }

  return data;
};
