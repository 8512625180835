import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatLocationFields } from "./utils";
import { formatField } from "../../utility";

import { setAlert } from "features/Alert/alertSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "location";

export const fetchLocation = createAsyncThunk(
  `${namespace}/fetchLocation`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatLocationFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLocationByName = createAsyncThunk(
  `${namespace}/fetchLocationByName`,
  async (name, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/location/${name}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatLocationFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchLocation = createAsyncThunk(
  `${namespace}/patchLocation`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/customer_location`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated location",
        })
      );
      return formatLocationFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating location: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postLocation = createAsyncThunk(
  `${namespace}/postLocation`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/customer_location`,
            },
          },
          _meta: {
            customer_nid: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatLocationFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding Location: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAvailableLocationMembers = createAsyncThunk(
  `${namespace}/fetchAvailableLocationMembers`,
  async (
    { id, coords, county, params },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/members/for-customer-location/${id}/${coords}/${county}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        members: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreAvailableLocationMembers = createAsyncThunk(
  `${namespace}/fetchMoreAvailableLocationMembers`,
  async (
    { id, coords, county, params },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/members/for-customer-location/${id}/${coords}/${county}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        members: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const membersAdapter = createEntityAdapter({
  selectId: (member) => member.member_nid,
});

const locationSlice = createSlice({
  name: namespace,
  initialState: {
    error: null,
    loading: false,
    data: {},
    availableMembers: membersAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
  },
  reducers: {
    setLocationData: (state, { payload: location }) => {
      state.data = location;
    },
  },
  extraReducers: {
    [fetchLocation.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchLocation.fulfilled](state, { payload: location }) {
      state.loading = false;
      state.data = location;
    },
    [fetchLocation.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchLocationByName.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchLocationByName.fulfilled](state, { payload: location }) {
      state.loading = false;
      state.data = location;
    },
    [fetchLocationByName.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchAvailableLocationMembers.pending](state) {
      state.availableMembers.loading = true;
    },
    [fetchAvailableLocationMembers.fulfilled](
      state,
      { payload: { members, pagination } }
    ) {
      state.availableMembers.loading = false;
      state.availableMembers.pagination = pagination;
      membersAdapter.setAll(state.availableMembers, members);
    },
    [fetchAvailableLocationMembers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.availableMembers.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreAvailableLocationMembers.fulfilled](
      state,
      { payload: { members, pagination } }
    ) {
      state.availableMembers.pagination = pagination;
      membersAdapter.addMany(state.availableMembers, members);
    },
    [fetchMoreAvailableLocationMembers.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchLocation.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      state.data = {
        ...state.data,
        ...tempParams,
      };
    },
    [patchLocation.fulfilled](state, { payload: location }) {
      state.data = location;
    },
    [patchLocation.rejected](state, action) {
      const { location } = action.meta.arg;

      state.data = location;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getLocationDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.location.data
);

export const getLocationLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.location.loading
);

export const getAvailableMembersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.location.availableMembers.loading
);

export const getAvailableMembersPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.location.availableMembers.pagination
);

export const availableMembersSelectors = membersAdapter.getSelectors(
  (state) => state.location.availableMembers
);

export const { setLocationData } = locationSlice.actions;

export default locationSlice.reducer;
