import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

// const getQueryParams = (params) => {
//   return `?${
//     typeof params === "string"
//       ? params.substring(1)
//       : querystring.stringify(params)
//   }`;
// };

const publicInstance = axios.create();

const namespace = "elocal";

export const patchElocalShare = createAsyncThunk(
  `${namespace}/patchElocalShare`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/elocal/action/set-elocal`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postElocalUpload = createAsyncThunk(
  `${namespace}/postElocalUpload`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await publicInstance.post(
        `${config.api_url}/rest/elocal/upload`,
        params
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postElocalVerifyHash = createAsyncThunk(
  `${namespace}/postElocalVerifyHash`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await publicInstance.post(
        `${config.api_url}/rest/elocal/verify-hash?_format=hal_json`,
        params
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchElocalHashReset = createAsyncThunk(
  `${namespace}/patchElocalHashReset`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/elocal/action/reset-hash`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchElocalAcceptTerms = createAsyncThunk(
  `${namespace}/patchElocalAcceptTerms`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/elocal/action/accept-terms`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const elocalAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const elocalSlice = createSlice({
  name: namespace,
  initialState: {},
  reducers: {},
  extraReducers: {},
});

// Custom selectors
const selectSelf = (state) => state;
export const getElocalLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.elocal.loading
);

export const getElocalPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.elocal.pagination
);

export const searchSelectors = elocalAdapter.getSelectors(
  (state) => state.elocal
);

export default elocalSlice.reducer;
