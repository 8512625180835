import React from "react";
import { useField } from "formik";
import { TextField, CircularProgress } from "@mui/material";
import { Autocomplete } from '@mui/material';

const AutocompleteFormField = ({
  fullWidth,
  label,
  variant,
  htmlFor,
  endAdornment,
  labelwidth,
  margin,
  required,
  shrink,
  loading,
  options,
  autoFocus,
  multiple,
  open,
  onOpen,
  onClose,
  inputProps,
  helperText,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <Autocomplete
      autoComplete={false}
      multiple={multiple}
      open={open}
      onChange={(e, value) => helpers.setValue(value)}
      onBlur={(e) => helpers.setTouched(true)}
      value={field.value}
      onOpen={onOpen}
      onClose={onClose}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      options={options}
      loading={loading}
      disableCloseOnSelect={multiple}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? `${label}${required ? "*" : ""}` : null}
          variant={variant}
          margin={margin}
          error={!!errorText}
          helperText={(errorText ? errorText + ' ' : '') + helperText}
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...inputProps}
        />
      )}
    />
  );
};

export default AutocompleteFormField;
