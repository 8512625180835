import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const userAPI = {
  getCurrentUser: async ({ config }) => {
    return instance.get("/user", config);
  },
  updateAvatar: async ({ params, config }) => {
    return instance.patch("/user/avatar", params, config);
  },
};

export default userAPI;
