import React from "react";
import styled from "styled-components";
import { toArray } from "lodash";

const Styles = styled.div`
  border-bottom: 1px solid var(--color-gray-medium);
  padding: 0 1.25rem;
  background: white;
`;

const Tabs = ({ children, handleChange, value, ...rest }) => {
  return (
    <Styles {...rest}>
      {toArray(children).map((child, i) =>
        React.cloneElement(child, {
          key: i,
          handleChange: handleChange,
          value: value,
        })
      )}
    </Styles>
  );
};

Tabs.propTypes = {};

export default Tabs;
