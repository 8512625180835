import action from "../action";
import config from "../../config";

export const getMembers = (params) =>
  action("members", config.api_url, "rest/members", params);

export const getMemberSnapshot = (nid, params) =>
  action("memberSnapshot", config.api_url, `node/${nid}`, params);

export const getMember = (name, params) =>
  action("member", config.api_url, `member/${name}`, params);

export const postMember = (params) =>
  action("member", config.api_url, `node`, params, "post", true);

export const postChildMember = (params) =>
  action("member", config.api_url, `node`, params, "post", false);

export const patchMember = (nid, params) =>
  action("member", config.api_url, `node/${nid}`, params, "patch", true);

export const getMemberJobs = (nid, params) =>
  action("memberJobs", config.api_url, `rest/member/jobs/${nid}`, params);

export const getMemberUsers = (nid, params) =>
  action("memberUsers", config.api_url, `rest/member/users/${nid}`, params);

export const getMemberTempUsers = (nid, params) =>
  action(
    "memberTempUsers",
    config.api_url,
    `rest/temporary-users/${nid}`,
    params
  );

export const deleteTempUser = (params) =>
  action(
    "memberTempUsers",
    config.api_url,
    `rest/temporary-user/archive`,
    params,
    "patch"
  );

export const postMemberUser = (params) =>
  action("memberUser", config.api_url, "rest/member/add/user", params, "post");

export const getMemberActivity = (nid, params) =>
  action("memberActivity", config.api_url, `rest/member/log/${nid}`, params);

export const getChildMembers = (nid, params) =>
  action(
    "childMembers",
    config.api_url,
    `rest/member/child-members/${nid}`,
    params
  );

export const getMemberNotes = (nid, params) =>
  action("memberNotes", config.api_url, `rest/member/notes/${nid}`, params);

export const getMemberCompliance = (nid, params) =>
  action(
    "memberCompliance",
    config.api_url,
    `rest/compliance-manager/${nid}`,
    params
  );

export const getMemberResources = (nid, params) =>
  action(
    "memberResources",
    config.api_url,
    `rest/resource_items/member/${nid}`,
    params
  );

export const getMemberEstimates = (nid, params) =>
  action(
    "memberEstimates",
    config.api_url,
    `rest/askaime/files/${nid}`,
    params
  );

export const getMemberFiles = (entity_type, field_name, entity_id, params) =>
  action(
    "memberFiles",
    config.api_url,
    `entity/${entity_type}/${field_name}/${entity_id}`,
    params
  );

export const getAvailableMemberUsersIncludingTemp = (nid, params) =>
  action(
    "availableUsers",
    config.api_url,
    `rest/member/users-w-temp/${nid}`,
    params
  );

export const getMemberInsuranceAgents = (nid, params) =>
  action(
    "memberInsuranceAgents",
    config.api_url,
    `entity/node/field_member_insurance_agents/${nid}`,
    params
  );

export const getMemberInsuranceAgent = (nid, params) =>
  action("memberInsuranceAgent", config.api_url, `node/${nid}`, params);

export const postMemberInsuranceAgent = (params) =>
  action("memberInuranceAgent", config.api_url, "node", params, "post", true);

export const patchMemberInsuranceAgent = (nid, params) =>
  action(
    "memberInuranceAgent",
    config.api_url,
    `node/${nid}`,
    params,
    "patch",
    true
  );
