import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import {
  FormHelperText,
  FormControl,
  FormControlLabel,
  Switch,
} from "@mui/material";

const SwitchFormField = ({
  aria,
  label,
  margin,
  disabled,
  name,
  helperText,
  onChange,
  ...props
}) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const handleChange = (e) => {
          form.setFieldValue(name, e.target.checked);
          if(onChange){
            onChange(e);
          }
        };

        const errorText = meta.error && meta.touched ? meta.error : "";
        return label ? (
          <FormControl component="fieldset" error={!!errorText} margin={margin}>
            <FormControlLabel
              control={
                <Switch
                  checked={field.value}
                  name={field.name}
                  {...props}
                  onChange={handleChange}
                />
              }
              label={label}
              disabled={disabled}
            />
            <FormHelperText error={!!errorText}>
              {errorText || helperText}
            </FormHelperText>
          </FormControl>
        ) : (
          <Switch
            checked={field.value}
            onChange={handleChange}
            name={field.name}
            disabled={disabled}
            {...props}
          />
        );
      }}
    </Field>
  );
};

const { string, bool, oneOfType, object } = PropTypes;
SwitchFormField.propTypes = {
  aria: string,
  label: oneOfType([string, object]),
  margin: string,
  disabled: bool,
};

export default SwitchFormField;
