import * as Yup from "yup";

export const addLocationFormSchema = Yup.object().shape({
  title: Yup.string("Must be a string").required("Required"),
  field_note: Yup.string("Must be a string"),
  field_cl_status: Yup.string("Must be a string").required("Required"),
  field_street_address: Yup.object().shape({
    address_line1: Yup.string("Must be a string").required("Required"),
    address_line2: Yup.string("Must be a string"),
    administrative_area: Yup.string("Must be a string").required("Required"),
    locality: Yup.string("Must be a string").required("Required"),
    postal_code: Yup.string("Must be a string").required("Required"),
  }),
  field_preferred_member: Yup.object()
    .typeError("Required")
    .required("Required"),
});
