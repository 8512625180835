import React, { useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiFileUploadLine } from "react-icons/ri";
import {
  ListSubheader,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { size } from "lodash";

import Dialog from "common/Dialog";
import { MultiUploader } from "common/Uploader";

import Alert from "common/Alert";
import { postJobDivisionFile } from "features/Files/filesSlice";
import { jobDivisionFileCategoriesSelectors } from "features/Taxonomies/taxonomiesSlice";
import { getDivisionDataSelector } from "../jobSlice";
import { workAreasSelectors } from "features/WorkAreas/workAreasSlice";
import { chambersSelectors } from "features/Chambers/chambersSlice";
import { fetchJobProgress } from "features/JobProgress/jobProgressSlice";
import FileToolbar from "../components/FileToolbar";

export const Form = ({
  setFormData,
  formData,
  id,
  timeOverride,
  categoryNameOverride,
  references,
  division,
  roleType,
  hasMBYAgent,
}) => {
  const categories = useSelector(jobDivisionFileCategoriesSelectors.selectAll);
  const areas = useSelector(workAreasSelectors.selectAll);
  const chambers = useSelector(chambersSelectors.selectAll);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [id]: { ...formData[id], [e.target.name]: e.target.value },
    });
  };

  const handleDateChange = (value) => {
    setFormData({
      ...formData,
      [id]: { ...formData[id], date: value },
    });
  };

  const handleChecked = (e) => {
    setFormData({
      ...formData,
      [id]: { ...formData[id], [e.target.name]: e.target.checked },
    });
  };

  const getLabelById = useCallback(
    (id, query, field) => {
      const entity = categories.find((g) => g[query] === id);

      return entity?.[field];
    },
    [categories]
  );

  const filterCategories = useMemo(() => ["Invoice", "Client Invoice", "Client Estimate", "Estimate", "PO"], []);

  const filterDSCategories = useMemo(
    () => [
      "DS Equipment Hand Written Sheet",
      "DS Expense Hand Written Sheet",
      "DS Service Hand Written Sheet",
    ],
    []
  );

  const category = getLabelById(formData[id].category, "tid", "name");

  return (
    <div>
      {!categoryNameOverride && (
        <FormControl variant="filled" size="small" fullWidth margin="normal">
          <InputLabel id="div-category">Category</InputLabel>
          <Select
            name="category"
            labelId="div-category"
            id="div-category-select"
            value={formData[id].category}
            onChange={handleChange}
            label="Category"
          >
            {categories.map((option) => (
              <MenuItem value={option.tid}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {(size(areas) > 0 || size(chambers) > 0 || size(references) > 0) && (
        <FormControl variant="filled" size="small" fullWidth margin="normal">
          <InputLabel id="div-reference">Reference</InputLabel>
          <Select
            name="reference"
            labelId="div-reference"
            id="div-reference-select"
            value={formData[id].reference}
            onChange={handleChange}
            label="Reference"
          >
            {size(areas) > 0 && <ListSubheader>Areas</ListSubheader>}
            {areas.map((obj) => (
              <MenuItem key={obj.nid} value={obj.nid}>
                {obj.title}
              </MenuItem>
            ))}
            {size(chambers) > 0 && <ListSubheader>Chambers</ListSubheader>}
            {chambers.map((obj) => (
              <MenuItem key={obj.nid} value={obj.nid}>
                {obj.title}
              </MenuItem>
            ))}
            {references && references.map((obj) => (
              <MenuItem key={obj.nid} value={obj.nid}>
                {obj.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {(categoryNameOverride === "Photos" || category === "Photos") && (
        <FormControl variant="filled" size="small" fullWidth margin="normal">
          <InputLabel id="div-time">Time</InputLabel>
          <Select
            name="time"
            labelId="div-time"
            id="div-time-select"
            value={formData[id].time}
            onChange={handleChange}
            label="Time"
          >
            {[
              { value: "before", label: "Before" },
              { value: "during", label: "During" },
              { value: "after", label: "After" },
            ].map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {!categoryNameOverride && filterCategories.indexOf(category) >= 0 && (
        <TextField
          margin="normal"
          variant="filled"
          size="small"
          label="Amount"
          autocomplete="off"
          name="amount"
          onChange={handleChange}
          fullWidth
          value={formData[id].amount}
        />
      )}
      {!categoryNameOverride && filterDSCategories.indexOf(category) >= 0 && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Day"
            name="date"
            fullWidth
            margin="normal"
            value={formData[id].date}
            onChange={handleDateChange}
            inputVariant="filled"
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" />
            )}
          />
        </LocalizationProvider>
      )}
      {roleType !== "" && (
        <>
        {roleType !== "phx_client" && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData[id].phx_client}
                onChange={handleChecked}
                name="phx_client"
              />
            }
            label={`${division?._processed.client_name} Admin`}
          />
        </div>
        )}
        {roleType !== "member" && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData[id].member}
                onChange={handleChecked}
                name="member"
              />
            }
            label="Member"
          />
        </div>
        )}
        {roleType !== "customer" && (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData[id].customer}
                  onChange={handleChecked}
                  name="customer"
                />
              }
              label="Customer"
            />
          </div>
        )}
        {(hasMBYAgent && roleType !== "agent") && (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData[id].mby_agent}
                  onChange={handleChecked}
                  name="mby_agent"
                />
              }
              label="Agent"
            />
          </div>
        )}
        </>
      )}
    </div>
  );
};

const AddDocument = ({
  referenceNid,
  timeOverride,
  categoryNameOverride,
  children,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const categories = useSelector(jobDivisionFileCategoriesSelectors.selectAll);
  const division = useSelector(getDivisionDataSelector);
  const roleType = useSelector((state) => state.auth.user.data.roleType);
  const hasMBYAgent = useSelector((state) => division?._processed?.has_mby_agent);
  const [selected, setSelected] = useState([]);
  const [allSelectable, setAllSelectable] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [completing, setCompleting] = useState(false);

  const getIdByLabel = useCallback(
    (name) => {
      const entity = categories.find((g) => g.name === name);

      return entity?.tid;
    },
    [categories]
  );

  const handleSubmit = async (meta, fid, setComplete) => {
    const params = {
      ...meta,
      fid,
      reference: referenceNid ? referenceNid : meta?.reference,
    };

    if (categoryNameOverride) {
      params.category = getIdByLabel(categoryNameOverride);
    }

    if (timeOverride) {
      params.time = timeOverride;
    }

    await dispatch(
      postJobDivisionFile({
        id: division.nid,
        params,
      })
    ).then((res) => {
      setComplete((complete) => complete + 1);
    });
  };

  const handleComplete = () => {
    if(!completing){
      setCompleting(true);
      dispatch(
        fetchJobProgress(division.nid)
      )
      .then(() => {
        setCompleting(false);
      });
    }
    handleClose();
  };

  const handleChangeBasedOnType = useCallback(
    (type, id, formData, setFormData) => {
      if (type.includes("image") && !categoryNameOverride) {
        setMessage(
          "We noticed some files you have selected are image type files. We have switched the category to Photos for these files."
        );
        setFormData({
          ...formData,
          [id]: { ...formData[id], category: getIdByLabel("Photos") },
        });
      }
    },
    [getIdByLabel, categoryNameOverride]
  );

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
    setSelected([]);
    setAllSelected(false);
  };

  const handleSelect = useCallback(
    (id) => {
      const index = selected.indexOf(id);
      const tempSelected = [...selected];
      if (index > -1) {
        tempSelected.splice(index, 1);
        if(tempSelected.length !== selected.length){
          setAllSelected(false);
        }
      } else {
        tempSelected.push(id);
      }

      setSelected(tempSelected);
    },
    [selected]
  );

  const handleSelectAll = useCallback(
    () => {
      if(!allSelected){
        setSelected(allSelectable);
        setAllSelected(true);
      }
      else{
        setSelected([]);
        setAllSelected(false);
      }
    },
    [allSelectable, allSelected]
  );

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: () => setOpen(true),
        })
      ) : (
        <ListItem
          button
          onClick={() => setOpen(true)}
          className="my-third-step"
        >
          <ListItemIcon>
            <RiFileUploadLine />
          </ListItemIcon>
          <ListItemText primary="File" />
        </ListItem>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        title="Add Documents"
        initialValues={{}}
        onSubmit={handleSubmit}
        disablePadding
      >
        <>
          {message && (
            <div style={{ padding: " 0 1.25rem" }}>
              <Alert kind="warning">{message}</Alert>
            </div>
          )}
          <MultiUploader
            // entity="paragraph"
            // bundle="job_division_file"
            entity="node"
            bundle="general_file"
            field="field_file"
            initialValues={{
              category: categoryNameOverride
                ? getIdByLabel(categoryNameOverride)
                : getIdByLabel("Uncategorized"),
              phx_client: true,
              member: roleType === "phx_client" ? false : true,
              customer: roleType === "phx_client" ? false : true,
              mby_agent: roleType === "phx_client" || !hasMBYAgent ? false : true,
              reference: referenceNid ? referenceNid : null,
              time: timeOverride ? timeOverride : "",
            }}
            onUploadSuccess={handleSubmit}
            onUploadStart={() => {}}
            onUploadFail={() => {}}
            onRemove={() => {}}
            onComplete={handleComplete}
            form={(props) => (
              <Form
                timeOverride={timeOverride}
                categoryNameOverride={categoryNameOverride}
                division={division}
                roleType={roleType}
                hasMBYAgent={hasMBYAgent}
                {...props}
              />
            )}
            onClose={handleClose}
            onChangeBasedOnType={handleChangeBasedOnType}
            onSelect={handleSelect}
            selected={selected}
            setAllSelectable={setAllSelectable}
            fileToolbar={
              (props) => (
                <FileToolbar
                  inUpload={true}
                  selected={selected}
                  onClear={() => {setSelected([]); setAllSelected(false);}}
                  allSelected={allSelected}
                  onSelectAll={handleSelectAll}
                  type="multiselect"
                  subType={categoryNameOverride === "Photos" ? "photos" : "documents"}
                  {...props}
                />
              )
            }
          />
        </>
      </Dialog>
    </>
  );
};

AddDocument.propTypes = {};

export default AddDocument;
