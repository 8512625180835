import action from "../action";
import config from "../../config";
import moment from "moment";

export const getJobs = (params) =>
  action("jobs", config.api_url, "rest/jobs", params);

const now = moment().unix();
export const getRecentlyCreatedJobs = (location) =>
  action("recentJobs", config.api_url, "rest/jobs", {
    "filter[created][0][>]": now - 3600,
    "filter[customer_location_nid][0]": location,
  });

export const getIncomingJobs = (params) =>
  action("incomingJobs", config.api_url, "rest/jobs", params);

export const getJobSnapshot = (nid, params) =>
  action("jobSnapshot", config.api_url, `node/${nid}`, params);

export const getJob = (name, params) =>
  action("division", config.api_url, `job/${name}`, params);

export const getDivisionJob = (nid, params) =>
  action("job", config.api_url, `node/${nid}`, params);

export const updateDivisionJob = (nid, params) =>
  action("job", config.api_url, `node/${nid}`, params, "patch");

export const updateJob = (nid, params, method) =>
  action("division", config.api_url, `node/${nid}`, params, method);

export const getJobNotes = (nid, params) =>
  action("jobNotes", config.api_url, `rest/job/notes/${nid}`, params);

export const updateJobNote = (cid, params) =>
  action("jobNotes", config.api_url, `comment/${cid}`, params);

export const getJobAccountings = (nid, params) =>
  action(
    "jobAccountings",
    config.api_url,
    `rest/job/accountings/${nid}`,
    params
  );

export const getJobAccounting = (nid) =>
  action("jobAccounting", config.api_url, `node/${nid}`);

export const getJobActivity = (nid, params) =>
  action("jobActivity", config.api_url, `rest/job/log/${nid}`, params);

// export const getJobAuditRequirements = (nid, params) =>
//   action(
//     "jobAuditRequirements",
//     config.api_url,
//     `rest/job/audit_requirements/${nid}`,
//     params
//   );

export const postDivision = (params) =>
  action("division", config.api_url, `node`, params, "post");

export const postJob = (params) =>
  action("division", config.api_url, `node`, params, "post");

export const getJobTasks = (nid, params) =>
  action("jobTasks", config.api_url, `rest/job/tasks/${nid}/1`, params);

export const postJobAccountings = (params) =>
  action("jobAccountings", config.api_url, `node`, params, "post");

export const patchJobAccountings = (nid, params) =>
  action("jobAccountings", config.api_url, `node/${nid}`, params, "patch");

export const getPHXClientReps = (params) =>
  action("phxClientReps", config.api_url, `rest/users/phx_client`, params);

export const getJobPdf = (nid, type = null, day = null, params) => {
  const path = ["rest/jobs/pdf", nid];
  if (type) {
    path.push(type);
  }

  if (day) {
    path.push(day);
  }

  return action("jobPdf", config.api_url, path.join("/"), params);
};

export const toggleHold = (params) =>
  action("toggleHold", config.api_url, `rest/job/hold`, params, "patch");

export const postAccountingToSage = (nid, type = null, params) => {
  const path = ["rest/sage/send", nid];
  if (type) {
    path.push(type);
  }

  return action(
    "accountingSage",
    config.api_url,
    path.join("/"),
    params,
    "patch"
  );
};

export const patchTimeEntry = (params) =>
  action(
    "timeEntriesUpdate",
    config.api_url,
    `rest/time-entry/clock-in-out`,
    params,
    "patch"
  );

export const getTimeEntries = (params) =>
  action("timeEntries", config.api_url, `rest/time-entries`, params);

export const declineJob = (params) =>
  action("declineJob", config.api_url, `rest/job/decline`, params, "patch");

export const postComplianceOverride = (params) =>
  action(
    "complianceOverride",
    config.api_url,
    `entity/override_log_entry`,
    params,
    "post"
  );

export const archiveJobComment = (params) =>
  action("temp", config.api_url, `rest/comment/archive`, params, "patch");

export const getJobDivisions = (entity_id, params) =>
  action(
    "jobDivisions",
    config.api_url,
    `entity/node/field_job_divisions/${entity_id}`,
    params
  );

export const getJobFiles = (entity_type, field_name, entity_id, params) =>
  action(
    "jobFiles",
    config.api_url,
    `entity/${entity_type}/${field_name}/${entity_id}`,
    params
  );

export const clearJobDivisions = () =>
  action("jobDivisions", "", "", {}, "clear");

export const getJobEstimates = (nid, params) =>
  action("jobEstimates", config.api_url, `rest/askaime/files/${nid}`, params);

export const getJobPhotos = (nid, params) =>
  action("jobPhotos", config.api_url, `rest/job/files/${nid}/true`, params);
