import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { DialogForm } from "common/Dialog";
import Button from "common/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import { getFormDataSelector, postFormShare } from "../formBuilderSlice";
import { getDivisionDataSelector } from "features/Job/jobSlice";
import TextFormField from "common/Fields/TextFormField";
import { setAlert } from "features/Alert/alertSlice";

const validation = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

const FormShare = () => {
  const dispatch = useDispatch();
  const form = useSelector(getFormDataSelector);
  const division = useSelector(getDivisionDataSelector);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    try {
      const resultAction = await dispatch(
        postFormShare({
          form_id: form.id,
          job_division_id: division.nid,
          email: data.email,
        })
      );
      unwrapResult(resultAction);
      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Shared form with ${data.email}`,
        })
      );
      setOpen(false);
    } catch (err) {
      console.error(err);
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: err?.message
            ? `Error sharing form: ${err.message}`
            : "Error sharing form",
        })
      );
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Button
      size="small"
      variant="outlined"
      disableElevation
      onClick={handleOpen}
      disabled={!Boolean(form.id)}
      style={{ marginRight: "1rem" }}>
      Share
    </Button>
    <DialogForm
      open={open}
      onClose={handleClose}
      title="Share Form"
      maxWidth="xs"
      initialValues={{
        email: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validation}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <div style={{ padding: "1rem 0" }}>
          <TextFormField
            name="email"
            label="Email"
            fullWidth
            size="small"
            variant="filled"
          />
        </div>
      )}
    </DialogForm>
  </>;
};

FormShare.propTypes = {};

export default FormShare;
