import axios from "axios";
import conf from "config";
import getQueryParams from "utility/getQueryParams";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  getArticles: async ({ config }) => {
    return instance.get(`/vendor-articles`, config);
  },
  getPosts: async ({ id, params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/vendor-articles/${id}/posts${queryParams}`, config);
  },
  getArticlesAdmin: async ({ config }) => {
    return instance.get(`/vendor-articles/admin`, config);
  },
  getArticle: async ({ id, config }) => {
    return instance.get(`/vendor-articles/${id}`, config);
  },
  addArticle: async ({ params, config }) => {
    return instance.post(`/vendor-articles`, params, config);
  },
  addReaction: async ({ id, params, config }) => {
    return instance.patch(`/vendor-articles/${id}/add-reaction`, params, config);
  },
  removeReaction: async ({ id, params, config }) => {
    return instance.patch(`/vendor-articles/${id}/remove-reaction`, params, config);
  },
  addAdmin: async ({ id, params, config }) => {
    return instance.patch(`/vendor-articles/${id}/add-admin`, params, config);
  },
  removeAdmin: async ({ id, params, config }) => {
    return instance.patch(
      `/vendor-articles/${id}/remove-admin`,
      params,
      config
    );
  },
  updateArticle: async ({ id, params, config }) => {
    return instance.patch(`/vendor-articles/${id}`, params, config);
  },
  deleteArticle: async ({ id, config }) => {
    return instance.delete(`/vendor-articles/${id}`, config);
  },
};

export default API;
