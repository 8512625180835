import { snackbarActions } from "../actions/snackbarActions";

const initialState = {
  msg: null,
  kind: null,
  show: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case snackbarActions.ENTER:
      return {
        ...state,
        msg: action.payload.msg,
        kind: action.payload.kind,
        show: true,
      };
    case snackbarActions.EXITING:
      return {
        ...state,
        show: false,
      };
    case snackbarActions.EXITED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
