import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatEstimate } from "../Estimates/utils";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "estimate";

export const fetchEstimate = createAsyncThunk(
  `${namespace}/fetchEstimate`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatEstimate(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchEstimateLineItems = createAsyncThunk(
  `${namespace}/fetchEstimateLineItems`,
  async ({ id, params }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/accounting/line-items/${id}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return {
        results: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreEstimateLineItems = createAsyncThunk(
  `${namespace}/fetchMoreEstimateLineItems`,
  async ({ id, params }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/accounting/line-items/${id}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return {
        results: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLineItem = createAsyncThunk(
  `${namespace}/deleteLineItem`,
  async ({ id }, { getState, rejectWithValue, dispatch }) => {
    try {
      await axios.delete(
        `${config.api_url}/entity/paragraph/${id}`,
        tokenConfig(getState)
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const lineItemsAdapter = createEntityAdapter({
  selectId: (result) => result.pid,
});

const estimateSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    data: {},
    lineItems: lineItemsAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchEstimate.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchEstimate.fulfilled](state, { payload: estimate }) {
      state.loading = false;
      state.data = estimate;
    },
    [fetchEstimate.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchEstimateLineItems.pending](state) {
      state.lineItems.loading = true;
      state.lineItems.error = null;
    },
    [fetchEstimateLineItems.fulfilled](
      state,
      { payload: { results, pagination } }
    ) {
      state.lineItems.loading = false;
      state.lineItems.pagination = pagination;
      lineItemsAdapter.setAll(state.lineItems, results);
    },
    [fetchEstimateLineItems.rejected](state, action) {
      state.lineItems.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.lineItems.error = action.payload.message;
        } else {
          state.lineItems.error = action.error.message;
        }
      }
    },
    [fetchMoreEstimateLineItems.pending](state) {
      state.lineItems.loading = true;
    },
    [fetchMoreEstimateLineItems.fulfilled](
      state,
      { payload: { results, pagination } }
    ) {
      state.lineItems.loading = false;
      state.lineItems.pagination = pagination;
      lineItemsAdapter.addMany(state.lineItems, results);
    },
    [fetchMoreEstimateLineItems.rejected](state, action) {
      state.lineItems.loading = false;
      state.lineItems.pagination = {
        count: 0,
        current_page: 0,
        total_pages: 0,
      };
      if (!action.meta.aborted) {
        if (action.payload) {
          state.lineItems.error = action.payload.message;
        } else {
          state.lineItems.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getEstimateDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.estimate.data
);

export const getEstimateLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.estimate.loading
);

export const getEstimateErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.estimate.error
);

export const getEstimateLineItemsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.estimate.lineItems.loading
);

export const getEstimateLineItemsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.estimate.lineItems.pagination
);

export const getEstimateLineItemsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.estimate.lineItems.error
);

export const lineItemsSelectors = lineItemsAdapter.getSelectors(
  (state) => state.estimate.lineItems
);

export default estimateSlice.reducer;
