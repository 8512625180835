import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../config";
import { tokenConfig } from "../../actions/authActions";
import { formatClientFields } from "./utils";
import { setAlert } from "features/Alert/alertSlice";

const namespace = "clients";

export const fetchClient = createAsyncThunk(
  `${namespace}/fetchClient`,
  async (nid, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${nid}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatClientFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchClient = createAsyncThunk(
  `${namespace}/patchClient`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/phoenix_client`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated Client",
        })
      );
      return formatClientFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating Client: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const clientsAdapter = createEntityAdapter({
  selectId: (client) => client.nid,
});

const clientsSlice = createSlice({
  name: namespace,
  initialState: clientsAdapter.getInitialState({
    loading: false,
    jobCreateClients: clientsAdapter.getInitialState({
      loading: false,
    }),
    client: {
      loading: false,
      data: {},
      error: null,
    },
  }),
  reducers: {
    setAllClients: clientsAdapter.setAll,
    setJobCreateClients: (state, { payload: jobCreateClients }) => {
      state.loading = false;
      state.error = null;
      clientsAdapter.setAll(state.jobCreateClients, jobCreateClients);
    },
  },
  extraReducers: {
    [fetchClient.pending](state) {
      state.client.error = null;
      state.client.loading = true;
    },
    [fetchClient.fulfilled](state, { payload: client }) {
      state.client.loading = false;
      state.client.data = client;
    },
    [fetchClient.rejected](state, action) {
      if (!action.meta.aborted) {
        state.client.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchClient.pending](state, action) {
      state.client.error = null;
      state.client.loading = true;
    },
    [patchClient.fulfilled](state, { meta, payload: client }) {
      const { id } = meta.arg;
      state.client.loading = false;
      state.client.data = client;
      clientsAdapter.updateOne(state, {
        id,
        changes: client,
      });
    },
    [patchClient.rejected](state, action) {
      const { client } = action.meta.arg;
      state.client.error = true;
      state.client.loading = false;
      state.client.data = client;
    },
  },
});

export const clientsSelectors = clientsAdapter.getSelectors(
  (state) => state.clients
);

export const jobCreateClientsSelectors = clientsAdapter.getSelectors(
  (state) => state.clients.jobCreateClients
);

export const { setAllClients, setJobCreateClients } = clientsSlice.actions;

const selectSelf = (state) => state;
export const getClientDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.clients.client.data
);

export const getClientLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.clients.client.loading
);


export default clientsSlice.reducer;
