const ENTER = "snackbar/ENTER";
const EXITING = "snackbar/EXITING";
const EXITED = "snackbar/EXITED";

export const snackbarActions = {
  ENTER,
  EXITING,
  EXITED,
};

export const snackbarEnter = (payload) => ({
  type: ENTER,
  payload,
});

export const snackbarExiting = () => ({
  type: EXITING,
});

export const snackbarExited = () => ({
  type: EXITED,
});
