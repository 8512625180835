import axios from "axios";
import { size } from "lodash";

export const modifyFiles = (existingFiles, files, field) => {
  let fileToUpload = {};
  for (let i = 0; i < files.length; i++) {
    const id = size(existingFiles) + i + 1;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files[i],
        progress: 0,
        field,
        cancelSource: source,
      },
    };
  }

  return fileToUpload;
};
