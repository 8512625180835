import React from "react";
import ContentLoader from "react-content-loader";
import constants from "../constants";

const DisplayGroupSkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={150}
    height={38}
    viewBox="0 0 150 38"
    foregroundColor={constants.colorGray}
    backgroundColor={constants.colorGrayLight}
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="100" height="17" />
    <rect x="0" y="24" rx="3" ry="3" width="150" height="13" />
  </ContentLoader>
);

export default DisplayGroupSkeleton;
