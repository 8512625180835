import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
// import { tokenConfig } from "actions/authActions";

// import { tokenConfig } from "../../actions/authActions";
import config from "config";

const namespace = "imageinteraction";

export const fetchImageInteractionLandingPage = createAsyncThunk(
  `${namespace}/fetchImageInteractionLandingPage`,
  async ({hash}, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/files/enhancement-landing-page/${hash}`,
        {
          // ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postImageInteractionLandingPageSubmission = createAsyncThunk(
  `${namespace}/postImageInteractionLandingPageSubmission`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/form-builder/form-shares/submit`,
        params,
        // tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const imageInteractionAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const imageInteractionSlice = createSlice({
  name: namespace,
  initialState: {
    landing_page: imageInteractionAdapter.getInitialState({
      loading: false,
      error: null,
    }),
  },
  reducers: {
  },
  extraReducers: {
    [fetchImageInteractionLandingPage.pending](state) {
      state.landing_page.loading = true;
      state.landing_page.error = null;
      state.landing_page.data = {};
    },
    [fetchImageInteractionLandingPage.fulfilled](state, { payload: landingPage }) {
      state.landing_page.loading = false;
      state.landing_page.data = landingPage;
    },
    [fetchImageInteractionLandingPage.rejected](state, action) {
      if (!action.meta.aborted) {
        state.landing_page.loading = false;
        if (action.payload) {
          state.landing_page.error = action.payload.message;
        } else {
          state.landing_page.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;

export const getImageInteractionLandingDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.imageinteraction.landing_page.data
);

export const getImageInteractionLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.imageinteraction.landing_page.loading
);

export const getImageInteractionErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.imageinteraction.landing_page.error
);

export default imageInteractionSlice.reducer;
