import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

const Container = styled.div`
  width: 100%;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--color-gray);
`;

const Icon = styled.div`
  font-size: 2rem;
  opacity: 0.5;
  margin-bottom: 1rem;
`;

const Empty = ({ children, message, action, ...rest }) => {
  return (
    <Container {...rest}>
      {children && <Icon>{children}</Icon>}
      <Typography variant="body1" gutterBottom>
        {message}
      </Typography>
      {action}
    </Container>
  );
};

Empty.propTypes = {};

export default Empty;
