import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { setAllPosts } from "features/Posts/postsSlice";
import eventsAPI from "./eventsAPI";
import {eventIsCurrent} from "./utils";
import axios from "axios";
import config from "config";
import { tokenConfig } from "actions/authActions";
import { parseHubEnvParams } from "features/Hub/hubSlice.js";

const namespace = "events";

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      // const clientNid = getState().app?.selectedClient?.client?.nid;
      const params = {};
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.getEvents({
        params: params/*{
          client_nid: clientNid,
        }*/,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserEvents = createAsyncThunk(
  "events/fetchUserEvents",
  async ({ id }, { rejectWithValue, getState, dispatch }) => {
    try {
      // const clientNid = getState().app?.selectedClient?.client?.nid;
      const params = {};
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.getUserEvents({
        id,
        params: params/*{
          client_nid: clientNid,
        }*/,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchEventPosts = createAsyncThunk(
  "events/fetchEventPosts",
  async ({ id, params }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.getPosts({
        id,
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      dispatch(setAllPosts(response.data));

      return response.data;
    } catch (err) {
      dispatch(setAllPosts({ data: [] }));
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUpcomingEvents = createAsyncThunk(
  "events/fetchUpcomingEvents",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      // const clientNid = getState().app?.selectedClient?.client?.nid;
      const params = {};
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.getUpcoming({
        params: params/*{
          client_nid: clientNid,
        }*/,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddReaction = createAsyncThunk(
  "events/patchAddReaction",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.addReaction({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveReaction = createAsyncThunk(
  "events/patchRemoveReaction",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.removeReaction({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInviteAttendee = createAsyncThunk(
  "events/patchInviteAttendee",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.inviteAttendee({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddAttendeeConfirmation = createAsyncThunk(
  "events/patchAddAttendeeConfirmation",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.addAttendeeConfirmation({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);
export const patchRemoveAttendeeConfirmation = createAsyncThunk(
  "events/patchRemoveAttendeeConfirmation",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.removeAttendeeConfirmation({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveAttendee = createAsyncThunk(
  "events/patchRemoveAttendee",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.removeAttendee({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddAttendeeInterest = createAsyncThunk(
  "events/patchAddAttendeeInterest",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.addInterest({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveAttendeeInterest = createAsyncThunk(
  "events/patchRemoveAttendeeInterest",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.removeInterest({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddAdmin = createAsyncThunk(
  "events/patchAddAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.addAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveAdmin = createAsyncThunk(
  "events/patchRemoveAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.removeAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAdminRemoveAttendee = createAsyncThunk(
  "events/patchAdminRemoveAttendee",
  async ({ id, attendeeID, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.adminRemoveAttendee({
        id,
        attendeeID,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchEvent = createAsyncThunk(
  "events/patchEvent",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.updateEvent({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchUploadEventAttachment = createAsyncThunk(
  "events/patchUploadEventAttachment",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.uploadAttachment({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadAttachment = createAsyncThunk(
  "events/downloadAttachment",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.downloadAttachment({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      dispatch(setAllPosts(response.data));

      return response.data;
    } catch (err) {
      dispatch(setAllPosts({ data: [] }));
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEvent = createAsyncThunk(
  "events/postEvent",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.addEvent({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEventNotification = createAsyncThunk(
  `${namespace}/postEventNotification`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/off-site/send-notification`,
        {
          id: id,
          type: "event",
          ...params,
        },
        tokenConfig(getState)
      );


      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchEvent = createAsyncThunk(
  "events/fetchEvent",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.getEvent({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await eventsAPI.deleteEvent({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  "events/deleteAttachment",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      await eventsAPI.deleteAttachment({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return id;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const eventsAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const eventsSlice = createSlice({
  name: namespace,
  initialState: eventsAdapter.getInitialState({
    loading: false,
    error: null,
    can_create: false,
    event: {
      loading: false,
      error: null,
      data: {},
    },
    upcoming: eventsAdapter.getInitialState({
      loading: false,
      error: null,
      private: eventsAdapter.getInitialState({
        loading: false,
        error: null,
        data: [],
      }),
      public: eventsAdapter.getInitialState({
        loading: false,
        error: null,
        data: [],
      }),
    }),
    attachments: eventsAdapter.getInitialState({}),
  }),
  reducers: {
    resetEvents: (state, action) => {
      state.loading = true;
      state.error = null;
      eventsAdapter.removeAll(state);
    },
    resetEvent: (state, action) => {
      state.event.loading = true;
      state.event.error = null;
      state.event.data = {};
    },
    updateEvent: (state, { payload: event }) => {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      eventsAdapter.updateOne(state.upcoming.private, { id: event.id, changes: event });
      eventsAdapter.updateOne(state.upcoming.public, { id: event.id, changes: event });
      if(state.event.data.id === event.id){
        state.event.data = {
          ...state.event.data,
          ...event,
        };
      }
    },
  },
  extraReducers: {
    [fetchEvents.pending](state, action) {
      state.loading = true;
      state.error = null;
      state.can_create = false;
      eventsAdapter.removeAll(state);
    },
    [fetchEvents.fulfilled](state, { payload: events }) {
      state.loading = false;
      state.error = null;
      state.can_create = events.can_create;
      eventsAdapter.setAll(state, events.events);
    },
    [fetchEvents.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postEvent.pending](state, action) {
      state.creating = true;
    },
    [postEvent.fulfilled](state, { payload: event }) {
      state.creating = null;
      state.error = null;
      eventsAdapter.addOne(state, event);
      if(eventIsCurrent(event)) {
        if(event.is_private) {
          eventsAdapter.addOne(state.upcoming.private, event);
        }
        else{
          eventsAdapter.addOne(state.upcoming.public, event);
        }
      }
    },
    [postEventNotification.pending](state, action) {
      state.sending = true;
    },
    [postEventNotification.fulfilled](state, { payload: event }) {
      state.sending = null;
      state.error = null;
    },
    [fetchUserEvents.pending](state, action) {
      state.loading = true;
      state.error = null;
      eventsAdapter.removeAll(state);
    },
    [fetchUserEvents.fulfilled](state, { payload: events }) {
      state.loading = false;
      state.error = null;
      eventsAdapter.setAll(state, events);
    },
    [fetchUserEvents.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchAddReaction.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
    },
    [patchAddReaction.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchRemoveReaction.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
    },
    [patchRemoveReaction.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchEvent.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      if(eventIsCurrent(event)) {
        if(event.is_private) {
          if(state.upcoming.private.ids.includes(event.id)) {
            eventsAdapter.updateOne(state.upcoming.private, { id: event.id, changes: event });
          }
          else{
            eventsAdapter.addOne(state.upcoming.private, event);
          }
          eventsAdapter.removeOne(state.upcoming.public, event.id);
        }
        else{
          if(state.upcoming.public.ids.includes(event.id)) {
            eventsAdapter.updateOne(state.upcoming.public, { id: event.id, changes: event });
          }
          else{
            eventsAdapter.addOne(state.upcoming.public, event);
          }
          eventsAdapter.removeOne(state.upcoming.private, event.id);
        }
      }
      else{
        eventsAdapter.removeOne(state.upcoming.public, event.id);
        eventsAdapter.removeOne(state.upcoming.private, event.id);
      }
      state.event.data = event;
      state.event.error = null;
    },
    [patchEvent.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchAddAdmin.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchAddAdmin.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchRemoveAdmin.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchRemoveAdmin.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchAddAttendeeConfirmation.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchAddAttendeeConfirmation.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchRemoveAttendeeConfirmation.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchRemoveAttendeeConfirmation.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchRemoveAttendee.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchRemoveAttendee.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchAddAttendeeInterest.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchAddAttendeeInterest.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchRemoveAttendeeInterest.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchRemoveAttendeeInterest.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchInviteAttendee.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchInviteAttendee.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [patchAdminRemoveAttendee.fulfilled](state, { payload: event }) {
      eventsAdapter.updateOne(state, { id: event.id, changes: event });
      state.event.data = event;
      state.event.error = null;
    },
    [patchAdminRemoveAttendee.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [fetchUpcomingEvents.pending](state, action) {
      state.upcoming.loading = true;
      state.upcoming.error = null;
      eventsAdapter.removeAll(state.upcoming);
    },
    [fetchUpcomingEvents.fulfilled](state, { payload: events }) {
      state.upcoming.loading = false;
      state.upcoming.error = null;
      eventsAdapter.setAll(state.upcoming.private, events.private);
      eventsAdapter.setAll(state.upcoming.public, events.public);
    },
    [fetchUpcomingEvents.rejected](state, action) {
      state.upcoming.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.upcoming.error = action.payload.message;
        } else {
          state.upcoming.error = action.error.message;
        }
      }
    },
    [fetchEvent.pending](state, { payload: event }) {
      state.event.loading = true;
      state.event.error = null;
      state.event.data = {};
      eventsAdapter.removeAll(state);
    },
    [fetchEvent.fulfilled](state, { payload: event }) {
      state.event.loading = false;
      state.event.error = null;
      state.event.data = event;
      eventsAdapter.updateOne(state.upcoming.private, { id: event.id, changes: event });
      eventsAdapter.updateOne(state.upcoming.public, { id: event.id, changes: event });
      eventsAdapter.setAll(state, event.posts);
      eventsAdapter.setAll(state.attachments, event.attachments);
    },
    [fetchEvent.rejected](state, action) {
      state.event.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [deleteEvent.fulfilled](state, { payload: event }) {
      eventsAdapter.removeOne(state, event.id);
      eventsAdapter.removeOne(state.upcoming.public, event.id);
      eventsAdapter.removeOne(state.upcoming.private, event.id);
    },
    [patchUploadEventAttachment.fulfilled](state, { payload: event }) {
      eventsAdapter.setAll(state.attachments, event.attachments);
      state.event.error = null;
    },
    [patchUploadEventAttachment.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.event.error = action.payload.message;
        } else {
          state.event.error = action.error.message;
        }
      }
    },
    [deleteAttachment.fulfilled](state, { payload: id }) {
      eventsAdapter.removeOne(state.attachments, id);
    },
    [fetchEventPosts.fulfilled](state, action) {
      if (!action.meta?.page || action.meta.page === 1) {
        state.event.data = {...state.event.data, 'unread_posts_count': 0};
        eventsAdapter.updateOne(state.upcoming.private, { id: state.event.data.id, changes: state.event.data });
        eventsAdapter.updateOne(state.upcoming.public, { id: state.event.data.id, changes: state.event.data });
      }
    },
  },
});

export const eventsSelectors = eventsAdapter.getSelectors(
  (state) => state.events
);

export const attachmentsSelectors = eventsAdapter.getSelectors(
  (state) => state.events.attachments
);

export const upcomingPrivateEventsSelectors = eventsAdapter.getSelectors(
  (state) => state.events.upcoming.private
);
export const upcomingPublicEventsSelectors = eventsAdapter.getSelectors(
  (state) => state.events.upcoming.public
);

export const { resetEvents, resetEvent, updateEvent } = eventsSlice.actions;

export default eventsSlice.reducer;
