import React from "react";
import { Popper, Grid, Typography } from "@mui/material";

import Button from "common/Button";
import { TextFormField } from "common/Fields";
import AutocompleteFormField from "components/AutocompleteFormField";
import { DateFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { times } from "utility";

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 150 }} placement="bottom-start" />;
};

const Resident = ({ value, handlePrev, isSubmitting, values }) => {
  return (
    <div>
      <Typography style={{ marginTop: "2rem" }} variant="h6">
        Details
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        This information pertains to the Job Division. Information here is
        important to tracking and performing work for the job.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xxs={12}>
          <DateFormField
            fullWidth
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            id="field_loss_date"
            label="Loss Date"
            name="field_loss_date"
            inputVariant="outlined"
            size="small"
            KeyboardButtonProps={{
              "aria-label": "change loss date",
            }}
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography variant="body1" color="textSecondary">
            ETA
          </Typography>
        </Grid>
        <Grid item xxs={12}>
          <Grid container spacing={3}>
            <Grid item xxs={4}>
              <DateFormField
                fullWidth
                disableToolbar
                variant="inline"
                format="MM/DD/YYYY"
                id="field_jd_eta_range.date"
                label="Scheduled Date"
                name="field_jd_eta_range.date"
                inputVariant="outlined"
                size="small"
                KeyboardButtonProps={{
                  "aria-label": "change loss date",
                }}
              />
            </Grid>
            <Grid item xxs={4}>
              <AutocompleteFormField
                name="field_jd_eta_range.value"
                label="From"
                disabled={isSubmitting}
                disableClearable
                options={times}
                variant="outlined"
                size="small"
                PopperComponent={CustomPopper}
              />
            </Grid>
            <Grid item xxs={4}>
              <AutocompleteFormField
                name="field_jd_eta_range.end_value"
                variant="outlined"
                size="small"
                label="To"
                disabled={isSubmitting}
                disableClearable
                PopperComponent={CustomPopper}
                options={times}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xxs={12}>
          <SelectFormField
            fullWidth
            name="field_cause_select"
            label="Cause"
            size="small"
            variant="outlined"
            required
            disabled={isSubmitting}
            options={[
              { value: "freeze", label: "Freeze" },
              { value: "sewer_backup", label: "Sewer Backup" },
              { value: "plumbing", label: "Plumbing" },
              { value: "mold", label: "Mold" },
              { value: "storm", label: "Storm" },
              { value: "at_fault", label: "At Fault" },
              { value: "fire", label: "Fire" },
              { value: "pre_existing", label: "Pre-existing" },
              { value: "other", label: "Other" },
            ]}
          />
        </Grid>
        {values.field_cause_select === "other" && (
          <Grid item xxs={12}>
            <TextFormField
              fullWidth
              name="field_cause"
              label="Cause (Other)"
              variant="outlined"
              size="small"
              required
              disabled={isSubmitting}
              labelwidth={70}
            />
          </Grid>
        )}
        <Grid item xxs={6} md={3}>
          <TextFormField
            fullWidth
            name="field_run_time_text"
            label="Run Time"
            disabled={isSubmitting}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={6} md={3}>
          <SelectFormField
            fullWidth
            name="field_run_time_select"
            label="Duration"
            size="small"
            variant="outlined"
            disabled={isSubmitting}
            options={[
              { value: "Minutes", label: "Minutes" },
              { value: "Hours", label: "Hours" },
              { value: "Days", label: "Days" },
              { value: "Weeks", label: "Weeks" },
            ]}
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_mf_effected_level_qty"
            label="Effected Levels"
            disabled={isSubmitting}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12}>
          <Grid container spacing={3}>
            <Grid item xxs={values.field_mf_hw_wet ? 6 : 12}>
              <CheckboxFormField name="field_mf_hw_wet" label="Hardwood Wet" />
            </Grid>
            {values.field_mf_hw_wet && (
              <Grid item xxs={6}>
                <TextFormField
                  fullWidth
                  name="field_mf_hw_wet_sqft"
                  label="Hardwood Wet Sqft"
                  disabled={isSubmitting}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_details"
            label="Details"
            disabled={isSubmitting}
            autoComplete="off"
            variant="outlined"
            size="small"
            multiline
          />
        </Grid>
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Resident.propTypes = {};

export default Resident;
