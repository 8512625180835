import { toArray } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { size } from "lodash";
import { Chip } from "@mui/material";

export const formatCustInfoItems = (items) => {
  const data = [];

  items.forEach((item) => {
    const subCategories = toArray(item.children);
    data.push({
      category: item.name,
      tid: item.tid,
      fields: subCategories.map((sub) => ({
        name: sub.name,
        tid: sub.tid,
        parentTid: item.tid,
        parentName: item.name,
        value: "",
        field: sub.toggle_field_name,
      })),
    });
  });

  return data;
};

export const filterNationalContracts = (allNationalContracts, phxClientNids) => {
  if(size(allNationalContracts) === 0) return [];
  if(typeof phxClientNids !== "object") phxClientNids = [String(phxClientNids)];
  return allNationalContracts.reduce((arr, term) => {
    if(term?.phx_client_nids){
      const _phxClientNids = term.phx_client_nids.split(",");
      if(_phxClientNids.filter((nid) => phxClientNids.includes(String(nid))).length > 0) {
        arr.push({
          tid: term.tid,
          name: term.name,
        });
      }
    }
    return arr;
  }, []);
}

export const TaxonomyChip = ({ selectors, tid }) => {
  const taxonomy = useSelector((state) => selectors.selectById(state, tid));

  return (
    <Chip key={tid} label={taxonomy?.name} size="small" style={{ margin: 2 }} />
  );
};
