import React from "react";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import { Portal, Slide } from "@mui/material";
import { toArray, size } from "lodash";

import Card from "common/Card";
import BatchItem from "./BatchItem";
import { Wrapper, Container } from "./Styled";

const Batch = ({ batches }) => {
  return (
    <Portal container={document.body}>
      <Slide
        direction="up"
        in={size(toArray(batches)) > 0}
        mountOnEnter
        unmountOnExit
      >
        <Wrapper>
          <Card>
            <SimpleBar style={{ maxHeight: "70vh" }}>
              <Container>
                {toArray(batches).map((batch) => (
                  <BatchItem key={batch.id} data={batch} />
                ))}
              </Container>
            </SimpleBar>
          </Card>
        </Wrapper>
      </Slide>
    </Portal>
  );
};

Batch.propTypes = {};

const mapStateToProps = (state) => ({
  batches: state.app.batchExport.batchProgress,
});

export default connect(mapStateToProps)(Batch);
