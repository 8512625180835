import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationGroup from "components/Notification";

import Alert from "common/Alert";
import constants from "components/constants";

import { toastSelectors, setToast, addToast, removeToast } from "./toastSlice";
import {size} from "lodash";
import styled from "styled-components";

import {
  fetchUnreadNotifications,
  getUnreadNotificationDataSelector,
  clearUnreadNotifications,
} from "components/Notification/notificationSlice";
import { hubEnvironmentSelectors } from "features/Hub/hubSlice.js";

const StyledToast = styled.div`
  position: fixed;
  z-index: 1000;
  height: auto;
  width: 350px;
  max-height: 100%;
  max-width: 90%;
  transition: top 300ms ease 0ms,right 300ms ease 0ms,bottom 300ms ease 0ms,left 300ms ease 0ms,max-width 300ms ease 0ms;
  pointer-events: none;
  top: calc(var(--page-header-height) - 1.5rem);
  right: 1rem;

  @media (max-width: ${constants.breakpoints.xs}) {
    font-size: 0.875rem;
  }

  > * { pointer-events: auto; }

  .more{
    text-align: right;

    .button{
      cursor: pointer;
      display: inline-block;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      color: var(--color-white);
      background: var(--color-gray-darkest);
      box-shadow: var(--drop-shadow);
    }
  }
`;

const Toast = ({continueAction}) => {
  const dispatch = useDispatch();
  const toast = useSelector(toastSelectors.selectAll);
  const user = useSelector((state) => state.auth.user.data);
  const helixAPICurrent = useSelector((state) => state.user.helixAPICurrent.data);
  const webNotificationsEnabled = useSelector((state) => state.auth.user.data?.field_user_settings?.enable_web_notifications);
  const notifications = useSelector(getUnreadNotificationDataSelector);
  const envs = useSelector(hubEnvironmentSelectors.selectAll);

//  Subscribe to pusher notifications, and get any unread notifications when webNotificationsEnabled is true
  useEffect(() => {
    if (size(helixAPICurrent) > 0) {
      if(webNotificationsEnabled){
      //  Get unread notifications
        if(helixAPICurrent?.notification_unread_count && Number(helixAPICurrent.notification_unread_count) > 0){
          dispatch(fetchUnreadNotifications());
        }

        window.Echo.join(`notifications.${user.uid}`).listen("NotifyUser", (e) => {
          dispatch(
            addToast({
              show: true,
              kind: "warning", // "warning" persists message
              message: e,
              formatter: "notification",
            })
          );
        });
        // user.field_clients.forEach((client) => {
        //   window.Echo.join(`notifications.client.${client.nid}`).listen("NotifyClientUsers", (e) => {
        //     dispatch(
        //       addToast({
        //         show: true,
        //         kind: "warning", // "warning" persists message
        //         message: e,
        //         formatter: "notification",
        //       })
        //     );
        //   });
        // });
        envs.forEach((env) => {
          window.Echo.join(`notifications.environment.${env.nid}`).listen("NotifyEnvironmentUsers", (e) => {
            dispatch(
              addToast({
                show: true,
                kind: "warning", // "warning" persists message
                message: e,
                formatter: "notification",
              })
            );
          });
        });

        return () => {
          window.Echo.leave(`notifications.${user.uid}`);
          // user.field_clients.forEach((client) => {
          //   window.Echo.leave(`notifications.client.${client.nid}`);
          // });
          envs.forEach((env) => {
            window.Echo.leave(`notifications.environment.${env.nid}`);
          });
        };
      }
      else {
      //  TODO - Unsubscribe from notifications if freshly disabled

        if(size(notifications) > 0){
          notifications.forEach((grouping) => {
            dispatch(removeToast({
              parent_id: grouping.parent_id,
              parent_type: grouping.parent_type,
            }));
          });
          dispatch(clearUnreadNotifications());
        }
      }
    }
  }, [helixAPICurrent?.notification_unread_count, webNotificationsEnabled, dispatch]); //  eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (size(notifications)) {
      const data = [];
      notifications.forEach((grouping) => {
        data.push({
          show: true,
          kind: "warning",
          formatter: "notification",
          grouping_id: grouping.grouping_id,
          parent_id: grouping.parent_id,
          parent_type: grouping.parent_type,
          notifications: grouping.last_notifications,
        });
      });
      dispatch(
        setToast(data)
      );
    }
  }, [notifications, dispatch]);

  const handleClose = (params) => {
    dispatch(removeToast(params));
  };

  let index = 0;
  return (
    <StyledToast>
      {toast.map((toastMsg, i) => {
        index++;
        switch(toastMsg?.formatter) {
          case 'notification':
            return <NotificationGroup
              key={toastMsg?.grouping_id ? toastMsg.grouping_id : i}
              index={(index - 1)}
              messages={toastMsg?.notifications}
              onClose={handleClose}
            />;

          default:
            return <Alert
                key={i}
                kind={toastMsg.kind}
              >
                {toast.notifications[0]}
              </Alert>;
        }
      }).slice(0, 3)}
      {size(toast) > 3 && (
        <div className="more"><div className="button" onClick={() => continueAction(true)}>More...</div></div>
      )}
    </StyledToast>
  );
};

Toast.propTypes = {};

export default Toast;
