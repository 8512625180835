import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatLocationFields } from "../Location/utils";
import { setAlert } from "features/Alert/alertSlice";
import { formatField } from "../../utility";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "divisions";

export const fetchJobs = createAsyncThunk(
  `${namespace}/fetchJobs`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/jobs${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { jobs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreJobs = createAsyncThunk(
  `${namespace}/fetchMoreJobs`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/jobs${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { jobs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberJobs = createAsyncThunk(
  `${namespace}/fetchMemberJobs`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/jobs/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { jobs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMyActiveJobs = createAsyncThunk(
  `${namespace}/fetchMyActiveJobs`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/dashboard/active_jobs${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { jobs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerJobs = createAsyncThunk(
  `${namespace}/fetchCustomerJobs`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/jobs/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { jobs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLocationJobs = createAsyncThunk(
  `${namespace}/fetchLocationJobs`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/location/jobs/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { jobs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreCustomerJobs = createAsyncThunk(
  `${namespace}/fetchMoreCustomerJobs`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/jobs/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { jobs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchLocation = createAsyncThunk(
  `${namespace}/patchLocation`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/customer_location`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated location",
        })
      );
      return formatLocationFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating location: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const divisionsAdapter = createEntityAdapter({
  selectId: (division) => division.division_nid,
});

const divisionsSlice = createSlice({
  name: namespace,
  initialState: divisionsAdapter.getInitialState({
    loading: false,
    data: [],
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    error: null,
  }),
  reducers: {
    setAllDivisions: divisionsAdapter.setAll,
    setManyDivisions: divisionsAdapter.removeOne,
    setOneDivision: divisionsAdapter.addMany,
    addOneDivision: divisionsAdapter.addOne,
    updateOneDivision: (state, { payload }) => {
      divisionsAdapter.updateOne(state, payload);
    },
  },
  extraReducers: {
    [fetchJobs.pending](state) {
      state.loading = true;
    },
    [fetchJobs.fulfilled](state, { payload: { jobs, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      divisionsAdapter.setAll(state, jobs);
    },
    [fetchJobs.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMemberJobs.pending](state) {
      state.loading = true;
    },
    [fetchMemberJobs.fulfilled](state, { payload: { jobs, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      divisionsAdapter.setAll(state, jobs);
    },
    [fetchMemberJobs.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMyActiveJobs.pending](state) {
      state.loading = true;
    },
    [fetchMyActiveJobs.fulfilled](state, { payload: { jobs, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      divisionsAdapter.setAll(state, jobs);
    },
    [fetchMyActiveJobs.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreJobs.fulfilled](state, { payload: { jobs, pagination } }) {
      state.pagination = pagination;
      divisionsAdapter.addMany(state, jobs);
    },
    [fetchMoreJobs.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerJobs.pending](state) {
      state.loading = true;
    },
    [fetchCustomerJobs.fulfilled](state, { payload: { jobs, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      divisionsAdapter.setAll(state, jobs);
    },
    [fetchCustomerJobs.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchLocationJobs.pending](state) {
      state.loading = true;
    },
    [fetchLocationJobs.fulfilled](state, { payload: { jobs, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      divisionsAdapter.setAll(state, jobs);
    },
    [fetchLocationJobs.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreCustomerJobs.fulfilled](
      state,
      { payload: { jobs, pagination } }
    ) {
      state.pagination = pagination;
      divisionsAdapter.addMany(state, jobs);
    },
    [fetchMoreCustomerJobs.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchLocation.pending](state, action) {
      const { params, division } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      divisionsAdapter.updateOne(state, {
        id: division.nid,
        changes: { customer_location_name: tempParams.title },
      });
    },
    [patchLocation.fulfilled](state, { meta, payload: location }) {
      const { division } = meta.arg;

      divisionsAdapter.updateOne(state, {
        id: division.nid,
        changes: {
          customer_location_name: location.title,
          _paths: { ...division._paths, customer_location: location.self },
        },
      });
    },
    [patchLocation.rejected](state, action) {
      const { location, division } = action.meta.arg;

      divisionsAdapter.updateOne(state, {
        id: division.nid,
        changes: { customer_location_name: location.title },
      });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

export const divisionsSelectors = divisionsAdapter.getSelectors(
  (state) => state.divisions
);

// Custom selectors
const selectSelf = (state) => state;
export const getDivisionsDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.divisions.data
);

export const getDivisionsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.divisions.loading
);

export const getDivisionsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.divisions.error
);

export const getDivisionsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.divisions.pagination
);

export const {
  setAllDivisions,
  setManyDivisions,
  setOneDivision,
  addOneDivision,
  updateOneDivision,
} = divisionsSlice.actions;

export default divisionsSlice.reducer;
