import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdNotifications } from "react-icons/md";
import { Tooltip, IconButton, Badge, Typography } from "@mui/material";
// import { BiMessageSquareDetail } from "react-icons/bi";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import NotificationGroup from "components/Notification";
import Tabs, { Tab } from "common/Tabs";

import Drawer from "common/Drawer";
import {
  fetchRecentAcitivty,
  recentActivitySelectors,
  getRecentActivityLoadingSelector,
} from "./recentAcitivitySlice";
import {
  fetchNotifications,
  getNotificationDataSelector,
  getNotificationLoadingSelector,
  deleteNotifications,
  getNotificationDeletingSelector,
} from "components/Notification/notificationSlice";
import { removeToast } from "features/Toast/toastSlice";
import { DefaultLoader } from "common/Loader";
import { useLocalStorage } from "hooks";

const emptyBox = `${process.env.PUBLIC_URL}/images/empty-box.png`;

const Item = styled(Link)`
  display: block;
  padding: 0 1.25rem 1.25rem;
  border-bottom: 1px solid var(--color-gray-medium);
  text-decoration: none;

  & + &{
    padding-top: 1.25em;
  }

  &:hover {
    background: var(--color-gray-light);
  }
`;

const getDiff = (d) => {
  if (Array.isArray(d)) {
    return d.map((p) => <Typography color="textPrimary">{p}</Typography>);
  } else {
    return <Typography color="textPrimary">{d}</Typography>;
  }
};

const RecentActivity = (props) => {
  const drawerRef = useRef();
  const lastLogActivity = useSelector((state) => state.auth.user?.data?._processed?.last_log_activity);
  const notificationCount = useSelector((state) => Number(state.user.helixAPICurrent.data?.notification_count));
  const hasActivity = useSelector((state) => state.auth.user?.data?._processed?.has_activity);
  const [savedLastLogActivity, setSavedLastLogActivity] = useLocalStorage('last_log_activity', 0); // eslint-disable-line no-unused-vars
  const [unreadActivity, setUnreadActivity] = useState(false);
  const [showIndicator, setShowIndicator] = useState(false);
  const [toggle, setToggle] = useState(0);

  useEffect(() => {
    const activity = hasActivity && lastLogActivity > Number(savedLastLogActivity);
    setUnreadActivity(activity)
    setShowIndicator(notificationCount || activity);
  }, [notificationCount, lastLogActivity, hasActivity, unreadActivity]); // eslint-disable-line

  let icon = <MdNotifications />;
  if(showIndicator){
    icon =  <Badge
                  color="tertiary"
                  overlap="circular"
                  variant="dot"
                >
                  {icon}
                </Badge>;
  }

  let notificationLabel = "Notifications";
  if(notificationCount){
    notificationLabel =  <Badge
                  color="tertiary"
                  overlap="circular"
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    [`& > .MuiBadge-badge`]: {
                      left: `calc(100% - 0.25rem)`,
                    }
                  }}
                >
                  {notificationLabel}
                </Badge>;
  }

  let activityLabel = "Activity";
  if(unreadActivity){
    activityLabel =  <Badge
                  color="tertiary"
                  overlap="circular"
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    [`& > .MuiBadge-badge`]: {
                      left: `calc(100% - 0.25rem)`,
                    }
                  }}
                >
                  {activityLabel}
                </Badge>;
  }

  const closeDrawer = () => {
    drawerRef.current.close();
  }


  let isEmpty = false;
  if(toggle === 0 && !notificationCount){
    isEmpty = true;
  }
  else if(toggle === 1 && !unreadActivity){
    isEmpty = true;
  }

  return (
    <Drawer
      ref={drawerRef}
      anchor="right"
      primary="Notifications & Activity"
      // secondary="Recent Activity"
      icon={icon}
      remoteOpen={props?.open}
      onClose={props?.onClose}
      action={
        <Tooltip title="Notifications & Activity">
          <IconButton style={{ color: "white" }} size="large">
            {icon}
          </IconButton>
        </Tooltip>
      }
      mainProps={{className:`bg-gray-light ${isEmpty ? 'flex flex-col' : ''}`}}
    >
      <Tabs
        value={(toggle ? toggle : 0)}
        handleChange={setToggle}
        style={{
          display: "flex",
          padding: "0",
          border: "none",
        }}
      >
        <Tab
          index={0}
          label={notificationLabel}
          style={{
            flex: "0 1 50%",
            textAlign: "center",
            border: "none",
          }}
        />
        <Tab
          index={1}
          label={activityLabel}
          onClick={() => {
            setUnreadActivity(false);
            setSavedLastLogActivity(moment().unix());
          }}
          style={{
            flex: "0 1 50%",
            textAlign: "center",
            border: "none",
          }}
        />
      </Tabs>
      {toggle === 0 ? (
        <div className={isEmpty ? 'flex flex-col flex-[1_1_auto]' : ''}>
          <Typography variant="h6" className="p-2">Notifications</Typography>
          <Notifications onNavigate={closeDrawer} />
        </div>
      ) : (
        <div className={isEmpty ? 'flex flex-col flex-[1_1_auto]' : ''}>
          <Typography variant="h6" className="p-2">Activity</Typography>
          <Activity hasActivity={hasActivity} />
        </div>
      )}
    </Drawer>
  );
};

const Notifications = ({onNavigate}) => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationDataSelector);
  const loading = useSelector(getNotificationLoadingSelector);
  const deleting = useSelector(getNotificationDeletingSelector);
  // const [deleting, setDeleting] = useState(null);

  useEffect(() => {
    // if(notificationCount){
      const promise = dispatch(fetchNotifications());

      return () => {
        promise.abort();
      };
    // }
  }, [/*notificationCount,*/ dispatch]);

  const handleNotificationDelete = (params) => {
  // //  Prevent double calls from the delete and close calllbacks
  //   const delIndex = params?.uuid + '-' + params?.parent_id + '-' + params?.parent_type;
  //   if(!deleting || deleting !== delIndex){
  //     setDeleting(delIndex);
      dispatch(deleteNotifications(params));
      dispatch(removeToast(params));
    // }
  };

  let index = 0;
  return (
    (loading && !deleting) ? (
      <DefaultLoader />
    ) : (
      notifications.length > 0 ? (
        notifications.map((notification) => {
          let key = `${notification.parent_id}-`
          key += notification.parent_type.replace(/\\/g, '-').toLowerCase();
          index++;
          return (<div className="pl-4 pr-6" key={key}>
            <NotificationGroup
              index={(index - 1)}
              messages={notification.last_notifications}
              // onClose={handleNotificationDelete}
              onNavigate={onNavigate}
              onDelete={handleNotificationDelete}
              isLastNotificationGroup={notifications.length === 1}
            />
          </div>);
        })
      ) : (
        <Typography variant="body2" className="px-3 text-center flex-[1_1_auto] flex justify-center">
          <div className="self-center">
            <img src={emptyBox} alt="Empty Box Icon" className="inline-block w-[100px] h-auto" /><br />
            No New Notifications
          </div>
        </Typography>
      )
    )
  );
};

const Activity = ({ hasActivity }) => {
  const dispatch = useDispatch();
  const activities = useSelector(recentActivitySelectors.selectAll);
  const loading = useSelector(getRecentActivityLoadingSelector);

  useEffect(() => {
    const promise = dispatch(fetchRecentAcitivty());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  return (
    !loading ? (
      hasActivity && activities.length > 0 ? (
        activities.map((activity) => {
          let path = activity?._paths?.[activity.type];
          if(activity.bundle === 'damage_area'){
            path = `/job/${activity.referenced_entity_title}/areas/${activity.id}`;
          }
          return (
            <Item key={`activity-${activity.id}`} to={path}>
              <Typography color="secondary" style={{ fontWeight: "bold" }}>
                {activity._action}
              </Typography>
              {getDiff(activity.diff_unserialized)}
              <Typography
                color="textSecondary"
                style={{ marginTop: "1rem" }}
              >{`${activity.user_first_name} ${activity.user_last_name} from ${activity.user_company}`}</Typography>
              <Typography variant="caption" color="secondary">
                {moment.unix(activity.datetime).format("h:mm A MMM Do, YYYY")}
              </Typography>
            </Item>
          );
        })
      ) : (
        <Typography variant="body2" className="px-3 text-center flex-[1_1_auto] flex justify-center">
          <div className="self-center">
            <img src={emptyBox} alt="Empty Box Icon" className="inline-block w-[100px] h-auto" /><br />
            No Current Activity
          </div>
        </Typography>
      )
    ) : (
      <DefaultLoader />
    )
  );
};

RecentActivity.propTypes = {};

export default RecentActivity;
