import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import axios from "axios";

import config from "../../config";
import { DefaultLoader } from "common/Loader";
import Alert from "common/Alert";
import Header from "../../features/Header";
import Sidebar from "../Sidebar";
import { Wrapper } from "./Styled";
import { withPermissions } from "../../providers";

const MBYSSORoute = ({ children, isAuthenticated, roles, ...rest }) => {
  const history = useHistory();
  const auth = useSelector((state) => state.auth.token);
  const csrf = useSelector((state) => state.auth.csrf);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSingleSignOn = useCallback(async () => {
    setLoading(true);
    const params = {
      withCredentials: true,
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/hal+json",
        Authorization: `Bearer ${auth}`,
      },
      params: {
        _format: "hal_json",
      },
    };

    try {
      const response = await axios.post(
        `${config.api_url}/rest/phx-mybackyard-api/tokens`,
        {
          action: "get_mby_sso_token",
          // redirect_url: history.location,
        },
        params
      );
      setLoading(false);
      if (Number(response.status) === 201) {
        const queryParams = new URLSearchParams(history.location.search);
        const ssoRedirect = decodeURIComponent(queryParams.get('sso_redirect'));
        window.location.href = ssoRedirect + (ssoRedirect.indexOf('?') > -1 ? '&' : '?') + 'sso_token=' + response.data.sso_token;
      } else {
        let message = "There was an error logging you in.";
        if(response?.response?.data?.message){
          message = response.response.data.message;
        }
        setMessage({
          id: "negative",
          msg: message,
        });
      }
    } catch (err) {
      let message = "There was an error logging you in.";
      if(err?.response?.data?.message){
        message = err.response.data.message;
      }
      setLoading(false);
      setMessage({ id: "negative", msg: message });
    }
  }, [auth, csrf, history]);

  useEffect(() => {
    if (isAuthenticated) {
      handleSingleSignOn();
    }
  }, [isAuthenticated, handleSingleSignOn]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <div className="app">
            <Header roles={roles} />
            <Wrapper>
              <Sidebar />
              <section className="page">
                <Helmet>
                  <title>Single Sign On | Helix</title>
                  <meta name="description" content="Single Sign On" />
                </Helmet>
                {loading ? (
                  <DefaultLoader />
                ) : (
                  <div style={{ padding: "1.25rem" }}>
                    {message && <Alert kind={message.id}>{message.msg}</Alert>}
                  </div>
                )}
              </section>
            </Wrapper>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const { bool } = PropTypes;
MBYSSORoute.propTypes = {
  isAuthenticated: bool.isRequired,
};

MBYSSORoute.defaultProps = {
  token: "",
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token,
});
export default compose(connect(mapStateToProps), withPermissions)(MBYSSORoute);
