import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import {
  formatCustomerComment,
  formatJobComment,
  formatGeneralFileComment,
  formatWorkAreaComment,
} from "./utils";
import { getLocalStorage } from "utility";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "comments";

export const fetchJobComments = createAsyncThunk(
  `${namespace}/fetchJobComments`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/notes/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUnreadComments = createAsyncThunk(
  `${namespace}/fetchUnreadComments`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/intranet/team/unread-comments${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchLastReadComment = createAsyncThunk(
  `${namespace}/patchLastReadComment`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.patch(
        `${config.api_url}/rest/intranet/team/comment/set-last-read/${id}`,
        params,
        tokenConfig(getState)
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreJobComments = createAsyncThunk(
  `${namespace}/fetchMoreJobComments`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/notes/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMostRecentComment = createAsyncThunk(
  `${namespace}/fetchMostRecentComment`,
  async (nid, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/notes/${nid}?rows_per_page=1`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkAreaComments = createAsyncThunk(
  `${namespace}/fetchWorkAreaComments`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/damage-area/notes/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreWorkAreaComments = createAsyncThunk(
  `${namespace}/fetchMoreWorkAreaComments`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/damage-area/notes/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchGeneralFileComments = createAsyncThunk(
  `${namespace}/fetchGeneralFileComments`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/general-file/comments/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreGeneralFileComments = createAsyncThunk(
  `${namespace}/fetchMoreGeneralFileComments`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/general-file/comments/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGeneralFilePublicComments = createAsyncThunk(
  `${namespace}/fetchGeneralFilePublicComments`,
  async ({ hash, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const commenter = getLocalStorage('public_commenter');
      if(commenter?.email){
        params.email = commenter.email;
      }
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/files/enhancement-public-comments/${hash}${queryparams}`,
        {
          // ...tokenConfig(getState),
          withCredentials: false,
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreGeneralFilePublicComments = createAsyncThunk(
  `${namespace}/fetchMoreGeneralFilePublicComments`,
  async ({ hash, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const commenter = getLocalStorage('public_commenter');
      if(commenter?.email){
        params.email = commenter.email;
      }
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/files/enhancement-public-comments/${hash}${queryparams}`,
        {
          // ...tokenConfig(getState),
          withCredentials: false,
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerComments = createAsyncThunk(
  `${namespace}/fetchCustomerComments`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/notes/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreCustomerComments = createAsyncThunk(
  `${namespace}/fetchMoreCustomerComments`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/notes/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        comments: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postJobComment = createAsyncThunk(
  `${namespace}/postJobComment`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/comment`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/comment/job_division`,
            },
          },
          entity_id: [
            {
              target_id: id,
              revision_id: null,
            },
          ],
          entity_type: [{ value: "node" }],
          field_name: [
            {
              value: "field_comments",
            },
          ],
          field_comment_long: [{ value: params.field_comment_long }],
          field_comment_images: params.files,
          field_comment_visible_to: params.field_comment_visible_to,
          field_notify_emails: params.field_notify_emails,
          field_notify_users: params.field_notify_users,
          field_comment_type: [
            {
              value: params.field_comment_type
                ? params.field_comment_type
                : "standard",
            },
          ],
          field_sent_to_fm_pilot: params.field_sent_to_fm_pilot,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchJobComment = createAsyncThunk(
  `${namespace}/patchJobComment`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const patchParams = {
        field_comment_long: [{ value: params.field_comment_long }],
      };

      if (params.field_comment_visible_to) {
        patchParams.field_comment_visible_to = params.field_comment_visible_to;
      }

      const response = await axios.patch(
        `${config.api_url}/comment/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/comment/job_division`,
            },
          },
          ...patchParams,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteJobComment = createAsyncThunk(
  `${namespace}/deleteJobComment`,
  async ({ id, division }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/comment/archive`,
        {
          division_nid: division,
          comment_cid: id,
          archive: true,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postWorkAreaComment = createAsyncThunk(
  `${namespace}/postWorkAreaComment`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/comment`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/comment/damage_area`,
            },
          },
          entity_id: [
            {
              target_id: id,
              revision_id: null,
            },
          ],
          entity_type: [{ value: "node" }],
          field_name: [
            {
              value: "field_da_comments",
            },
          ],
          field_da_comment: [{ value: params.field_da_comment }],
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchWorkAreaComment = createAsyncThunk(
  `${namespace}/patchWorkAreaComment`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const patchParams = {
        field_da_comment: [{ value: params.field_da_comment }],
      };

      if (params.field_da_comment_visible_to) {
        patchParams.field_da_comment_visible_to = params.field_da_comment_visible_to;
      }
      const response = await axios.patch(
        `${config.api_url}/comment/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/comment/damage_area`,
            },
          },
          ...patchParams,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteWorkAreaComment = createAsyncThunk(
  `${namespace}/deleteWorkAreaComment`,
  async ({ id, parent_nid }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/comment/archive`,
        {
          parent_nid: parent_nid,
          comment_cid: id,
          archive: true,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postGeneralFileComment = createAsyncThunk(
  `${namespace}/postGeneralFileComment`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/comment`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/comment/general_file`,
            },
          },
          entity_id: [
            {
              target_id: id,
              revision_id: null,
            },
          ],
          entity_type: [{ value: "node" }],
          field_name: [
            {
              value: "field_gf_comments",
            },
          ],
          field_comment_long: [{ value: params.field_comment_long }],
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postGeneralFilePublicComment = createAsyncThunk(
  `${namespace}/postGeneralFilePublicComment`,
  async ({ id, hash, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/files/enhancement-public-comments/${hash}`,
        params,
        {
          ...tokenConfig(getState),
          withCredentials: false,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchGeneralFileComment = createAsyncThunk(
  `${namespace}/patchGeneralFileComment`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const patchParams = {
        field_comment_long: [{ value: params.field_comment_long }],
      };

      const response = await axios.patch(
        `${config.api_url}/comment/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/comment/general_file`,
            },
          },
          ...patchParams,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGeneralFileComment = createAsyncThunk(
  `${namespace}/deleteGeneralFileComment`,
  async ({ id, division }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/comment/archive`,
        {
          division_nid: division,
          comment_cid: id,
          archive: true,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCustomerComment = createAsyncThunk(
  `${namespace}/postCustomerComment`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/comment`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/comment/customer_note`,
            },
          },
          entity_id: [
            {
              target_id: id,
              revision_id: null,
            },
          ],
          entity_type: [{ value: "node" }],
          field_name: [
            {
              value: "field_customer_notes",
            },
          ],
          subject: [{ value: params.subject }],
          field_customer_note_comment: [
            { value: params.field_customer_note_comment },
          ],
          field_customer_note_type: [
            { target_id: params.field_customer_note_type },
          ],
          field_customer_note_status: [
            { target_id: params.field_customer_note_status },
          ],
        },
        tokenConfig(getState)
      );

      return formatCustomerComment(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const commentsAdapter = createEntityAdapter({
  selectId: (comment) => comment.cid,
});

const unreadCommentsAdapter = createEntityAdapter({
  selectId: (team) => team.team_nid,
});

const commentsSlice = createSlice({
  name: namespace,
  initialState: commentsAdapter.getInitialState({
    loading: false,
    moreLoading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    unreadComments: unreadCommentsAdapter.getInitialState({
      error: null,
      loading: false,
    }),
  }),
  reducers: {},
  extraReducers: {
    [fetchJobComments.pending](state) {
      state.loading = true;
      commentsAdapter.removeAll(state);
    },
    [fetchJobComments.fulfilled](state, { payload: { comments, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      commentsAdapter.setAll(state, comments);
    },
    [fetchJobComments.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreJobComments.pending](state, action) {
      state.moreLoading = true;
    },
    [fetchMoreJobComments.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.moreLoading = false;
      state.pagination = pagination;
      commentsAdapter.upsertMany(state, comments);
    },
    [fetchMoreJobComments.rejected](state, action) {
      state.moreLoading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMostRecentComment.pending](state) {
      state.loading = true;
      commentsAdapter.removeAll(state);
    },
    [fetchMostRecentComment.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.loading = false;
      state.pagination = pagination;
      commentsAdapter.setAll(state, comments);
    },
    [fetchMostRecentComment.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchWorkAreaComments.pending](state) {
      state.loading = true;
      commentsAdapter.removeAll(state);
    },
    [fetchWorkAreaComments.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.loading = false;
      state.pagination = pagination;
      commentsAdapter.setAll(state, comments);
    },
    [fetchWorkAreaComments.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreWorkAreaComments.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.pagination = pagination;
      commentsAdapter.addMany(state, comments);
    },
    [fetchMoreWorkAreaComments.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchGeneralFileComments.pending](state) {
      state.error = false;
      state.loading = true;
      commentsAdapter.removeAll(state);
    },
    [fetchGeneralFileComments.fulfilled](state, { payload: { comments, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      commentsAdapter.setAll(state, comments);
    },
    [fetchGeneralFileComments.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreGeneralFileComments.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.pagination = pagination;
      commentsAdapter.addMany(state, comments);
    },
    [fetchMoreGeneralFileComments.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchGeneralFilePublicComments.pending](state) {
      state.error = false;
      state.loading = true;
      commentsAdapter.removeAll(state);
    },
    [fetchGeneralFilePublicComments.fulfilled](state, { payload: { comments, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      commentsAdapter.setAll(state, comments);
    },
    [fetchGeneralFilePublicComments.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreGeneralFilePublicComments.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.pagination = pagination;
      commentsAdapter.addMany(state, comments);
    },
    [fetchMoreGeneralFilePublicComments.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchCustomerComments.pending](state) {
      state.loading = true;
      commentsAdapter.removeAll(state);
    },
    [fetchCustomerComments.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.loading = false;
      state.pagination = pagination;
      commentsAdapter.setAll(state, comments);
    },
    [fetchCustomerComments.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreCustomerComments.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.pagination = pagination;
      commentsAdapter.addMany(state, comments);
    },
    [fetchMoreCustomerComments.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreCustomerComments.pending](state) {
      state.loading = true;
      commentsAdapter.removeAll(state);
    },
    [fetchMostRecentComment.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.loading = false;
      state.pagination = pagination;
      commentsAdapter.setAll(state, comments);
    },
    [fetchMostRecentComment.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchUnreadComments.pending](state) {
      state.unreadComments.error = null;
      state.unreadComments.loading = true;
    },
    [fetchUnreadComments.fulfilled](
      state,
      { payload: { comments, pagination } }
    ) {
      state.unreadComments.loading = false;
      unreadCommentsAdapter.setAll(state.unreadComments, comments);
    },
    [fetchUnreadComments.rejected](state, action) {
      state.unreadComments.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.unreadComments.error = action.payload.message;
        } else {
          state.unreadComments.error = action.error.message;
        }
      }
    },
    [patchLastReadComment.pending](state, action) {
      const { id } = action.meta.arg;
      unreadCommentsAdapter.removeOne(state.unreadComments, id);
    },
    [postJobComment.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;
      const comment = {
        cid: params.id,
        entity_id: "",
        subject: "",
        uid: params.user.uid,
        created: moment().format("X"),
        changed: "",
        first_name: params.user.first,
        last_name: params.user.last,
        profile_pic_uri: params.user.pic,
        comment: params.field_comment_long,
        comment_type: params.field_comment_type
          ? params.field_comment_type
          : "",
        sent_to_fm_pilot: "",
        comment_image_uris: "",
        comment_visible_to: "",
        notify_emails: "",
        notify_user_uids: "",
        notify_user_names: "",
        error: null,
        processing: true,
        _files: {
          profile_pic: params.user.pic,
        },
      };

      commentsAdapter.addOne(state, comment);
    },
    [postJobComment.fulfilled](state, { meta, payload }) {
      const { params } = meta.arg;
      const fields = formatJobComment(payload);

      const comment = {
        cid: `${fields.cid}`,
        entity_id: fields.entity_id,
        subject: null,
        uid: fields.user.uid,
        created: moment(fields.created).format("X"),
        changed: moment(fields.changed).format("X"),
        first_name: fields.user.field_first_name,
        last_name: fields.user.field_last_name,
        profile_pic_uri: null,
        comment: fields.field_comment_long,
        comment_type: fields.field_comment_type,
        sent_to_fm_pilot: fields.field_sent_to_fm_pilot,
        comment_image_uris: null,
        comment_visible_to: payload.field_comment_visible_to
          ?.map((vis) => vis.value)
          .join(","),
        notify_emails: null,
        notify_user_uids: null,
        notify_user_names: null,
        error: null,
        processing: false,
      };

      commentsAdapter.updateOne(state, {
        id: params.id,
        changes: comment,
      });
    },
    [postJobComment.rejected](state, action) {
      const { params } = action.meta.arg;
      commentsAdapter.removeOne(state, params.id);
      if (action.payload) {
        state.error = action.payload.message;
        // commentsAdapter.updateOne(state, {
        //   id: params.id,
        //   changes: { error: action.payload.message, processing: false },
        // });
      } else {
        state.error = action.error.message;
        // commentsAdapter.updateOne(state, {
        //   id: params.id,
        //   changes: { error: action.error.message, processing: false },
        // });
      }
    },
    [patchJobComment.pending](state, action) {
      state.error = null;
      const { id, params } = action.meta.arg;
      const comment = {
        comment: params.field_comment_long,
        processing: true,
      };

      commentsAdapter.updateOne(state, { id, changes: comment });
    },
    [patchJobComment.fulfilled](state, { meta, payload }) {
      const { id } = meta.arg;
      const comment = {
        processing: false,
        comment_visible_to: payload.field_comment_visible_to
          ?.map((vis) => vis.value)
          .join(","),
      };

      commentsAdapter.updateOne(state, { id, changes: comment });
    },
    [patchJobComment.rejected](state, action) {
      const { params, comment } = action.meta.arg;
      if (action.payload) {
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: comment,
        });
      } else {
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: {
            ...comment,
            error: action.error.message,
            processing: false,
          },
        });
      }
    },
    [deleteJobComment.pending](state, action) {
      state.error = null;
      const { id } = action.meta.arg;

      commentsAdapter.removeOne(state, id);
    },
    [deleteJobComment.rejected](state, action) {
      const { comment } = action.meta.arg;
      commentsAdapter.addOne(state, comment);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postWorkAreaComment.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;
      const comment = {
        cid: params.id,
        entity_id: "",
        subject: "",
        uid: params.user.uid,
        created: moment().format("X"),
        changed: "",
        first_name: params.user.first,
        last_name: params.user.last,
        profile_pic_uri: params.user.pic,
        comment: params.field_da_comment,
        comment_type: "",
        sent_to_fm_pilot: "",
        comment_image_uris: "",
        comment_visible_to: "",
        notify_emails: "",
        notify_user_uids: "",
        notify_user_names: "",
        error: null,
        processing: true,
      };

      commentsAdapter.addOne(state, comment);
    },
    [postWorkAreaComment.fulfilled](state, { meta, payload }) {
      const { params } = meta.arg;
      const fields = formatWorkAreaComment(payload);

      const comment = {
        cid: `${fields.cid}`,
        entity_id: fields.entity_id,
        subject: null,
        uid: fields.user.uid,
        created: moment(fields.created).format("X"),
        changed: moment(fields.changed).format("X"),
        first_name: fields.user.field_first_name,
        last_name: fields.user.field_last_name,
        profile_pic_uri: null,
        comment: fields.field_da_comment,
        comment_type: fields.field_comment_type,
        sent_to_fm_pilot: fields.field_sent_to_fm_pilot,
        comment_image_uris: null,
        comment_visible_to: fields.field_comment_visible_to,
        notify_emails: null,
        notify_user_uids: null,
        notify_user_names: null,
        error: null,
        processing: false,
      };

      commentsAdapter.updateOne(state, {
        id: params.id,
        changes: comment,
      });
    },
    [postWorkAreaComment.rejected](state, action) {
      const { params } = action.meta.arg;
      // commentsAdapter.removeOne(state, params.id);\
      if (action.payload) {
        // state.error = action.payload.message;
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: { error: action.payload.message, processing: false },
        });
      } else {
        // state.error = action.error.message;
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: { error: action.error.message, processing: false },
        });
      }
    },
    [patchWorkAreaComment.pending](state, action) {
      state.error = null;
      const { id, params } = action.meta.arg;
      const comment = {
        comment: params.field_da_comment,
        processing: true,
      };

      commentsAdapter.updateOne(state, { id, changes: comment });
    },
    [patchWorkAreaComment.fulfilled](state, { meta, payload }) {
      const { id } = meta.arg;
      const comment = {
        processing: false,
        comment_visible_to: payload.field_da_comment_visible_to
          ?.map((vis) => vis.value)
          .join(","),
      };

      commentsAdapter.updateOne(state, { id, changes: comment });
    },
    [patchWorkAreaComment.rejected](state, action) {
      const { params, comment } = action.meta.arg;
      if (action.payload) {
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: comment,
        });
      } else {
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: {
            ...comment,
            error: action.error.message,
            processing: false,
          },
        });
      }
    },
    [deleteWorkAreaComment.pending](state, action) {
      state.error = null;
      const { id } = action.meta.arg;

      commentsAdapter.removeOne(state, id);
    },
    [deleteWorkAreaComment.rejected](state, action) {
      const { comment } = action.meta.arg;
      commentsAdapter.addOne(state, comment);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postGeneralFileComment.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;
      const comment = {
        cid: params.id,
        entity_id: "",
        subject: "",
        uid: params.user.uid,
        created: moment().format("X"),
        changed: "",
        first_name: params.user.first,
        last_name: params.user.last,
        profile_pic_uri: params.user.pic,
        comment: params.field_comment_long,
        error: null,
        processing: true,
        _files: {
          profile_pic: params.user.pic,
        },
      };

      commentsAdapter.addOne(state, comment);
    },
    [postGeneralFileComment.fulfilled](state, { meta, payload }) {
      const { params } = meta.arg;
      const fields = formatGeneralFileComment(payload);

      const comment = {
        cid: `${fields.cid}`,
        entity_id: fields.entity_id,
        subject: null,
        uid: fields.user.uid,
        created: moment(fields.created).format("X"),
        changed: moment(fields.changed).format("X"),
        first_name: fields.user.field_first_name,
        last_name: fields.user.field_last_name,
        profile_pic_uri: null,
        comment: fields.field_comment_long,
        error: null,
        processing: false,
      };

      commentsAdapter.updateOne(state, {
        id: params.id,
        changes: comment,
      });
    },
    [postGeneralFileComment.rejected](state, action) {
      const { params } = action.meta.arg;
      commentsAdapter.removeOne(state, params.id);
      if (action.payload) {
        state.error = action.payload.message;
        // commentsAdapter.updateOne(state, {
        //   id: params.id,
        //   changes: { error: action.payload.message, processing: false },
        // });
      } else {
        state.error = action.error.message;
        // commentsAdapter.updateOne(state, {
        //   id: params.id,
        //   changes: { error: action.error.message, processing: false },
        // });
      }
    },
    [postGeneralFilePublicComment.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;
      console.log(params);
      const comment = {
        cid: params.id,
        entity_id: "",
        subject: "",
        uid: 0,
        created: moment().format("X"),
        changed: "",
        first_name: params.commenter.name,
        last_name: "",
        is_owner: 1,
        profile_pic_uri: null,
        comment: params.field_comment_long,
        error: null,
        processing: true,
        _files: {
          profile_pic: null,
        },
      };

      commentsAdapter.addOne(state, comment);
    },
    [postGeneralFilePublicComment.fulfilled](state, { meta, payload }) {
      const { params } = meta.arg;
      console.log(params);
      const fields = formatGeneralFileComment(payload);

      const comment = {
        cid: `${fields.cid}`,
        entity_id: fields.entity_id,
        subject: null,
        uid: 0,
        created: moment(fields.created).format("X"),
        changed: moment(fields.changed).format("X"),
        first_name: fields.field_commenter_name,
        last_name: "",
        is_owner: 1,
        profile_pic_uri: null,
        comment: fields.field_comment_long,
        error: null,
        processing: false,
      };

      commentsAdapter.updateOne(state, {
        id: params.id,
        changes: comment,
      });
    },
    [postGeneralFilePublicComment.rejected](state, action) {
      const { params } = action.meta.arg;
      commentsAdapter.removeOne(state, params.id);
      if (action.payload) {
        state.error = action.payload.message;
        // commentsAdapter.updateOne(state, {
        //   id: params.id,
        //   changes: { error: action.payload.message, processing: false },
        // });
      } else {
        state.error = action.error.message;
        // commentsAdapter.updateOne(state, {
        //   id: params.id,
        //   changes: { error: action.error.message, processing: false },
        // });
      }
    },
    [patchGeneralFileComment.pending](state, action) {
      state.error = null;
      const { id, params } = action.meta.arg;
      const comment = {
        comment: params.field_comment_long,
        processing: true,
      };

      commentsAdapter.updateOne(state, { id, changes: comment });
    },
    [patchGeneralFileComment.fulfilled](state, { meta, payload }) {
      const { id } = meta.arg;
      const comment = {
        processing: false,
      };

      commentsAdapter.updateOne(state, { id, changes: comment });
    },
    [patchGeneralFileComment.rejected](state, action) {
      const { params, comment } = action.meta.arg;
      if (action.payload) {
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: comment,
        });
      } else {
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: {
            ...comment,
            error: action.error.message,
            processing: false,
          },
        });
      }
    },
    [deleteGeneralFileComment.pending](state, action) {
      state.error = null;
      const { id } = action.meta.arg;

      commentsAdapter.removeOne(state, id);
    },
    [deleteGeneralFileComment.rejected](state, action) {
      const { comment } = action.meta.arg;
      commentsAdapter.addOne(state, comment);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postCustomerComment.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;
      const comment = {
        cid: params.id,
        entity_id: "",
        subject: params.subject,
        uid: params.user.uid,
        created: moment().format("X"),
        changed: "",
        first_name: params.user.first,
        last_name: params.user.last,
        profile_pic_uri: params.user.pic,
        comment: params.field_customer_note_comment,
        comment_type: params.field_customer_note_type,
        sent_to_fm_pilot: "",
        comment_image_uris: "",
        comment_visible_to: "",
        notify_emails: "",
        notify_user_uids: "",
        notify_user_names: "",
        error: null,
        processing: true,
      };

      commentsAdapter.addOne(state, comment);
    },
    [postCustomerComment.fulfilled](state, { meta, payload: fields }) {
      const { params } = meta.arg;
      const comment = {
        cid: `${fields.cid}`,
        entity_id: fields.entity_id,
        subject: fields.subject,
        uid: fields.user.uid,
        created: moment(fields.created).format("X"),
        changed: moment(fields.changed).format("X"),
        first_name: fields.user.field_first_name,
        last_name: fields.user.field_last_name,
        profile_pic_uri: null,
        comment: fields.field_customer_note_comment,
        note_type: fields.field_customer_note_type.name,
        note_status: fields.field_customer_note_status.name,
        sent_to_fm_pilot: fields.field_sent_to_fm_pilot,
        comment_image_uris: null,
        comment_visible_to: fields.field_comment_visible_to,
        notify_emails: null,
        notify_user_uids: null,
        notify_user_names: null,
        error: null,
        processing: false,
      };

      commentsAdapter.updateOne(state, {
        id: params.id,
        changes: comment,
      });
    },
    [postCustomerComment.rejected](state, action) {
      const { params } = action.meta.arg;
      // commentsAdapter.removeOne(state, params.id);\
      if (action.payload) {
        // state.error = action.payload.message;
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: { error: action.payload.message, processing: false },
        });
      } else {
        // state.error = action.error.message;
        commentsAdapter.updateOne(state, {
          id: params.id,
          changes: { error: action.error.message, processing: false },
        });
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getCommentsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.comments.loading
);

export const getCommentsMoreLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.comments.moreLoading
);

export const getCommentsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.comments.pagination
);

export const getCommentsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.comments.error
);

export const commentsSelectors = commentsAdapter.getSelectors(
  (state) => state.comments
);

export const unreadCommentsSelectors = unreadCommentsAdapter.getSelectors(
  (state) => state.comments.unreadComments
);

export default commentsSlice.reducer;
