import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import constants from "components/constants";

const Div = styled.div``; // Prevents warnings about unknown attributes on normal <div> elements

export const ScrollableContainer = ({
  scrollTo,
  setLayoutScrollTo,
  children,
  ...props
}) => {
  const scrollRef = useRef();

  useEffect(() => {
    if(typeof scrollTo !== 'undefined' && scrollTo !== null && scrollRef.current){
      scrollRef.current.scrollTop = scrollTo;
      setLayoutScrollTo(null);
    }
  }, [scrollTo, setLayoutScrollTo]);

  return <Div ref={el => { scrollRef.current = el; }} {...props}>{children}</Div>;
};


export const Container = styled(ScrollableContainer)`
  width: 100%;
  ${({ overflowYScroll, disableScroll}) => !disableScroll
    ? overflowYScroll ? "overflow-y: scroll;" : "overflow: auto;"
    : ""
  }
  height: ${({ disablePageHeader }) =>
    disablePageHeader
      ? "calc((var(--vh, 1vh) * 100) - var(--page-header-height))"
      : "calc((var(--vh, 1vh) * 100) - var(--page-header-height) - var(--page-header-height))"};
  min-height: var(--page-body-min-height);
  // background: var(--color-gray-background);
  background: white;
  outline: none !important;
  display: flex;
  flex-direction: column;

  &.has-bottom-navigation{
    min-height: calc(var(--page-body-min-height) - var(--page-bottom-navigation-height));
  }

  @media (max-width: ${constants.breakpoints.sm}) {
    ${({ disablePageHeader }) =>
    disablePageHeader
      ? ""
      : "height: calc((var(--vh, 1vh) * 100) - var(--page-header-height) - var(--page-header-height-2-line));"}
  }
  @media (max-height: ${constants.pageBodyMinHeight}) {
    min-height: 0px;
  }
`;
