import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
//   import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { formatTaskForListing } from "../Tasks/utils";
import { updateOneTask } from "../Tasks/tasksSlice";
import { formatTask } from "./utils";

//   const getQueryParams = (params) => {
//     return `?${
//       typeof params === "string"
//         ? params.substring(1)
//         : querystring.stringify(params)
//     }`;
//   };

const namespace = "task";

export const fetchTask = createAsyncThunk(
  `${namespace}/fetchTask`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatTask(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchTask = createAsyncThunk(
  `${namespace}/patchTask`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/task`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      const listingTask = formatTaskForListing(response.data);
      dispatch(updateOneTask({ id, changes: { ...listingTask } }));

      return formatTask(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating task: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postMonitoringChecklist = createAsyncThunk(
  `${namespace}/postMonitoringChecklist`,
  async ({ task, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/mf_monitoring_checklist`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_mf_monitoring_checklist",
            parent_id: task.nid,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      // dispatch(updateOneTask({ id, changes: { title: "test" } }));

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const taskSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    error: null,
    data: {},
  },
  reducers: {},
  extraReducers: {
    [fetchTask.pending](state) {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
    [fetchTask.fulfilled](state, { payload: task }) {
      state.loading = false;
      state.error = null;
      state.data = task;
    },
    [fetchTask.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchTask.fulfilled](state, { payload: task }) {
      state.error = null;
      state.data = task;
    },
    [patchTask.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getTaskLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.task.loading
);

export const getTaskDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.task.data
);

export const getTaskErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.task.error
);

export default taskSlice.reducer;
