import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
//   import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import {
  incrementChamberRooms,
  decrementChamberRooms,
} from "../Chambers/chambersSlice";
// import { addToast } from "../Toast/toastSlice";
import { formatRoomFields, formatFlooringOptionsForSubmit } from "./utils";

//   const getQueryParams = (params) => {
//     return `?${
//       typeof params === "string"
//         ? params.substring(1)
//         : querystring.stringify(params)
//     }`;
//   };

const namespace = "rooms";

export const fetchRooms = createAsyncThunk(
  `${namespace}/fetchRooms`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/entity/node/field_mf_chamber_rooms/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const chambers = response.data?.field_mf_chamber_rooms
        ? response.data.field_mf_chamber_rooms.map((c) => formatRoomFields(c))
        : [];

      return chambers;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postRoom = createAsyncThunk(
  `${namespace}/postRoom`,
  async ({ params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/mf_room`,
            },
          },
          _meta: {
            mf_chamber_nid: params.mf_chamber_nid,
          },
          title: [{ value: params.title }],
          field_mf_room_flooring_options:
            formatFlooringOptionsForSubmit(params),
        },
        tokenConfig(getState)
      );

      dispatch(incrementChamberRooms(params.mf_chamber_nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully created room",
        })
      );
      return formatRoomFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating room: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRoom = createAsyncThunk(
  `${namespace}/patchRoom`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/mf_room`,
            },
          },
          title: [{ value: params.title }],
          field_mf_room_flooring_options:
            formatFlooringOptionsForSubmit(params),
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated room",
        })
      );
      return formatRoomFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating room: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoom = createAsyncThunk(
  `${namespace}/deleteRoom`,
  async ({ id, chamber }, { getState, rejectWithValue, dispatch }) => {
    try {
      await axios.delete(`${config.api_url}/node/${id}`, tokenConfig(getState));

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "positive",
      //     message: `Successfully removed room`,
      //   })
      // );

      dispatch(decrementChamberRooms(chamber.nid));

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const roomsAdapter = createEntityAdapter({
  selectId: (room) => room.nid,
});

const roomsSlice = createSlice({
  name: namespace,
  initialState: roomsAdapter.getInitialState({
    error: null,
    loading: false,
  }),
  reducers: {},
  extraReducers: {
    [fetchRooms.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [fetchRooms.fulfilled](state, { payload: rooms }) {
      state.loading = false;
      roomsAdapter.setAll(state, rooms);
    },
    [fetchRooms.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postRoom.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;
      const room = {
        nid: params.id,
        title: params.title,
        is_saving: true,
      };

      roomsAdapter.addOne(state, room);
    },
    [postRoom.fulfilled](state, { meta, payload: room }) {
      const { params } = meta.arg;

      roomsAdapter.updateOne(state, {
        id: params.id,
        changes: { ...room, is_saving: false },
      });
    },
    [postRoom.rejected](state, action) {
      const { params } = action.meta.arg;
      roomsAdapter.removeOne(state, params.id);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchRoom.pending](state, action) {
      state.error = null;
      const { id, params } = action.meta.arg;

      const room = {
        nid: id,
        title: params.title,
        is_saving: true,
      };

      roomsAdapter.updateOne(state, {
        id,
        changes: room,
      });
    },
    [patchRoom.fulfilled](state, { meta, payload: room }) {
      const { id } = meta.arg;

      roomsAdapter.updateOne(state, {
        id,
        changes: { ...room, is_saving: false },
      });
    },
    [patchRoom.rejected](state, action) {
      const { params, room } = action.meta.arg;
      roomsAdapter.updateOne(state, { id: params.id, changes: room });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [deleteRoom.pending](state, action) {
      const { id } = action.meta.arg;
      roomsAdapter.removeOne(state, id);
    },
    [deleteRoom.rejected](state, action) {
      const { room } = action.meta.arg;
      roomsAdapter.addOne(state, room);

      if (action.payload) {
        state.error = `Error removing room: ${action.payload.message}`;
      } else {
        state.error = `Error removing room: ${action.error.message}`;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getRoomsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.rooms.loading
);

export const getRoomsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.rooms.error
);

export const roomsSelectors = roomsAdapter.getSelectors((state) => state.rooms);

export default roomsSlice.reducer;
