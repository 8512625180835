import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BsInfoCircle, BsCheckCircle } from "react-icons/bs";
import { AiOutlineWarning } from "react-icons/ai";
import { BiErrorAlt } from "react-icons/bi";

import { Wrapper, Icon, Message } from "./Styled";

import { getAlertSelector, clearAlert, hideAlert } from "./alertSlice";

const icon = {
  info: <BsInfoCircle />,
  positive: <BsCheckCircle />,
  warning: <AiOutlineWarning />,
  negative: <BiErrorAlt />,
};

const color = {
  info: {
    bkg: "rgb(232, 244, 253)",
    font: "rgb(13, 60, 97)",
    icon: "var(--color-blue)",
  },
  positive: {
    bkg: "rgb(237, 247, 237)",
    font: "rgb(30, 70, 32)",
    icon: "var(--color-green)",
  },
  warning: {
    bkg: "rgb(255, 244, 229)",
    font: "rgb(102, 60, 0)",
    icon: "var(--color-orange)",
  },
  negative: {
    bkg: "rgb(253, 236, 234)",
    font: "rgb(97, 26, 21)",
    icon: "var(--color-red)",
  },
};

const Body = ({
  children,
  kind,
  margin,
  onClose,
  disableElevation,
  disableRoundedCorners,
  ...rest
}) => {
  return (
    <Wrapper
      theme={{
        color: color[kind],
        margin:
          margin === "normal"
            ? "1rem 1.25rem"
            : margin === "none"
            ? "0"
            : "1rem 0",
        disableElevation,
        disableRoundedCorners,
      }}
      {...rest}
    >
      {kind !== "default" && (
        <Icon
          theme={{
            color: color[kind],
          }}
        >
          {icon[kind]}
        </Icon>
      )}
      {typeof children === "string" ? (
        <Message dangerouslySetInnerHTML={{ __html: children }}></Message>
      ) : (
        <Message>{children}</Message>
      )}
      {onClose && (
        <IconButton
          size="small"
          onClick={onClose}
          style={{ marginLeft: "1.25rem", color: color[kind]?.font }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Wrapper>
  );
};

const { oneOf, bool } = PropTypes;
Body.propTypes = {
  margin: oneOf(["normal", "default", "none"]),
  kind: oneOf(["default", "info", "positive", "negative", "warning"]),
  disableElevation: bool,
};

Body.defaultProps = {
  kind: "default",
  margin: "default",
  disableElevation: false,
};

const Alert = ({ ...rest }) => {
  const dispatch = useDispatch();
  const alert = useSelector(getAlertSelector);

  useEffect(() => {
    let timer;
    if (alert.show && alert.kind !== "negative") {
      timer = setTimeout(() => dispatch(hideAlert()), 6000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [alert, dispatch]);

  const handleClose = () => {
    dispatch(hideAlert());
  };

  const handleClear = () => {
    dispatch(clearAlert());
  };

  return (
    <Collapse in={alert.show} onExited={handleClear}>
      <Body kind={alert.kind} onClose={handleClose} {...rest}>
        {alert.msg}
      </Body>
    </Collapse>
  );
};

export default Alert;
