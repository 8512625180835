import React from "react";
import ContentLoader from "react-content-loader";
import constants from "../constants";

const ImageSkeleton = props => {
  return (
    <ContentLoader
      speed={2}
      width={150}
      height={150}
      viewBox="0 0 150 150"
      foregroundColor={constants.colorGray}
      backgroundColor={constants.colorGrayLight}
    >
      <rect x="0" y="0" rx="3" ry="3" width="150" height="150" />
    </ContentLoader>
  );
};

export default ImageSkeleton;
