import Layout from "common/Layout";
import React from "react";
import { useParams } from "react-router-dom";
import Upload from "./components/Upload";
import styled from "styled-components";

const Styles = styled.div`
  .form {
    width: 100%;
    padding: 1.25rem;
  }
`;

const Elocal = (props) => {
  const { hash } = useParams();

  return (
    <Layout
      name={"File Upload"}
      meta="File Upload"
      title="File Upload"
      loading={false}
    >
      <Styles>
        <Upload hash={hash} />
      </Styles>
    </Layout>
  );
};

Elocal.propTypes = {};

export default Elocal;
