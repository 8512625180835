import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { TextField, CircularProgress } from "@mui/material";
import { Autocomplete } from '@mui/material';

import { useAutocomplete } from "../../hooks";

const AutocompleteFormFieldContained = ({
  fullWidth,
  label,
  variant,
  endAdornment,
  labelwidth,
  margin,
  required,
  shrink,
  autoFocus,
  multiple,
  open,
  onOpen,
  onClose,
  inputProps,
  clientNid,
  load,
  existingOptions,
  filter,
  filterOptions,
  error,
  loadingData,
  data,
  entityId,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const errorText = meta.error && meta.touched ? meta.error : "";

  const [working] = useAutocomplete(
    load,
    query,
    null,
    null,
    clientNid,
    filter,
    entityId,
    filterOptions
  );

  useEffect(() => {
    if (!error) {
      const u = data.map((obj) => ({
        name: obj.name
          ? obj.name
          : obj.member_name
          ? obj.member_name
          : obj.customer_name
          ? obj.customer_name
          : obj.full_name
          ? obj.full_name
          : obj.title
          ? obj.title
          : null,
        entity_id: obj.uid
          ? obj.uid
          : obj.nid
          ? obj.nid
          : obj.member_nid
          ? obj.member_nid
          : obj.customer_nid
          ? obj.customer_nid
          : obj.entity_id
          ? obj.entity_id
          : obj.id
          ? obj.id
          : null,
        entity_type: obj.type ? obj.type : null,
      }));
      if(existingOptions){
        existingOptions.forEach((option) => {
          if(!u.find((obj) => Number(obj.entity_id) === Number(option.entity_id))){
            u.push({name: option.name, entity_id: option.entity_id});
          }
        });
      }
      setOptions([...u]);
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  return (
    <Autocomplete
      autoComplete={false}
      multiple={multiple}
      open={open}
      onChange={(e, value) => helpers.setValue(value)}
      value={field.value}
      onOpen={onOpen}
      onClose={onClose}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={working}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin={margin}
          error={!!errorText}
          helperText={errorText}
          required={required}
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            onChange: handleSearch,
            endAdornment: (
              <React.Fragment>
                {working ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...inputProps}
        />
      )}
    />
  );
};

AutocompleteFormFieldContained.defaultProps = {
  data: [],
};

export default AutocompleteFormFieldContained;
