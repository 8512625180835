import { fileActions } from "../actions/files/fileActions";
import { modifyFiles } from "../utility/fileUtils";

const initialState = {
  fileProgress: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fileActions.SETUP_UPLOAD:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.files, action.field),
        },
      };
    case fileActions.PROGRESS_UPLOAD:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            status: 1,
            progress: action.payload.progress,
          },
        },
      };
    case fileActions.OK_UPLOAD:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.id]: {
            ...state.fileProgress[action.id],
            status: 2,
            response: action.response,
          },
        },
      };
    case fileActions.ERROR_UPLOAD:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.id]: {
            ...state.fileProgress[action.id],
            status: 0,
            progress: 0,
            response: action.response,
          },
        },
      };
    case fileActions.CLEAR_UPLOAD:
      return initialState;
    case fileActions.REMOVE_UPLOAD:
      const currentFiles = state.fileProgress;
      delete currentFiles[action.id];
      return {
        ...state,
        fileProgress: {
          ...currentFiles,
        },
      };
    default:
      return state;
  }
};

export default reducer;
