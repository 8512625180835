import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import Button from "common/Button";

import { Container, Spinner, SpinnerContainer } from "./Styled";

const ButtonLoader = ({
  fullWidth,
  isSubmitting,
  children,
  disabled,
  ...rest
}) => {
  return (
    <Container fullWidth={fullWidth}>
      <Button
        disabled={isSubmitting || disabled}
        fullWidth={fullWidth}
        {...rest}
      >
        {children}
      </Button>
      {isSubmitting && (
        <SpinnerContainer>
          <Spinner>
            <CircularProgress size={12} />
          </Spinner>
        </SpinnerContainer>
      )}
    </Container>
  );
};

const { bool } = PropTypes;
ButtonLoader.propTypes = {
  fullWidth: bool,
  isSubmitting: bool,
};

export default ButtonLoader;
