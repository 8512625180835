import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatUserForManage } from "features/Users/utils";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "autocomplete";

export const fetchUsers = createAsyncThunk(
  `${namespace}/fetchUsers`,
  async ({ id, assignedOnly, params }, { getState, signal }) => {
    const source = axios.CancelToken.source();
    const queryParams = getQueryParams(params);

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await axios.get(
      `${config.api_url}/rest/users/${id ? id : ""}${
        assignedOnly ? `/${assignedOnly}` : ""
      }${queryParams}`,
      {
        ...tokenConfig(getState),
        cancelToken: source.token,
      }
    );

    return response.data.data.map(formatUserForManage);
  }
);

export const fetchCustomerUsers = createAsyncThunk(
  `${namespace}/fetchCustomerUsers`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/users/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        users: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberUsers = createAsyncThunk(
  `${namespace}/fetchMemberUsers`,
  async (
    { id, params, onlyAssigned },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/users/${id}${
          onlyAssigned ? "" : "/true"
        }${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        users: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchChildMembers = createAsyncThunk(
  `${namespace}/fetchChildMembers`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/child-members/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        members: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomers = createAsyncThunk(
  `${namespace}/fetchCustomers`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customers${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        customers: response.data.data,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const usersAdapter = createEntityAdapter({
  selectId: (user) => user.uid,
});

const membersAdapter = createEntityAdapter({
  selectId: (member) => member.member_nid,
});

const autocompleteSlice = createSlice({
  name: namespace,
  initialState: {
    users: usersAdapter.getInitialState({
      loading: false,
    }),
    members: membersAdapter.getInitialState({
      loading: false,
    }),
    customers: {
      loading: false,
      data: [],
    },
  },
  reducers: {},
  extraReducers: {
    [fetchUsers.pending](state) {
      state.users.loading = true;
    },
    [fetchUsers.fulfilled](state, { payload: users }) {
      usersAdapter.setAll(state.users, users);
      state.users.loading = false;
    },
    [fetchUsers.rejected](state) {
      state.users.loading = false;
    },
    [fetchCustomerUsers.pending](state) {
      state.users.loading = true;
      state.users.error = null;
    },
    [fetchCustomerUsers.fulfilled](state, { payload: users }) {
      state.users.loading = false;
      state.users.error = null;
      usersAdapter.setAll(state.users, users);
    },
    [fetchCustomerUsers.rejected](state, action) {
      state.users.loading = false;
      if (action.payload) {
        state.users.error = action.payload.message;
      } else {
        state.users.error = action.error.message;
      }
    },
    [fetchMemberUsers.pending](state) {
      state.users.loading = true;
      state.users.error = null;
    },
    [fetchMemberUsers.fulfilled](state, { payload: users }) {
      state.users.loading = false;
      state.users.error = null;
      usersAdapter.setAll(state.users, users);
    },
    [fetchMemberUsers.rejected](state, action) {
      state.users.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.users.error = action.payload.message;
        } else {
          state.users.error = action.error.message;
        }
      }
    },
    [fetchChildMembers.pending](state) {
      state.loading = true;
    },
    [fetchChildMembers.fulfilled](state, { payload: { members, pagination } }) {
      state.members.loading = false;
      state.members.pagination = pagination;
      membersAdapter.setAll(state.members, members);
    },
    [fetchChildMembers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.members.error = action.payload.message;
        } else {
          state.members.error = action.error.message;
        }
      }
    },
    [fetchCustomers.pending](state) {
      state.customers.loading = true;
    },
    [fetchCustomers.fulfilled](state, { payload: { customers/*, pagination*/ } }) {
      state.customers.loading = false;
      // state.customers.pagination = pagination;
      state.customers.data = customers;
    },
    [fetchCustomers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.customers.loading = false;
        if (action.payload) {
          state.customers.error = action.payload.message;
        } else {
          state.customers.error = action.error.message;
        }
      }
    },
  },
});

export const autocompleteUsersSelectors = usersAdapter.getSelectors(
  (state) => state.autocomplete.users
);

export const autocompleteMembersSelectors = membersAdapter.getSelectors(
  (state) => state.autocomplete.members
);

// Custom selectors
const selectSelf = (state) => state;
export const getAutocompleteUsersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.autocomplete.users.loading
);

export const getAutocompleteUsersErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.autocomplete.users.error
);

export const getAutocompleteMembersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.autocomplete.members.loading
);

export const getAutocompleteCustomersSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.autocomplete.customers.data
);

export const getAutocompleteCustomersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.autocomplete.customers.loading
);

export const getAutocompleteCustomersErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.autocomplete.customers.error
);

export default autocompleteSlice.reducer;
