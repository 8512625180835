import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";

const namespace = "alert";

const layoutSlice = createSlice({
  name: namespace,
  initialState: {
    // show: false,
    // kind: null,
    // msg: null,
    rightAsideOpen: localStorage.getItem('right_aside_pinned') === "true",
    rightAsideDrawerOpen: false,
  },
  reducers: {
    setRightAsideOpen: (state, action) => {
      state.rightAsideOpen = action.payload;
    },
    setRightAsideDrawerOpen: (state, action) => {
      state.rightAsideDrawerOpen = action.payload;
    },
    // setAlert: (state, action) => {
    //   state.show = true;
    //   state.kind = action.payload.kind;
    //   state.msg = action.payload.msg;
    // },
    // clearAlert: (state) => {
    //   state.show = false;
    //   state.kind = null;
    //   state.msg = null;
    // },
    // hideAlert: (state) => {
    //   state.show = false;
    // },
  },
  extraReducers: {},
});

// Custom selectors
const selectSelf = (state) => state;
export const getRightAsideOpenSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.layout.rightAsideOpen
);
export const getRightAsideDrawerOpenSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.layout.rightAsideDrawerOpen
);
// export const getAlertSelector = createDraftSafeSelector(
//   selectSelf,
//   (state) => state.alert
// );

export const { setRightAsideOpen, setRightAsideDrawerOpen, /*setAlert, clearAlert, hideAlert*/ } = layoutSlice.actions;

export default layoutSlice.reducer;
