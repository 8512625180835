import * as Yup from "yup";
import constants from "components/constants";

export const addCustomerGeneral = Yup.object().shape({
  name: Yup.string("Must be a string").required("Required"),
  notes: Yup.string("Must by a string"),
  status: Yup.string("Must by a string").required("Required"),
});

export const addCustomerAddress = Yup.object().shape({
  "office-street": Yup.string("Must be a string").required("Required"),
  "office-street2": Yup.string("Must by a string"),
  "office-city": Yup.string("Must by a string").required("Required"),
  "office-state": Yup.string("Must by a string").required("Required"),
  "office-zip": Yup.string("Must by a string").required("Required"),
  "mail-street": Yup.string("Must be a string"),
  "mail-street2": Yup.string("Must by a string"),
  "mail-city": Yup.string("Must by a string"),
  "mail-state": Yup.string("Must by a string"),
  "mail-zip": Yup.string("Must by a string"),
});

export const addCustomerContact = Yup.object().shape({
  field_emp_first_name: Yup.string().required("Required"),
  field_emp_last_name: Yup.string().required("Required"),
  field_emp_position: Yup.string(),
  field_emp_email: Yup.string().email(),
  field_emp_phone: Yup.string().matches(
    constants.phoneRegExp,
    "Phone number is not valid"
  ),
  field_emp_mobile: Yup.string().matches(
    constants.phoneRegExp,
    "Phone number is not valid"
  ),
});

export const addCustomerFormSchema = Yup.object().shape({
  title: Yup.string("Must be a string").required("Required"),
  field_phoenix_client: Yup.string("Must be a string").required("Required"),
  field_note: Yup.string("Must be a string"),
  field_customer_status: Yup.string("Must be a string").required("Required"),
  field_street_address: Yup.object().shape({
    address_line1: Yup.string("Must be a string").required("Required"),
    address_line2: Yup.string("Must be a string"),
    administrative_area: Yup.string("Must be a string").required("Required"),
    locality: Yup.string("Must be a string").required("Required"),
    postal_code: Yup.string("Must be a string").required("Required"),
  }),
  field_billing_address: Yup.object().shape({
    address_line1: Yup.string("Must be a string").required("Required"),
    address_line2: Yup.string("Must be a string"),
    administrative_area: Yup.string("Must be a string").required("Required"),
    locality: Yup.string("Must be a string").required("Required"),
    postal_code: Yup.string("Must be a string").required("Required"),
  }),
});
