import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  getTaxonomyFields,
  removeCurrencyFormatting,
} from "../../utility";

export const formatForGraph = (data) => {
  const areas = {
    totals: [],
    labels: [],
  };
  if (!data) return areas;
  data.forEach((area) => {
    areas.totals.push(removeCurrencyFormatting(area.total));
    areas.labels.push(area.title);
  });

  return areas;
};

export const formatDataForCompletionTracker = (data) => {
  const items = [];
  if (!data) return items;
  data.forEach((item, i) => {
    items.push({
      date: item.title,
      validated: item.status === "approved",
    });
  });

  return items;
};

export const formatWorkAreaFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/damage_area",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_cust_approval_needed: formatField(d, "field_cust_approval_needed"),
    field_da_area_size: formatField(d, "field_da_area_size"),
    field_da_demo: formatField(d, "field_da_demo"),
    field_da_height_water: formatField(d, "field_da_height_water"),
    field_da_is_general: formatField(d, "field_da_is_general"),
    field_da_life_safety: formatField(d, "field_da_life_safety"),
    field_da_linear_affected: formatField(d, "field_da_linear_affected"),
    field_da_location: formatField(d, "field_da_location"),
    field_da_notes: formatField(d, "field_da_notes"),
    field_da_power: formatField(d, "field_da_power"),
    field_da_status: formatField(d, "field_da_status"),
    field_da_type: getTaxonomyFields(d, "field_da_type"),
    field_da_water_present: formatField(d, "field_da_water_present"),
    field_consumables_tax: formatField(d, "field_consumables_tax"),
    _processed: d._processed,
  };
};

export const formatWorkAreaGroupFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/damage_area_group",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
  };
};
