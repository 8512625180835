import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";
import moment from "moment";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatExpense } from "../Expense/utils";
import { formatEquipment } from "../Equipment/utils";
import { setAlert } from "features/Alert/alertSlice";
import { formatService } from "../Service/utils";
import { formatMoney } from "../../utility";

const formatServiceSummary = (service) => {
  const strArr = [];
  if (service.serviceHrs > 0)
    strArr.push(
      `Standard: ${Number(service.serviceHrs).toFixed(2)}/${formatMoney(
        service.rate
      )}`
    );
  if (service.overtimeHrs > 0)
    strArr.push(
      `Overtime: ${Number(service.overtimeHrs).toFixed(2)}/${formatMoney(
        service.overrideRate
      )}`
    );
  if (service.afterHoursHrs > 0)
    strArr.push(
      `After Hours: ${Number(service.afterHoursHrs).toFixed(2)}/${formatMoney(
        service.afterHoursRate
      )}`
    );
  if (service.travelHrs > 0)
    strArr.push(
      `Travel: ${Number(service.travelHrs).toFixed(2)}/${formatMoney(
        service.travelRate
      )}`
    );

  return strArr.join("\\,");
};

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "dailysheets";

export const fetchDailysheets = createAsyncThunk(
  `${namespace}/fetchDailysheets`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        sheets: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreDailysheets = createAsyncThunk(
  `${namespace}/fetchMoreDailysheets`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        sheets: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDailysheetsInvoices = createAsyncThunk(
  `${namespace}/fetchDailysheetsInvoices`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/invoices/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        invoices: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDailysheetsOverview = createAsyncThunk(
  `${namespace}/fetchDailysheetsOverview`,
  async ({ id, day, endDay, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/overview/${id}${
          day ? `/${day}` : ""
        }${
          day && endDay ? `/${endDay}` : ""
        }${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDailysheetsDates = createAsyncThunk(
  `${namespace}/fetchDailysheetsDates`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/dates/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDailysheetsInvoiceResources = createAsyncThunk(
  `${namespace}/fetchDailysheetsInvoiceResources`,
  async ({ division, start, end }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/ds-invoice/ds-items/${division.nid}/0/${start}/${end}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        sheets: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postValidation = createAsyncThunk(
  `${namespace}/postValidation`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/daily_sheets_validation`,
            },
          },
          _meta: {
            job_division_nid: id,
          },
          title: [{ value: "-" }],
          field_ds_validation_date: [{ value: params.date }],
          field_ds_validation_is_validated: [{ value: 1 }],
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postExpense = createAsyncThunk(
  `${namespace}/postExpense`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/ds_expense`,
            },
          },
          title: [{ value: `Daily Sheet for ${params.division}` }],
          field_ds_job_division: [{ target_id: id }],
          field_ds_description: [{ value: params.description }],
          field_ds_exclude_invoice: [{ value: params.exclude }],
          field_ds_expense_cost: [{ value: params.cost }],
          field_ds_date: [{ value: params.date }],
          field_ds_total: [{ value: params.total }],
          field_ds_expense_type_text: [{ value: params.type }],
          field_ds_percent_markup: [{ value: params.markup }],
          field_ds_reimburse: [{ value: params.reimburse }],
          field_ds_description_show: [{ value: params.show }],
          field_ds_show_descript_invoice: [{ value: params.show }],
          field_ds_receipt: params.receipt
            ? [{ target_id: params.receipt }]
            : [],
          field_ds_user: [{ target_id: params.user.uid }],
          field_ds_taxable: [{ value: params.field_ds_taxable }],
        },
        tokenConfig(getState)
      );

      return formatExpense(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEquipment = createAsyncThunk(
  `${namespace}/postEquipment`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const postParams = {
        title: [{ value: `Daily Sheet for ${params.division}` }],
        field_ds_job_division: [{ target_id: id }],
        field_ds_user: [{ target_id: params.user.uid }],
        field_ds_equipment_resources: params.resources,
        field_ds_total: [{ value: params.total }],
        field_ds_date: [{ value: params.date }],
        field_ds_taxable: [{ value: params.field_ds_taxable }],
      };

      if (params.work_area) {
        postParams.field_ds_work_area_allocation = [
          { target_id: params.work_area },
        ];
      }

      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/ds_equipment`,
            },
          },
          ...postParams,
        },
        tokenConfig(getState)
      );

      return formatEquipment(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postService = createAsyncThunk(
  `${namespace}/postService`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/ds_service`,
            },
          },
          // title: [{ value: `Daily Sheet for ${params.division}` }],
          field_ds_job_division: [{ target_id: id }],
          ...params,
        },
        tokenConfig(getState)
      );

      const overtimeError = response.data?._processed
        ?.ds_service_overtime_message
        ? response.data._processed.ds_service_overtime_message
        : null;
      if (overtimeError) {
        if (overtimeError === "overtime_wrong_day_but_has_overrides") {
          dispatch(
            setAlert({
              show: true,
              kind: "warning",
              msg: `This entry has been saved with a warning. An Entry for this User during the work week has an override in place. As a result overtime could not be distributed properly.`,
            })
          );
        } else if (overtimeError === "overtime_wrong_day_validation_required") {
          dispatch(
            setAlert({
              show: true,
              kind: "warning",
              msg: `This entry has been saved with a warning. The total hours for this user results in overtime and has been redistributed to the proper days of the work week, as a result one or more days for this week will need re-validation.`,
            })
          );
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            kind: "positive",
            msg: `Successfully added labor`,
          })
        );
      }

      return formatService(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding labor: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchService = createAsyncThunk(
  `${namespace}/patchService`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/ds_service`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      const overtimeError = response.data?._processed
        ?.ds_service_overtime_message
        ? response.data._processed.ds_service_overtime_message
        : null;
      if (overtimeError) {
        if (overtimeError === "overtime_wrong_day_but_has_overrides") {
          dispatch(
            setAlert({
              show: true,
              kind: "warning",
              msg: `This entry has been saved with a warning. An Entry for this User during the work week has an override in place. As a result overtime could not be distributed properly.`,
            })
          );
        } else if (overtimeError === "overtime_wrong_day_validation_required") {
          dispatch(
            setAlert({
              show: true,
              kind: "warning",
              msg: `This entry has been saved with a warning. The total hours for this user results in overtime and has been redistributed to the proper days of the work week, as a result one or more days for this week will need re-validation.`,
            })
          );
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            kind: "positive",
            msg: `Successfully Updated labor`,
          })
        );
      }

      return formatService(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating labor: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postBreak = createAsyncThunk(
  `${namespace}/postBreak`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/ds_service_break_times`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_ds_breaks",
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchBreak = createAsyncThunk(
  `${namespace}/patchBreak`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/ds_service_break_times`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchEquipment = createAsyncThunk(
  `${namespace}/patchEquipment`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/ds_equipment`,
            },
          },
          field_ds_user: [{ target_id: params.user.uid }],
          field_ds_equipment_resources: params.resources,
          field_ds_total: [{ value: params.total }],
          field_ds_date: [{ value: params.date }],
          field_ds_work_area_allocation: [{ target_id: params.work_area }],
          field_ds_taxable: [{ value: params.field_ds_taxable }],
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated equipment`,
        })
      );

      return formatEquipment(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating equipment: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchExpense = createAsyncThunk(
  `${namespace}/patchExpense`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/ds_expense`,
            },
          },
          field_ds_description: [{ value: params.description }],
          field_ds_exclude_invoice: [{ value: params.exclude }],
          field_ds_expense_cost: [{ value: params.cost }],
          field_ds_date: [{ value: params.date }],
          field_ds_total: [{ value: params.total }],
          field_ds_expense_type_text: [{ value: params.type }],
          field_ds_percent_markup: [{ value: params.markup }],
          field_ds_reimburse: [{ value: params.reimburse }],
          field_ds_description_show: [{ value: params.show }],
          field_ds_show_descript_invoice: [{ value: params.show }],
          field_ds_receipt: params.receipt
            ? [{ target_id: params.receipt }]
            : [],
          field_ds_user: [{ target_id: params.user.uid }],
          field_ds_taxable: [{ value: params.field_ds_taxable }],
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated expense`,
        })
      );

      return formatExpense(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating expense: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDailysheet = createAsyncThunk(
  `${namespace}/deleteDailysheet`,
  async ({ id, sheet }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/daily-sheet/archive`,
        {
          daily_sheet_nid: id,
          archive: true,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully removed ${sheet.title}`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing item: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  `${namespace}/deleteInvoice`,
  async ({ id, sheet }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/daily-sheet/archive`,
        {
          daily_sheet_nid: id,
          archive: true,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully removed ${sheet.title}`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing item: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postDuplicateDay = createAsyncThunk(
  `${namespace}/postDuplicateDay`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/job/daily-sheet/duplicate`,
        params,
        tokenConfig(getState)
      );

      console.log(response);
      // return formatEquipment(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const dailysheetsAdapter = createEntityAdapter({
  selectId: (sheet) => sheet.daily_sheet_nid,
});

const dailysheetsInvoicesAdapter = createEntityAdapter({
  selectId: (invoice) => invoice.invoice_nid,
});

const dailysheetsInvoiceResourcesAdapter = createEntityAdapter({
  selectId: (sheet) => sheet.daily_sheet_nid,
});

const dailysheetsDatesAdapter = createEntityAdapter({
  selectId: (date) => date.date,
});

const dailysheetsSlice = createSlice({
  name: namespace,
  initialState: dailysheetsAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    dates: dailysheetsDatesAdapter.getInitialState({
      loading: false,
    }),
    overview: {
      loading: false,
      data: {},
    },
    invoices: dailysheetsInvoicesAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    invoiceResources: dailysheetsInvoiceResourcesAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
  }),
  reducers: {
    clearAllDailysheets: dailysheetsAdapter.removeAll,
    clearAllInvoices: dailysheetsInvoicesAdapter.removeAll,
    clearOverviewData: (state) => {
      state.overview.data = {};
    },
  },
  extraReducers: {
    [fetchDailysheets.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchDailysheets.fulfilled](state, { payload: { sheets, pagination } }) {
      state.loading = false;
      state.error = null;
      state.pagination = pagination;
      dailysheetsAdapter.setAll(state, sheets);
    },
    [fetchDailysheets.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreDailysheets.fulfilled](
      state,
      { payload: { sheets, pagination } }
    ) {
      state.pagination = pagination;
      dailysheetsAdapter.addMany(state, sheets);
    },
    [fetchMoreDailysheets.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchDailysheetsInvoices.pending](state) {
      state.invoices.loading = true;
      state.error = null;
    },
    [fetchDailysheetsInvoices.fulfilled](
      state,
      { payload: { invoices, pagination } }
    ) {
      state.invoices.loading = false;
      state.error = null;
      state.invoices.pagination = pagination;
      dailysheetsInvoicesAdapter.setAll(state.invoices, invoices);
    },
    [fetchDailysheetsInvoices.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchDailysheetsOverview.pending](state) {
      state.overview.loading = true;
      state.error = null;
    },
    [fetchDailysheetsOverview.fulfilled](state, { payload: meta }) {
      state.overview.loading = false;
      state.overview.data = meta;
      state.error = null;
    },
    [fetchDailysheetsOverview.rejected](state, action) {
      state.overview.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchDailysheetsDates.pending](state) {
      state.dates.loading = true;
      state.error = null;
    },
    [fetchDailysheetsDates.fulfilled](state, { payload: dates }) {
      state.dates.loading = false;
      state.error = null;
      dailysheetsDatesAdapter.setAll(state.dates, dates);
    },
    [fetchDailysheetsDates.rejected](state, action) {
      state.dates.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchDailysheetsInvoiceResources.pending](state) {
      state.invoiceResources.loading = true;
      state.error = null;
    },
    [fetchDailysheetsInvoiceResources.fulfilled](
      state,
      { payload: { sheets, pagination } }
    ) {
      state.invoiceResources.loading = false;
      state.invoiceResources.pagination = false;
      state.error = null;
      dailysheetsInvoiceResourcesAdapter.setAll(state.invoiceResources, sheets);
    },
    [fetchDailysheetsInvoiceResources.rejected](state, action) {
      state.invoiceResources.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteDailysheet.pending](state, action) {
      const { id } = action.meta.arg;
      dailysheetsAdapter.removeOne(state, id);
    },
    [deleteDailysheet.rejected](state, action) {
      const { sheet } = action.meta.arg;
      dailysheetsAdapter.addOne(state, sheet);
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteInvoice.pending](state, action) {
      const { id } = action.meta.arg;
      dailysheetsInvoicesAdapter.removeOne(state.invoices, id);
    },
    [deleteInvoice.rejected](state, action) {
      const { sheet } = action.meta.arg;
      dailysheetsInvoicesAdapter.addOne(state.invoices, sheet);
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postExpense.fulfilled](state, { payload: expense }) {
      state.error = null;

      const data = {
        daily_sheet_nid: expense.nid,
        daily_sheet_node_type: "ds_expense",
        category: "Expense",
        date: moment(expense.field_ds_date).format("X"),
        total: expense.field_ds_total,
        summary: expense.field_ds_expense_type_text,
        ds_user_full_name: `${expense.field_ds_user?.[0].first} ${expense.field_ds_user?.[0].last}`,
        hours_rate: expense.field_ds_total,
      };

      dailysheetsAdapter.addOne(state, data);
    },
    [postService.fulfilled](state, { payload: service }) {
      state.error = null;
      const userName = service.user?.[0]
        ? `${service.user?.[0].first} ${service.user?.[0].last}`
        : service.tempUser?.[0]
        ? `${service.tempUser?.[0].name}`
        : null;

      const data = {
        daily_sheet_nid: service.nid,
        daily_sheet_node_type: "ds_service",
        category: "Labor",
        date: moment(service.date).format("X"),
        total: service.total,
        summary: service.summary,
        ds_user_full_name: userName,
        hours_rate: formatServiceSummary(service),
      };

      dailysheetsAdapter.addOne(state, data);
    },
    [postEquipment.fulfilled](state, { payload: equipment, meta }) {
      const { params } = meta.arg;
      state.error = null;

      const data = {
        daily_sheet_nid: equipment.nid,
        daily_sheet_node_type: "ds_equipment",
        category: "Equipment",
        date: moment(equipment.field_ds_date).format("X"),
        total: equipment.field_ds_total,
        summary: equipment.summary,
        ds_user_full_name: `${equipment.field_ds_user?.[0].first} ${equipment.field_ds_user?.[0].last}`,
        hours_rate: params.rate,
      };

      dailysheetsAdapter.addOne(state, data);
    },
    [patchEquipment.pending](state, action) {
      state.error = null;
      const { params, row } = action.meta.arg;

      const data = {
        daily_sheet_nid: params.daily_sheet_nid,
        date: moment(params.date).format("X"),
        total: params.total,
        summary: "",
        ds_user_full_name: `${params.user?.first} ${params.user?.last}`,
        hours_rate: row.hours_rate,
      };

      dailysheetsAdapter.updateOne(state, {
        id: params.daily_sheet_nid,
        changes: data,
      });
    },
    [patchEquipment.fulfilled](state, { payload: equipment, meta }) {
      const { row } = meta.arg;

      const data = {
        daily_sheet_nid: equipment.nid,
        daily_sheet_node_type: "ds_equipment",
        category: "Equipment",
        date: moment(equipment.field_ds_date).format("X"),
        total: equipment.field_ds_total,
        summary: equipment.summary,
        ds_user_full_name: `${equipment.field_ds_user?.[0].first} ${equipment.field_ds_user?.[0].last}`,
        hours_rate: row.hours_rate,
      };

      dailysheetsAdapter.updateOne(state, { id: equipment.nid, changes: data });
    },
    [patchEquipment.rejected](state, action) {
      const { row } = action.meta.arg;

      dailysheetsAdapter.updateOne(state, {
        id: row.daily_sheet_nid,
        changes: row,
      });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchExpense.pending](state, action) {
      state.error = null;
      const { params } = action.meta.arg;

      const data = {
        daily_sheet_nid: params.daily_sheet_nid,
        date: moment(params.date).format("X"),
        total: params.total,
        summary: params.type,
        ds_user_full_name: `${params.user?.first} ${params.user?.last}`,
        hours_rate: params.total,
      };

      dailysheetsAdapter.updateOne(state, {
        id: params.daily_sheet_nid,
        changes: data,
      });
    },
    [patchExpense.fulfilled](state, { payload: expense }) {
      const data = {
        daily_sheet_nid: expense.nid,
        daily_sheet_node_type: "ds_expense",
        category: "Expense",
        date: moment(expense.field_ds_date).format("X"),
        total: expense.field_ds_total,
        summary: expense.field_ds_expense_type_text,
        ds_user_full_name: `${expense.field_ds_user?.[0].first} ${expense.field_ds_user?.[0].last}`,
        hours_rate: expense.field_ds_total,
      };

      dailysheetsAdapter.updateOne(state, { id: expense.nid, changes: data });
    },
    [patchExpense.rejected](state, action) {
      const { row } = action.meta.arg;

      dailysheetsAdapter.updateOne(state, {
        id: row.daily_sheet_nid,
        changes: row,
      });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchService.pending](state, action) {
      state.error = null;
      const { user, params } = action.meta.arg;

      const data = {
        daily_sheet_nid: params.daily_sheet_nid,
        date: moment(params.date).format("X"),
        total: params.total,
        summary: params.type,
        ds_user_full_name: user.name,
        hours_rate: params.total,
      };

      dailysheetsAdapter.updateOne(state, {
        id: params.daily_sheet_nid,
        changes: data,
      });
    },
    [patchService.fulfilled](state, { payload: service }) {
      const userName = service.user?.[0]
        ? `${service.user?.[0].first} ${service.user?.[0].last}`
        : service.tempUser?.[0]
        ? `${service.tempUser?.[0].name}`
        : null;

      const data = {
        daily_sheet_nid: service.nid,
        daily_sheet_node_type: "ds_service",
        category: "Labor",
        date: moment(service.date).format("X"),
        total: service.total,
        summary: service.summary,
        ds_user_full_name: userName,
        hours_rate: formatServiceSummary(service),
      };

      dailysheetsAdapter.updateOne(state, { id: service.nid, changes: data });
    },
    [patchService.rejected](state, action) {
      const { row } = action.meta.arg;

      dailysheetsAdapter.updateOne(state, {
        id: row.daily_sheet_nid,
        changes: row,
      });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postValidation.pending](state, action) {
      const { params } = action.meta.arg;
      dailysheetsDatesAdapter.updateOne(state.dates, {
        id: params.id,
        changes: { validated: "1" },
      });
    },
    [postValidation.rejected](state, action) {
      const { params } = action.meta.arg;
      dailysheetsDatesAdapter.updateOne(state.dates, {
        id: params.id,
        changes: { validated: "0" },
      });
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getDailysheetsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheets.loading
);

export const getDailysheetsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheets.pagination
);

export const getDailysheetsInvoicesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheets.invoices.loading
);

export const getDailysheetsInvoicesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheets.invoices.pagination
);

export const getDailysheetsInvoiceResourcesLoadingSelector =
  createDraftSafeSelector(
    selectSelf,
    (state) => state.dailysheets.invoiceResources.loading
  );

export const getDailysheetsMetaSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheets.overview.data
);

export const getDailysheetsMetaLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheets.overview.loading
);

export const getDailysheetsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheets.error
);

export const getDailysheetsDatesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheets.dates.loading
);

export const dailysheetsSelectors = dailysheetsAdapter.getSelectors(
  (state) => state.dailysheets
);

export const dailysheetsInvoicesSelectors =
  dailysheetsInvoicesAdapter.getSelectors(
    (state) => state.dailysheets.invoices
  );

export const dailysheetsInvoiceResourcesSelectors =
  dailysheetsInvoiceResourcesAdapter.getSelectors(
    (state) => state.dailysheets.invoiceResources
  );

export const dailysheetsDatesSelectors = dailysheetsDatesAdapter.getSelectors(
  (state) => state.dailysheets.dates
);

export const { clearAllDailysheets, clearAllInvoices, clearOverviewData } =
  dailysheetsSlice.actions;

export default dailysheetsSlice.reducer;
