import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutocompleteFormField, SelectFormField } from "common/Fields";
import {
  customersSelectors,
  fetchCustomers,
  getCustomersLoadingSelector,
} from "features/Customers/customersSlice";
import { size, toArray } from "lodash";
import {
  fetchLocations,
  getLocationsLoadingSelector,
  locationsSelectors,
} from "features/Locations/locationsSlice";
import { Grid, Typography } from "@mui/material";
import {
  fetchMembers,
  getMembersLoadingSelector,
  membersSelectors,
} from "features/Members/membersSlice";
import { TextFormField } from "common/Fields";
import QuickAdd from "features/Location/forms/QuickAdd";
import ClosestMembers from "../../components/ClosestMembers";
import Guard from "common/Guard";
import { jobDivisionTypesSelectors } from "features/Taxonomies/taxonomiesSlice";
import Toolbar from "common/Toolbar";

const ExistingCustomer = ({
  value,
  values,
  isSubmitting,
  handleNext,
  setFieldValue,
}) => {
  const dispatch = useDispatch();
  const customers = useSelector(customersSelectors.selectAll);
  const loadingCustomers = useSelector(getCustomersLoadingSelector);
  const locations = useSelector(locationsSelectors.selectAll);
  const loadingLocations = useSelector(getLocationsLoadingSelector);
  const members = useSelector(membersSelectors.selectAll);
  const loadingMembers = useSelector(getMembersLoadingSelector);
  const user = useSelector((state) => state.auth.user.data);
  const types = useSelector(jobDivisionTypesSelectors.selectAll);

  const getMembers = useCallback(
    (query) => {
      return dispatch(
        fetchMembers({
          keywords: query,
          "filter[phx_client_nids]": values.field_phoenix_client,
        })
      );
    },
    [dispatch, values.field_phoenix_client]
  );

  const getCustomers = useCallback(
    (query) => {
      return dispatch(
        fetchCustomers({
          keywords: query,
          "filter[phx_client_nid]": values.field_phoenix_client,
        })
      );
    },
    [dispatch, values.field_phoenix_client]
  );

  const getCustomerLocations = useCallback(
    (query) => {
      return dispatch(
        fetchLocations({
          id: values.field_customer?.nid,
          params: {
            keywords: query,
          },
        })
      );
    },
    [dispatch, values]
  );

  return (
    <div>
      <Typography variant="h6">Customer</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        This information is specific to the Customer Location or the Resident's
        Location.
      </Typography>
      <Guard intendedRoles={["admin", "phx_sub_admin", "phx_client_admin"]}>
        <Toolbar
          disablePadding
          left={
            <QuickAdd
              id={values.field_customer?.nid}
              setFieldValue={setFieldValue}
              clientId={values.field_phoenix_client}
            />
          }
          right={
            <ClosestMembers
              tid={values.field_job_division_type}
              cid={values.field_customer_location?.nid}
              clientId={values.field_phoenix_client}
              onClick={(value) => {
                setFieldValue("field_assigned_member", value);
              }}
            />
          }
        />
      </Guard>
      <Grid container spacing={2}>
        <Guard intendedRoles={["admin", "phx_sub_admin", "phx_client_admin"]}>
          <Grid item xxs={12} md={6}>
            <SelectFormField
              fullWidth
              name="field_job_division_type"
              label="Division Type"
              required
              size="small"
              variant="outlined"
              disabled={isSubmitting}
              options={types.map((type) => ({
                label: type.name,
                value: type.tid,
              }))}
            />
          </Grid>
        </Guard>
        {(size(user.field_members) > 1 || size(user.field_members) === 0) && (
          <Grid item xxs={12}>
            <AutocompleteFormField
              name="field_assigned_member"
              variant="outlined"
              label="Provider"
              size="small"
              disabled={isSubmitting}
              options={toArray(members).map((obj) => ({
                name: obj.member_name,
                nid: obj.member_nid,
              }))}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              loading={loadingMembers}
              fetchData={getMembers}
            />
          </Grid>
        )}
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_customer"
            label="Customer"
            disabled={isSubmitting}
            options={toArray(customers).map((cust) => ({
              name: cust.customer_name,
              title: cust.customer_name,
              nid: cust.customer_nid,
            }))}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            loading={loadingCustomers}
            fetchData={getCustomers}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_customer_location"
            label="Customer Location"
            helperText="Select a customer first"
            disabled={isSubmitting || !Boolean(values.field_customer)}
            options={toArray(locations).map((cust) => ({
              name: cust.customer_location_name,
              title: cust.customer_location_name,
              nid: cust.customer_location_nid,
            }))}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            loading={loadingLocations}
            fetchData={getCustomerLocations}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography variant="body1" color="textSecondary">
            Caller
          </Typography>
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_jd_mf_caller"
            label="Caller"
            disabled={isSubmitting}
            labelwidth={70}
            autoComplete="off"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <TextFormField
            fullWidth
            name="field_jd_mf_caller_phone"
            label="Caller Phone"
            disabled={isSubmitting}
            labelwidth={70}
            autoComplete="off"
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
    </div>
  );
};

ExistingCustomer.propTypes = {};

export default ExistingCustomer;
