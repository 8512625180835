import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { toArray } from "lodash";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { generateTempUUID } from "../../utility";
import { setAlert } from "features/Alert/alertSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "pricelist";

export const fetchPriceList = createAsyncThunk(
  `${namespace}/fetchPriceList`,
  async (
    { id, type, params },
    { getState, signal, rejectWithValue, dispatch }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/price-list/${id}${
          type ? `/${type}` : ""
        }${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const resources = toArray(response.data.resources.data);
      const subCategories = [];
      const items = [];
      const categories = resources
        .filter((c) => c.name !== "Legacy")
        .map((res) => {
          const uuid = res?.id ? res.id : generateTempUUID();
          const sub_categories = {};
          const subCategoryTids = toArray(res.sub_categories).reduce(
            (prev, subCat) => {
              const sub_category_items = [];
              const sub_uuid = subCat?.id ? subCat.id : generateTempUUID();
              const itemTids = toArray(subCat.items).reduce((prev, curr) => {
                const item_uuid = curr?.id ? curr.id : generateTempUUID();
                items.push({
                  ...curr,
                  id: item_uuid,
                  chase: curr.tid,
                  parentTid: sub_uuid,
                  type: (res.name === "Consumables & Materials" ? res.name : "Equipment"),
                  category: res.name,
                  categoryTid: uuid,
                  category_chase: res.tid,
                  sub_category: subCat.name,
                  sub_category_chase: subCat.tid,
                  qb_ids: curr.qb_ids ? curr.qb_ids : {},
                });
                sub_category_items.push({
                  ...curr,
                  id: item_uuid,
                  parentTid: sub_uuid,
                  categoryTid: uuid,
                  category_chase: res.tid,
                  sub_category_chase: subCat.tid,
                  sub_category: subCat.name,
                });
                return [...prev, item_uuid];
              }, []);

              const data = {
                id: sub_uuid,
                tid: subCat.tid,
                chase: subCat.tid,
                name: subCat.name,
                items: sub_category_items,
                itemTids: itemTids,
                parentTid: uuid,
                category_chase: res.tid,
              };

              sub_categories[sub_uuid] = data;
              subCategories.push(data);
              return [...prev, sub_uuid];
            },
            []
          );

          return {
            ...res,
            sub_categories,
            id: uuid,
            tid: res.tid,
            chase: res.tid,
            name: res.name,
            subCategoryTids,
          };
        });

      const expenses = toArray(response.data.expense_types.data).map((exp) => {
        const uuid = exp?.id ? exp.id : generateTempUUID();
        return { ...exp, id: uuid, qb_ids: exp.qb_ids ? exp.qb_ids : {} };
      });
      const laborTypes = toArray(response.data.service_types.data).map(
        (exp) => {
          const uuid = exp?.id ? exp.id : generateTempUUID();
          return { ...exp, id: uuid, qb_ids: exp.qb_ids ? exp.qb_ids : {} };
        }
      );
      const laborActivities = toArray(
        response.data.service_activities.data
      ).map((exp) => {
        const uuid = exp?.id ? exp.id : generateTempUUID();
        return { ...exp, id: uuid, qb_ids: exp.qb_ids ? exp.qb_ids : {} };
      });

      return {
        requestedType: type,
        data: {
          id: response.data?.id,
          title: response.data?.title,
          costAccess: response.data?.cost_access,
          inUse: response.data?.in_use,
          logChanges: false,
        },
        categories: {
          isGlobal: response.data?.resources?.is_global_default > 0,
          data: categories,
        },
        subCategories,
        items,
        expenseTypes: {
          data: expenses,
          isGlobal: response.data?.expense_types?.is_global_default > 0,
        },
        laborTypes: {
          data: laborTypes,
          isGlobal: response.data?.service_types?.is_global_default > 0,
        },
        laborActivities: {
          data: laborActivities,
          isGlobal: response.data?.service_activities?.is_global_default > 0,
        },
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error loading price list: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPriceListRefresh = createAsyncThunk(
  `${namespace}/fetchPriceListRefresh`,
  async ({ id, type, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/price-list/${id}${
          type ? `/${type}` : ""
        }${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const resources = toArray(response.data.resources.data);
      const subCategories = [];
      const items = [];
      const categories = resources
        .filter((c) => c.name !== "Legacy")
        .map((res) => {
          const uuid = res?.id ? res.id : generateTempUUID();
          const sub_categories = {};
          const subCategoryTids = toArray(res.sub_categories).reduce(
            (prev, curr) => {
              const sub_category_items = [];
              const sub_uuid = curr?.id ? curr.id : generateTempUUID();
              const itemTids = toArray(curr.items).reduce((prev, curr) => {
                const item_uuid = curr?.id ? curr.id : generateTempUUID();
                items.push({
                  ...curr,
                  id: item_uuid,
                  parentTid: sub_uuid,
                  categoryTid: uuid,
                  sub_category: curr.name,
                  qb_ids: curr.qb_ids ? curr.qb_ids : {},
                });
                sub_category_items.push({
                  ...curr,
                  id: item_uuid,
                  parentTid: sub_uuid,
                  categoryTid: uuid,
                  sub_category: curr.name,
                });
                return [...prev, item_uuid];
              }, []);

              const data = {
                id: sub_uuid,
                tid: curr.tid,
                name: curr.name,
                itemTids: itemTids,
                items: sub_category_items,
                parentTid: uuid,
              };

              sub_categories[sub_uuid] = data;
              subCategories.push(data);
              return [...prev, sub_uuid];
            },
            []
          );

          return {
            ...res,
            sub_categories,
            id: uuid,
            tid: res.tid,
            name: res.name,
            subCategoryTids,
          };
        });

      const expenses = toArray(response.data.expense_types.data).map((exp) => {
        const uuid = exp?.id ? exp.id : generateTempUUID();
        return { ...exp, id: uuid, qb_ids: exp.qb_ids ? exp.qb_ids : {} };
      });
      const laborTypes = toArray(response.data.service_types.data).map(
        (exp) => {
          const uuid = exp?.id ? exp.id :  generateTempUUID();
          return { ...exp, id: uuid, qb_ids: exp.qb_ids ? exp.qb_ids : {} };
        }
      );
      const laborActivities = toArray(
        response.data.service_activities.data
      ).map((exp) => {
        const uuid = exp?.id ? exp.id : generateTempUUID();
        return { ...exp, id: uuid, qb_ids: exp.qb_ids ? exp.qb_ids : {} };
      });

      return {
        data: {
          id: response.data?.id,
          title: response.data?.title,
          inUse: response.data?.in_use,
          logChanges: false,
        },
        categories: {
          isGlobal: response.data?.resources?.is_global_default > 0,
          data: categories,
        },
        subCategories,
        items,
        expenseTypes: {
          data: expenses,
          isGlobal: response.data?.expense_types?.is_global_default > 0,
        },
        laborTypes: {
          data: laborTypes,
          isGlobal: response.data?.service_types?.is_global_default > 0,
        },
        laborActivities: {
          data: laborActivities,
          isGlobal: response.data?.service_activities?.is_global_default > 0,
        },
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const categoriesAdapter = createEntityAdapter({
  selectId: (cat) => cat.id,
});

const subCategoriesAdapter = createEntityAdapter({
  selectId: (cat) => cat.id,
});

const itemsAdapter = createEntityAdapter({
  selectId: (cat) => cat.id,
});

const expenseTypesAdapter = createEntityAdapter({
  selectId: (cat) => cat.id,
});

const laborTypesAdapter = createEntityAdapter({
  selectId: (cat) => cat.id,
});

const laborActivitiesAdapter = createEntityAdapter({
  selectId: (cat) => cat.id,
});

const priceListSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    error: null,
    data: {},
    categories: categoriesAdapter.getInitialState({
      isGlobal: true,
    }),
    expenseTypes: expenseTypesAdapter.getInitialState({
      isGlobal: true,
    }),
    laborTypes: laborTypesAdapter.getInitialState({
      isGlobal: true,
    }),
    laborActivities: laborActivitiesAdapter.getInitialState({
      isGlobal: true,
    }),
    subCategories: subCategoriesAdapter.getInitialState({}),
    items: itemsAdapter.getInitialState({}),
  },
  reducers: {
    clearPriceListData: (state, { payload }) => {
      state.data = {};
    },
    updateOneCategory: (state, { payload }) => {
      categoriesAdapter.updateOne(state.categories, payload);
    },
    addOneCategory: (state, { payload }) => {
      categoriesAdapter.addOne(state.categories, payload);
    },
    removeManyCategories: (state, { payload }) => {
      categoriesAdapter.removeMany(state.categories, payload);
    },
    updateOneExpenseType: (state, { payload }) => {
      expenseTypesAdapter.updateOne(state.expenseTypes, payload);
    },
    removeOneExpenseType: (state, { payload }) => {
      expenseTypesAdapter.removeOne(state.expenseTypes, payload);
    },
    setAllExpenseTypes: (state, { payload }) => {
      expenseTypesAdapter.setAll(state.expenseTypes, payload);
    },
    addOneExpenseType: (state, { payload }) => {
      expenseTypesAdapter.addOne(state.expenseTypes, payload);
    },
    removeManyExpenseTypes: (state, { payload }) => {
      expenseTypesAdapter.removeMany(state.expenseTypes, payload);
    },
    updateOneLaborType: (state, { payload }) => {
      laborTypesAdapter.updateOne(state.laborTypes, payload);
    },
    setAllLaborTypes: (state, { payload }) => {
      laborTypesAdapter.setAll(state.laborTypes, payload);
    },
    addOneLaborType: (state, { payload }) => {
      laborTypesAdapter.addOne(state.laborTypes, payload);
    },
    removeManyLaborTypes: (state, { payload }) => {
      laborTypesAdapter.removeMany(state.laborTypes, payload);
    },
    updateOneLaborActivity: (state, { payload }) => {
      laborActivitiesAdapter.updateOne(state.laborActivities, payload);
    },
    setAllLaborActivities: (state, { payload }) => {
      laborActivitiesAdapter.setAll(state.laborActivities, payload);
    },
    addOneLaborActivity: (state, { payload }) => {
      laborActivitiesAdapter.addOne(state.laborActivities, payload);
    },
    removeManyLaborActivities: (state, { payload }) => {
      laborActivitiesAdapter.removeMany(state.laborActivities, payload);
    },
    updateOneSubCategory: (state, { payload }) => {
      subCategoriesAdapter.updateOne(state.subCategories, payload);
    },
    addOneSubCategory: (state, { payload }) => {
      subCategoriesAdapter.addOne(state.subCategories, payload);
    },
    removeManySubCategories: (state, { payload }) => {
      subCategoriesAdapter.removeMany(state.subCategories, payload);
    },
    updateOneItem: (state, { payload }) => {
      itemsAdapter.updateOne(state.items, payload);
    },
    setAllItems: (state, { payload }) => {
      itemsAdapter.setAll(state.items, payload);
    },
    addOneItem: (state, { payload }) => {
      itemsAdapter.addOne(state.items, payload);
    },
    removeManyItems: (state, { payload }) => {
      itemsAdapter.removeMany(state.items, payload);
    },
    setLogChanges: (state, { payload }) => {
      state.data.logChanges = payload;
    },
    removeAllCategories: categoriesAdapter.removeAll,
    removeAllSubCategories: subCategoriesAdapter.removeAll,
    removeAllExpenseTypes: expenseTypesAdapter.removeAll,
    removeAllLaborTypes: laborTypesAdapter.removeAll,
    removeAllLaborActivities: laborActivitiesAdapter.removeAll,
  },
  extraReducers: {
    [fetchPriceList.pending](state) {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
    [fetchPriceList.fulfilled](
      state,
      {
        payload: {
          requestedType,
          data,
          categories,
          subCategories,
          items,
          expenseTypes,
          laborTypes,
          laborActivities,
        },
      }
    ) {
      state.loading = false;
      state.data = data;
      if(!requestedType || requestedType === 'resources') {
        state.categories.isGlobal = categories.isGlobal;
        categoriesAdapter.setAll(state.categories, categories.data);
        subCategoriesAdapter.setAll(state.subCategories, subCategories);
        itemsAdapter.setAll(state.items, items);
      }
      if(!requestedType || requestedType === 'expense_types') {
        state.expenseTypes.isGlobal = expenseTypes.isGlobal;
        expenseTypesAdapter.setAll(state.expenseTypes, expenseTypes.data);
      }
      if(!requestedType || requestedType === 'service_types') {
        state.laborTypes.isGlobal = laborTypes.isGlobal;
        laborTypesAdapter.setAll(state.laborTypes, laborTypes.data);
      }
      if(!requestedType || requestedType === 'service_activities') {
        state.laborActivities.isGlobal = laborActivities.isGlobal;
        laborActivitiesAdapter.setAll(
          state.laborActivities,
          laborActivities.data
        );
      }
    },
    [fetchPriceList.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        state.data = {};
        categoriesAdapter.removeAll(state.categories);
        subCategoriesAdapter.removeAll(state.subCategories);
        itemsAdapter.removeAll(state.items);
        expenseTypesAdapter.removeAll(state.expenseTypes);
        laborTypesAdapter.removeAll(state.laborTypes);
        laborActivitiesAdapter.removeAll(state.laborActivities);
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchPriceListRefresh.pending](state) {
      state.error = null;
    },
    [fetchPriceListRefresh.fulfilled](
      state,
      {
        payload: {
          data,
          categories,
          subCategories,
          items,
          expenseTypes,
          laborTypes,
          laborActivities,
        },
      }
    ) {
      state.loading = false;
      state.data = data;
      state.categories.isGlobal = categories.isGlobal;
      state.expenseTypes.isGlobal = expenseTypes.isGlobal;
      state.laborTypes.isGlobal = laborTypes.isGlobal;
      state.laborActivities.isGlobal = laborActivities.isGlobal;
      categoriesAdapter.setAll(state.categories, categories.data);
      subCategoriesAdapter.setAll(state.subCategories, subCategories);
      itemsAdapter.setAll(state.items, items);
      expenseTypesAdapter.setAll(state.expenseTypes, expenseTypes.data);
      laborTypesAdapter.setAll(state.laborTypes, laborTypes.data);
      laborActivitiesAdapter.setAll(
        state.laborActivities,
        laborActivities.data
      );
    },
    [fetchPriceListRefresh.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getPriceListLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.pricelist.loading
);

export const getPriceListErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.pricelist.error
);

export const getPriceListDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.pricelist.data
);

export const categoriesSelectors = categoriesAdapter.getSelectors(
  (state) => state.pricelist.categories
);

export const subCategoriesSelectors = subCategoriesAdapter.getSelectors(
  (state) => state.pricelist.subCategories
);

export const itemsSelectors = itemsAdapter.getSelectors(
  (state) => state.pricelist.items
);

export const expenseTypesSelectors = expenseTypesAdapter.getSelectors(
  (state) => state.pricelist.expenseTypes
);

export const laborTypesSelectors = laborTypesAdapter.getSelectors(
  (state) => state.pricelist.laborTypes
);

export const laborActivitiesSelectors = laborActivitiesAdapter.getSelectors(
  (state) => state.pricelist.laborActivities
);

export const {
  updateOneCategory,
  addOneCategory,
  removeManyCategories,
  updateOneExpenseType,
  removeOneExpenseType,
  setAllExpenseTypes,
  addOneExpenseType,
  removeManyExpenseTypes,
  updateOneLaborType,
  setAllLaborTypes,
  addOneLaborType,
  removeManyLaborTypes,
  setAllLaborActivities,
  updateOneLaborActivity,
  addOneLaborActivity,
  removeManyLaborActivities,
  updateOneSubCategory,
  addOneSubCategory,
  removeManySubCategories,
  updateOneItem,
  setAllItems,
  addOneItem,
  removeManyItems,
  clearPriceListData,
  setLogChanges,
  removeAllCategories,
  removeAllSubCategories,
  removeAllExpenseTypes,
  removeAllLaborTypes,
  removeAllLaborActivities,
} = priceListSlice.actions;

export default priceListSlice.reducer;
