import {
  createSlice,
  createEntityAdapter,
  // createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";

const namespace = "hub";

const hubEnvironmentAdapter = createEntityAdapter({
  selectId: (result) => result.nid,
});

const hubSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    pagination: null,
    environments: hubEnvironmentAdapter.getInitialState({}),
    currentEnvironment: {},
  },
  reducers: {
    setEnvironments: (state, action) => {
      hubEnvironmentAdapter.setAll(state.environments, action.payload);
    },
    setCurrentEnvironment: (state, action) => {
      state.currentEnvironment = action.payload;
      localStorage.setItem('hub_environment', action.payload.nid)
    },
   clearEnvironments: (state, action) => {
      state.loading = false;
      state.pagination = null;
      hubEnvironmentAdapter.removeAll(state.environments);
      state.currentEnvironment = {};
    },
  },
  extraReducers: {},
});

// Custom selectors
const selectSelf = (state) => state;
export const getHubLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.hub.loading
);

export const getHubPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.hub.pagination
);

export const getHubEnvironmentSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.hub.currentEnvironment
);

export const hubEnvironmentSelectors = hubEnvironmentAdapter.getSelectors((state) => state.hub.environments);

export const { setEnvironments, setCurrentEnvironment, clearEnvironments } = hubSlice.actions;

export const parseHubEnvParams = (params, getState) => {
  params.environment_nid = getState().hub?.currentEnvironment?.nid;
  params.environment_type = getState().hub?.currentEnvironment?.type;
}

export default hubSlice.reducer;
