import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { removePathFromUrl } from "../../utility";
import Dialog from "./Dialog";

const ComponentBody = ({
  onExit,
  fullscreen,
  parsedUrl,
  maxWidth,
  title,
  children,
  url,
  ...props
}) => {
  const history = useHistory();

  // useEffect(() => {
  //   return () => {
  //     if (onExit) {
  //       onExit();
  //     }
  //   };
  // }, []);

  const handleClose = () => {
    history.push({
      pathname: url,
      search: history.location.search,
    });

    if (onExit) {
      onExit();
    }
  };

  return (
    <Dialog
      open={true}
      fillScreen={fullscreen}
      onClose={handleClose}
      maxWidth={maxWidth}
      title={title}
      {...props}
    >
      {children}
    </Dialog>
  );
};

const RouteDialog = ({
  url,
  path,
  maxWidth,
  title,
  fullscreen,
  children,
  route,
  onExit,
  ...props
}) => {
  const [parsedUrl, setParsedUrl] = React.useState(url);

  useEffect(() => {
    if (url.indexOf(path) >= 0) {
      setParsedUrl(removePathFromUrl(url, path, true));
    }
  }, [url, path]);

  return (
    <Route path={`${parsedUrl}/${route}`}>
      {({ match }) => (
        <CSSTransition
          in={match != null}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <ComponentBody
            fillScreen={fullscreen}
            maxWidth={maxWidth}
            title={title}
            onExit={onExit}
            url={parsedUrl}
            {...props}
          >
            {children}
          </ComponentBody>
        </CSSTransition>
      )}
    </Route>
  );
};

RouteDialog.propTypes = {};

export default RouteDialog;
