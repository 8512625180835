const reducer =
  (type, initialData) =>
  (
    state = {
      loading: false,
      data: initialData || [],
    },
    action
  ) => {
    switch (action.type) {
      case `REQUEST/${type}`:
        return {
          ...state,
          error: false,
          loading: true,
          cancel: action?.cancel,
        };
      case `OK/${type}`:
        return {
          loading: false,
          data: action.payload,
          error: false,
          cancel: null,
        };
      case `ERROR/${type}`:
        return {
          data: [],
          error: action.error || true,
          loading: false,
          cancel: null,
        };
      case `QUERY/${type}`:
        return {
          ...state,
          query: action.query,
        };
      case `CLEAR/${type}`:
        if(state?.cancel){
          state?.cancel.cancel('Operation canceled by the user.');
        }
        return {
          loading: false,
          data: [],
        };
      default:
        return state;
    }
  };

export default reducer;
