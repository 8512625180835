import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  getAdvertisements: async ({ config }) => {
    return instance.get(`/advertisements`, config);
  },
  getAdminAdvertisements: async ({ config }) => {
    return instance.get(`/advertisements/all`, config);
  },
  addAdvertisement: async ({ params, config }) => {
    return instance.post(`/advertisements`, params, config);
  },
  updateAdvertisement: async ({ id, params, config }) => {
    return instance.patch(`/advertisements/${id}`, params, config);
  },
  addAdmin: async ({ id, params, config }) => {
    return instance.patch(`/advertisements/${id}/add-admin`, params, config);
  },
  removeAdmin: async ({ id, params, config }) => {
    return instance.patch(`/advertisements/${id}/remove-admin`, params, config);
  },
  deleteAdvertisement: async ({ id, config }) => {
    return instance.delete(`/advertisements/${id}`, config);
  },
};

export default API;
