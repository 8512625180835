import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "quickbooks";

export const fetchQuickbooksAccounts = createAsyncThunk(
  `${namespace}/fetchQuickbooksAccounts`,
  async ({ id, params }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/quickbooks/accounts/${id}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuickbooksInvoice = createAsyncThunk(
  `${namespace}/postQuickbooksInvoice`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/quickbooks/invoice`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully added Invoice to Quickbooks`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Invoice to Quickbooks: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuickbooksCustomerCards = createAsyncThunk(
  `${namespace}/fetchQuickbooksCustomerCards`,
  async ({ member, customer }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/quickbooks/payment/card/list/${member}/${customer}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuickbooksCardVerification = createAsyncThunk(
  `${namespace}/postQuickbooksCardVerification`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.qb_url}/quickbooks/v4/payments/tokens`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuickbooksCardSave = createAsyncThunk(
  `${namespace}/postQuickbooksCardSave`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/quickbooks/payment/card/save`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully saved card`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchQuickbooksPayInvoice = createAsyncThunk(
  `${namespace}/patchQuickbooksPayInvoice`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/quickbooks/invoice/payment`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully payed invoice`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuickbooksItem = createAsyncThunk(
  `${namespace}/postQuickbooksItem`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/quickbooks/item`,
        {
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully synced price list and Quickbooks`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error syncing price list and Quickbooks : ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuickbooksItemListing = createAsyncThunk(
  `${namespace}/fetchQuickbooksItemListing`,
  async ({ member, inc, pricelist }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/quickbooks/item/${member}/${inc}/${pricelist}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return { results: response.data.data, meta: response.data.meta };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuickbooksEstimates = createAsyncThunk(
  `${namespace}/fetchQuickbooksEstimates`,
  async ({ id, params }, { getState, dispatch, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/quickbooks/estimate/list/${id}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error fetching Estimates: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuickbooksEstimateItems = createAsyncThunk(
  `${namespace}/fetchQuickbooksEstimateItems`,
  async (
    { estimate, inc, division },
    { getState, rejectWithValue, signal }
  ) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/quickbooks/estimate/items/${estimate}/${division}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return response.data.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuickbooksInvoices = createAsyncThunk(
  `${namespace}/fetchQuickbooksInvoices`,
  async ({ id, params }, { getState, dispatch, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/quickbooks/invoice/list/${id}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error fetching Invoices: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuickbooksEstimateImport = createAsyncThunk(
  `${namespace}/postQuickbooksEstimateImport`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/quickbooks/estimate`,
        {
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully added Import Estimate`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Import Estimate: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuickbooksAccessUrls = createAsyncThunk(
  `${namespace}/fetchQuickbooksAccessUrls`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/quickbooks/access/urls/${id}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuickbooksImportInvoice = createAsyncThunk(
  `${namespace}/postQuickbooksImportInvoice`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      await axios.post(
        `${config.api_url}/rest/quickbooks/invoice`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully imported invoice from Quickbooks`,
        })
      );

      return params.qb_invoice_id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error import invoice from Quickbooks : ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postQuickbooksImportEstimate = createAsyncThunk(
  `${namespace}/postQuickbooksImportEstimate`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      await axios.post(
        `${config.api_url}/rest/quickbooks/estimate`,
        params,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully imported estimate from Quickbooks`,
        })
      );

      return params.qb_invoice_id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error import estimate from Quickbooks : ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuickbooksTaxRates = createAsyncThunk(
  `${namespace}/fetchQuickbooksTaxRates`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.api_url}/rest/quickbooks/tax-rates/${id}`,
        tokenConfig(getState)
      );

      return response.data.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const quickbooksAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const quickbooksTaxAdapter = createEntityAdapter({
  selectId: (result) => result.qb_id,
});

const quickbooksSlice = createSlice({
  name: namespace,
  initialState: {
    accounts: quickbooksAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    cards: quickbooksAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    items: quickbooksAdapter.getInitialState({
      loading: false,
      error: null,
      meta: {
        next_inc: null,
      },
    }),
    estimates: quickbooksAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    invoices: quickbooksAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    estimateItems: quickbooksAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    taxRates: quickbooksTaxAdapter.getInitialState({
      loading: false,
      error: null,
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchQuickbooksAccounts.pending](state, action) {
      state.accounts.loading = true;
      state.accounts.error = null;
      quickbooksAdapter.removeAll(state.accounts);
    },
    [fetchQuickbooksAccounts.fulfilled](state, { payload: results }) {
      quickbooksAdapter.setAll(state.accounts, results);
      state.accounts.loading = false;
    },
    [fetchQuickbooksAccounts.rejected](state, action) {
      if (!action.meta.aborted) {
        state.accounts.loading = false;
        if (action.payload) {
          state.accounts.error = action.payload.message;
        } else {
          state.accounts.error = action.error.message;
        }
      }
    },
    [fetchQuickbooksCustomerCards.pending](state, action) {
      state.cards.loading = true;
      state.cards.error = null;
      quickbooksAdapter.removeAll(state.cards);
    },
    [fetchQuickbooksCustomerCards.fulfilled](state, { payload: results }) {
      quickbooksAdapter.setAll(state.cards, results);
      state.cards.loading = false;
    },
    [fetchQuickbooksCustomerCards.rejected](state, action) {
      if (!action.meta.aborted) {
        state.cards.loading = false;
        if (action.payload) {
          state.cards.error = action.payload.message;
        } else {
          state.cards.error = action.error.message;
        }
      }
    },
    [fetchQuickbooksItemListing.pending](state, action) {
      state.items.loading = true;
      state.items.error = null;
      state.items.meta = { next_inc: null };
      quickbooksAdapter.removeAll(state.items);
    },
    [fetchQuickbooksItemListing.fulfilled](
      state,
      { payload: { results, meta } }
    ) {
      quickbooksAdapter.setAll(state.items, results);
      state.items.loading = false;
      state.items.meta = meta;
    },
    [fetchQuickbooksItemListing.rejected](state, action) {
      if (!action.meta.aborted) {
        state.items.loading = false;
        if (action.payload) {
          state.items.error = action.payload.message;
        } else {
          state.items.error = action.error.message;
        }
      }
    },
    [fetchQuickbooksEstimates.pending](state, action) {
      state.estimates.loading = true;
      state.estimates.error = null;
      quickbooksAdapter.removeAll(state.estimates);
    },
    [fetchQuickbooksEstimates.fulfilled](state, { payload: results }) {
      quickbooksAdapter.setAll(state.estimates, results);
      state.estimates.loading = false;
    },
    [fetchQuickbooksEstimates.rejected](state, action) {
      if (!action.meta.aborted) {
        state.estimates.loading = false;
        if (action.payload) {
          state.estimates.error = action.payload.message;
        } else {
          state.estimates.error = action.error.message;
        }
      }
    },
    [fetchQuickbooksInvoices.pending](state, action) {
      state.invoices.loading = true;
      state.invoices.error = null;
      quickbooksAdapter.removeAll(state.invoices);
    },
    [fetchQuickbooksInvoices.fulfilled](state, { payload: results }) {
      quickbooksAdapter.setAll(state.invoices, results);
      state.invoices.loading = false;
    },
    [fetchQuickbooksInvoices.rejected](state, action) {
      if (!action.meta.aborted) {
        state.invoices.loading = false;
        if (action.payload) {
          state.invoices.error = action.payload.message;
        } else {
          state.invoices.error = action.error.message;
        }
      }
    },
    [fetchQuickbooksEstimateItems.pending](state, action) {
      state.estimateItems.loading = true;
      state.estimateItems.error = null;
      quickbooksAdapter.removeAll(state.estimateItems);
    },
    [fetchQuickbooksEstimateItems.fulfilled](state, { payload: results }) {
      quickbooksAdapter.setAll(state.estimateItems, results);
      state.estimateItems.loading = false;
    },
    [fetchQuickbooksEstimateItems.rejected](state, action) {
      if (!action.meta.aborted) {
        state.estimateItems.loading = false;
        if (action.payload) {
          state.estimateItems.error = action.payload.message;
        } else {
          state.estimateItems.error = action.error.message;
        }
      }
    },
    [postQuickbooksImportInvoice.fulfilled](state, { payload: id }) {
      quickbooksAdapter.removeOne(state.invoices, id);
    },
    [fetchQuickbooksTaxRates.pending](state, action) {
      state.taxRates.loading = true;
      state.taxRates.error = null;
      quickbooksTaxAdapter.removeAll(state.taxRates);
    },
    [fetchQuickbooksTaxRates.fulfilled](state, { payload: results }) {
      quickbooksTaxAdapter.setAll(state.taxRates, results);
      state.taxRates.loading = false;
    },
    [fetchQuickbooksTaxRates.rejected](state, action) {
      if (!action.meta.aborted) {
        state.taxRates.loading = false;
        if (action.payload) {
          state.taxRates.error = action.payload.message;
        } else {
          state.taxRates.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getQuickbooksLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.loading
);

export const getQuickbooksPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.pagination
);

export const getQuickbooksAccountsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.accounts.loading
);

export const getQuickbooksAccountsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.accounts.error
);

export const getQuickbooksCardsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.cards.loading
);

export const getQuickbooksCardsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.cards.error
);

export const getQuickbooksItemsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.items.loading
);

export const getQuickbooksItemsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.items.error
);

export const getQuickbooksEstimatesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.estimates.loading
);

export const getQuickbooksEstimatesErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.estimates.error
);

export const getQuickbooksInvoicesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.invoices.loading
);

export const getQuickbooksInvoicesErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.invoices.error
);

export const getQuickbooksEstimateItemsLoadingSelector =
  createDraftSafeSelector(
    selectSelf,
    (state) => state.quickbooks.estimateItems.loading
  );

export const getQuickbooksTaxErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.taxRates.error
);

export const getQuickbooksTaxLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.taxRates.loading
);

export const getQuickbooksEstimateItemsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.quickbooks.estimateItems.error
);

export const quickbooksAccountsSelectors = quickbooksAdapter.getSelectors(
  (state) => state.quickbooks.accounts
);

export const quickbooksCardsSelectors = quickbooksAdapter.getSelectors(
  (state) => state.quickbooks.cards
);

export const quickbooksItemsSelectors = quickbooksAdapter.getSelectors(
  (state) => state.quickbooks.items
);

export const quickbooksEstimatesSelectors = quickbooksAdapter.getSelectors(
  (state) => state.quickbooks.estimates
);

export const quickbooksEstimateItemsSelectors = quickbooksAdapter.getSelectors(
  (state) => state.quickbooks.estimateItems
);

export const quickbooksInvoicesSelectors = quickbooksAdapter.getSelectors(
  (state) => state.quickbooks.invoices
);

export const quickbooksTaxRatesSelectors = quickbooksTaxAdapter.getSelectors(
  (state) => state.quickbooks.taxRates
);

export default quickbooksSlice.reducer;
