import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import ButtonLoader from "common/ButtonLoader";
import Alert from "common/Alert";

const ConfirmationDialog = ({
  open,
  onClose,
  message,
  title,
  body,
  onSubmit,
  isSubmitting,
  submitButton,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="note-confirmation-dialog-title"
      aria-describedby="note-confirmation-dialog-description"
    >
      <DialogTitle id="note-confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {message && <Alert kind={message.id}>{message.msg}</Alert>}
        <DialogContentText id="note-confirmation-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <ButtonLoader
          variant="contained"
          color="primary"
          type="submit"
          isSubmitting={isSubmitting}
          disableElevation
          size="small"
          onClick={onSubmit}
        >
          {submitButton}
        </ButtonLoader>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {};

export default ConfirmationDialog;
