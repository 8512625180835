import styled from "styled-components";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

export const Icon = styled(ChevronRight)`
  transition: transform 0.25s !important;
  margin-left: ${({ sizes, theme }) =>
    theme.reverse ? `-${sizes.marginLeft}` : sizes.marginLeft};
  width: ${({ sizes }) => sizes.width};
  height: ${({ sizes }) => sizes.height};
  transform: translateX(0)
    rotate(${({ theme }) => (theme.reverse ? "180deg" : "0")});
`;

export const Wrapper = styled.div`
  display: inline-block;
  color: var(--color-black);
`;

export const LinkTag = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ color }) => color};
  font-size: 1.25rem;
  font-weight: 600;

  &:hover > ${Icon} {
    transform: translateX(${({ theme }) => (theme.reverse ? "5px" : "-5px")})
      rotate(${({ theme }) => (theme.reverse ? "180deg" : "0")});
  }
`;

export const ATag = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ color }) => color};
  font-size: 1.25rem;
  font-weight: 600;

  &:hover > ${Icon} {
    transform: translateX(${({ theme }) => (theme.reverse ? "5px" : "-5px")})
      rotate(${({ theme }) => (theme.reverse ? "180deg" : "0")});
  }
`;

export const Text = styled.div`
  font-size: ${({ sizes }) => sizes.fontSize};
  text-overflow: ${({ noWrap }) => (noWrap ? "ellipsis" : "unset")};
  overflow: ${({ noWrap }) => (noWrap ? "hidden" : "unset")};
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "unset")};
  text-transform: ${({ uppercase }) =>
    uppercase ? "uppercase" : "capitalize"};
`;
