import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import {
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment";

import Information from "./Information";
import Resident from "./Resident";
import Details from "./Details";
import Alert from "features/Alert";
import {
  addResidentialJobSchema,
  addExistingResidentialJobSchema,
} from "schemas/forms/job";

import { postCustomer } from "features/Customer/customerSlice";
import { postLocation } from "features/Location/locationSlice";
import { postJob, postJobDivision } from "../../jobSlice";
import { fetchGeoAddressCoords } from "features/Geo/geoSlice";
import {
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import { first, size } from "lodash";
import ExistingCustomer from "./ExistingCustomer";

const round = (date, duration, method) => {
  return moment(Math[method](+date / +duration) * +duration);
};

const AddJob = ({ clientId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const user = useSelector((state) => state.auth.user.data);

  const [existing, setExisting] = useState(false);
  const [progress, setProgress] = useState({ show: false, message: "" });

  const handleCreateCustomer = async (data) => {
    try {
      setProgress({ show: true, message: "Creating Customer" });
      const resultAction = await dispatch(
        postCustomer({
          title: [{ value: data.customer_location_title }],
          field_phoenix_client: [{ target_id: data.field_phoenix_client }],
          field_customer_status: [{ value: "active" }],
          field_referred_by_text: [{ value: data.field_referred_by_text }],
          field_referred_by_phone: [{ value: data.field_referred_by_phone }],
          field_street_address: [data.field_street_address],
          field_res_com: [{ value: data.field_res_com }],
        })
      );

      unwrapResult(resultAction);
      return {
        nid: resultAction.payload.nid,
        title: resultAction.payload.title,
      };
    } catch (err) {
      setProgress({ show: false, message: "" });
      return false;
    }
  };

  const handleVerifyAddress = async (data) => {
    try {
      setProgress({ show: true, message: "Verifying Address" });
      const resultAction = await dispatch(
        fetchGeoAddressCoords({
          address: `${data.field_street_address.address_line1}, ${data.field_street_address.locality}, ${data.field_street_address.administrative_area} ${data.field_street_address.postal_code}`,
        })
      );

      unwrapResult(resultAction);
      return resultAction.payload;
    } catch (err) {
      setProgress({ show: false, message: "" });
      return false;
    }
  };

  const handleCreateLocation = async (customer, countyId, data) => {
    try {
      setProgress({ show: true, message: "Creating Location" });
      const resultAction = await dispatch(
        postLocation({
          id: customer.nid,
          params: {
            title: [{ value: data.customer_location_title }],
            field_county: [{ target_id: countyId }],
            field_phone: [{ value: data.customer_location_field_phone }],
            field_phone_after_hours: [
              { value: data.customer_location_field_phone_after_hours },
            ],
            field_cl_email: [{ value: data.field_cl_email }],
            field_preferred_member: [
              {
                target_id: data.field_assigned_member.nid,
              },
            ],
            field_cl_year_built: [{ value: data.field_cl_year_built }],
            field_street_address: [data.field_street_address],
            field_pref_contact_method: [
              { value: data.field_pref_contact_method },
            ],
          },
        })
      );

      unwrapResult(resultAction);
      return resultAction.payload.nid;
    } catch (err) {
      setProgress({ show: false, message: "" });
      return false;
    }
  };

  const handleCreateJob = async (customer, location, data) => {
    try {
      setProgress({ show: true, message: "Creating Job" });
      const resultAction = await dispatch(
        postJob({
          field_phoenix_client: [{ target_id: data.field_phoenix_client }],
          field_customer: [{ target_id: customer.nid }],
          field_customer_name: [{ value: customer.title }],
          field_customer_location: [{ target_id: location }],
          field_date: [{ value: moment().format("YYYY-MM-DD") }],
        })
      );

      unwrapResult(resultAction);
      return resultAction.payload.nid;
    } catch (err) {
      setProgress({ show: false, message: "" });
      return false;
    }
  };

  const handleCreateJobDivision = async (job, data) => {
    try {
      setProgress({ show: true, message: "Creating Job Division" });
      const etaStart = moment(
        moment(data.field_jd_eta_range.date).format("YYYY-MM-DD") +
          "T" +
          data.field_jd_eta_range.value.value
      ).format();
      const etaEnd = moment(
        moment(data.field_jd_eta_range.date).format("YYYY-MM-DD") +
          "T" +
          data.field_jd_eta_range.end_value.value
      ).format();

      const type = types.find((type) => type.name === "Water");
      const status = statuses.find((status) => status.name === "Dispatch");

      const resultAction = await dispatch(
        postJobDivision({
          id: job,
          params: {
            field_job_division_type: [{ target_id: type?.tid }],
            field_jd_status: [{ target_id: status?.tid }],
            field_assigned_member: [
              {
                target_id: data.field_assigned_member.nid,
              },
            ],
            field_jd_mf_caller: [{ value: data.field_jd_mf_caller }],
            field_jd_mf_caller_phone: [
              { value: data.field_jd_mf_caller_phone },
            ],
            field_eta_datetime: [{ value: etaStart }],
            field_jd_eta_range: [
              {
                value: etaStart,
                end_value: etaEnd,
              },
            ],
            field_loss_date: [
              { value: data.field_loss_date.format("YYYY-MM-DD") },
            ],
            field_cause_select: [{ value: data.field_cause_select }],
            field_cause: [{ value: data.field_cause }],
            field_run_time: [
              {
                value: `${data.field_run_time_text} ${data.field_run_time_select}`,
              },
            ],
            field_mf_effected_level_qty: [
              { value: data.field_mf_effected_level_qty },
            ],
            field_mf_hw_wet: [{ value: data.field_mf_hw_wet }],
            field_mf_hw_wet_sqft: [{ value: data.field_mf_hw_wet_sqft }],
            field_details: [{ value: data.field_details }],
          },
        })
      );

      unwrapResult(resultAction);
      return resultAction.payload.self;
    } catch (err) {
      setProgress({ show: false, message: "" });
      return false;
    }
  };

  const handleCreateExistingJob = async (data) => {
    try {
      setProgress({ show: true, message: "Creating Job" });
      const params = {
        field_phoenix_client: [{ target_id: data.field_phoenix_client }],
        field_customer: [{ target_id: data.field_customer.nid }],
        field_customer_name: [{ value: data.field_customer.name }],
        field_customer_location: [
          { target_id: data.field_customer_location.nid },
        ],
        field_date: [{ value: moment(data.field_date).format("YYYY-MM-DD") }],
      };

      const resultAction = await dispatch(postJob(params));

      unwrapResult(resultAction);
      return resultAction.payload.nid;
    } catch (err) {
      setProgress({ show: false, message: "" });
      return false;
    }
  };

  const handleCreateExistingCustomerJob = async (data) => {
    const job = await handleCreateExistingJob(data);
    if (!job) return;
    const division = await handleCreateJobDivision(job, data);
    if (!division) return;

    history.push(division);
  };

  const handleSubmit = async (data) => {
    if (data.existingCustomer) {
      handleCreateExistingCustomerJob(data);
    } else {
      const customer = await handleCreateCustomer(data);
      if (!customer) return;
      const geo = await handleVerifyAddress(data);
      if (!geo) return;
      const location = await handleCreateLocation(
        customer,
        geo.county_tid,
        data
      );
      if (!location) return;
      const job = await handleCreateJob(customer, location, data);
      if (!job) return;
      const division = await handleCreateJobDivision(job, data);
      if (!division) return;
      history.push(division);
    }
  };

  return (
    <Formik
      initialValues={{
        existingCustomer: false,
        field_phoenix_client: clientId,
        field_assigned_member:
          size(user.field_members) > 1 || size(user.field_members) === 0
            ? null
            : first(user.field_members),
        field_referred_by_text: "",
        field_referred_by_phone: "",
        field_jd_mf_caller: "",
        field_jd_mf_caller_phone: "",
        field_res_com: "residential",
        field_street_address: {
          address_line1: "",
          address_line2: "",
          locality: "",
          administrative_area: "",
          postal_code: "",
          country_code: "US",
        },
        customer_location_title: "",
        field_cl_year_built: "",
        customer_location_field_phone: "",
        customer_location_field_phone_after_hours: "",
        field_cl_email: "",
        field_pref_contact_method: "phone_call",
        field_loss_date: moment(),
        field_jd_eta_range: {
          date: moment(),
          value: {
            name: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("h:mm A"),
            value: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("HH:mm"),
          },
          end_value: {
            name: round(moment(), moment.duration(15, "minutes"), "ceil")
              .add(1, "h")
              .format("h:mm A"),
            value: round(moment(), moment.duration(15, "minutes"), "ceil")
              .add(2, "h")
              .format("HH:mm"),
          },
        },
        field_cause: "",
        field_cause_select: "",
        field_run_time_text: "",
        field_run_time_select: "Hours",
        field_mf_effected_level_qty: "",
        field_mf_hw_wet: false,
        field_mf_hw_wet_sqft: "",
        field_details: "",
        existing_customer: "no",
        field_customer: null,
        field_customer_location: null,
      }}
      validationSchema={
        existing ? addExistingResidentialJobSchema : addResidentialJobSchema
      }
      onSubmit={async (data, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        await handleSubmit(data);
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        validateForm,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form>
          <div
            style={{
              position: "relative",
            }}
          >
            {progress.show && (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  background: "rgba(245, 242, 247, 0.5)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 2,
                }}
              >
                <CircularProgress />
                <Typography variant="h6" style={{ marginTop: "2rem" }}>
                  {progress.message}
                </Typography>
              </div>
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={values.existingCustomer}
                  onChange={() => {
                    setFieldValue("existingCustomer", !values.existingCustomer);
                    setExisting(!existing);
                  }}
                  name="existingCustomer"
                  color="primary"
                />
              }
              label="Existing Customer"
            />
            {values.existingCustomer ? (
              <>
                <div style={{ padding: "2rem 0" }}>
                  <Alert margin="none" disableElevation disableRoundedCorners />
                </div>
                <ExistingCustomer values={values} isSubmitting={isSubmitting} />
                <Details
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  values={values}
                />
              </>
            ) : (
              <>
                <div style={{ padding: "2rem 0" }}>
                  <Alert margin="none" disableElevation disableRoundedCorners />
                </div>
                <Information
                  isSubmitting={isSubmitting}
                  values={values}
                  clientId={clientId}
                />
                <Resident isSubmitting={isSubmitting} values={values} />
                <Details
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  values={values}
                />
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddJob.propTypes = {};

export default AddJob;
