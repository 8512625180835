// TODO: Need to add contact info

import React, { useState } from "react";
import {
  Typography,
  Popper,
  ClickAwayListener,
  Paper,
} from "@mui/material";
import styled from "styled-components";

import LinkHeader from "common/LinkHeader";
import Avatar from "common/Avatar";
import { formatPhoneNumber } from "utility";

const Styles = styled.div`
  display: flex;
  margin: 0.5rem 0;
  align-items: flex-start;

  .user-details {
    margin-left: 1rem;
    flex: 1;
  }

  .user-actions {

  }

  .arrow {
    position: absolute;
    fontSize: 7px;
    width: 3em;
    height: 3em;

    &:before {
      content: "";
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    },
  }
`;

const UserItem = ({ user, children, actions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOnMouseEnter = (e) => {
    if(user.field_phone || user.field_cell){
      setAnchorEl(e.currentTarget);
    }
  };

  if (!user) return null;

  return (
    <Styles
      onMouseLeave={() => setAnchorEl(null)}
    >
      <Avatar title={user.name} link={"/"} size="small" />
      <div className="user-details">
        {user?.detail_access || user?._processed?.detail_access ? (
          <LinkHeader
            to={user.path}
            color="secondary"
            size="small"
            onMouseEnter={handleOnMouseEnter}
          >
            {user.name}
          </LinkHeader>
        ) : (
          <Typography
            to={user.path}
            color="secondary"
            size="small"
            onMouseEnter={handleOnMouseEnter}
          >
            {user.name}
          </Typography>
        )}
        <Typography color="textSecondary">
          {user.field_title_position}
        </Typography>
        {children}
      </div>
      <div className="user-actions">{actions}</div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        placement="top"
        style={{ zIndex: 100000 }}
      >
        <Paper /*elevation={2}*/ variant="outlined">
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div
              style={{
                padding: "1rem",
                width: 250,
              }}
            >
              <div style={{ display: "flex", marginBottom: "1rem" }}>
                <Avatar title={user.name} link={"/"} size="small" />
                <div style={{ marginLeft: "1rem" }}>
                  <Typography color="secondary">{user.name}</Typography>
                  <Typography color="textSecondary">
                    {user.field_title_position}
                  </Typography>
                </div>
              </div>
              {user.field_phone && (
                <Typography variant="body1">
                  Phone: {formatPhoneNumber(user.field_phone)}
                </Typography>
              )}
              {user.field_cell && (
                <Typography variant="body1">
                  Cell: {formatPhoneNumber(user.field_cell)}
                </Typography>
              )}
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Styles>
  );
};

UserItem.propTypes = {};

export default UserItem;
