import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

const Styles = styled.div`
  border: ${({ active }) =>
    active ? "1px solid var(--color-gray-medium)" : "none"};
  border-bottom: ${({ active }) =>
    active
      ? "1px solid var(--color-gray-background)"
      : "1px solid var(--color-gray-medium)"};
  background: ${({ active }) =>
    active ? "var(--color-gray-background)" : "white"};
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  margin-bottom: -1px;
  display: inline-block;
  padding: ${({ secondary }) =>
    secondary ? "0.25rem 1.25rem" : "0.75rem 1.25rem"};
  cursor: pointer;
`;

const Tab = ({ value, index, label, handleChange, secondary, onClick, ...props }) => {
  const handleClick = () => {
    handleChange(index);
  };

  return (
    <Styles
      active={value === index}
      onClick={(e) => {
        if(onClick){
          onClick(e);
        }
        handleClick();
      }}
      secondary={secondary}
      {...props}
    >
      <Typography>{label}</Typography>
      <Typography variant="caption">{secondary}</Typography>
    </Styles>
  );
};

Tab.propTypes = {};

export default Tab;
