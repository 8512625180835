import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  getProfile: async ({ username, config }) => {
    return instance.get(`/profiles/${username}`, config);
  },
};

export default API;
