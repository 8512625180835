import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import "simplebar/dist/simplebar.min.css";
import ReactGA from "react-ga";
import { ErrorBoundary } from "react-error-boundary";

import "./styles/index.scss";
import App from "./components/App";
import store from "./store";
// import * as serviceWorker from "./serviceWorker";
import {
  refreshToken,
  logout,
  refreshCsrfToken,
} from "./features/Auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Pusher from "pusher-js";
import { default as ErrorFallback } from "common/ErrorBoundary";
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9cc3VTRmFfUUd0XEo=');

// Log pusher events for debugging
// Pusher.log = (msg) => {
//   console.log(msg);
// };

window.Pusher = Pusher;

ReactGA.initialize("UA-133573801-1", []);

const refreshAuthLogic = (failedRequest) => {
  return store
    .dispatch(refreshToken())
    .then((res) => {
      try {
        const payload = unwrapResult(res);
        failedRequest.response.config.headers.Authorization =
          "Bearer " + payload.access_token;

        return Promise.resolve();
      } catch (err) {
        store.dispatch(logout());
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      store.dispatch(logout());
      return Promise.reject(err);
    });
};

const refreshCsrfLogic = (failedRequest) => {
  return store
    .dispatch(refreshCsrfToken())
    .then((res) => {
      try {
        const payload = unwrapResult(res);
        failedRequest.response.config.headers["X-CSRF-Token"] = payload;

        return Promise.resolve();
      } catch (err) {
        store.dispatch(logout());
        return Promise.reject(err);
      }
    })
    .catch((err) => {
      store.dispatch(logout());
      return Promise.reject(err);
    });
};

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [401],
});

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshCsrfLogic, {
  statusCodes: [403],
});

const getAccessToken = () => localStorage.getItem("access_token");
const getCsrfToken = () => localStorage.getItem("csrf");
// Use interceptor to inject the token to requests
axios.interceptors.request.use((request) => {
  if (
    !request.url.includes("aerisapi") &&
    !request.url.includes("/oauth/token") &&
    !request.url.includes("/vapor") &&
    !request.url.includes("amazonaws") &&
    getAccessToken()
  ) {
    request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
    request.headers["X-CSRF-Token"] = getCsrfToken();
  }
  return request;
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
