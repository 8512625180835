import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { toArray } from "lodash";
import moment from "moment";
import { size } from "lodash";

import { AutocompleteFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import DuplicateJobOverride from "./DuplicateJobOverride";

import {
  accountRepsSelectors,
  fetchPhxClientUsers,
} from "features/Users/usersSlice";
import {
  customersSelectors,
  fetchCustomers,
  getCustomersLoadingSelector,
} from "features/Customers/customersSlice";
import {
  divisionsSelectors,
  fetchJobs,
} from "features/Divisions/divisionsSlice";
import {
  fetchLocations,
  getLocationsLoadingSelector,
  locationsSelectors,
  resetLocations,
} from "features/Locations/locationsSlice";
import { fetchLocation } from "features/Location/locationSlice";
import QuickAdd from "features/Location/forms/QuickAdd";

const Job = ({
  clientId,
  values,
  isSubmitting,
  value,
  handleNext,
  setFieldValue,
  jobSourceOptions,
}) => {
  const dispatch = useDispatch();
  const customers = useSelector(customersSelectors.selectAll);
  const loadingCustomers = useSelector(getCustomersLoadingSelector);
  const locations = useSelector(locationsSelectors.selectAll);
  const loadingLocations = useSelector(getLocationsLoadingSelector);
  const reps = useSelector(accountRepsSelectors.selectAll);
  const jobs = useSelector(divisionsSelectors.selectAll);

  const [openOverride, setOpenOverride] = useState(false);
  const [loadingRecentJobs, setLoadingRecentJobs] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetLocations());
    };
  }, [dispatch]);

  useEffect(() => {
    const promise = dispatch(
      fetchPhxClientUsers({ "filter[phx_client_nids]": clientId })
    );

    return () => {
      promise.abort();
    };
  }, [dispatch, clientId]);

  const getCustomers = useCallback(
    (query) => {
      return dispatch(
        fetchCustomers({
          keywords: query,
          "filter[phx_client_nid]": clientId,
        })
      );
    },
    [dispatch, clientId]
  );

  const getRecentJobs = useCallback(
    (nid) => {
      const now = moment().unix();

      return dispatch(
        fetchJobs({
          "filter[created][0][>]": now - 3600,
          "filter[customer_location_nid][0]": nid,
        })
      );
    },
    [dispatch]
  );

  const getCustomerLocations = useCallback(
    (query) => {
      return dispatch(
        fetchLocations({
          id: values.field_customer?.nid,
          params: {
            keywords: query,
          },
        })
      );
    },
    [dispatch, values]
  );

  const getCustomerLocation = useCallback(
    (nid) => {
      return dispatch(fetchLocation(nid));
    },
    [dispatch]
  );

  const gridWidth = size(jobSourceOptions) > 1 ? 4 : 6;
  return (
    <div>
      <Typography variant="h6">Job</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Creating a Job Division will also create a Job container for this Job
        Division.
      </Typography>
      <QuickAdd id={values.field_customer?.nid} setFieldValue={setFieldValue} />
      <Grid container spacing={2}>
        <Grid item xxs={12} md={6}>
          <AutocompleteFormField
            name="field_customer"
            label="Customer"
            disabled={isSubmitting}
            options={toArray(customers).map((cust) => ({
              name: cust.customer_name,
              nid: cust.customer_nid,
              isPilot: cust.is_fm_pilot_client > 0,
            }))}
            loading={loadingCustomers}
            fetchData={getCustomers}
            variant="outlined"
            size="small"
            onChange={async (e, value) => {
              setFieldValue("field_customer", value);
              setFieldValue("field_customer_location", null);
              setFieldValue("isPilot", value.isPilot);
            }}
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <AutocompleteFormField
            name="field_customer_location"
            label="Customer Location"
            helperText="Select a customer first"
            disabled={isSubmitting || !Boolean(values.field_customer)}
            options={toArray(locations).map((cust) => ({
              name: cust.customer_location_name,
              nid: cust.customer_location_nid,
            }))}
            loading={loadingLocations || loadingRecentJobs}
            fetchData={getCustomerLocations}
            variant="outlined"
            size="small"
            onChange={async (e, value) => {
              setFieldValue("field_customer_location", value);
              if (value) {
                setLoadingRecentJobs(true);
                const response = await getRecentJobs(value.nid);
                setLoadingRecentJobs(false);
                if (response.payload?.jobs?.length) {
                  setOpenOverride(true);
                }

                getCustomerLocation(value.nid);
              }
            }}
          />
        </Grid>
        {size(jobSourceOptions) > 1 && (
          <Grid item xxs={12} md={4}>
            <SelectFormField
              fullWidth
              name="field_job_source"
              label="Job Source"
              size="small"
              variant="outlined"
              disabled={isSubmitting}
              required
              options={jobSourceOptions}
            />
          </Grid>
        )}
        <Grid item xxs={12} md={gridWidth}>
          <SelectFormField
            fullWidth
            name="field_phx_client_rep"
            label="Account Rep"
            size="small"
            variant="outlined"
            disabled={isSubmitting}
            options={[
              { value: "_none", label: "None" },
              ...reps.map((rep) => ({ value: rep.uid, label: rep.name })),
            ]}
          />
        </Grid>
        <Grid item xxs={12} md={gridWidth}>
          <TextFormField
            fullWidth
            name="field_reported_by"
            label="Reported By"
            size="small"
            variant="outlined"
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <DateFormField
            fullWidth
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            id="field_date"
            label="Date Received"
            name="field_date"
            required
            size="small"
            inputVariant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <SelectFormField
            fullWidth
            name="field_loss_category"
            label="Category"
            size="small"
            variant="outlined"
            disabled={isSubmitting}
            options={[
              { value: "cs", label: "CS" },
              { value: "g2", label: "G2" },
            ]}
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_note"
            label="Notes"
            size="small"
            variant="outlined"
            multiline
            disabled={isSubmitting}
          />
        </Grid>
      </Grid>
      <DuplicateJobOverride
        open={openOverride}
        onClose={() => setOpenOverride(false)}
        setFieldValue={setFieldValue}
        jobs={jobs}
      />
    </div>
  );
};

Job.propTypes = {};

export default Job;
