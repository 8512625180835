import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { size } from "lodash";

import { getIsAuthenticatedSelector } from "features/Auth/authSlice";
import { hasPermission } from "utility";

const GuestRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const userProcessed = useSelector((state) => state.auth.user.data._processed);

  let defaultPage = 'hub';
  if(isAuthenticated && size(userProcessed.roles)){
    if(hasPermission(userProcessed.roles, [
      "customer",
      "mby_agent",
    ])){
      defaultPage = 'user/profile';
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          isAuthenticated && !size(userProcessed.roles) ? (
            ''
          ) : (
          <Redirect
            to={{
              pathname: `/${defaultPage}`,
              state: { from: location },
            }}
          />
          )
        )
      }
    />
  );
};

export default GuestRoute;
