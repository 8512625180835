import React from "react";
import {
  TextField,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { size } from "lodash";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { SearchableSelectField } from "@phx/common/Fields";

export const SelectColumnFilter = (props) => {
  const { filterValue, setFilter, label, id, filterOptions, multiple } = props;

  return (
    <>
      <Typography
        variant="caption"
        color="primary"
        onClick={() => setFilter(multiple ? [] : "")}
        style={{
          textDecoration: "underline",
          marginBottom: "0.25rem",
          display: "inline-block",
          opacity: size(filterValue) > 0 ? 1 : 0,
          pointerEvents: size(filterValue) > 0 ? "all" : "none",
          cursor: "pointer",
        }}
      >
        Clear
      </Typography>
      {multiple ? (
        <SearchableSelectField
          labelId={id}
          label={label}
          title={`Apply ${label} filters`}
          multiple
          value={filterValue}
          onChange={(value) => {
            setFilter(value);
          }}
          options={filterOptions.map((option) => ({
            ...option,
            value: option.value,
            label: option.label,
          }))}
        />
      ) : (
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id={`${id}-label`}>{label}</InputLabel>
          <Select
            labelId={`${id}-label`}
            label={label}
            value={filterValue}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            multiple={multiple}
            style={{ background: "var(--color-gray-light)" }}
          >
            {filterOptions.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export const DateColumnFilter = (props) => {
  const { filterValue, setFilter, label } = props;

  return (
    <>
      <Typography
        variant="caption"
        color="primary"
        onClick={() => setFilter(null)}
        style={{
          textDecoration: "underline",
          display: "block",
          marginBottom: "0.25rem",
          opacity: filterValue > 0 ? 1 : 0,
          pointerEvents: filterValue > 0 ? "all" : "none",
          cursor: "pointer",
        }}
      >
        Clear
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          fullWidth
          value={filterValue}
          onChange={(value) => {
            setFilter(value);
          }}
          inputVariant="outlined"
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              {...props}
              variant={props?.inputVariant}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export const KeywordFilter = ({ query, setQuery }) => {
  return (
    <>
      <Typography
        variant="caption"
        color="primary"
        onClick={() => setQuery("")}
        style={{
          textDecoration: "underline",
          opacity: query ? 1 : 0,
          marginBottom: "0.25rem",
          display: "inline-block",
          pointerEvents: query ? "all" : "none",
          cursor: "pointer",
        }}
      >
        Clear
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        label="Search"
        autoComplete="off"
        name="query-search"
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
        value={query}
        style={{ background: "var(--color-gray-light)" }}
      />
    </>
  );
};
