import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "config";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "search";

export const fetchSearchResults = createAsyncThunk(
  `${namespace}/fetchSearchResults`,
  async ({ query, params }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/search/${query}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      let results = response.data.data;

      return {
        results,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const searchAdapter = createEntityAdapter({
  selectId: (result) => result.nid,
});

const searchSlice = createSlice({
  name: namespace,
  initialState: searchAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
  }),
  reducers: {
    clearResults: (state) => searchAdapter.removeAll(state),
  },
  extraReducers: {
    [fetchSearchResults.pending](state, action) {
      state.loading = true;
      searchAdapter.removeAll(state);
    },
    [fetchSearchResults.fulfilled](
      state,
      { payload: { results, pagination } }
    ) {
      searchAdapter.setAll(state, results);
      state.pagination = pagination;
      state.loading = false;
    },
    [fetchSearchResults.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getSearchLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.search.loading
);

export const getSearchPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.search.pagination
);

export const searchSelectors = searchAdapter.getSelectors(
  (state) => state.search
);

export const { clearResults } = searchSlice.actions;

export default searchSlice.reducer;
