import React, { useState, useEffect } from "react";
import Webcam from "@uppy/webcam";
import { ImCamera } from "react-icons/im";

import Button from "common/Button";
import Dialog from "@phx/common/Dialog";

import "@uppy/webcam/dist/style.css";

const Content = ({ uppy }) => {
  useEffect(() => {
    uppy.use(Webcam, {
      countdown: false,
      modes: ["picture"],
      videoConstraints: {
        facingMode: "user",
        width: { min: 720, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 800, max: 1080 },
      },
      showVideoSourceDropdown: true,
      showRecordingLength: false,
      preferredVideoMimeType: null,
      preferredImageMimeType: null,
      locale: {},
      target: ".screencast",
    });

    return () => {
      const instance = uppy.getPlugin("Webcam");
      uppy.removePlugin(instance);
    };
  }, [uppy]);

  return <div className="screencast" style={{ height: "100%" }}></div>;
};

const Camera = (props) => {
  const [openCamera, setOpenCamera] = useState(false);

  const handleOpenWebcam = () => {
    setOpenCamera(true);
  };

  return <>
    <Button
      onClick={handleOpenWebcam}
      startIcon={<ImCamera />}
      disableElevation
      variant="outlined"
      size="small"
      style={{ marginBottom: "1rem" }}>
      Use Camera
    </Button>
    <Dialog
      fillScreen
      open={openCamera}
      onClose={() => setOpenCamera(false)}
      title="Take Photo"
    >
      <Content {...props} />
    </Dialog>
  </>;
};

Camera.propTypes = {};

export default Camera;
