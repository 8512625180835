import styled from "styled-components";
import constants from "../constants";
export { Page, PageContainer, PageWrapper, RigthAside } from "./Page";
export { FormActions, Actions, Form } from "./Form";

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  padding: 40px 20px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  & > div,
  button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const FlexSpacer = styled.div`
  flex: 1;
`;

export const ExternalLink = styled.a`
  color: ${constants.colorPrimary};
`;

export const DropZone = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > img {
    margin-bottom: 1rem;
  }
`;

export const DropZoneRoot = styled.div`
  outline: none !important;
`;
export const IconGarden = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 1.25rem;
  }
`;
