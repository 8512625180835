import action from "../action";
import config from "../../config";

// export const getJobStatus = (nid) =>
//   action(
//     "jobStatus",
//     config.api_url,
//     `rest/job/statuses${nid ? `/${nid}` : ""}`
//   );

// export const getAffiliations = (sort, params) =>
//   action(
//     "affiliations",
//     config.api_url,
//     `rest/taxonomy-listing/affiliations/${sort ? sort : ""}`,
//     params
//   );

// export const getServices = (sort, params) =>
//   action(
//     "services",
//     config.api_url,
//     `rest/taxonomy-listing/services/${sort ? sort : ""}`,
//     params
//   );

// export const getCertifications = (sort, params) =>
//   action(
//     "certifications",
//     config.api_url,
//     `rest/taxonomy-listing/certifications/${sort ? sort : ""}`,
//     params
//   );

// export const getJobDivisionTypes = (sort, params) =>
//   action(
//     "jobDivisionTypes",
//     config.api_url,
//     `rest/taxonomy-listing/job_division_types/${sort ? sort : ""}`,
//     params
//   );

// export const getMemberNoteTypes = (sort, params) =>
//   action(
//     "memberNoteTypes",
//     config.api_url,
//     `rest/taxonomy-listing/member_note_types/${sort ? sort : ""}`,
//     params
//   );

export const getMemberNoteStatus = (sort, params) =>
  action(
    "memberNoteStatus",
    config.api_url,
    `rest/taxonomy-listing/member_note_status/${sort ? sort : ""}`,
    params
  );

export const getMemberNoteSubjects = (sort, params) =>
  action(
    "memberNoteSubjects",
    config.api_url,
    `rest/taxonomy-listing/member_note_subjects/${sort ? sort : ""}`,
    params
  );

// export const getCustomerNoteStatus = (sort, params) =>
//   action(
//     "customerNoteStatus",
//     config.api_url,
//     `rest/taxonomy-listing/customer_note_status/${sort ? sort : ""}`,
//     params
//   );

// export const getCustomerNoteTypes = (sort, params) =>
//   action(
//     "customerNoteTypes",
//     config.api_url,
//     `rest/taxonomy-listing/customer_note_types/${sort ? sort : ""}`,
//     params
//   );

// export const getJobSourceLossTypes = (sort, params) =>
//   action(
//     "jobSourceLossTypes",
//     config.api_url,
//     `rest/taxonomy-listing/sources_of_loss/${sort ? sort : ""}`,
//     params
//   );

// export const getResources = (sort, params) =>
//   action(
//     "resources",
//     config.api_url,
//     `rest/taxonomy-listing/resources/${sort ? sort : ""}`,
//     params
//   );

export const getRegions = (sort, params) =>
  action(
    "regions",
    config.api_url,
    `rest/taxonomy-listing/regions/${sort}`,
    params
  );

// export const getServiceActivities = (sort, params) =>
//   action(
//     "serviceActivities",
//     config.api_url,
//     `rest/taxonomy-listing/service_activities/${sort ? sort : ""}`,
//     params
//   );

// export const getServiceLaborTypes = (sort, params) =>
//   action(
//     "serviceLaborTypes",
//     config.api_url,
//     `rest/taxonomy-listing/service_labor_types/${sort ? sort : ""}`,
//     params
//   );

// export const getExpenseTypes = (sort, params) =>
//   action(
//     "expenseTypes",
//     config.api_url,
//     `rest/taxonomy-listing/expense_types/${sort ? sort : ""}`,
//     params
//   );

export const getComplianceChangeReasons = (sort, params) =>
  action(
    "complianceChangeReasons",
    config.api_url,
    `rest/taxonomy-listing/compliance_change_reasons/${sort ? sort : ""}`,
    params
  );

// export const getDivisionFileCategories = (sort, params) =>
//   action(
//     "divisionFileCategories",
//     config.api_url,
//     `rest/taxonomy-listing/job_division_file_categories/${sort ? sort : ""}`,
//     params
//   );

export const getMemberFileCategories = (sort, params) =>
  action(
    "memberFileCategories",
    config.api_url,
    `rest/taxonomy-listing/member_file_categories/${sort ? sort : ""}`,
    params
  );

// export const getCustomerFileCategories = (sort, params) =>
//   action(
//     "customerFileCategories",
//     config.api_url,
//     `rest/taxonomy-listing/customer_file_categories/${sort ? sort : ""}`,
//     params
//   );

// export const getDefaultCustomerResources = (params) =>
//   action(
//     "defaultResources",
//     config.api_url,
//     `rest/resource_items/customer`,
//     params
//   );

// export const getNationalContracts = (sort, params) =>
//   action(
//     "nationalContracts",
//     config.api_url,
//     `rest/taxonomy-listing/national_contracts/${sort ? sort : ""}`,
//     params
//   );

// export const getMFJobCategories = (sort, params) =>
//   action(
//     "mfJobCategories",
//     config.api_url,
//     `rest/taxonomy-listing/mf_job_categories/${sort ? sort : ""}`,
//     params
//   );

// export const getMFJobNotSecuredDetails = (sort, params) =>
//   action(
//     "mfJobNotSecuredDetails",
//     config.api_url,
//     `rest/taxonomy-listing/mf_job_not_secured_details/${sort ? sort : ""}`,
//     params
//   );

// export const getMFReferringPartyFollowUp = (sort, params) =>
//   action(
//     "mfReferringPartyFollowUp",
//     config.api_url,
//     `rest/taxonomy-listing/mf_referring_party_follow_up/${sort ? sort : ""}`,
//     params
//   );

// export const getMFLevels = (sort, params) =>
//   action(
//     "mfLevels",
//     config.api_url,
//     `rest/taxonomy-listing/more_floods_levels/${sort ? sort : ""}`,
//     params
//   );

// export const getMFScopeOptions = (tid, memberNid, params) =>
//   action(
//     "mfScopes",
//     config.api_url,
//     `rest/mf/scopes-listing/${tid}/${memberNid}`,
//     params
//   );

// export const getMFTimes = (sort, params) =>
//   action(
//     "mfTimes",
//     config.api_url,
//     `rest/taxonomy-listing/mf_photo_times/${sort ? sort : ""}`,
//     params
//   );

// export const getMFFlooringOptions = (sort, params) =>
//   action(
//     "mfFlooring",
//     config.api_url,
//     `rest/taxonomy-listing/mf_flooring_options/${sort ? sort : ""}`,
//     params
//   );

// export const getInventoryTypes = (sort, params) =>
//   action(
//     "inventoryTypes",
//     config.api_url,
//     `rest/taxonomy-listing/inventory_item_types/${sort ? sort : ""}`,
//     params
//   );

export const getCoverageCategories = (sort, params) =>
  action(
    "coverageCategories",
    config.api_url,
    `rest/taxonomy-listing/insurance_coverage_categories/${sort ? sort : ""}`,
    params
  );

export const getIntranetFileFolders = (sort, params) =>
  action(
    "intranetFileFolders",
    config.api_url,
    `rest/taxonomy-listing/intranet_file_folders/${sort ? sort : ""}`,
    params
  );

export const getMemberGenFileFolders = (sort, params) =>
  action(
    "memberGenFileFolders",
    config.api_url,
    `rest/taxonomy-listing/member_ops_gen_doc_folders/${sort ? sort : ""}`,
    params
  );

export const postTaxonomy = (params) =>
  action("taxonomy", config.api_url, `taxonomy/term`, params, "post");

export const patchTaxonomy = (tid, params) =>
  action("taxonomy", config.api_url, `taxonomy/term/${tid}`, params, "patch");

// export const getTaskTypes = (sort, params) =>
//   action(
//     "taskTypes",
//     config.api_url,
//     `rest/taxonomy-listing/task_types/${sort ? sort : ""}`,
//     params
//   );

// export const getMFCustInfoItems = (params) =>
//   action(
//     "mfCustInfoItems",
//     config.api_url,
//     `rest/mf/customer-info-sheet-items`,
//     params
//   );

// export const getMFMonitoringChecklist = (params) =>
//   action(
//     "mfMonitoringChecklist",
//     config.api_url,
//     `rest/taxonomy-listing/mf_monitoring_checklist_items`,
//     params
//   );
