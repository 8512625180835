import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";

import vendorsAPI from "./vendorsAPI";

const namespace = "vendors";

export const fetchVendors = createAsyncThunk(
  "vendors/fetchVendors",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.getVendors({
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchVendor = createAsyncThunk(
  "vendors/fetchVendor",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.getVendor({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postVendor = createAsyncThunk(
  "vendors/postVendor",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.addVendor({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddAdmin = createAsyncThunk(
  "vendors/patchAddAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.addAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveAdmin = createAsyncThunk(
  "vendors/patchRemoveAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.removeAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchUploadAttachment = createAsyncThunk(
  "vendors/patchUploadAttachment",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.uploadAttachment({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchVendor = createAsyncThunk(
  "vendors/patchVendor",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.updateVendor({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInviteUser = createAsyncThunk(
  "vendors/patchInviteUser",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.inviteUser({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteVendor = createAsyncThunk(
  "vendors/deleteVendor",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorsAPI.deleteVendor({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const vendorsAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const vendorsSlice = createSlice({
  name: namespace,
  initialState: vendorsAdapter.getInitialState({
    loading: false,
    error: null,
    vendors: vendorsAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    directory: {
      data: {},
      loading: false,
      error: null,
    },
  }),
  reducers: {},
  extraReducers: {
    [fetchVendors.pending](state, action) {
      state.loading = true;
      state.error = null;
      vendorsAdapter.removeAll(state);
    },
    [fetchVendors.fulfilled](state, { payload: vendors }) {
      state.loading = false;
      state.error = null;
      vendorsAdapter.setAll(state, vendors);
    },
    [fetchVendors.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postVendor.fulfilled](state, { payload: vendor }) {
      vendorsAdapter.addOne(state, vendor);
    },
    [fetchVendor.pending](state, action) {
      state.directory.loading = true;
      state.directory.error = null;
      state.directory.data = {};
    },
    [fetchVendor.fulfilled](state, { payload: directory }) {
      state.directory.loading = false;
      state.directory.error = null;
      state.directory.data = directory;
    },
    [fetchVendor.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchAddAdmin.fulfilled](state, { payload: directory }) {
      vendorsAdapter.updateOne(state, {
        id: directory.id,
        changes: directory,
      });
      state.directory.data = directory;
      state.directory.error = null;
    },
    [patchAddAdmin.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchRemoveAdmin.fulfilled](state, { payload: directory }) {
      vendorsAdapter.updateOne(state, {
        id: directory.id,
        changes: directory,
      });
      state.directory.data = directory;
      state.directory.error = null;
    },
    [patchRemoveAdmin.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchUploadAttachment.fulfilled](state, { payload: directory }) {
      state.directory.data = directory;
      state.directory.error = null;
    },
    [patchUploadAttachment.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
    [patchVendor.fulfilled](state, { payload: vendor }) {
      vendorsAdapter.updateOne(state, {
        id: vendor.id,
        changes: vendor,
      });
      state.error = null;
    },
    [patchVendor.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchInviteUser.fulfilled](state, { payload: directory }) {
      state.directory.data = directory;
      state.directory.error = null;
    },
    [patchInviteUser.rejected](state, action) {
      state.directory.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.directory.error = action.payload.message;
        } else {
          state.directory.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getResourcesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.vendors.loading
);

export const getResourcesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.vendors.pagination
);

export const vendorsSelectors = vendorsAdapter.getSelectors(
  (state) => state.vendors
);

export default vendorsSlice.reducer;
