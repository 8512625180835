import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import {
  formatWorkAreaFields,
  formatResourcesForSubmit,
  calcTotal,
  getIncrementMachineNameByLabel,
  formatWorkAreaExpense,
  formatWorkAreaService,
} from "./utils";
// import { addToast } from "../Toast/toastSlice";
import { formatField } from "../../utility";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "workArea";

export const fetchWorkArea = createAsyncThunk(
  `${namespace}/fetchWorkArea`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatWorkAreaFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkAreaTotals = createAsyncThunk(
  `${namespace}/fetchWorkAreaTotals`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return response.data?._processed;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkAreaResources = createAsyncThunk(
  `${namespace}/fetchWorkAreaResources`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/jobs/damage-area/resources/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );
      const resources = response.data.data.map((resource) => ({
        ...resource,
        increment: getIncrementMachineNameByLabel(resource.increment),
        tid: resource.chase,
        discount_details: (resource.discount_details ? JSON.parse(resource.discount_details) : null),
      }));
      return {
        resources,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkAreaExpenses = createAsyncThunk(
  `${namespace}/fetchWorkAreaExpenses`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/entity/node/field_da_expenses/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response?.data?.field_da_expenses
        ? response?.data?.field_da_expenses.map((d) => formatWorkAreaExpense(d))
        : [];
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkAreaLabor = createAsyncThunk(
  `${namespace}/fetchWorkAreaLabor`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/entity/node/field_da_services/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response?.data?.field_da_services
        ? response?.data?.field_da_services.map((d) => formatWorkAreaService(d))
        : [];
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postWorkAreaExpense = createAsyncThunk(
  `${namespace}/postWorkAreaExpense`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/da_expenses`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_da_expenses",
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatWorkAreaExpense(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchWorkAreaExpense = createAsyncThunk(
  `${namespace}/patchWorkAreaExpense`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/da_expenses`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatWorkAreaExpense(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error updated expense: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteWorkAreaExpense = createAsyncThunk(
  `${namespace}/deleteWorkAreaExpense`,
  async ({ id }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${config.api_url}/entity/paragraph/${id}`,
        tokenConfig(getState)
      );

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "positive",
      //     message: `Successfully deleted expense`,
      //   })
      // );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error deleting expense: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteWorkAreaLabor = createAsyncThunk(
  `${namespace}/deleteWorkAreaLabor`,
  async ({ id }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${config.api_url}/entity/paragraph/${id}`,
        tokenConfig(getState)
      );

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "positive",
      //     message: `Successfully deleted labor`,
      //   })
      // );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error deleting labor: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postWorkAreaLabor = createAsyncThunk(
  `${namespace}/postWorkAreaLabor`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/da_services`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_da_services",
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatWorkAreaService(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchWorkAreaLabor = createAsyncThunk(
  `${namespace}/patchWorkAreaLabor`,
  async ({ id, params }, { getState, rejectWithValue, signal, dispatch }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/da_services`,
            },
          },
          ...params,
        },
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return formatWorkAreaService(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error updated labor: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchWorkAreaResources = createAsyncThunk(
  `${namespace}/patchWorkAreaResources`,
  async ({ id, params }, { getState, rejectWithValue, signal, dispatch }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/damage_area`,
            },
          },
          field_da_equipment_resources: formatResourcesForSubmit(params),
        },
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return formatWorkAreaFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // dispatch(
      //   addToast({
      //     show: true,
      //     kind: "negative",
      //     message: `Error updating resources: ${error.response.data?.message}`,
      //   })
      // );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const workAreaResourcesAdapter = createEntityAdapter({
  selectId: (resource) => resource.tid,
});

const workAreaExpensesAdapter = createEntityAdapter({
  selectId: (expense) => expense.id,
});

const workAreaServicesAdapter = createEntityAdapter({
  selectId: (service) => service.id,
});

const workAreasSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    loadingTotals: false,
    data: {},
    resources: workAreaResourcesAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    expenses: workAreaExpensesAdapter.getInitialState({
      loading: false,
    }),
    labor: workAreaServicesAdapter.getInitialState({
      loading: false,
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchWorkArea.pending](state) {
      state.loading = true;
    },
    [fetchWorkArea.fulfilled](state, { payload: area }) {
      state.loading = false;
      state.data = area;
    },
    [fetchWorkArea.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchWorkAreaTotals.pending](state) {
      state.loadingTotals = true;
    },
    [fetchWorkAreaTotals.fulfilled](state, { payload: processed }) {
      state.loadingTotals = false;
      if (processed?.totals) {
        state.data._processed.totals = processed.totals;
      }
    },
    [fetchWorkAreaTotals.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loadingTotals = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchWorkAreaResources.pending](state) {
      state.resources.loading = true;
    },
    [fetchWorkAreaResources.fulfilled](
      state,
      { payload: { resources, pagination } }
    ) {
      state.resources.loading = false;
      state.resources.pagination = pagination;
      workAreaResourcesAdapter.setAll(state.resources, resources);
    },
    [fetchWorkAreaResources.rejected](state, action) {
      state.resources.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchWorkAreaExpenses.pending](state) {
      state.expenses.loading = true;
    },
    [fetchWorkAreaExpenses.fulfilled](state, { payload: expenses }) {
      state.expenses.loading = false;
      workAreaExpensesAdapter.setAll(state.expenses, expenses);
    },
    [fetchWorkAreaExpenses.rejected](state, action) {
      state.expenses.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchWorkAreaLabor.pending](state) {
      state.labor.loading = true;
    },
    [fetchWorkAreaLabor.fulfilled](state, { payload: labor }) {
      state.labor.loading = false;
      workAreaServicesAdapter.setAll(state.labor, labor);
    },
    [fetchWorkAreaLabor.rejected](state, action) {
      state.labor.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchWorkAreaResources.pending](state, action) {
      const { params } = action.meta.arg;
      const resources = params.map((param) => ({
        name: param.name,
        category: param.category,
        duration: param.duration,
        increment: param.increment,
        notes: param.notes,
        price: param.price,
        qty: param.qty,
        discount: param.discount,
        discount_details: param.discount_details,
        sub_category: param.sub_category,
        tid: param.tid,
        total: calcTotal(param.duration * param.qty, param.price, param.discount),
      }));

      workAreaResourcesAdapter.setAll(state.resources, resources);
    },
    [patchWorkAreaResources.fulfilled](state, { payload: area }) {
      state.data = area;
    },
    [patchWorkAreaResources.rejected](state, action) {
      const { resources } = action.meta.arg;
      workAreaResourcesAdapter.setAll(state.resources, resources);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postWorkAreaExpense.pending](state, action) {
      const { params, uuid } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      workAreaExpensesAdapter.addOne(state.expenses, {
        ...tempParams,
        id: uuid,
        saving: true,
      });
    },
    [postWorkAreaExpense.fulfilled](state, { meta, payload: expense }) {
      const { uuid } = meta.arg;
      workAreaExpensesAdapter.updateOne(state.expenses, {
        id: uuid,
        changes: { ...expense, saving: false },
      });
      state.data._processed.totals = expense._processed.da_totals;
      state.data._processed.break_downs = expense._processed.da_break_downs;
    },
    [postWorkAreaExpense.rejected](state, action) {
      const { uuid } = action.meta.arg;
      workAreaExpensesAdapter.removeOne(state.expenses, uuid);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postWorkAreaLabor.pending](state, action) {
      const { params, uuid } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      workAreaServicesAdapter.addOne(state.labor, {
        ...tempParams,
        id: uuid,
        saving: true,
      });
    },
    [postWorkAreaLabor.fulfilled](state, { meta, payload: labor }) {
      const { uuid } = meta.arg;
      workAreaServicesAdapter.updateOne(state.labor, {
        id: uuid,
        changes: { ...labor, saving: false },
      });
      state.data._processed.totals = labor._processed.da_totals;
      state.data._processed.break_downs = labor._processed.da_break_downs;
    },
    [postWorkAreaLabor.rejected](state, action) {
      const { uuid } = action.meta.arg;
      workAreaServicesAdapter.removeOne(state.labor, uuid);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchWorkAreaExpense.pending](state, action) {
      const { id, params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      workAreaExpensesAdapter.updateOne(state.expenses, {
        id,
        changes: tempParams,
      });
    },
    [patchWorkAreaExpense.fulfilled](state, { payload: expense }) {
      workAreaExpensesAdapter.updateOne(state.expenses, {
        id: expense.id,
        changes: expense,
      });
      state.data._processed.totals = expense._processed.da_totals;
      state.data._processed.break_downs = expense._processed.da_break_downs;
    },
    [patchWorkAreaExpense.rejected](state, action) {
      const { expense } = action.meta.arg;

      workAreaExpensesAdapter.updateOne(state.expenses, {
        id: expense.id,
        changes: expense,
      });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [deleteWorkAreaExpense.pending](state, action) {
      const { id } = action.meta.arg;

      workAreaExpensesAdapter.removeOne(state.expenses, id);
    },
    [deleteWorkAreaExpense.rejected](state, action) {
      const { expense } = action.meta.arg;

      workAreaExpensesAdapter.addOne(state.expenses, expense);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchWorkAreaLabor.pending](state, action) {
      const { id, params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      workAreaServicesAdapter.updateOne(state.labor, {
        id,
        changes: tempParams,
      });
    },
    [patchWorkAreaLabor.fulfilled](state, { payload: service }) {
      workAreaServicesAdapter.updateOne(state.labor, {
        id: service.id,
        changes: service,
      });
      state.data._processed.totals = service._processed.da_totals;
      state.data._processed.break_downs = service._processed.da_break_downs;
    },
    [patchWorkAreaLabor.rejected](state, action) {
      const { service } = action.meta.arg;

      workAreaServicesAdapter.updateOne(state.labor, {
        id: service.id,
        changes: service,
      });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [deleteWorkAreaLabor.pending](state, action) {
      const { id } = action.meta.arg;

      workAreaServicesAdapter.removeOne(state.labor, id);
    },
    [deleteWorkAreaLabor.rejected](state, action) {
      const { service } = action.meta.arg;

      workAreaServicesAdapter.addOne(state.labor, service);
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getAreaLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.loading
);

export const getAreaTotalsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.loadingTotals
);

export const getAreaDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.data
);

export const getAreaResourcesSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.resources
);

export const getAreaErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.error
);

export const getWorkAreaResourcesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.resources.loading
);

export const getWorkAreaResourcesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.resources.pagination
);

export const workAreaResourcesSelectors = workAreaResourcesAdapter.getSelectors(
  (state) => state.workarea.resources
);

export const getWorkAreaExpensesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.expenses.loading
);

export const getWorkAreaLaborLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.workarea.labor.loading
);

export const workAreaExpenseSelectors = workAreaExpensesAdapter.getSelectors(
  (state) => state.workarea.expenses
);

export const workAreaServicesSelectors = workAreaServicesAdapter.getSelectors(
  (state) => state.workarea.labor
);

export default workAreasSlice.reducer;
