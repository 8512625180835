import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { fetchJobProgress } from "../JobProgress/jobProgressSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "scopes";

export const fetchScopes = createAsyncThunk(
  `${namespace}/fetchScopes`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/mf/job/scopes/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        scopes: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberScopes = createAsyncThunk(
  `${namespace}/fetchMemberScopes`,
  async ({ tid, member }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/mf/scopes-listing/${tid}/${member}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postScope = createAsyncThunk(
  `${namespace}/postScope`,
  async ({ id, params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/mf_room_scope`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_mf_room_scopes",
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchScope = createAsyncThunk(
  `${namespace}/patchScope`,
  async ({ id, params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/mf_room_scope`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const scopesAdapter = createEntityAdapter({
  selectId: (scope) => scope.paragraph_pid,
});

const memberScopesAdapter = createEntityAdapter({
  selectId: (scope) => scope.tid,
});

const scopesSlice = createSlice({
  name: namespace,
  initialState: scopesAdapter.getInitialState({
    error: null,
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    memberScopes: memberScopesAdapter.getInitialState({
      loading: false,
      error: null,
    }),
  }),
  reducers: {},
  extraReducers: {
    [fetchScopes.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [fetchScopes.fulfilled](state, { payload: { scopes, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      scopesAdapter.setAll(state, scopes);
    },
    [fetchScopes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMemberScopes.pending](state, action) {
      state.memberScopes.error = null;
      state.memberScopes.loading = true;
    },
    [fetchMemberScopes.fulfilled](state, { payload: scopes }) {
      state.memberScopes.loading = false;
      memberScopesAdapter.setAll(state.memberScopes, scopes);
    },
    [fetchMemberScopes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.memberScopes.loading = false;
        if (action.payload) {
          state.memberScopes.error = action.payload.message;
        } else {
          state.memberScopes.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getScopesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.scopes.loading
);

export const getScopesErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.scopes.error
);

export const getScopesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.scopes.pagination
);

export const getMemberScopesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.scopes.memberScopes.loading
);

export const scopesSelectors = scopesAdapter.getSelectors(
  (state) => state.scopes
);

export const memberScopesSelectors = memberScopesAdapter.getSelectors(
  (state) => state.scopes.memberScopes
);

export default scopesSlice.reducer;
