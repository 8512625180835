import React from "react";
import styled from "styled-components";
import Button from "common/Button";
import { Typography, Checkbox } from "@mui/material";
import { MdAddAPhoto, MdLayersClear } from "react-icons/md";
import { CgFileAdd } from "react-icons/cg";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { exportBatch } from "../../../actions";
import { size, toArray } from "lodash";
import AddDocument from "../forms/AddDocument";
import FormDropdown from "features/FormBuilder/components/FormDropdown";
import FileSettings from "features/Files/components/FileSettings";
import PhotoReport from "../forms/PhotoReport";
import { MoreVert } from "@mui/icons-material";
import { MdPhotoAlbum } from "react-icons/md";


const Styles = styled.div`
  .bar {
    padding-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > button {
      margin-left: 1rem;
    }
  }

  ${({inUpload}) => inUpload ? "" : `
    padding: 0 1.25rem;

    .bar{
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--color-gray-medium);
    }
  `}
`;

const Multiselect = ({
  subType,
  selected,
  selectedFiles,
  onClear,
  allSelected,
  onSelectAll,
  referenceNid,
  references,
  inUpload,
  setFormData,
  formData,
  includeCustomForm,
  allowDownloads
}) => {
  const dispatch = useDispatch();
  const disabled = size(selected) === 0;
  const textColor = (!disabled ? 'text-gray-dark' : 'text-gray');

  const handlePackagePhotos = (data) => {
    const files = {};
    selected.forEach((fid, i) => {
      files[`files[${i}]`] = fid;
    });

    if (size(toArray(files)) > 0) {
      dispatch(
        exportBatch("/rest/files/download", {
          ...files,
        })
      );
    }
  };

  return (
    <div className="bar">
      {!inUpload &&
        <div className="flex-1 pl-4" >
          <AddDocument
            categoryNameOverride={subType === "photos" ? "Photos" : "Uncategorized"}
            referenceNid={referenceNid}
          >
            <Button
              // color="tertiary"
              size="small"
              disableElevation
              startIcon={<MdAddAPhoto className="text-gray-dark" />}
            >
              Upload
            </Button>
          </AddDocument>
        </div>
      }
      {includeCustomForm && (
        <FormDropdown />
      )}
      <Typography
        variant="button"
        onClick={onSelectAll}
        className="mx-2"
      >
        <Checkbox
          size="small"
          // className="-mr-2"
          checked={allSelected}
        />
        Select All
      </Typography>
      <Typography
        variant="button"
        onClick={onClear}
        title="Clear Selection"
        className={`mx-2 text-xl cursor-pointer ${textColor}`}
      >
        <MdLayersClear />
      </Typography>
      {((subType === "photos" || allowDownloads) && !inUpload) &&
        <Typography
          variant="button"
          onClick={handlePackagePhotos}
          title="Download"
          className={`mx-2 text-xl cursor-pointer ${textColor}`}
        >
          <RiDownloadCloud2Line />
        </Typography>
      }
      {(subType === "photos" && !inUpload) &&
        <PhotoReport
          selected={selected}
        >
          <Typography
            variant="button"
            onClick={handlePackagePhotos}
            title="Photo Report"
            className={`mx-2 text-xl cursor-pointer ${textColor}`}
          >
            <MdPhotoAlbum />
          </Typography>

        </PhotoReport>
      }
      <FileSettings
        selected={selected}
        selectedFiles={selectedFiles}
        className={textColor}
        disabled={disabled}
        category={subType === "photos" ? "Photos" : "Uncategorized"}
        setFormData={setFormData}
        formData={formData}
        references={references}
        trigger={
          <Typography
            variant="button"
            className={`mx-2 cursor-pointer ${textColor}`}
          >
            <MoreVert />
          </Typography>
        }
      />
    </div>
  );
};

const Documents = ({ selected, onClear, referenceNid }) => {
  return (
    <div className="bar">
      <FormDropdown />
      <AddDocument
        referenceNid={referenceNid}
      >
        <Button
          color="tertiary"
          size="small"
          disableElevation
          startIcon={<CgFileAdd />}
        >
          Upload
        </Button>
      </AddDocument>
    </div>
  );
};

const FileToolbar = ({ type, inUpload, ...rest }) => {
  return (
    <Styles inUpload={inUpload}>
      {type === "multiselect" ? <Multiselect inUpload={inUpload} {...rest} /> : <Documents inUpload={inUpload} {...rest} />}
    </Styles>
  );
};

FileToolbar.propTypes = {};

export default FileToolbar;
