import {
  createSlice,
  //   createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

import { setAlert } from "features/Alert/alertSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "geo";

export const fetchGeoByZip = createAsyncThunk(
  `${namespace}/fetchGeoByZip`,
  async (zip, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/geo-tools/${zip}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGeoAddressCoords = createAsyncThunk(
  `${namespace}/fetchGeoAddressCoords`,
  async (params, { getState, rejectWithValue, signal, dispatch }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/geo/address-coords${queryparams}`,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error verifying address: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const geoSlice = createSlice({
  name: namespace,
  initialState: {
    error: null,
    loading: false,
    data: {},
    addressCoords: {
      error: null,
      loading: false,
      data: {},
    },
  },
  reducers: {},
  extraReducers: {
    [fetchGeoByZip.pending](state, action) {},
    [fetchGeoByZip.fulfilled](state, { payload: geo }) {},
    [fetchGeoByZip.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchGeoAddressCoords.pending](state, action) {
      state.addressCoords.loading = true;
    },
    [fetchGeoAddressCoords.fulfilled](state, { payload: geo }) {
      state.addressCoords.loading = false;
      state.addressCoords.error = null;
      state.addressCoords.data = geo;
    },
    [fetchGeoAddressCoords.rejected](state, action) {
      state.addressCoords.loading = false;
      if (action.payload) {
        state.addressCoords.error = action.payload.message;
      } else {
        state.addressCoords.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getGeoDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.geo.data
);

export const getGeoCoordsDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.geo.addressCoords.data
);

export const getGeoCoordsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.geo.addressCoords.loading
);

export const getGeoCoordsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.geo.addressCoords.error
);

export default geoSlice.reducer;
