import { first, has, isObject, isArray, size, isString } from "lodash";
import moment from "moment";

import {
  getUserFields,
  getValuesFromFieldArray,
  getNumValuesInFieldArray,
  formatCustomerLocationFields,
  formatCustomerFields,
  getFileFields,
  getPathFromLinksSelf,
  getTaxonomyFields,
  getContacts,
} from "./";

export const parseRawHalJson = (d) => {
  const data = {};
  for (let key in d) {
    const field = d[key];

    if (isArray(field) && size(field) === 1) {
      const obj = first(field);
      if (has(obj, "value")) {
        data[key] = obj.value;
      } else if (has(obj, "target_id")) {
        data[key] = obj.target_id;
      } else if (has(obj, "_links")) {
        data[key] = field;
      } else {
        data[key] = obj;
      }
    } else if (isArray(field) && size(field) > 1) {
      data[key] = [];
      for (let index in field) {
        const obj = field[index];
        if (has(obj, "value")) {
          data[key] = obj.value;
        } else if (has(obj, "target_id")) {
          data[key] = obj.target_id;
        } else if (has(obj, "_links")) {
          data[key] = field;
        } else {
          data[key] = obj;
        }
      }
    } else {
      data[key] = field;
    }
  }

  return data;
};

export const formatField = (object, field) => {
  if (has(object, "data")) {
    if (
      object.data[field] &&
      object.data[field][0] &&
      object.data[field][0].value
    ) {
      return object.data[field][0].value;
    } else {
      return null;
    }
  } else if (isObject(object)) {
    if (object[field] && object[field][0] && "value" in object[field][0]) {
      return object[field][0].value;
    } else if (
      object[field] &&
      object[field][0] &&
      "target_id" in object[field][0]
    ) {
      return object[field][0].target_id;
    } else if (object[field] && object[field][0]) {
      return object[field][0];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const formatFieldByValue = (object, field, value, returnAllValues) => {
  if (has(object, "data")) {
    if (
      object.data[field] &&
      object.data[field][0] &&
      object.data[field][0][value]
    ) {
      if(returnAllValues){
        return object.data[field].map((item) => item?.value);
      }
      else{
        return object.data[field][0].value;
      }
    } else {
      return null;
    }
  } else if (isObject(object)) {
    if (object[field] && object[field][0] && `${value}` in object[field][0]) {
      if(returnAllValues){
        return object[field].map((item) => item[value]);
      }
      else{
        return object[field][0][value];
      }
    } else if (object[field] && object[field][0]) {
      if(returnAllValues){
        return object[field];
      }
      else{
        return object[field][0];
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const formatNumberField = (object, field) => {
  const value = formatField(object, field);
  if(value){
    return Number(value).toFixed(2);
  }
  return value;
}

export const formatScopesArray = (object, field) => {
  if (has(object, field)) {
    if (isArray(object[field])) {
      return object[field].map((obj) => ({
        id: formatField(obj, "id"),
        revision_id: formatField(obj, "revision_id"),
        field_mf_room_cc_scopes: obj.field_mf_room_cc_scopes
          ? obj.field_mf_room_cc_scopes
          : [],
        field_mf_room_pm_scopes: obj.field_mf_room_pm_scopes
          ? obj.field_mf_room_pm_scopes
          : [],
      }));
    } else {
      return {
        id: null,
        revision_id: null,
        field_mf_room_cc_scopes: [],
        field_mf_room_pm_scopes: [],
      };
    }
  } else {
    return {
      id: null,
      revision_id: null,
      field_mf_room_cc_scopes: [],
      field_mf_room_pm_scopes: [],
    };
  }
};

export const formatInsurance = (d) => {
  return {
    nid: formatField(d, "nid"),
    carrier: formatField(d, "field_jdi_carrier"),
    claim: formatField(d, "field_jdi_claim_number"),
    policyNumber: formatField(d, "field_jdi_policy_number"),
    deductible: formatField(d, "field_jdi_deductible"),
    adjusterEmail: formatField(d, "field_jdi_adjuster_email"),
    adjusterFax: formatField(d, "field_jdi_adjuster_fax"),
    adjusterName: formatField(d, "field_jdi_adjuster_name"),
    adjusterPhone: formatField(d, "field_jdi_adjuster_phone"),
    agentEmail: formatField(d, "field_jdi_agent_email"),
    agentFax: formatField(d, "field_jdi_agent_fax"),
    agentName: formatField(d, "field_jdi_agent_name"),
    agentPhone: formatField(d, "field_jdi_agent_phone"),
  };
};

export const formatTimeRangeField = (obj, field) => {
  if (obj && has(obj, field) && isArray(obj[field])) {
    const timeRange = first(obj[field]);
    if (has(timeRange, "value") && has(timeRange, "end_value")) {
      return {
        from: timeRange.value,
        to: timeRange.end_value,
      };
    } else {
      return {
        from: null,
        to: null,
      };
    }
  } else {
    return {
      from: null,
      to: null,
    };
  }
};

export const formatCrew = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_crew_arrival_time_range: formatTimeRangeField(
      d,
      "field_crew_arrival_time_range"
    ),
    field_crew_confirm_time: formatField(d, "field_crew_confirm_time"),
    field_crew_confirm_user: getUserFields(d, "field_crew_confirm_user"),
    chief: getUserFields(d, "field_crew_chief_user")
      ? getUserFields(d, "field_crew_chief_user")[0]
      : null,
    users: getUserFields(d, "field_crew_member_users")
      ? getUserFields(d, "field_crew_member_users")
      : [],
    tempUsers: getUserFields(d, "field_crew_users_temporary")
      ? getUserFields(d, "field_crew_users_temporary")
      : [],
  };
};

export const formatMoreFloodsData = (d) => {
  const mf = d && d[0] ? d[0] : null;

  return mf
    ? {
        crew_confirmation_timestamp: mf.crew_confirmation_timestamp,
        crew_confirmation_uid: mf.crew_confirmation_uid,
        customer_video_sent_timestamp: mf.customer_video_sent_timestamp,
        customer_video_sent_uid: mf.customer_video_sent_uid,
        job_secured: mf.job_secured,
        job_secured_timestamp: mf.job_secured_timestamp,
        job_secured_uid: mf.job_secured_uid,
        pm_confirmation_timestamp: mf.pm_confirmation_timestamp,
        pm_confirmation_uid: mf.pm_confirmation_uid,
      }
    : {
        crew_confirmation_timestamp: null,
        crew_confirmation_uid: null,
        customer_video_sent_timestamp: null,
        customer_video_sent_uid: null,
        job_secured: null,
        job_secured_timestamp: null,
        job_secured_uid: null,
        pm_confirmation_timestamp: null,
        pm_confirmation_uid: null,
      };
};

export const formatJobFields = (job) => {
  const jobNode = job ? job["node.job.field_job_divisions"] : null;
  const insuranceNode = job
    ? job["node.job_division_insurance.field_jdi_job_division"]
    : null;
  const fieldLastUpdated = formatField(job, "field_last_updated");
  const fieldStartDate = formatField(job, "field_start_date");
  const fieldEtaDatetime = formatField(job, "field_eta_datetime");
  const fieldCompletionDate = formatField(job, "field_completion_date");
  const fieldAssignedMember = formatField(job, "field_assigned_member");
  const fieldDateReceived = formatField(jobNode, "field_date");
  const fieldCustomer = formatField(jobNode, "field_customer");
  const fieldCustomerLocation = formatField(jobNode, "field_customer_location");
  const fieldPhoenixClient = formatField(jobNode, "field_phoenix_client");
  const processed = job && job._processed ? job._processed : null;
  const fieldPropertyScan =
    job && job.field_property_scan && job.field_property_scan[0]
      ? job.field_property_scan[0].uri
      : null;
  const type =
    job && job.field_job_division_type && job.field_job_division_type[0]
      ? {
          tid: formatField(job.field_job_division_type[0], "tid"),
          name: formatField(job.field_job_division_type[0], "name"),
        }
      : {
          tid: "",
          name: "",
        };
  const source =
    job && job.field_source_loss && job.field_source_loss[0]
      ? {
          tid: formatField(job.field_source_loss[0], "tid"),
          name: formatField(job.field_source_loss[0], "name"),
        }
      : {
          tid: "",
          name: "",
        };

  return {
    links: job && job._links ? job._links : null,
    nid: formatField(job, "nid"),
    uuid: formatField(job, "uuid"),
    nte: formatField(job, "field_nte"),
    title: formatField(job, "title"),
    fmPilotWorkOrderNumber: formatField(
      job,
      "field_fm_pilot_work_order_number"
    ),
    type,
    source,
    lastUpdated: formatField(job, "field_last_updated"),
    lastUpdatedBy: getUserFields(job, "field_last_updated_by"),
    path:
      job && job.path && job.path[0] && job.path[0].alias
        ? job.path[0].alias
        : null,
    fieldLastUpdated,
    fieldStartDate,
    fieldEtaDatetime,
    fieldCompletionDate,
    fieldPropertyScan,
    packagePreset:
      job && job.field_invoice_package_file_order
        ? job.field_invoice_package_file_order.map((t) => t.value)
        : [],
    // fieldAuditFileCategories: formatField(job, "field_audit_file_categories")
    //   ? formatField(job, "field_audit_file_categories")
    //   : "",
    fieldSourceLossOther: formatField(job, "field_source_loss_other"),
    fieldReferenceNum: formatField(job, "field_reference_num"),
    ntp: processed && processed.nte ? processed.nte : null,
    // auditRequirements:
    //   processed && processed.audit_requirements
    //     ? processed.audit_requirements
    //     : null,
    headerData: {
      status: getTaxonomyFields(job, "field_jd_status"),
      lastUpdatedDate: fieldLastUpdated
        ? moment(fieldLastUpdated).format("MMM Do, YYYY")
        : null,
      lastUpdatedTime: fieldLastUpdated
        ? moment(fieldLastUpdated).format("h:mm A")
        : null,
      startDate: fieldStartDate
        ? moment(fieldStartDate).format("MMM Do, YYYY")
        : null,
      etaDate: fieldEtaDatetime
        ? moment(fieldEtaDatetime).format("MMM Do, YYYY")
        : null,
      etaTime: fieldEtaDatetime
        ? moment(fieldEtaDatetime).format("h:mm A")
        : null,
      completionDate: fieldCompletionDate
        ? moment(fieldCompletionDate).format("MMM Do, YYYY")
        : null,
      dispatchTime:
        processed && processed.dispatch_time ? processed.dispatch_time : null,
    },
    fieldAssignedUsers: getUserFields(job, "field_assigned_users"),
    fieldEstimator: getUserFields(job, "field_estimator"),
    fieldCoordinator: getUserFields(job, "field_coordinator"),
    fieldAssignedMember,
    member: formatMemberFields(fieldAssignedMember),
    hold: {
      onHold: formatField(job, "field_hold_timestamp") ? true : false,
      reason: formatField(job, "field_hold_reason"),
      timestamp: formatField(job, "field_hold_timestamp"),
      user: getUserFields(job, "field_hold_user"),
    },
    job: {
      links: jobNode && jobNode._links ? jobNode._links : null,
      nid: formatField(jobNode, "nid"),
      title: formatField(jobNode, "title"),
      category: formatField(jobNode, "field_loss_category"),
      jobSource: formatField(jobNode, "field_job_source"),
      dateReceived: fieldDateReceived
        ? moment(fieldDateReceived).format("MMM Do, YYYY")
        : null,
      fieldDateReceived: formatField(jobNode, "field_date"),
      divisions: getValuesFromFieldArray(jobNode, "field_job_divisions"),
      numDivisions: getNumValuesInFieldArray(jobNode, "field_job_divisions"),
      customer: formatCustomerFields(fieldCustomer),
      location: formatCustomerLocationFields(fieldCustomerLocation),
      reported: formatField(jobNode, "field_reported_by"),
      notes: formatField(jobNode, "field_note"),
      client: formatClient(fieldPhoenixClient),
    },
    insurance: formatInsurance(insuranceNode),
    caller: formatField(job, "field_jd_mf_caller"),
    callerPhone: formatField(job, "field_jd_mf_caller_phone"),
    directPay: formatField(job, "field_jd_direct_pay_auth"),
    isFMPilotCommenter:
      processed && processed.fm_pilot_commenter
        ? processed.fm_pilot_commenter
        : null,
    field_work_area_tax: formatField(job, "field_work_area_tax"),
    field_jd_eta_range: {
      start:
        job && job.field_jd_eta_range && job.field_jd_eta_range[0]
          ? job.field_jd_eta_range[0].value
          : null,
      end:
        job && job.field_jd_eta_range && job.field_jd_eta_range[0]
          ? job.field_jd_eta_range[0].end_value
          : null,
    },
    field_loss_date: formatField(job, "field_loss_date"),
    field_cause: formatField(job, "field_cause"),
    field_run_time: formatField(job, "field_run_time"),
    field_mf_effected_level_qty: formatField(
      job,
      "field_mf_effected_level_qty"
    ),
    field_mf_hw_wet: formatField(job, "field_mf_hw_wet"),
    field_mf_hw_wet_sqft: formatField(job, "field_mf_hw_wet_sqft"),
    field_details: formatField(job, "field_details"),
    field_jd_est_amt: formatField(job, "field_jd_est_amt"),
    field_jd_act_amt: formatField(job, "field_jd_act_amt"),
    field_jd_mf_job_category: getTaxonomyFields(
      job,
      "field_jd_mf_job_category"
    ),
  };
};

export const formatMemberFields = (member) => {
  const logo = formatField(member, "field_logo");
  const logoUrl = logo ? logo._links.self.href : null;
  const path =
    member && member.path && member.path[0] && member.path[0].alias
      ? member.path[0].alias
      : null;
  const hasNotesAccess =
    member && member._meta && member._meta.notes_access_permitted
      ? member._meta.notes_access_permitted
      : false;
  const hasComplianceAccess =
    member && member._meta && member._meta.compliance_activity_access_permitted
      ? member._meta.compliance_activity_access_permitted
      : false;

  return {
    links: member && member._links ? member._links : null,
    self: getPathFromLinksSelf(member),
    type: "node/member",
    nid: formatField(member, "nid"),
    changed: formatField(member, "changed"),
    name: formatField(member, "title"),
    isParent: formatField(member, "field_is_parent_member"),
    g2: formatField(member, "field_g2"),
    d2d: formatField(member, "field_d2d"),
    notes: formatField(member, "field_note"),
    number: formatField(member, "field_next_job_number"),
    region: getTaxonomyFields(member, "field_region"),
    hasNotesAccess,
    hasComplianceAccess,
    logo: logoUrl,
    path,
    hasDailySheets: formatField(member, "field_daily_sheets_member"),
    clients:
      member && member.field_phoenix_clients
        ? getPhoenixClients(member.field_phoenix_clients)
        : [],
    officeId: formatField(member, "field_office_id"),
    affiliation: getTaxonomyFields(member, "field_affiliation"),
    location: getTaxonomyFields(member, "field_region"),
    officeAddress: formatField(member, "field_street_address"),
    billingAddress: formatField(member, "field_billing_address"),
    phone: formatField(member, "field_phone"),
    afterHoursPhone: formatField(member, "field_phone_after_hours"),
    fax: formatField(member, "field_fax"),
    status: formatField(member, "field_status"),
    hours: formatField(member, "field_hours"),
    contacts: getContacts(member),
    hasAimeAccess: formatField(member, "field_allow_askaime_access"),
    nationalContracts:
      member && member.field_national_contracts
        ? member.field_national_contracts
        : [],
    aimeFiles:
      member && member.field_askaime_files ? member.field_askaime_files : [],
    geo:
      member &&
      member.field_street_address_geo &&
      member.field_street_address_geo[0]
        ? {
            lat: member.field_street_address_geo[0].lat,
            lng: member.field_street_address_geo[0].lon,
          }
        : {
            lat: null,
            lng: null,
          },
    website:
      member && member.field_website ? member.field_website[0].uri : null,
    zoom:
      member && member.field_video_call_url
        ? member.field_video_call_url[0].uri
        : null,
    effectiveDate: formatField(member, "field_effective_date"),
    termExpirationDate: formatField(member, "field_expiration_date"),
    openingDate: formatField(member, "field_opening_date"),
    otherAddendum: formatField(member, "field_other_addendum"),
    agrmntExpiration: formatField(member, "field_agrmnt_exp"),
    field_response_time: formatField(member, "field_response_time"),
    lastContact: formatField(member, "field_last_contact"),
    storeType: formatField(member, "field_store_type"),
    ufoc: formatField(member, "field_ufoc"),
    rsm: getUserFields(member, "field_rsm"),
    email: formatField(member, "field_email"),
    dkiLiason: getUserFields(member, "field_dki_liaison"),
    primaryContact: getUserFields(member, "field_member_ci_primary"),
    division: formatField(member, "field_member_division"),
    field_term_renewal_fee: formatField(member, "field_term_renewal_fee"),
    field_term_monthly_fee: formatField(member, "field_term_monthly_fee"),
    field_term_length: formatField(member, "field_term_length"),
    processed: member && member._processed ? member._processed : null,
  };
};

export const formatClient = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_pc_address: formatField(d, "field_pc_address"),
    // field_pc_default_member: formatField(d, "field_pc_default_member"),
    // field_pc_domain: formatField(d, "field_pc_domain"),
    field_pc_logo: formatField(d, "field_pc_logo"),
    field_pc_phone: formatField(d, "field_pc_phone"),
    field_pc_proper_name: formatField(d, "field_pc_proper_name"),
    _processed: d?._processed,
  };
};

// export const getAvailableClientSettings = (clients) => {
//   const clientSettings = {
//     dki_compliance: 0,
//     dki_job_division_process: 0,
//     job_division_allow_crews: 0,
//     job_division_allow_insurance: 0,
//     job_division_auto_clock_in: 0,
//     mf_job_division_process: 0,
//     job_division_process_type: "general",
//   };

//   for (let i in clients) {
//     const client = formatClient(clients[i]);
//     const settings = client.field_pc_client_settings;
//     for (let key in settings) {
//       if (settings[key] && key !== "job_division_process_type") {
//         clientSettings[key] = true;
//       } else if (settings[key] && key === "job_division_process_type") {
//         clientSettings[key] = settings[key];
//       }
//     }
//   }

//   return clientSettings;
// };

export const getPhoenixClients = (clients) => {
  if (clients && isArray(clients)) {
    return clients.map((client) => formatClient(client));
  } else {
    return [];
  }
};

// export const getSelectedClientSettings = (nid, clients) => {
//   const client = clients.filter((c) => c.nid === nid).shift();

//   if (client) {
//     return client.field_pc_client_settings;
//   } else {
//     return null;
//   }
// };

export const formatMemberCapability = (c) => {
  const handlingExperience = formatField(c, "field_mc_hndlng_exp");

  return {
    id: formatField(c, "id"),
    rid: formatField(c, "revision_id"),
    certifiedMitigationStaff: formatField(c, "field_mc_cert_mit_staff"),
    mitigationEstimators: formatField(c, "field_mc_mit_esmtrs"),
    constructionStaff: formatField(c, "field_mc_cnst_staff"),
    constructionEstimators: formatField(c, "field_mc_cnst_esmtrs"),
    mitigationProjectManagers: formatField(c, "field_mc_mit_prj_mgrs"),
    operationsManagers: formatField(c, "field_mc_ops_mgrs"),
    constructionProjectManagers: formatField(c, "field_mc_cnst_prj_mgrs"),
    adminCoordinators: formatField(c, "field_mc_adm_coords"),
    systemApplications:
      c && c.field_mc_sys_apps && isArray(c.field_mc_sys_apps)
        ? c.field_mc_sys_apps
        : [],
    otherTech: formatField(c, "field_mc_sys_apps_other"),
    handlingExperience:
      handlingExperience && isArray(handlingExperience)
        ? handlingExperience
        : handlingExperience && isString(handlingExperience)
        ? handlingExperience.split(", ")
        : [],
    updatedPrograms: formatField(c, "field_mc_updtd_prgms"),
    traveled: formatField(c, "field_mc_trvld"),
    travelsForBusiness: formatField(c, "field_mc_trvl_bsns"),
    hasTraveled: formatField(c, "field_mc_trvld_amt"),
    yearLastTravelled: formatField(c, "field_mc_last_trvl_year"),
    travelingSupport: formatField(c, "field_mc_trvl_intrst"),
    catComments: formatField(c, "field_mc_cat_comments"),
    suveyCompleted: formatField(c, "field_mc_srvy_date"),
    equipment:
      c && c.field_mc_equipment && isArray(c.field_mc_equipment)
        ? c.field_mc_equipment.map((e) => formatMemberCapabilityEquipment(e))
        : [],
    vehicles:
      c && c.field_mc_vehicles && isArray(c.field_mc_vehicles)
        ? c.field_mc_vehicles.map((e) => formatMemberCapabilityVehicles(e))
        : [],
  };
};

export const formatMemberCapabilityEquipment = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_mce_port_ext_uts: formatField(d, "field_mce_port_ext_uts"),
    field_mce_hydroxyls: formatField(d, "field_mce_hydroxyls"),
    field_mce_trk_mnt_ext_uts: formatField(d, "field_mce_trk_mnt_ext_uts"),
    field_mce_emrgy_lt_uts: formatField(d, "field_mce_emrgy_lt_uts"),
    field_mce_in_frd_htrs: formatField(d, "field_mce_in_frd_htrs"),
    field_mce_thrm_img_cmra: formatField(d, "field_mce_thrm_img_cmra"),
    field_mce_wall_cav_dryng_mchn: formatField(
      d,
      "field_mce_wall_cav_dryng_mchn"
    ),
    field_trash_pumps: formatField(d, "field_trash_pumps"),
    field_mce_dec_dehu_2000: formatField(d, "field_mce_dec_dehu_2000"),
    field_mce_dec_dehu_6000: formatField(d, "field_mce_dec_dehu_6000"),
    field_mce_dec_dehu_12000: formatField(d, "field_mce_dec_dehu_12000"),
    field_mce_dec_dehu_ovr_12000: formatField(
      d,
      "field_mce_dec_dehu_ovr_12000"
    ),
    field_mce_sup_heat_250: formatField(d, "field_mce_sup_heat_250"),
    field_mce_sup_heat_500: formatField(d, "field_mce_sup_heat_500"),
    field_mce_sup_heat_999: formatField(d, "field_mce_sup_heat_999"),
    field_mce_sup_heat_ovr_1_mil: formatField(
      d,
      "field_mce_sup_heat_ovr_1_mil"
    ),
    field_mce_starc_prvdr: formatField(d, "field_mce_starc_prvdr"),
    field_mce_steramist_prvdr: formatField(d, "field_mce_steramist_prvdr"),
    field_mce_cnv_rfrdgr_dehu: formatField(d, "field_mce_cnv_rfrdgr_dehu"),
    field_mce_prssr_wshr: formatField(d, "field_mce_prssr_wshr"),
    field_mce_air_mover: formatField(d, "field_mce_air_mover"),
    field_mce_ozone_gen: formatField(d, "field_mce_ozone_gen"),
    field_mce_lg_rfrdgr_dehu: formatField(d, "field_mce_lg_rfrdgr_dehu"),
    field_mce_hepa_vcms: formatField(d, "field_mce_hepa_vcms"),
    field_mce_fuel_cells: formatField(d, "field_mce_fuel_cells"),
    field_mce_fueling_stns_szs: formatField(d, "field_mce_fueling_stns_szs"),
    field_mce_air_scrub_1000: formatField(d, "field_mce_air_scrub_1000"),
    field_mce_air_scrub_2000: formatField(d, "field_mce_air_scrub_2000"),
    field_mce_air_scrub_3500: formatField(d, "field_mce_air_scrub_3500"),
    field_mce_air_scrub_ovr_3501: formatField(
      d,
      "field_mce_air_scrub_ovr_3501"
    ),
    field_mce_port_gen_50: formatField(d, "field_mce_port_gen_50"),
    field_mce_port_gen_125: formatField(d, "field_mce_port_gen_125"),
    field_mce_port_gen_175: formatField(d, "field_mce_port_gen_175"),
    field_mce_port_gen_ovr_175: formatField(d, "field_mce_port_gen_ovr_175"),
    field_mce_sup_cool_1_5: formatField(d, "field_mce_sup_cool_1_5"),
    field_mce_sup_cool_5: formatField(d, "field_mce_sup_cool_5"),
    field_mce_sup_cool_ovr_5: formatField(d, "field_mce_sup_cool_ovr_5"),
    field_mce_comments: formatField(d, "field_mce_comments"),
    field_mce_other: formatField(d, "field_mce_other"),
  };
};

export const formatMemberCapabilityVehicles = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_mcv_cargo_vans: formatField(d, "field_mcv_cargo_vans"),
    field_mcv_pickup_trucks: formatField(d, "field_mcv_pickup_trucks"),
    field_mcv_18_whlr_trucks: formatField(d, "field_mcv_18_whlr_trucks"),
    field_mcv_moving_vans: formatField(d, "field_mcv_moving_vans"),
    field_mcv_box_type_trucks: formatField(d, "field_mcv_box_type_trucks"),
    field_mcv_semi_tractor: formatField(d, "field_mcv_semi_tractor"),
    field_mcv_semi_trailers: formatField(d, "field_mcv_semi_trailers"),
    field_mcv_mobile_command: formatField(d, "field_mcv_mobile_command"),
    field_mcv_marine_capable: formatField(d, "field_mcv_marine_capable"),
  };
};

export const formatMemberCapabilityItem = (c) => {
  return {
    value: formatField(c, "field_mci_qty_ans"),
    label: formatField(c, "field_mci_title"),
  };
};

export const formatMemberDefaultTermination = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    committeeApproved: formatField(d, "field_mdt_cmte_apr"),
    dateTerminated: formatField(d, "field_mdt_date"),
    reason: formatField(d, "field_mdt_reason"),
    dateSent: formatField(d, "field_mdt_sent_date"),
    dateFollowup: formatField(d, "field_mdt_flwup_date"),
    dateCured: formatField(d, "field_mdt_cured_date"),
    dateEffective: formatField(d, "field_mdt_efctve_date"),
    actionType: formatField(d, "field_mdt_action_type"),
    triggerTasks: formatField(d, "field_mdt_trigger_tsks"),
    amount: formatField(d, "field_mdt_amt"),
    collected: formatField(d, "field_mdt_collected"),
    comments: formatField(d, "field_mdt_comments"),
    documents: d && d.field_mdt_documents ? d.field_mdt_documents : [],
    other: formatField(d, "field_mdt_other_reason"),
  };
};

export const formatMemberEntityDetails = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    aka: formatField(d, "field_aka_dba_name"),
    formationDate: formatField(d, "field_formation_date"),
    entityType: formatField(d, "field_member_entity_type"),
    hubZone: formatField(d, "field_hubzone"),
    minorityOwnedSmallBusiness: formatField(d, "field_minority_owned"),
    agreementName: formatField(d, "field_agreement_name"),
    serviceDisabledVeteran: formatField(d, "field_sdvosb"),
    taxpayerID: formatField(d, "field_fein_id"),
    veteransAdministration: formatField(d, "field_vavsdvosb"),
    field_minority_owned_file: getFileFields(d, "field_minority_owned_file"),
    field_women_owned_document: getFileFields(d, "field_women_owned_document"),
    field_sdvosb_file: getFileFields(d, "field_sdvosb_file"),
    field_vavsdvosb_file: getFileFields(d, "field_vavsdvosb_file"),
    field_hubzone_file: getFileFields(d, "field_hubzone_file"),
    field_women_owned: formatField(d, "field_women_owned"),
  };
};

export const formatMemberEmployee = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_emp_user: getUserFields(d, "field_emp_user"),
    field_emp_type: formatField(d, "field_emp_type"),
    field_emp_first_name: formatField(d, "field_emp_first_name"),
    field_emp_last_name: formatField(d, "field_emp_last_name"),
    field_emp_status: formatField(d, "field_emp_status"),
    field_emp_position: formatField(d, "field_emp_position"),
    field_emp_phone: formatField(d, "field_emp_phone"),
    field_emp_mobile: formatField(d, "field_emp_mobile"),
    field_emp_email: formatField(d, "field_emp_email"),
    field_emp_roles: formatField(d, "field_emp_roles"),
    field_emp_ufoc: formatField(d, "field_emp_ufoc"),
    field_emp_other_ufoc: formatField(d, "field_emp_other_ufoc"),
    field_emp_ownr_title: formatField(d, "field_emp_ownr_title"),
    field_emp_ownr_sgnd: formatField(d, "field_emp_ownr_sgnd"),
    field_emp_ownr_prcnt: formatField(d, "field_emp_ownr_prcnt"),
  };
};

export const formatEmployeeEducation = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_ede_course_source: formatField(d, "field_ede_course_source"),
    field_ede_course_cat: formatField(d, "field_ede_course_cat"),
    field_ede_course_name: formatField(d, "field_ede_course_name"),
    field_ede_course_acc: formatField(d, "field_ede_course_acc"),
    field_ede_start_date: formatField(d, "field_ede_start_date"),
    field_ede_end_date: formatField(d, "field_ede_end_date"),
    field_ede_credits_earned: formatField(d, "field_ede_credits_earned"),
  };
};

export const formatMemberFinancials = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_mf_annual_revenue: formatField(d, "field_mf_annual_revenue"),
    field_mf_bin_number: formatField(d, "field_mf_bin_number"),
    field_mf_contentstrack: formatField(d, "field_mf_contentstrack"),
    field_mf_credit_rating: formatField(d, "field_mf_credit_rating"),
    field_mf_credit_report: formatField(d, "field_mf_credit_report"),
    field_mf_xcmt_end_date: formatField(d, "field_mf_xcmt_end_date"),
    field_mf_finance_appr: formatField(d, "field_mf_finance_appr"),
    field_mf_financial_notes: formatField(d, "field_mf_financial_notes"),
    field_mf_fncl_stmt_on_file: formatField(d, "field_mf_fncl_stmt_on_file"),
    field_mf_ini_frnchs_fee_paid: formatField(
      d,
      "field_mf_ini_frnchs_fee_paid"
    ),
    field_mf_ini_frnchs_fee_total: formatField(
      d,
      "field_mf_ini_frnchs_fee_total"
    ),
    field_mf_lm_ct_jobs: formatField(d, "field_mf_lm_ct_jobs"),
    field_mf_lm_ct_jobs_2nd_inv: formatField(d, "field_mf_lm_ct_jobs_2nd_inv"),
    field_mf_last_sub_change: formatField(d, "field_mf_last_sub_change"),
    field_mf_mkt_fund_amt: formatField(d, "field_mf_mkt_fund_amt"),
    field_mf_mkt_fund_fee_pd_date: formatField(
      d,
      "field_mf_mkt_fund_fee_pd_date"
    ),
    field_mf_prsnl_grnte_argmt: formatField(d, "field_mf_prsnl_grnte_argmt"),
    field_mf_prsnl_grnte_on_file: formatField(
      d,
      "field_mf_prsnl_grnte_on_file"
    ),
    field_mf_prosupply_dorp_amt: formatField(d, "field_mf_prosupply_dorp_amt"),
    field_mf_prosupply_dorp_date: formatField(
      d,
      "field_mf_prosupply_dorp_date"
    ),
    field_mf_pull_billing_rpt: formatField(d, "field_mf_pull_billing_rpt"),
    field_mf_prosupply_qlfy: formatField(d, "field_mf_prosupply_qlfy"),
    field_mf_xcmt_start_date: formatField(d, "field_mf_xcmt_start_date"),
    field_mf_term_length: formatField(d, "field_mf_term_length"),
    field_mf_term_monthly_fee: formatField(d, "field_mf_term_monthly_fee"),
    field_mf_term_renewal_fee: formatField(d, "field_mf_term_renewal_fee"),
    field_mf_xcmt_contacts: formatField(d, "field_mf_xcmt_contacts"),
    field_mf_xcmt_emails: formatField(d, "field_mf_xcmt_emails"),
    field_mf_xcmt_agmt_title: formatField(d, "field_mf_xcmt_agmt_title"),
    field_mf_xcmt_licenses: formatField(d, "field_mf_xcmt_licenses"),
    field_mf_xcmt_lcns_2nd_inv: formatField(d, "field_mf_xcmt_lcns_2nd_inv"),
    field_mf_xcmt_notes: formatField(d, "field_mf_xcmt_notes"),
    field_mf_xcmt_status: formatField(d, "field_mf_xcmt_status"),
    field_mf_xcmt_upgrades: formatField(d, "field_mf_xcmt_upgrades"),
    field_mf_xcmt_upgd_2nd_inv: formatField(d, "field_mf_xcmt_upgd_2nd_inv"),
    field_mf_xcmt_agmt_file: getFileFields(d, "field_mf_xcmt_agmt_file"),
    field_mf_prsnl_grnte_argmt_file: getFileFields(
      d,
      "field_mf_prsnl_grnte_argmt_file"
    ),
    field_mf_credit_report_file: getFileFields(
      d,
      "field_mf_credit_report_file"
    ),
  };
};

export const formatTerminationDoc = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    created: formatField(d, "created"),
    file: getFileFields(d, "field_mdtd_file"),
    title: formatField(d, "field_mdtd_title"),
  };
};

export const formatAgreementDoc = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    created: formatField(d, "created"),
    file: getFileFields(d, "field_mad_file"),
    title: formatField(d, "field_mad_title"),
  };
};

export const formatMemberRenewal = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_mr_renewal_status: formatField(d, "field_mr_renewal_status"),
    field_mr_likely_to_renew: formatField(d, "field_mr_likely_to_renew"),
    field_mr_renewal_date: formatField(d, "field_mr_renewal_date"),
    field_mr_90_day_ntc: formatField(d, "field_mr_90_day_ntc"),
    field_mr_fdd_rcpt_rec: formatField(d, "field_mr_fdd_rcpt_rec"),
    field_mr_rnwl_pwk_sent: formatField(d, "field_mr_rnwl_pwk_sent"),
    field_mr_cure_lttr_sent: formatField(d, "field_mr_cure_lttr_sent"),
    field_mr_agrmnt_qty: formatField(d, "field_mr_agrmnt_qty"),
    field_mr_agrmnt_exp_date: formatField(d, "field_mr_agrmnt_exp_date"),
    field_mr_notes: formatField(d, "field_mr_notes"),
    field_mr_trggr_rnwl_tasks: formatField(d, "field_mr_trggr_rnwl_tasks"),
    field_mr_renewal_fee: formatField(d, "field_mr_renewal_fee"),
    field_mr_fdd_rcpt_title: formatField(d, "field_mr_fdd_rcpt_title"),
    field_mr_fdd_rcpt_file: getFileFields(d, "field_mr_fdd_rcpt_file"),
    field_mr_ter_sprdsht_title: formatField(d, "field_mr_ter_sprdsht_title"),
    field_mr_ter_sprdsht_file: getFileFields(d, "field_mr_ter_sprdsht_file"),
    field_mr_cure_lttr_title: formatField(d, "field_mr_cure_lttr_title"),
    field_mr_cure_lttr_file: getFileFields(d, "field_mr_cure_lttr_file"),
    field_mr_agrmnt_title: formatField(d, "field_mr_agrmnt_title"),
    field_mr_agrmnt_file: getFileFields(d, "field_mr_agrmnt_file"),
  };
};

export const formatMemberTerritory = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_mt_type: formatField(d, "field_mt_type"),
    field_mt_shares_owned: formatField(d, "field_mt_shares_owned"),
    field_mt_start_date: formatField(d, "field_mt_start_date"),
    field_mt_end_date: formatField(d, "field_mt_end_date"),
    field_mt_population: formatField(d, "field_mt_population"),
    field_mt_country: formatField(d, "field_mt_country"),
    field_mt_states: formatField(d, "field_mt_states"),
    field_mt_county: formatField(d, "field_mt_county"),
    field_mt_zips_ownd: formatField(d, "field_mt_zips_ownd"),
    field_mt_zips_ex_loc: formatField(d, "field_mt_zips_ex_loc"),
    field_mt_notes: formatField(d, "field_mt_notes"),
  };
};

export const formatCRMCall = (d) => {
  return {
    id: formatField(d, "nid"),
    rid: formatField(d, "revision_id"),
    field_crmc_user: getUserFields(d, "field_crmc_user"),
    field_crmc_subject: formatField(d, "field_crmc_subject"),
    field_crmc_time: formatField(d, "field_crmc_time"),
    field_crmc_status: formatField(d, "field_crmc_status"),
    field_crmc_call_type: formatField(d, "field_crmc_call_type"),
    field_crmc_notes: formatField(d, "field_crmc_notes"),
    title: formatField(d, "title"),
  };
};

export const formatCRMEmail = (d) => {
  return {
    id: formatField(d, "nid"),
    rid: formatField(d, "revision_id"),
    field_em_attachments: formatField(d, "field_em_attachments"),
    field_em_bcc: formatField(d, "field_em_bcc"),
    field_em_cc: formatField(d, "field_em_cc"),
    field_em_from: formatField(d, "field_em_from"),
    field_em_identifier: formatField(d, "field_em_identifier"),
    field_em_message: formatField(d, "field_em_message"),
    field_em_opened_time: formatField(d, "field_em_opened_time"),
    field_em_recipients: formatField(d, "field_em_recipients"),
    field_em_sent_time: formatField(d, "field_em_sent_time"),
    field_em_subject: formatField(d, "field_em_subject"),
    title: formatField(d, "title"),
  };
};

const formatFieldAssignedUsers = (obj, field) => {
  if (obj && obj[field]) {
    const users = obj[field];

    return users.map((user) => first(getUserFields(user, "field_iau_user")));
  }
};

const formatFieldSpecificEntity = (obj, field) => {
  if (obj && obj[field]) {
    const entities = obj[field];

    return entities.map((entity) => {
      const name = formatField(entity, "title");
      const entity_id = formatField(entity, "nid");

      return {
        name,
        entity_id,
      };
    });
  }
};

export const formatStatesOptions = (states) => {
  const data = [];
  if (!states || Array.isArray(states)) return data;
  for (let id in states) {
    data.push({ label: states[id], value: id });
  }

  return data;
};

const getChamberInspectionReportData = (d) => {
  const field = formatField(d, "field_mf_chm_insp_rpt_data");
  const data = {
    average_class: 0,
    ceiling_height: 0,
    dehumidifier_lg_qty: 0,
    dehumidifier_lg_rec_qty: 0,
    dehumidifier_std_qty: 0,
    dehumidifier_std_rec_qty: 0,
    dehumidifier_xl_qty: 0,
    dehumidifier_xl_rec_qty: 0,
    injector_lg_qty: 0,
    injector_lg_rec_qty: 0,
    injector_xl_qty: 0,
    injector_xl_rec_qty: 0,
    manifold_qty: 0,
    manifold_rec_qty: 0,
    total_cubic_ft: 0,
    total_pints: 0,
    total_sqft: 0,
    total_wet_wall_lf: 0,
  };

  if (field) {
    return field;
  } else {
    return data;
  }
};

export const formatChamberFields = (c) => {
  return {
    nid: formatField(c, "nid"),
    title: formatField(c, "title"),
    level: getTaxonomyFields(c, "field_mf_chamber_level"),
    rooms: size(c.field_mf_chamber_rooms),
    inspectionReportData: getChamberInspectionReportData(c),
  };
};

const getInspectionReportData = (d) => {
  const field = formatField(d, "field_mf_room_insp_rpt_data");
  const data = {
    ceiling_air_mover_rec: 0,
    class: 0,
    class_rec: 0,
    floor_air_mover_rec: 0,
    offset_qty: 0,
    total_air_mover_qty: 0,
    total_air_mover_rec: 0,
    total_sqft: 0,
    total_wall_height: 0,
    total_wet_sqft: 0,
    wall_offset_air_mover_rec: 0,
    wet_ceiling_sqft: 0,
    wet_floor_sqft: 0,
    wet_wall_lft: 0,
  };

  if (field) {
    return field;
  } else {
    return data;
  }
};

export const formatRoomFields = (r) => {
  return {
    nid: formatField(r, "nid"),
    title: formatField(r, "title"),
    level: getTaxonomyFields(r, "field_mf_chamber_level"),
    roomScopes: formatScopesArray(r, "field_mf_room_scopes"),
    items: formatInventoryItemsArray(r, "field_ii_assignments"),
    flooringOptions: getValuesFromFieldArray(
      r,
      "field_mf_room_flooring_options"
    ),
    surfaces: formatRoomWallArray(r, "field_mf_room_surfaces"),
    inspectionReportData: getInspectionReportData(r),
  };
};

export const formatInventoryItemsArray = (object, field) => {
  if (has(object, field)) {
    if (isArray(object[field])) {
      return object[field].map((obj) => ({
        links: obj && obj._links ? obj._links : null,
        id: formatField(obj, "id"),
        uuid: formatField(obj, "uuid"),
        revisionId: formatField(obj, "revision_id"),
        item: formatInventoryItem(formatField(obj, "field_iia_inventory_item")),
      }));
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const formatRoomWallArray = (object, field) => {
  if (has(object, field)) {
    if (isArray(object[field])) {
      return object[field].map((obj) => ({
        links: obj && obj._links ? obj._links : null,
        id: formatField(obj, "id"),
        uuid: formatField(obj, "uuid"),
        revisionId: formatField(obj, "revision_id"),
        totalHeight: formatField(obj, "field_mfrs_total_height_depth"),
        totalWidth: formatField(obj, "field_mfrs_total_width"),
        wetHeight: formatField(obj, "field_mfrs_wet_height_depth"),
        wetWidth: formatField(obj, "field_mfrs_wet_width"),
        location: formatField(obj, "field_mfrs_location"),
      }));
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const formatRoomSurface = (d) => {
  return {
    id: formatField(d, "id"),
    uuid: formatField(d, "uuid"),
    revisionId: formatField(d, "revision_id"),
    totalHeight: formatField(d, "field_mfrs_total_height_depth"),
    totalWidth: formatField(d, "field_mfrs_total_width"),
    wetHeight: formatField(d, "field_mfrs_wet_height_depth"),
    wetWidth: formatField(d, "field_mfrs_wet_width"),
    location: formatField(d, "field_mfrs_location"),
  };
};

export const formatInventoryItem = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    id: formatField(d, "field_ii_id"),
    type: formatField(d, "field_ii_type"),
    typeText: formatField(d, "field_ii_type_text"),
    category: formatField(d, "field_ii_type_category"),
    categoryText: formatField(d, "field_ii_type_category_text"),
    subCategory: formatField(d, "field_ii_type_subcategory"),
    subCategoryText: formatField(d, "field_ii_type_subcategory_text"),
    actualCost: formatField(d, "field_ii_actual_cost"),
    estimatedCost: formatField(d, "field_ii_estimate_cost"),
    isRental: formatField(d, "field_ii_is_rental"),
    rentalPricePerDay: formatField(d, "field_ii_rental_price_per_day"),
    make: formatField(d, "field_ii_make"),
    model: formatField(d, "field_ii_model"),
    serial: formatField(d, "field_ii_serial_number"),
    location: formatField(d, "field_ii_warehouse_location"),
    notes: formatField(d, "field_ii_notes"),
    barcode: formatField(d, "field_ii_barcode_image"),
    inUse: formatField(d, "field_ii_in_use"),
  };
};

export const formatInventoryItemAssignment = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    field_iia_addl_actions: formatField(d, "field_iia_addl_actions"),
    field_iia_assignment_time: formatField(d, "field_iia_assignment_time"),
    field_iia_assignment_user: getUserFields(d, "field_iia_assignment_user"),
    field_iia_inventory_item:
      d && d.field_iia_inventory_item && d.field_iia_inventory_item[0]
        ? formatInventoryItem(d.field_iia_inventory_item[0])
        : formatInventoryItem(d),
    field_iia_inventory_item_text: formatField(
      d,
      "field_iia_inventory_item_text"
    ),
    field_iia_inv_item_type: formatField(d, "field_iia_inv_item_type"),
    field_iia_quantity: formatField(d, "field_iia_quantity"),
    field_iia_return_time: formatField(d, "field_iia_return_time"),
    field_iia_return_user: getUserFields(d, "field_iia_return_user"),
  };
};

export const formatMaintenanceRecord = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    date: formatField(d, "field_ii_mr_date"),
    notes: formatField(d, "field_ii_mr_notes"),
    title: formatField(d, "field_ii_mr_title"),
    user: formatField(d, "field_ii_mr_user"),
  };
};

export const formatMaintenanceSchedule = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    title: formatField(d, "field_ii_ms_title"),
    increment: formatField(d, "field_ii_ms_increment"),
    interval: formatField(d, "field_ii_ms_interval"),
    lastDate: formatField(d, "field_ii_ms_last_mntnce_date"),
    nextDate: formatField(d, "field_ii_ms_next_mntnce_date"),
  };
};

export const formatDirectPay = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    claimNumber: formatField(d, "field_dpa_claim_number"),
    insured: formatField(d, "field_dpa_insured_name"),
    insurer: formatField(d, "field_dpa_insurer_name"),
    date: formatField(d, "field_dpa_loss_date"),
    member: formatField(d, "field_dpa_member_name"),
    signatory: formatField(d, "field_dpa_signatory_name"),
    signature: formatField(d, "field_dpa_signature"),
    signDate: formatField(d, "field_dpa_sign_date"),
  };
};

export const formatWorkAuth = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    type: formatField(d, "type"),
    date: formatField(d, "field_mf_wa_auth_date"),
    billingAddress: formatField(d, "field_mf_wa_billing_address"),
    customer: formatField(d, "field_mf_wa_customer_name"),
    customerSignature: formatField(d, "field_mf_wa_customer_signature"),
    customerSignDate: formatField(d, "field_mf_wa_cust_signature_date"),
    member: formatField(d, "field_mf_wa_member_name"),
    memberSignature: formatField(d, "field_mf_wa_member_signature"),
    memberSignDate: formatField(d, "field_mf_wa_mem_signature_date"),
    obtainedCC: formatField(d, "field_mf_wa_cc_card_offline"),
    propertyAddress: formatField(d, "field_mf_wa_property_address"),
    allowContact: formatField(d, "field_mf_wa_intl_aff_est"),
    damageLiability: formatField(d, "field_mf_wa_intl_baseboard"),
    ceramicLiability: formatField(d, "field_mf_wa_intl_ceramic"),
  };
};

export const formatCISItems = (object, field) => {
  if (has(object, field)) {
    if (isArray(object[field])) {
      return object[field].map((obj) => ({
        links: obj && obj._links ? obj._links : null,
        tid: formatField(obj, "tid"),
        uuid: formatField(obj, "uuid"),
        revisionId: formatField(obj, "revision_id"),
        name: formatField(obj, "name"),
      }));
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const formatTeam = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_it_description: formatField(d, "field_it_description"),
    field_intranet_scopes: d.field_intranet_scopes
      ? d.field_intranet_scopes.map((scope) => scope.value)
      : [],
    field_intranet_assigned_users: formatFieldAssignedUsers(
      d,
      "field_intranet_assigned_users"
    ),
    field_intranet_specific_members: formatFieldSpecificEntity(
      d,
      "field_intranet_specific_members"
    ),
    field_intranet_specific_custs: formatFieldSpecificEntity(
      d,
      "field_intranet_specific_custs"
    ),
    field_intranet_specific_m_roles: d.field_intranet_specific_m_roles
      ? d.field_intranet_specific_m_roles.map((role) => role.target_id)
      : [],
    field_intranet_mem_affiliations: getValuesFromFieldArray(
      d,
      "field_intranet_mem_affiliations"
    ),
  };
};

export const formatFile = (d) => {
  return {
    fid: formatField(d, "fid"),
    data: formatField(d, "data"),
    created: formatField(d, "created"),
    filemime: formatField(d, "filemime"),
    filename: formatField(d, "filename"),
    filesize: formatField(d, "filesize"),
    url:
      d.data && d.data.uri && d.data.uri[0] && d.data.uri[0].url
        ? d.data.uri[0].url
        : null,
  };
};

export const formatEvent = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_it_description: formatField(d, "field_it_description"),
    field_ie_all_day: formatField(d, "field_ie_all_day"),
    body: formatField(d, "body"),
    summary: formatFieldByValue(d, "body", "summary"),
    field_ie_is_announcement: formatField(d, "field_ie_is_announcement"),
    field_ie_date_range: {
      start:
        d && d.field_ie_date_range && d.field_ie_date_range[0]
          ? d.field_ie_date_range[0].value
          : null,
      end:
        d && d.field_ie_date_range && d.field_ie_date_range[0]
          ? d.field_ie_date_range[0].end_value
          : null,
    },
    field_intranet_scopes: d.field_intranet_scopes
      ? d.field_intranet_scopes.map((scope) => scope.value)
      : [],
    field_intranet_assigned_users: formatFieldAssignedUsers(
      d,
      "field_intranet_assigned_users"
    ),
    field_intranet_specific_members: formatFieldSpecificEntity(
      d,
      "field_intranet_specific_members"
    ),
    field_intranet_specific_custs: formatFieldSpecificEntity(
      d,
      "field_intranet_specific_custs"
    ),
    field_intranet_specific_m_roles: d.field_intranet_specific_m_roles
      ? d.field_intranet_specific_m_roles.map((role) => role.target_id)
      : [],
    field_intranet_mem_affiliations: getValuesFromFieldArray(
      d,
      "field_intranet_mem_affiliations"
    ),
  };
};

export const formatPost = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_in_date: formatField(d, "field_in_date"),
    author: getUserFields(d, "uid"),
    body: formatField(d, "body"),
    summary: formatFieldByValue(d, "body", "summary"),
    field_in_categories: formatField(d, "field_in_categories"),
    field_in_images: getFileFields(d, "field_in_images"),
    field_in_pinned: formatField(d, "field_in_pinned"),
  };
};

export const formatIntranetResource = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_if_description: formatField(d, "field_if_description"),
    field_if_folders: getTaxonomyFields(d, "field_if_folders"),
    field_if_file: getFileFields(d, "field_if_file"),
    field_intranet_scopes: d.field_intranet_scopes
      ? d.field_intranet_scopes.map((scope) => scope.value)
      : [],
    field_intranet_assigned_users: formatFieldAssignedUsers(
      d,
      "field_intranet_assigned_users"
    ),
    field_intranet_specific_members: formatFieldSpecificEntity(
      d,
      "field_intranet_specific_members"
    ),
    field_intranet_specific_custs: formatFieldSpecificEntity(
      d,
      "field_intranet_specific_custs"
    ),
    field_intranet_specific_m_roles: d.field_intranet_specific_m_roles
      ? d.field_intranet_specific_m_roles.map((role) => role.target_id)
      : [],
    field_intranet_mem_affiliations: getValuesFromFieldArray(
      d,
      "field_intranet_mem_affiliations"
    ),
  };
};


export const reactMarkdownDefaults = () => {
  return {
    p: ({ node, ...props }) => (
      <p style={{ marginBottom: "1rem" }} {...props} />
    ),
  }
}

export const reactMarkdownTruncate = () => {
  return {
    p: ({ node, ...props }) => (
      <p style={{ marginBottom: "1rem", textOverflow: 'ellipsis' }} {...props} />
    ),
  }
}

export const formatMemberGeneralDoc = (d) => {
  return {
    id: formatField(d, "id"),
    rid: formatField(d, "revision_id"),
    field_mogd_date: formatField(d, "field_mogd_date"),
    field_mogd_folder: getTaxonomyFields(d, "field_mogd_folder"),
  };
};

export const formatCOS = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    field_coc_owner_agent: formatField(d, "field_coc_owner_agent"),
    field_coc_street_address: formatField(d, "field_coc_street_address"),
    field_coc_claim_number: formatField(d, "field_coc_claim_number"),
    field_coc_date: formatField(d, "field_coc_date"),
    field_coc_owner_agent_signature: formatField(
      d,
      "field_coc_owner_agent_signature"
    ),
    field_coc_owner_agent_print_name: formatField(
      d,
      "field_coc_owner_agent_print_name"
    ),
    field_coc_owner_agent_date: formatField(d, "field_coc_owner_agent_date"),
  };
};

export const formatCIS = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    billingTo: formatField(d, "field_cis_billing_to"),
    callFirstNumber: formatField(d, "field_cis_call_fst_number"),
    callFirstName: formatField(d, "field_cis_call_fst_name"),
    customerName: formatField(d, "field_cis_customer_name"),
    customerSignature: formatField(d, "field_cis_customer_signature"),
    garageCode: formatField(d, "field_cis_garage_code"),
    lockBoxNumber: formatField(d, "field_cis_lock_box_number"),
    returnDate: formatField(d, "field_cis_return_date"),
    field_cis_billing_to: formatField(d, "field_cis_billing_to"),
    field_cis_call_fst_number: formatField(d, "field_cis_call_fst_number"),
    field_cis_call_fst_name: formatField(d, "field_cis_call_fst_name"),
    field_cis_customer_name: formatField(d, "field_cis_customer_name"),
    field_cis_garage_code: formatField(d, "field_cis_garage_code"),
    field_cis_lock_box_number: formatField(d, "field_cis_lock_box_number"),
    field_cis_return_date: formatField(d, "field_cis_return_date"),
    items: formatCISItems(d, "field_cis_items"),
  };
};

export const formatMFDoc = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    field_mfd_type: formatField(d, "field_mfd_type"),
    field_mfd_file: getFileFields(d, "field_mfd_file"),
    field_mfd_title: formatField(d, "field_mfd_title"),
  };
};

export const formatMFMonitoringChecklist = (d) => {
  return {
    id: formatField(d, "id"),
    revisionId: formatField(d, "revision_id"),
    field_mfmc_items: formatMultipleTaxonomyReferences(d, "field_mfmc_items"),
    field_mfmc_notes: formatField(d, "field_mfmc_notes"),
    field_mfmc_truck_no: formatField(d, "field_mfmc_truck_no"),
  };
};

export const formatMultipleTaxonomyReferences = (obj, field) => {
  if (obj[field] && isArray(obj[field])) {
    return obj[field].map((item) => ({
      tid: formatField(item, "tid"),
      name: formatField(item, "name"),
      machine_name: formatField(item, "field_machine_name"),
    }));
  } else {
    return [];
  }
};

export const formatMFOptions = (d) => {
  const params = {};

  if (d?.field_mf_options) {
    d.field_mf_options.forEach((option) => {
      const field = formatField(option, "field_mfmo_option");
      const value = formatField(option, "field_mfmo_value");

      if (field) {
        params[field] = value;
      }
    });
  }

  return params;
};

export const formatDrupalErrors = (string, fieldLabels) => {
  let output = string.replace(/Unprocessable Entity: validation failed./gi, '').trim();
  for(let index in fieldLabels){
    output = output.replace(new RegExp(`${index}:`, 'gi'), `${fieldLabels[index]}:`);
  }
  return output;
};
