import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import conversationsAPI from "./conversationsAPI";
import photosAPI from "./photosAPI";
import { parseHubEnvParams } from "features/Hub/hubSlice.js";

const namespace = "conversations";

export const postConversation = createAsyncThunk(
  "conversations/postConversation",
  async (params, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.addConversation({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const findConversation = createAsyncThunk(
  "conversations/findConversation",
  async (params, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.findConversation({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteConversation = createAsyncThunk(
  "conversations/deleteConversation",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      await conversationsAPI.deleteConversation({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return id;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postMessage = createAsyncThunk(
  "conversations/postMessage",
  async (params, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.addMessage({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Socket-ID": params?.socketId,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddReaction = createAsyncThunk(
  "conversations/patchAddReaction",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.addReaction({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveReaction = createAsyncThunk(
  "conversations/patchRemoveReaction",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.removeReaction({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConversation = createAsyncThunk(
  "conversations/fetchConversation",
  async ({ id, ignoreMessages, config }, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.getConversation({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMessages = createAsyncThunk(
  "conversations/fetchMessages",
  async ({ id, params, config }, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.getMessages({
        id,
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchNewMessages = createAsyncThunk(
  "conversations/fetchNewMessages",
  async ({ id, params, config }, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.getMessages({
        id,
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConversations = createAsyncThunk(
  "conversations/fetchConversations",
  async (config, { rejectWithValue, getState }) => {
    try {
      // const clientNid = getState().app?.selectedClient?.client?.nid;
      const params = {};
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.getConversations({
        params: params/*{
          client_nid: clientNid
        }*/,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postPhoto = createAsyncThunk(
  "conversations/postPhoto",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await photosAPI.savePhoto({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postPreview = createAsyncThunk(
  "conversations/postPreview",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await conversationsAPI.addPreview({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "conversations/deleteMessage",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      await conversationsAPI.deleteMessage({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return id;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const conversationsAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const conversationsSlice = createSlice({
  name: namespace,
  initialState: conversationsAdapter.getInitialState({
    loading: false,
    error: null,
    conversation: conversationsAdapter.getInitialState({
      loading: false,
      data: {},
      error: null,
      messages: conversationsAdapter.getInitialState({
        loading: false,
        adding: null,
        pagination: {
          last_page: 1,
          current_page: 1,
          total: 1,
        },
      }),
    }),
  }),
  reducers: {
    resetConversations: (state) => {
      conversationsAdapter.removeAll(state.conversation.messages);
      conversationsAdapter.removeAll(state.conversation);
      conversationsAdapter.removeAll(state);
      state.loading = false;
      state.error = null;
      state.conversation.loading = false;
      state.conversation.error = null;
      state.conversation.data = {};
    },
    resetConversation: (state) => {
      conversationsAdapter.removeAll(state.conversation.messages);
      conversationsAdapter.removeAll(state.conversation);
      state.loading = false;
      state.error = null;
      state.conversation.loading = false;
      state.conversation.error = null;
      state.conversation.data = {};
    },
    setAllConversations: (state, action) => {
      conversationsAdapter.setAll(state, action);
    },
    removeOneConversation: (state, action) => {
      conversationsAdapter.removeOne(state, action);
    },
    addOneMessage: (state, action) => {
      conversationsAdapter.addOne(state.conversation.messages, action);
    },
    removeOneMessage: (state, action) => {
      conversationsAdapter.removeOne(state.conversation.messages, action);
    },
    removeAllMessages: (state) => {
      conversationsAdapter.removeAll(state.conversation.messages);
    },
    updateConversation: (state, { payload: conversation }) => {
      conversationsAdapter.updateOne(state, { id: conversation.id, changes: conversation });
      if(state.conversation.data.id === conversation.id){
        state.conversation.data = {
          ...state.conversation.data,
          ...conversation,
        };
      }
    },
  },
  extraReducers: {
    [postConversation.fulfilled](state, { payload: conversation }) {
      conversationsAdapter.addOne(state, conversation);
      if(conversation && conversation?.latest_messages){
        conversationsAdapter.setAll(
          state.conversation.messages,
          conversation.latest_messages
        );
      }
    },
    [findConversation.fulfilled](state, { payload: conversation }) {
      // if(conversation.id > 0){
      //   conversationsAdapter.addOne(state, conversation);
      //   conversationsAdapter.setAll(
      //     state.conversation.messages,
      //     conversation.latest_messages
      //   );
      // }
      // else{
      //   conversationsAdapter.removeAll(state.conversation.messages);
      //   conversationsAdapter.removeAll(state.conversation);
      // }
      state.conversation.loading = false;
      state.conversation.data = { ...conversation, unread_count: 0 };
      conversationsAdapter.updateOne(state, {
        id: conversation.id,
        changes: { unread_count: 0 },
      });
      if(conversation?.messages){
        conversationsAdapter.setAll(
          state.conversation.messages,
          conversation.messages.data
        );
        state.conversation.messages.pagination = {
          last_page: conversation.messages.last_page,
          current_page: conversation.messages.current_page,
          total: conversation.messages.total,
        };
      }
    },
    [postMessage.pending](state, { meta: { arg: message } }) {
      state.conversation.messages.adding = message.uuid;
      conversationsAdapter.addOne(state.conversation.messages, {
        ...message,
        id: message.uuid,
        created_at: new Date().toString(),
        attachments: message?.attachments ? message.attachments.map((f) => ({ ...f, url: !f?.url ? f.src : f.url })) : [],
        pending: true,
      });
    },
    [postMessage.fulfilled](state, { payload: message, meta: { arg } }) {
      conversationsAdapter.removeOne(state.conversation.messages, arg.uuid);
      conversationsAdapter.addOne(state.conversation.messages, {
        ...message,
        attachments: message?.attachments ? message.attachments.map((f) => ({ ...f, url: !f?.url ? f.src : f.url })) : [],
        pending: false,
      });
      // conversationsAdapter.updateOne(state.conversation.messages, {
      //   id: arg.uuid,
      //   changes: {
      //     ...message,
      //     attachments: message?.attachments ? message.attachments.map((f) => ({ ...f, url: f.url })) : [],
      //     pending: false,
      //   },
      // });
      state.conversation.messages.adding = null;
    },
    [deleteMessage.fulfilled](state, { payload: id }) {
      conversationsAdapter.removeOne(state.conversation.messages, id);
    },
    [deleteConversation.fulfilled](state, { payload: id }) {
      // conversationsAdapter.removeAll(state.conversation.messages);
      // conversationsAdapter.removeAll(state.conversation);
      conversationsAdapter.removeOne(state, id);
    },
    [fetchConversations.pending](state, action) {
      state.loading = true;
    },
    [fetchConversations.fulfilled](state, { payload: conversations }) {
      state.loading = false;
      conversationsAdapter.setAll(state, conversations);
    },
    [fetchConversations.rejected](state, action) {
      state.loading = false;
    },
    [fetchConversation.pending](state, action) {
      state.conversation.loading = true;
    },
    [fetchConversation.fulfilled](state, { payload: conversation, meta }) {
      state.conversation.loading = false;
      state.conversation.data = { ...conversation, unread_count: 0 };
      conversationsAdapter.updateOne(state, {
        id: conversation.id,
        changes: { unread_count: 0 },
      });
      if(!meta?.arg?.ignoreMessages){
        conversationsAdapter.setAll(
          state.conversation.messages,
          conversation.messages.data
        );
        state.conversation.messages.pagination = {
          last_page: conversation.messages.last_page,
          current_page: conversation.messages.current_page,
          total: conversation.messages.total,
        };
      }
    },
    [fetchConversation.rejected](state, action) {
      state.conversation.loading = false;
    },
    [fetchMessages.pending](state, action) {
      state.conversation.messages.loading = true;
    },
    [fetchMessages.fulfilled](state, { payload: messages }) {
      state.conversation.messages.loading = false;
      conversationsAdapter.upsertMany(state.conversation.messages, messages.data);

      state.conversation.messages.pagination = {
        last_page: messages.last_page,
        current_page: messages.current_page,
        total: messages.total,
      };
    },
    [fetchMessages.rejected](state, action) {
      state.conversation.messages.loading = false;
    },
    [patchAddReaction.fulfilled](state, { payload: message }) {
      conversationsAdapter.updateOne(state.conversation.messages, {
        id: message.id,
        changes: message,
      });
    },
    [patchRemoveReaction.fulfilled](state, { payload: message }) {
      conversationsAdapter.updateOne(state.conversation.messages, {
        id: message.id,
        changes: message,
      });
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getConversationsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.conversations.loading
);

export const getConversationsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.conversations.pagination
);

export const conversationsSelectors = conversationsAdapter.getSelectors(
  (state) => state.conversations
);

export const messagesSelectors = conversationsAdapter.getSelectors(
  (state) => state.conversations.conversation.messages
);

export const messagesAddingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.conversations.conversation.messages.adding
);

export const {
  setAllConversations,
  removeOneConversation,
  addOneMessage,
  updateConversation,
  removeAllMessages,
  resetConversations,
  resetConversation,
  removeOneMessage,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
