import React from "react";

const ErrorBoundary = ({ error }) => {
  return (
    <div role="alert" className="flex items-center justify-center flex-col">
      <h1>Something went wrong</h1>
      <pre>{error.message}</pre>
      <button
        onClick={() => {
          window.location.reload();
        }}
        className="p-2 rounded bg-blue-alt text-white uppercase mt-8 font-bold"
      >
        Hit Refresh
      </button>
    </div>
  );
};

export default ErrorBoundary;
