import React from "react";
import ContentLoader from "react-content-loader";
import constants from "../constants";

const ChatSkeleton = props => {
  return (
    <ContentLoader
      height={54}
      width={320}
      speed={1}
      viewBox="0 0 320 54"
      style={{ margin: "20px", display: "block" }}
      foregroundColor={constants.colorGray}
      backgroundColor={constants.colorGrayLight}
    >
      <circle cx="27" cy="27" r="18" />
      <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
      <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
      <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
    </ContentLoader>
  );
};

export default ChatSkeleton;
