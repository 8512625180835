import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { size } from "lodash";
import {
  Divider,
  MenuItem,
  ListItemIcon,
  Typography,
  InputAdornment,
  ListSubheader,
} from "@mui/material";
import moment from "moment";
import { GetApp, Delete, MoreVert, Launch } from "@mui/icons-material";
import { RiShareFill, RiImageEditLine } from "react-icons/ri";
import { removeCurrencyFormatting } from "utility";

import { IconButtonDropdown } from "common/ButtonDropdown";
import ConfirmationDialog from "components/ConfirmationDialog";
import Button from "common/Button";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";
import { SwitchFormField, } from "common/Fields";
// import config from "config";

import {
  deleteJobDivisionFile,
  downloadFile,
  patchJobDivisionFile,
  patchMultipleJobDivisionFiles,
} from "features/Files/filesSlice";
import { jobDivisionFileCategoriesSelectors } from "features/Taxonomies/taxonomiesSlice";
import { workAreasSelectors } from "features/WorkAreas/workAreasSlice";
import { chambersSelectors } from "features/Chambers/chambersSlice";
import { fetchJobProgress } from "features/JobProgress/jobProgressSlice";
import Share from "./Share";
import { openExternalFile } from "../utils";
import { getDivisionDataSelector } from "features/Job/jobSlice";

const filterCategories = ["Invoice", "Client Invoice", "Client Estimate", "Estimate", "PO"];

const filterDSCategories = [
  "DS Equipment Hand Written Sheet",
  "DS Expense Hand Written Sheet",
  "DS Service Hand Written Sheet",
];

const FileSettings = ({
  file,
  id,
  divisionNID,
  url,
  onSelect,
  category,
  selected,
  selectedFiles,
  trigger,
  disabled,
  formData,
  setFormData,
  references,
}) => {
  const history = useHistory();
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const areas = useSelector(workAreasSelectors.selectAll);
  const chambers = useSelector(chambersSelectors.selectAll);
  const categories = useSelector(jobDivisionFileCategoriesSelectors.selectAll);
  const auth = useSelector((state) => state.auth.token);
  const division = useSelector(getDivisionDataSelector);
  const roleType = useSelector((state) => state.auth.user.data.roleType);
  const hasMBYAgent = useSelector((state) => (division ? division?._processed?.has_mby_agent : false));

  const [openDelete, setOpenDelete] = useState(false);

  const getCategoryProp = useCallback(
    (id, query, field) => {
      const entity = categories.find((g) => `${g[query]}` === `${id}`);

      return entity?.[field];
    },
    [categories]
  );

  const toggleDelete = () => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
    setOpenDelete(true);
  };

  const handleDownload = async () => {
    dispatch(
      downloadFile({
        id: file.field_file.fid,
        filename: file.field_file.filename,
        token: file.field_file.file_dl_token,
      })
    );
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  const handleDelete = async () => {
    dispatch(deleteJobDivisionFile({ id, file }));
    setOpenDelete(false);
  };

  let initCategory = "";
  if(file && file.field_file_category.tid){
    initCategory = file.field_file_category.tid;
  }
  else if(category){
    initCategory = getCategoryProp(category, "name", "tid");
  }
  if(!size(categories.find(cat => cat.tid === initCategory))){
    initCategory = "";
  }

  return (
    <>
      <IconButtonDropdown
        icon={MoreVert}
        tooltip="Actions"
        size="small"
        ref={dropdownRef}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        trigger={trigger}
        disabled={disabled}
      >
        <div style={{ width: 250 }}>
          <Formik
            initialValues={{
              field_file_category: initCategory,
              field_gf_photo_time: file && file.field_gf_photo_time ? file.field_gf_photo_time : "",
              field_addl_ref: (file && file?.field_addl_ref ? file.field_addl_ref : ""),
              field_gf_amount: file && file.field_gf_amount,
              field_gf_date: file && file.field_gf_date
                ? moment(file && file.field_gf_date)
                : null,
              edit_visibility: (file ? true : false),
              phx_client: roleType === 'phx_client' || (file && file.field_visibility.includes("phx_client")),
              member: roleType === 'member' || (file && file.field_visibility.includes("member")),
              customer: roleType === 'customer' || (file && file.field_visibility.includes("customer")),
              mby_agent: roleType === 'agent' || (file && file.field_visibility.includes("mby_agent")),
            }}
            onSubmit={(data, { resetForm }) => {
              const params = {
                time: data.field_gf_photo_time,
                reference_nid: data.field_addl_ref,
                field_gf_amount: removeCurrencyFormatting(data.field_gf_amount),
                field_gf_date: data.field_gf_date
                  ? moment(data.field_gf_date).format("YYYY-MM-DD")
                  : null,
              };
              if(data?.field_file_category && data.field_file_category !== ""){
                params.field_file_category = data.field_file_category;
                params.field_file_category_name = getCategoryProp(
                  data.field_file_category,
                  "tid",
                  "name"
                );
              }
              if(data.edit_visibility){
                params.phx_client = data.phx_client;
                params.member = data.member;
                params.customer = data.customer;
                params.mby_agent = data.mby_agent;
              }
              if(file){
                dispatch(
                  patchJobDivisionFile({
                    id,
                    params: params,
                  })
                ).then((res) => {
                  dispatch(fetchJobProgress(division.nid));
                });
              }
              else if(size(selectedFiles)){
                params.division_nid = division.nid;
                params.nids = selectedFiles;
                dispatch(
                  patchMultipleJobDivisionFiles({
                    params: params,
                  })
                ).then((res) => {
                  dispatch(fetchJobProgress(division.nid));
                });
              }
              else if(setFormData){
                let data = formData;
                selected.forEach((id) => {
                  data = {
                    ...data,
                    [id]: {
                      ...data[id],
                      ...params,
                      reference: params.reference_nid,
                      category: params.field_file_category,
                      amount: params.field_gf_amount,
                      date: params.field_gf_date,
                    },
                  };
                });
                setFormData(data);
              }

              resetForm({
                values: {
                  field_gf_photo_time: data.field_gf_photo_time,
                  field_addl_ref: data.field_addl_ref,
                  field_gf_amount: data.field_gf_amount,
                  field_file_category: data.field_file_category,
                  field_gf_date: data.field_gf_date
                    ? moment(data.field_gf_date)
                    : null,
                  edit_visibility: data.edit_visibility,
                  phx_client: data.phx_client,
                  member: data.member,
                  customer: data.customer,
                  mby_agent: data.mby_agent,
                },
              });

              if (dropdownRef.current) {
                dropdownRef.current.close();
              }
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              validateField,
              setFieldValue,
              submitForm,
              dirty,
            }) => (
              <Form>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ padding: "1.25rem 1.25rem 0 1.25rem" }}
                >
                  Actions
                </Typography>
                <div style={{ padding: "0 1.25rem 1rem 1.25rem" }}>
                  {category !== "Photos" &&
                    <SelectFormField
                      variant="filled"
                      size="small"
                      fullWidth
                      margin="normal"
                      label="Category"
                      name="field_file_category"
                      options={categories.map((obj) => ({
                        value: obj.tid,
                        label: obj.name,
                      }))}
                    />
                  }
                  {(size(areas) > 0 || size(chambers) > 0 || size(references) > 0) && (
                    <SelectFormField
                      variant="filled"
                      size="small"
                      fullWidth
                      margin="normal"
                      label="Reference"
                      name="field_addl_ref"
                    >
                      {size(areas) > 0 && (
                        <ListSubheader>Areas</ListSubheader>
                      )}
                      {areas.map((obj) => (
                        <MenuItem key={obj.nid} value={obj.nid}>
                          {obj.title}
                        </MenuItem>
                      ))}
                      {size(chambers) > 0 && (
                        <ListSubheader>Chambers</ListSubheader>
                      )}
                      {chambers.map((obj) => (
                        <MenuItem key={obj.nid} value={obj.nid}>
                          {obj.title}
                        </MenuItem>
                      ))}
                      {references && references.map((obj) => (
                        <MenuItem key={obj.nid} value={obj.nid}>
                          {obj.title}
                        </MenuItem>
                      ))}
                    </SelectFormField>
                  )}
                  {(category === "Photos" || getCategoryProp(values.field_file_category, "tid", "name") === "Photos") && (
                    <SelectFormField
                      variant="filled"
                      size="small"
                      fullWidth
                      margin="normal"
                      label="Time"
                      name="field_gf_photo_time"
                      defaultValue=""
                      options={[
                        { value: "before", label: "Before" },
                        { value: "during", label: "During" },
                        { value: "after", label: "After" },
                      ]}
                    />
                  )}
                  {filterCategories.includes(
                    getCategoryProp(values.field_file_category, "tid", "name")
                  ) && (
                    <TextFormField
                      name="field_gf_amount"
                      variant="filled"
                      fullWidth
                      size="small"
                      label="Amount"
                      margin="normal"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  )}
                  {filterDSCategories.includes(
                    getCategoryProp(values.field_file_category, "tid", "name")
                  ) && (
                    <DateFormField
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="MM/DD/YYYY"
                      id="field_gf_date"
                      label="Day"
                      margin="normal"
                      size="small"
                      name="field_gf_date"
                      inputVariant="filled"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  )}
                  {(roleType && !file) &&
                    <SwitchFormField
                      name="edit_visibility"
                      label="Change Permissions"
                    />
                  }
                  {(roleType && (file || values.edit_visibility)) && (
                    <>
                    {roleType !== 'phx_client' && (
                      <div>
                        <CheckboxFormField name="phx_client" label={`${division?._processed.client_name} Admin`} />
                      </div>
                    )}
                    {roleType !== 'member' && (
                      <div>
                        <CheckboxFormField name="member" label="Member" />
                      </div>
                    )}
                    {roleType !== 'customer' && (
                      <div>
                        <CheckboxFormField name="customer" label="Customer" />
                      </div>
                    )}
                    {(hasMBYAgent && roleType !== 'agent' &&
                      <div>
                        <CheckboxFormField name="mby_agent" label="Agent" />
                      </div>
                    )}
                    </>
                  )}
                  {dirty && (
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      disableElevation
                      type="submit"
                      fullWidth
                      onClick={submitForm}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          {file &&
            <>
            <Divider />
            <MenuItem onClick={() => openExternalFile(file, auth)}>
              <ListItemIcon>
                <Launch fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">View</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (dropdownRef.current) {
                  dropdownRef.current.close();
                }
                history.push(`${url}/preview/${file.field_file.fid}/edit`);
              }}
            >
              <ListItemIcon>
                <RiImageEditLine fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Edit</Typography>
            </MenuItem>
            <Share id={id} fileShareType="job_division">
              <MenuItem>
                <ListItemIcon>
                  <RiShareFill fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Share</Typography>
              </MenuItem>
            </Share>
            <MenuItem onClick={handleDownload}>
              <ListItemIcon>
                <GetApp fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Download</Typography>
            </MenuItem>
            <MenuItem onClick={toggleDelete}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Remove</Typography>
            </MenuItem>
            </>
          }
        </div>
      </IconButtonDropdown>
      <ConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onSubmit={handleDelete}
        title="Are you sure?"
        body="Are you sure you want to delete this item?"
        isSubmitting={false}
        submitButton="Delete"
      />
    </>
  );
};

FileSettings.propTypes = {};

export default FileSettings;
