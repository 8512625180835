import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  // getUserFields,
  // getFileFields,
} from "../../utility";

export const formatEstimate = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "estimate",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_est_date: formatField(d, "field_est_date"),
    field_est_customer: formatField(d, "field_est_customer"),
    field_est_customer_loc: formatField(d, "field_est_customer_loc"),
    field_est_job_division: formatField(d, "field_est_job_division"),
    field_est_status: formatField(d, "field_est_status"),
    field_est_notes: formatField(d, "field_est_notes"),
    field_est_subtotal: formatField(d, "field_est_subtotal"),
    field_est_tax_rate: formatField(d, "field_est_tax_rate"),
    field_est_tax: formatField(d, "field_est_tax"),
    field_est_discount_type: formatField(d, "field_est_discount_type"),
    field_est_discount_percent: formatField(d, "field_est_discount_percent"),
    field_est_discount: formatField(d, "field_est_discount"),
    field_est_total: formatField(d, "field_est_total"),
  };
};

export const formatEstimateLineItem = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "line_item",
    id: formatField(d, "id"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    field_li_description: formatField(d, "field_li_description"),
    field_li_discount: formatField(d, "field_li_discount"),
    field_li_discount_percent: formatField(d, "field_li_discount_percent"),
    field_li_product_text: formatField(d, "field_li_product_text"),
    field_li_product_type: formatField(d, "field_li_product_type"),
    field_li_qty: formatField(d, "field_li_qty"),
    field_li_rate: formatField(d, "field_li_rate"),
    field_li_taxable: formatField(d, "field_li_taxable"),
    field_li_total: formatField(d, "field_li_total"),
  };
};
