import React from "react";
import { Field } from "formik";
import {
  OutlinedInput,
  InputLabel,
  Input,
  FilledInput,
  FormHelperText,
  FormControl,
} from "@mui/material";

const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\dxX]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }

  if (cvLength < 11) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }

  if (cvLength >= 11 && currentValue.toLowerCase().slice(10, 11) === "x") {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)} ${currentValue.slice(10)}`;
  }
  if (cvLength >= 12 && currentValue.toLowerCase().slice(11, 12) === "x") {
    return `${currentValue.slice(0, 1)} (${currentValue.slice(
      1,
      4
    )}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)} ${currentValue.slice(11)}`;
  }

  return `${currentValue.slice(0, 1)} (${currentValue.slice(
    1,
    4
  )}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)}`;
};

const MuiField = ({
  variant,
  error,
  labelwidth,
  endAdornment,
  onChange,
  onBlur,
  value,
  ...rest
}) => {
  if (variant === "outlined") {
    return (
      <OutlinedInput
        error={error}
        // labelwidth={labelwidth}
        endAdornment={endAdornment}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...rest}
      />
    );
  } else if (variant === "filled") {
    return (
      <FilledInput
        error={error}
        endAdornment={endAdornment}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...rest}
      />
    );
  } else {
    return (
      <Input
        error={error}
        endAdornment={endAdornment}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...rest}
      />
    );
  }
};

const TextFormField = ({
  name,
  fullWidth,
  label,
  variant,
  htmlFor,
  endAdornment,
  labelwidth,
  margin,
  required,
  shrink,
  size,
  helperText,
  isPhone,
  doNotExceed,
  ...props
}) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const handleChange = (e) => {
          if (isPhone) {
            const value = normalizeInput(e.target.value);
            form.setFieldValue(name, value);
          } else if (doNotExceed) {
            if (e.target.value <= Number(doNotExceed)) {
              form.setFieldValue(name, e.target.value);
            }
          } else {
            form.setFieldValue(name, e.target.value);
          }
        };

        const handleBlur = () => form.setFieldTouched(name, true);
        const errorText = meta.error && meta.touched ? meta.error : "";
        return (
          <FormControl
            fullWidth={fullWidth}
            variant={variant}
            error={!!errorText}
            margin={margin}
            size={size}
          >
            <InputLabel
              required={required}
              error={!!errorText}
              htmlFor={htmlFor ? htmlFor : name}
              shrink={shrink}
              color={props.color ? props.color : "primary"}
            >
              {label}
            </InputLabel>
            <MuiField
              variant={variant}
              endAdornment={endAdornment}
              labelwidth={labelwidth}
              error={!!errorText}
              value={field.value}
              onChange={handleChange}
              onBlur={handleBlur}
              required={required}
              label={label}
              {...props}
            />
            {!!errorText ? (
              <FormHelperText
                error={!!errorText}
                id="filled-weight-helper-text"
              >
                {errorText}
              </FormHelperText>
            ) : !!helperText ? (
              <FormHelperText id="filled-weight-helper-text">
                {helperText}
              </FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
    </Field>
  );
};

export default TextFormField;
