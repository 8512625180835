import { createSlice, createAsyncThunk, createDraftSafeSelector } from "@reduxjs/toolkit";
import axios from "axios";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const namespace = "jobProgress";

export const fetchJobProgress = createAsyncThunk(
  `${namespace}/fetchJobProgress`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/progress/data/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRefreshMilestones = createAsyncThunk(
  `${namespace}/patchRefreshMilestones`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.patch(
        `${config.api_url}/rest/milestones/scheme/refresh/${id}`,
        {},
        {
          ...tokenConfig(getState),
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchMilestoneCustomReq = createAsyncThunk(
  `${namespace}/patchMilestoneCustomReq`,
  async ({id, params}, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.patch(
        `${config.api_url}/rest/milestones/update-custom-reqs/${id}`,
        {
          ...params,
        },
        {
          ...tokenConfig(getState),
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const dailysheetsSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    custom_loading: false,
    data: {},
  },
  reducers: {
    setProgressData: (state, { payload: progress }) => {
      state.data = { ...state.data, ...progress };
    },
  },
  extraReducers: {
    [fetchJobProgress.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchJobProgress.fulfilled](state, { payload: progress }) {
      state.loading = false;
      state.error = null;
      state.data = progress;
    },
    [fetchJobProgress.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchMilestoneCustomReq.pending](state) {
      state.custom_loading = true;
      state.error = null;
    },
    [patchMilestoneCustomReq.fulfilled](state, { payload: progress }) {
      state.custom_loading = false;
      state.error = null;
      state.data = progress;
    },
    [patchMilestoneCustomReq.rejected](state, action) {
      state.custom_loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getJobProgressSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.jobProgress.data,
);
export const getJobProgressLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.jobProgress.loading,
);

export const { setProgressData } = dailysheetsSlice.actions;

export default dailysheetsSlice.reducer;
