import axios from "axios";
import conf from "config";
import getQueryParams from "utility/getQueryParams";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  getDirectories: async ({ params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/directories${queryParams}`, config);
  },
  getDirectory: async ({ id, config }) => {
    return instance.get(`/directories/${id}`, config);
  },
  addDirectory: async ({ params, config }) => {
    return instance.post(`/directories`, params, config);
  },
  addAdmin: async ({ id, params, config }) => {
    return instance.patch(`/directories/${id}/add-admin`, params, config);
  },
  removeAdmin: async ({ id, params, config }) => {
    return instance.patch(`/directories/${id}/remove-admin`, params, config);
  },
  uploadAttachment: async ({ id, params, config }) => {
    return instance.patch(
      `/directories/${id}/upload-attachment`,
      params,
      config
    );
  },
  updateDirectory: async ({ id, params, config }) => {
    return instance.patch(`/directories/${id}`, params, config);
  },
  inviteUser: async ({ id, params, config }) => {
    return instance.patch(`/directories/${id}/add-users`, params, config);
  },
  deleteDirectory: async ({ id, config }) => {
    return instance.delete(`/directories/${id}`, config);
  },
  deleteAttachment: async ({ id, config }) => {
    return instance.delete(`/attachments/${id}`, config);
  },
};

export default API;
