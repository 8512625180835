import React, { useRef } from "react";
import Button from "common/Button";

const FileUploader = ({ onClick, children, ...rest }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    onClick(fileUploaded);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleClick,
        })
      ) : (
        <Button onClick={handleClick}>Upload a file</Button>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        {...rest}
      />
    </>
  );
};

export default FileUploader;
