import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ disablePadding, subToolbar }) =>
    !subToolbar ?
      disablePadding ? "0 0 1.25rem 0" : "0 1.25rem 1.25rem 1.25rem"
      : ''
    };

  .left: {
  }

  .right: {
  }
`;

const Toolbar = ({ left, right, disablePadding, subToolbar }) => {
  return (
    <>
    <Styles disablePadding={disablePadding} subToolbar={subToolbar}>
      <div className="left">{left}</div>
      <div className="right">{right}</div>
    </Styles>
    {subToolbar && (<div className="sub-toolbar">{subToolbar}</div>)}
    </>
  );
};

Toolbar.propTypes = {};

export default Toolbar;
