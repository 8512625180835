import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
// import store from "store";
import {
  patchReadNotifications,
} from "components/Notification/notificationSlice";

const namespace = "toast";

export const addToast = createAsyncThunk(
  `${namespace}/addToast`,
  async (payload, { getState, signal, rejectWithValue, dispatch }) => {
  //  If the toast is blocked, reject
    const blocked = getState().toast.blocked;
    const block = blocked.find(b =>
      b.parent_type === payload.message.parent_type
      && b.parent_id === payload.message.parent_id
      && (!b?.type || b.type === payload.message.type)
    );
    if(!block){
      const current = toastSelectors.selectAll(getState());
      let newCurrent = [...current];
      // let changed = false;
      let toAdd = JSON.parse(JSON.stringify(payload));
      delete toAdd.message;
      const message = payload.message;

    //  See if the new notification fits into any existing groupings.
    //  If not, create a new grouping, and add it.
    //  Limit to 3 groupings
      if(!current.find(t => t.grouping_id === message.grouping_id)){
        newCurrent.unshift({
          ...toAdd,
          grouping_id: message.grouping_id,
          notifications: [message],
        });
        if(newCurrent.length > 4){
          newCurrent = newCurrent.slice(-4);
        }
        // changed = true;
      }
    // If found, add the notification to a grouping
    //  Limit to 3 notifications per grouping
      else{
        let newFront = null;
        newCurrent = newCurrent.filter(t => {
          if(t.grouping_id === message.grouping_id
              && !t.notifications.find(t2 => t2.uuid === message.uuid)){
            // changed = true;
            let newNotifications = [message, ...t.notifications];
            if(newNotifications.length > 3){
              newNotifications = newNotifications.slice(0, 4);
            }
            newFront = {
              ...t,
              notifications: [...newNotifications],
            };
            return false;
          }
          return true;
        });
        if(newFront){
          newCurrent.unshift(newFront);
        }
      }
      // if(changed){
      //   localStorage.setItem("toast", JSON.stringify(newCurrent));
      // }

      return {
        data: newCurrent,
        added: message.grouping_id,
      };
    }
    else{
      if(block?.onBlockPatchRead){
        dispatch(patchReadNotifications(block));
      }
      return rejectWithValue('Notification was blocked.');
    }
  }
);

export const setToast = createAsyncThunk(
  `${namespace}/setToast`,
  async (payload, { getState, signal, rejectWithValue, dispatch }) => {
  //  If the toast is blocked, reject
    const blocked = getState().toast.blocked;

    const toSet = [];
    payload.forEach((t) => {
      const block = blocked.find(b =>
        b.parent_type === t.parent_type
        && b.parent_id === t.parent_id
        && (!b?.type || b.type === t.type)
      );
      if(!block){
        toSet.push(t);
      }
      else if(block?.onBlockPatchRead){
        dispatch(patchReadNotifications(block));
      }
    });

    return toSet;
  }
);

export const removeToast = createAsyncThunk(
  `${namespace}/removeToast`,
  async ({uuid, parent_id, parent_type, type}, { getState, signal, rejectWithValue }) => {

  //  Remove Toast from local storage
    const current = toastSelectors.selectAll(getState());
    let groupingID = null;
    let updatedGrouping = null;
    // let newLocal = current.map(t => {
    current.forEach(t => {
      if(parent_id && parent_type){
        if(t?.parent_id){
          if(t.parent_id === parent_id && t.parent_type === parent_type && (!type || type === t.type)){
            groupingID = t.grouping_id;
          }
        }
        else if(t.notifications.find(t2 =>
          t2.parent_id === parent_id
          && t2.parent_type === parent_type
          && (!type || type === t2.type)
        )){
          groupingID = t.grouping_id;
        }
      }
      else{
        const newT = {...t};
        if(t.notifications.find(t2 => t2.uuid === uuid)){
          groupingID = t.grouping_id;
          newT.notifications = t.notifications.filter(t2 => t2.uuid !== uuid);
          if(newT.notifications.length === 0){
            return null;
          }
          updatedGrouping = newT;
        }
        return newT;
      }
    });
    // newLocal = newLocal.filter(t => t !== null);
    // localStorage.setItem("toast", JSON.stringify(newLocal));

    return {
      groupingID: groupingID,
      updatedGrouping: updatedGrouping,
    };
  }
);

const toastAdapter = createEntityAdapter({
  selectId: (toast) => toast.grouping_id,
});
const emptyInitialState = toastAdapter.getInitialState({
  // show: false,
  kind: null,
  // message: null,
  formatter: null,
  blocked: [],
  added: null,
  loading: false,
  error: null,
});
// const localToast = localStorage.getItem("toast");
// const haslocal = Boolean(localToast && JSON.parse(localToast));
// const filledState = toastAdapter.upsertMany(
//                       emptyInitialState,
//                       (haslocal ? JSON.parse(localToast) : [])
//                     );

const toastSlice = createSlice({
  name: namespace,
  // initialState: filledState,
  initialState: emptyInitialState,
  reducers: {
    // setToast: (state, action) => {
    //   state.show = true;
    //   state.kind = action.payload.kind;
    //   state.msg = action.payload.msg;
    //   state.formatter = action.payload?.formatter;
    // },
    blockToast: (state, {payload}) => {
      state.blocked.push(payload);
    },
    removeToastBlock: (state, {payload}) => {
      state.blocked = state.blocked.filter(b =>
        b.parent_type !== payload.parent_type
        && b.parent_id !== payload.parent_id
        && (!b?.type || b.type === payload.type)
      );
    },
    clearToast: (state) => {
      // state.show = false;
      state.kind = null;
      state.formatter = null;
      toastAdapter.removeAll(state);
      localStorage.setItem("toast", JSON.stringify([]));
    },
    clearAdded: (state) => {
      state.added = null;
    },
    // hideToast: (state) => {
    //   state.show = false;
    // },
  },
  extraReducers: {
  //  Add Toast
    [addToast.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [addToast.fulfilled](state, { payload: {data, added} }) {
      state.loading = false;
      state.added = added;
      toastAdapter.setAll(state, data);
    },
    [addToast.rejected](state, { payload, meta }) {
      state.loading = false;
      state.error = payload;
    },
    [setToast.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [setToast.fulfilled](state, { payload }) {
      state.loading = false;
      toastAdapter.setAll(state, payload);
    },
    [setToast.rejected](state, { payload, meta }) {
      state.loading = false;
      state.error = payload;
    },

  //  Remove Toast
    [removeToast.pending](state, action) {
      state.error = null;
      state.loading = true;
    },
    [removeToast.fulfilled](state, { payload: {groupingID, updatedGrouping} }) {
      state.loading = false;
      if(updatedGrouping){
        toastAdapter.updateOne(state, {
          id: groupingID,
          changes: updatedGrouping,
        });
      }
      else{
        toastAdapter.removeOne(state, groupingID);
      }
    },
    [removeToast.rejected](state, action) {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getToastSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.toast
);
export const getToastAddedSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.toast.added
);
export const toastSelectors = toastAdapter.getSelectors(
  (state) => state.toast
);

export const { /*hideToast,*/ clearToast, clearAdded, blockToast, removeToastBlock } = toastSlice.actions;

export default toastSlice.reducer;
