import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileAPI from "./profileAPI";

const namespace = "profile";

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async ({ username, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await profileAPI.getProfile({
        username,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: namespace,
  initialState: {
    data: {},
    loading: true,
    error: null,
  },
  reducers: {
    updateProfile: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    resetProfile: (state, action) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
  },
  extraReducers: {
    [fetchProfile.pending](state, action) {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
    [fetchProfile.fulfilled](state, { payload: profile }) {
      state.loading = false;
      state.error = null;
      state.data = profile;
    },
    [fetchProfile.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const { updateProfile, resetProfile } = profileSlice.actions;

export default profileSlice.reducer;
