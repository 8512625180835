import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Helmet from "react-helmet";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import {
  Typography,
  CircularProgress,
  Stepper,
  Step,
  StepButton,
  Divider,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as IconCustomerLine } from "assets/images/icons/icon-customer-line.svg";

import Information from "./Information";
import Contacts from "./Contacts";
import Location from "./Location";
import Alert from "features/Alert";
import { DefaultLoader } from "common/Loader";
import * as Yup from "yup";
import { addCustomerFormSchema } from "schemas/forms/customer";
import { tmTermsSchema } from "components/TMTerms/forms/schema/TMTerms";

import { postCustomer } from "../customerSlice";
import { first } from "lodash";

const StyledStepper = withStyles({
  root: {
    background: "transparent",
  },
})(Stepper);

function getSteps() {
  return ["Information", "Contact", "Location"];
}

const STEP_FIELDS = [
  ["title", "field_phoenix_client", "field_note", "field_customer_status"],
  [],
  ["field_street_address", "field_billing_address"],
];

const hasValidationErrors = (fields, errors, value, index) => {
  if (value > index) {
    for (let i = 0; i < fields.length; i += 1) {
      if (errors[fields[i]]) {
        return true;
      }
    }
  }

  return false;
};

const isComplete = (fields, errors, value, index) => {
  if (value > index) {
    for (let i = 0; i < fields.length; i += 1) {
      if (errors[fields[i]]) {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

const Add = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const loadingUser = useSelector((state) => state.auth.user.loading);
  const user = useSelector((state) => state.auth.user.data);

  const [value, setValue] = useState(0);
  const [progress, setProgress] = useState({ show: false, message: "" });
  const [uploading, setUploading] = useState(false);
  const [fileId, setFileId] = useState(null);

  const handleNext = (validateForm) => () => {
    validateForm();
    setValue(value + 1);
  };

  const handlePrev = (validateForm) => () => {
    validateForm();
    setValue(value - 1);
  };

  const handleSubmit = async (data) => {
    try {
      setProgress({ show: true, message: "Creating Customer" });
      const params = {
        title: [{ value: data.title }],
        field_customer_status: [{ value: data.field_customer_status }],
        field_phoenix_client: [{ target_id: data.field_phoenix_client }],
        field_jda_invoice_template: [
          { value: data.field_jda_invoice_template },
        ],
        field_note: [{ value: data.field_note }],
        field_phone: [{ value: data.field_phone }],
        field_phone_after_hours: [{ value: data.field_phone_after_hours }],
        field_fax: [{ value: data.field_fax }],
        field_primary_poc: data.field_primary_poc
          ? [{ target_id: data.field_primary_poc.uid }]
          : [],
        field_secondary_pocs: data.field_secondary_pocs.map((u) => ({
          target_id: u.uid,
        })),
        field_after_hours_pocs: data.field_after_hours_pocs.map((u) => ({
          target_id: u.uid,
        })),
        field_street_address: [data.field_street_address],
        field_is_headquarters: [{ value: data.field_is_headquarters }],
        field_is_parent_customer: [{ value: data.field_is_parent_customer }],
        field_res_com: [{ value: data.field_res_com }],
        field_tm_terms: (data?.field_tm_terms ? [{value: JSON.stringify(data.field_tm_terms)}] : null),
      };

      if (fileId) {
        params.field_logo = [{ target_id: fileId }];
      }
      if (data.field_phx_client_rep) {
        params.field_phx_client_rep = [
          { target_id: data.field_phx_client_rep },
        ];
      }
      if (data.field_national_contracts) {
        params.field_national_contracts = data.field_national_contracts.map((tid) => ({ target_id: tid }));
      }

      if (id) {
        params._meta = {
          parent_customer_nid: id,
        };
      }

      const resultAction = await dispatch(postCustomer(params));

      unwrapResult(resultAction);
      history.push(resultAction.payload.self);
    } catch (err) {
      setProgress({ show: false, message: "" });
    }
  };

  const steps = getSteps();

  return (
    <div
      style={{
        flex: 1,
        overflow: "auto",
        background: "var(--color-gray-background)",
      }}
    >
      <Helmet>
        <title>Add Customer</title>
        <meta name="description" content="Add Customer" />
      </Helmet>
      {loadingUser ? (
        <DefaultLoader />
      ) : (
        <Formik
          initialValues={{
            title: "",
            field_customer_status: "",
            field_phx_client_rep: "",
            field_phoenix_client: first(user.field_clients)?.nid,
            field_jda_invoice_template: "default",
            field_note: "",
            field_phone: "",
            field_phone_after_hours: "",
            field_fax: "",
            field_primary_poc: null,
            field_secondary_pocs: [],
            field_after_hours_pocs: [],
            field_national_contracts: [],
            billing_same_as_street: true,
            field_street_address: {
              address_line1: "",
              address_line2: "",
              locality: "",
              administrative_area: "",
              postal_code: "",
              country_code: "US",
            },
            field_billing_address: {
              address_line1: "",
              address_line2: "",
              locality: "",
              administrative_area: "",
              postal_code: "",
              country_code: "US",
            },
            field_is_headquarters: false,
            field_is_parent_customer: false,
            field_res_com: "commercial",
            field_tm_terms: null,
          }}
          validationSchema={Yup.object().shape({
            ...addCustomerFormSchema.fields,
            ...tmTermsSchema.fields,
          })}
          onSubmit={async (data, { setSubmitting, setFieldValue }) => {
            setSubmitting(true);
            await handleSubmit(data);
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue, validateForm, validateField }) => (
            <Form>
              <div
                style={{
                  padding: "1.25rem",
                  maxWidth: 800,
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                {progress.show && (
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      background: "rgba(245, 242, 247, 0.5)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                    }}
                  >
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: "2rem" }}>
                      {progress.message}
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    padding: "2rem 0",
                  }}
                >
                  <Typography variant="h6">{<IconCustomerLine className="header-icon -mb-1" />} Add Customer</Typography>
                  <Typography variant="body1" color="textSecondary">
                    Complete the steps below to create a new Customer.
                  </Typography>
                  <Divider style={{ margin: "2rem 0" }} />
                  <StyledStepper nonLinear activeStep={value}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const buttonProps = {};
                      const hasErrors = hasValidationErrors(
                        STEP_FIELDS[index],
                        errors,
                        value,
                        index
                      );
                      const stepComplete = isComplete(
                        STEP_FIELDS[index],
                        errors,
                        value,
                        index
                      );

                      if (hasErrors) {
                        buttonProps.error = true;
                      }
                      if (stepComplete) {
                        stepProps.completed = true;
                      }

                      return (
                        <Step key={label} {...stepProps}>
                          <StepButton
                            onClick={() => {
                              validateForm();
                              setValue(index);
                            }}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      );
                    })}
                  </StyledStepper>
                  <Alert margin="none" disableElevation disableRoundedCorners />
                </div>
                <Information
                  value={value}
                  handleNext={handleNext(validateForm)}
                  isSubmitting={isSubmitting}
                  values={values}
                  setFileId={setFileId}
                  setUploading={setUploading}
                  setFieldValue={setFieldValue}
                  isParent={id}
                  validateField={validateField}
                />
                <Contacts
                  value={value}
                  handleNext={handleNext(validateForm)}
                  handlePrev={handlePrev(validateForm)}
                  isSubmitting={isSubmitting}
                  values={values}
                />
                <Location
                  value={value}
                  handlePrev={handlePrev(validateForm)}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  values={values}
                  setFieldValue={setFieldValue}
                  uploading={uploading}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

Add.propTypes = {};

export default Add;
