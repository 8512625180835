import React, { useEffect } from "react";
import { NewspaperIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchArticles,
  vendorArticlesSelectors,
} from "features/VendorArticles/vendorArticlesSlice";
import { Skeleton } from "@mui/material";
// import { Link } from "react-router-dom";
import Card from "common/Card";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import ReactMarkdown from "react-markdown";
// import { reactMarkdownDefaults, reactMarkdownTruncate } from "utility/format";
// import {renderToStaticMarkup} from 'react-dom/server';
// import TruncateMarkup from 'react-truncate-markup';
// import parse from 'html-react-parser';
import TruncateReactMarkdown from 'common/TruncateReactMarkdown';

const defaultCover = `${process.env.PUBLIC_URL}/images/default-group.png`;

const ArticleWidget = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.articles.loading);
  const articles = useSelector(vendorArticlesSelectors.selectAll);
  const history = useHistory();

  useEffect(() => {
    const promise = dispatch(fetchArticles());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  if (articles.length === 0 && !loading) return null;

  return (
    <Card className="p-4 my-2 grid-lg:my-4">
      <header className="flex items-center">
        <div className="rounded-full h-6 w-6 mr-2">
          <NewspaperIcon className="h-6 w-6 text-gray-dark" />
        </div>
        <h6 className="text-lg font-semibold text-gray-darkest">Articles</h6>
      </header>
      <main>
        {loading ? (
          <div className="mb-2 flex items-center">
            <div className="flex-1 mr-2">
              <Skeleton height={24} width={100} variant="text" />
              <Skeleton height={18} width={100} variant="text" />
              <Skeleton height={14} width={75} variant="text" />
            </div>
            <Skeleton
              height={70}
              width={70}
              variant="rectangle"
              className="rounded-lg"
            />
          </div>
        ) : (
          <ul>
            {articles.length > 0 ? (
              articles.map((event, i) => (
                <li
                  className="my-2 flex items-center text-black cursor-pointer"
                  key={event.id}
                  onClick={() => {history.push(`/hub/articles/${event.id}`);}}
                >
                  <div className="flex-1">
                    <h6 className="mb-2 text-large font-semibold">
                      {event.title}
                    </h6>
                    <p className="pl-2 text-sm">{moment(event.start).format("MM/DD/YY")}</p>
                    {/* {renderToStaticMarkup(<ReactMarkdown components={reactMarkdownDefaults()} children={event.summary} />)} */}
                    {/* <div className="max-h-10 text-ellipsis overflow-hidden"><ReactMarkdown components={reactMarkdownTruncate()} children={event.body} /></div> */}
                    <TruncateReactMarkdown lines={4}>{event.body}</TruncateReactMarkdown>
                  </div>
                  <div className="bg-gradient-to-r from-blue to-blue-alt h-[70px] w-[70px] ml-4 rounded-lg">
                    <img
                      className="w-full h-full rounded-lg object-cover object-center"
                      src={event.cover_url ? event.cover_url : defaultCover}
                      alt="cover"
                    />
                  </div>
                </li>
              ))
            ) : (
              <li className="mb-2">
                <div className="flex items-center text-gray-dark">
                  <div className="flex-1">
                    <h6 className="mb-2 text-large font-semibold">
                      No Articles
                    </h6>
                    <p>There are no new articles.</p>
                  </div>
                </div>
              </li>
            )}
          </ul>
        )}
      </main>
    </Card>
  );
};

ArticleWidget.propTypes = {};

export default ArticleWidget;
