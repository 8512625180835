import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { formatUserForManage, formatTempUserForListing } from "./utils";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "users";

export const fetchUsers = createAsyncThunk(
  `${namespace}/fetchUsers`,
  async ({ param1, assignedOnly, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/users/${param1 ? param1 : ""}${
          assignedOnly ? `/${assignedOnly}` : ""
        }${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );
      return {
        users: response.data.data.map(formatUserForManage),
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerUsers = createAsyncThunk(
  `${namespace}/fetchCustomerUsers`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/users/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        users: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreCustomerUsers = createAsyncThunk(
  `${namespace}/fetchMoreCustomerUsers`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/users/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        users: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberUsers = createAsyncThunk(
  `${namespace}/fetchMemberUsers`,
  async (
    { id, params, onlyAssigned },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/users/${id}${
          onlyAssigned ? "" : "/true"
        }${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        users: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberUsersTemp = createAsyncThunk(
  `${namespace}/fetchMemberUsersTemp`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/users-w-temp/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        users: response.data.data.map((u) => ({
          ...u,
          uid: u.entity_id,
          name: u.full_name,
        })),
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDailySheetsUsers = createAsyncThunk(
  `${namespace}/fetchDailySheetsUsers`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/users/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const mappedUsers = response.data.data.map((user) => ({
        uid: user.ds_user_uid ? user.ds_user_uid : user.ds_user_temp_nid,
        field_first_name: user.ds_user_first_name,
        field_last_name: user.ds_user_last_name,
        field_temp_nid: user.ds_user_temp_nid,
        name: user.ds_user_full_name,
      }));

      return {
        users: mappedUsers,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPhxClientUsers = createAsyncThunk(
  `${namespace}/fetchPhxClientUsers`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/users/phx_client${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const mappedUsers = response.data.data.map((user) => ({
        uid: user.uid,
        field_first_name: user.first_name,
        field_last_name: user.last_name,
        name: user.name,
      }));

      return {
        users: mappedUsers,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postMemberUser = createAsyncThunk(
  `${namespace}/postMemberUser`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/member/add/user`,
        params,
        tokenConfig(getState)
      );

      if (response.data._warning_messages) {
        dispatch(
          setAlert({
            show: true,
            kind: "warning",
            msg: `<div>
                ${response.data._warning_messages.map(
                  (msg) => `<div>${msg}</div>`
                )}
              </div>`,
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            kind: "positive",
            msg: "Successfully added user",
          })
        );
      }

      const user = {
        uid: params.uid,
        name: `${params.first_name} ${params.last_name}`,
        first_name: params.first_name,
        last_name: params.last_name,
        mail: params.mail,
        _paths: { user: "" },
        _files: { profile_pic: null },
      };

      return user;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding user: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postTemporaryUser = createAsyncThunk(
  `${namespace}/postTemporaryUser`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/temporary_user`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatTempUserForListing(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding user: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const stateUserAddUpdateSubAction = async ({ uid }, { getState, signal, rejectWithValue }) => {
  try {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await axios.get(
      `${config.api_url}/rest/users/manage?filter[uid][0]=${uid}`,
      {
        ...tokenConfig(getState),
        cancelToken: source.token,
      }
    );
    return {
      users: response.data.data.map(formatUserForManage),
      pagination: response.data.pagination,
    };
  } catch (err) {
    let error = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data);
  }
}

export const addStateUser = createAsyncThunk(
  `${namespace}/addStateUser`,
  stateUserAddUpdateSubAction
);

export const updateStateUser = createAsyncThunk(
  `${namespace}/updateStateUser`,
  stateUserAddUpdateSubAction
);

const usersAdapter = createEntityAdapter({
  selectId: (user) => user.uid,
});

const usersSlice = createSlice({
  name: namespace,
  initialState: usersAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    accountReps: usersAdapter.getInitialState({
      loading: false,
    }),
    all: usersAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
      error: null,
    }),
  }),
  reducers: {
    setAllUsers: usersAdapter.setAll,
    addOneUser: usersAdapter.addOne,
    // updateOneUser(state, {payload: changes}) {
    //   usersAdapter.updateOne(state.all, {
    //     id: String(changes.id),
    //     changes: changes,
    //   })
    // },
  },
  extraReducers: {
    [fetchUsers.pending](state) {
      state.all.loading = true;
      state.all.error = null;
    },
    [fetchUsers.fulfilled](state, { payload: {users, pagination} }) {
      usersAdapter.setAll(state.all, users);
      state.all.loading = false;
      state.all.pagination = pagination;
      state.all.error = null;
    },
    [fetchUsers.rejected](state, action) {
      state.all.loading = false;
      if (action.payload) {
        state.all.error = action.payload.message;
      } else {
        state.all.error = action.error.message;
      }
    },
    [fetchCustomerUsers.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchCustomerUsers.fulfilled](state, { payload: { users, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      state.error = null;
      usersAdapter.setAll(state, users);
    },
    [fetchCustomerUsers.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMoreCustomerUsers.pending](state) {
      state.error = null;
    },
    [fetchMoreCustomerUsers.fulfilled](
      state,
      { payload: { users, pagination } }
    ) {
      state.pagination = pagination;
      state.error = null;
      usersAdapter.addMany(state, users);
    },
    [fetchMoreCustomerUsers.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMemberUsers.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchMemberUsers.fulfilled](state, { payload: { users, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      state.error = null;
      usersAdapter.setAll(state, users);
    },
    [fetchMemberUsers.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMemberUsersTemp.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchMemberUsersTemp.fulfilled](
      state,
      { payload: { users, pagination } }
    ) {
      state.loading = false;
      state.pagination = pagination;
      state.error = null;
      usersAdapter.setAll(state, users);
    },
    [fetchMemberUsersTemp.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchDailySheetsUsers.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchDailySheetsUsers.fulfilled](
      state,
      { payload: { users, pagination } }
    ) {
      state.loading = false;
      state.pagination = pagination;
      state.error = null;
      usersAdapter.setAll(state, users);
    },
    [fetchDailySheetsUsers.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchPhxClientUsers.pending](state) {
      state.accountReps.loading = true;
      state.accountReps.error = null;
    },
    [fetchPhxClientUsers.fulfilled](state, { payload: { users, pagination } }) {
      state.accountReps.loading = false;
      state.accountReps.pagination = pagination;
      state.accountReps.error = null;
      usersAdapter.setAll(state.accountReps, users);
    },
    [fetchPhxClientUsers.rejected](state, action) {
      state.accountReps.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.accountReps.error = action.payload.message;
        } else {
          state.accountReps.error = action.error.message;
        }
      }
    },
    [postMemberUser.pending](state) {},
    [postMemberUser.fulfilled](state, { payload: user }) {
      usersAdapter.addOne(state, user);
    },
    [postMemberUser.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [addStateUser.pending](state) {
    //  This is to update the user on the sly; so, don't broadcast the loading
      // state.all.loading = true;
      state.all.error = null;
    },
    [addStateUser.fulfilled](state, { payload: {users, pagination} }) {
      usersAdapter.addOne(state.all, users[0]);
      state.all.error = null;
    },
    [addStateUser.rejected](state, action) {
      if (action.payload) {
        state.all.error = action.payload.message;
      } else {
        state.all.error = action.error.message;
      }
    },
    [updateStateUser.pending](state) {
    //  This is to update the user on the sly; so, don't broadcast the loading
      // state.all.loading = true;
      state.all.error = null;
    },
    [updateStateUser.fulfilled](state, { payload: {users, pagination} }) {
      usersAdapter.updateOne(state.all, {
        id: String(users[0].uid),
        changes: users[0],
      })
      state.all.error = null;
    },
    [updateStateUser.rejected](state, action) {
      if (action.payload) {
        state.all.error = action.payload.message;
      } else {
        state.all.error = action.error.message;
      }
    },
  },
});

export const usersSelectors = usersAdapter.getSelectors((state) => state.users);
export const usersAllSelector = usersAdapter.getSelectors((state) => state.users.all);
export const accountRepsSelectors = usersAdapter.getSelectors(
  (state) => state.users.accountReps
);

// Custom selectors
const selectSelf = (state) => state;
export const getUsersDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.users.data
);

export const getUsersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.users.loading
);

export const getUsersErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.users.error
);

export const getUsersPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.users.pagination
);
export const getUsersAllLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.users.all.loading
);

export const getUsersAllErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.users.all.error
);

export const getUsersAllPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.users.all.pagination
);

export const { setAllUsers, addOneUser, updateOneUser } = usersSlice.actions;

export default usersSlice.reducer;
