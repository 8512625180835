import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { setAllPosts } from "features/Posts/postsSlice";
import groupsAPI from "./groupsAPI";
import { parseHubEnvParams } from "features/Hub/hubSlice.js";

const namespace = "groups";

export const fetchGroups = createAsyncThunk(
  "groups/fetchGroups",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      // const clientNid = getState().app?.selectedClient?.client?.nid;
      const params = {};
      parseHubEnvParams(params, getState);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.getGroups({
        params: params/*{
          client_nid: clientNid,
        }*/,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGroup = createAsyncThunk(
  "groups/fetchGroup",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.getGroup({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGroupPosts = createAsyncThunk(
  "groups/fetchGroupPosts",
  async ({ id, params }, { rejectWithValue, getState, dispatch }) => {
    try {
      // params.client_nid = getState().app?.selectedClient?.client?.nid;
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.getPosts({
        id,
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      dispatch(setAllPosts(response.data));

      return response.data;
    } catch (err) {
      dispatch(setAllPosts({ data: [] }));
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadAttachment = createAsyncThunk(
  "groups/downloadAttachment",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.downloadAttachment({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      dispatch(setAllPosts(response.data));

      return response.data;
    } catch (err) {
      dispatch(setAllPosts({ data: [] }));
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postGroup = createAsyncThunk(
  "groups/postGroup",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.addGroup({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInviteUser = createAsyncThunk(
  "groups/patchInviteUser",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.inviteUser({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveUser = createAsyncThunk(
  "groups/patchRemoveUser",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.removeUser({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddAdmin = createAsyncThunk(
  "groups/patchAddAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.addAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveAdmin = createAsyncThunk(
  "groups/patchRemoveAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.removeAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchGroup = createAsyncThunk(
  "groups/patchGroup",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.updateGroup({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchUploadGroupAttachment = createAsyncThunk(
  "groups/patchUploadGroupAttachment",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.uploadAttachment({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "groups/deleteGroup",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await groupsAPI.deleteGroup({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  "groups/deleteAttachment",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      await groupsAPI.deleteAttachment({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return id;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const groupsAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const groupsSlice = createSlice({
  name: namespace,
  initialState: groupsAdapter.getInitialState({
    loading: false,
    error: null,
    can_create: false,
    group: {
      loading: false,
      error: null,
      data: {},
    },
    attachments: groupsAdapter.getInitialState({}),
  }),
  reducers: {
    resetGroup: (state, action) => {
      state.group.loading = true;
      state.group.error = null;
      state.group.data = {};
    },
    resetGroups: (state, action) => {
      state.loading = true;
      state.error = null;
      groupsAdapter.removeAll(state);
    },
    updateGroup: (state, { payload: group }) => {
      groupsAdapter.updateOne(state, { id: group.id, changes: group });
      if(state.group.data.id === group.id){
        state.group.data = {
          ...state.group.data,
          ...group,
        };
      }
    },
  },
  extraReducers: {
    [fetchGroups.pending](state, action) {
      state.loading = true;
      state.error = null;
      state.can_create = false;
      groupsAdapter.removeAll(state);
    },
    [fetchGroups.fulfilled](state, { payload: groups }) {
      state.loading = false;
      state.error = null;
      state.can_create = groups.can_create;
      groupsAdapter.setAll(state, groups.groups);
    },
    [fetchGroups.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postGroup.fulfilled](state, { payload: group }) {
      groupsAdapter.addOne(state, group);
    },
    [deleteAttachment.fulfilled](state, { payload: id }) {
      groupsAdapter.removeOne(state.attachments, id);
    },
    [fetchGroup.pending](state, action) {
      state.group.loading = true;
      state.group.error = null;
      state.group.data = {};
    },
    [fetchGroup.fulfilled](state, { payload: group }) {
      state.group.loading = false;
      state.group.error = null;
      state.group.data = group;
      groupsAdapter.setAll(state.attachments, group.attachments);
      groupsAdapter.updateOne(state, { id: group.id, changes: group });
    },
    [fetchGroup.rejected](state, action) {
      state.group.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.group.error = action.payload.message;
        } else {
          state.group.error = action.error.message;
        }
      }
    },
    [patchInviteUser.fulfilled](state, { payload: group }) {
      groupsAdapter.updateOne(state, { id: group.id, changes: group });
      state.group.data = group;
      state.group.error = null;
    },
    [patchInviteUser.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.group.error = action.payload.message;
        } else {
          state.group.error = action.error.message;
        }
      }
    },
    [patchRemoveUser.fulfilled](state, { payload: group }) {
      groupsAdapter.updateOne(state, { id: group.id, changes: group });
      state.group.data = group;
      state.group.error = null;
    },
    [patchRemoveUser.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.group.error = action.payload.message;
        } else {
          state.group.error = action.error.message;
        }
      }
    },
    [patchAddAdmin.fulfilled](state, { payload: group }) {
      groupsAdapter.updateOne(state, { id: group.id, changes: group });
      state.group.data = group;
      state.group.error = null;
    },
    [patchAddAdmin.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.group.error = action.payload.message;
        } else {
          state.group.error = action.error.message;
        }
      }
    },
    [patchRemoveAdmin.fulfilled](state, { payload: group }) {
      groupsAdapter.updateOne(state, { id: group.id, changes: group });
      state.group.data = group;
      state.group.error = null;
    },
    [patchRemoveAdmin.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.group.error = action.payload.message;
        } else {
          state.group.error = action.error.message;
        }
      }
    },
    [patchGroup.fulfilled](state, { payload: group }) {
      groupsAdapter.updateOne(state, { id: group.id, changes: group });
      state.group.data = group;
      state.group.error = null;
    },
    [patchGroup.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.group.error = action.payload.message;
        } else {
          state.group.error = action.error.message;
        }
      }
    },
    [patchUploadGroupAttachment.fulfilled](state, { payload: group }) {
      groupsAdapter.setAll(state.attachments, group.attachments);
      state.group.error = null;
    },
    [patchUploadGroupAttachment.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.group.error = action.payload.message;
        } else {
          state.group.error = action.error.message;
        }
      }
    },
    [fetchGroupPosts.fulfilled](state, action) {
      if (!action.meta?.params?.page || action.meta.params.page === 1) {
        state.group.data = {...state.group.data, 'unread_posts_count': 0};
        groupsAdapter.updateOne(state, { id: state.group.data.id, changes: state.group.data });
      }
    },
  },
});

export const groupsSelectors = groupsAdapter.getSelectors(
  (state) => state.groups
);

export const attachmentsSelectors = groupsAdapter.getSelectors(
  (state) => state.groups.attachments
);

export const { resetGroup, resetGroups, updateGroup } = groupsSlice.actions;

export default groupsSlice.reducer;
