import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "config";
import { setAlert } from "features/Alert/alertSlice";
import { formatInvoice } from "features/Invoice/utils";
import { formatInvoiceLineItem } from "./utils";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "invoices";

export const postInvoiceLineItem = createAsyncThunk(
  `${namespace}/postInvoiceLineItem`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/line_items`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_invoice_line_items",
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatInvoiceLineItem(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInvoiceLineItem = createAsyncThunk(
  `${namespace}/patchInvoiceLineItem`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/line_items`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatInvoiceLineItem(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInvoices = createAsyncThunk(
  `${namespace}/fetchInvoices`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/daily_sheets/invoices/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        invoices: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  `${namespace}/deleteInvoice`,
  async ({ id, sheet }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/daily-sheet/archive`,
        {
          daily_sheet_nid: id,
          archive: true,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully removed ${sheet.title}`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing invoice: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchQuickbooksInvoice = createAsyncThunk(
  `${namespace}/patchQuickbooksInvoice`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/quickbooks/invoice`,
        {
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated invoice`,
        })
      );

      return formatInvoice(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating invoice: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInvoice = createAsyncThunk(
  `${namespace}/patchInvoice`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/ds_invoice`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated invoice`,
        })
      );

      return formatInvoice(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating invoice: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postInvoice = createAsyncThunk(
  `${namespace}/postInvoice`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/ds_invoice`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatInvoice(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const invoicesAdapter = createEntityAdapter({
  selectId: (result) => result.invoice_nid,
});

const invoicesSlice = createSlice({
  name: namespace,
  initialState: invoicesAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
  }),
  reducers: {},
  extraReducers: {
    [fetchInvoices.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchInvoices.fulfilled](state, { payload: { invoices, pagination } }) {
      state.loading = false;
      state.error = null;
      state.pagination = pagination;
      invoicesAdapter.setAll(state, invoices);
    },
    [fetchInvoices.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteInvoice.pending](state, action) {
      const { id } = action.meta.arg;
      invoicesAdapter.removeOne(state, id);
    },
    [deleteInvoice.rejected](state, action) {
      const { sheet } = action.meta.arg;
      invoicesAdapter.addOne(state, sheet);
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postInvoice.fulfilled](state, { payload: invoice }) {
      state.error = null;

      const data = {
        title: invoice.title,
        created: moment(invoice.created).format("X"),
        changed: moment(invoice.changed).format("X"),
        invoice_nid: invoice.nid,
        division_nid: invoice.field_invoice_job_division,
        status: invoice.field_invoice_status,
        paid_status: invoice.field_invoice_paid_status,
        total: invoice.field_invoice_total,
        sage_invoice_number: null,
        sage_timestamp: null,
        ds_item_nids: null,
        has_quickbook_invoice: "0",
        date: moment(invoice.field_invoice_date).format("X"),
      };

      invoicesAdapter.addOne(state, data);
    },
    [patchInvoice.fulfilled](state, { payload: invoice }) {
      const data = {
        title: invoice.title,
        created: moment(invoice.created).format("X"),
        changed: moment(invoice.changed).format("X"),
        invoice_nid: invoice.nid,
        division_nid: invoice.field_invoice_job_division,
        status: invoice.field_invoice_status,
        paid_status: invoice.field_invoice_paid_status,
        paid_amount: invoice.field_invoice_paid_amount,
        pl_update_review: invoice.field_pl_update_review,
        total: invoice.field_invoice_total,
        sage_invoice_number: null,
        sage_timestamp: null,
        ds_item_nids: null,
        date: moment(invoice.field_invoice_date).format("X"),
      };

      invoicesAdapter.updateOne(state, {
        id: invoice.nid,
        changes: data,
      });
    },
    [patchInvoice.rejected](state, action) {
      const { row } = action.meta.arg;

      invoicesAdapter.updateOne(state, {
        id: row.daily_sheet_nid,
        changes: row,
      });
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getInvoicesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.invoices.loading
);

export const getInvoicesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.invoices.pagination
);

export const invoicesSelectors = invoicesAdapter.getSelectors(
  (state) => state.invoices
);

export default invoicesSlice.reducer;
