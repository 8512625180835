import React, { useRef, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip, Popover } from "@mui/material";
import { MdArrowDropDown } from "react-icons/md";

const IconButtonDropdown = forwardRef(
  (
    {
      id,
      icon: Icon,
      tooltip,
      children,
      anchorOrigin,
      transformOrigin,
      showArrow,
      trigger,
      disabled,
      ...rest
    },
    ref
  ) => {
    const menuRef = useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (e) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
      e.stopPropagation();
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      close() {
        setAnchorEl(null);
      },
      getOpenState() {
        return Boolean(anchorEl);
      },
      updatePosition() {
        if (menuRef.current) {
          menuRef.current.updatePosition();
        }
      },
    }));

    return (
      <React.Fragment>
        <Tooltip title={tooltip}>
          {trigger ? (
            React.cloneElement(trigger, {
              onClick: (!disabled ? handleClick : () => {}),
            })
          ) : (
            <span>
              <IconButton onClick={handleClick} {...rest} size="large">
                <Icon />
                {showArrow && <MdArrowDropDown />}
              </IconButton>
            </span>
          )}
        </Tooltip>
        <Popover
          action={menuRef}
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {children}
        </Popover>
      </React.Fragment>
    );
  }
);

const { string, element, oneOfType, array } = PropTypes;
IconButtonDropdown.propTypes = {
  children: oneOfType([array, element]),
  tooltip: string,
};

export default IconButtonDropdown;
