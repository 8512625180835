import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import querystring from "querystring";
import directoryAPI from "./directoryAPI";
import { parseHubEnvParams } from "features/Hub/hubSlice.js";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "directory";

export const fetchUsers = createAsyncThunk(
  "directory/fetchUsers",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      // params.client_nid = getState().app?.selectedClient?.client?.nid;
      parseHubEnvParams(params, getState);
      const queryparams = getQueryParams(params);
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await directoryAPI.getUsers({
        queryparams,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const directoryAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const directorySlice = createSlice({
  name: namespace,
  initialState: directoryAdapter.getInitialState({
    loading: true,
    error: null,
    pagination: {
      last_page: 1,
      current_page: 1,
      total: 1,
    },
  }),
  reducers: {
    resetDirectory: (state, action) => {
      state.loading = true;
      state.error = null;
      directoryAdapter.removeAll(state);
      state.pagination = {
        last_page: 1,
        current_page: 1,
        total: 1,
      };
    },
  },
  extraReducers: {
    [fetchUsers.pending](state, action) {
      state.loading = true;
      state.error = null;
      directoryAdapter.removeAll(state);
    },
    [fetchUsers.fulfilled](state, { payload: users }) {
      state.loading = false;
      state.error = null;
      directoryAdapter.setAll(state, users.data);
      state.pagination = {
        last_page: users.last_page,
        current_page: users.current_page,
        total: users.total,
      };
    },
    [fetchUsers.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getDirectoryLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.directory.loading
);

export const getDirectoryPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.directory.pagination
);

export const directorySelectors = directoryAdapter.getSelectors(
  (state) => state.directory
);

export const { resetDirectory } = directorySlice.actions;

export default directorySlice.reducer;
