import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Button, ClickAwayListener } from "@mui/material";
import Popper from "@mui/material/Popper";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import { GoSettings } from "react-icons/go";
import { IoMdArrowDropdown } from "react-icons/io";
import { size } from "lodash";

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
    border: "none",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  borderBottom: `1px solid ${
    theme.palette.mode === "light" ? "#eaecef" : "#30363d"
  }`,
  "& input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${
      theme.palette.mode === "light" ? "#eaecef" : "#30363d"
    }`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === "light"
          ? "rgba(3, 102, 214, 0.3)"
          : "rgb(12, 45, 107)"
      }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  button: {
    background: "var(--color-gray-light)",
    height: 38.81,
  },
  buttonStartIcon: {
    opacity: 0.5,
  },
  header: {
    padding: "8px 10px",
    fontWeight: 600,
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
  badge: {
    background: theme.palette.primary.main,
    borderRadius: "50%",
    color: "white",
    display: "inline-flex",
    minWidth: 16,
    height: 16,
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "center",
  },
}));

const SearchableSelectField = ({
  title,
  options,
  labelId,
  label,
  multiple,
  value,
  onChange,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingValue, setPendingValue] = useState([]);

  const handleClick = (event) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    onChange(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "search-select" : undefined;

  return (
    <>
      <div>
        <Button
          fullWidth
          color="default"
          variant="outlined"
          startIcon={<GoSettings />}
          endIcon={<IoMdArrowDropdown />}
          onClick={handleClick}
          classes={{
            root: classes.button,
            startIcon: classes.buttonStartIcon,
          }}
        >
          {label}
          {size(value) > 0 && (
            <span className={classes.badge}>{size(value)}</span>
          )}
        </Button>
      </div>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <div className={classes.header}>{title}</div>
            <Autocomplete
              open
              multiple={multiple}
              onClose={(event, reason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === "keydown" &&
                  event.key === "Backspace" &&
                  reason === "removeOption"
                ) {
                  return;
                }
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No options"
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <DoneIcon
                    className={classes.iconSelected}
                    style={{ visibility: selected ? "visible" : "hidden" }}
                  />
                  <div className={classes.text}>{option.label}</div>
                  <CloseIcon
                    className={classes.close}
                    style={{ visibility: selected ? "visible" : "hidden" }}
                  />
                </li>
              )}
              options={[...options].sort((a, b) => {
                let ai = value.map((e) => e.value).indexOf(a.value);
                ai =
                  ai === -1
                    ? value.length +
                      options.map((e) => e.value).indexOf(a.value)
                    : ai;
                let bi = value.map((e) => e.value).indexOf(b.value);
                bi =
                  bi === -1
                    ? value.length +
                      options.map((e) => e.value).indexOf(b.value)
                    : bi;
                return ai - bi;
              })}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Filter options"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default SearchableSelectField;
