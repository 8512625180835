import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  formatNumberField,
  getTaxonomyFields,
} from "../../utility";

export const formatWorkAreaFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/damage_area",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_cust_approval_needed: formatField(d, "field_cust_approval_needed"),
    field_da_area_size: formatField(d, "field_da_area_size"),
    field_da_demo: formatField(d, "field_da_demo"),
    field_da_height_water: formatField(d, "field_da_height_water"),
    field_da_is_general: formatField(d, "field_da_is_general"),
    field_da_life_safety: formatField(d, "field_da_life_safety"),
    field_da_linear_affected: formatField(d, "field_da_linear_affected"),
    field_da_location: formatField(d, "field_da_location"),
    field_da_notes: formatField(d, "field_da_notes"),
    field_da_power: formatField(d, "field_da_power"),
    field_da_status: formatField(d, "field_da_status"),
    field_da_type: getTaxonomyFields(d, "field_da_type"),
    field_da_water_present: formatField(d, "field_da_water_present"),
    field_consumables_tax: formatField(d, "field_consumables_tax"),
    field_da_equipment_resources: d?.field_da_equipment_resources
      ? d.field_da_equipment_resources
      : [],
    _processed: d?._processed,
  };
};

export const formatWorkAreaExpense = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    id: formatField(d, "id"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    field_dae_expense_price: formatField(d, "field_dae_expense_price"),
    field_dae_percent_markup: formatField(d, "field_dae_percent_markup"),
    field_dae_expense_type: formatField(d, "field_dae_expense_type"),
    field_dae_expense_notes: formatField(d, "field_dae_expense_notes"),
    _processed: d?._processed,
  };
};

export const formatWorkAreaService = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    id: formatField(d, "id"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    field_das_service_actvty: formatField(d, "field_das_service_actvty"),
    field_das_srvc_lbr_type: formatField(d, "field_das_srvc_lbr_type"),
    field_das_stndrd_hrs: formatNumberField(d, "field_das_stndrd_hrs"),
    field_das_stndrd_rate: formatNumberField(d, "field_das_stndrd_rate"),
    field_das_ah_hrs: formatNumberField(d, "field_das_ah_hrs"),
    field_das_ah_rate: formatNumberField(d, "field_das_ah_rate"),
    field_das_ovrtm_hrs: formatNumberField(d, "field_das_ovrtm_hrs"),
    field_das_ovrtm_rate: formatNumberField(d, "field_das_ovrtm_rate"),
    field_das_trvl_hrs: formatNumberField(d, "field_das_trvl_hrs"),
    field_das_trvl_rate: formatNumberField(d, "field_das_trvl_rate"),
    field_das_per_diem: formatNumberField(d, "field_das_per_diem"),
    field_das_total: formatNumberField(d, "field_das_total"),
    field_das_service_note: formatField(d, "field_das_service_note"),
    field_das_days: formatNumberField(d, "field_das_days"),
    field_das_qty: formatNumberField(d, "field_das_qty"),
    field_das_stc_prct: formatNumberField(d, "field_das_stc_prct"),
    field_das_stc: formatNumberField(d, "field_das_stc"),
    field_das_ppe: formatNumberField(d, "field_das_ppe"),
    _processed: d?._processed,
  };
};

export const getCatTotals = (data) => {
  const breakDowns = data?._processed?.break_downs?.categories;
  if (breakDowns) {
    const totals = breakDowns.map((item) => item.total);
    const labels = breakDowns.map((item) => item.category);
    return { data: totals, labels };
  }

  return { data: [], labels: [] };
};

export const getSubCatTotals = (data) => {
  const breakDowns = data?._processed?.break_downs?.sub_categories;
  if (breakDowns) {
    const totals = breakDowns.map((item) => item.total);
    const labels = breakDowns.map((item) => item.sub_category);
    return { data: totals, labels };
  }

  return { data: [], labels: [] };
};

export const calcTotal = (qty, price, discount) => {
  if (qty && price) {
    return (qty * price) - (discount ? discount : 0);
  } else return 0;
};

export const formatResourcesForSubmit = (data) => {
  const resources = [];
  if (!data) return resources;
  data.forEach((resource) => {
    const total = calcTotal(resource.duration * resource.qty, resource.price);
    if (total > 0) {
      let item = {};
      item[`term_${resource.sub_category}`] = [];
      item[`term_${resource.sub_category}`].push({
        tid: resource.tid,
        qty: resource.qty,
        price: resource.price,
        increment: resource.increment,
        duration: resource.duration,
        discount: resource?.discount,
        discount_details: (resource?.discount_details ? JSON.stringify(resource.discount_details) : null),
        total,
        notes: resource.notes,
      });
      resources.push(item);
    }
  });

  return resources;
};

export const getIncrementMachineNameByLabel = (label) => {
  const array = Object.entries(increments);
  let data = label;
  array.forEach((increment) => {
    const [key, value] = increment;
    if (value === label) {
      data = key;
    }
  });

  return data;
};

export const getIncrementLabel = (increment) => {
  return increments[increment] ? increments[increment] : increment;
};

export const increments = {
  h: "Hour(s)",
  d: "Day(s)",
  e: "Each",
  df: "Day(s) + Fuel",
  mi: "MI(s)",
  p: "Pair(s)",
  b: "Box(es)",
  c: "Can(s)",
  g: "Gallon(s)",
  r: "Roll(s)",
  cs: "CS",
  s: "Set(s)",
  pk: "Pack(s)",
  bg: "Bag(s)",
  pc: "Per Cubic Ft.",
  dz: "Dozen",
  pd: "Pad(s)",
  sf: "Sqft.",
  pnd: "Pound(s)",
  cas: "Case(s)",
  lf: "Linear Ft.",
  wk: "Week(s)",
  mnth: "Month(s)",
  ft: "Per ft Per Day",
  mrkt: "Market Price",
};
