import React from "react";
import {
  Typography,
  MenuItem,
  ListItemIcon,
  CircularProgress,
  ListItemText,
} from "@mui/material";

const MenuItemLoader = ({ onClick, loading, text, children, large }) => {
  return (
    <MenuItem onClick={onClick} disabled={loading}>
      <ListItemIcon>
        {loading ? (
          <CircularProgress size={14} style={{ marginLeft: "3px" }} />
        ) : (
          children
        )}
      </ListItemIcon>
      {large ? (
        <ListItemText primary={text} />
      ) : (
        <Typography variant="inherit">{text}</Typography>
      )}
    </MenuItem>
  );
};

MenuItemLoader.propTypes = {};

export default MenuItemLoader;
