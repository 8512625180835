import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import {
  Typography,
  CircularProgress,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import Information from "./Information";
import Contacts from "./Contacts";
import Location from "./Location";
import Alert from "features/Alert";
import Dialog from "common/Dialog";
import { addLocationFormSchema } from "schemas/forms/location";

import { postLocation } from "../locationSlice";
import { fetchGeoAddressCoords } from "features/Geo/geoSlice";
import Button from "common/Button";
import { Add } from "@mui/icons-material";

const StyledStepper = withStyles({
  root: {
    background: "transparent",
  },
})(Stepper);

function getSteps() {
  return ["Information", "Contact", "Providers"];
}

const STEP_FIELDS = [
  ["title", "field_note", "field_cl_status", "field_street_address"],
  [],
  ["field_street_address", "field_preferred_member"],
];

const hasValidationErrors = (fields, errors, value, index) => {
  if (value > index) {
    for (let i = 0; i < fields.length; i += 1) {
      if (errors[fields[i]]) {
        return true;
      }
    }
  }

  return false;
};

const isComplete = (fields, errors, value, index) => {
  if (value > index) {
    for (let i = 0; i < fields.length; i += 1) {
      if (errors[fields[i]]) {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

const QuickAdd = ({ id, setFieldValue, clientId}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [progress, setProgress] = useState({ show: false, message: "" });

  const handleNext = (validateForm) => () => {
    validateForm();
    setValue(value + 1);
  };

  const handlePrev = (validateForm) => () => {
    validateForm();
    setValue(value - 1);
  };

  const handleVerifyAddress = async (data) => {
    try {
      setProgress({ show: true, message: "Verifying Address" });
      const resultAction = await dispatch(
        fetchGeoAddressCoords({
          address: `${data.field_street_address.address_line1}, ${data.field_street_address.locality}, ${data.field_street_address.administrative_area} ${data.field_street_address.postal_code}`,
        })
      );

      unwrapResult(resultAction);
      return resultAction.payload;
    } catch (err) {
      setProgress({ show: false, message: "" });
      return false;
    }
  };

  const handleSubmit = async (data) => {
    const geo = await handleVerifyAddress(data);
    if (!geo) return;

    try {
      setProgress({ show: true, message: "Creating Location" });
      const params = {
        title: [{ value: data.title }],
        field_cl_customer: [{ target_id: data.field_cl_customer }],
        field_county: [{ target_id: geo.county_tid }],
        field_cl_status: [{ value: data.field_cl_status }],
        field_note: [{ value: data.field_note }],
        field_phone: [{ value: data.field_phone }],
        field_phone_after_hours: [{ value: data.field_phone_after_hours }],
        field_primary_poc: data.field_primary_poc
          ? [{ target_id: data.field_primary_poc.uid }]
          : [],
        field_secondary_pocs: data.field_secondary_pocs.map((u) => ({
          target_id: u.uid,
        })),
        field_after_hours_pocs: data.field_after_hours_pocs.map((u) => ({
          target_id: u.uid,
        })),
        field_street_address: [data.field_street_address],
      };

      if (data.field_phx_client_rep) {
        params.field_phx_client_rep = [
          { target_id: data.field_phx_client_rep },
        ];
      }
      if (data.field_preferred_member) {
        params.field_preferred_member = [
          { target_id: data.field_preferred_member.nid },
        ];
      }

      if (data.field_preferred_member_2) {
        params.field_preferred_member_2 = [
          { target_id: data.field_preferred_member_2.nid },
        ];
      }

      if (data.field_preferred_member_3) {
        params.field_preferred_member_3 = [
          { target_id: data.field_preferred_member_3.nid },
        ];
      }

      if (data.field_preferred_member_4) {
        params.field_preferred_member_4 = [
          { target_id: data.field_preferred_member_4.nid },
        ];
      }

      const resultAction = await dispatch(postLocation({ id, params }));

      unwrapResult(resultAction);
      setProgress({ show: false, message: "" });
      setFieldValue("field_customer_location", {
        nid: resultAction.payload.nid,
        name: resultAction.payload.title,
      });
      if (data.field_preferred_member) {
        setFieldValue("field_assigned_member", data.field_preferred_member);
      }
      onClose();
    } catch (err) {
      setProgress({ show: false, message: "" });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const steps = getSteps();

  return (
    <>
      <Button
        color="tertiary"
        variant="contained"
        size="small"
        disableElevation
        onClick={handleOpen}
        style={{ marginBottom: "2rem" }}
        disabled={!id}
        startIcon={<Add />}
      >
        Add Location
      </Button>
      <Dialog open={open} onClose={onClose} maxWidth="xs" title="Add Location">
        <Formik
          initialValues={{
            title: "",
            field_cl_customer: id,
            field_square_feet: "",
            field_cl_status: "active",
            field_note: "",
            field_phone: "",
            field_phone_after_hours: "",
            field_primary_poc: null,
            field_secondary_pocs: [],
            field_after_hours_pocs: [],
            field_street_address: {
              address_line1: "",
              address_line2: "",
              locality: "",
              administrative_area: "",
              postal_code: "",
              country_code: "US",
            },
            field_preferred_member: null,
            field_preferred_member_2: null,
            field_preferred_member_3: null,
            field_preferred_member_4: null,
          }}
          validationSchema={addLocationFormSchema}
          onSubmit={async (data, { setSubmitting, setFieldValue }) => {
            setSubmitting(true);
            await handleSubmit(data);
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue, validateForm }) => (
            <Form>
              <div
                style={{
                  padding: "1.25rem",
                  maxWidth: 800,
                  position: "relative",
                  margin: "0 auto",
                }}
              >
                {progress.show && (
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      background: "rgba(245, 242, 247, 0.5)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                    }}
                  >
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: "2rem" }}>
                      {progress.message}
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    padding: "2rem 0",
                  }}
                >
                  <StyledStepper nonLinear activeStep={value}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const buttonProps = {};
                      const hasErrors = hasValidationErrors(
                        STEP_FIELDS[index],
                        errors,
                        value,
                        index
                      );
                      const stepComplete = isComplete(
                        STEP_FIELDS[index],
                        errors,
                        value,
                        index
                      );

                      if (hasErrors) {
                        buttonProps.error = true;
                      }
                      if (stepComplete) {
                        stepProps.completed = true;
                      }

                      return (
                        <Step key={label} {...stepProps}>
                          <StepButton
                            onClick={() => {
                              validateForm();
                              setValue(index);
                            }}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      );
                    })}
                  </StyledStepper>
                  <Alert margin="none" disableElevation disableRoundedCorners />
                </div>
                <Information
                  value={value}
                  handleNext={handleNext(validateForm)}
                  isSubmitting={isSubmitting}
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <Contacts
                  value={value}
                  handleNext={handleNext(validateForm)}
                  handlePrev={handlePrev(validateForm)}
                  isSubmitting={isSubmitting}
                  values={values}
                />
                <Location
                  cid={id}
                  value={value}
                  handlePrev={handlePrev(validateForm)}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  values={values}
                  setFieldValue={setFieldValue}
                  clientId={clientId}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

QuickAdd.propTypes = {};

export default QuickAdd;
