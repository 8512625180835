import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const notificationAPI = {
  getNotifications: async ({ config }) => {
    return instance.get("/notifications", config);
  },
  getUnreadNotifications: async ({ config }) => {
    return instance.get("/notifications/unread", config);
  },
  setRead: async ({ params, config }) => {
    return instance.patch(`/notifications/read`, params, config);
  },
  deleteNotification: async ({ uuid, config }) => {
    return instance.delete(`/notifications/${uuid}`, config);
  },
  deleteNotifications: async ({ parent_id, parent_type, config }) => {
    return instance.delete(`/notifications/${parent_id}/${parent_type}`, config);
  },
};

export default notificationAPI;
