import React from "react";
import ContentLoader from "react-content-loader";
import constants from "../constants";

const MemberSkeleton = props => {
  return (
    <ContentLoader
      speed={2}
      style={{ width: "100%" }}
      height={500}
      foregroundColor={constants.colorGray}
      backgroundColor={constants.colorGrayLight}
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="500" />
    </ContentLoader>
  );
};

export default MemberSkeleton;
