import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  margin-bottom: ${({ gutterBottom }) => (gutterBottom ? "2rem" : "0.5rem")};
`;

const Icon = styled.div`
  padding-right: 1rem;
  color: ${({ color }) =>
    color === "secondary"
      ? "var(--color-secondary)"
      : color === "primary"
      ? "var(--color-primary)"
      : "var(--color-gray-dark)"};
  font-size: ${({ size }) => (size === "large" ? "1.75rem" : "1rem")};
`;

const Content = styled.div`
  flex: 1;
`;

const IconTextItem = ({
  icon,
  children,
  size,
  gutterBottom,
  color,
  ...props
}) => {
  return (
    <Container gutterBottom={gutterBottom} {...props}>
      <Icon color={color} size={size}>
        {icon}
      </Icon>
      <Content>{children}</Content>
    </Container>
  );
};

const { oneOf } = PropTypes;
IconTextItem.propTypes = {
  size: oneOf(["small", "default", "large"]),
  color: oneOf(["gray", "secondary", "primary"]),
};

IconTextItem.defaultProps = {
  size: "default",
  color: "gray",
};

export default IconTextItem;
