import { combineReducers } from "redux";
import reducer from "./reducer";
import messageReducer from "./messageReducer";
import fileReducer from "./fileReducer";
import batchReducer from "./batchReducer";
import snackbarReducer from "./snackbarReducer";
import clientSelectorReducer from "./clientSelectorReducer";

export default combineReducers({
  message: messageReducer,
  fileUpload: fileReducer,
  batchExport: batchReducer,
  snackbar: snackbarReducer,
  selectedClient: clientSelectorReducer,
  user: reducer("user"),
  addressGeo: reducer("addressGeo"),
  report: reducer("report"),
  batch: reducer("batch"),
  paragraph: reducer("paragraph"),
  subParagraph: reducer("subParagraph"),
  team: reducer("team"),
  teamFiles: reducer("teamFiles"),
  teams: reducer("teams"),
  teamComments: reducer("teamComments"),
  unread: reducer("unread"),
  file: reducer("file"),
  news: reducer("news"),
  pinnedNews: reducer("pinnedNews"),
  newsPostComments: reducer("newsPostComments"),
  events: reducer("events"),
  eventsCalendar: reducer("eventsCalendar"),
  event: reducer("event"),
  node: reducer("node"),
  intranetFileFolders: reducer("intranetFileFolders"),
  intranetResources: reducer("intranetResources"),
  announcements: reducer("announcements"),
  teamSummary: reducer("teamSummary"),
  directMessageSummary: reducer("directMessageSummary"),
  intranetExternalLinks: reducer("intranetExternalLinks"),
  intranetSponsorships: reducer("intranetSponsorships"),
  employees: reducer("employees"),
  memberGenFileFolders: reducer("memberGenFileFolders"),
  memberGeneralDocuments: reducer("memberGeneralDocuments"),
  userJobs: reducer("userJobs"),
  phxUser: reducer("phxUser"),
  jobs: reducer("jobs"),
  jobAccountings: reducer("jobAccountings"),
  members: reducer("members"),
  memberFiles: reducer("memberFiles"),
  childMembers: reducer("childMembers"),
  memberNotes: reducer("memberNotes"),
  memberCompliance: reducer("memberCompliance"),
  memberCapabilities: reducer("memberCapabilities"),
  memberTerminations: reducer("memberTerminations"),
  memberEmployees: reducer("memberEmployees"),
  memberEducation: reducer("memberEducation"),
  memberEntityDetails: reducer("memberEntityDetails"),
  memberFinancials: reducer("memberFinancials"),
  memberTerminationDocs: reducer("memberTerminationDocs"),
  memberRenewals: reducer("memberRenewals"),
  crmCalls: reducer("crmCalls"),
  memberAgreementDocs: reducer("memberAgreementDocs"),
  memberTerritories: reducer("memberTerritories"),
  crmEmails: reducer("crmEmails"),
  customers: reducer("customers"),
  availableUsers: reducer("availableUsers"),
  tasksDrawer: reducer("tasksDrawer"),
  complianceActivities: reducer("complianceActivities"),
  mapJobs: reducer("mapJobs"),
  mapMembers: reducer("mapMembers"),
  mapCustomers: reducer("mapCustomers"),
  mapCustomerLocations: reducer("mapCustomerLocations"),
  zones: reducer("zones"),
  mapGroups: reducer("mapGroups"),
  mapLocations: reducer("mapLocations"),
  users: reducer("users"),
  jobDivisionTypes: reducer("jobDivisionTypes"),
  memberNoteStatus: reducer("memberNoteStatus"),
  memberNoteSubjects: reducer("memberNoteSubjects"),
  memberStatus: reducer("memberStatus"),
  affiliations: reducer("affiliations"),
  complianceChangeReasons: reducer("complianceChangeReasons"),
  regions: reducer("regions"),
  states: reducer("states"),
  phxClientReps: reducer("phxClientReps"),
  liftMethods: reducer("liftMethods"),
  memberFileCategories: reducer("memberFileCategories"),
  aimeCarriers: reducer("aimeCarriers"),
  division: reducer("division"),
  job: reducer("job"),
  jobAccounting: reducer("jobAccounting"),
  jobAccountingInvoice: reducer("jobAccountingInvoice"),
  jobAccountingInvoiceForm: reducer("jobAccountingInvoiceForm"),
  member: reducer("member"),
  customer: reducer("customer"),
  task: reducer("task"),
  complianceActivity: reducer("complianceActivity"),
});
