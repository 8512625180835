import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  margin: ${(props) => props.theme.margin};
  background: ${(props) => props.theme.color?.bkg};
  border-radius: ${(props) =>
    props.theme.disableRoundedCorners ? "0" : "2px"};
  box-shadow: ${(props) =>
    props.theme.disableElevation ? "none" : "0 0 6px rgba(0, 0, 0, 0.1)"};
  display: flex;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.color?.font};
`;

export const Icon = styled.div`
  font-size: 24px;
  height: 24px;
  margin-right: 1rem;
  color: ${(props) => props.theme.color?.icon};
`;

export const Message = styled.div`
  flex: 1;
  line-height: 1.5;
`;
