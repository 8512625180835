import React, { useEffect } from "react";
import { ChatAlt2Icon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationsSelectors,
  deleteConversation,
} from "features/Conversations/conversationsSlice";
import ListItem from "features/Conversations/components/ListItem";
import { Skeleton } from "@mui/material";
import Card from "common/Card";
import { Link } from "react-router-dom";
import { ReactComponent as IconChatAddLine } from "assets/images/icons/icon-chat-add-line.svg";
import { fetchConversations } from "features/Conversations/conversationsSlice";
import { HubIconButton } from "features/Hub/Styled";

const ConversationsWidget = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.conversations.loading);
  const conversations = useSelector(conversationsSelectors.selectIds);
  // const selectedClient = useSelector((state) => state.app.selectedClient.client);
  const currentEnvironment = useSelector((state) => state.hub.currentEnvironment);

  useEffect(() => {
    if(currentEnvironment?.nid /*selectedClient?.nid*/) {
      const promise = dispatch(fetchConversations());

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, currentEnvironment/*selectedClient*/]);

  const removeConversation = (id) => {
    dispatch(deleteConversation(id));
  };

  return (
    <Card className="p-4 my-2 grid-lg:my-4">
      <header className="flex items-center">
        <div className="rounded-full h-6 w-6 mr-2">
          <ChatAlt2Icon className="h-6 w-6 text-gray-dark" />
        </div>
        <h6 className="text-lg font-semibold text-gray-darkest">Conversations</h6>
        <div className="flex-1 text-right" >
          <HubIconButton>
            <Link
              to={`/hub/conversations/create`}
              title={`Start a Conversation`}
            >
              <IconChatAddLine />
            </Link>
          </HubIconButton>
        </div>
      </header>
      <main>
        {loading ? (
          <div className="mb-2 flex items-center">
            <div className="flex-1 mr-2">
              <Skeleton height={24} width={100} variant="text" />
              <Skeleton height={18} width={100} variant="text" />
              <Skeleton height={14} width={75} variant="text" />
            </div>
            <Skeleton
              height={70}
              width={70}
              variant="rectangle"
              className="rounded-lg"
            />
          </div>
        ) : (
          <ul>
            {conversations.slice(0, 3).map((id) => (
              <ListItem key={id} onRemove={removeConversation} id={id} />
            ))}
            {conversations.length === 0 && (
              <li className="mt-3 px-2">
                No conversations yet
                {/*, <Link
                  to={`/hub/conversations/create`}
                  className="italic font-bold text-blue-dark"
                >
                  find some folks to chat with.
                </Link> */}
              </li>
            )}
            {conversations.length > 3 && (
              <li className="mt-3 px-2 text-right">
                <Link
                  to={`/hub/conversations`}
                  className="italic text-blue-dark"
                >
                  More Conversations...
                </Link>
              </li>
            )}
          </ul>
        )}
      </main>
    </Card>
  );
};

ConversationsWidget.propTypes = {};

export default ConversationsWidget;
