import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "logs";

export const fetchJobLogs = createAsyncThunk(
  `${namespace}/fetchJobLogs`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/log/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { logs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberLogs = createAsyncThunk(
  `${namespace}/fetchMemberLogs`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/log/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { logs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerLogs = createAsyncThunk(
  `${namespace}/fetchCustomerLogs`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/log/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { logs: response.data.data, pagination: response.data.pagination };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const logsAdapter = createEntityAdapter({
  selectId: (log) => log.pid,
});

const logsSlice = createSlice({
  name: namespace,
  initialState: logsAdapter.getInitialState({
    loading: false,
    error: null,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
  }),
  reducers: {},
  extraReducers: {
    [fetchJobLogs.pending](state) {
      state.loading = true;
      state.error = null;
      logsAdapter.setAll(state, []);
    },
    [fetchJobLogs.fulfilled](state, { payload: { logs, pagination } }) {
      state.loading = false;
      state.error = null;
      state.pagination = pagination;
      logsAdapter.setAll(state, logs);
    },
    [fetchJobLogs.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMemberLogs.pending](state) {
      state.loading = true;
      logsAdapter.setAll(state, []);
      state.error = null;
    },
    [fetchMemberLogs.fulfilled](state, { payload: { logs, pagination } }) {
      state.loading = false;
      state.error = null;
      state.pagination = pagination;
      logsAdapter.setAll(state, logs);
    },
    [fetchMemberLogs.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerLogs.pending](state) {
      state.loading = true;
      logsAdapter.setAll(state, []);
      state.error = null;
    },
    [fetchCustomerLogs.fulfilled](state, { payload: { logs, pagination } }) {
      state.loading = false;
      state.error = null;
      state.pagination = pagination;
      logsAdapter.setAll(state, logs);
    },
    [fetchCustomerLogs.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getLogsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.logs.loading
);

export const getLogsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.logs.pagination
);

export const getLogsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.logs.error
);

export const logsSelectors = logsAdapter.getSelectors((state) => state.logs);

export default logsSlice.reducer;
