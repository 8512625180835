import { formatField, getUserFields } from "utility";
import { formatJobDivisionFile } from "features/Files/utils";

export const formatAccounting = (d) => {
  const jobAccounting =
    d.field_job_accounting && d.field_job_accounting[0]
      ? d.field_job_accounting[0]
      : null;

  return {
    title: formatField(d, "title"),
    progress: d.field_jda_progress,
    status: d.field_jda_status,
    field_payment_terms: formatField(d, "field_payment_terms"),
    field_payment_terms_other: formatField(d, "field_payment_terms_other"),
    field_payment_due_date: formatField(d, "field_payment_due_date"),
    files: (d?.field_general_files ? d?.field_general_files.map((f) => {
      const file = formatJobDivisionFile(f);

      return {
        id: file.nid,
        fid: file.fid,
        name: file.filename,
        url: file.url,
        type: file.filemime,
        file_dl_token: file.downloadToken,
      };
    }) : []),
    preTaxJobCost: jobAccounting ? jobAccounting.pre_tax_job_cost : null,
    tax: jobAccounting ? jobAccounting.tax : null,
    travel: jobAccounting ? jobAccounting.travel : null,
    mobilization: jobAccounting ? jobAccounting.mobilization : null,
    markupFee: jobAccounting ? jobAccounting.markup_fee : null,
    markupFeePercent: jobAccounting ? jobAccounting.markup_fee_percent : null,
    providerFeeOverride: jobAccounting
      ? jobAccounting.provider_fee_override
      : null,
    techFeeOverride: jobAccounting ? jobAccounting.tech_fee_override : null,
    deposit: jobAccounting ? jobAccounting.deposit : null,
    fileFeeOverride: jobAccounting ? jobAccounting.file_fee_override : null,
    subcontractor: jobAccounting ? jobAccounting.subcontractor : null,
    subcontractorMarkup: jobAccounting
      ? jobAccounting.subcontractor_markup
      : null,
    subcontractorMarkupFee: jobAccounting
      ? jobAccounting.subcontractor_markup_fee
      : null,
    estGrossProfit: jobAccounting ? jobAccounting.est_gross_profit : null,
    actGrossProfit: jobAccounting ? jobAccounting.act_gross_profit : null,
    totalCollected: jobAccounting ? jobAccounting.total_collected : null,
    estGrossProfitPercent: jobAccounting
      ? jobAccounting.est_gross_profit_percent
      : null,
    lienRights: jobAccounting ? jobAccounting.lien_rights : null,
    totalInvoiced: jobAccounting ? jobAccounting.total_invoiced : null,
    burdenTotal: jobAccounting ? jobAccounting.burden_total : null,
    deductible: jobAccounting ? jobAccounting.deductible : null,
    balanceRemaining: jobAccounting ? jobAccounting.balance_remaingin : null,
    totalJobCost: jobAccounting ? jobAccounting.total_job_cost : null,
    discount: jobAccounting ? jobAccounting.discount : null,
    invoiceTax: jobAccounting ? jobAccounting.invoice_tax : null,
    actCost: jobAccounting ? jobAccounting.act_cost : null,
    adminFee: jobAccounting ? jobAccounting.admin_fee : null,
    workOrderBudget: jobAccounting ? jobAccounting.work_order_budget : null,
    invoiceSubtotal: jobAccounting ? jobAccounting.invoice_subtotal : null,
    addlReference: d.field_addl_ref
      ? d.field_addl_ref.map((ar) => ({
          nid: formatField(ar, "nid"),
          title: formatField(ar, "title"),
        }))
      : [],
    backCharge: d.field_member_back_charges
      ? d.field_member_back_charges.map((tp) => ({
          pid: formatField(tp, "id"),
          desc: formatField(tp, "field_li_desc"),
          total: formatField(tp, "field_li_total"),
          revision_id: formatField(tp, "revision_id"),
          files: (tp?.field_general_files ? tp?.field_general_files.map((f) => {
            const file = formatJobDivisionFile(f);

            return {
              id: file.nid,
              fid: file.fid,
              name: file.filename,
              url: file.url,
              type: file.filemime,
              file_dl_token: file.downloadToken,
            };
          }) : []),
        }))
      : [],
    thirdParty: d.field_third_party_expenses
      ? d.field_third_party_expenses.map((tp) => ({
          pid: formatField(tp, "id"),
          vendor: formatField(tp, "field_vendor"),
          amount: formatField(tp, "field_amount"),
          markup: formatField(tp, "field_markup"),
          markup_percent: formatField(tp, "field_markup_percent"),
          invoice_received: formatField(tp, "field_invoice_received"),
          payment_due: formatField(tp, "field_payment_due"),
          paid: formatField(tp, "field_paid"),
          revision_id: formatField(tp, "revision_id"),
          files: (tp?.field_general_files ? tp?.field_general_files.map((f) => {
            const file = formatJobDivisionFile(f);

            return {
              id: file.nid,
              fid: file.fid,
              name: file.filename,
              url: file.url,
              type: file.filemime,
              file_dl_token: file.downloadToken,
            };
          }) : []),
        }))
      : [],
    revenues: d.field_revenues
      ? d.field_revenues.map((revenue) => ({
          title: revenue.title,
          amount: revenue.amount,
          account: revenue.account,
          type: revenue.type,
          markup: revenue.markup,
          markup_percent: revenue.markup_percent,
        }))
      : [],
    voidReason: formatField(d, "field_void_reason"),
    voidTimestamp: formatField(d, "field_void_timestamp"),
    voidUser: getUserFields(d, "field_void_user"),
    sageBillableTotal: formatField(d, "field_sage_billable_total"),
    sagePreTaxJobCost: formatField(d, "field_sage_pre_tax_job_cost"),
    sageProviderFee: formatField(d, "field_sage_provider_fee"),
    sageProviderFeePercent: formatField(d, "field_sage_provider_fee_percent"),
    sageTechFee: formatField(d, "field_sage_tech_fee"),
    tfInvoiceNum: formatField(d, "field_tf_invoice_num"),
    pfCompleteTimestamp: formatField(d, "field_pf_complete_timestamp"),
    pfInvoiceNum: formatField(d, "field_pf_invoice_num"),
    pfUser: getUserFields(d, "field_pf_user"),
    note: formatField(d, "field_note"),
    payment: d.field_payment,
    accountingInvoices:
      d._processed && d._processed.job_division_accounting_invoices
        ? d._processed.job_division_accounting_invoices
        : [],
    _processed: d?._processed,
  };
};

export const getAccountingItemTitle = (item, divisionName) => {
  let name = '';
  if(item){
    name = item.date;
    if(item.title !== `Accounting for ${divisionName}` ){
      name = item.title;
    }
  }
  return name;
}
