import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { DialogForm } from "common/Dialog";
import Button from "common/Button";
import { removeAvailableMember } from "features/Job/jobSlice";
import { TextFormField } from "common/Fields";

const MemberDecline = ({ member, onDecline }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    onDecline((d) => [
      ...d,
      { member_nid: member.member_nid, reason: data.reason },
    ]);
    dispatch(removeAvailableMember(member.member_nid));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button size="small" disableElevation onClick={handleOpen}>
        Decline
      </Button>
      <DialogForm
        open={open}
        onClose={handleClose}
        title="Member Declined"
        maxWidth="xs"
        initialValues={{
          reason: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <>
            <TextFormField
              fullWidth
              name="reason"
              label="Reason"
              margin="normal"
              required
              multiline
              disabled={isSubmitting}
              variant="filled"
              size="small"
            />
          </>
        )}
      </DialogForm>
    </>
  );
};

MemberDecline.propTypes = {};

export default MemberDecline;
