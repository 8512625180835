import action from "../action";
import config from "../../config";

export const getUsers = (params) =>
  action("users", config.api_url, "rest/users", params);

export const getUser = (name) =>
  action("phxUser", config.api_url, `users/${name}`);

export const postTempUser = (params) =>
  action("tempUser", config.api_url, "node", params, "post");
