import action from "../action";
import config from "../../config";

export const getJobAccountingInvoiceForm = (nid, params) =>
  action(
    "jobAccountingInvoiceForm",
    config.api_url,
    `rest/job-accounting-invoice/template/${nid}`,
    params
  );

export const getJobAccountingInvoice = (nid, params) =>
  action("jobAccountingInvoice", config.api_url, `node/${nid}`, params);

export const postJobAccountingInvoice = (params) =>
  action("jobAccountingInvoice", config.api_url, `node`, params, "post");

export const patchJobAccountingInvoice = (nid, params) =>
  action(
    "jobAccountingInvoice",
    config.api_url,
    `node/${nid}`,
    params,
    "patch"
  );
