import styled from "styled-components";
import { Form as FormikForm } from "formik";

export const FormContainer = styled(FormikForm)`
  padding: ${({ theme }) => theme.padding};
`;

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  cursor: pointer;
`;
