import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Typography, Drawer as MuiDrawer } from "@mui/material";

import { Container, Header, Icon, Main } from "./Styled";

const Drawer = forwardRef(
  ({ action, icon, primary, secondary, header, anchor, className, children, remoteOpen, onClose, mainProps }, ref) => {
    const [open, setOpen] = useState(remoteOpen ? remoteOpen : false);
    const [checkOpen, setCheckOpen] = useState(remoteOpen ? remoteOpen : false);

    useImperativeHandle(ref, () => ({
      close() {
        setOpen(false);
      },
      open() {
        setOpen(true);
      },
    }));

    useEffect(() => {
      setCheckOpen(open);
    }, [open, setCheckOpen]);

    useEffect(() => {
      setOpen(remoteOpen);
    }, [remoteOpen, setCheckOpen]);

    return (
      <>
        {React.cloneElement(action, {
          onClick: (e) => {
            e.stopPropagation();
            setOpen(true);
          },
        })}
        <MuiDrawer
          className={className}
          anchor={anchor}
          open={checkOpen}
          onClose={(e) => {
            e.stopPropagation();
            setOpen(false);
            if(onClose){
              onClose();
            }
          }}
        >
          <Container>
            {header ? (
              header
            ) : (
              <Header>
                <Icon>{icon}</Icon>
                <div>
                  <Typography variant="h6" style={{ lineHeight: 1 }}>
                    {primary}
                  </Typography>
                  <Typography variant="body1">{secondary}</Typography>
                </div>
              </Header>
            )}
            <Main {...mainProps}>{children}</Main>
          </Container>
        </MuiDrawer>
      </>
    );
  }
);

Drawer.propTypes = {};

Drawer.defaultProps = {
  anchor: "right",
};

export default Drawer;
