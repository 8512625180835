import axios from "axios";
import conf from "config";
import getQueryParams from "utility/getQueryParams";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  addEvent: async ({ params, config }) => {
    return instance.post(`/events`, params, config);
  },
  getEvents: async ({ params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/events${queryParams}`, config);
  },
  getPosts: async ({ id, params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/events/${id}/posts${queryParams}`, config);
  },
  getUserEvents: async ({ id, params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/users/${id}/events${queryParams}`, config);
  },
  getUpcoming: async ({ params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/events/upcoming${queryParams}`, config);
  },
  getEvent: async ({ id, config }) => {
    return instance.get(`/events/${id}`, config);
  },
  addReaction: async ({ id, params, config }) => {
    return instance.patch(`/events/${id}/add-reaction`, params, config);
  },
  removeReaction: async ({ id, config }) => {
    return instance.patch(`/events/${id}/remove-reaction`, {}, config);
  },
  inviteAttendee: async ({ id, params, config }) => {
    return instance.patch(`/events/${id}/invite-attendee`, params, config);
  },
  addAdmin: async ({ id, params, config }) => {
    return instance.patch(`/events/${id}/add-admin`, params, config);
  },
  removeAdmin: async ({ id, params, config }) => {
    return instance.patch(`/events/${id}/remove-admin`, params, config);
  },
  addAttendeeConfirmation: async ({ id, params, config }) => {
    return instance.patch(`/events/${id}/add-attendee-confirmation`, params, config);
  },
  removeAttendeeConfirmation: async ({ id, params, config }) => {
    return instance.patch(`/events/${id}/remove-attendee-confirmation`, params, config);
  },
  removeAttendee: async ({ id, config }) => {
    return instance.patch(`/events/${id}/remove-attendee`, {}, config);
  },
  adminRemoveAttendee: async ({ id, attendeeID, config }) => {
    return instance.patch(`/events/${id}/admin-remove-attendee/${attendeeID}`, {}, config);
  },
  addInterest: async ({ id, config }) => {
    return instance.patch(`/events/${id}/add-attendee-interest`, {}, config);
  },
  removeInterest: async ({ id, config }) => {
    return instance.patch(`/events/${id}/remove-attendee-interest`, {}, config);
  },
  updateEvent: async ({ id, params, config }) => {
    return instance.patch(`/events/${id}`, params, config);
  },
  uploadAttachment: async ({ id, params, config }) => {
    return instance.patch(`/events/${id}/upload-attachment`, params, config);
  },
  downloadAttachment: async ({ id, config }) => {
    return instance.get(`/attachments/${id}`, config);
  },
  deleteAttachment: async ({ id, config }) => {
    return instance.delete(`/attachments/${id}`, config);
  },
  deleteEvent: async ({ id, config }) => {
    return instance.delete(`/events/${id}`, config);
  },
};

export default API;
