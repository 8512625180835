import React from "react";
import { CircularProgress } from "@mui/material";

const DefaultLoader = ({ style, ...rest }) => {
  return (
    <div
      style={{
        height: "200px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        ...style,
      }}
    >
      <CircularProgress color="secondary" {...rest} />
    </div>
  );
};

DefaultLoader.propTypes = {};

export default DefaultLoader;
