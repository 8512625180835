const SET = "clientSelector/SET";
const CLEAR = "clientSelector/CLEAR";

export const clientSelectorActions = {
  SET,
  CLEAR,
};

export const clientSelectorSet = (payload) => ({
  type: SET,
  payload,
});

export const clientSelectorClear = () => ({
  type: CLEAR,
});
