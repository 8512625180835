import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  unwrapResult,
} from "@reduxjs/toolkit";
import axios from "axios";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatFormData } from "./utils";
import { formatUser, getRoleType } from "../../utility";
import { setAllClients, setJobCreateClients } from "../Clients/clientsSlice";
import { size, isNull } from "lodash";
import { patchUser } from "features/User/userSlice";
import { logoutPusherBeams } from "components/BrowserNotifications/BrowserNotifications";

const namespace = "auth";

const instance = axios.create();

export const postHelixApiToken = createAsyncThunk(
  `${namespace}/postHelixApiToken`,
  async (_, { getState, signal, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/phx-offsite-api/tokens`,
        { action: "register" },
        { ...tokenConfig(getState), skipAuthRefresh: true }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCurrentUser = createAsyncThunk(
  `${namespace}/fetchCurrentUser`,
  async (_, { getState, signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/rest/user/current`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      const user = formatUser(response.data);
      user.roleType = getRoleType(user._processed.roles);
      const clientIds = user.field_clients.map((client) => client.nid);
      user.clientIds = clientIds;
      dispatch(setAllClients(user.field_clients));
      dispatch(setJobCreateClients(user._processed.job_create_clients));

      return user;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadUser = createAsyncThunk(
  `${namespace}/loadUser`,
  async (_, { signal, rejectWithValue, dispatch, getState }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      if (localStorage.getItem("access_token")) {
        const access_token = localStorage.getItem("access_token");
        const response = await axios.get(`${config.api_url}/rest/csrf/token`, {
          ...tokenConfig(getState),
          cancelToken: source.token,
        });

        localStorage.setItem("csrf", response.data.token);

        return { csrf: response.data.token, access_token };
      }

      return rejectWithValue("You have been logged out");
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkIsValidToken = createAsyncThunk(
  `${namespace}/checkIsValidToken`,
  async (_, { signal, rejectWithValue, dispatch, getState }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      if (localStorage.getItem("access_token")) {
        const response = await axios.get(`${config.api_url}/rest/csrf/token`, {
          ...tokenConfig(getState),
          cancelToken: source.token,
        });

        localStorage.setItem("csrf", response.data.token);

        return response.data.token;
      }
      return rejectWithValue("You have been logged out");
    } catch (err) {
      let error = err; // cast the error for access
      if (!error) {
        return rejectWithValue("Logged out");
      }
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const login = createAsyncThunk(
  `${namespace}/login`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resultAction = await dispatch(authenticate(data));
      unwrapResult(resultAction);
      // const resultActionCurrentUser = await dispatch(fetchCurrentUser());
      // unwrapResult(resultActionCurrentUser);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const authenticate = createAsyncThunk(
  `${namespace}/authenticate`,
  async (data, { signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const { grant_type, client_id, client_secret, scope } = config;
      data.grant_type = grant_type;
      data.client_id = client_id;
      data.client_secret = client_secret;
      data.scope = scope;

      const token = await instance.post(
        `${config.api_url}/oauth/token?_format=json`,
        formatFormData(data),
        { cancelToken: source.token }
      );

      localStorage.setItem("access_token", token.data.access_token);
      localStorage.setItem("refresh_token", token.data.refresh_token);

      const csrf = await instance.get(`${config.api_url}/rest/csrf/token`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
        params: {
          _format: "hal_json",
        },
        cancelToken: source.token,
      });

      localStorage.setItem("csrf", csrf.data.token);

      return {
        csrf: csrf.data.token,
        access_token: token.data.access_token,
        refresh_token: token.data.refresh_token,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const refreshToken = createAsyncThunk(
  `${namespace}/refreshToken`,
  async (data, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { client_id, client_secret } = config;
      const data = {};
      data.grant_type = "refresh_token";
      data.client_id = client_id;
      data.client_secret = client_secret;
      data.refresh_token = localStorage.getItem("refresh_token");
      const token = await axios.post(
        `${config.api_url}/oauth/token?_format=json`,
        formatFormData(data),
        {
          skipAuthRefresh: true,
        }
      );

      localStorage.setItem("refresh_token", token.data.refresh_token);
      localStorage.setItem("access_token", token.data.access_token);

      return {
        csrf: null,
        access_token: token.data.access_token,
        refresh_token: token.data.refresh_token,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error) {
        return rejectWithValue("Logged out");
      }
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const refreshCsrfToken = createAsyncThunk(
  `${namespace}/refreshCsrfToken`,
  async (_, { signal, rejectWithValue, dispatch, getState }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/csrf/token`,
        {
          ...tokenConfig(getState),
        },
        {
          skipAuthRefresh: true,
        }
      );

      localStorage.setItem("csrf", response.data.token);

      return response.data.token;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error) {
        return rejectWithValue("Logged out");
      }
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAccount = createAsyncThunk(
  `${namespace}/updateAccount`,
  async ({ user, params }, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(patchUser({ user, params }));
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerZips = createAsyncThunk(
  `${namespace}/fetchCustomerZips`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer_locations/by-zip/get_zips`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data.filter((zip) => size(zip) > 0 && !isNaN(zip));
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postLogout = createAsyncThunk(
  `${namespace}/postLogout`,
  async ({revokeType, uid}, { getState, dispatch, rejectWithValue }) => {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      let pusherBeamsDeviceID = '';
      if(!isNull(localStorage.getItem("pusher_beam_device_id"))){
        pusherBeamsDeviceID = localStorage.getItem("pusher_beam_device_id")
      }
      await axios.post(
        `${config.api_url}/rest/user/logout`,
        {
          refresh_token: refreshToken,
          revoke_all: revokeType && revokeType === 'all' ? true : false,
          revoke_others: revokeType && revokeType === 'others' ? true : false,
          uid: uid ? uid : null,
          pusher_beams_device_id: pusherBeamsDeviceID,
        },
        tokenConfig(getState)
      );

      return (revokeType && revokeType === 'others');
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: namespace,
  initialState: {
    token: localStorage.getItem("access_token"),
    refresh_token: localStorage.getItem("refresh_token"),
    csrf: localStorage.getItem("csrf"),
    isAuthenticated: null,
    loading: false,
    user: {
      loading: false,
      data: {
        _processed: {
          askaime_access: false,
          update_access: false,
          alias: null,
          is_intranet_team_admin: false,
          is_intranet_event_admin: false,
          is_intranet_announcement_admin: false,
          is_intranet_news_admin: false,
          is_intranet_file_admin: false,
          is_pc_intranet_items_admin: false,
          is_g2: false,
          is_d2d: false,
          detail_access: false,
          company_alias: null,
          is_affiliate_member: false,
          job_create_permission: false,
          customer_create_permission: false,
          member_create_permission: false,
          daily_sheets_access_permission: false,
          compliance_access_permission: false,
          job_accounting_invoice_template_option_access: false,
          roles: [],
          reports: {
            accounting: false,
            admin_accounting: false,
            weekly_accounting: false,
            aging_report: false,
            compliance_documents: false,
            customers: false,
            customer_locations: false,
            customer_notes: false,
            customer_users: false,
            job_divisions: false,
            hpn_select: false,
            members: false,
            member_notes: false,
            member_users: false,
            override_members: false,
            override_duplicate_jobs: false,
            time_entries: false,
            wip_overview: false,
            wip_detail: false,
            wip_detail_parent_customer: false,
          },
        },
      },
    },
    current_client: null,
    customer_zip_codes: [],
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("csrf");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("access_token");

      state.loading = false;
      state.isAuthenticated = false;
      state.csrf = null;
      state.token = null;
      state.refresh_token = null;

    //  Disconnect from Pusher Beams
      logoutPusherBeams();
    },
    setClient: (state, { payload }) => {
      state.current_client = payload;
    },
    updateAuthUser: (state, { payload }) => {
      state.user.data = payload;
    },
  },
  extraReducers: {
    [postHelixApiToken.fulfilled](state, { payload: { token } }) {
      state.user.data._processed.phx_offsite_api_token = token;
    },
    [authenticate.pending](state) {
      state.loading = true;
    },
    [authenticate.fulfilled](
      state,
      { payload: { csrf, access_token, refresh_token } }
    ) {
      state.loading = false;
      state.isAuthenticated = true;
      state.csrf = csrf;
      state.token = access_token;
      state.refresh_token = refresh_token;
      state.error = null;
    },
    [authenticate.rejected](state, action) {
      if (!action.meta.aborted) {
        localStorage.removeItem("csrf");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");

        state.loading = false;
        state.isAuthenticated = false;
        state.csrf = null;
        state.token = null;
        state.refresh_token = null;

      //  Disconnect from Pusher Beams
        logoutPusherBeams();

        if (typeof action.payload === "string") {
          state.error = action.payload;
        } else if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [checkIsValidToken.pending](state) {
      state.loading = true;
    },
    [checkIsValidToken.fulfilled](state, { payload: csrf }) {
      state.loading = false;
      state.isAuthenticated = true;
      state.csrf = csrf;
    },
    [checkIsValidToken.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        state.isAuthenticated = false;
        state.csrf = null;

        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [refreshCsrfToken.fulfilled](state, { payload: csrf }) {
      state.isAuthenticated = true;
      state.csrf = csrf;
    },
    [refreshCsrfToken.rejected](state, action) {
      if (!action.meta.aborted) {
        state.isAuthenticated = false;
        state.csrf = null;

        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [loadUser.pending](state) {
      state.loading = true;
      state.user.loading = true;
    },
    [loadUser.fulfilled](state, { payload: { csrf, access_token } }) {
      state.loading = false;
      state.user.loading = false;
      state.isAuthenticated = true;
      state.csrf = csrf;
      state.token = access_token;
    },
    [loadUser.rejected](state, action) {
      if (!action.meta.aborted) {
        localStorage.removeItem("csrf");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");

        state.loading = false;
        state.user.loading = false;
        state.isAuthenticated = false;
        state.csrf = null;
        state.token = null;
        state.refresh_token = null;

      //  Disconnect from Pusher Beams
        logoutPusherBeams();

        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [refreshToken.pending](state) {
      state.loading = true;
    },
    [refreshToken.fulfilled](
      state,
      { payload: { csrf, access_token, refresh_token } }
    ) {
      state.loading = false;
      state.isAuthenticated = true;
      state.csrf = csrf;
      state.token = access_token;
      state.refresh_token = refresh_token;
    },
    [refreshToken.rejected](state, action) {
      if (!action.meta.aborted) {
        localStorage.removeItem("csrf");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");

        state.loading = false;
        state.isAuthenticated = false;
        state.csrf = null;
        state.token = null;
        state.refresh_token = null;

      //  Disconnect from Pusher Beams
        logoutPusherBeams();

        if (action.payload) {
          if (action.payload.message) {
            state.error = action.payload.message;
          } else {
            state.error = "Refresh token is invalid";
          }
        } else {
          if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Refresh token is invalid";
          }
        }
      }
    },
    [fetchCurrentUser.pending](state) {
      state.user.loading = true;
    },
    [fetchCurrentUser.fulfilled](state, { payload: user }) {
      state.user.loading = false;
      state.user.data = user;
    },
    [fetchCurrentUser.rejected](state, action) {
      state.user.loading = false;
      if (action.payload) {
        state.user.error = action.payload.message;
      } else {
        state.user.error = action.error.message;
      }
    },
    [fetchCustomerZips.pending](state) {
      state.customer_zip_codes = [];
    },
    [fetchCustomerZips.fulfilled](state, { payload: zips }) {
      state.customer_zip_codes = zips;
    },
    [fetchCustomerZips.rejected](state, action) {
      if (action.payload) {
        state.user.error = action.payload.message;
      } else {
        state.user.error = action.error.message;
      }
    },
    [postLogout.pending](state, action) {
      state.loading = true;
    },
    [postLogout.fulfilled](state, {payload: revokedOthers}) {
      if(!revokedOthers){
        localStorage.removeItem("csrf");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");

        state.isAuthenticated = false;
        state.csrf = null;
        state.token = null;
        state.refresh_token = null;

      //  Disconnect from Pusher Beams
        logoutPusherBeams();
      }
    },
    [postLogout.rejected](state, action) {
      // localStorage.removeItem("csrf");
      // localStorage.removeItem("refresh_token");
      // localStorage.removeItem("access_token");

      state.loading = false;
      // state.isAuthenticated = false;
      // state.csrf = null;
      // state.token = null;
      // state.refresh_token = null;
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getIsAuthenticatedSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.auth.isAuthenticated
);

export const getAuthErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.auth.error
);

export const getAccessTokenSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.auth.token
);

export const { logout, setClient, updateAuthUser } = authSlice.actions;

export default authSlice.reducer;
