import React, { useCallback, useEffect, useState } from "react";
import { AiFillYoutube } from "react-icons/ai";
import { FaVimeoSquare, FaGoogle, FaTwitterSquare } from "react-icons/fa";
import { SiImgur } from "react-icons/si";
import { useDispatch } from "react-redux";
import { postPreview } from "features/Conversations/conversationsSlice";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Gif } from "@giphy/react-components";
import { first, size } from "lodash";
import { XCircleIcon } from "@heroicons/react/solid";

const giphylogo = `${process.env.PUBLIC_URL}/images/giphy-logo-b.png`;
const giphyFetch = new GiphyFetch("txXcXaoyiMVybl4h2DPMy2WLc21BerO4");

const icons = {
  YouTube: <AiFillYoutube />,
  Vimeo: <FaVimeoSquare />,
  Google: <FaGoogle />,
  Twitter: <FaTwitterSquare />,
  Imgur: <SiImgur />,
};

const YOUTUBE_PREFIX = "https://www.youtube.com/embed/";
const VIMEO_PREFIX = "https://player.vimeo.com/video/";

const Twitter = ({ payload }) => {
  return (
    <div className="border-l-4 border-gray-middle rounded p-2 pl-4 mr-2 bg-gray-lightest">
      <div className="flex items-center mb-2 text-gray-darkest font-bold">
        <div>{icons["Twitter"]}</div>
        <p className="text-sm ml-2">{payload?.oEmbed?.provider_name}</p>
      </div>
      <a
        target="blank"
        href={payload?.oEmbed?.url}
        className="rounded inline-block w-[350px]"
      >
        {payload?.api?.includes?.media?.[0]?.url && (
          <img
            src={payload.api.includes.media[0]?.url}
            className="w-full rounded overflow-hidden"
            alt="twitter cover"
          />
        )}
        <div className="pt-4 flex flex-col">
          <p className="flex-1 truncate text-ellipsis mb-2 text-gray-darkest">
            {payload?.api?.data?.text}
          </p>
          <a target="blank" href={payload?.oEmbed?.author_url}>
            @{payload?.oEmbed?.author_name}
          </a>
        </div>
      </a>
    </div>
  );
};

const Youtube = ({ id }) => {
  return (
    <div className="border-l-4 border-gray-middle rounded p-2 pl-4 mr-2 bg-gray-lightest">
      <div className="flex items-center mb-2 text-gray-darkest font-bold">
        <div>{icons["YouTube"]}</div>
        <p className="text-sm ml-2">YouTube</p>
      </div>
      <div className="w-[350px] rounded overflow-hidden">
        <iframe
          title="Youtube"
          frameBorder="0"
          allowFullScreen
          src={`${YOUTUBE_PREFIX}${id}`}
          style={{
            width: "100%",
            maxWidth: "350px",
            height: "250px",
          }}
        />
      </div>
    </div>
  );
};

const Vimeo = ({ id, payload }) => {
  return (
    <div className="border-l-4 border-gray-middle rounded p-2 pl-4 mr-2 bg-gray-lightest">
      <div className="flex items-center mb-2 text-gray-darkest font-bold">
        <div>{icons["Vimeo"]}</div>
        <p className="text-sm ml-2">Vimeo</p>
      </div>
      <div className="w-[350px] rounded overflow-hidden">
        <p className="text-sm mt-2 text-gray-darker truncate text-ellipsis">
          {payload?.oEmbed?.title}
        </p>
        <iframe
          title="Vimeo"
          src={`${VIMEO_PREFIX}${id}?h=ef103ac0a7&title=0&byline=0&portrait=0`}
          style={{
            width: "100%",
            maxWidth: "350px",
            height: "250px",
          }}
          frameBorder="0"
          allowFullscreen
        />
      </div>
    </div>
  );
};

const Giph = ({ id, onClear }) => {
  const [gif, setGif] = useState(null);

  const fetchGif = useCallback(async () => {
    const { data } = await giphyFetch.gif(id);
    setGif(data);
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchGif();
    }
  }, [fetchGif, id]);

  return (
    gif && (
      <div className="giphy-preview-wrapper">
        {onClear && (
          <div className="text-right">
            <XCircleIcon className="icon inline-block text-red-500 h-5 w-5 cursor-pointer"  onClick={onClear} />
          </div>
        )}
        <Gif gif={gif} width={350} />
        <div className="giphy-caption flex pt-1">
          <div className="giphy-title text-sm text-gray-dark max-w-[350px] truncate text-ellipsis">
            {gif.title}
          </div>
          <div className="giphy-logo ml-2 flex justify-end items-center flex-1">
            <img className="h-6" src={giphylogo} alt="Powered by Giphy" />
          </div>
        </div>
      </div>
    )
  );
};

const Generic = ({ payload }) => {
  const image = first(payload?.metas?.image);
  const title = payload?.metas?.title;
  const description = payload?.metas?.description;

  if (!title && !description) return null;

  return (
    <div className="border-l-4 border-gray-middle rounded p-2 pl-4 mr-2 bg-gray-lightest">
      <div className="flex items-center mb-2 text-gray-darkest font-bold">
        <p className="text-sm">{payload?.provider}</p>
      </div>
      <div className="w-[350px] rounded overflow-hidden">
        <a href={payload?.metas?.url} className=" truncate text-ellipsis">
          {payload?.metas?.title}
        </a>
        <p className="text-sm mt-2 text-gray-darker mb-2">
          {payload?.metas?.description}
        </p>
        {image && <img src={image} className="w-full rounded" alt="cover" />}
      </div>
    </div>
  );
};

const Preview = ({ link, onClear }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});

  const fetchPreview = useCallback(
    async (params) => {
      const response = await dispatch(postPreview(params)).unwrap();
      setPayload(response.data);
      setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    if (link.srcType !== "giphy") {
      fetchPreview({ url: link.url.replaceAll("\\", "") });
    }
  }, [dispatch, link.url, fetchPreview, link.srcType]);

  if (loading && link.srcType !== "giphy") return null;

  switch (link.srcType) {
    case "twitter":
      return <Twitter payload={payload} onClear={onClear} />;
    case "youtube":
      return <Youtube id={link.srcID} onClear={onClear} />;
    case "giphy":
      return <Giph id={link.srcID} onClear={onClear} />;
    case "vimeo":
      return <Vimeo id={link.srcID} payload={payload} onClear={onClear} />;
    default:
      return <Generic payload={payload} onClear={onClear} />;
  }
};

const Previews = ({ links, onClear }) => {
  if (!links || !size(links)) return null;

  return (
    <div className="preview-wrapper flex py-2 flex-wrap">
      {links.map((link, i) => (
        <Preview key={i} link={link} onClear={onClear} />
      ))}
    </div>
  );
};

Previews.propTypes = {};

export default Previews;
