import React, { useState } from "react";
import { size, first } from "lodash";
import { useSelector } from "react-redux";
import { clientsSelectors } from "features/Clients/clientsSlice";
import { filterEntityClients } from "components/ClientSelector/utils";
import { SelectFormField } from "common/Fields";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

import "common/LinkDropdown/LinkDropdown.scss";

export const ClientAssignedFieldAddEdit = ( {
  entity,
  clients,
  field,
  values,
  setFieldValue,
  options,
  optionsSelectors,
  selectChip,
  isSubmitting,
  emptyMessage,
  wrapperClassName,
  ...props
} ) => {
  const _clients = useSelector(clientsSelectors.selectAll);
  const [same, setSame] = useState(values[`${field}_same`]);

  let availableClients = clients;
  if(!availableClients || size(availableClients) === 0){
    if(entity){
      availableClients = filterEntityClients(entity?.field_phoenix_clients, _clients);
    }
    else if(size(_clients) > 0){
      availableClients = [first(_clients)];
    }
  }
  let clientNames = availableClients.map((client) => client?.title ? client.title : client.name).join(", ");
  clientNames = clientNames.slice(0, clientNames.lastIndexOf(",")) + " &" + clientNames.slice(clientNames.lastIndexOf(",") + 1);
  const displayClients = (same && size(availableClients) > 0 ? [first(availableClients)] : availableClients);

  return (
    <>
    {displayClients.length === 0 && (
      emptyMessage
    )}
    {displayClients.map((client, i) => (
      <React.Fragment key={`client-assigned-title-${field}-${client.nid}`}>
        {displayClients.length > 1 &&
          <Typography
            variant="substitle"
            className="block mt-3 mb-1"
          >
            {client?.title ? client.title : client.name}
          </Typography>
        }
        <SelectFormField
          fullWidth
          name={`${field}[${i}]`}
          label={props.label}
          margin="normal"
          multiple
          variant="filled"
          size="small"
          disabled={isSubmitting || props.disabled}

          wrapperClassName={`${wrapperClassName} ${availableClients.length > 1 && !same ? "mt-0" : ''}`}
          {...props}
          onChange={(e) => {
            if(!same){
              setFieldValue(`${field}[${i}]`, e.target.value);
            }
            else{
              availableClients.forEach((_client, j) => {
                setFieldValue(`${field}[${j}]`, e.target.value);
              });
            }
          }}
          renderValue={(selected) => (
            <div
              style={{
                minHeight: "10px",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {selected.map((value, i) =>
                selectChip(value, i, field, optionsSelectors)
              )}
            </div>
          )}
          options={options.map((s) => ({
            label: s.name,
            value: s.tid,
          }))}
        />
      </React.Fragment>
    ))}
    {availableClients.length > 1 && (
      <FormControlLabel
        control={
          <Checkbox
            name={`${field}_same`}
            checked={same}
            onChange={(e) => {
              setSame(e.target.checked);
              setFieldValue(`${field}_same`, e.target.checked);
              if(e.target.checked){
              //  Duplicate the first values for all the rest
                let firstValues = [];
                availableClients.forEach((_client, j) => {
                  if(j === 0){
                    firstValues = values[field][j];
                  }
                  else{
                    setFieldValue(`${field}[${j}]`, firstValues);
                  }
                });
              }
            }}
         />
        }
        label={`Same ${props.label} for ${clientNames}?`}
        disabled={isSubmitting || props.disabled}
      />
    )}
  </>
  )
};

ClientAssignedFieldAddEdit.propTypes = {};

export default ClientAssignedFieldAddEdit;
