import React from "react";
import ContentLoader from "react-content-loader";
import constants from "../constants";

const ChartSkeleton = props => {
  return (
    <ContentLoader
      speed={2}
      width={75}
      height={75}
      viewBox="0 0 75 75"
      foregroundColor={constants.colorGray}
      backgroundColor={constants.colorGrayLight}
    >
      <rect x="0" y="25" rx="1" ry="1" width="20" height="50" />
      <rect x="25" y="45" rx="1" ry="1" width="15" height="30" />
      <rect x="45" y="50" rx="1" ry="1" width="15" height="25" />
      <rect x="65" y="0" rx="1" ry="1" width="10" height="75" />
    </ContentLoader>
  );
};

export default ChartSkeleton;
