import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
`;

const Container = styled.div`
  animation: ${rotate} 2s linear infinite;
  width: 100%:
`;

const FileSkeleton = (props) => (
  <Container>
    <div
      style={{
        width: "100%",
        height: 110,
        borderRadius: 2,
        background: "var(--color-gray-lighter)",
        marginBottom: 4,
      }}
    ></div>
    <div
      style={{
        width: 100,
        height: 14,
        borderRadius: 2,
        background: "var(--color-gray-lighter)",
        marginBottom: 4,
      }}
    ></div>
    <div
      style={{
        width: 75,
        height: 12,
        borderRadius: 2,
        background: "var(--color-gray-lighter)",
      }}
    ></div>
  </Container>
);

export default FileSkeleton;
