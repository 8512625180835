import action from "./action";
import config from "../config";

export {
  getRegions,
  getComplianceChangeReasons,
  getMemberFileCategories,
} from "./taxonomies";

export {
  postParagraph,
  patchParagraph,
  deleteParagraph,
  getParagraph,
  getSubParagraph,
} from "./paragraphs";

export {
  getMemberStatus,
  getLossCategories,
  getCustomerStatus,
  getDaDemo,
  getDaWaterPresent,
  getDaSafety,
  getDaPower,
  getDsEntryTypes,
  getCustInvoiceTerms,
  getCustInvoiceDiscount,
  getLiftMethods,
  getStates,
  getCounties,
  getAvailableUsers,
  getAvailableMemberUsers,
  getAvailableDailySheetsUsers,
  getAvailableJobMembers,
  getAvailableJobCustomers,
  getAvailableJobLocations,
  getAvailableMembersByCoords,
  getJobAccountingTemplateOptions,
  getCurrentJobDivisionStatus,
} from "./fields";

export {
  getWorkareas,
  postWorkArea,
  getWorkArea,
  updateWorkArea,
  getWorkAreaResources,
  getWorkareaNotes,
  reviewWorkarea,
  archiveWorkarea,
} from "./workareas";

export {
  getDailySheets,
  getDailySheetsInvoices,
  getDailySheetsDates,
  getDailySheet,
  getUserServiceHours,
  getDailySheetOverrides,
  postDailySheet,
  getDailySheetsResources,
  patchDailySheet,
  getDailySheetsResourcesForInvoice,
  postValidation,
  patchValidation,
  archiveDailySheet,
} from "./dailysheets";

export {
  getJob,
  getDivisionJob,
  updateDivisionJob,
  getJobAccountings,
  postJobAccountings,
  getJobActivity,
  // getJobAuditRequirements,
  getJobNotes,
  getJobSnapshot,
  getJobs,
  postDivision,
  updateJob,
  postJob,
  getPHXClientReps,
  getJobPdf,
  toggleHold,
  patchJobAccountings,
  getJobAccounting,
  postAccountingToSage,
  getJobTasks,
  getIncomingJobs,
  patchTimeEntry,
  getTimeEntries,
  declineJob,
  postComplianceOverride,
  getRecentlyCreatedJobs,
  archiveJobComment,
  getJobFiles,
  getJobDivisions,
  clearJobDivisions,
  getJobEstimates,
  getJobPhotos,
} from "./job";

export {
  getAvailableMemberUsersIncludingTemp,
  deleteTempUser,
  getChildMembers,
  getMember,
  getMemberActivity,
  getMemberCompliance,
  getMemberEstimates,
  getMemberFiles,
  getMemberJobs,
  getMemberNotes,
  getMemberSnapshot,
  getMemberTempUsers,
  getMemberUsers,
  getMembers,
  patchMember,
  postChildMember,
  postMember,
  postMemberUser,
  getMemberInsuranceAgent,
  getMemberInsuranceAgents,
  patchMemberInsuranceAgent,
  postMemberInsuranceAgent,
} from "./member";

export {
  getChildCustomers,
  getCustomer,
  getCustomerActivity,
  getCustomerJobs,
  getCustomerNotes,
  getCustomerResources,
  getCustomerSnapshot,
  getCustomerUsers,
  getCustomerlocations,
  getCustomers,
  patchCustomer,
  postCustomer,
  postCustomerUser,
  getCustomerFiles,
} from "./customer";

export {
  getLocationJobs,
  getAvailableLocationMembers,
  getLocation,
  getLocationSnapshot,
  patchLocation,
  postLocation,
} from "./location";

export {
  patchComment,
  patchMemberComment,
  postCustomerComment,
  postJobComment,
  postMemberComment,
  postWorkareaComment,
  postComment,
} from "./comment";

export {
  getComplianceActivity,
  patchComplianceActivity,
  postComplianceActivity,
  getComplianceActivities,
  liftComplianceActivity,
  deleteComplianceActivity,
} from "./compliance";

export {
  getMapJobs,
  getMapMembers,
  getMapCustomers,
  clearMapData,
  getMapSearch,
  getMapZones,
  postZone,
  postMapLocation,
  patchZone,
  postMapGroup,
  getMapGroups,
  getMapLocations,
  patchMapLocation,
  getMapCustomerLocations,
  deleteZone,
} from "./mapping";

export { getWidgetData } from "./dashboard";

export { getUsers, getUser, postTempUser } from "./users";

export {
  getTasksDrawer,
  getTasks,
  postTask,
  getTask,
  patchTask,
  completeTask,
} from "./tasks";

export {
  getReportAccounting,
  getReportAccountingDKI,
  getReportMembers,
  getReportCustomers,
  getReportCustomerLocations,
  getReportCustomerNotes,
  getReportJobsAging,
  getReportComplianceDocs,
  getReportJobDivisions,
  getReportMemberNotes,
  getReportCustomerUsers,
  getReportMemberUsers,
  getReportMembersOverride,
  getReportJobsOverride,
  getReportWeeklyAccountingDKI,
  getReportWipDetail,
  getReportWipDetailParent,
  getReportWipOverview,
  getReportHpnSelect,
  getReportTimeEntries,
  getReportTerritories,
  getReportAgreements,
  getReportTerminations,
  getReportOwners,
  getReportEmployees,
  getReportAddresses,
  getReportMemberFullListing,
  getReportEntityName,
  getReportXactimate,
  getReportJobsAgingSpan,
} from "./reports";

export {
  postAimeProcessFile,
  getAimeCarriers,
  postAimeAnalyzeFile,
  postAimeFinalizeReport,
} from "./askaime";

export { processBatch, exportBatch, exportBatchClear } from "./batch";

export {
  getJobAccountingInvoice,
  getJobAccountingInvoiceForm,
  postJobAccountingInvoice,
  patchJobAccountingInvoice,
} from "./accounting";

export {
  getInsuranceCarrier,
  postInsuranceCarrier,
  patchInsuranceCarrier,
} from "./insurance";

export {
  patchProjectManagerConfirmation,
  getJobProgressFieldData,
  patchSendCustomerVideo,
  patchCrewConfirmation,
  patchCustomerSurvey,
  patchJobSecured,
  postMoreFloodsFile,
  getMoreFloodsPhotos,
  patchMFPhoto,
  postMoreFloodsScope,
  patchMoreFloodsScope,
  postMoreFloodsDirectPay,
  getMoreFloodsDirectPays,
  getMoreFloodsWorkAuthorizations,
  getMoreFloodsCIS,
  patchPlatinumPackage,
  patchPriceProvided,
  patchReferrerFollowupProvided,
  patchInfoSentRestServ,
  patchCustomerCall,
  patchCustomerSurveySent,
  patchFinalReferralFeeConfirmation,
  getMoreFloodsDocument,
  getMoreFloodsUsers,
  getMoreFloodsProgress,
  patchProjectManagerAssignment,
  patchMoreFloodsMarkWetSpots,
  getMoreFloodsDocuments,
  patchMoreFloodsEmailInsurance,
  patchMoreFloodsCRMUpdated,
  patchMoreFloodsInvoiced,
  patchMoreFloodsPaymentReceived,
  getMoreFloodsMonitorings,
  getMoreFloodsProjectManager,
  patchMoreFloodsFloorSamples,
  getMoreFloodsMonitoringData,
  patchProjectManagerDeclination,
  getMoreFloodsCOS,
  patchCrewDeclination,
  getAllJobScopes,
} from "./morefloods";

export { postCrew, getJobCrews, getJobCrew, patchCrew } from "./crew";

export {
  getMemberCapabilities,
  getMemberTerminations,
  getMemberEmployees,
  getMemberEntityDetails,
  getMemberFinancials,
  getMemberTerminationDocs,
  getMemberRenewals,
  getCrmCalls,
  getCRMTasks,
  getMemberEmployeeEducation,
  getMemberAgreementDocs,
  getMemberTerritories,
  getCRMEmails,
  getEntityEmployees,
  getMemberGenDocuments,
} from "./connect";

export {
  getTeam,
  getTeams,
  getTeamComments,
  getTeamUsers,
  patchAlertTaggedUsers,
  getTeamUnreadComments,
  patchMarkLastReadComment,
  postTeam,
  getTeamFiles,
  getNews,
  getEvents,
  getEventsCalendar,
  getEvent,
  patchLikeNews,
  getNewsPostComments,
  postNews,
  patchNews,
  getResources,
  getPinnedNews,
  getAnnouncements,
  postEvent,
  patchEvent,
  postResource,
  patchTeam,
  getTeamSummary,
  getDirectMessageSummary,
  getIntranetExternalLinks,
  getIntranetSponsorships,
  patchResource,
  deleteComment,
  sendNotification,
} from "./intranet";

export { getFile } from "./files";

export {
  postTaxonomy,
  getIntranetFileFolders,
  patchTaxonomy,
  getMemberGenFileFolders,
} from "./taxonomies";

export {
  getChambers,
  getChamber,
  postChamber,
  patchChamber,
  postRoom,
  getRooms,
  getRoom,
  patchRoom,
  postStockToRoom,
  patchStockToRoom,
} from "./chamber";

export {
  getMemberInventory,
  getInventoryStock,
  postInventoryStock,
  patchInventoryStock,
  getMaintenanceHistory,
  getMaintenanceSchedules,
  getUsageHistory,
  scheduleMaintenance,
  getJobInventory,
} from "./inventory";

export const getUserData = () =>
  action("user", config.api_url, "rest/user/current");

export const getUserJobData = (uid, params) =>
  action(
    "userJobs",
    config.api_url,
    `rest/user/jobs${uid ? `/${uid}` : ""}`,
    params
  );

export const getUserActivity = (params) =>
  action("userActivity", config.api_url, "rest/user/recent-activity", params);

export const getAddressGeo = (params) =>
  action("addressGeo", config.api_url, `rest/geo/address-coords`, params);

export const getQuickSearchResults = (query, params) =>
  action("quickSearch", config.api_url, `rest/search/${query}`, params);

export const getSearchResults = (query, params) =>
  action("search", config.api_url, `rest/search/${query}`, params);

export const getNode = (nid, params) =>
  action("node", config.api_url, `node/${nid}`, params);

export const clear = (type) => action(type, "", "", {}, "clear");

export const deleteNode = (nid) =>
  action("node", config.api_url, `node/${nid}`, {}, "delete");

export const deactivateNode = (nid, params) => {
  params.status = [{ value: 0 }];
  return action("node", config.api_url, `node/${nid}`, params, "patch");
};
