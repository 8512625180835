import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import companiesAPI from "./companiesAPI";

const namespace = "companies";

export const fetchCompanyUsers = createAsyncThunk(
  "companies/fetchCompanyUsers",
  async ({ id, config }, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await companiesAPI.getUsers({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postConversation = createAsyncThunk(
  "conversations/postConversation",
  async (params, { rejectWithValue, getState }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await companiesAPI.addConversation({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const companiesAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const companiesSlice = createSlice({
  name: namespace,
  initialState: companiesAdapter.getInitialState({
    company: {},
    users: companiesAdapter.getInitialState(),
  }),
  reducers: {
    setAllCompanies: (state, action) => {
      companiesAdapter.setAll(state, action);
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
  },
  extraReducers: {
    [postConversation.fulfilled](state, { payload: conversation }) {
      companiesAdapter.addOne(state, conversation);
    },
    [fetchCompanyUsers.fulfilled](state, { payload: { data } }) {
      companiesAdapter.setAll(state.users, data);
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getCompaniesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.companies.loading
);

export const getCompaniesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.companies.pagination
);

export const companiesSelectors = companiesAdapter.getSelectors(
  (state) => state.companies
);

export const companyUsersSelectors = companiesAdapter.getSelectors(
  (state) => state.companies.users
);

export const { setAllCompanies, setCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
