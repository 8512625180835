import React, { useState, useEffect } from "react";
import { Formik, useFormikContext } from "formik";

import Alert from "common/Alert";
import { FormContainer } from "./Styled";

const FormikSubmitEffect = ({ onSubmitError, onSubmitting }) => {
  const { submitCount, isValid, isSubmitting } = useFormikContext();
  const [lastHandled, setLastHandled] = useState(0);
  useEffect(() => {
    if (submitCount > lastHandled && !isValid) {
      onSubmitError();
      setLastHandled(submitCount);
    } else {
      if (onSubmitting) {
        onSubmitting(isSubmitting);
      }
    }
  }, [
    submitCount,
    isValid,
    onSubmitError,
    lastHandled,
    isSubmitting,
    onSubmitting,
  ]);
  return null;
};

const Form = ({
  onSubmit,
  disablePadding,
  successCallback,
  errorCallback,
  onSubmitError,
  onSubmitting,
  children,
  ...props
}) => {
  const [message, setMessage] = React.useState(null);

  return (
    <Formik
      onSubmit={async (data, { setSubmitting, setFieldValue }) => {
        await onSubmit(
          data,
          setSubmitting,
          setMessage,
          successCallback,
          errorCallback,
          setFieldValue
        );
      }}
      {...props}
    >
      {({
        values,
        errors,
        isSubmitting,
        validateField,
        setFieldValue,
        setFieldError,
      }) => (
        <FormContainer theme={{ padding: disablePadding ? 0 : "0 1.25rem" }}>
          {message && <Alert kind={message.id}>{message.msg}</Alert>}
          <FormikSubmitEffect
            onSubmitError={onSubmitError}
            onSubmitting={onSubmitting}
          />
          {children({
            values,
            errors,
            isSubmitting,
            validateField,
            setFieldValue,
            setFieldError,
          })}
        </FormContainer>
      )}
    </Formik>
  );
};

Form.propTypes = {};

export default Form;
