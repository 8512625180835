import React from "react";
import { Typography } from "@mui/material";
// import TruncateReactMarkdown from 'common/TruncateReactMarkdown';
import Avatar from "common/Avatar";
import moment from "moment";
// import ReactDomServer from 'react-dom/server';
import { linkStrategy } from "features/Hub/components/editor/plugins/Linkify";
import Previews from "features/Hub/components/editor/Previews";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

export const notification = ( {data, isSubNotication, currentUser} ) => {
  const others = data?.participants ? data.participants.filter((p) => p?.first_name && p.id !== currentUser?.id) : [];
  let othersList = "";
  others.forEach((p, i) => {
    if(i > 0 && i === others.length - 1){
      othersList += ' and ';
    }
    else if(i > 0){
      othersList += ', ';
    }
    othersList += p.first_name + ' ' + p.last_name;
  });


  const { text, links } = linkStrategy(data.body);

  return {
    'path': `/hub/conversations/c/${data.thread_id}#${data.id}`,
    'icon': (
      <Avatar
        key={data.user.id}
        title={data.title}
        link={data?.icon ? data?.icon : data.user?.avatar}
        sx={{ height: 48, width: 48 }}
        variant="circle"
      />
    ),
    'body': (
      <div className="flex flex-col justify-between h-full">
        <div className="flex justify-end">
          <div className="h-4 flex-1 min-w-0 overflow-hidden text-ellipsis whitespace-nowrap">{data.title}</div>
          <div className="whitespace-nowrap text-sm">{moment(data.created_at).format('M/D/YY h:mma')}</div>
        </div>
        <div className="flex-[1_0_auto] flex px-1 italic">
          {text !== '' && (
            <div className={`${others.length === 0 ? 'multiline-ellipsis' : 'multiline-ellipsis-2'} flex-1`}>
            {/* <TruncateReactMarkdown
              lines={(others.length <= 1 ? 3 : 2)}
            > */}
              <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]} >{text}</ReactMarkdown>
            {/* </TruncateReactMarkdown> */}
            </div>
          )}
          {links.length > 0 && (
            <div className="preview-links">
              <Previews links={links} />
            </div>
          )}
        </div>
        {others.length > 1 && (
          <Typography variant="body2" className="whitespace-nowrap overflow-hidden text-ellipsis">Conversation: {othersList}</Typography>
        )}
      </div>
    )
  };
};

