import React, { useCallback, useState } from "react";
import { DateRangePicker } from "react-dates";
import { ArrowForward } from "@mui/icons-material";
import { Field } from "formik";
import { InputLabel } from "@mui/material";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { size } from "lodash";

const DateRangeFormField = ({ name, label, ...props }) => {
  const [focusedInput, setFocusedInput] = useState(null);

  const enumerateNumDaysBetweenDates = useCallback((startDate, endDate) => {
    const dates = [startDate.format("YYYY-MM-DD")];

    if (startDate && endDate && !startDate.isSame(endDate)) {
      if (startDate.isBefore(endDate)) {
        const currDate = startDate.clone().startOf("day");
        const lastDate = endDate.clone().startOf("day");

        while (currDate.add(1, "days").diff(lastDate) < 0) {
          dates.push(currDate.clone().format("YYYY-MM-DD"));
        }

        dates.push(endDate.format("YYYY-MM-DD"));
      }
    }

    return size(dates);
  }, []);

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const handleDateChange = ({ startDate, endDate }) => {
          form.setFieldValue(name, {
            start: startDate,
            end: endDate,
            days: enumerateNumDaysBetweenDates(startDate, endDate),
          });
        };

        return (
          <div style={{ marginTop: "16px", marginBottom: "8px" }}>
            {label && (
              <InputLabel style={{ marginBottom: "0.5rem" }}>
                {label}
              </InputLabel>
            )}
            <DateRangePicker
              startDate={field.value.start}
              startDateId="event_add_start_date_id"
              endDate={field.value.end}
              endDateId="event_add_end_date_id"
              onDatesChange={handleDateChange}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              withPortal
              appendToBody
              customArrowIcon={<ArrowForward fontSize="small" />}
              {...props}
            />
          </div>
        );
      }}
    </Field>
  );
};

DateRangeFormField.propTypes = {};

export default DateRangeFormField;
