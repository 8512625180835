import { Badge, } from "@mui/material";
import constants from "components/constants";

export const Indicators = ({children, orange, red, green, marginRightStart}) => {
  let badge = children;
  let marginRight = (marginRightStart ? Number(marginRightStart) : 0);
  let zIndex = 5;
  if(red){
    badge = <Badge
      color="error"
      variant="dot"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        // [`&`]: {
        //   paddingLeft: "0.5rem",
        // },
        [`& > .MuiBadge-badge`]: {
          left: `calc(100% - ${marginRight}rem)`,
          boxShadow: constants.dropShadowSharp,
          zIndex: zIndex,
        }
      }}
    >
      {badge}
    </Badge>;
    marginRight += 0.4;
    zIndex -= 1;
  }
  if(orange){
    badge = <Badge
      color="tertiary"
      variant="dot"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        // [`&`]: {
        //   paddingLeft: "0.5rem",
        // },
        [`& > .MuiBadge-badge`]: {
          left: `calc(100% - ${marginRight}rem)`,
          boxShadow: constants.dropShadowSharp,
          zIndex: zIndex,
        }
      }}
    >
      {badge}
    </Badge>;
    marginRight += 0.4;
    zIndex -= 1;
  }
  if(green){
    badge = <Badge
      variant="dot"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        // [`&`]: {
        //   paddingLeft: "0.5rem",
        // },
        [`& > .MuiBadge-badge`]: {
          backgroundColor: "var(--color-green)",
          boxShadow: constants.dropShadowSharp,
          left: `calc(100% - ${marginRight}rem)`,
          zIndex: zIndex,
        }
      }}
    >
      {badge}
    </Badge>;
    marginRight += 0.4;
    zIndex -= 1;
  }

  return (
    badge
  );
}

export default Indicators;
