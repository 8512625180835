import React, { useState, useRef, useMemo } from "react";
import {
  Tabs,
  Tab,
  Paper,
  Popper,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from "@mui/material";
import { size } from "lodash";
import { BiListCheck } from "react-icons/bi";
import withStyles from "@mui/styles/withStyles";

const StyledTab = withStyles((theme) => ({
  root: {
    backgroundColor: (props) =>
      props.selected ? "var(--color-secondary)" : "transparent",
    borderRadius: "3px",
    padding: "0.25rem",
    minHeight: "0",
    color: "var(--color-secondary)"/*(props) =>
      props.selected ? "white !important" : "var(--color-secondary)"*/,
    zIndex: 1,
    transition: "background-color 0.25s, opacity 0.25s",
    "&:focus": {},
    "&:hover": {
      opacity: 1,
    },
  },
}))((props) => <Tab {...props} />);

const StyledTabs = withStyles((theme) => ({
  root: {
    padding: "1.25rem",
    backgroundColor: ({ fillBkg }) => "white",
    borderTop: ({ fillBkg }) => "none",
    borderBottom: ({ fillBkg }) => "none",
  },
  indicator: {
    display: "none",
  },
}))(({ fillBkg, ...rest }) => <Tabs {...rest} />);

const TabbedAutocomplete = ({
  options,
  onChange,
  value,
  filters,
  onFilter,
  ...rest
}) => {
  const inputRef = useRef();
  const firstFocus = useRef(true);
  const listboxRef = useRef(null);
  const ignoreFocus = useRef(false);
  const id = useRef(`mui-${Math.round(Math.random() * 1e9)}`);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [filterValue, setFilterValue] = useState(0);
  const [inputValue, setInputValue] = useState(value ? value : "");
  const [open, setOpenState] = useState(false);

  // Prevent input blur when interacting with the combobox
  const handleMouseDown = (event) => {
    if (event.target.getAttribute("id") !== id.current) {
      event.preventDefault();
    }
  };

  const handleOpen = (event) => {
    if (open) {
      return;
    }

    setAnchorEl(event.currentTarget);
    setOpenState(true);
  };

  const handleClose = (event, reason) => {
    if (!open) {
      return;
    }

    setOpenState(false);
  };

  // Focus the input when interacting with the combobox
  const handleClick = () => {
    inputRef.current.focus();

    if (
      firstFocus.current &&
      inputRef.current.selectionEnd - inputRef.current.selectionStart === 0
    ) {
      inputRef.current.select();
    }

    firstFocus.current = false;
  };

  const handleChange = (e, newValue) => {
    setInputValue("");
    setTabValue(newValue);
  };

  const handleChangeFilter = (e, newValue) => {
    setInputValue("");
    setFilterValue(newValue);
  };

  const handleFocus = (event) => {
    handleOpen(event);
  };

  const handleBlur = (event) => {
    // Ignore the event when using the scrollbar with IE11
    if (
      listboxRef.current !== null &&
      listboxRef.current.parentElement.contains(document.activeElement)
    ) {
      inputRef.current.focus();
      return;
    }

    firstFocus.current = true;
    ignoreFocus.current = false;

    handleClose(event, "blur");
  };

  const listFilters = useMemo(() => {
    return options[tabValue].filters ? options[tabValue].filters : null;
  }, [options, tabValue]);

  const listOptions = useMemo(() => {
    const filter = listFilters ? listFilters[filterValue] : null;
    return options[tabValue].options.filter((el) => {
      if (filter) {
        return (
          el.name?.toLowerCase()?.includes(inputValue.toLowerCase()) &&
          el.category === filter.value
        );
      }

      return el.name?.toLowerCase()?.includes(inputValue.toLowerCase());
    });
  }, [options, inputValue, tabValue, filterValue, listFilters]);

  const Row = ({ index, style }) => {
    const item = listOptions[index];

    return (
      <ListItem
        component="div"
        key={index}
        button
        onClick={(e) => {
          onChange(e, item);
          setInputValue(item.name);
          handleClose(e, "selected");
        }}
      >
        <ListItemText>{item.name}</ListItemText>
        {item.onClick && (
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={item.onClick} size="large">
              <BiListCheck />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };

  return (
    <div>
      <div onClick={handleClick} onMouseDown={handleMouseDown}>
        <TextField
          autoComplete="off"
          id={id.current}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          {...rest}
        />
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        onClose={() => setAnchorEl(null)}
        style={{ zIndex: 10000 }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ]}
      >
        <Paper
          variant="outlined"
          ref={listboxRef}
          onMouseDown={(e) => e.preventDefault()}
        >
          <div style={{ minWidth: inputRef?.current?.clientWidth }}>
            <div
              style={{
                background: "var(--color-gray-background)",
                borderBottom: "1px solid var(--color-gray-medium)",
              }}
            >
              <Tabs
                value={(tabValue ? tabValue : 0)}
                onChange={handleChange}
                aria-label="Select Menu Tabs"
                variant="fullWidth"
              >
                {size(options) > 0
                  ? options.map((option, i) => (
                      <Tab key={i} label={option.label} />
                    ))
                  : null}
              </Tabs>
            </div>
            <div style={{ maxHeight: "40vh", overflow: "auto" }}>
              {listFilters && (
                <StyledTabs
                  value={filterValue}
                  onChange={handleChangeFilter}
                  aria-label="Select category Menu Tabs"
                  variant="fullWidth"
                >
                  {listFilters.map((option, i) => (
                    <StyledTab key={i} label={option.label} />
                  ))}
                </StyledTabs>
              )}
              <List>
                {size(listOptions) > 0 ? (
                  listOptions.map((opt, i) => <Row key={i} index={i} />)
                ) : (
                  <ListItem>
                    <ListItemText>No Options</ListItemText>
                  </ListItem>
                )}
              </List>
            </div>
          </div>
        </Paper>
      </Popper>
    </div>
  );
};

TabbedAutocomplete.propTypes = {};

export default TabbedAutocomplete;
