import { getPhoenixClients } from "../../utility";
import { size } from "lodash";
import * as Yup from "yup";

export const filterEntityClients = (aClients, bClients) => {
  const filtered = [];
  if(!aClients || !bClients) return filtered;
  aClients.forEach((aClient) => {
    bClients.forEach((bClient) => {
      if(Number(aClient?.nid ? aClient.nid : aClient.target_id) === Number(bClient?.nid ? bClient.nid : bClient.target_id) ) {
        filtered.push(aClient);
      }
    });
  });
  return filtered;
};

export const getEntityClients = (entity, clients) => {
  let entityClients = [];
  if(entity){
    if(entity?.field_phoenix_clients){
      entityClients = getPhoenixClients(entity.field_phoenix_clients);
    }
    else if(entity?.field_clients){
      entityClients = entity.field_clients;
    }
    else if(entity?.phx_client_nids){
      entityClients = entity?.phx_client_nids.split(',').map((nid) => {
        return clients.find((client) => client.nid === Number(nid));
      });
    }
  }
  return entityClients;
};

export const prepClientSelectorField = (entity, parent, clients, initialValues) => {
  let availableClients = clients;
  if(parent){
    const parentClients = (parent?.field_phoenix_clients ? parent.field_phoenix_clients : [parent.field_phoenix_client]);
    availableClients = filterEntityClients(parentClients, clients);
  }
  const entityClients = getEntityClients(entity, clients);
  availableClients.forEach((client) => {
    let clientNid = false;
    entityClients.forEach((entityClient) => {
      if (entityClient.nid === client.nid) {
        clientNid = true;
        return false;
      }
    });
    if(initialValues){
      initialValues[`phx_client_nid_${client.nid}`] = clientNid;
    }
  });
  return availableClients;
};


export const prepClientSelectorFieldData = (clients, params, data) => {
  clients.forEach((client) => {
    //  Force the entity to be assigned to the user's client if they only have one client
    if(clients.length === 1 || data[`phx_client_nid_${client.nid}`]){
      if(!params.field_phoenix_clients){
        params.field_phoenix_clients = [];
      }
      params.field_phoenix_clients.push({'target_id': client.nid});
    }
  });
};

export const validateClientSelectorField = (clients, values, errors, setMessage) => {
  let checked = false;
  clients.forEach((client) => {
    //  Ignore if only one client, we'll set that later
    if(clients.length === 1 || values[`phx_client_nid_${client.nid}`]){
      checked = true;
    }
  });
  if(!checked){
    errors['phx_client_nid_'] = '';
    setMessage({
      id: "negative",
      msg: 'Please select at least one client',
    });
  }
};

export const getClientSelectorYupRule = (availableClients) => {
  if(availableClients.length === 1){
    return null;
  }
  let schema = {};
  if(availableClients.length > 1){
    availableClients.forEach((client) => {
      schema[`phx_client_nid_${client.nid}`] = Yup.bool()
        .test(
          'oneOfRequired',
          'Select a Client',
          function(item) {
            let isSet = false;
            availableClients.forEach((client) => {
              if(this.parent[`phx_client_nid_${client.nid}`]){
                isSet = true;
              }
            });
            return isSet;
          }
        );
    })
  }
  return schema;
};

export const reportSelectedClients = (entity, parent, userClients) => {
  if(size(userClients) <= 1){
    return '';
  }
  const entityClients = getEntityClients(entity, userClients);
  let availableClients = entityClients;
//  In case the parent has since been unassigned to a client.
  if(parent && parent?.field_phoenix_clients ){
    if(size(parent?.field_phoenix_clients) <= 1){
      return '';
    }
    availableClients = filterEntityClients(parent?.field_phoenix_clients, entityClients);
  }
  availableClients = filterEntityClients(availableClients, userClients);
  const phxClients = availableClients.map((client) => client.title);
  if(size(phxClients)){
    return phxClients.join(", ");
  }
  return '';
};


