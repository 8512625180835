import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, ListItem, ListItemIcon, ListItemText, FormHelperText } from "@mui/material";
import { MdPhotoAlbum } from "react-icons/md";

import { SelectFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";
import { getDivisionDataSelector, getJobDataSelector } from "../jobSlice";
import { getMemberDataSelector } from "features/Member/memberSlice";
import {
  fetchWorkAreas,
  getAreasLoadingSelector,
  workAreasSelectors,
} from "features/WorkAreas/workAreasSlice";
import { toArray } from "lodash";
import { AutocompleteFormField, SwitchFormField, } from "common/Fields";
import { exportBatch } from "../../../actions";

const PhotoReport = ({ selected, children }) => {
  const dispatch = useDispatch();
  const job = useSelector(getJobDataSelector);
  const division = useSelector(getDivisionDataSelector);
  const member = useSelector(getMemberDataSelector);
  const areas = useSelector(workAreasSelectors.selectAll);
  const loadingAreas = useSelector(getAreasLoadingSelector);
  const progress = useSelector((state) => state.jobProgress.data);

  const [open, setOpen] = useState(false);

  const getWorkAreas = useCallback(
    (query) => {
      return dispatch(
        fetchWorkAreas({
          id: division?.nid,
          params: {
            keywords: query,
            "filter[has_photos]": 1,
          },
        })
      );
    },
    [division, dispatch]
  );

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    const area = data.workarea ? data.workarea.nid : 0;
    const make_public = data.make_public ? '/1' : '';
    const params = {};
    if(selected){
      params.files = btoa(selected.map((fid) => fid).join(","));
    }
    await dispatch(
      exportBatch(
        `/rest/job/photos/pdf/${division.nid}/${area}/${data.perspective}${make_public}`,
        params
      )
    );

    onClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const logoOptions = [];
  if (member?.title) {
    logoOptions.push({
      label: member.title,
      value: "member",
    });
  }

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
          disabled: !progress.has_photos,
        })
      ) : (
        <ListItem button onClick={handleOpen} disabled={!progress.has_photos}>
          <ListItemIcon>
            <MdPhotoAlbum />
          </ListItemIcon>
          <ListItemText primary="Photo Report" />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Photo Report"
        initialValues={{
          perspective: null,
          workarea: null,
          make_public: true,
        }}
        disablePadding
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "1.25rem" }}>
            <Grid container spacing={2}>
              <Grid item xxs={12}>
                <SelectFormField
                  fullWidth
                  name="perspective"
                  label="Cover Logo"
                  required
                  disabled={isSubmitting}
                  size="small"
                  variant="filled"
                  options={[
                    ...logoOptions,
                    {
                      label: job.field_phoenix_client
                        ? job.field_phoenix_client.field_pc_proper_name
                        : "Client",
                      value: "phx_client",
                    },
                  ]}
                />
              </Grid>
              {!selected &&
                <Grid item xxs={12}>
                  <AutocompleteFormField
                    name="workarea"
                    variant="filled"
                    label="Work Area"
                    size="small"
                    margin="normal"
                    disabled={isSubmitting}
                    options={toArray(areas).map((area) => ({
                      name: area.title,
                      nid: area.nid,
                    }))}
                    loading={loadingAreas}
                    fetchData={getWorkAreas}
                  />
                </Grid>
              }
              <Grid item xxs={12}>
                <SwitchFormField
                  name="make_public"
                  label="Link to Full Photos"
                />
                <FormHelperText className="mt-0">
                  This makes all your selected photos available to anyone with the photo's link.
                </FormHelperText>
              </Grid>
            </Grid>
          </div>
        )}
      </DialogForm>
    </>
  );
};

PhotoReport.propTypes = {};

export default PhotoReport;
