import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatInvoice } from "./utils";
// import { setAlert } from "features/Alert/alertSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "dailysheet";

export const fetchInvoice = createAsyncThunk(
  `${namespace}/fetchInvoice`,
  async (id, { getState, signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatInvoice(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInvoiceLineItems = createAsyncThunk(
  `${namespace}/fetchInvoiceLineItems`,
  async ({ id, params }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/accounting/line-items/${id}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return {
        results: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreInvoiceLineItems = createAsyncThunk(
  `${namespace}/fetchMoreInvoiceLineItems`,
  async ({ id, params }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/accounting/line-items/${id}${queryparams}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return {
        results: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLineItem = createAsyncThunk(
  `${namespace}/deleteLineItem`,
  async ({ id }, { getState, rejectWithValue, dispatch }) => {
    try {
      await axios.delete(
        `${config.api_url}/entity/paragraph/${id}`,
        tokenConfig(getState)
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const lineItemsAdapter = createEntityAdapter({
  selectId: (result) => result.pid,
});

const invoiceSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    error: null,
    data: {},
    lineItems: lineItemsAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchInvoice.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchInvoice.fulfilled](state, { payload: sheet }) {
      state.loading = false;
      state.error = null;
      state.data = sheet;
    },
    [fetchInvoice.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchInvoiceLineItems.pending](state) {
      state.lineItems.loading = true;
      state.lineItems.error = null;
    },
    [fetchInvoiceLineItems.fulfilled](
      state,
      { payload: { results, pagination } }
    ) {
      state.lineItems.loading = false;
      state.lineItems.pagination = pagination;
      lineItemsAdapter.setAll(state.lineItems, results);
    },
    [fetchInvoiceLineItems.rejected](state, action) {
      state.lineItems.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.lineItems.error = action.payload.message;
        } else {
          state.lineItems.error = action.error.message;
        }
      }
    },
    [fetchMoreInvoiceLineItems.pending](state) {
      state.lineItems.loading = true;
    },
    [fetchMoreInvoiceLineItems.fulfilled](
      state,
      { payload: { results, pagination } }
    ) {
      state.lineItems.loading = false;
      state.lineItems.pagination = pagination;
      lineItemsAdapter.addMany(state.lineItems, results);
    },
    [fetchMoreInvoiceLineItems.rejected](state, action) {
      state.lineItems.loading = false;
      state.lineItems.pagination = {
        count: 0,
        current_page: 0,
        total_pages: 0,
      };
      if (!action.meta.aborted) {
        if (action.payload) {
          state.lineItems.error = action.payload.message;
        } else {
          state.lineItems.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getInvoiceLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.invoice.loading
);

export const getInvoiceDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.invoice.data
);

export const getInvoiceErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.invoice.error
);

export const getInvoiceLineItemsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.invoice.lineItems.loading
);

export const getInvoiceLineItemsPaginatinonSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.invoice.lineItems.pagination
);

export const getInvoiceLineItemsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.invoice.lineItems.error
);

export const lineItemsSelectors = lineItemsAdapter.getSelectors(
  (state) => state.invoice.lineItems
);

export default invoiceSlice.reducer;
