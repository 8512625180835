import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "formbuilder";

export const fetchForms = createAsyncThunk(
  `${namespace}/fetchForms`,
  async (params, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/form-builder/forms${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { forms: response.data.data, pagination: {} };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchForm = createAsyncThunk(
  `${namespace}/fetchForm`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/form-builder/forms/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFormShare = createAsyncThunk(
  `${namespace}/fetchFormShare`,
  async (token, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/form-builder/form-shares/${token}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFormFieldTypes = createAsyncThunk(
  `${namespace}/fetchFormFieldTypes`,
  async (_, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/form-builder/form-field-types`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFormCategories = createAsyncThunk(
  `${namespace}/fetchFormCategories`,
  async (_, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/form-builder/form-categories`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFormAccessTypes = createAsyncThunk(
  `${namespace}/fetchFormAccessTypes`,
  async (_, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/form-builder/form-access-types`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFormSubmissions = createAsyncThunk(
  `${namespace}/fetchFormSubmissions`,
  async (params, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/form-builder/form-submissions/${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return { submissions: response.data.data, pagination: {} };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFormSubmission = createAsyncThunk(
  `${namespace}/fetchFormSubmission`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/form-builder/form-submissions/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postForm = createAsyncThunk(
  `${namespace}/postForm`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/form-builder/forms`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchForm = createAsyncThunk(
  `${namespace}/patchForm`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/form-builder/forms/${id}`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchFormField = createAsyncThunk(
  `${namespace}/patchFormField`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/form-builder/form-fields/${id}`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postFormField = createAsyncThunk(
  `${namespace}/postFormField`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/form-builder/form-fields`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postFormCategory = createAsyncThunk(
  `${namespace}/postFormCategory`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/form-builder/form-categories`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchFormCategory = createAsyncThunk(
  `${namespace}/patchFormCategory`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/form-builder/form-categories/${id}`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postFormFieldOption = createAsyncThunk(
  `${namespace}/postFormFieldOption`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/form-builder/form-field-options`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchFormFieldOption = createAsyncThunk(
  `${namespace}/patchFormFieldOption`,
  async ({ id, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/form-builder/form-field-options/${id}`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFormFieldOption = createAsyncThunk(
  `${namespace}/deleteFormFieldOption`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await axios.delete(
        `${config.api_url}/rest/form-builder/form-field-options/${id}`,
        tokenConfig(getState)
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFormCategory = createAsyncThunk(
  `${namespace}/deleteFormCategory`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await axios.delete(
        `${config.api_url}/rest/form-builder/form-categories/${id}`,
        tokenConfig(getState)
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postFormSubmission = createAsyncThunk(
  `${namespace}/postFormSubmission`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/form-builder/form-submissions`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postFormShareSubmission = createAsyncThunk(
  `${namespace}/postFormShareSubmission`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/form-builder/form-shares/submit`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postFormShare = createAsyncThunk(
  `${namespace}/postFormShare`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/form-builder/form-shares/create`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteForm = createAsyncThunk(
  `${namespace}/deleteForm`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await axios.delete(
        `${config.api_url}/rest/form-builder/forms/${id}`,
        tokenConfig(getState)
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFormField = createAsyncThunk(
  `${namespace}/deleteFormField`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await axios.delete(
        `${config.api_url}/rest/form-builder/form-fields/${id}`,
        tokenConfig(getState)
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSubmission = createAsyncThunk(
  `${namespace}/deleteSubmission`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      await axios.delete(
        `${config.api_url}/rest/form-builder/form-submissions/${id}`,
        tokenConfig(getState)
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const formBuilderAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const formBuilderSlice = createSlice({
  name: namespace,
  initialState: {
    forms: formBuilderAdapter.getInitialState({
      loading: false,
      error: null,
      pagination: {},
    }),
    submissions: formBuilderAdapter.getInitialState({
      loading: false,
      error: null,
      pagination: {},
    }),
    types: formBuilderAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    access_types: formBuilderAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    form: {
      loading: false,
      error: null,
      data: {},
    },
    fields: formBuilderAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    categories: formBuilderAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    submission: {
      loading: false,
      error: null,
      data: {},
    },
    shareable: {
      data: {},
    },
  },
  reducers: {
    clearSubmissions: (state) => {
      formBuilderAdapter.removeAll(state.submissions);
    },
  },
  extraReducers: {
    [fetchForms.pending](state) {
      state.forms.loading = true;
      state.forms.error = null;
    },
    [fetchForms.fulfilled](state, { payload: { forms, pagination } }) {
      state.forms.loading = false;
      state.forms.pagination = pagination;
      formBuilderAdapter.setAll(state.forms, forms);
    },
    [fetchForms.rejected](state, action) {
      if (!action.meta.aborted) {
        state.forms.loading = false;
        if (action.payload) {
          state.forms.error = action.payload.message;
        } else {
          state.forms.error = action.error.message;
        }
      }
    },
    [fetchFormSubmissions.pending](state) {
      state.submissions.loading = true;
      state.submissions.error = null;
    },
    [fetchFormSubmissions.fulfilled](
      state,
      { payload: { submissions, pagination } }
    ) {
      state.submissions.loading = false;
      state.submissions.pagination = pagination;
      formBuilderAdapter.setAll(state.submissions, submissions);
    },
    [fetchFormSubmissions.rejected](state, action) {
      if (!action.meta.aborted) {
        state.submissions.loading = false;
        if (action.payload) {
          state.submissions.error = action.payload.message;
        } else {
          state.submissions.error = action.error.message;
        }
      }
    },
    [fetchFormFieldTypes.pending](state) {
      state.types.loading = true;
      state.types.error = null;
    },
    [fetchFormFieldTypes.fulfilled](state, { payload: types }) {
      state.types.loading = false;
      formBuilderAdapter.setAll(state.types, types);
    },
    [fetchFormFieldTypes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.types.loading = false;
        if (action.payload) {
          state.types.error = action.payload.message;
        } else {
          state.types.error = action.error.message;
        }
      }
    },
    [fetchFormAccessTypes.pending](state) {
      state.access_types.loading = true;
      state.access_types.error = null;
    },
    [fetchFormAccessTypes.fulfilled](state, { payload: types }) {
      state.access_types.loading = false;
      formBuilderAdapter.setAll(state.access_types, types);
    },
    [fetchFormAccessTypes.rejected](state, action) {
      if (!action.meta.aborted) {
        state.access_types.loading = false;
        if (action.payload) {
          state.access_types.error = action.payload.message;
        } else {
          state.access_types.error = action.error.message;
        }
      }
    },
    [fetchFormCategories.pending](state) {
      state.categories.loading = true;
      state.categories.error = null;
      formBuilderAdapter.removeAll(state.categories);
    },
    [fetchFormCategories.fulfilled](state, { payload: categories }) {
      state.categories.loading = false;
      formBuilderAdapter.setAll(state.categories, categories);
    },
    [fetchFormCategories.rejected](state, action) {
      if (!action.meta.aborted) {
        state.categories.loading = false;
        formBuilderAdapter.removeAll(state.categories);
        if (action.payload) {
          state.categories.error = action.payload.message;
        } else {
          state.categories.error = action.error.message;
        }
      }
    },
    [postFormCategory.fulfilled](state, { payload: category }) {
      formBuilderAdapter.addOne(state.categories, category);
    },
    [patchFormCategory.fulfilled](state, { payload: category }) {
      formBuilderAdapter.updateOne(state.categories, {
        id: category.id,
        changes: category,
      });
    },
    [postForm.pending](state) {
      state.form.loading = true;
      state.form.error = null;
    },
    [postForm.fulfilled](state, { payload: form }) {
      state.form.loading = false;
      state.form.data = form;
      formBuilderAdapter.addOne(state.forms, form);
    },
    // [postForm.rejected](state, action) {
    //   if (!action.meta.aborted) {
    //     state.types.loading = false;
    //     if (action.payload) {
    //       state.types.error = action.payload.message;
    //     } else {
    //       state.types.error = action.error.message;
    //     }
    //   }
    // },
    [patchForm.fulfilled](state, { payload: form }) {
      state.form.loading = false;
      state.form.data = form;
      formBuilderAdapter.updateOne(state.forms, { id: form.id, changes: form });
    },
    [patchForm.rejected](state, action) {
      if (!action.meta.aborted) {
        state.form.loading = false;
        if (action.payload) {
          state.form.error = action.payload.errors;
        } else {
          state.form.error = action.error.message;
        }
      }
    },
    [patchFormField.fulfilled](state, { payload: field }) {
      formBuilderAdapter.updateOne(state.fields, {
        id: field.id,
        changes: field,
      });
    },
    [fetchForm.pending](state) {
      state.form.loading = true;
      state.form.error = null;
      state.form.data = {};
      formBuilderAdapter.removeAll(state.fields);
    },
    [fetchForm.fulfilled](state, { payload: form }) {
      state.form.loading = false;
      state.form.data = form;
      formBuilderAdapter.setAll(state.fields, form.fields);
    },
    [fetchForm.rejected](state, action) {
      if (!action.meta.aborted) {
        state.form.loading = false;
        if (action.payload) {
          state.form.error = action.payload.message;
        } else {
          state.form.error = action.error.message;
        }
      }
    },
    [fetchFormShare.pending](state) {
      state.form.loading = true;
      state.form.error = null;
      state.form.data = {};
      formBuilderAdapter.removeAll(state.fields);
    },
    [fetchFormShare.fulfilled](state, { payload: shareable }) {
      state.form.loading = false;
      state.form.data = shareable.form;
      formBuilderAdapter.setAll(state.fields, shareable.form.fields);
      state.shareable.data = {
        token: shareable.token,
        job_division_id: shareable.job_division_id,
        email: shareable.email,
      };
    },
    [fetchFormShare.rejected](state, action) {
      if (!action.meta.aborted) {
        state.form.loading = false;
        if (action.payload) {
          state.form.error = action.payload.message;
        } else {
          state.form.error = action.error.message;
        }
      }
    },
    [fetchFormSubmission.pending](state) {
      state.submission.loading = true;
      state.submission.error = null;
      state.submission.data = {};
    },
    [fetchFormSubmission.fulfilled](state, { payload: submission }) {
      state.submission.loading = false;
      state.submission.data = submission;
    },
    [fetchFormSubmission.rejected](state, action) {
      if (!action.meta.aborted) {
        state.submission.loading = false;
        if (action.payload) {
          state.submission.error = action.payload.message;
        } else {
          state.submission.error = action.error.message;
        }
      }
    },
    [postFormField.pending](state) {
      state.form.error = null;
    },
    [postFormField.fulfilled](state, { payload: field }) {
      formBuilderAdapter.addOne(state.fields, field);
    },
    [postFormField.rejected](state, action) {
      if (!action.meta.aborted) {
        state.form.loading = false;
        if (action.payload) {
          state.form.error = action.payload.errors;
        } else {
          state.form.error = action.error.message;
        }
      }
    },
    // [postFormFieldOption.pending](state) {
    //   state.form.error = null;
    // },
    // [postFormFieldOption.fulfilled](state, { payload: form }) {
    //   state.form.loading = false;
    //   state.form.data = form;
    // },
    // [postFormFieldOption.rejected](state, action) {
    //   if (!action.meta.aborted) {
    //     state.types.loading = false;
    //     if (action.payload) {
    //       state.types.error = action.payload.message;
    //     } else {
    //       state.types.error = action.error.message;
    //     }
    //   }
    // },
    [postFormSubmission.pending](state) {
      state.submissions.error = null;
    },
    [postFormSubmission.fulfilled](state, { payload: form }) {
      formBuilderAdapter.addOne(state.submissions, form);
    },
    // [postFormSubmission.rejected](state, action) {
    //   if (!action.meta.aborted) {
    //     state.types.loading = false;
    //     if (action.payload) {
    //       state.types.error = action.payload.message;
    //     } else {
    //       state.types.error = action.error.message;
    //     }
    //   }
    // },
    [deleteFormField.fulfilled](state, { payload: id }) {
      formBuilderAdapter.removeOne(state.fields, id);
    },
    [deleteForm.fulfilled](state, { payload: id }) {
      formBuilderAdapter.removeOne(state.forms, id);
    },
    [deleteFormCategory.fulfilled](state, { payload: id }) {
      formBuilderAdapter.removeOne(state.categories, id);
    },
    // [deleteForm.rejected](state, action) {
    //   if (!action.meta.aborted) {
    //     state.types.loading = false;
    //     if (action.payload) {
    //       state.types.error = action.payload.message;
    //     } else {
    //       state.types.error = action.error.message;
    //     }
    //   }
    // },
    [deleteSubmission.fulfilled](state, { payload: id }) {
      formBuilderAdapter.removeOne(state.submissions, id);
    },
    // [deleteSubmission.rejected](state, action) {
    //   if (!action.meta.aborted) {
    //     state.types.loading = false;
    //     if (action.payload) {
    //       state.types.error = action.payload.message;
    //     } else {
    //       state.types.error = action.error.message;
    //     }
    //   }
    // },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getFormsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.forms.loading
);

export const getFormsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.forms.pagination
);

export const getFormLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.form.loading
);

export const getFormDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.form.data
);

export const getFormErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.form.error
);

export const getFormShareableDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.shareable.data
);

export const getSubmissionLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.submission.loading
);

export const getSubmissionDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.submission.data
);

export const getSubmissionErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.submission.error
);

export const getSubmissionsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.formbuilder.submissions.loading
);

export const formsSelectors = formBuilderAdapter.getSelectors(
  (state) => state.formbuilder.forms
);

export const submissionsSelectors = formBuilderAdapter.getSelectors(
  (state) => state.formbuilder.submissions
);

export const formFieldsSelectors = formBuilderAdapter.getSelectors(
  (state) => state.formbuilder.fields
);

export const formFieldTypesSelectors = formBuilderAdapter.getSelectors(
  (state) => state.formbuilder.types
);

export const formAccessTypesSelectors = formBuilderAdapter.getSelectors(
  (state) => state.formbuilder.access_types
);

export const formCategoriesSelectors = formBuilderAdapter.getSelectors(
  (state) => state.formbuilder.categories
);

export const { clearSubmissions } = formBuilderSlice.actions;

export default formBuilderSlice.reducer;
