import {
  createSlice,
  // createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
// import { tokenConfig } from "actions/authActions";

// import { tokenConfig } from "../../actions/authActions";
import config from "config";

const namespace = "time_clock";

export const fetchTimeClock = createAsyncThunk(
  `${namespace}/fetchTimeClock`,
  async ({hash}, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/ds-time-clock/${hash}`,
        {
          // ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


export const postTimeClock = createAsyncThunk(
  `${namespace}/postTimeClock`,
  async ({hash, params}, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/job/ds-time-clock/${hash}`,
        params,
        // tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const timeClockSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    error: null,
    data: {},
    user: {},
  },
  reducers: {
    setUser: (state, {payload}) => {
      state.user = payload;
    },
  },
  extraReducers: {
    [fetchTimeClock.pending](state) {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
    [fetchTimeClock.fulfilled](state, { payload: timeClock }) {
      state.loading = false;
      state.data = timeClock;
    },
    [fetchTimeClock.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postTimeClock.pending](state) {
      state.error = null;
    },
    [postTimeClock.fulfilled](state, { payload: timeClock }) {
      state.data = timeClock;
    },
    [postTimeClock.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;

export const getTimeClockSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.data
);

export const getTimeClockLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.loading
);

export const getTimeClockErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.error
);

export const getTimeClockUserSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.time_clock.user
);

export const { setUser } = timeClockSlice.actions;

export default timeClockSlice.reducer;
