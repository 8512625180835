import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { tokenConfig } from "../../actions/authActions";
//   import querystring from "querystring";

import config from "../../config";

//   const getQueryParams = (params) => {
//     return `?${
//       typeof params === "string"
//         ? params.substring(1)
//         : querystring.stringify(params)
//     }`;
//   };

const namespace = "weather";

export const fetchWeatherAlerts = createAsyncThunk(
  `${namespace}/fetchWeatherAlerts`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/weather/alerts`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRefreshWeatherAlerts = createAsyncThunk(
  `${namespace}/patchRefreshWeatherAlerts`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.patch(
        `${config.api_url}/rest/weather/refresh`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const alertsAdapter = createEntityAdapter({
  selectId: (alert) => alert.weather_alert_timestamp,
});

const weatherSlice = createSlice({
  name: namespace,
  initialState: {
    alertsByZip: alertsAdapter.getInitialState({
      loading: false,
      error: null,
    }),
  },
  reducers: {},
  extraReducers: {
    [fetchWeatherAlerts.pending](state) {
      state.alertsByZip.loading = true;
      state.alertsByZip.error = null;
    },
    [fetchWeatherAlerts.fulfilled](state, { payload: alerts }) {
      state.alertsByZip.loading = false;
      state.alertsByZip.error = null;
      alertsAdapter.setAll(state.alertsByZip, alerts);
    },
    [fetchWeatherAlerts.rejected](state, action) {
      if (!action.meta.aborted) {
        state.alertsByZip.loading = false;
        if (action.payload) {
          state.alertsByZip.error = action.payload.message;
        } else {
          state.alertsByZip.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getAlertsByZipLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.weather.alertsByZip.loading
);

export const getAlertsByZipErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.weather.alertsByZip.error
);

export const alertsByZipSelectors = alertsAdapter.getSelectors(
  (state) => state.weather.alertsByZip
);

export default weatherSlice.reducer;
