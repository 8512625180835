import { formatField } from "utility";

export const formatClientFields = (d) => {
  const formatted = {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_pc_address: formatField(d, "field_pc_address"),
  //  ** Remember, only admins can see field_pc_client_settings
    field_pc_client_settings: formatField(d, "field_pc_client_settings"),
    // field_pc_default_member: formatField(d, "field_pc_default_member"),
    // field_pc_domain: formatField(d, "field_pc_domain"),
    field_pc_logo: formatField(d, "field_pc_logo"),
    field_pc_phone: formatField(d, "field_pc_phone"),
    field_pc_proper_name: formatField(d, "field_pc_proper_name"),
    _processed: d?._processed,
  };
  if(formatted.field_pc_client_settings?.member_ops_settings){
    const settings = JSON.parse(formatted.field_pc_client_settings.member_ops_settings);
    formatted.field_pc_client_settings.member_ops_settings = {items: {...settings}};
  }
  return formatted
};
