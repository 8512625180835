import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import vendorArticlesAPI from "./vendorArticlesAPI";
import { setAllPosts } from "features/Posts/postsSlice";

const namespace = "articles";

export const fetchArticles = createAsyncThunk(
  "articles/fetchArticles",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.getArticles({
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchArticlePosts = createAsyncThunk(
  "articles/fetchArticlePosts",
  async ({ id, params }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.getPosts({
        id,
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      dispatch(setAllPosts(response.data));

      return response.data;
    } catch (err) {
      dispatch(setAllPosts({ data: [] }));
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchArticlesAdmin = createAsyncThunk(
  "articles/fetchArticlesAdmin",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.getArticlesAdmin({
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchArticle = createAsyncThunk(
  "articles/fetchArticle",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.getArticle({
        id,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postArticle = createAsyncThunk(
  "articles/postArticle",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.addArticle({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddReaction = createAsyncThunk(
  "articles/patchAddReaction",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.addReaction({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveReaction = createAsyncThunk(
  "articles/patchRemoveReaction",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.removeReaction({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddAdmin = createAsyncThunk(
  "articles/patchAddAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.addAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveAdmin = createAsyncThunk(
  "articles/patchRemoveAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.removeAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

//     export const patchUploadAttachment = createAsyncThunk(
//       "vendors/patchUploadAttachment",
//       async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
//         try {
//           const token =
//             getState().auth.user?.data?._processed?.phx_offsite_api_token;
//           const response = await vendorsAPI.uploadAttachment({
//             id,
//             params,
//             config: config
//               ? config
//               : {
//                   headers: {
//                     Authorization: `Bearer ${token}`,
//                   },
//                 },
//           });

//           return response.data;
//         } catch (err) {
//           const error = err; // cast the error for access
//           if (!error.response) {
//             throw err;
//           }

//           // We got validation errors, let's return those so we can reference in our component and set form errors
//           return rejectWithValue(error.response.data);
//         }
//       }
//     );

export const patchArticle = createAsyncThunk(
  "articles/patchArticle",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await vendorArticlesAPI.updateArticle({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

//     export const patchInviteUser = createAsyncThunk(
//       "vendors/patchInviteUser",
//       async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
//         try {
//           const token =
//             getState().auth.user?.data?._processed?.phx_offsite_api_token;
//           const response = await vendorsAPI.inviteUser({
//             id,
//             params,
//             config: config
//               ? config
//               : {
//                   headers: {
//                     Authorization: `Bearer ${token}`,
//                   },
//                 },
//           });

//           return response.data;
//         } catch (err) {
//           const error = err; // cast the error for access
//           if (!error.response) {
//             throw err;
//           }

//           // We got validation errors, let's return those so we can reference in our component and set form errors
//           return rejectWithValue(error.response.data);
//         }
//       }
//     );

export const deleteArticle = createAsyncThunk(
  "articles/deleteArticle",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      await vendorArticlesAPI.deleteArticle({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return id;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const vendorsAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const vendorArticlesSlice = createSlice({
  name: namespace,
  initialState: vendorsAdapter.getInitialState({
    loading: false,
    error: null,
    article: {
      data: {},
      loading: false,
      error: null,
    },
    admin: {
      loading: false,
      error: null,
      articles: vendorsAdapter.getInitialState({
        data: {},
        loading: false,
        error: null,
      })
    },
  }),
  reducers: {},
  extraReducers: {
    [fetchArticles.pending](state, action) {
      state.loading = true;
      state.error = null;
      vendorsAdapter.removeAll(state);
    },
    [fetchArticles.fulfilled](state, { payload: articles }) {
      state.loading = false;
      state.error = null;
      vendorsAdapter.setAll(state, articles);
    },
    [fetchArticles.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchArticle.pending](state, action) {
      state.article.loading = true;
      state.article.error = null;
    },
    [fetchArticle.fulfilled](state, { payload: article }) {
      state.article.loading = false;
      state.article.error = null;
      state.article.data = article;
    },
    [fetchArticle.rejected](state, action) {
      state.article.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.article.error = action.payload.message;
        } else {
          state.article.error = action.error.message;
        }
      }
    },
    [fetchArticlesAdmin.pending](state, action) {
      state.admin.loading = true;
      state.admin.error = null;
      vendorsAdapter.removeAll(state.admin.articles);
    },
    [fetchArticlesAdmin.fulfilled](state, { payload: articles }) {
      state.admin.loading = false;
      state.admin.error = null;
      vendorsAdapter.setAll(state.admin.articles, articles);
    },
    [fetchArticlesAdmin.rejected](state, action) {
      state.admin.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.admin.error = action.payload.message;
        } else {
          state.admin.error = action.error.message;
        }
      }
    },
    [deleteArticle.fulfilled](state, { payload: id }) {
      vendorsAdapter.removeOne(state, id);
    },
    [postArticle.fulfilled](state, { payload: article }) {
      vendorsAdapter.addOne(state, article);
      vendorsAdapter.addOne(state.admin.articles, article);
    },
    [patchAddReaction.fulfilled](state, { payload: article }) {
      state.article.data.reactions = article.reactions;
      state.article.data.reactions_summary = article.reactions_summary;
      state.article.data.my_reaction = article.my_reaction;
      vendorsAdapter.updateOne(state, { id: article.id, changes: article });
    },
    [patchRemoveReaction.fulfilled](state, { payload: article }) {
      state.article.data.reactions = article.reactions;
      state.article.data.reactions_summary = article.reactions_summary;
      state.article.data.my_reaction = article.my_reaction;
      vendorsAdapter.updateOne(state, { id: article.id, changes: article });
    },
    [patchAddAdmin.fulfilled](state, { payload: article }) {
      vendorsAdapter.updateOne(state, {
        id: article.id,
        changes: article,
      });
    },
    [patchAddAdmin.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchRemoveAdmin.fulfilled](state, { payload: article }) {
      vendorsAdapter.updateOne(state, {
        id: article.id,
        changes: article,
      });
    },
    [patchRemoveAdmin.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchArticle.fulfilled](state, { payload: article }) {
      vendorsAdapter.updateOne(state, {
        id: article.id,
        changes: article,
      });
      vendorsAdapter.updateOne(state.admin.articles, {
        id: article.id,
        changes: article,
      });
      state.error = null;
    },
    [patchArticle.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const vendorArticlesSelectors = vendorsAdapter.getSelectors(
  (state) => state.articles
);
export const vendorArticlesAdminSelectors = vendorsAdapter.getSelectors(
  (state) => state.articles.admin.articles
);

export default vendorArticlesSlice.reducer;
