import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";

const namespace = "alert";

const alertSlice = createSlice({
  name: namespace,
  initialState: {
    show: false,
    kind: null,
    msg: null,
  },
  reducers: {
    setAlert: (state, action) => {
      state.show = true;
      state.kind = action.payload.kind;
      state.msg = action.payload.msg;
    },
    clearAlert: (state) => {
      state.show = false;
      state.kind = null;
      state.msg = null;
    },
    hideAlert: (state) => {
      state.show = false;
    },
  },
  extraReducers: {},
});

// Custom selectors
const selectSelf = (state) => state;
export const getAlertSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.alert
);

export const { setAlert, clearAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
