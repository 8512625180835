import styled from "styled-components";
import { Form as FormikForm } from "formik";

export const FormActions = styled.div`
  width: 100%;
  margin-top: 40px;
`;

export const Actions = styled.div`
  padding-top: 2rem;

  & > .MuiButtonBase-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Form = styled(FormikForm)`
  padding: 1.25rem;
  padding-top: ${({ theme }) => (theme.disablePaddingTop ? "0" : "1.25rem")};
`;
