import { useEffect, useState } from "react";

const useWindowSize = (waitForWindowChange) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    previousWidth: undefined,
    previousHeight: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        previousWidth: windowSize.width,
        previousHeight: windowSize.height,
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    let listener = handleResize;
    if(waitForWindowChange){
      listener = debounce(handleResize, 500)
    }

    window.addEventListener("resize", listener);
    listener();
    return () => window.removeEventListener("resize", listener);
  }, [waitForWindowChange]); // eslint-disable-line react-hooks/exhaustive-deps
  return windowSize;
};

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

export default useWindowSize;
