import React from "react";
// import { Typography } from "@mui/material";
// import TruncateReactMarkdown from 'common/TruncateReactMarkdown';
// import Avatar from "common/Avatar";
import moment from "moment";
// import ReactDomServer from 'react-dom/server';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { formatTimeRange } from 'features/Events/utils';

const defaultCover = `${process.env.PUBLIC_URL}/images/default-event.png`;

export const eventNotification = ( {data, isSubNotication, currentUser} ) => {

  return {
    'path': `/hub/events/${data.id}`,
    'icon': (

      <div className="bg-gray-light h-[48px] w-[48px] rounded-lg">
        <img
          className="w-full h-full rounded-lg object-cover object-center border-solid border border-gray-dark"
          src={data.icon ? data.icon : defaultCover}
          alt="cover"
        />
      </div>
    ),
    'body': (
      <div className="flex flex-col justify-between h-full">
        <div className="flex justify-end">
          <div className="h-4 flex-1 min-w-0 overflow-hidden text-ellipsis whitespace-nowrap">{data.title}</div>
          <div className="whitespace-nowrap text-sm">{moment(data.created_at).format('M/D/YY h:mma')}</div>
        </div>
        <div className="flex-[1_0_auto] px-1 italic">
          <div className="multiline-ellipsis-2">
          {/* <TruncateReactMarkdown
            lines={(others.length <= 1 ? 3 : 2)}
          > */}
            <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]} >{data.body}</ReactMarkdown>
          {/* </TruncateReactMarkdown> */}
          </div>
          <div className="text-sm">{formatTimeRange(data.times, "l")}</div>
        </div>
      </div>
    )
  };
};

