import React from "react";
import ContentLoader from "react-content-loader";
import constants from "../constants";

const TabSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width={500}
      height={48}
      viewBox="0 0 500 48"
      foregroundColor={constants.colorGray}
      backgroundColor={constants.colorGrayLight}
    >
      <rect x="0" y="9" rx="3" ry="3" width="150" height="30" />
      <rect x="160" y="9" rx="3" ry="3" width="150" height="30" />
      <rect x="320" y="9" rx="3" ry="3" width="150" height="30" />
    </ContentLoader>
  );
};

TabSkeleton.propTypes = {};

export default TabSkeleton;
