import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "locations";

export const fetchLocations = createAsyncThunk(
  `${namespace}/fetchLocations`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/locations/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        locations: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLocationsByZip = createAsyncThunk(
  `${namespace}/fetchLocationsByZip`,
  async (params, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${
          config.api_url
        }/rest/customer_locations/by-zip?zip_codes=${params.join(",")}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        locations: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const locationsAdapter = createEntityAdapter({
  selectId: (location) => location.customer_location_nid,
});

const locationsSlice = createSlice({
  name: namespace,
  initialState: locationsAdapter.getInitialState({
    loading: false,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    error: null,
  }),
  reducers: {
    resetLocations: locationsAdapter.removeAll,
  },
  extraReducers: {
    [fetchLocations.pending](state) {
      state.loading = true;
    },
    [fetchLocations.fulfilled](state, { payload: { locations, pagination } }) {
      state.loading = false;
      state.pagination = pagination;
      locationsAdapter.setAll(state, locations);
    },
    [fetchLocations.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchLocationsByZip.pending](state) {
      state.loading = true;
    },
    [fetchLocationsByZip.fulfilled](
      state,
      { payload: { locations, pagination } }
    ) {
      state.loading = false;
      state.pagination = pagination;
      locationsAdapter.setAll(state, locations);
    },
    [fetchLocationsByZip.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const locationsSelectors = locationsAdapter.getSelectors(
  (state) => state.locations
);

// Custom selectors
const selectSelf = (state) => state;
export const getLocationsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.locations.loading
);

export const getLocationsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.locations.error
);

export const getLocationsPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.locations.pagination
);

export const { resetLocations } = locationsSlice.actions;

export default locationsSlice.reducer;
