import React, { useState } from "react";
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Fade,
  useMediaQuery,
} from "@mui/material";
import { GiArchiveResearch } from "react-icons/gi";
import styled from "styled-components";

import Empty from "@phx/common/Empty";
import Pagination from "./components/Pagination";
import { DefaultLoader } from "common/Loader";
import TableColumnHider from "./components/TableColumnHider";

const Actions = styled.div`
  border-radius: 3;
  background: white;
  padding: 0.25rem;
  border: 1px solid var(--color-gray-medium);
  box-shadow: rgb(17 51 83 / 4%) 0px 4px 12px 0px;
`;

const EnhancedTableHead = ({
  order,
  sort,
  onRequestSort,
  headCells,
  hideColumns,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          <TableColumnHider key={headCell.id} column={headCell}>
            <TableCell
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={order === headCell.id ? sort : false}
              style={{
                minWidth: headCell.minWidth ? headCell.minWidth : "auto",
                width: headCell.width ? headCell.width : "auto",
              }}
            >
              {!headCell.notSortable ? (
                <TableSortLabel
                  active={order === headCell.id}
                  direction={order === headCell.id ? sort : "desc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {order === headCell.id ? (
                    <span hidden>
                      {sort === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          </TableColumnHider>
        )}
      </TableRow>
    </TableHead>
  );
};

const SmartRow = ({ index, onRowClick, row, columns }) => {
  const [hovering, setHovering] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={index}
      style={{
        position: "relative",
        cursor: onRowClick ? "pointer" : "default",
      }}
      onClick={onRowClick ? (e) => onRowClick(row, e) : null}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {columns.map((column) => {
        const value = row[column.id];
        return column.id === "actions" ? (
          <TableColumnHider key={column.id} column={column}>
            <TableCell
              align="center"
              padding={column.disablePadding ? "none" : "normal"}
              style={{
                position: "sticky",
                right: 0,
                minWidth: column.minWidth ? column.minWidth : "auto",
                width: column.width ? column.width : "auto",
              }}
            >
              <Fade in={hovering || isMobile}>
                <Actions>
                  {column.format && typeof value === "number"
                    ? column.format(value)
                    : React.cloneElement(value, {
                      updateHover: setHovering
                    })}
                </Actions>
              </Fade>
            </TableCell>
          </TableColumnHider>
        ) : (
          <TableColumnHider key={column.id} column={column}>
            <TableCell
              align={column.align}
              padding={column.disablePadding ? "none" : "normal"}
              style={{
                minWidth: column.minWidth ? column.minWidth : "auto",
                width: column.width ? column.width : "auto",
              }}
            >
              {column.format && typeof value === "number"
                ? column.format(value)
                : value}
            </TableCell>
          </TableColumnHider>
        )
      })}
    </TableRow>
  );
};

const MuiTable = ({
  rows,
  columns,
  loading,
  size,
  onRowClick,
  stickyHeader,
  flex,
  pagination,
  handleChangePage,
  handleChangeRowsPerPage,
  sort,
  setSort,
  order,
  setOrder,
  disableHeader,
}) => {
  const handleRequestSort = (event, property) => {
    const isAsc = order === property && sort === "asc";
    setSort(isAsc ? "desc" : "asc");
    setOrder(property);
  };

  const _handleChangePage = (event, newPage) => {
    if(handleChangePage){
      handleChangePage(event, newPage);
    }
  }

  return (
    <>
      {pagination && (
        <Pagination
          pagination={pagination}
          onPageChange={_handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <TableContainer
        style={{
          flex: flex ? 1 : "0 1 auto",
          background: "white",
          // borderTop: pagination ? "none" : "1px solid var(--color-gray-medium)",
          borderTop: "none",
        }}
      >
        <MaterialTable
          stickyHeader={stickyHeader}
          aria-label="Table"
          size={size}
        >
          {!disableHeader && (
            <EnhancedTableHead
              order={order}
              sort={sort}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={columns}
            />
          )}
          <TableBody>
            {loading ? (
              <TableRow style={{ background: "white" }}>
                <TableCell
                  align="center"
                  colSpan={columns.length}
                  style={{
                    borderBottom: "none",
                  }}
                >
                  <DefaultLoader />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((row, index) => {
                return (
                  <SmartRow
                    key={index}
                    index={index}
                    onRowClick={onRowClick}
                    row={row}
                    columns={columns}
                  />
                );
              })
            ) : (
              <TableRow style={{ background: "white" }}>
                <TableCell
                  align="center"
                  colSpan={columns.length}
                  style={{
                    borderBottom: "none",
                  }}
                >
                  <Empty message="No results were found">
                    <GiArchiveResearch />
                  </Empty>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MaterialTable>
      </TableContainer>
    </>
  );
};

MuiTable.propTypes = {};

export default MuiTable;
