import { formatField, getTaxonomyFields, getUserFields } from "../../utility";

export const formatTask = (task) => {
  const end =
    task &&
    task.field_task_date &&
    task.field_task_date[0] &&
    task.field_task_date[0].end_value
      ? task.field_task_date[0].end_value
      : null;
  const rrule =
    task &&
    task.field_task_date &&
    task.field_task_date[0] &&
    task.field_task_date[0].rrule
      ? task.field_task_date[0].rrule
      : null;
  const infinite =
    task &&
    task.field_task_date &&
    task.field_task_date[0] &&
    task.field_task_date[0].infinite
      ? task.field_task_date[0].infinite
      : false;
  const job =
    task && task.field_task_job_number && task.field_task_job_number[0]
      ? task.field_task_job_number[0]
      : null;

  return {
    nid: formatField(task, "nid"),
    title: formatField(task, "title"),
    start: formatField(task, "field_task_date"),
    infinite,
    end,
    rrule,
    field_task_all_day: formatField(task, "field_task_all_day"),
    field_task_completed: formatField(task, "field_task_completed"),
    body: formatField(task, "body"),
    field_task_priority: getTaxonomyFields(task, "field_task_priority"),
    field_task_users: getUserFields(task, "field_task_users"),
    job: job
      ? { name: formatField(job, "title"), nid: formatField(job, "nid") }
      : null,
    field_task_reminder: formatField(task, "field_task_reminder"),
    field_task_color_code: formatField(task, "field_task_color_code"),
    field_task_type: getTaxonomyFields(task, "field_task_type"),
    field_mf_monitoring_checklist:
      task && task.field_mf_monitoring_checklist
        ? task.field_mf_monitoring_checklist
        : [],
    _processed: task._processed,
  };
};
