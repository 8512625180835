import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import Button from "common/Button";
import { AutocompleteFormField } from "common/Fields";

import {
  fetchMembers,
  getMembersLoadingSelector,
  membersSelectors,
} from "features/Members/membersSlice";
import ClosestMembers from "../components/ClosestMembers";

const Location = ({
  cid,
  handlePrev,
  values,
  isSubmitting,
  value,
  handleSubmit,
  setFieldValue,
  clientId,
}) => {
  const dispatch = useDispatch();
  const members = useSelector(membersSelectors.selectAll);
  const loading = useSelector(getMembersLoadingSelector);

  const getMembers = useCallback(
    (query) => {
      return dispatch(
        fetchMembers({
          keywords: query,
        })
      );
    },
    [dispatch]
  );

  if (value !== 2) return null;
  return (
    <div>
      <Typography variant="h6">Providers</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Preferred Providers who will be used when assigning jobs.
      </Typography>
      <ClosestMembers
        cid={cid}
        data={values}
        clientId={clientId}
        onClick={(value) => {
          setFieldValue("field_preferred_member", value);
        }}
      />
      <Grid container spacing={2}>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_preferred_member"
            label="Primary Preferred Provider"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            options={members.map((member) => ({
              name: member.member_name,
              nid: member.member_nid,
            }))}
            loading={loading}
            fetchData={getMembers}
          />
        </Grid>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_preferred_member_2"
            label="Secondary Preferred Provider"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            options={members.map((member) => ({
              name: member.member_name,
              nid: member.member_nid,
            }))}
            loading={loading}
            fetchData={getMembers}
          />
        </Grid>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_preferred_member_3"
            label="Third Preferred Provider"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            options={members.map((member) => ({
              name: member.member_name,
              nid: member.member_nid,
            }))}
            loading={loading}
            fetchData={getMembers}
          />
        </Grid>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_preferred_member_4"
            label="Fourth Preferred Provider"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            options={members.map((member) => ({
              name: member.member_name,
              nid: member.member_nid,
            }))}
            loading={loading}
            fetchData={getMembers}
          />
        </Grid>
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
              onClick={handlePrev}
              style={{ marginRight: "1rem" }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              disableElevation
              size="small"
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Location.propTypes = {};

export default Location;
