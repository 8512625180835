import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";
import moment from "moment";

import { formatField } from "../../utility";
import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatInventoryItem, formatMaintenanceRecord } from "./utils";

import { setAlert } from "features/Alert/alertSlice";
import { fetchJobProgress } from "../JobProgress/jobProgressSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "inventory";

export const fetchInventoryByMember = createAsyncThunk(
  `${namespace}/fetchInventoryByMember`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/inventory/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        items: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInventoryByMemberRefresh = createAsyncThunk(
  `${namespace}/fetchInventoryByMemberRefresh`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/member/inventory/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        items: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInventoryByDivision = createAsyncThunk(
  `${namespace}/fetchInventoryByDivision`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/inventory-usage/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        items: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInventoryItemUsage = createAsyncThunk(
  `${namespace}/fetchInventoryItemUsage`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/inventory-item/usage/${id}${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        items: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInventoryItemMaintenance = createAsyncThunk(
  `${namespace}/fetchInventoryItemMaintenance`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/entity/node/field_ii_mntnce_history/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data?.field_ii_mntnce_history
        ? response.data?.field_ii_mntnce_history.map((item) =>
            formatMaintenanceRecord(item)
          )
        : [];
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchItem = createAsyncThunk(
  `${namespace}/fetchItem`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatInventoryItem(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignItemToEntity = createAsyncThunk(
  `${namespace}/assignItemToEntity`,
  async (
    { id, parentId, division },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const user = getState().auth.user.data;

      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/inventory_item_assignment`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_ii_assignments",
            parent_id: parentId,
          },
          field_iia_inventory_item: [{ target_id: id }],
          field_iia_assignment_user: [
            {
              target_id: user.uid,
            },
          ],
        },
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      return { response: formatInventoryItem(response.data), id };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInventoryItemAssignment = createAsyncThunk(
  `${namespace}/patchInventoryItemAssignment`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/inventory_item_assignment`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postInventoryMaintenanceSchedule = createAsyncThunk(
  `${namespace}/postInventoryMaintenanceSchedule`,
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/ii_maintenance_schedule`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_ii_mntnce_schdls",
            parent_id: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postInventoryMaintenanceRecord = createAsyncThunk(
  `${namespace}/postInventoryMaintenanceRecord`,
  async ({ id, schedule, params }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/ii_maintenance_record`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_ii_mntnce_history",
            parent_id: id,
            ii_maintenance_schedule_pid: schedule,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const returnInventoryItemAssignment = createAsyncThunk(
  `${namespace}/returnInventoryItemAssignment`,
  async (
    { id, date, user, nid, division },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/inventory_item_assignment`,
            },
          },
          field_iia_return_time: [{ value: date }],
          field_iia_return_user: [{ target_id: user.uid }],
          field_iia_inventory_item: [{ target_id: nid }],
        },
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Removed item successfully.`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Job Division: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postInventoryItem = createAsyncThunk(
  `${namespace}/postInventoryItem`,
  async (
    { params, member },
    { dispatch, getState, signal, rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/inventory_item`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchInventoryByMemberRefresh({ id: member, params: {} }));
      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully added inventory item`,
        })
      );

      return formatInventoryItem(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Inventory Item: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchInventoryItem = createAsyncThunk(
  `${namespace}/patchInventoryItem`,
  async ({ id, params }, { dispatch, getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/inventory_item`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated inventory item`,
        })
      );

      return formatInventoryItem(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating Inventory Item: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const divisionItemsAdapter = createEntityAdapter({
  selectId: (item) => item.pid,
});

const memberItemsAdapter = createEntityAdapter({
  selectId: (item) => item.nid,
});

const itemsUsageAdapter = createEntityAdapter({
  selectId: (item) => item.nid,
});

const itemsMaintenanceAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const itemsMaintenanceScheduleAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const inventorySlice = createSlice({
  name: namespace,
  initialState: {
    loading: true,
    error: null,
    data: {},
    usage: itemsUsageAdapter.getInitialState({
      error: null,
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    maintenanceHistory: itemsMaintenanceAdapter.getInitialState({
      error: null,
      loading: false,
    }),
    maintenanceSchedules: itemsMaintenanceScheduleAdapter.getInitialState({
      error: null,
      loading: false,
    }),
    divisionItems: divisionItemsAdapter.getInitialState({
      error: null,
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    memberItems: memberItemsAdapter.getInitialState({
      error: null,
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
  },
  reducers: {
    clearItem: (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
  },
  extraReducers: {
    [fetchInventoryByDivision.pending](state) {
      state.divisionItems.loading = true;
    },
    [fetchInventoryByDivision.fulfilled](
      state,
      { payload: { items, pagination } }
    ) {
      state.divisionItems.loading = false;
      state.divisionItems.pagination = pagination;
      divisionItemsAdapter.setAll(state.divisionItems, items);
    },
    [fetchInventoryByDivision.rejected](state, action) {
      if (!action.meta.aborted) {
        state.divisionItems.loading = false;
        if (action.payload) {
          state.divisionItems.error = action.payload.message;
        } else {
          state.divisionItems.error = action.error.message;
        }
      }
    },
    [fetchInventoryItemUsage.pending](state) {
      state.usage.loading = true;
    },
    [fetchInventoryItemUsage.fulfilled](
      state,
      { payload: { items, pagination } }
    ) {
      state.usage.loading = false;
      state.usage.pagination = pagination;
      itemsUsageAdapter.setAll(state.usage, items);
    },
    [fetchInventoryItemUsage.rejected](state, action) {
      if (!action.meta.aborted) {
        state.usage.loading = false;
        if (action.payload) {
          state.usage.error = action.payload.message;
        } else {
          state.usage.error = action.error.message;
        }
      }
    },
    [fetchInventoryItemMaintenance.pending](state) {
      state.maintenanceHistory.loading = true;
    },
    [fetchInventoryItemMaintenance.fulfilled](state, { payload: items }) {
      state.maintenanceHistory.loading = false;
      itemsMaintenanceAdapter.setAll(state.maintenanceHistory, items);
    },
    [fetchInventoryItemMaintenance.rejected](state, action) {
      if (!action.meta.aborted) {
        state.maintenanceHistory.loading = false;
        if (action.payload) {
          state.maintenanceHistory.error = action.payload.message;
        } else {
          state.maintenanceHistory.error = action.error.message;
        }
      }
    },
    [fetchInventoryByMember.pending](state) {
      state.memberItems.loading = true;
    },
    [fetchInventoryByMember.fulfilled](
      state,
      { payload: { items, pagination } }
    ) {
      state.memberItems.loading = false;
      state.memberItems.pagination = pagination;
      memberItemsAdapter.setAll(state.memberItems, items);
    },
    [fetchInventoryByMember.rejected](state, action) {
      if (!action.meta.aborted) {
        state.memberItems.loading = false;
        if (action.payload) {
          state.memberItems.error = action.payload.message;
        } else {
          state.memberItems.error = action.error.message;
        }
      }
    },
    [fetchInventoryByMemberRefresh.fulfilled](
      state,
      { payload: { items, pagination } }
    ) {
      state.memberItems.pagination = pagination;
      memberItemsAdapter.setAll(state.memberItems, items);
    },
    [fetchInventoryByMemberRefresh.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.memberItems.error = action.payload.message;
        } else {
          state.memberItems.error = action.error.message;
        }
      }
    },
    [fetchItem.pending](state) {
      state.loading = true;
    },
    [fetchItem.fulfilled](state, { payload: item }) {
      state.loading = false;
      state.data = item;
      itemsMaintenanceScheduleAdapter.setAll(
        state.maintenanceSchedules,
        item.field_ii_mntnce_schdls
      );
    },
    [fetchItem.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [assignItemToEntity.pending](state) {},
    [assignItemToEntity.fulfilled](state, { payload: { response, id } }) {
      memberItemsAdapter.removeOne(state.memberItems, id);
    },
    [assignItemToEntity.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchInventoryItemAssignment.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });
    },
    [patchInventoryItemAssignment.fulfilled](state, { payload: item }) {},
    [patchInventoryItemAssignment.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchInventoryItem.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      state.data = {
        ...state.data,
        ...tempParams,
      };
    },
    [patchInventoryItem.fulfilled](state, { payload: item }) {
      state.data = item;
    },
    [patchInventoryItem.rejected](state, action) {
      const { item } = action.meta.arg;

      state.data = item;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [returnInventoryItemAssignment.pending](state, action) {
      const { id, date, user } = action.meta.arg;

      divisionItemsAdapter.updateOne(state.divisionItems, {
        id,
        changes: {
          return_time: moment(date).format("X"),
          return_uid: user.uid,
          return_user_name: user.name,
          returned: "1",
        },
      });
    },
    [returnInventoryItemAssignment.rejected](state, action) {
      const { id, item } = action.meta.arg;

      divisionItemsAdapter.updateOne(state.divisionItems, {
        id,
        changes: item,
      });
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const inventoryDivisionItemsSelectors =
  divisionItemsAdapter.getSelectors((state) => state.inventory.divisionItems);

export const inventoryMemberItemsSelectors = memberItemsAdapter.getSelectors(
  (state) => state.inventory.memberItems
);

export const itemsUsageSelectors = itemsUsageAdapter.getSelectors(
  (state) => state.inventory.usage
);

export const itemsMaintenanceSelectors = itemsMaintenanceAdapter.getSelectors(
  (state) => state.inventory.maintenanceHistory
);

export const itemsMaintenanceScheduleSelectors =
  itemsMaintenanceScheduleAdapter.getSelectors(
    (state) => state.inventory.maintenanceSchedules
  );

// Custom selectors
const selectSelf = (state) => state;
export const getInventoryDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.data
);

export const getInventoryLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.loading
);

export const getInventoryDivisionItemsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.divisionItems.loading
);

export const getInventoryDivisionItemsPaginationSelector =
  createDraftSafeSelector(
    selectSelf,
    (state) => state.inventory.divisionItems.pagination
  );

export const getInventoryMemberItemsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.memberItems.loading
);

export const getInventoryMemberItemsPaginationSelector =
  createDraftSafeSelector(
    selectSelf,
    (state) => state.inventory.memberItems.pagination
  );

export const getInventoryErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.error
);

export const getInventoryDivisionItemsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.divisionItems.error
);

export const getInventoryMemberItemsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.memberItems.error
);

export const getItemsUsageErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.usage.error
);

export const getItemsUsageLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.usage.loading
);

export const getItemsUsagePaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.usage.pagination
);

export const getItemsMaintenanceErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.maintenanceHistory.error
);

export const getItemsMaintenanceLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.inventory.maintenanceHistory.loading
);

export const { clearItem } = inventorySlice.actions;

export default inventorySlice.reducer;
