import axios from "axios";
import conf from "config";
import getQueryParams from "utility/getQueryParams";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const postsAPI = {
  getCompanyPosts: async ({ params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/posts${queryParams}`, config);
  },
  getPost: async ({ id, config }) => {
    return instance.get(`/posts/${id}`, config);
  },
  getUserPosts: async ({ id, params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/users/${id}/posts${queryParams}`, config);
  },
  getPinnedPosts: async ({ params, config }) => {
    const queryParams = getQueryParams(params);
    return instance.get(`/pinned/posts${queryParams}`, config);
  },
  addPost: async ({ params, config }) => {
    return instance.post(`/posts`, params, config);
  },
  addReaction: async ({ id, params, config }) => {
    return instance.patch(`/posts/${id}/add-reaction`, params, config);
  },
  removeReaction: async ({ id, params, config }) => {
    return instance.patch(`/posts/${id}/remove-reaction`, params, config);
  },
  addPinned: async ({ id, params, config }) => {
    return instance.patch(`/posts/${id}/add-pinned`, params, config);
  },
  removePinned: async ({ id, config }) => {
    return instance.patch(`/posts/${id}/remove-pinned`, {}, config);
  },
  deletePost: async ({ id, config }) => {
    return instance.delete(`/posts/${id}`, config);
  },
};

export default postsAPI;
