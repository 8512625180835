import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatField } from "../../utility";
import { setAlert } from "features/Alert/alertSlice";
import { formatInvoice } from "./utils";
import { formatAccounting } from "components/Accounting/utils";

// const getQueryParams = (params) => {
//   return `?${
//     typeof params === "string"
//       ? params.substring(1)
//       : querystring.stringify(params)
//   }`;
// };

const namespace = "sage";

export const fetchSageAccountings = createAsyncThunk(
  `${namespace}/fetchSageAccountings`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/job/accountings/${id}`,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return {
        isAccounting: response.data.accounting_role,
        history: response.data.accounting_history,
        selectedFiles: response.data.selected_files,
        results: response.data.accountings,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSageAccounting = createAsyncThunk(
  `${namespace}/fetchSageAccounting`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatAccounting(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSageInvoiceTemplate = createAsyncThunk(
  `${namespace}/fetchSageInvoiceTemplate`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(
        `${config.api_url}/rest/job-accounting-invoice/template/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSageInvoice = createAsyncThunk(
  `${namespace}/fetchSageInvoice`,
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatInvoice(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postSageAccounting = createAsyncThunk(
  `${namespace}/postSageAccounting`,
  async (
    { divisionNid, divisionName, params },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job_division_accounting`,
            },
          },
          _meta: {
            job_division_nid: divisionNid,
          },
          title: [{ value: `Accounting for ${divisionName}` }],
          field_jd_accounting_type: [{ value: "invoice" }],
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully added billing",
        })
      );
      return formatInvoice(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding billing: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSageInvoice = createAsyncThunk(
  `${namespace}/patchSageInvoice`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job_division_accounting_invoice`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated invoice",
        })
      );
      return formatInvoice(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating invoice: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const accountingsAdapter = createEntityAdapter({
  selectId: (result) => result.id,
});

const sageSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    isAccounting: false,
    history: {},
    accounting: {
      loading: false,
      data: {},
    },
    invoiceTemplate: {
      loading: false,
      data: {},
    },
    invoice: {
      loading: false,
      data: {},
    },
    accountings: accountingsAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: {
    [fetchSageAccountings.pending](state, action) {
      state.loading = true;
      state.history = {};
      accountingsAdapter.removeAll(state.accountings);
    },
    [fetchSageAccountings.fulfilled](state, { payload: { results, history } }) {
      accountingsAdapter.setAll(state.accountings, results);
      state.history = history;
      state.loading = false;
    },
    [fetchSageAccountings.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchSageAccounting.pending](state, action) {
      state.accounting.loading = true;
      state.accounting.data = {};
      state.accounting.error = null;
    },
    [fetchSageAccounting.fulfilled](state, { payload: accounting }) {
      state.accounting.data = accounting;
      state.accounting.loading = false;
    },
    [fetchSageAccounting.rejected](state, action) {
      if (!action.meta.aborted) {
        state.accounting.loading = false;
        if (action.payload) {
          state.accounting.error = action.payload.message;
        } else {
          state.accounting.error = action.error.message;
        }
      }
    },
    [fetchSageInvoiceTemplate.pending](state, action) {
      state.invoiceTemplate.loading = true;
      state.invoiceTemplate.data = {};
      state.invoiceTemplate.error = null;
    },
    [fetchSageInvoiceTemplate.fulfilled](state, { payload: template }) {
      state.invoiceTemplate.data = template;
      state.invoiceTemplate.loading = false;
    },
    [fetchSageInvoiceTemplate.rejected](state, action) {
      if (!action.meta.aborted) {
        state.invoiceTemplate.loading = false;
        if (action.payload) {
          state.invoiceTemplate.error = action.payload.message;
        } else {
          state.invoiceTemplate.error = action.error.message;
        }
      }
    },
    [fetchSageInvoice.pending](state, action) {
      state.invoice.loading = true;
      state.invoice.data = {};
      state.invoice.error = null;
    },
    [fetchSageInvoice.fulfilled](state, { payload: invoice }) {
      state.invoice.data = invoice;
      state.invoice.loading = false;
    },
    [fetchSageInvoice.rejected](state, action) {
      if (!action.meta.aborted) {
        state.invoice.loading = false;
        if (action.payload) {
          state.invoice.error = action.payload.message;
        } else {
          state.invoice.error = action.error.message;
        }
      }
    },
    [patchSageInvoice.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      state.data = {
        ...state.data,
        ...tempParams,
      };
    },
    [patchSageInvoice.fulfilled](state, { payload: invoice }) {
      state.invoice.data = invoice;
    },
    [patchSageInvoice.rejected](state, action) {
      const { invoice } = action.meta.arg;

      state.invoice.data = invoice;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.invoice.error = action.payload.message;
        } else {
          state.invoice.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getSageLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.sage.loading
);

export const getSageAccountingLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.sage.accounting.loading
);

export const getSageAccountingDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.sage.accounting.data
);

export const getSageHistorySelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.sage.history
);

export const getSageInvoiceTemplateLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.sage.invoiceTemplate.loading
);
export const getSageInvoiceTemplateDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.sage.invoiceTemplate.data
);

export const getSageInvoiceLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.sage.invoice.loading
);
export const getSageInvoiceDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.sage.invoice.data
);

export const sageAccountingsSelectors = accountingsAdapter.getSelectors(
  (state) => state.sage.accountings
);

export default sageSlice.reducer;
