import React from "react";
import { Route } from "react-router-dom";

import { Wrapper } from "./Styled";
import Header from "../../features/Header";

const PublicRoute = ({ children, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <div className="app">
          <Header isPublic />
          <Wrapper>{children}</Wrapper>
        </div>
      )}
    />
  );
};

export default PublicRoute;
