import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  Typography,
  DialogContent,
  Switch,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Input,
  Tooltip,
} from "@mui/material";
import config from "config";

import Dialog from "common/Dialog";
import {
  jobDivisionFilesSelectors,
  patchJobDivisionFilePublished,
} from "features/Files/filesSlice";

const Share = ({ id, fileShareType, children }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const file = useSelector((state) =>
    jobDivisionFilesSelectors.selectById(state, id)
  );
  const _published = (file?.field_published && Number(file.field_published) === 1 ? true : false);
  const [checked, setChecked] = useState(_published);
  const [submitting, setSubmitting] = useState(false);
  const [published, setPublished] = useState(_published);
  const [tooltip, setTooltip] = useState("Copy to clipboard");
  // const fileShareSlug = (fileShareType === 'job_division' ? 'job-division/' : '');

  const url = `${config.rawHostname}/file-share/${file.field_public_hash}`;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (e) => {
    const published = e.target.checked;
    setChecked(published);
    if(!published){
      setPublished(published);
    }
    setSubmitting(true);
    dispatch(
      patchJobDivisionFilePublished({
        id: file.id,
        published: published,
      })
    ).then((res) => {
      if(published){
        setPublished(published);
      }
      setSubmitting(false);
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setTooltip("Copied");
    setTimeout(() => {
      setTooltip("Copy to clipboard");
    }, 1000);
  };

  return (
    <>
      {React.cloneElement(children, {
          onClick: handleOpen,
        })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        title="Share File"
      >
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={handleToggle}
                name="published"
                disabled={submitting}
              />
            }
            label="Public"
          />
          <FormHelperText>
            Anyone with the link below can view this file.
          </FormHelperText>

          <div className="flex flex-wrap my-3">
            <InputLabel className={` flex-[1_0_100%] ${(published ? 'text-black' : 'text-gray-middle')}`}>
              Public URL
            </InputLabel>
            <Input
              value={url}
              disabled={true}
              label="Public URL"
              fullWidth={true}
              className={`flex-1 mr-2 pt-0 rounded bg-gray-light ${(!published ? 'opacity-50' : '')}`}
            />
            <Tooltip title={tooltip}>
              <Typography
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginTop: "1rem",
                  display: "inline-block",
                  opacity: (published ? 1 : 0),
                }}
                onClick={handleCopy}
              >
                Copy Link
              </Typography>
            </Tooltip>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

Share.propTypes = {};

export default Share;
