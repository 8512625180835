import linkifyIt from "linkify-it";
import tlds from "tlds";

export const YOUTUBEMATCH_URL =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const VIMEOMATCH_URL =
  /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape
export const IMGURMATCH_URL = /https?:\/\/imgur\.com\/a\/(.*?)(?:[#\/].*|$)/; // eslint-disable-line no-useless-escape
export const GIPHYMATCH_URL =
  /https?:\/\/giphy\.com\/embed\/(.*?)(?:[#\/].*|$)/; // eslint-disable-line no-useless-escape
export const TWITTERMATCH_URL = /https?:\/\/twitter\.com\/(.*?)(?:[#\/].*|$)/; // eslint-disable-line no-useless-escape

export function isYoutube(url) {
  return YOUTUBEMATCH_URL.test(url);
}
export function isVimeo(url) {
  return VIMEOMATCH_URL.test(url);
}
export function isImgur(url) {
  return IMGURMATCH_URL.test(url);
}
export function isGiphy(url) {
  return GIPHYMATCH_URL.test(url);
}
export function isTwitter(url) {
  return TWITTERMATCH_URL.test(url);
}

export function getYoutubeSrc(url) {
  const id = url.match(YOUTUBEMATCH_URL) && url.match(YOUTUBEMATCH_URL)[1];
  return {
    srcID: id,
    srcType: "youtube",
    url,
  };
}
export function getVimeoSrc(url) {
  const id = url.match(VIMEOMATCH_URL) && url.match(VIMEOMATCH_URL)[3];
  return {
    srcID: id,
    srcType: "vimeo",
    url,
  };
}
export function getImgurSrc(url) {
  const id = url.match(IMGURMATCH_URL) && url.match(IMGURMATCH_URL)[1];
  return {
    srcID: id,
    srcType: "imgur",
    url,
  };
}
export function getGiphySrc(url) {
  const id = url.match(GIPHYMATCH_URL) && url.match(GIPHYMATCH_URL)[1];
  return {
    srcID: id,
    srcType: "giphy",
    url,
  };
}
export function getTwitterSrc(url) {
  return {
    srcID: null,
    srcType: "twitter",
    url,
  };
}

const linkify = linkifyIt();
linkify.tlds(tlds);

export const extractLinks = (text) => {
  return linkify.match(text);
};

const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

export const linkStrategy = (text) => {
  if (!text) {
    return {
      text: "",
      links: [],
    };
  }
  const links = extractLinks(text);
  let tempText = text;
  if (links) {
    let urls = [
      ...new Set(
        links.map((l) => stripTrailingSlash(l.url.replaceAll("\\", "")))
      ),
    ];

    const parsedLinks = [];
    urls.forEach((link) => {
      const extracted = extractVideoLink(link.replaceAll("\\", ""));
      if (extracted.srcType === "giphy") {
        tempText = tempText.replace(link, "");
      }

      if (!extracted.url.includes("mailto:")) {
        parsedLinks.push(extracted);
      }
    });

    return {
      text: tempText,
      links: parsedLinks,
    };
  }

  return {
    text,
    links: [],
  };
};

export const extractVideoLink = (src) => {
  if (isYoutube(src)) {
    return getYoutubeSrc(src);
  }
  if (isVimeo(src)) {
    return getVimeoSrc(src);
  }
  if (isGiphy(src)) {
    return getGiphySrc(src);
  }
  if (isTwitter(src)) {
    return getTwitterSrc(src);
  }
  if (isImgur(src)) {
    return getImgurSrc(src);
  }

  return { type: "generic", srcID: null, url: src };
};
