
import { filterEntityClients } from "components/ClientSelector/utils";
import { arraysMatchNested } from "utility";
import { isObject, orderBy, size } from "lodash";

/**
 *  Get the count of all the unique items across all clients in the array
 *
 * @param {array} items
 * @returns integer
 */
export const getTotalUiniqueItems = (items) => {
  const found = {};
  if(items){
    items.forEach((item) => {
      found[item.target_id] = true;
    });
  }
  return Object.keys(found).length;
}

/**
 *  Set-up an object to with all possible clients to contain their selected items
 *
 * @param {array} items
 * @returns integer
 */
export const prepClientAssignedItems = (clients) => {
  const clientItems = {};
  if(clients){
    clients.forEach((client) => {
      var clientObj = {
        "client": {
          "nid": (client.nid ? client.nid : client.target_id),
          "title": (client?.title ? client?.title : ''),
        },
        "items": [],
      };
      clientItems[Number(client.nid ? client.nid : client.target_id)] = clientObj;
    });
  }
  return clientItems;
}

/**
 *  Break a simple database array into an object with all available clients
 *
 * @param {array} items
 * @returns integer
 */
export const parseClientAssignedField = (entity, field, clients, fieldData, removeEmpty) => {
  let availableClients = [];
  if(entity){
    availableClients = filterEntityClients(entity?.field_phoenix_clients, clients);
  }
  else{
    availableClients = clients;
  }

  let items = [];
  if(fieldData){
    items = fieldData;
  }
  else if(entity && entity[field]){
    items = entity[field];
  }
  else{
    return {};
  }
  const clientItems = prepClientAssignedItems(availableClients);
  orderBy(items, "entity_title", "asc").forEach((e) => {
    if(clientItems[Number(e?.phx_client_nid ? e.phx_client_nid : e.nid)]){
      clientItems[Number(e?.phx_client_nid ? e.phx_client_nid : e.nid)].items.push(e);
    }
  });
  if(removeEmpty){
    Object.entries(clientItems).forEach((_client) => {
      const [nid, client] = _client;
      if(client?.items && client?.items.length === 0){
        delete clientItems[nid];
      }
    });
  }
  return clientItems;
}

/**
 * Check if all the Client Assigned values are the same for all clients
 *
 * @param {array} clientItems
 * @returns boolean
 */
export const checkMatchingClientAssignedValues = (clientItems) => {
  const clientItemEntries = Object.entries(clientItems);
  let isDiffClientItems = false;
  clientItemEntries.forEach((entries) => {
    const [nid, entry] = entries;
    clientItemEntries.forEach((_entries) => {
      const [_nid, _entry] = _entries;
      if(nid !== _nid
        && size(entry.items) > 0
        && size(_entry.items) > 0
        && !arraysMatchNested(entry.items, _entry.items, 'target_id')) {
        isDiffClientItems = true;
        return;
      }
    });
    if(isDiffClientItems){
      return;
    }
  });
  return isDiffClientItems;
}

/**
 * Convert the straight array of values into an object separated by client nid for initial values on a form
 *
 * @param {array} values
 * @returns object
 */
export const getClientAssignedFieldInitialValues = (clientItems) => {
  const fieldItems = {};
  if(clientItems){
    Object.entries(clientItems).forEach((entries, i) => {
      if(!fieldItems[i]){
        fieldItems[i] = [];
      }
      entries[1].items.forEach((item) => {
        if(item?.target_id){
          fieldItems[i].push(String(item.target_id));
        }
      });
    });
  }
  return fieldItems;
}

/**
 * parse the form values into a straight array for the form submit
 *
 * @param {object} values
 * @returns array
 */
export const parseClientAssignedFieldSubmitData = (values, clientItems) => {
  const data = [];
  let vals = values;
  if(isObject(values)){
    vals = [];
    Object.entries(values).forEach((entries) => {
      vals.push(entries[1]);
    });
  }
  Object.entries(clientItems).forEach((entries, i) => {
    vals.forEach((items, j) => {
      if(i === j){
        items.forEach((target_id) => {
          data.push({
            phx_client_nid: Number(entries[0]),
            target_id: Number(target_id),
          });
        });
      }
    });
  });
  return data;
}
