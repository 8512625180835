import action from "../action";
import config from "../../config";

export const getComplianceActivities = (params) =>
  action(
    "complianceActivities",
    config.api_url,
    `rest/compliance-manager`,
    params
  );

export const getComplianceActivity = (nid, params) =>
  action("complianceActivity", config.api_url, `node/${nid}`, params);

export const patchComplianceActivity = (params) =>
  action(
    "complianceActivity",
    config.api_url,
    `rest/compliance-manager/add`,
    params,
    "post"
  );

export const postComplianceActivity = (params) =>
  action(
    "complianceActivity",
    config.api_url,
    `rest/compliance-manager/add`,
    params,
    "post"
  );

export const liftComplianceActivity = (nid, params) =>
  action(
    "complianceActivity",
    config.api_url,
    `rest/compliance-manager/lift/${nid}`,
    params,
    "patch"
  );

export const deleteComplianceActivity = (nid, params) =>
  action(
    "complianceActivity",
    config.api_url,
    `rest/compliance-manager/delete/${nid}`,
    params,
    "delete"
  );
