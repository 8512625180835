import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@mui/material";
import { Autocomplete as MuiAutocomplete } from '@mui/material';

import { useDebounce } from "../../hooks";

const Autocomplete = ({
  fetchData,
  loading,
  value,
  handleChange,
  fullWidth,
  label,
  variant,
  endAdornment,
  labelwidth,
  margin,
  required,
  shrink,
  options,
  autoFocus,
  multiple,
  inputProps,
  helperText,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 250);
  const working = open && loading;

  useEffect(() => {
    let promise;
    if (open) {
      promise = fetchData(debouncedQuery);
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [debouncedQuery, fetchData, open]);

  const handleInputChange = (e) => {
    if (e) {
      e.stopPropagation();
      setQuery(e.target.value);
    }
  };

  const clearUsersOptions = () => {
    setOpen(false);
  };

  const onChange = (e, value) => {
    e.stopPropagation();
    handleChange(value);
  };

  return (
    <MuiAutocomplete
      multiple={multiple}
      open={open}
      onChange={onChange}
      value={value}
      onOpen={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
      onClose={clearUsersOptions}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={working}
      disableCloseOnSelect={multiple}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${label}${required ? " *" : ""}`}
          variant={variant}
          margin={margin}
          helperText={helperText ? helperText : ""}
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {working ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...inputProps}
        />
      )}
    />
  );
};

Autocomplete.propTypes = {};

export default Autocomplete;
