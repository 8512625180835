import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";

import Button from "common/Button";
import { TextFormField } from "common/Fields";
import { AutocompleteFormField } from "common/Fields";

import {
  fetchUsers,
  autocompleteUsersSelectors,
  getAutocompleteUsersLoadingSelector,
} from "features/Autocomplete/autocompleteSlice";

const Contacts = ({ values, isSubmitting, value, handleNext, handlePrev }) => {
  const dispatch = useDispatch();
  const users = useSelector(autocompleteUsersSelectors.selectAll);
  const loading = useSelector(getAutocompleteUsersLoadingSelector);

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchUsers({
          params: {
            keywords: query,
          },
        })
      );
    },
    [dispatch]
  );

  if (value !== 1) return null;
  return (
    <div>
      <Typography variant="h6">Contact</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Contact information for this Customer. Along with designated users that
        should be contacted in order of priority.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_phone"
            label="Phone"
            disabled={isSubmitting}
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_phone_after_hours"
            label="Phone After Hours"
            disabled={isSubmitting}
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_fax"
            label="Fax"
            disabled={isSubmitting}
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_primary_poc"
            label="Primary Contact"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            options={users.map((user) => ({
              name: user.name,
              uid: user.uid,
            }))}
            loading={loading}
            fetchData={getUsers}
          />
        </Grid>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_secondary_pocs"
            label="Secondary Contacts"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            options={users.map((user) => ({
              name: user.name,
              uid: user.uid,
            }))}
            loading={loading}
            fetchData={getUsers}
            multiple
          />
        </Grid>
        <Grid item xxs={12}>
          <AutocompleteFormField
            name="field_after_hours_pocs"
            label="After Hours Contacts"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            options={users.map((user) => ({
              name: user.name,
              uid: user.uid,
            }))}
            loading={loading}
            fetchData={getUsers}
            multiple
          />
        </Grid>
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
              onClick={handlePrev}
              style={{ marginRight: "1rem" }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Contacts.propTypes = {};

export default Contacts;
