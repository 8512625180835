import styled from "styled-components";

export const HubIconButton = styled.div`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 9999px;
  color: var(--color-gray-darker);
  cursor: pointer;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: var(--timing-quartic);
  transition-duration: 150ms;

  a{
    color: var(--color-gray-darker);
  }

  &:hover {
    background-color: var(--color-blue-lightest);
  }

  ${props => props.disabled ? `
    pointer-events: none;
    opacity: 0.25;
  ` : ''}
`;
