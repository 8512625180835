import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";

import { deleteParagraph } from "../../actions";
import { DialogForm } from "../Form";
import { RouteDialog } from "../Dialog";

const Form = ({ url, path, removeParagraph, refreshMethod }) => {
  const { id } = useParams();

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    const response = await removeParagraph(id);
    if (response.status === 204) {
      if (refreshMethod) {
        refreshMethod();
      }
      successCallback("Removed successfully.");
    } else {
      errorCallback(setMessage, response, []);
    }
  };

  return (
    <DialogForm
      submitText="Remove"
      url={url}
      path={"remove"}
      initialValues={{}}
      onSubmit={handleSubmit}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <div>
          <Typography>Are you sure you want to remove this item?</Typography>
        </div>
      )}
    </DialogForm>
  );
};

const ParagraphDeleteDialog = ({ url, ...rest }) => {
  return (
    <RouteDialog
      route="remove/:id"
      path="remove"
      url={url}
      maxWidth="xs"
      title="Are you sure?"
    >
      <Form url={url} {...rest} />
    </RouteDialog>
  );
};

ParagraphDeleteDialog.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  removeParagraph: (params) => dispatch(deleteParagraph(params)),
});

export default connect(null, mapDispatchToProps)(ParagraphDeleteDialog);
