import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import { setAllCompanies, setCompany } from "features/Companies/companiesSlice";
import userAPI from "./userAPI";
import isNumber from "lodash/isNumber";

import axios from "axios";

import { formatUser } from "utility";
import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const namespace = "user";

export const fetchAPIUserCurrent = createAsyncThunk(
  "user/fetchAPIUserCurrent",
  async (config, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await userAPI.getCurrentUser({
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      const companies = response.data.companies;
      const company = companies[0];
      dispatch(setCompany(company));
      dispatch(setAllCompanies(companies));

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUser = createAsyncThunk(
  `${namespace}/fetchUser`,
  async (uidName, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      let path = `/user/${uidName}`;
      if(!isNumber(uidName)){
        path = `/users/${uidName}`;
      }

      const response = await axios.get(
        `${config.api_url}/${path}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return formatUser(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


export const postUser = createAsyncThunk(
  `${namespace}/postUser`,
  async ({ user, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/user`,
        {
          _links: { type: { href: `${config.api_url}/rest/type/user/user` } },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatUser(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchUser = createAsyncThunk(
  `${namespace}/patchUser`,
  async ({ user, params }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/user/${user.uid}`,
        {
          _links: { type: { href: `${config.api_url}/rest/type/user/user` } },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatUser(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);


const userAdapter = createEntityAdapter({
  selectId: (user) => user.uid,
});

const userSlice = createSlice({
  name: namespace,
  initialState: {
    data: {},
    loading: true,
    error: null,
    helixAPICurrent: userAdapter.getInitialState({
      loading: false,
      error: null,
    }),
    userEditFormData: {}
  },
  reducers: {
    updateUser: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    addUser: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    updateHelixAPICurrent: (state, action) => {
      state.helixAPICurrent.data = { ...state.helixAPICurrent.data, ...action.payload };
    },
    updateUserEditFormData: (state, action) => {
      state.userEditFormData = { ...state.userEditFormData, ...action.payload };
    },
  },
  extraReducers: {
    [fetchAPIUserCurrent.pending](state, action) {
      state.helixAPICurrent.loading = true;
      state.helixAPICurrent.error = null;
      state.helixAPICurrent.data = {};
    },
    [fetchAPIUserCurrent.fulfilled](state, { payload: user }) {
      state.helixAPICurrent.loading = false;
      state.helixAPICurrent.error = null;
      state.helixAPICurrent.data = user;
    },
    [fetchAPIUserCurrent.rejected](state, action) {
      state.helixAPICurrent.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.helixAPICurrent.error = action.payload.message;
        } else {
          state.helixAPICurrent.error = action.error.message;
        }
      }
    },
    [fetchUser.pending](state, action) {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
    [fetchUser.fulfilled](state, { payload: user }) {
      state.loading = false;
      state.error = null;
      state.data = user;
    },
    [fetchUser.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postUser.pending](state) {},
    [postUser.fulfilled](state, { payload: user }) {
      state.user = user;
    },
    [postUser.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchUser.pending](state, action) {},
    [patchUser.fulfilled](state, { payload: user }) {
      state.data = user;
    },
    [patchUser.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

export const userSelector = userAdapter.getSelectors((state) => state.user);

// Custom selectors
const selectSelf = (state) => state;
export const getUserDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.user.data
);

export const getUserLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.user.loading
);

export const getUserErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.user.error
);

export const { updateUser, updateHelixAPICurrent, updateUserEditFormData } = userSlice.actions;

export default userSlice.reducer;
