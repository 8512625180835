import React from "react";
import { Field } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";

const DateTimeFormField = ({ name, datePicker, ...props }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const handleDateChange = (value) => {
          if (!value) {
            form.setFieldValue(name, null);
          } else {
            form.setFieldValue(name, value);
          }
        };

        const handleBlur = () => form.setFieldTouched(name, true);
        const errorText = meta.error && meta.touched ? meta.error : "";
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              value={field.value}
              onChange={handleDateChange}
              onBlur={handleBlur}
              error={!!errorText}
              helperText={errorText}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  {...props}
                  variant={props?.inputVariant}
                />
              )}
              {...props}
            />
          </LocalizationProvider>
        );
      }}
    </Field>
  );
};

DateTimeFormField.propTypes = {};

export default DateTimeFormField;
