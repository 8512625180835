import { size } from "lodash";

import { formatField, getTaxonomyFields } from "../../utility";

const getChamberInspectionReportData = (d) => {
  const field = formatField(d, "field_mf_chm_insp_rpt_data");
  const data = {
    average_class: 0,
    ceiling_height: 0,
    dehumidifier_lg_qty: 0,
    dehumidifier_lg_rec_qty: 0,
    dehumidifier_std_qty: 0,
    dehumidifier_std_rec_qty: 0,
    dehumidifier_xl_qty: 0,
    dehumidifier_xl_rec_qty: 0,
    injector_lg_qty: 0,
    injector_lg_rec_qty: 0,
    injector_xl_qty: 0,
    injector_xl_rec_qty: 0,
    manifold_qty: 0,
    manifold_rec_qty: 0,
    total_cubic_ft: 0,
    total_pints: 0,
    total_sqft: 0,
    total_wet_wall_lf: 0,
  };

  if (field) {
    return field;
  } else {
    return data;
  }
};

export const formatChamberFields = (c) => {
  return {
    nid: formatField(c, "nid"),
    title: formatField(c, "title"),
    field_mf_chamber_level: getTaxonomyFields(c, "field_mf_chamber_level"),
    field_mf_chamber_rooms: size(c.field_mf_chamber_rooms),
    field_mf_chm_insp_rpt_data: getChamberInspectionReportData(c),
  };
};
