import action from "../action";
import config from "../../config";

export const getTasksDrawer = (params) =>
  action("tasksDrawer", config.api_url, `rest/task/info`, params);

export const getTasks = (view, start, end, omit = 0, params) =>
  action(
    "tasks",
    config.api_url,
    `rest/tasks/${view}/${start}/${end}/${omit}`,
    params
  );

export const getTask = (nid, params) =>
  action("task", config.api_url, `node/${nid}`, params);

export const postTask = (params) =>
  action("task", config.api_url, `node`, params, "post");

export const patchTask = (nid, params) =>
  action("task", config.api_url, `node/${nid}`, params, "patch");

export const completeTask = (params) =>
  action(
    "taskComplete",
    config.api_url,
    `rest/tasks/completion-toggle`,
    params,
    "patch"
  );
