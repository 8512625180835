import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  createResponsiveStoreEnhancer,
  createResponsiveStateReducer,
  calculateResponsiveState,
} from "redux-responsive";
import store from "store";
import app from "../reducers";
import authReducer from "../features/Auth/authSlice";
import userReducer from "../features/User/userSlice";
import layoutReducer from "@phx/common/Layout/layoutSlice";
import jobReducer from "../features/Job/jobSlice";
import usersReducer from "../features/Users/usersSlice";
import commentsReducer from "../features/Comments/commentsSlice";
import workAreasReducer from "../features/WorkAreas/workAreasSlice";
import workAreaReducer from "../features/WorkArea/workAreaSlice";
import taskReducer from "../features/Task/taskSlice";
import tasksReducer from "../features/Tasks/tasksSlice";
import divisionsReducer from "../features/Divisions/divisionsSlice";
import toastReducer from "../features/Toast/toastSlice";
import notificationReducer from "components/Notification/notificationSlice";
import taxonomiesReducer from "../features/Taxonomies/taxonomiesSlice";
import filesReducer from "../features/Files/filesSlice";
import dailysheetsReducer from "../features/DailySheets/dailysheeetsSlice";
import dailysheetReducer from "../features/DailySheet/dailySheetSlice";
import invoiceReducer from "../features/Invoice/invoiceSlice";
import jobProgressReducer from "../features/JobProgress/jobProgressSlice";
import equipmentReducer from "../features/Equipment/equipmentSlice";
import locationReducer from "../features/Location/locationSlice";
import alertReducer from "../features/Alert/alertSlice";
import geoReducer from "../features/Geo/geoSlice";
import crewReducer from "../features/Crew/crewSlice";
import searchReducer from "../features/Search/searchSlice";
import recentActivityReducer from "../features/RecentActivity/recentAcitivitySlice";
import insuranceReducer from "../features/Insurance/insuranceSlice";
import membersReducer from "../features/Members/membersSlice";
import memberReducer from "../features/Member/memberSlice";
import customersReducer from "../features/Customers/customersSlice";
import customerReducer from "../features/Customer/customerSlice";
import autocompleteReducer from "../features/Autocomplete/autocompleteSlice";
import inventoryReducer from "../features/Inventory/inventorySlice";
import clientsReducer from "../features/Clients/clientsSlice";
import locationsReducer from "../features/Locations/locationsSlice";
import chambersReducer from "../features/Chambers/chambersSlice";
import chamberReducer from "../features/Chamber/chamberSlice";
import roomsReducer from "../features/Rooms/roomsSlice";
import roomReducer from "../features/Room/roomSlice";
import scopesReducer from "../features/Scopes/scopesSlice";
import complianceReducer from "../features/Compliance/complianceSlice";
import priceListsReducer from "../features/PriceLists/priceListsSlice";
import priceListReducer from "../features/PriceList/priceListSlice";
import logsReducer from "../features/Logs/logsSlice";
import timeEntriesReducer from "../features/TimeEntries/timeEntriesSlice";
import weatherReducer from "../features/Weather/weatherSlice";
import reportsReducer from "../features/Reports/reportsSlice";
import batchReducer from "../features/Batch/batchSlice";
import aimeReducer from "../features/Aime/aimeSlice";
import sageReducer from "../features/Sage/sageSlice";
import quoteSheetsReducer from "../features/QuoteSheets/quoteSheetsSlice";
import formBuilderReducer from "../features/FormBuilder/formBuilderSlice";
import quickbooksReducer from "../features/Quickbooks/quickbooksSlice";
import estimateReducer from "../features/Estimate/estimateSlice";
import estimatesReducer from "../features/Estimates/estimatesSlice";
import invoicesReducer from "../features/Invoices/invoicesSlice";
import floorplannerReducer from "../features/Floorplanner/floorplannerSlice";
import conversationsReducer from "../features/Conversations/conversationsSlice";
import companiesReducer from "../features/Companies/companiesSlice";
import postsReducer from "../features/Posts/postsSlice";
import profileReducer from "../features/Profile/profileSlice";
import groupsReducer from "../features/Groups/groupsSlice";
import hubReducer from "../features/Hub/hubSlice";
import eventsReducer from "../features/Events/eventsSlice";
import directoryReducer from "../features/Directory/directorySlice";
import advertisementsReducer from "../features/Advertisements/advertisementsSlice";
import resourcesReducer from "../features/Resources/resourcesSlice";
import vendorsReducer from "../features/Vendors/vendorsSlice";
import vendorArticlesReducer from "../features/VendorArticles/vendorArticlesSlice";
import tmTermsReducer from "../components/TMTerms/TMTermsSlice";
import imageInteractionReducer from "../components/ImageInteraction/imageInteractionSlice";
import timeClockReducer from "../components/TimeClock/timeClockSlice";

const combinedReducer = combineReducers({
  app,
  auth: authReducer,
  user: userReducer,
  layout: layoutReducer,
  profile: profileReducer,
  clients: clientsReducer,
  activity: recentActivityReducer,
  geo: geoReducer,
  job: jobReducer,
  users: usersReducer,
  comments: commentsReducer,
  workareas: workAreasReducer,
  workarea: workAreaReducer,
  chambers: chambersReducer,
  chamber: chamberReducer,
  rooms: roomsReducer,
  room: roomReducer,
  task: taskReducer,
  tasks: tasksReducer,
  divisions: divisionsReducer,
  toast: toastReducer,
  notification: notificationReducer,
  taxonomies: taxonomiesReducer,
  dailysheets: dailysheetsReducer,
  dailysheet: dailysheetReducer,
  invoice: invoiceReducer,
  equipment: equipmentReducer,
  jobProgress: jobProgressReducer,
  files: filesReducer,
  location: locationReducer,
  locations: locationsReducer,
  alert: alertReducer,
  crew: crewReducer,
  search: searchReducer,
  insurance: insuranceReducer,
  members: membersReducer,
  member: memberReducer,
  customers: customersReducer,
  customer: customerReducer,
  autocomplete: autocompleteReducer,
  inventory: inventoryReducer,
  scopes: scopesReducer,
  compliance: complianceReducer,
  pricelists: priceListsReducer,
  pricelist: priceListReducer,
  logs: logsReducer,
  timeEntries: timeEntriesReducer,
  weather: weatherReducer,
  reports: reportsReducer,
  batch: batchReducer,
  aime: aimeReducer,
  sage: sageReducer,
  quotesheets: quoteSheetsReducer,
  formbuilder: formBuilderReducer,
  quickbooks: quickbooksReducer,
  estimate: estimateReducer,
  estimates: estimatesReducer,
  invoices: invoicesReducer,
  floorplanner: floorplannerReducer,
  conversations: conversationsReducer,
  companies: companiesReducer,
  posts: postsReducer,
  groups: groupsReducer,
  hub: hubReducer,
  events: eventsReducer,
  directory: directoryReducer,
  advertisements: advertisementsReducer,
  resources: resourcesReducer,
  vendors: vendorsReducer,
  articles: vendorArticlesReducer,
  tmTerms: tmTermsReducer,
  imageinteraction: imageInteractionReducer,
  time_clock: timeClockReducer,
  browser: createResponsiveStateReducer(
    {
      extraSmall: 480,
      small: 768,
      medium: 992,
      large: 1200,
      extraLarge: 1800,
      extraLarge2: 1920,
    },
    {
      extraFields: () => ({
        width: window.innerWidth,
      }),
    }
  ),
});

const rootReducer = (state, action) => {
  if (
    action.type === "auth/postLogout/fulfilled" ||
    action.type === "auth/postLogout/rejected" ||
    action.type === "auth/logout"
  ) {
  //  If we're just logging out other accounts, then don't reset the state
    if (action.type !== "auth/postLogout/fulfilled" || action?.meta?.arg?.revokeType !== 'others'){
    //  Clear the state, but maintain browser across logout
      const _browser = state.browser;
      const newState = combinedReducer(undefined, action);
      newState.browser = _browser;
      return newState;
    }
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
  enhancers: [createResponsiveStoreEnhancer()],
});

let resizing = null;
window.addEventListener('resize', () => {
  if(!resizing){
    resizing = setTimeout(() => {
      store.dispatch(calculateResponsiveState(window));
      resizing = null;
    }, 500);
  }
});
