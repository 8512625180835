import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  getUsers: async ({ queryparams, config }) => {
    return instance.get(`/users${queryparams}`, config);
  },
};

export default API;
