import React, { useEffect, useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { size, find, first } from "lodash";
import styled from "styled-components";

import { DefaultLoader } from "common/Loader";
import DKIJobForm from "../DKI/forms/AddJob";
import D2DJobForm from "../D2D/forms/AddJob";
import MFIJobForm from "../MFI/forms/AddJob";
import { jobCreateClientsSelectors } from "features/Clients/clientsSlice";
import { ScrollableContainer } from "common/Layout/Styled";
import { ImFilesEmpty } from "react-icons/im";

export const Wrapper = styled(ScrollableContainer)`
  flex: 1;
  overflow: auto;
  background: var(--color-gray-background);
`;


const AddJob = () => {
  const user = useSelector((state) => state.auth.user.data);
  const clients = useSelector(jobCreateClientsSelectors.selectAll);
  const loading = useSelector((state) => state.auth.user.loading);

  const [client, setClient] = useState(null);
  const [clientDisabled, setClientDisabled] = useState(false);

  const [layoutScrollTo, setLayoutScrollTo] = useState(null);

  useEffect(() => {
    setClient(first(clients)?.nid);
  }, [clients]);

  const updateScroll = useCallback((scrollTo) => {
    setLayoutScrollTo(scrollTo);
  }, []);

  const getTypeByClientId = useCallback(
    (nid) => {
      const clients = user.field_clients;
      const client = find(clients, (c) => c.nid === nid);

      return client;
    },
    [user]
  );

  const getFormContent = useCallback(
    (nid) => {
      const client = getTypeByClientId(nid);

      switch (client?._processed?.job_division_process_type) {
        case "dki":
          return <DKIJobForm client={client} updateScroll={updateScroll} setClientDisabled={setClientDisabled} />;
        case "mf":
          return <MFIJobForm clientId={nid} updateScroll={updateScroll} setClientDisabled={setClientDisabled} />;
        case "general":
          return <D2DJobForm client={client} updateScroll={updateScroll} setClientDisabled={setClientDisabled} />;
        default:
          return <D2DJobForm client={client} updateScroll={updateScroll} setClientDisabled={setClientDisabled} />;
      }
    },
    [getTypeByClientId, updateScroll]
  );

  const handleChange = (e) => {
    setClient(e.target.value);
  };

  return (
    <Wrapper
      scrollTo={layoutScrollTo}
      setLayoutScrollTo={setLayoutScrollTo}
    >
      <Helmet>
        <title>Add Job</title>
        <meta name="description" content="Add Job" />
      </Helmet>
      {loading ? (
        <DefaultLoader />
      ) : (
        <div
          style={{
            padding: "1.25rem 1.25rem 4rem 1.25rem",
            position: "relative",
            maxWidth: 800,
            margin: "0 auto",
          }}
        >
          <Typography variant="h6">{<ImFilesEmpty className="header-icon" />} Add Job</Typography>
          {size(clients) > 1 && (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginBottom: "1rem" }}
              >
                You have access to multiple job processes. Select the job type
                you want to complete below.
              </Typography>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                shrink
              >
                <InputLabel id="client-select" shrink>
                  Client
                </InputLabel>
                <Select
                  shrink
                  label="Client"
                  labelId="client-select"
                  size="small"
                  value={client}
                  onChange={handleChange}
                  disabled={clientDisabled}
                  inputProps={{
                    name: "client",
                    variant: "outlined",
                  }}
                >
                  {clients.map((option) => (
                    <MenuItem key={option.nid} value={option.nid}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Divider style={{ margin: "2rem 0" }} />
          {getFormContent(client)}
        </div>
      )}
    </Wrapper>
  );
};

AddJob.propTypes = {};

export default AddJob;
