import { first } from "lodash";
import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  getTaxonomyFields,
  getUserFields,
  getFileFields,
  getValuesFromFieldArray,
  getPhoenixClients,
} from "../../utility";

export const formatMemberFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "node/member",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_affiliation: getTaxonomyFields(d, "field_affiliation"),
    field_primary_poc: d?.field_primary_poc
      ? d.field_primary_poc.map((poc) => formatUserFields(poc))
      : [],
    field_secondary_pocs: d?.field_secondary_pocs
      ? d.field_secondary_pocs.map((poc) => formatUserFields(poc))
      : [],
    field_after_hours_pocs: d?.field_after_hours_pocs
      ? d.field_after_hours_pocs.map((poc) => formatUserFields(poc))
      : [],
    field_agrmnt_exp: formatField(d, "field_agrmnt_exp"),
    field_response_time: formatField(d, "field_response_time"),
    field_allow_askaime_access: formatField(d, "field_allow_askaime_access"),
    field_billing_address: formatField(d, "field_billing_address"),
    field_billing_address_geo: d?.field_billing_address_geo?.[0],
    field_certifications: getValuesFromFieldArray(d, "field_certifications"),
    // field_child_members: []
    field_counties_owned: d?.field_counties_owned ? d.field_counties_owned : [], // This moved to _processed not sure if this index is needed now.
    field_counties_served: d?.field_counties_served ? d.field_counties_served : [], // This moved to _processed not sure if this index is needed now.

    // field_crm_calls: []
    field_d2d: formatField(d, "field_d2d"),
    field_daily_sheets_member: formatField(d, "field_daily_sheets_member"),
    field_default_termination: formatField(d, "field_default_termination"),
    field_division_types: getValuesFromFieldArray(d, "field_division_types"),
    field_jd_types: (d?.field_jd_types ? d.field_jd_types : []),
    field_dki_liaison: getUserFields(d, "field_dki_liaison"),
    field_ds_cust_validation_toggle: formatField(
      d,
      "field_ds_cust_validation_toggle"
    ),
    field_ds_expns_rcpt_require: formatField(d, "field_ds_expns_rcpt_require"),
    field_ds_mem_val_effect_override: formatField(
      d,
      "field_ds_mem_val_effect_override"
    ),
    field_ds_nte_hold_override: formatField(d, "field_ds_nte_hold_override"),
    field_ds_srvc_mult_day_usr_ovrd: formatField(
      d,
      "field_ds_srvc_mult_day_usr_ovrd"
    ),
    field_effective_date: formatField(d, "field_effective_date"),
    field_email: formatField(d, "field_email"),
    // field_employees: []
    field_expiration_date: formatField(d, "field_expiration_date"),
    field_fax: formatField(d, "field_fax"),
    field_g2: formatField(d, "field_g2"),
    field_hours: formatField(d, "field_hours"),
    field_is_headquarters: formatField(d, "field_is_headquarters"),
    field_is_parent_member: formatField(d, "field_is_parent_member"),
    field_last_contact: formatField(d, "field_last_contact"),
    field_last_updated: formatField(d, "field_last_updated"),
    field_last_updated_by: getUserFields(d, "field_last_updated_by"),
    field_location_name: formatField(d, "field_location_name"),
    field_logo: first(getFileFields(d, "field_logo")),
    field_member_code: formatField(d, "field_member_code"),
    field_member_division: formatField(d, "field_member_division"),
    field_mf_cust_video_url: formatField(d, "field_mf_cust_video_url"),
    field_mf_options: formatField(d, "field_mf_options"),
    field_national_contracts: getValuesFromFieldArray(d, "field_national_contracts"),
    field_office_id: formatField(d, "field_office_id"),
    field_opening_date: formatField(d, "field_opening_date"),
    field_other_addendum: formatField(d, "field_other_addendum"),
    field_phoenix_clients: getPhoenixClients(d?.field_phoenix_clients),
    field_phone: formatField(d, "field_phone"),
    field_phone_after_hours: formatField(d, "field_phone_after_hours"),
    field_phx_certified_poc: getUserFields(d, "field_phx_certified_poc"),
    field_previous_status: formatField(d, "field_previous_status"),
    field_region: getTaxonomyFields(d, "field_region"),
    field_rsm: getUserFields(d, "field_rsm"),
    field_status: formatField(d, "field_status"),
    field_street_address: formatField(d, "field_street_address"),
    field_street_address_geo: d?.field_street_address_geo?.[0],
    field_term_length: formatField(d, "field_term_length"),
    field_term_monthly_fee: formatField(d, "field_term_monthly_fee"),
    field_term_renewal_fee: formatField(d, "field_term_renewal_fee"),
    field_ufoc: formatField(d, "field_ufoc"),
    field_users_ignore_auto_clockin: formatField(
      d,
      "field_users_ifnore_auto_clockin"
    ),
    field_website: formatField(d, "field_website"),
    field_video_call_url: formatField(d, "field_video_call_url"),
    field_note: formatField(d, "field_note"),
    field_next_job_number: formatField(d, "field_next_job_number"),
    field_store_type: formatField(d, "field_store_type"),
    field_member_ci_primary: getUserFields(d, "field_member_ci_primary"),
    field_qb_payment_acct: formatField(d, "field_qb_payment_acct"),
    field_tm_terms: JSON.parse(formatFieldByValue(
      d,
      "field_tm_terms",
      "value"
    )),
    _meta: d?._meta,
    _processed: {
      ...d?._processed,
      field_counties_owned: d?._processed?.field_counties_owned ? d._processed.field_counties_owned : [],
      field_counties_served: d?._processed?.field_counties_served
        ? d._processed.field_counties_served
        : [],
    },
  };
};

export const filterMemberClients = (aClients, bClients) => {
  const filtered = [];
  if(!aClients || !bClients) return filtered;
  aClients.forEach((aClient) => {
    bClients.forEach((bClient) => {
      if(aClient.nid === bClient.nid) {
        filtered.push(aClient);
      }
    });
  });
  return filtered;
};

export const formatUserFields = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "user/user",
    uid: formatField(d, "uid"),
    status: formatField(d, "status"),
    path: formatFieldByValue(d, "path", "alias"),
    field_first_name: formatField(d, "field_first_name"),
    field_last_name: formatField(d, "field_last_name"),
    field_title_position: formatField(d, "field_title_position"),
    field_phone: formatField(d, "field_phone"),
    field_cell: formatField(d, "field_cell"),
    name: formatField(d, "name"),
    profile_pic: d._files?.profile_pic,
    _processed: (d?._processed ? {...d._processed} : {}),
  };
};

export const formatInsuranceAgent = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    field_member_ins_agent_email: formatField(
      d,
      "field_member_ins_agent_email"
    ),
    field_member_ins_agent_phone: formatField(
      d,
      "field_member_ins_agent_phone"
    ),
    field_member_ins_agent_agcy_name: formatField(
      d,
      "field_member_ins_agent_agcy_name"
    ),
    field_coverage_categories: d?.field_coverage_categories
      ? d.field_coverage_categories.map((category) => ({
          tid: formatField(category, "tid"),
          name: formatField(category, "name"),
        }))
      : [],
  };
};
