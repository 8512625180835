import action from "../action";
import config from "../../config";

export const getCustomers = (params) =>
  action("customers", config.api_url, "rest/customers", params);

export const getCustomerSnapshot = (nid, params) =>
  action("customerSnapshot", config.api_url, `node/${nid}`, params);

export const getCustomer = (name, params) =>
  action("customer", config.api_url, `customer/${name}`, params);

export const postCustomer = (params) =>
  action("customer", config.api_url, `node`, params, "post", true);

export const patchCustomer = (nid, params) =>
  action("customer", config.api_url, `node/${nid}`, params, "patch", true);

export const getCustomerJobs = (nid, params) =>
  action("customerJobs", config.api_url, `rest/customer/jobs/${nid}`, params);

export const getCustomerUsers = (nid, params) =>
  action("customerUsers", config.api_url, `rest/customer/users/${nid}`, params);

export const getChildCustomers = (nid, params) =>
  action(
    "childCustomers",
    config.api_url,
    `rest/customer/child-customers/${nid}`,
    params
  );

export const getCustomerlocations = (nid, params) =>
  action(
    "customerLocations",
    config.api_url,
    `rest/customer/locations/${nid}`,
    params
  );

export const getCustomerNotes = (nid, params) =>
  action("customerNotes", config.api_url, `rest/customer/notes/${nid}`, params);

export const getCustomerActivity = (nid, params) =>
  action(
    "customerActivity",
    config.api_url,
    `rest/customer/log/${nid}`,
    params
  );

export const getCustomerResources = (nid, params) =>
  action(
    "customerResources",
    config.api_url,
    `rest/resource_items/customer/${nid}`,
    params
  );

export const postCustomerUser = (params) =>
  action("customerUser", config.api_url, "entity/user", params, "post");

export const getCustomerFiles = (nid, params) =>
  action("customerFiles", config.api_url, `rest/customer/files/${nid}`, params);
