import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  getVendors: async ({ config }) => {
    return instance.get(`/vendors`, config);
  },
  getVendor: async ({ id, config }) => {
    return instance.get(`/vendors/${id}`, config);
  },
  addVendor: async ({ params, config }) => {
    return instance.post(`/vendors`, params, config);
  },
  addAdmin: async ({ id, params, config }) => {
    return instance.patch(`/vendors/${id}/add-admin`, params, config);
  },
  removeAdmin: async ({ id, params, config }) => {
    return instance.patch(`/vendors/${id}/remove-admin`, params, config);
  },
  updateVendor: async ({ id, params, config }) => {
    return instance.patch(`/vendors/${id}`, params, config);
  },
  deleteVendor: async ({ id, config }) => {
    return instance.delete(`/vendors/${id}`, config);
  },
};

export default API;
