import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import { toArray } from "lodash";
import {
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { DialogForm } from "common/Dialog";
import { AutocompleteFormField } from "common/Fields";
import { TextFormField } from "common/Fields";

import { postDuplicateJobOverride } from "../../jobSlice";
import {
  fetchUsers,
  getUsersLoadingSelector,
  usersAllSelector,
} from "features/Users/usersSlice";

const DuplicateJobOverride = ({
  open,
  onClose,
  setFieldValue,
  locationName,
  jobs,
}) => {
  const dispatch = useDispatch();
  const users = useSelector(usersAllSelector.selectAll);
  const loadingUsers = useSelector(getUsersLoadingSelector);

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchUsers({
          params: {
            keywords: query,
          },
        })
      );
    },
    [dispatch]
  );

  const handleClose = () => {
    setFieldValue("field_customer_location", null);
    onClose();
  };

  return (
    <DialogForm
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      title="Duplicate Job Override"
      disablePadding
      initialValues={{
        field_override_reason: "",
        field_approved_by: null,
      }}
      onSubmit={async (
        data,
        setSubmitting,
        setMessage,
        successCallback,
        errorCallback
      ) => {
        setMessage(null);

        const params = {
          field_approved_by: [{ target_id: data.field_approved_by.uid }],
          field_override_reason: [{ value: data.field_override_reason }],
        };

        try {
          const resultAction = await dispatch(postDuplicateJobOverride(params));
          unwrapResult(resultAction);
          setFieldValue("field_override_log_entry", resultAction.payload.id);
          onClose();
        } catch (err) {
          setFieldValue("field_customer_location", null);
          console.log(err);
        }
      }}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <DialogContent>
          <Typography>
            Jobs were recently created for this location, {locationName}.
          </Typography>
          <List>
            {jobs.map((job, i) => (
              <ListItem
                key={i}
                component={Link}
                to={job._paths.division}
                button
              >
                <ListItemText>{job.title}</ListItemText>
              </ListItem>
            ))}
          </List>
          <TextFormField
            name="field_override_reason"
            label="Override Reason"
            labelwidth={105}
            multiline
            variant="filled"
            rows={4}
            fullWidth
            margin="normal"
            required
          />
          <AutocompleteFormField
            name="field_approved_by"
            label="Approved By"
            disabled={isSubmitting}
            options={toArray(users).map((user) => ({
              name: user.name,
              uid: user.uid,
            }))}
            loading={loadingUsers}
            fetchData={getUsers}
            variant="filled"
            size="small"
          />
        </DialogContent>
      )}
    </DialogForm>
  );
};

DuplicateJobOverride.propTypes = {};

export default DuplicateJobOverride;
