import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";

const namespace = "batch";

export const postBatchImportStart = createAsyncThunk(
  `${namespace}/postBatchImportStart`,
  async ({ url, params, persisted }, { getState, rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.post(
        `${config.api_url}/${url}`,
        params,
        tokenConfig(getState)
      );

      return { response: response.data, persisted };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postBatchImportProcess = createAsyncThunk(
  `${namespace}/postBatchImportProcess`,
  async ({ url, params, persisted }, { getState, rejectWithValue, signal }) => {
    try {
      const response = await axios.post(`${config.api_url}${url}`, params, {
        withCredentials: true,
        headers: { "Content-Type": "application/hal+json" },
      });

      return { response: { batch_url: null, ...response.data }, persisted };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const batchAdapter = createEntityAdapter({
  selectId: (result) => result.batch_id,
});

const batchSlice = createSlice({
  name: namespace,
  initialState: {
    import: batchAdapter.getInitialState(),
  },
  reducers: {
    removeOneImportBatch: (state, { payload }) => {
      batchAdapter.removeOne(state.import, payload);
    },
  },
  extraReducers: {
    [postBatchImportStart.pending](state, action) {},
    [postBatchImportStart.fulfilled](
      state,
      { payload: { response, persisted } }
    ) {
      batchAdapter.addOne(state.import, { ...response, persisted: persisted });
    },
    [postBatchImportStart.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [postBatchImportProcess.pending](state, action) {},
    [postBatchImportProcess.fulfilled](
      state,
      { payload: { response, persisted } }
    ) {
      batchAdapter.updateOne(state.import, {
        id: response.batch_id,
        changes: { ...response },
      });
    },
    [postBatchImportProcess.rejected](state, action) {
      const { url } = action.meta.arg;
      const requestUrl = new URL(`${config.api_url}${url}`);
      const batchId = requestUrl.searchParams.get("batch_id");
      let error = null;
      if (!action.meta.aborted) {
        if (action.payload) {
          error = action.payload.message;
        } else {
          error = action.error.message;
        }
      }

      batchAdapter.updateOne(state.import, {
        id: batchId,
        changes: { error },
      });
    },
  },
});

export const batchImportSelectors = batchAdapter.getSelectors(
  (state) => state.batch.import
);

export const { removeOneImportBatch } = batchSlice.actions;

export default batchSlice.reducer;
