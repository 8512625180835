import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  getUsers: async ({ id, config }) => {
    return instance.get(`/companies/${id}/users`, config);
  },
};

export default API;
