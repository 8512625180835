import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  getUserFields,
} from "../../utility";

import { formatWorkAreaFields } from "../WorkArea/utils";

export const formatEquipment = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "ds_equipment",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_ds_date: formatField(d, "field_ds_date"),
    field_ds_user: getUserFields(d, "field_ds_user"),
    field_ds_total: formatField(d, "field_ds_total"),
    field_ds_taxable: formatField(d, "field_ds_taxable"),
    field_ds_work_area_allocation: formatWorkAreaFields(
      d?.field_ds_work_area_allocation?.[0]
    ),
    resources:
      d && d._processed && d._processed.resources
        ? d._processed.resources
        : null,
    summary: d?._processed?.ds_summary
  };
};

export const formatIncrements = (inc, singularize) => {
  let increment = inc;
  if(increments?.[inc]){
    increment = increments[inc];
  }
  if(singularize){
    increment = increment.replace(/\(s\)/gi, "").replace(/\(es\)/gi, "");
  }
  return increment;
}

export const increments = {
  h: "Hour(s)",
  d: "Day(s)",
  e: "Each",
  df: "Day(s) + Fuel",
  mi: "MI(s)",
  p: "Pair(s)",
  b: "Box(es)",
  c: "Can(s)",
  g: "Gallon(s)",
  r: "Roll(s)",
  cs: "CS",
  s: "Set(s)",
  pk: "Pack(s)",
  bg: "Bag(s)",
  pc: "Per Cubic Ft.",
  dz: "Dozen",
  pd: "Pad(s)",
  sf: "Sqft.",
  pnd: "Pound(s)",
  cas: "Case(s)",
  lf: "Linear Ft.",
  wk: "Week(s)",
  mnth: "Month(s)",
  ft: "Per ft Per Day",
  mrkt: "Market Price",
};
