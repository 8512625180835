import axios from "axios";
import config from "../config";
import FormData from "form-data";

import { returnErrors } from "./messageActions";
import { getUserData } from "./";

const START = "auth/LOGIN_START";
const LOAD = "auth/USER_LOAD";
const LOADED = "auth/USER_LOADED";
const LOAD_ERROR = "auth/USER_LOAD_ERROR";
const ERROR = "auth/LOGIN_ERROR";
const OK = "auth/LOGIN_OK";
const OUT = "auth/LOGOUT_SUCCESS";
const START_REFRESH = "auth/REFRESH_START";
const REFRESH_OK = "auth/REFRESH_OK";

export const authActions = {
  START,
  LOAD,
  LOADED,
  LOAD_ERROR,
  ERROR,
  OK,
  OUT,
  START_REFRESH,
  REFRESH_OK,
};

const loginStart = () => ({
  type: START,
});

const userLoad = () => ({
  type: LOAD,
});

const userLoaded = (payload) => ({
  type: LOADED,
  payload,
});

const loginOk = (payload) => ({
  type: OK,
  payload,
});

const logoutOk = () => ({
  type: OUT,
});

const refreshStart = () => ({
  type: START_REFRESH,
});

const refreshOk = (payload) => ({
  type: REFRESH_OK,
  payload,
});

const getAccessToken = () => localStorage.getItem("access_token");

export const loadUser = () => async (dispatch, getState) => {
  dispatch(userLoad());
  // if (localStorage.getItem("access_token")) {
  //   await dispatch(refreshToken());
  // }

  // const token = getState().app.auth.token;
  // if (token) {
  if (localStorage.getItem("access_token")) {
    try {
      const csrf = await getCSRF(config.api_url, getAccessToken(), axios);

      dispatch(
        userLoaded({ csrf: csrf.data.token, access_token: getAccessToken() })
      );
      dispatch(getUserData());
    } catch (error) {
      dispatch(returnErrors(error));
      dispatch({
        type: LOAD_ERROR,
      });
    }
  } else {
    dispatch({ type: LOAD_ERROR });
  }
};

export const login = (data) => async (dispatch) => {
  const { grant_type, client_id, client_secret, scope } = config;
  data.grant_type = grant_type;
  data.client_id = client_id;
  data.client_secret = client_secret;
  data.scope = scope;

  dispatch(loginStart());
  try {
    const token = await axios.post(
      `${config.api_url}/oauth/token?_format=json`,
      formatFormData(data)
    );

    const csrf = await getCSRF(config.api_url, token.data.access_token, axios);

    token.data.csrf = csrf.data.token;
    dispatch(loginOk(token.data));
    dispatch(getUserData());
  } catch (error) {
    dispatch(returnErrors(error));
    dispatch({
      type: ERROR,
    });
  }
};

export const logout = () => (dispatch) => dispatch(logoutOk());

export const refreshToken = () => async (dispatch, getState) => {
  dispatch(refreshStart());

  const { client_id, client_secret } = config;
  const data = {};
  data.grant_type = "refresh_token";
  data.client_id = client_id;
  data.client_secret = client_secret;
  data.refresh_token = getState().app.auth.refresh_token;

  try {
    const token = await axios.post(
      `${config.api_url}/oauth/token?_format=json`,
      formatFormData(data)
    );

    const csrf = await getCSRF(config.api_url, token.data.access_token, axios);

    token.data.csrf = csrf.data.token;
    dispatch(refreshOk(token.data));
    return token.data;
  } catch (error) {
    dispatch(returnErrors(error));
    dispatch({
      type: ERROR,
    });
    dispatch(logoutOk());
    return error;
  }
};

export const requestPasswordReset = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api_url}/rest/user/password?_format=hal_json`,
      data,
      {
        headers: {
          "Content-Type": "application/hal+json",
        },
      }
    );

    return response;
  } catch (error) {
    dispatch({
      type: ERROR,
    });
    return error;
  }
};

export const confirmPasswordResetValid = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api_url}/rest/user/confirm-reset?_format=hal_json`,
      data,
      {
        headers: {
          "Content-Type": "application/hal+json",
        },
      }
    );

    return response;
  } catch (error) {
    dispatch(returnErrors(error));
    dispatch({
      type: ERROR,
    });
    return error;
  }
};

export const requestPasswordResetLogin = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api_url}/rest/user/reset?_format=hal_json`,
      data,
      {
        headers: {
          "Content-Type": "application/hal+json",
        },
      }
    );

    return response;
  } catch (error) {
    dispatch(returnErrors(error));
    dispatch({
      type: ERROR,
    });
    return error;
  }
};

export const changePassword = (data, uid, token) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${config.api_url}/user/${uid}?_format=hal_json`,
      data,
      {
        headers: {
          "Content-Type": "application/hal+json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    dispatch(returnErrors(error));
    dispatch({
      type: ERROR,
    });
    return error;
  }
};

export const formatFormData = (data) => {
  const formData = new FormData();
  for (let field in data) {
    formData.append(field, data[field]);
  }

  return formData;
};

export const tokenConfig = (getState) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/hal+json",
    },
    params: {
      _format: "hal_json",
    },
  };

  return config;
};

const getCSRF = (url, token, instance) =>
  instance.get(`${url}/rest/csrf/token`, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      _format: "hal_json",
    },
  });
