import React, { useState } from "react";
import { IconButton } from "@mui/material";
import Avatar from "common/Avatar";
import { Close } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { conversationsSelectors } from "../conversationsSlice";
import { size } from "lodash";
import ConfirmationDialog from "components/ConfirmationDialog";
import { stringAvatar, getParticpants } from "./utils";

const ListItem = ({ id, onRemove, hideAvatars }) => {
  // const { id: routeId } = useParams();
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.user.helixAPICurrent.data);
  const conversation = useSelector((state) =>
    conversationsSelectors.selectById(state, id)
  );
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  // useEffect(() => {
  // window.Echo.join(`thread.${id}`)
  //   .listen("MessageUnread", (e) => {
  //     if (Number(routeId) !== id) {
  //       dispatch(
  //         updateConversation({
  //           id: e.thread_id,
  //           changes: { unread_count: conversation.unread_count + 1 },
  //         })
  //       );
  //     }
  //   })
  //   .listen("MessageSent", (e) => {
  //     if (Number(routeId) === id) {
  //       console.log(e);
  //       dispatch(
  //         addOneMessage({
  //           ...e.message,
  //           photos: e.photos,
  //         })
  //       );
  //     }
  //   });
  // return () => {
  //   window.Echo.leave(`thread.${id}`);
  // };
  // }, [id, dispatch, conversation, routeId]);

  const thisCloseButton = (
    <IconButton onClick={(e) => {
        e.preventDefault();
        setDeleteConfirm(true)
      }}>
      <Close />
    </IconButton>);

  return (
    <li className="flex items-center">
      <NavLink
        activeClassName="active"
        className="hub-nav-link group flex-1 align-w-full rounded-full py-2 px-4 flex items-center grid-md:hover:bg-gray-lightest transition-colors"
        to={`/hub/conversations/c/${conversation.id}`}
      >
        {!hideAvatars && (
          <div className="h-[40px] w-[40px] mr-2 flex flex-wrap">
            {conversation.participants
              .filter((p) => p.user_id !== user.id)
              .slice(0, 4)
              .map((p) => <Avatar
                key={p.user_id}
                title={p.user?.profile?.first_name + ' ' + p.user?.profile?.last_name}
                link={p.user?.profile?.avatar}
                sx={{ height: 64, width: 64 }}
                variant="circle"
                {...stringAvatar(
                  p.user.name,
                  size(conversation.participants)
                )}
              />
              )}
          </div>
        )}
        <h6 className="text-md font-semibold flex-1">
          <div className="multiline-ellipsis">
            {getParticpants(conversation, user)}
          </div>
        </h6>
        <div className="h-[38.5px] w-[38.5px] flex items-center justify-center">
          {conversation.unread_count > 0 && (
            <div className="py-0 px-2 bg-red-500 text-white text-sm rounded-full block group-hover:hidden">
              {conversation.unread_count}
            </div>
          )}
        </div>
        <div className="hidden grid-md:block opacity-0 group-hover:opacity-100">
          {thisCloseButton}
        </div>
      </NavLink>
      <div className="grid-md:hidden">
        {thisCloseButton}
        <ConfirmationDialog
          open={Boolean(deleteConfirm)}
          onClose={() => setDeleteConfirm(false)}
          onSubmit={() => {
            onRemove(conversation.id);
            setDeleteConfirm(false);
          }}
          title="Are you sure?"
          body="Are you sure that you want to delete this conversation?"
          submitButton="Delete"
        />
      </div>
    </li>
  );
};

ListItem.propTypes = {};

export default ListItem;
