import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";

const handleLoadData = async (
  load,
  query,
  page,
  clientNid,
  filter,
  setLoading,
  entity_id,
  filterOptions
) => {
  const params = {
    ...filterOptions
  };

  if (page) {
    params.page = page - 1;
  }

  if (query) {
    params.keywords = query;
  }

  if (clientNid && filter) {
    params[filter] = clientNid;
  }

  setLoading(true);
  if (entity_id) {
    await load(entity_id, params);
  } else {
    await load(params);
  }
  setLoading(false);
};

const useAutocomplete = (
  load,
  query,
  page,
  setPage,
  clientNid,
  filter,
  entity_id,
  filterOptions
) => {
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (setPage) {
      setPage(0);
    }
  }, [debouncedSearchTerm, setPage]);

  useEffect(() => {
    handleLoadData(
      load,
      debouncedSearchTerm,
      page,
      clientNid,
      filter,
      setLoading,
      entity_id,
      filterOptions
    );
  }, [load, debouncedSearchTerm, page, clientNid, filter, entity_id]); // eslint-disable-line react-hooks/exhaustive-deps

  return [loading];
};

export default useAutocomplete;
