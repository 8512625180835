import React from "react";
import ContentLoader from "react-content-loader";
import constants from "../constants";

const SummaryCardSkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    foregroundColor={constants.colorGray}
    backgroundColor={constants.colorGrayLight}
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="100" height="12" />
    <rect x="0" y="20" rx="3" ry="3" width="400" height="10" />
    <rect x="0" y="40" rx="3" ry="3" width="400" height="10" />
    <rect x="0" y="60" rx="3" ry="3" width="400" height="10" />
    <rect x="0" y="80" rx="3" ry="3" width="400" height="10" />
    <rect x="0" y="100" rx="3" ry="3" width="400" height="10" />
    <rect x="0" y="130" rx="3" ry="3" width="400" height="10" />
    <rect x="0" y="150" rx="3" ry="3" width="400" height="10" />
  </ContentLoader>
);

export default SummaryCardSkeleton;
